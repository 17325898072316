import {
  AspectRatio,
  AspectRatioProps,
  Link as CLink,
  LinkProps as CLinkProps,
  Center,
  ChakraProps,
  Stack,
  Text,
  chakra,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import { createLink } from '@eigtech/ui-shared-router'
import { forwardRef as reactForwardRef } from 'react'
import { IconType } from 'react-icons'

export type MobileIconLinkBaseProps = { Icon: IconType } & CLinkProps

export const MobileIconLink = createLink(
  reactForwardRef<'a', MobileIconLinkBaseProps>(function MobileIconLink(
    { Icon, children, ...props },
    ref
  ) {
    return (
      <CLink ref={ref} {...props}>
        <BaseButton Icon={Icon}>{children}</BaseButton>
      </CLink>
    )
  })
)

export type MobileIconButtonProps = { Icon: IconType } & ChakraProps

export const MobileIconButton = forwardRef<MobileIconButtonProps, 'button'>(function MobileIconLink(
  { Icon, children, ...props },
  ref
) {
  return (
    <>
      <chakra.button ref={ref} color="brandPrimary.500" w="full" {...props}>
        <BaseButton Icon={Icon}>{children}</BaseButton>
      </chakra.button>
    </>
  )
})

type BaseButtonProps = { Icon: IconType } & AspectRatioProps

const BaseButton = forwardRef<BaseButtonProps, 'div'>(function BaseButton(
  { Icon, children, ...props },
  ref
) {
  return (
    <AspectRatio ref={ref} bg="white" borderRadius="2xl" ratio={1} shadow="sm" w="full" {...props}>
      <Center p="2" w="full">
        <Stack alignItems="center" spacing="3" w="full">
          <Icon size="45%" />
          {typeof children === 'string' ? (
            <Text fontWeight="semibold" lineHeight="1.2" textAlign="center">
              {children}
            </Text>
          ) : (
            children
          )}
        </Stack>
      </Center>
    </AspectRatio>
  )
})
