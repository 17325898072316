import {
  BaseClaimSchema,
  BaseLossDetailsSchema,
  ClaimLossTypeSchema,
} from '@eigtech/claims-v2-types'
import { z } from 'zod'

export const BaseClaimWithClaimNumberSchema = BaseClaimSchema.partial()
  .required({
    claimNumber: true,
  })
  .merge(
    z.object({
      lossDetails: BaseLossDetailsSchema.merge(
        z.object({ lossType: ClaimLossTypeSchema.or(z.string()).optional() })
      ).optional(),
    })
  )

export const BaseClaimWithLossDetailsSchema = BaseClaimWithClaimNumberSchema.required({
  lossDetails: true,
})

export const BaseClaimWithLossLocationSchema = BaseClaimWithClaimNumberSchema.required({
  lossLocation: true,
})

export type BaseClaimWithLossDetails = z.infer<typeof BaseClaimWithLossDetailsSchema>
export type BaseClaimWithLossLocation = z.infer<typeof BaseClaimWithLossLocationSchema>
export type BaseClaimWithClaimNumber = z.infer<typeof BaseClaimWithClaimNumberSchema>
