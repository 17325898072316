import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, InitialClaimActionsSchema } from '../model'
import { BaseHeaderSchema } from './base'

const AddInitialClaimActionsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    initialClaimActions: InitialClaimActionsSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    initialClaimActions: InitialClaimActionsSchema.array(),
  }),
}

export const AddInitialClaimActionsRequestSchema = AddInitialClaimActionsSchemas.params
  .merge(AddInitialClaimActionsSchemas.body)
  .merge(AddInitialClaimActionsSchemas.headers)

export type AddInitialClaimActionsBodyRequest = z.infer<typeof AddInitialClaimActionsSchemas.body>
export type AddInitialClaimActionsHeaderRequest = z.infer<
  typeof AddInitialClaimActionsSchemas.headers
>
export type AddInitialClaimActionsRequest = z.infer<typeof AddInitialClaimActionsRequestSchema>
export type AddInitialClaimActionsResponse = z.infer<typeof AddInitialClaimActionsSchemas.response>

export interface AddInitialClaimActionsHandler
  extends Handler<AddInitialClaimActionsRequest, AddInitialClaimActionsResponse> {}

export const AddInitialClaimActionsApiSchema: ApiSchema = AddInitialClaimActionsSchemas
