import { ensureContactCorn } from '@eigtech/contacts-util'
import { queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { assignmentsQueryKeys } from './constants'
import { getAssignablesForAssignee } from './getAssignablesForAssignee'

export function getAssignablesForContactQueryOptions(contactId: string) {
  const contactCorn = ensureContactCorn(contactId)

  return queryOptions({
    queryKey: assignmentsQueryKeys.assignee.detail(contactCorn),
    queryFn: () => getAssignablesForAssignee({ assigneeId: contactCorn }),
    enabled: !!contactId,
  })
}

export const useGetAssignablesForContact = (contactId: string) =>
  useQuery(getAssignablesForContactQueryOptions(contactId))

export function useInvalidateAssignablesForContact() {
  const queryClient = useQueryClient()

  return function invalidateAssignablesForContact(contactId: string) {
    queryClient.invalidateQueries({
      queryKey: getAssignablesForContactQueryOptions(contactId).queryKey,
    })
  }
}
