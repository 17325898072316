import z from 'zod'

import { BaseClaimSchema } from '../model'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim'

export const ClaimCreatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.claimCreated),
    claim: BaseClaimSchema,
  })
)

export type ClaimCreatedEvent = z.infer<typeof ClaimCreatedEventSchema>
