import { cornType } from '@eigtech/shared-corn'
import z from 'zod'

export const notesServiceCorn = <V extends string | undefined>(eventName?: V) =>
  cornType('notes', eventName)

export const NotesServiceCornSchema = notesServiceCorn()
export type NotesServiceCorn = z.infer<typeof NotesServiceCornSchema>

export const NoteCornSchema = notesServiceCorn('note')
export type NoteCorn = z.infer<typeof NoteCornSchema>

export const NotebookCornSchema = notesServiceCorn('notebook')
export type NotebookCorn = z.infer<typeof NotebookCornSchema>
