import z from 'zod'

/**
 * Contains a list of all available job services. This should be used sparingly as a validation tool,
 * as each job will contain a specific allowed subset of this list.
 */
export const JobServiceSchema = z.enum([
  'notImplemented',
  'tarping',
  'waterMitigation',
  'boardUp',
  'interior',
  'exterior',
  'roof',
  'detachedStructures',
])
export const JobService = JobServiceSchema.Values

/**
 * Contains a list of all available job services, not specific to a job type.
 */
export type JobServiceEnum = z.infer<typeof JobServiceSchema>
