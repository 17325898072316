import { Text, TextProps } from '@chakra-ui/react'
import { isString } from 'lodash-es'

export function WrapChildrenWithText({ children, ...props }: TextProps) {
  return typeof children === 'string' || (Array.isArray(children) && children.every(isString)) ? (
    <Text {...props}>{children}</Text>
  ) : (
    <>{children}</>
  )
}
