import { ComponentWithAs, forwardRef, MenuItem, MenuItemProps } from '@chakra-ui/react'

export const DangerMenuItem: ComponentWithAs<'button', MenuItemProps> = forwardRef(
  (
    {
      bgColor = 'danger.50',
      color = 'danger.700',
      _hover = { bgColor: 'danger.100' },
      _focus = { bgColor: 'danger.100' },
      ...props
    },
    ref
  ) => (
    <MenuItem
      ref={ref}
      _focus={_focus}
      _hover={_hover}
      bgColor={bgColor}
      color={color}
      {...props}
    />
  )
)
