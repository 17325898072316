import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { Navigation, NavigationProps } from './Navigation'
import { MdMenu as MenuIcon } from 'react-icons/md'

export type MobileNavigationProps<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
> = Pick<NavigationProps<Action, Subject, Id>, 'heading' | 'navs' | 'bottomNavItems'>

export function MobileNavigation<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
>(props: MobileNavigationProps<Action, Subject, Id>) {
  const drawer = useDisclosure()

  return (
    <>
      <Button
        aria-label={`Open navigation menu`}
        leftIcon={<MenuIcon />}
        p={2}
        variant="ghost"
        onClick={drawer.onToggle}
      >
        Main Menu
      </Button>
      <Drawer
        closeOnEsc
        closeOnOverlayClick
        isOpen={drawer.isOpen}
        placement="left"
        size="nav"
        onClose={drawer.onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="white" />
          <Navigation drawer={drawer} isMobileNav {...props} />
        </DrawerContent>
      </Drawer>
    </>
  )
}
