import { Contact, ContactCommunicationInformation } from '@eigtech/contacts-types'
import { isAPhoneNumber } from '@eigtech/contacts-util'
import { objHasKeys, omitEmptyStringDeep } from '@eigtech/function-utils'
import { cloneDeep } from 'lodash-es'
import { ContactFormContactFields } from '../ContactFormSchema'

export function mapContactToPhones(contact: Partial<Contact>): ContactFormContactFields['phones'] {
  return (
    contact.communicationInformation?.filter(isAPhoneNumber).map((commInfo) => ({
      countryCode: commInfo.countryCode,
      extension: commInfo?.extension,
      extensionPrefix: commInfo?.extensionPrefix ?? 'x',
      id: commInfo.id,
      phone: commInfo?.unformattedTelephoneNumber,
      preferenceOrder: commInfo.preferenceOrder,
      telecommunicationEquipmentType: commInfo.telecommunicationEquipmentType,
      telecommunicationUseCode: commInfo.telecommunicationUseCode,
    })) ?? [{ phone: '', extension: '', extensionPrefix: 'x' }]
  )
}

/**
 * Returns a copy of the incomingCommInfo with added phone entries
 */
export function mapPhonesToContact(
  phones: ContactFormContactFields['phones'],
  primaryPhoneIndex: ContactFormContactFields['primaryPhoneIndex'],
  incomingCommInfo: ContactCommunicationInformation[]
): ContactCommunicationInformation[] {
  const communicationInformation = cloneDeep(incomingCommInfo)

  // adds phones into comm info
  phones
    .filter(({ phone }) => !!phone)
    .forEach(
      (
        {
          countryCode,
          extension,
          extensionPrefix,
          id,
          phone,
          preferenceOrder,
          telecommunicationEquipmentType,
          telecommunicationUseCode,
        },
        index
      ) => {
        const existingIndex = communicationInformation.findIndex(({ id: i }) => i && i === id)
        communicationInformation.push({
          // split phone if it shares the same communication info index as another entry
          id: existingIndex ? undefined : id,
          unformattedTelephoneNumber: phone,
          // toggle preference order to undefined if it is unchecked
          preferenceOrder:
            primaryPhoneIndex === index ? 0 : preferenceOrder === 0 ? undefined : preferenceOrder,
          extensionPrefix:
            isValidExtension(extension) && extensionPrefix ? extensionPrefix : undefined,
          extension: isValidExtension(extension) ? extension : undefined,
          countryCode,
          telecommunicationEquipmentType,
          telecommunicationUseCode,
        })
      }
    )

  return communicationInformation.map(omitEmptyStringDeep).filter(objHasKeys)
}

const isValidExtension = (extension: string | undefined) =>
  extension !== undefined && extension !== ''
