import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { CarrierNameSchema } from '../model'

export const GetCarrierConfigurationSchemas = {
  query: z.object({
    carrier: CarrierNameSchema,
  }),
  response: z.object({
    lossTypes: z.string().array(),
  }),
}

export const GetCarrierConfigurationRequestSchema = GetCarrierConfigurationSchemas.query
export const GetCarrierConfigurationResponseSchema = GetCarrierConfigurationSchemas.response

export type GetCarrierConfigurationRequest = z.infer<typeof GetCarrierConfigurationRequestSchema>
export type GetCarrierConfigurationResponse = z.infer<typeof GetCarrierConfigurationResponseSchema>

export type GetCarrierConfigurationHandler = Handler<
  GetCarrierConfigurationRequest,
  GetCarrierConfigurationResponse
>

export const GetCarrierConfigurationApiSchema: ApiSchema = GetCarrierConfigurationSchemas
