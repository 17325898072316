import {
  Button,
  ButtonProps,
  CloseButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentProps,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  HStack,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import { IS_DEV } from '@eigtech/ui-shared-constants'
import log from '@eigtech/ui-shared-logging'
import { Component, ComponentType, ReactNode } from 'react'
import { SetOptional } from 'type-fest'
import { ComposedAlert } from '../Chakra/Alert'
import { WrapChildrenWithText } from '../Text/WrapChildrenWithText'

export type ComposedDrawerProps = DrawerProps & {
  bodyProps?: ModalBodyProps & Record<`data-${string}`, string>
  closeButtonProps?: CloseButtonProps & Record<`data-${string}`, string>
  closeLabel?: string
  contentProps?: DrawerContentProps & Record<`data-${string}`, string>
  footer?: ReactNode
  footerActionsLeft?: ReactNode
  footerActionsRight?: ReactNode
  footerCloseButtonProps?: ButtonProps & Record<`data-${string}`, string>
  footerProps?: ModalFooterProps & Record<`data-${string}`, string>
  headerProps?: ModalHeaderProps & Record<`data-${string}`, string>
  overlayProps?: ModalOverlayProps
  stackProps?: StackProps
  title: ReactNode
}

export type ComposedDrawerWrapperProps = Omit<ComposedDrawerProps, 'title' | 'children'>

export function ComposedDrawer({
  bodyProps,
  children,
  closeButtonProps,
  closeLabel = 'Close',
  contentProps,
  footer,
  footerActionsLeft,
  footerActionsRight,
  footerCloseButtonProps,
  footerProps,
  headerProps,
  overlayProps,
  stackProps,
  title,
  ...props
}: ComposedDrawerProps) {
  return (
    <Drawer closeOnOverlayClick={false} {...props}>
      <DrawerOverlay {...overlayProps} />
      <DrawerContent {...contentProps}>
        <DrawerCloseButton {...closeButtonProps} />
        <DrawerHeader {...headerProps}>{title}</DrawerHeader>

        <DrawerBody {...bodyProps}>
          <Stack h="full" spacing="6" {...stackProps}>
            <WrapChildrenWithText>{children}</WrapChildrenWithText>
          </Stack>
        </DrawerBody>

        {footer ?? (
          <DrawerFooter as={HStack} {...footerProps}>
            {footerActionsLeft}

            <Button
              id={props.id ? `${props.id}_close` : undefined}
              size="md"
              variant="outline"
              onClick={props.onClose}
              {...footerCloseButtonProps}
            >
              {closeLabel}
            </Button>

            {footerActionsRight}
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export type WithDrawerErrorBoundaryProps = SetOptional<
  ComposedDrawerProps,
  'isOpen' | 'onClose' | 'children'
>

export function withDrawerErrorBoundary<Props extends ComposedDrawerWrapperProps>(
  Component: ComponentType<Props>,
  options: WithDrawerErrorBoundaryProps | ((props: Props) => WithDrawerErrorBoundaryProps)
) {
  return function WithDrawerErrorBoundary(props: Props) {
    return (
      <ComposedDrawerBoundary
        {...props}
        {...(typeof options === 'function' ? options(props) : options)}
      >
        <Component {...props} />
      </ComposedDrawerBoundary>
    )
  }
}

export type ComposedDrawerBoundaryProps<Props extends ComposedDrawerProps> = Props

export type ComposedDrawerBoundaryState = { hasError: boolean }

export class ComposedDrawerBoundary<Props extends ComposedDrawerProps> extends Component<
  ComposedDrawerBoundaryProps<Props>,
  ComposedDrawerBoundaryState
> {
  constructor(props: ComposedDrawerBoundaryProps<Props>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    log.error('Page Error Boundary', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ComposedDrawer {...this.props}>
          <ComposedAlert
            alert={{
              title: 'Whoops! Something went wrong.',
              description: IS_DEV ? (
                <Stack>
                  <Text>Something went wrong in the drawer.</Text>
                  <Text>See console for more details.</Text>
                </Stack>
              ) : (
                <Text>Please try again or contact EIG Tech for help.</Text>
              ),
            }}
            status="error"
          />
        </ComposedDrawer>
      )
    }

    return this.props.children
  }
}
