import { Handler } from 'aws-lambda'
import z from 'zod'

export const UpdateUserRequestPathSchema = z.object({
  userId: z.string(),
})

export const UpdateUserRequestBodySchema = z
  .object({
    email: z.string().optional(),
    name: z.string().optional(),
  })
  .refine((val) => val.email || val.name, { message: 'name or email required' })

export const UpdateUserRequestSchema = UpdateUserRequestBodySchema.and(UpdateUserRequestPathSchema)
export const UpdateUserResponseSchema = z.unknown()

export type UpdateUserRequest = z.infer<typeof UpdateUserRequestSchema>
export type UpdateUserRequestBody = z.infer<typeof UpdateUserRequestBodySchema>
export type UpdateUserRequestPath = z.infer<typeof UpdateUserRequestPathSchema>
export type UpdateUserResponse = z.infer<typeof UpdateUserResponseSchema>

export interface UpdateUserHandler extends Handler<UpdateUserRequest, UpdateUserResponse> {}
