import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { AlertEventSchema } from '../base.js'

export const GetAlertsRequestQuerySchema = z.object({
  level: z.string(),
  nextToken: z.string().optional(),
})

export const GetAlertsRequestSchema = GetAlertsRequestQuerySchema

export const GetAlertsResponseSchema = z.object({
  alerts: AlertEventSchema.array(),
  nextToken: z.string().optional(),
})

export type GetAlertsRequest = z.infer<typeof GetAlertsRequestSchema>
export type GetAlertsResponse = z.infer<typeof GetAlertsResponseSchema>

export type GetAlertsHandler = Handler<GetAlertsRequest, GetAlertsResponse>
