import { HStack, IconButton, Tooltip, useCopyToClipboard } from '@eigtech/ui-shared-dave'
import { LinkButton } from '@eigtech/ui-shared-router'
import { CopyIcon, LinkIcon } from '@eigtech/ui-estimator/components/Core'
import { ROUTER_PATHS } from '../../../../lib/constants'

export function ClaimNumberCell({ value }: { value: string }) {
  const { copyToClipboard } = useCopyToClipboard()
  return (
    <HStack>
      <LinkButton
        _hover={{ textDecoration: 'underline !important' }}
        leftIcon={<LinkIcon />}
        params={{ claimNumber: value }}
        size="sm"
        to={ROUTER_PATHS.claimDetail.route}
        variant="link"
      >
        {value}
      </LinkButton>

      <Tooltip label={`Copy claim number "${value}" to clipboard.`}>
        <IconButton
          aria-label={`Copy claim number "${value}" to clipboard.`}
          icon={<CopyIcon />}
          size="xs"
          variant="outline"
          onClick={() => copyToClipboard(value)}
        />
      </Tooltip>
    </HStack>
  )
}
