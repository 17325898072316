import { ComponentType } from 'react'
import { Forbidden } from '../../Forbidden'
import { HasRequiredPermissions } from '../HasRequiredPermissions'
import { WithRequiredPermissionsProps } from '../types'

export function withRequiredPermissions<
  Action extends string = string,
  Subject extends string = string,
  Props extends {} = {},
>(
  Component: ComponentType<Props>,
  { requiredPermissions }: WithRequiredPermissionsProps<Action, Subject>
) {
  const displayName = Component.displayName || Component.name || 'Component'

  function ComponentWithRequiredPermissions(props: Props) {
    return (
      <HasRequiredPermissions passThrough requiredPermissions={requiredPermissions}>
        {(isAllowed) => (isAllowed ? <Component {...props} /> : <Forbidden />)}
      </HasRequiredPermissions>
    )
  }

  ComponentWithRequiredPermissions.displayName = `withRequiredPermissions(${displayName})`

  return ComponentWithRequiredPermissions
}
