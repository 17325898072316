import {
  Box,
  chakra,
  Divider,
  forwardRef,
  Heading,
  HeadingProps,
  List,
  ListItem,
  Stack,
  StackProps,
} from '@eigtech/ui-shared-dave'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useEffect, useState } from 'react'
import { BsChevronBarLeft as CollapseIcon, BsChevronBarRight as ExpandIcon } from 'react-icons/bs'
import { NavButton } from './NavButton'
import { NavContext, NavContextState, NavProvider, NavProviderProps } from './NavigationContext'
import { NavList } from './NavList'
import { NavId, Navs } from './types'
import { SetOptional } from 'type-fest'

export type BottomNavItemProps = Pick<
  NavContextState,
  'activeNav' | 'setActiveNav' | 'isCollapsed' | 'setIsCollapsed' | 'drawer'
>

export type NavigationProps<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
> = {
  heading?: ReactNode
  headingProps?: HeadingProps
  navs: Navs<Action, Subject, Id> | undefined
  bottomNavItems?: (ReactNode | ((props: BottomNavItemProps) => ReactNode))[]
} & StackProps &
  SetOptional<Omit<NavProviderProps, 'children'>, 'drawer'>

export const Navigation = forwardRef<NavigationProps, 'div'>(
  (
    {
      bottomNavItems,
      activeNav,
      setActiveNav,
      defaultActiveNav,
      heading,
      headingProps,
      isCollapsed,
      isMobileNav,
      navs,
      setIsCollapsed,
      drawer,
      ...props
    },
    ref
  ) => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(() => {
      setHasMounted(true)
    }, [])

    const navProviderProps: Omit<NavProviderProps, 'children'> = {
      activeNav,
      setActiveNav,
      defaultActiveNav,
      isMobileNav,
      isCollapsed,
      setIsCollapsed,
      drawer,
    }

    return (
      <NavProvider {...navProviderProps}>
        <NavContext.Consumer>
          {(context) => {
            const { activeNav, forceCollapse, isCollapsed } = context

            const containerProps = {
              w: isMobileNav ? 'full' : isCollapsed ? 14 : 64,
              transition: 'width 250ms',
              ...props,
            }

            return (
              <>
                <Box {...containerProps} />
                <Stack
                  ref={ref}
                  background="brandPrimary.500"
                  color="white"
                  h="full"
                  justifyContent="space-between"
                  left="0"
                  minH="100%"
                  position="fixed"
                  px="2"
                  py="2"
                  top="0"
                  {...containerProps}
                >
                  <Stack>
                    {!!heading && !isCollapsed && (
                      <>
                        <Box pt={isMobileNav ? '1' : '0'}>
                          {typeof heading === 'string' ? (
                            <Heading
                              fontSize={isMobileNav ? 'lg' : '2xl'}
                              letterSpacing="wider"
                              textAlign="center"
                              textTransform="uppercase"
                              whiteSpace="nowrap"
                              {...(headingProps ?? {})}
                            >
                              {heading}
                            </Heading>
                          ) : (
                            heading
                          )}
                        </Box>

                        <Box px={isMobileNav ? '16' : '8'}>
                          <Divider />
                        </Box>
                      </>
                    )}

                    <chakra.nav position="relative">
                      <AnimatePresence>
                        <motion.div
                          key={activeNav}
                          animate="enter"
                          custom={activeNav}
                          exit="exit"
                          initial={!hasMounted ? false : 'exit'}
                          style={{ position: 'absolute', top: 0, width: '100%' }}
                          variants={navTransitions}
                        >
                          <NavList items={navs?.[activeNav] ?? []} />
                        </motion.div>
                      </AnimatePresence>
                    </chakra.nav>
                  </Stack>

                  <List>
                    {bottomNavItems?.map((item, index) => (
                      <ListItem key={index}>
                        {typeof item === 'function' ? item(context) : item}
                      </ListItem>
                    ))}
                    {!!setIsCollapsed && (
                      <ListItem>
                        {!isMobileNav && !forceCollapse && (
                          <NavButton
                            item={{
                              icon: isCollapsed ? ExpandIcon : CollapseIcon,
                              label: isCollapsed ? 'Expand' : 'Collapse',
                              onClick: () => setIsCollapsed(!isCollapsed),
                            }}
                          />
                        )}
                      </ListItem>
                    )}
                  </List>
                </Stack>
              </>
            )
          }}
        </NavContext.Consumer>
      </NavProvider>
    )
  }
) as <Action extends string = string, Subject extends string = string, Id extends string = string>(
  props: NavigationProps<Action, Subject, Id> & { ref?: React.ForwardedRef<HTMLUListElement> }
) => JSX.Element

const navTransitions = {
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 25,
      stiffness: 180,
    },
  },
  exit: (activeNav: NavId) => ({
    x: activeNav !== 'main' ? 500 : -500,
    opacity: 0,
    transition: {
      duration: 0.15,
      ease: [0.4, 0, 0.2, 1],
    },
  }),
}
