import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ImportStatusesSchema } from './importStatus'

export const GetClaimAssignmentStatusRequestQueryStringSchema = z.object({
  claimNumber: z.string().optional(),
  transactionId: z.string().optional(),
})

export const GetClaimAssignmentStatusRequestSchema =
  GetClaimAssignmentStatusRequestQueryStringSchema

export const GetClaimAssignmentStatusResponseSchema = z.record(z.string(), ImportStatusesSchema)

export type GetClaimAssignmentStatusRequest = z.infer<typeof GetClaimAssignmentStatusRequestSchema>
export type GetClaimAssignmentStatusResponse = z.infer<
  typeof GetClaimAssignmentStatusResponseSchema
>

export type GetClaimAssignmentStatusHandler = Handler<
  GetClaimAssignmentStatusRequest,
  GetClaimAssignmentStatusResponse
>

export const GetClaimAssignmentStatusApiSchema: ApiSchema = {
  query: GetClaimAssignmentStatusRequestQueryStringSchema,
  response: GetClaimAssignmentStatusResponseSchema,
}
