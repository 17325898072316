import { Box, DebouncedInput, HStack, Text } from '@eigtech/ui-shared-dave'
import { RowData } from '@tanstack/react-table'
import { getColumnTitle } from '../utils'
import { DataGridFilterContainer } from './DataGridFilterContainer'
import { WithColumnProps } from '../../types'

export function DataGridNumberRangeFilter<TData extends RowData, TValue>({
  column,
}: WithColumnProps<TData, TValue>) {
  const columnFilterValue = column.getFilterValue() as [number, number]

  return (
    <Box>
      <Text fontStyle="italic">{getColumnTitle(column)}</Text>
      <HStack>
        <DataGridFilterContainer column={column} label="Min:">
          <DebouncedInput
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ''
            }`}
            type="number"
            value={columnFilterValue?.[0] ?? ''}
            onChange={(event) => {
              const value = event.target.value
              column.setFilterValue((old: [number, number]) => [value, old?.[1]])
            }}
          />
        </DataGridFilterContainer>
        <DataGridFilterContainer column={column} label="Max:">
          <DebouncedInput
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ''
            }`}
            type="number"
            value={columnFilterValue?.[1] ?? ''}
            onChange={(event) => {
              const value = event.target.value
              column.setFilterValue((old: [number, number]) => [old?.[0], value])
            }}
          />
        </DataGridFilterContainer>
      </HStack>
    </Box>
  )
}
