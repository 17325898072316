import { ClaimLossOfUseDetails } from '@eigtech/summarizer-types'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { startCase } from 'lodash-es'

export function LossOfUseDetails(props: EventDetailsComponentProps<ClaimLossOfUseDetails>) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const { type, durationInDays, amountRequested, description, requestedDate } =
    props.details.lossOfUse

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text as="span">{`$${amountRequested} requested for ${durationInDays} days of `}</Text>
        <Tag>{startCase(type)}</Tag>
        <Text as="span">on</Text>
        <PreferredDateTimeComponent property="requestedDate">
          {requestedDate}
        </PreferredDateTimeComponent>
      </HStack>

      {!!description && <Blockquote whiteSpace="preserve">{description}</Blockquote>}
    </VStack>
  )
}
