import { Handler } from 'aws-lambda'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { z } from 'zod'
import { CornSchema } from '@eigtech/shared-corn'

// get document url
export const GetDocumentUrlPathRequestSchema = z.object({
  entityId: CornSchema,
  documentCorn: z.string(),
})
export const GetDocumentUrlHeadersRequestSchema = ApiLambdalerAuthHeaderSchema
export const GetDocumentUrlRequestSchema = GetDocumentUrlPathRequestSchema.merge(
  GetDocumentUrlHeadersRequestSchema
)

export type GetDocumentUrlPathRequest = z.infer<typeof GetDocumentUrlPathRequestSchema>

export type GetDocumentUrlRequest = z.infer<typeof GetDocumentUrlRequestSchema>

export type GetDocumentUrlResponse = string
export type GetDocumentUrlHandler = Handler<GetDocumentUrlRequest, GetDocumentUrlResponse>
