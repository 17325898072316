import {
  ClaimAssigneeRelationshipCanon,
  GetAssignmentsForAssignableResponse,
} from '@eigtech/assignments-types'
import { Contact } from '@eigtech/contacts-types'
import { GetClaimDetailsResponse, GetClaimSummariesResponse } from '@eigtech/csr-types'
import {
  EstimatorClaimDetailsSchema,
  GetEstimatorAssignedClaimsResponse,
  GetEstimatorClaimResponse,
} from '@eigtech/estimator-types'
import { ArrayElement } from '@eigtech/function-utils'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { useApiInstanceContext, useMutation, useQueryClient } from '@eigtech/ui-shared-api'
import {
  claimsQueryKeys,
  useInvalidateClaim,
  useInvalidateClaimsSummaries,
} from '@eigtech/ui-shared-claims'
import { getContactName } from '@eigtech/ui-shared-contacts'
import log from '@eigtech/ui-shared-logging'
import { useCallback } from 'react'
import { assign } from './assign'
import { makeClaimAssignment } from './assignEntityToClaim'
import { assignmentsQueryKeys } from './constants'
import { useInvalidateAssignmentsForEntity } from './getAssignmentsForEntity'

export function useOptimisticallyUpdateAssignedClaimContact<T extends 'csr' | 'estimator'>(
  responseType: T
) {
  const queryClient = useQueryClient()
  const invalidateClaimsSummaries = useInvalidateClaimsSummaries()
  const invalidateClaim = useInvalidateClaim()
  const invalidateAssignmentsForClaim = useInvalidateAssignmentsForEntity()

  const onMutate = useCallback(
    async ({
      claimNumber,
      contact,
      type,
    }: {
      claimNumber: string
      contact: Contact | undefined
      type: ClaimAssigneeRelationshipCanon
    }) => {
      const claimCorn = ensureClaimCorn(claimNumber)

      const basicContact = !!contact
        ? {
            id: contact.contactId,
            name: getContactName(contact),
          }
        : undefined

      // update claims list
      const listQueryKey = claimsQueryKeys.list()
      await queryClient.cancelQueries({ queryKey: listQueryKey })

      type SummaryResponse = typeof responseType extends 'csr'
        ? GetClaimSummariesResponse
        : GetEstimatorAssignedClaimsResponse
      const previousClaims = queryClient.getQueriesData<SummaryResponse>({ queryKey: listQueryKey })

      previousClaims.forEach(([key, data]) => {
        if (!data) return

        if ('claims' in data) {
          queryClient.setQueryData<GetClaimSummariesResponse>(key, {
            ...data,
            claims: (data?.claims ?? []).map((claim) =>
              claimNumber === claim.claimNumber
                ? {
                    ...claim,
                    [type]: basicContact,
                  }
                : claim
            ),
          })
        } else {
          queryClient.setQueryData<GetEstimatorAssignedClaimsResponse>(
            key,
            data.map((claim) =>
              claimNumber === claim.claimNumber
                ? {
                    ...claim,
                    [type]: basicContact,
                  }
                : claim
            )
          )
        }
      })

      // update claim detail
      const detailQueryKey = claimsQueryKeys.detail(claimNumber)
      await queryClient.cancelQueries({ queryKey: detailQueryKey })

      type DetailResponse = typeof responseType extends 'csr'
        ? GetClaimDetailsResponse
        : GetEstimatorClaimResponse
      const previousClaimDetail = queryClient.getQueryData<DetailResponse>(detailQueryKey)

      if (!!previousClaimDetail) {
        if (isEstimatorResponse(previousClaimDetail)) {
          queryClient.setQueryData<GetEstimatorClaimResponse>(detailQueryKey, {
            ...previousClaimDetail,
            [type]: contact,
          })
        } else {
          queryClient.setQueryData<GetClaimDetailsResponse>(detailQueryKey, {
            ...previousClaimDetail,
            [type]: basicContact,
          })
        }
      }

      const assignmentQueryKey = assignmentsQueryKeys.assignable.detail(claimCorn)
      await queryClient.cancelQueries({ queryKey: assignmentQueryKey })
      const previousAssignment =
        queryClient.getQueryData<GetAssignmentsForAssignableResponse>(assignmentQueryKey)

      if (!!previousAssignment) {
        const updatedAssignment = [
          ...previousAssignment.filter(
            (assignment) =>
              assignment.assignable.type === 'claim' &&
              assignment.assignable.assigneeRelationship !== type
          ),
        ]

        if (!!contact) {
          const claimAssignment = {
            ...makeClaimAssignment({
              assigneeRelationship: type,
              claimNumber,
              entity: contact,
              type: 'contact',
            }),
            assignmentDate: new Date().toISOString(),
            requestActor: 'user',
          } as ArrayElement<GetAssignmentsForAssignableResponse>
          updatedAssignment.push(claimAssignment)
        }

        queryClient.setQueryData<GetAssignmentsForAssignableResponse>(
          assignmentQueryKey,
          updatedAssignment
        )
      }

      return {
        previousClaims,
        previousClaimDetail,
      }
    },
    [queryClient]
  )

  const onError = useCallback(
    (
      error: unknown,
      { claimNumber }: { claimNumber: string },
      context: Awaited<ReturnType<typeof onMutate>> | undefined
    ) => {
      log.error('could not set claim primary', { error })

      const claimCorn = ensureClaimCorn(claimNumber)

      context?.previousClaims.forEach(([key, data]) => {
        queryClient.setQueryData(key, data)
      })

      if (context?.previousClaimDetail) {
        queryClient.setQueryData(
          claimsQueryKeys.detail(context.previousClaimDetail.claimNumber),
          context.previousClaimDetail
        )
      }

      invalidateClaimsSummaries()
      invalidateClaim(claimNumber)
      invalidateAssignmentsForClaim(claimCorn)
    },
    [invalidateAssignmentsForClaim, invalidateClaim, invalidateClaimsSummaries, queryClient]
  )

  const onSuccess = useCallback(
    (claimNumber: string) => {
      const claimCorn = ensureClaimCorn(claimNumber)

      // invalidate after a few seconds on success
      // (it can take a lil bit for events on backend to catch up)
      setTimeout(() => {
        invalidateAssignmentsForClaim(claimCorn)
        invalidateClaimsSummaries()
        invalidateClaim(claimNumber)
      }, 30000)
    },
    [invalidateAssignmentsForClaim, invalidateClaim, invalidateClaimsSummaries]
  )

  return { onMutate, onError, onSuccess }
}

export type AssignContactToClaimProps = {
  assigneeRelationship: Exclude<ClaimAssigneeRelationshipCanon, 'job'>
  claimNumber: string
  contact: Contact
}

export function useAssignContactToClaim<T extends 'csr' | 'estimator'>(responseType: T) {
  const { post } = useApiInstanceContext()
  const { onError, onMutate, onSuccess } = useOptimisticallyUpdateAssignedClaimContact(responseType)

  return useMutation({
    mutationFn: (props: AssignContactToClaimProps) => {
      return assign(post)(makeClaimAssignment({ ...props, type: 'contact', entity: props.contact }))
    },
    onMutate({ assigneeRelationship, claimNumber, contact }) {
      return onMutate({
        claimNumber,
        type: assigneeRelationship,
        contact,
      })
    },
    onSuccess(__, { claimNumber }) {
      onSuccess(claimNumber)
    },
    onError,
  })
}

const isEstimatorResponse = (
  value: GetClaimDetailsResponse | GetEstimatorClaimResponse
): value is GetEstimatorClaimResponse => EstimatorClaimDetailsSchema.safeParse(value).success
