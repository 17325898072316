import {
  Button,
  ButtonGroup,
  Center,
  CircularProgress,
  ComposedCard,
  Stack,
  WrapChildrenWithText,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import React, { ReactNode, Suspense, SuspenseProps } from 'react'

export type SuspensefulQueryProps = SuspenseProps &
  Partial<Omit<QueryErrorBoundaryProps, 'children'>>

export function SuspensefulQuery({ children, fallback, ...boundaryProps }: SuspensefulQueryProps) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <QueryErrorBoundary onReset={reset} {...boundaryProps}>
          <Suspense
            fallback={
              fallback ?? (
                <Center>
                  <CircularProgress isIndeterminate size={100} />
                </Center>
              )
            }
          >
            {children}
          </Suspense>
        </QueryErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

type QueryErrorBoundaryProps = {
  children: React.ReactNode
  disableRetry?: boolean
  error?: ReactNode
  errorLink?: ReactNode
  onReset: () => void
}

type QueryErrorBoundaryState = { hasError: boolean }

class QueryErrorBoundary extends React.Component<QueryErrorBoundaryProps, QueryErrorBoundaryState> {
  constructor(props: QueryErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    log.error('Page Error Boundary', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ComposedCard heading="Whoops!">
          <Stack spacing="4">
            <WrapChildrenWithText>
              {this.props.error ||
                'Something unexpected happened and caused an error. Please try again or contact the EIG Tech team for help.'}
            </WrapChildrenWithText>

            <Center>
              <ButtonGroup>
                {!this.props.disableRetry && <Button onClick={this.props.onReset}>Retry</Button>}
                {this.props.errorLink ?? (
                  <Button as="a" href="/">
                    Go Home
                  </Button>
                )}
              </ButtonGroup>
            </Center>
          </Stack>
        </ComposedCard>
      )
    }

    return this.props.children
  }
}
