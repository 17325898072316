import { chakra, Box, BoxProps, forwardRef } from '@eigtech/ui-shared-dave'
import { Microphone } from './Mic'

export type MicVolumeIndicatorProps = {
  /* Whether or not the attendee is muted */
  muted?: boolean | undefined
  /* The measure of an attendee's network connection on a scale of 0 to 1.
  A bad connection is .5 or below. */
  signalStrength: number | undefined
} & BoxProps

export const MicVolumeIndicator = forwardRef<MicVolumeIndicatorProps, 'div'>(
  ({ muted = false, signalStrength, ...props }, ref) => {
    const poorConnection = signalStrength !== undefined && signalStrength <= 0.5

    return (
      <Box lineHeight={0} position="relative" {...props}>
        <Microphone muted={muted} poorConnection={poorConnection} position="relative" zIndex="2" />
        <chakra.div
          borderRadius="20%"
          bottom="41%"
          height="38%"
          left="40%"
          overflow="hidden"
          position="absolute"
          width="21%"
        >
          <chakra.div
            ref={ref}
            backgroundColor={!!signalStrength && signalStrength <= 0.5 ? 'red.500' : 'white'}
            bottom="0"
            left="0"
            position="absolute"
            right="0"
            top="0"
            transformOrigin="bottom"
            willChange="transform"
          />
        </chakra.div>
      </Box>
    )
  }
)
