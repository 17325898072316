import { z } from 'zod'
import { BaseClaimSchema } from '../model'
import {
  ClosedOutcomeInternalReasonSchema,
  ClosedOutcomeReasonSchema,
  ClosedOutcomeSchema,
  OutcomeSchema,
  OutcomeTypeEnum,
  ReopenedOutcomeSchema,
} from '../model/outcome'
import { ClaimEventSchema, claimEventType } from './claim'

export const OutcomeAddedEventSchema = ClaimEventSchema.merge(
  z.object({
    claim: BaseClaimSchema.merge(
      z.object({
        outcome: OutcomeSchema,
      })
    ),
    metadata: ClaimEventSchema.shape.metadata.and(
      z.object({
        reason: ClosedOutcomeReasonSchema.or(ClosedOutcomeInternalReasonSchema).or(
          z.string().optional()
        ),
      })
    ),
  })
)

export const ClaimClosedEventSchema = OutcomeAddedEventSchema.merge(
  z.object({
    claim: BaseClaimSchema.merge(
      z.object({
        outcome: ClosedOutcomeSchema,
      })
    ),
    metadata: ClaimEventSchema.shape.metadata.and(
      z.object({
        reason: ClosedOutcomeReasonSchema.or(z.string()),
        publishToXa: z.boolean(),
      })
    ),
    type: claimEventType(OutcomeTypeEnum.closed),
  })
)

export const ClaimReopenedEventSchema = OutcomeAddedEventSchema.merge(
  z.object({
    type: claimEventType(OutcomeTypeEnum.reopened),
    claim: BaseClaimSchema.merge(
      z.object({
        outcome: ReopenedOutcomeSchema,
      })
    ),
  })
)

export type OutcomeAddedEvent = z.infer<typeof OutcomeAddedEventSchema>
export type ClaimClosedEvent = z.infer<typeof ClaimClosedEventSchema>
export type ClaimReopenedEvent = z.infer<typeof ClaimReopenedEventSchema>
