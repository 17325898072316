import { InspectionDetails } from '@eigtech/claims-v2-types'
import { useGetClaim, useGetClaimInspections } from '@eigtech/ui-estimator/api'
import { ROUTER_PATHS } from '@eigtech/ui-estimator/lib/constants'
import { InspectionCalendar, ScheduleInspectionDrawer } from '@eigtech/ui-shared-claims'
import { endOfMonth, startOfMonth } from '@eigtech/ui-shared-dates'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  ComposedCard,
  HStack,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { getRouteApi, useNavigate } from '@eigtech/ui-shared-router'
import { useMemo } from 'react'

const Route = getRouteApi(ROUTER_PATHS.claimDetailInspections.routeId)

export function ClaimDetailInspections() {
  const { claimNumber } = Route.useParams()
  const { data: claim } = useGetClaim(claimNumber)

  const { selectedMonth } = Route.useSearch()
  const startOfMonthDate = !!selectedMonth ? new Date(selectedMonth) : startOfMonth(new Date())
  const endOfMonthDate = endOfMonth(startOfMonthDate)

  const navigate = useNavigate()

  function setCurrentMonth(month: Date) {
    navigate({
      search: (prev: Record<string, any>) => ({
        ...prev,
        selectedMonth: month.toISOString(),
      }),
      replace: true,
    })
  }

  const scheduleInspectionDrawer = useDisclosure()

  const { data, isPending: isPendingInspections } = useGetClaimInspections(claimNumber, {
    filter: [
      {
        field: 'scheduledDate',
        operator: '>=',
        value: startOfMonthDate.toISOString(),
      },
      {
        field: 'scheduledDate',
        operator: '<=',
        value: endOfMonthDate.toISOString(),
      },
    ],
  })

  const { data: requestedInspections, isPending: isPendingRequested } = useGetClaimInspections(
    claimNumber,
    {
      filter: [
        {
          field: 'status',
          operator: 'ANYOF',
          value: ['requested'],
        },
      ],
    }
  )

  const isPending = isPendingInspections || isPendingRequested

  const inspections = useMemo(
    () =>
      Object.values({
        ...data?.inspections,
        ...requestedInspections?.inspections,
      })
        .filter(
          (inspection): inspection is InspectionDetails & { entityId: string } =>
            !!(inspection as InspectionDetails & { entityId?: string }).entityId
        )
        .map((inspection) => ({
          ...inspection,
          claimNumber: inspection.entityId,
        })),
    [data?.inspections, requestedInspections?.inspections]
  )

  return (
    <>
      <ComposedCard
        actions={
          <HStack>
            {isPending && <CircularProgress isIndeterminate size={6} />}
            {!claim?.isReadOnly && (
              <ButtonGroup size="sm">
                <Button onClick={scheduleInspectionDrawer.onOpen}>Schedule Inspection</Button>
              </ButtonGroup>
            )}
          </HStack>
        }
        heading="Inspections"
      >
        <InspectionCalendar
          currentClaimNumber={claimNumber}
          inspections={inspections}
          selectedMonth={startOfMonthDate}
          onSelectedMonthChange={(date) => setCurrentMonth(date)}
        />
      </ComposedCard>

      {scheduleInspectionDrawer.isOpen && (
        <ScheduleInspectionDrawer {...scheduleInspectionDrawer} claimNumber={claimNumber} />
      )}
    </>
  )
}
