import { Box, BoxProps, forwardRef } from '@eigtech/ui-shared-dave'
import {
  useActiveSpeakersState,
  useContentShareState,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react'
import { pick } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MeetingState, useMeetingStore } from '../../context'
import { useFilteredRoster } from '../../hooks'
import { ContentShare } from './ContentShare'
import { RemoteVideo } from './RemoteVideo'
import { VideoTile } from './VideoTile'
import { LocalVideo } from './LocalVideo'

export type ActiveVideoProps = BoxProps

export const ActiveVideo = forwardRef<ActiveVideoProps, 'div'>(function ActiveVideo(props, ref) {
  const { chimeAttendee, participant, selectedSpeaker } = useMeetingStore(stateSelector)
  const roster = useFilteredRoster()

  const activeSpeakers = useActiveSpeakersState()

  const { everyoneButCurrentUser, everyoneButCurrentUserDict } = useMemo(() => {
    const everyoneButCurrentUser = Object.entries(roster).filter(
      ([__, attendee]) => attendee.externalUserId !== participant?.email
    )
    const everyoneButCurrentUserDict = Object.fromEntries(everyoneButCurrentUser)

    return { everyoneButCurrentUser, everyoneButCurrentUserDict }
  }, [roster, participant])

  const getActiveSpeaker = useCallback(() => {
    if (!everyoneButCurrentUser.length) {
      return null
    }

    if (!activeSpeakers.length) {
      return everyoneButCurrentUser[0]?.[1] ?? null
    }

    return everyoneButCurrentUserDict[activeSpeakers[0]] ?? null
  }, [activeSpeakers, everyoneButCurrentUser, everyoneButCurrentUserDict])

  const [lastSpeaker, setLastSpeaker] = useState(getActiveSpeaker())

  useEffect(() => {
    setLastSpeaker((current) => {
      if (!everyoneButCurrentUser.length) return null

      const activeSpeaker = getActiveSpeaker()

      return activeSpeaker ?? current
    })
  }, [everyoneButCurrentUser, getActiveSpeaker, roster])

  const { attendeeIdToTileId } = useRemoteVideoTileState()

  const { tileId: contentTileId, sharingAttendeeId } = useContentShareState()

  const activeSpeaker = useMemo(() => {
    return !!(selectedSpeaker && roster[selectedSpeaker])
      ? selectedSpeaker
      : lastSpeaker?.chimeAttendeeId
  }, [selectedSpeaker, roster, lastSpeaker])

  const activeSpeakerIsSelf = chimeAttendee?.AttendeeId === activeSpeaker

  const activeVideo = useMemo(() => {
    return !!activeSpeaker ? attendeeIdToTileId[activeSpeaker] : null
  }, [attendeeIdToTileId, activeSpeaker])

  return (
    <Box ref={ref} {...props}>
      {!!contentTileId ? (
        <ContentShare
          attendeeId={sharingAttendeeId ?? ''}
          fontSize="clamp(1.2rem, 1.5vw, 2rem)"
          w="full"
        />
      ) : (
        !!activeSpeaker &&
        (activeSpeakerIsSelf ? (
          <LocalVideo isActiveVideo={true} w="full" />
        ) : !!activeVideo ? (
          <RemoteVideo attendeeId={activeSpeaker} tileId={activeVideo} w="full" />
        ) : (
          <VideoTile attendeeId={activeSpeaker} w="full" />
        ))
      )}
    </Box>
  )
})

const stateSelector = (state: MeetingState) =>
  pick(state, ['chimeAttendee', 'participant', 'selectedSpeaker'])
