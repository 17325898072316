import { Center, chakra, forwardRef } from '@chakra-ui/react'
import { isSameDay } from '@eigtech/ui-shared-dates'
import { ComposedAlertProps } from '../Chakra/Alert'
import { Emoji } from '../Emoji'
import { PartyAlert } from './PartyAlert'

export type BirthdayAlertProps = Partial<ComposedAlertProps> & { birthdate?: string; name?: string }

export const BirthdayAlert = forwardRef<BirthdayAlertProps, 'div'>(function BirthdayAlert(
  { birthdate = '', name, ...props },
  ref
) {
  const regex = /^\d{4}(\/|\-|\.)(0?[1-9]|1[012])(\/|\-|\.)(0?[1-9]|[12][0-9]|3[01])$/
  const isCorrectDate = regex.test(birthdate)

  if (!isCorrectDate) return null

  const [__, month, day] = birthdate.split(/\-|\.\//).map((part) => parseInt(part))
  const today = new Date()
  const birthDayDate = new Date(today.getFullYear(), month - 1, day)
  const isBirthDay = isSameDay(birthDayDate, Date.now())

  if (!isBirthDay) return null

  return (
    <Center left="0" pointerEvents="none" position="fixed" right="0" top="6">
      <PartyAlert
        ref={ref}
        alert={message(name)}
        autoClose={60000}
        maxW="container.text"
        pointerEvents="auto"
        {...props}
      />
    </Center>
  )
})

const message = (name?: string) => (
  <chakra.span>
    <Emoji symbol="🎉" />
    <Emoji symbol="🕺" />
    <Emoji symbol="🎉" /> Happy Birthday{!!name && `, ${name}`}! <Emoji symbol="🎉" />
    <Emoji symbol="🕺" />
    <Emoji symbol="🎉" />
  </chakra.span>
)
