import { JobEventTypeSchema } from '@eigtech/restoration-types'
import z from 'zod'

export const JobRestorationChronicleTypeSchema = JobEventTypeSchema
export const JobRestorationChronicleType = JobRestorationChronicleTypeSchema.Enum
export type JobRestorationChronicleTypeEnum = z.infer<typeof JobRestorationChronicleTypeSchema>

export const JobAssignmentChronicleTypeSchema = z.enum([
  'approverAssignedToJob',
  'approverUnassignedFromJob',
  'coordinatorAssignedToJob',
  'coordinatorUnassignedFromJob',
  'dispatcherAssignedToJob',
  'dispatcherUnassignedFromJob',
  'fieldTechnicianAssignedToJob',
  'fieldTechnicianUnassignedFromJob',
  'jobAssignedToClaim',
  'jobUnassignedFromClaim',
  'primaryContactAssignedToJob',
  'primaryContactUnassignedFromJob',
  'projectManagerAssignedToJob',
  'projectManagerUnassignedFromJob',
  'subcontractorAssignedToJob',
  'subcontractorUnassignedFromJob',
])
export const JobAssignmentChronicleType = JobAssignmentChronicleTypeSchema.Enum
export type JobAssignmentChronicleTypeEnum = z.infer<typeof JobAssignmentChronicleTypeSchema>

export type JobChronicleTypeEnum = JobRestorationChronicleTypeEnum | JobAssignmentChronicleTypeEnum
