import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'

export const UpdateContactRequestPathSchema = z.object({ contactId: ContactCornSchema })
export const UpdateContactRequestBodySchema = ContactSchema.omit({ contactId: true })

export const UpdateContactRequestSchema = UpdateContactRequestBodySchema.merge(
  UpdateContactRequestPathSchema
)

export type UpdateContactPath = z.infer<typeof UpdateContactRequestPathSchema>
export type UpdateContactRequestBody = z.infer<typeof UpdateContactRequestBodySchema>
export type UpdateContactRequest = z.infer<typeof UpdateContactRequestSchema>

export type UpdateContactResponse = void

export interface UpdateContactHandler
  extends Handler<UpdateContactRequest, UpdateContactResponse> {}

export const UpdateContactApiSchema: ApiSchema = {
  body: UpdateContactRequestBodySchema,
  params: UpdateContactRequestPathSchema,
}
