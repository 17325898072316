import { Text, TextProps, forwardRef } from '@chakra-ui/react'

export const Stamp = forwardRef<TextProps, 'span'>(function Stamp({ children, ...props }, ref) {
  const color = props.color || 'red.500'

  return (
    <Text
      ref={ref}
      as="span"
      backgroundColor="gray.50"
      borderColor={color}
      borderRadius="md"
      borderStyle="solid"
      borderWidth="0.25em"
      color={color}
      fontSize="4xl"
      fontWeight="bold"
      lineHeight={1}
      opacity={0.7}
      outline="0.1em solid"
      outlineColor={color}
      outlineOffset="0.1em"
      pointerEvents="none"
      px="2"
      py="2"
      textTransform="uppercase"
      {...props}
    >
      {children}
    </Text>
  )
})
