import { Handler } from 'aws-lambda'
import { z } from 'zod'
import {
  MessageGroupMapSchema,
  MessageGroupIdSchema,
  MessageGroupSchema,
} from '../../base/index.js'

export const ListMessageGroupsRequestPathSchema = z.object({
  groupId: MessageGroupIdSchema.optional(),
})

export const ListMessageGroupsRequestSchema = ListMessageGroupsRequestPathSchema
export type ListMessageGroupsRequest = z.infer<typeof ListMessageGroupsRequestSchema>

export const ListMessageGroupsResponseSchema = MessageGroupMapSchema.or(
  MessageGroupSchema.or(z.undefined())
)

export type ListMessageGroupsResponse = z.infer<typeof ListMessageGroupsResponseSchema>

export interface ListMessageGroupsHandler
  extends Handler<ListMessageGroupsRequest, ListMessageGroupsResponse> {}
