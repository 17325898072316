import { ReactNode } from 'react'
import { FieldValues } from 'react-hook-form'
import { SelectControl, SelectControlProps } from 'react-hook-form-chakra'
import type { RequireAtLeastOne } from 'type-fest'
import { WithFieldNameProp } from '../types/FieldProps'

export type SelectFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  WithFieldNameProp<SelectControlProps, TFieldValues>,
  'placeholder' | 'children'
> &
  RequireAtLeastOne<
    {
      children?: ReactNode
      options?: { value: string; label?: string }[] | string[]
      placeholder?: string | false
    },
    'children' | 'options'
  >

export function SelectField<TFieldValues extends FieldValues = FieldValues>({
  children,
  options,
  placeholder,
  ...props
}: SelectFieldProps<TFieldValues>) {
  return (
    <SelectControl
      selectProps={{
        placeholder: placeholder === false ? undefined : placeholder || 'Select an option',
        isDisabled: props.isDisabled,
        isReadOnly: props.isReadOnly,
        ...props.selectProps,
      }}
      {...props}
    >
      {children ??
        (options ?? []).map((option) => {
          const { value, label } =
            typeof option === 'string' ? { value: option, label: option } : option

          return (
            <option key={value} value={value}>
              {label ?? value}
            </option>
          )
        })}
    </SelectControl>
  )
}

export type SelectFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof SelectField<TFieldValues>
