import { ContactCornSchema } from '@eigtech/contacts-types'
import { EventBase, EventBaseSchema, eventType } from '@eigtech/event-stream-types'
import z from 'zod'

const tplapServiceName = 'tplap'
const tplapServiceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(tplapServiceName, entity, eventName)

const TplapServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: tplapServiceEventType(),
  })
)

const tplapPortalEventType = <V extends string | undefined>(eventName?: V) =>
  tplapServiceEventType('portal', eventName)

export const DeprecatedTplapPortalStatusSchema = z.enum([
  'ACTIVE',
  'EXPIRED',
  'INACTIVE',
  'ACTIVATING',
])
export const DEPRECATED_TPLAP_PORTAL_STATUS = DeprecatedTplapPortalStatusSchema.Values

export type DeprecatedTplapPortalStatus = z.infer<typeof DeprecatedTplapPortalStatusSchema>

export const DeprecatedTplapPortalSchema = z.object({
  auth0Id: z.string(),
  contactId: ContactCornSchema,
  claimNumber: z.string(),
  dateCreated: z.string().datetime(),
  dateExpired: z.string().datetime(),
  dateModified: z.string().datetime(),
  expiresAt: z.number(),
  createdBy: z.string(),
  modifiedBy: z.string(),
  status: DeprecatedTplapPortalStatusSchema,
})

export type DeprecatedTplapPortal = z.infer<typeof DeprecatedTplapPortalSchema>

export const DeprecatedTplapPortalEventSchema = TplapServiceEventBaseSchema.merge(
  z.object({
    type: tplapPortalEventType(),
    portal: DeprecatedTplapPortalSchema,
  })
)

export const DeprecatedTplapPortalActivatedEventSchema = DeprecatedTplapPortalEventSchema.merge(
  z.object({
    type: tplapPortalEventType('activated'),
  })
)

export const DeprecatedTplapPortalDeactivatedEventSchema = DeprecatedTplapPortalEventSchema.merge(
  z.object({
    type: tplapPortalEventType('deactivated'),
  })
)

export const DeprecatedTplapPortalExpiredEventSchema = DeprecatedTplapPortalEventSchema.merge(
  z.object({
    type: tplapPortalEventType('expired'),
  })
)

export const isDeprecatedTplapPortalEvent = (event: EventBase) =>
  DeprecatedTplapPortalEventSchema.safeParse(event).success

export type DeprecatedTplapPortalEvent = z.infer<typeof DeprecatedTplapPortalEventSchema>
export type DeprecatedTplapPortalActivatedEvent = z.infer<
  typeof DeprecatedTplapPortalActivatedEventSchema
>
export type DeprecatedTplapPortalDeactivatedEvent = z.infer<
  typeof DeprecatedTplapPortalDeactivatedEventSchema
>
export type DeprecatedTplapPortalExpiredEvent = z.infer<
  typeof DeprecatedTplapPortalExpiredEventSchema
>
