import { formatStringAsDate, STANDARD_DATE_TIME_FORMAT } from '@eigtech/ui-shared-dates'
import {
  chakra,
  formatArrayAsStringList,
  forwardRef,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
  WrapItemProps,
  WrapProps,
} from '@eigtech/ui-shared-dave'
import { BuiltInFilterFn } from '@tanstack/react-table'
import { isFinite, isObjectLike } from 'lodash-es'
import { ReactNode } from 'react'
import { useDataGridContext } from '../../DataGridContext'
import { FilterFnName } from '../../types'
import { getColumnTitle, getFilterActualName } from '../utils'

export const DataGridFilterList = forwardRef<WrapProps, 'ul'>((props, ref) => {
  const { table } = useDataGridContext()

  const { columnFilters, globalFilter } = table.getState()

  return (
    <Wrap ref={ref} as="ul" {...props}>
      {!!globalFilter && (
        <FilterTag
          isGlobal
          label={
            <>
              <chakra.span fontStyle="italic" fontWeight="bold">
                Global
              </chakra.span>{' '}
              {!!table.options.meta?.globalFilterLabel
                ? table.options.meta?.globalFilterLabel(globalFilter)
                : getText({ filterName: table.getGlobalFilterFn()?.name, value: globalFilter })}
            </>
          }
          onClick={() => {
            table.setGlobalFilter(undefined)
          }}
        ></FilterTag>
      )}

      {columnFilters.map(({ id, value }) => {
        const column = table.getColumn(id)
        const filterName = getFilterActualName(column?.getFilterFn()?.name ?? '') as BuiltInFilterFn

        const text = () => {
          const filterLabelFn =
            column?.columnDef.meta?.filterLabel ??
            table.options.meta?.filterLabels?.[filterName] ??
            table.options.meta?.filterLabels?.[column?.getFilterFn()?.name ?? '']

          if (!!filterLabelFn && column) {
            return filterLabelFn(value, column)
          }

          return getText({ filterName, value })
        }

        return (
          <FilterTag
            key={column?.id ?? ''}
            label={
              <>
                <chakra.span fontStyle="italic">{column ? getColumnTitle(column) : ''}</chakra.span>{' '}
                {text()}
              </>
            }
            onClick={() => {
              column?.setFilterValue(undefined)
            }}
          ></FilterTag>
        )
      })}
    </Wrap>
  )
})

type FilterTagProps = WrapProps & {
  isGlobal?: boolean
  label: ReactNode
  onClick: () => void
}

const FilterTag = forwardRef<FilterTagProps, 'li'>(
  ({ isGlobal, label, onClick, ...props }, ref) => {
    return (
      <WrapItem ref={ref} as="li" {...(props as WrapItemProps)}>
        <Tag
          borderRadius="lg"
          {...(isGlobal ? { bgColor: 'brandPrimary.600', color: 'white' } : {})}
        >
          <TagLabel>{label}</TagLabel>
          <TagCloseButton onClick={onClick} />
        </Tag>
      </WrapItem>
    )
  }
)

type GetTextProps = {
  value: any
  filterName?: string
}

function getText({ filterName, value }: GetTextProps) {
  const stringifiedValue = isObjectLike(value) ? JSON.stringify(value) : value

  const labels: Partial<Record<FilterFnName, string>> = {
    dateGreaterEqualThan: 'greater than/equal to',
    dateTimeGreaterEqualThan: 'greater than/equal to',
    dateLesserEqualThan: 'less than/equal to',
    dateTimeLesserEqualThan: 'less than/equal to',
    dateGreaterThan: 'greater than',
    dateTimeGreaterThan: 'greater than',
    dateLesserThan: 'less than',
    dateTimeLesserThan: 'less than',
  }

  const valueTransform: Partial<Record<FilterFnName, (value: string) => string>> = {
    dateGreaterEqualThan: formatStringAsDate,
    dateTimeGreaterEqualThan: (value) => formatStringAsDate(value, STANDARD_DATE_TIME_FORMAT),
    dateLesserEqualThan: formatStringAsDate,
    dateTimeLesserEqualThan: (value) => formatStringAsDate(value, STANDARD_DATE_TIME_FORMAT),
    dateGreaterThan: formatStringAsDate,
    dateTimeGreaterThan: (value) => formatStringAsDate(value, STANDARD_DATE_TIME_FORMAT),
    dateLesserThan: formatStringAsDate,
    dateTimeLesserThan: (value) => formatStringAsDate(value, STANDARD_DATE_TIME_FORMAT),
  }

  const castName = filterName as FilterFnName | undefined
  if (!!castName && !!labels[castName]) {
    return `${labels[castName]} "${
      valueTransform[castName]?.(stringifiedValue) ?? stringifiedValue
    }"`
  }

  const valueIsArray = Array.isArray(value)
  const name = filterName?.toLowerCase()

  if (valueIsArray && filterName === 'inNumberRange') {
    const min = Number(value[0])
    const minIsNumber = isFinite(min)
    const max = Number(value[1])
    const maxIsNumber = isFinite(max)

    if (minIsNumber && maxIsNumber) {
      return `- min: "${min}", max: "${max}"`
    } else if (minIsNumber) {
      return `- min: "${min}"`
    } else if (maxIsNumber) {
      return `- max: "${max}"`
    }
    return '???'
  }

  if (name?.includes('equal')) {
    return `equals "${stringifiedValue}"`
  }

  if (name === 'arrIncludesSome' || name === 'arrIncludesAll') {
    return `includes "${
      Array.isArray(value)
        ? formatArrayAsStringList(value, {
            type: name === 'arrIncludesSome' ? 'disjunction' : 'conjunction',
          })
        : value
    }"`
  }

  if (name?.includes('include')) {
    return `includes "${stringifiedValue}"`
  }

  return `- "${isObjectLike(value) ? JSON.stringify(value) : value}"`
}
