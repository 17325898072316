// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/select.ts

import { selectAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

const $bg = cssVar('select-bg')

const baseStyleField = defineStyle({
  background: $bg,
})

// const baseStyleIcon = defineStyle({})

const baseStyle = definePartsStyle({
  field: baseStyleField,
})

// const sizes = {}

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
})
