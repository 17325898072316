import { z } from 'zod'

export const OutcomeTypeSchema = z.enum(['closed', 'reopened'])
export const ClosedOutcomeInternalReasonSchema = z.enum(['dueToInactivity'])
export const ClosedOutcomeReasonSchema = z.enum([
  'canceled',
  'jobNotSold',
  'jobStarted',
  'noContact',
  'withdrawn',
  ...ClosedOutcomeInternalReasonSchema.options,
])
export const isStandardClosedOutcomeReason = (reason: string): reason is ClosedOutcomeReason =>
  ClosedOutcomeReasonSchema.safeParse(reason).success

export const OutcomeSchema = z.object({
  date: z.string().datetime(),
  notes: z.string().optional(),
  isClosed: z.boolean(),
  reason: ClosedOutcomeReasonSchema.or(z.string()).optional(),
})

export const ClosedOutcomeSchema = OutcomeSchema.merge(
  z.object({
    isClosed: z.literal(true),
    reason: ClosedOutcomeReasonSchema.or(z.string()),
  })
)

export const ReopenedOutcomeSchema = OutcomeSchema.merge(z.object({ isClosed: z.literal(false) }))

export const ClosedOutcomeReasonOptions = ClosedOutcomeReasonSchema.options
export const ClosedOutcomeReasonEnum = ClosedOutcomeReasonSchema.Enum

export const ClosedOutcomeInternalReasonOptions = ClosedOutcomeInternalReasonSchema.options
export const ClosedOutcomeInternalReasonEnum = ClosedOutcomeReasonSchema.Enum

export const OutcomeOptions = OutcomeTypeSchema.options
export const OutcomeTypeEnum = OutcomeTypeSchema.Enum

export type Outcome = z.infer<typeof OutcomeSchema>
export type ClosedOutcomeReason = z.infer<typeof ClosedOutcomeReasonSchema>
export type ClosedOutcomeInternalReason = z.infer<typeof ClosedOutcomeInternalReasonSchema>
export type ClosedOutcome = z.infer<typeof ClosedOutcomeSchema>
export type ReopenedOutcome = z.infer<typeof ReopenedOutcomeSchema>
export type OutcomeType = z.infer<typeof OutcomeTypeSchema>
