import type { JobId } from '@eigtech/restoration-types'
import {
  DailyJob,
  DailyJobSchema,
  EmergencyServicesJob,
  EmergencyServicesJobSchema,
  JobTypeEnum,
  OtrJob,
  OtrJobSchema,
} from '@eigtech/restoration-types'
import { ZodSchema } from 'zod'
import { destructureJobId, jobAbbreviationFromJobType } from './ids'

export const getValidJob = <T extends DailyJob | EmergencyServicesJob | OtrJob>(
  job: unknown,
  jobType: T['jobType']
): T | undefined => {
  const jobSchemaMap: Record<
    JobTypeEnum | T['jobType'],
    ZodSchema<DailyJob> | ZodSchema<EmergencyServicesJob> | ZodSchema<OtrJob>
  > = {
    daily: DailyJobSchema,
    emergencyServices: EmergencyServicesJobSchema,
    otr: OtrJobSchema,
  }

  const parsed = jobSchemaMap[jobType].safeParse(job)
  if (parsed.success) {
    return parsed.data as T
  }
}

export const ensureValidJob = <T extends DailyJob | EmergencyServicesJob | OtrJob>(
  job: unknown,
  jobType: T['jobType']
): T => {
  const validJob = getValidJob(job, jobType)
  if (!validJob) {
    throw new Error(`invalid ${jobType} job`)
  }
  return validJob
}

export function getJobLabelFromId(jobId: JobId) {
  const { jobFactor, jobType, stateAbbreviation, year } = destructureJobId(jobId)

  return [year, jobFactor, jobAbbreviationFromJobType(jobType), stateAbbreviation]
    .filter((part) => !!part)
    .join('-')
}
