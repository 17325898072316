import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowData,
  Table,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import { merge } from 'lodash-es'
import { useMemo } from 'react'
import type { SetOptional } from 'type-fest'
import { ACTION_MENU_ID, CustomFilterFn, customFilterFns } from './Settings'

export type UseDataGridProps<TData extends RowData> = Omit<
  TableOptions<TData>,
  'getCoreRowModel' | 'filterFns' | 'data'
> & { data: TableOptions<TData>['data'] | undefined } & Partial<
    Pick<TableOptions<TData>, 'getCoreRowModel'>
  > & {
    filterFns?: SetOptional<TableOptions<TData>['filterFns'], CustomFilterFn>
  }

export function useDataGrid<TData extends RowData>(props: UseDataGridProps<TData>): Table<TData> {
  const tableOptions = useMemo(() => {
    const defaultTableOptions: Pick<
      TableOptions<TData>,
      | 'getCoreRowModel'
      | 'getFilteredRowModel'
      | 'getSortedRowModel'
      | 'getPaginationRowModel'
      | 'getFacetedRowModel'
      | 'getFacetedUniqueValues'
      | 'getFacetedMinMaxValues'
      | 'getExpandedRowModel'
      | 'autoResetPageIndex'
      | 'enablePinning'
      | 'globalFilterFn'
      | 'filterFns'
    > = {
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      getFacetedMinMaxValues: getFacetedMinMaxValues(),
      getExpandedRowModel: getExpandedRowModel(),
      autoResetPageIndex: false,
      enablePinning: false,
      globalFilterFn,
      filterFns: customFilterFns,
    }

    const tableOptions: TableOptions<TData> = merge(
      {
        data: [],
        initialState: {
          columnOrder: props.columns.map((column) => column.id as string),
        },
      },
      defaultTableOptions,
      props
    )

    if (tableOptions.meta?.manualGlobalFilter) {
      tableOptions.globalFilterFn = () => true
    }

    const actionMenuColumnIndex = tableOptions.columns.findIndex(
      (column) => column.id === ACTION_MENU_ID
    )

    if (actionMenuColumnIndex >= 0) {
      tableOptions.columns[actionMenuColumnIndex] = {
        maxSize: 48,
        enableHiding: false,
        meta: { hidePinButton: true, ...tableOptions.columns[actionMenuColumnIndex].meta },
        ...tableOptions.columns[actionMenuColumnIndex],
      }
    }

    return tableOptions
  }, [props])

  const table = useReactTable(tableOptions)

  return table
}

export type UseDataGridReturn = ReturnType<typeof useDataGrid>

const globalFilterFn = <TData extends RowData>(
  row: Row<TData>,
  columnId: string,
  filterValue: string
): boolean => {
  const search = filterValue.toLowerCase()

  let value = row.getValue(columnId) as string
  if (typeof value === 'number') value = String(value)

  return value?.toLowerCase().includes(search)
}
