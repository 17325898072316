import {
  AddCustomerCommunicationRequest,
  AddCustomerCommunicationRequestSchema,
  CustomerCommunicationTypeSchema,
} from '@eigtech/claims-v2-types'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import { DATE_TIME_INPUT_FORMAT, format } from '@eigtech/ui-shared-dates'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  Divider,
  useDisclosure,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import {
  FormDrawer,
  createForm,
  useWatch,
  zodEnumWithUserFriendlyError,
} from '@eigtech/ui-shared-forms'
import { CreateEntityNoteDrawer } from '@eigtech/ui-shared-notes'
import { useAddCustomerCommunication } from '@eigtech/ui-shared-timeline'
import { PublishableClaimEventEnum } from '@eigtech/verisk-edi-interface-types'
import { z } from 'zod'
import { PublicationTargetsField } from '../Fields/PublicationTargetsField'
import { useGetClaim } from '../api'
import { useCanPublishClaimInfoToXA } from '../hooks/useCanPublishToXA'
import { DelegateAdminClaim } from '../types/DelegateAdminClaim'
import { EntityAdminClaim } from '../types/EntityAdminClaim'
import { getCustomerCommunicationTypeLabel } from '../utils'

export type AddCustomerCommunicationDrawerProps = ComposedDrawerWrapperProps & {
  claimNumber: string
  forceDateInputValueToPreferredTimezone?: (date: Date) => Date
  timezoneAbbreviation?: string
}

export const AddCustomerCommunicationDrawer = withDrawerErrorBoundary(
  function AddCustomerCommunicationDrawer(props: AddCustomerCommunicationDrawerProps) {
    const {
      claimNumber,
      forceDateInputValueToPreferredTimezone = (date: Date) => date,
      timezoneAbbreviation,
      ...drawerProps
    } = props

    const { data: claim } = useGetClaim<DelegateAdminClaim | EntityAdminClaim>(claimNumber)
    const includePublishField = useCanPublishClaimInfoToXA({ claim })

    const noteFormDrawer = useDisclosure()

    const form = useForm({
      defaultValues: {
        // input takes a string, but is coerced to date, so we need to trick TS here
        date: format(new Date(), DATE_TIME_INPUT_FORMAT) as unknown as Date,
      },
    })

    const communicationType = useWatch({ control: form.control, name: 'type' })

    const toast = useToast()

    const { mutateAsync: addCustomerCommunication, isError } = useAddCustomerCommunication()

    async function onSubmit({ date, publicationTargets, ...data }: AddCustomerCommunicationForm) {
      const payload: OmitAuthHeaders<AddCustomerCommunicationRequest> = {
        claimNumber,
        customerCommunication: {
          ...data,
          date: forceDateInputValueToPreferredTimezone(date).toISOString(),
        },
        publicationTargets,
      }

      await addCustomerCommunication(payload)

      toast({
        status: 'success',
        title: `Successfully recorded customer communication!`,
        description: 'It may take a few minutes for changes to be reflected.',
      })

      if (data.includeNote) {
        noteFormDrawer.onOpen()
      } else {
        drawerProps.onClose()
      }
    }

    return (
      <>
        <FormDrawer {...baseDrawerProps} {...drawerProps} form={form} onSubmit={onSubmit}>
          <SelectField
            label="Type of Communication"
            name="type"
            options={CustomerCommunicationTypeSchema.options.map((value) => ({
              label: getCustomerCommunicationTypeLabel(value),
              value,
            }))}
          />

          <InputField
            label={`Date${!!timezoneAbbreviation ? ` (${timezoneAbbreviation})` : ''}`}
            name="date"
            type="datetime-local"
          />

          {publishableCommunicationTypes.includes(
            communicationType as PublishableClaimEventEnum
          ) && <PublicationTargetsField claimNumber={claimNumber} />}

          <Divider />

          <CheckboxField
            helperText="If selected, you'll be prompted to create a note after this form has been submitted."
            label="Include Note?"
            name="includeNote"
          />

          {isError && (
            <ComposedAlert
              alert={{
                title: `Failed to record customer communication info`,
                description:
                  'Please try again. If the issue persists, please contact EIG Tech support.',
              }}
              status="error"
            />
          )}
        </FormDrawer>

        {noteFormDrawer.isOpen && (
          <CreateEntityNoteDrawer
            {...noteFormDrawer}
            entityCorn={ensureClaimCorn(claimNumber)}
            includePublishField={includePublishField}
            includeTemplateField
            templateParams={claim}
            onClose={() => {
              noteFormDrawer.onClose()
              props.onClose()
            }}
          />
        )}
      </>
    )
  },
  () => baseDrawerProps
)

const baseDrawerProps = {
  title: 'Record Customer Communication',
}

const publishableCommunicationTypes: PublishableClaimEventEnum[] = [
  'customerContactedEmail',
  'customerContactedLvm',
  'customerNoContact',
  'initialCustomerContactSuccess',
]

const AddCustomerCommunicationFormSchema = z
  .object({
    includeNote: z.boolean(),
    date: z.coerce.date(),
    type: zodEnumWithUserFriendlyError(CustomerCommunicationTypeSchema.options, {
      optionFormatter: getCustomerCommunicationTypeLabel,
    }),
  })
  .merge(AddCustomerCommunicationRequestSchema.pick({ publicationTargets: true }))

type AddCustomerCommunicationForm = z.infer<typeof AddCustomerCommunicationFormSchema>

const { useForm, CheckboxField, SelectField, InputField } = createForm(
  AddCustomerCommunicationFormSchema,
  'addCustomerCommunicationForm'
)
