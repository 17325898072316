import { Currency } from '@eigtech/currency'

export function renderEstimateAmount({ amount, currency, scale }: Currency) {
  const amountString = amount.toString()
  const arrayLength = scale + 1 > amountString.length ? scale : amountString.length

  // construct array of 0s
  const arr = new Array(arrayLength).fill('0')

  // fill in amount in reverse order
  amountString
    .split('')
    .reverse()
    .forEach((char, index) => {
      arr[index] = char
    })

  // insert decimal
  arr.splice(scale, 0, '.')

  // reverse array so it's in proper order and convert to string
  const decimalString = arr.reverse().join('')

  const decimalAmount = parseFloat(decimalString)

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(decimalAmount)
}
