import { forwardRef, Tabs, TabsProps } from '@chakra-ui/react'

export type PageTabsProps = TabsProps

export const PageTabs = forwardRef<PageTabsProps, 'div'>((props, ref) => (
  <Tabs
    ref={ref}
    colorScheme="brandPrimary"
    overflowX="auto"
    overflowY="visible"
    pt="1"
    {...props}
  />
))
