import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const RecordDepositOverrideApprovedRequestBodySchema = z.object({
  approvedBy: ContactCornSchema.or(z.string()),
  approvedDate: z.string().datetime(),
  externalId: JobExternalIdSchema.optional(),
})
export const RecordDepositOverrideApprovedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordDepositOverrideApprovedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordDepositOverrideApprovedRequestSchema =
  RecordDepositOverrideApprovedRequestBodySchema.merge(
    RecordDepositOverrideApprovedRequestHeadersSchema
  ).merge(RecordDepositOverrideApprovedRequestPathSchema)
export const RecordDepositOverrideApprovedResponseSchema = z.void()
export const RecordDepositOverrideApprovedApiSchema: ApiSchema = {
  body: RecordDepositOverrideApprovedRequestBodySchema,
  headers: RecordDepositOverrideApprovedRequestHeadersSchema,
  params: RecordDepositOverrideApprovedRequestPathSchema,
}

export interface RecordDepositOverrideApprovedRequestBody
  extends z.infer<typeof RecordDepositOverrideApprovedRequestBodySchema> {}
export interface RecordDepositOverrideApprovedRequestPath
  extends z.infer<typeof RecordDepositOverrideApprovedRequestPathSchema> {}
export interface RecordDepositOverrideApprovedRequest
  extends z.infer<typeof RecordDepositOverrideApprovedRequestSchema> {}
export type RecordDepositOverrideApprovedResponse = z.infer<
  typeof RecordDepositOverrideApprovedResponseSchema
>
export interface RecordDepositOverrideApprovedHandler
  extends Handler<RecordDepositOverrideApprovedRequest, RecordDepositOverrideApprovedResponse> {}
