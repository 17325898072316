import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/base'
import { ClaimReviewerSchema, PendingReviewSchema } from '../model/reviews'
import { BaseHeaderSchema } from './base'

const FlagClaimForReviewSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    notes: z.string(),
    reviewers: ClaimReviewerSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z
    .object({
      claimNumber: ClaimNumberSchema,
    })
    .merge(PendingReviewSchema),
}

export const FlagClaimForReviewRequestSchema = FlagClaimForReviewSchemas.params
  .merge(FlagClaimForReviewSchemas.headers)
  .merge(FlagClaimForReviewSchemas.body)

export type FlagClaimForReviewBodyRequest = z.infer<typeof FlagClaimForReviewSchemas.body>
export type FlagClaimForReviewHeaderRequest = z.infer<typeof FlagClaimForReviewSchemas.headers>
export type FlagClaimForReviewRequest = z.infer<typeof FlagClaimForReviewRequestSchema>
export type FlagClaimForReviewResponse = z.infer<typeof FlagClaimForReviewSchemas.response>

export interface FlagClaimForReviewHandler
  extends Handler<FlagClaimForReviewRequest, FlagClaimForReviewResponse> {}

export const FlagClaimForReviewApiSchema: ApiSchema = FlagClaimForReviewSchemas
