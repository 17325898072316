import { makeBasicQueryKeys } from '../../makeBasicQueryKeys'

export const auth0BasePath = 'auth'

const basicQueryKeys = makeBasicQueryKeys('auth')

export const auth0QueryKeys = {
  ...basicQueryKeys,
  userName: (userId: string) => [basicQueryKeys.detail(userId), 'userName'],
}
