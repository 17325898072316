import { ContactCommunicationInformation } from '@eigtech/contacts-types'

export const isPrimary = (comm: ContactCommunicationInformation) =>
  comm.telecommunicationUseCode === 'Primary'
export const isAPhoneNumber = (comm: ContactCommunicationInformation) =>
  comm.unformattedTelephoneNumber || comm.telephoneNumber
export const isAnEmail = (comm: ContactCommunicationInformation) => !!comm.emailAddress
export const hasLowerPreferenceOrder = (
  cur: ContactCommunicationInformation,
  prev: ContactCommunicationInformation
) => prev?.preferenceOrder && cur.preferenceOrder && prev.preferenceOrder > cur.preferenceOrder
