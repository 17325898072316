import sanity from '@sanity/client'

export type MakeSanityClientProps = {
  projectId: string
  dataset?: string
  apiVersion?: string
  useCdn?: boolean
}

export const makeSanityClient = (props: MakeSanityClientProps) =>
  sanity({
    dataset: 'production',
    apiVersion: '2023-01-18', // use current UTC date - see "specifying API version"!
    // token: 'sanity-auth-token', // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
    ...props,
  })
