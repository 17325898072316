import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'
import { pick } from 'lodash-es'

export const preferencesBasePath = 'user-preferences'

export const preferencesUi = 'delegate'

export const userQueryKeys = {
  ...pick(makeBasicQueryKeys('user'), ['all']),
  preferences: () => [...userQueryKeys.all(), 'preferences'],
  status: () => [...userQueryKeys.all(), 'status'],
}
