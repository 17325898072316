import { CornSchema } from '@eigtech/shared-corn'
import z from 'zod'

export const ChimeMeetingSchema = z.object({
  entityId: CornSchema,
  activeAttendees: z.array(z.string()).optional(),
  meetingId: z.string(),
  meetingStartTime: z.string(),
  meeting: z.record(z.string(), z.unknown()),
})

/**
 * @param chimeMeeting @eigtech/aws-sdk/chime-sdk-media-pipelines -> Meeting
 */
export type ChimeMeeting = z.infer<typeof ChimeMeetingSchema>
