import { ContactCornSchema } from '@eigtech/contacts-types'
import z from 'zod'

export const ClaimAssignmentSchema = z.object({
  claimNumber: z.string(),
  contactId: ContactCornSchema,
  dateInspectionScheduled: z.string().datetime().optional(),
})

export type ClaimAssignment = z.infer<typeof ClaimAssignmentSchema>
