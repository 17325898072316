import { ROUTER_PATHS } from '@eigtech/ui-estimator/lib/constants'
import { ClaimEstimateDetail } from '@eigtech/ui-shared-claims'
import { Box } from '@eigtech/ui-shared-dave'
import { BackButton, getRouteApi } from '@eigtech/ui-shared-router'

const Route = getRouteApi(ROUTER_PATHS.claimDetailEstimateDetail.routeId)

export function ClaimDetailEstimateDetail() {
  const { claimNumber, estimateId } = Route.useParams()

  return (
    <>
      <Box>
        <BackButton params={{ claimNumber }} to={ROUTER_PATHS.claimDetailEstimates.route}>
          ← Estimates
        </BackButton>
      </Box>

      <ClaimEstimateDetail claimNumber={claimNumber} estimateId={estimateId} />
    </>
  )
}
