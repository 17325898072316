import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const GetUploadScreenshotUrlRequestPathSchema = z.object({
  cohort: CohortEnumSchema,
  entityId: CornSchema,
  meetingId: z.string(),
})

export const GetUploadScreenshotUrlRequestHeaderSchema = ApiLambdalerAuthHeaderSchema

export const GetUploadScreenshotUrlRequestSchema = GetUploadScreenshotUrlRequestPathSchema.merge(
  GetUploadScreenshotUrlRequestHeaderSchema
)

export const GetUploadScreenshotUrlResponseSchema = z.object({
  fields: z.record(z.string()),
  url: z.string(),
})

export type GetUploadScreenshotUrlRequest = z.infer<typeof GetUploadScreenshotUrlRequestSchema>
export type GetUploadScreenshotUrlResponse = z.infer<typeof GetUploadScreenshotUrlResponseSchema>
export type GetUploadScreenshotUrlHandler = Handler<
  GetUploadScreenshotUrlRequest,
  GetUploadScreenshotUrlResponse
>
