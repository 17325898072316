import { MeetingStatus } from 'amazon-chime-sdk-component-library-react'

export const meetingStatusLabels: Record<MeetingStatus, string> = {
  [MeetingStatus.Loading]: 'Loading',
  [MeetingStatus.Succeeded]: 'Succeeded',
  [MeetingStatus.Failed]: 'Failed',
  [MeetingStatus.Ended]: 'Ended',
  [MeetingStatus.JoinedFromAnotherDevice]: 'Joined From Another Device',
  [MeetingStatus.Left]: 'Left',
  [MeetingStatus.TerminalFailure]: 'Terminal Failure',
  [MeetingStatus.Reconnecting]: 'Reconnecting',
}
