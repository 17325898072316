import { merge } from 'lodash-es'
import { Options as SearchOptions } from 'minisearch'
import { useEffect, useState } from 'react'
import { useMiniSearch } from 'react-minisearch'

const defaultOptions: SearchOptions = {
  fields: [],
  searchOptions: {
    fuzzy: true,
    prefix: true,
  },
}

export function useQuerySearch<T = any>(documents: T[], options: SearchOptions<T>) {
  const searchInstance = useMiniSearch(documents, merge({}, defaultOptions, options))

  useEffect(() => {
    searchInstance.removeAll()

    searchInstance.addAll(documents ?? [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (!!query) {
      searchInstance.search(query)
    } else {
      searchInstance.clearSearch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return {
    ...searchInstance,
    query,
    setQuery,
  }
}
