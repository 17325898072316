import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const RemoveRecordedUserRequestPathSchema = z.object({
  userId: z.string(),
})

export const RemoveRecordedUserRequestSchema = RemoveRecordedUserRequestPathSchema
export const RemoveRecordedUserResponseSchema = z.void()
export const RemoveRecordedUserApiSchema = {
  params: RemoveRecordedUserRequestPathSchema,
} satisfies ApiSchema

export type RemoveRecordedUserRequest = z.infer<typeof RemoveRecordedUserRequestSchema>
export type RemoveRecordedUserResponse = z.infer<typeof RemoveRecordedUserResponseSchema>
export type RemoveRecordedUserHandler = Handler<
  RemoveRecordedUserRequest,
  RemoveRecordedUserResponse
>
