import { GetEstimatorUserResponse } from '@eigtech/estimator-types'
import { queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { useCheckIfAccessTokenSet } from '@eigtech/ui-shared-auth'
import { estimatorBasePath } from '../claims/constants'
import { get } from '../instance'
import { userQueryKeys } from './constants'
import { isPortalUserActive } from '@eigtech/portals-util/portalUser'

const getEstimatorUser = async ({ auth0Id, email }: { auth0Id: string; email: string }) => {
  const estimators = await get<GetEstimatorUserResponse>(
    `${estimatorBasePath}/user/${encodeURIComponent(auth0Id)}/${encodeURIComponent(email)}`
  )

  return estimators.filter(isPortalUserActive)
}

export const getEstimatorUserQueryOptions = ({
  accessTokenSet,
  ...request
}: {
  auth0Id: string
  email: string
  accessTokenSet: boolean
}) =>
  queryOptions({
    queryKey: userQueryKeys.status(),
    queryFn: () => getEstimatorUser(request),
    enabled: accessTokenSet,
    retry: false, // retry manually based on returned status/error
  })

export function useGetEstimatorUser(request: { auth0Id: string; email: string }) {
  const accessTokenSet = useCheckIfAccessTokenSet()
  return useQuery(getEstimatorUserQueryOptions({ ...request, accessTokenSet }))
}

export function useInvalidateUserStatus() {
  const queryClient = useQueryClient()

  return function invalidateUserGroups() {
    queryClient.invalidateQueries({ queryKey: userQueryKeys.status() })
  }
}
