import { CohortEnum } from '@eigtech/auth0-types'
import { ReactNode, createContext, useContext } from 'react'

export type CohortContext = {
  cohort: CohortEnum
}

export function CohortProvider({
  children,
  cohort,
}: {
  children: ReactNode
} & CohortContext) {
  return <CohortContext.Provider value={{ cohort }}>{children}</CohortContext.Provider>
}

export function useCohortContext() {
  const routes = useContext(CohortContext)

  if (!routes) {
    throw new Error('CohortProvider required to use useCohortContext')
  }

  return routes
}

// Context
const CohortContext = createContext<CohortContext | null>(null)
