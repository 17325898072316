import { forwardRef, Stack, StackProps } from '@chakra-ui/react'
import { ElementType } from 'react'
import { PageErrorBoundary } from './PageErrorBoundary'

export type PageBodyProps = StackProps & { ErrorBoundary?: ElementType<any> }

export const PageBody = forwardRef<PageBodyProps, 'div'>(
  ({ ErrorBoundary = PageErrorBoundary, children, ...props }, ref) => (
    <Stack ref={ref} spacing="6" {...props}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Stack>
  )
)
