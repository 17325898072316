import log from '@eigtech/ui-shared-logging'
import { ReactNode, useEffect } from 'react'
import { create } from 'zustand'
import {
  ApiGetRequest,
  ApiInstanceFactoryReturn,
  ApiPatchRequest,
  ApiPostRequest,
  ApiPutRequest,
  ApiRemoveRequest,
  apiInstanceFactory,
} from './instanceFactory'

export type ApiState = {
  instance: ApiInstanceFactoryReturn
  setInstance: (instance: ApiInstanceFactoryReturn) => void
}

export const useApiStore = create<ApiState>()((set) => ({
  instance: apiInstanceFactory({}),
  setInstance: (instance) => set({ instance }),
}))

export const contextualGet: ApiGetRequest = (path, options) =>
  useApiStore.getState().instance.get(path, options)

export const contextualPost: ApiPostRequest = (path, json, options) =>
  useApiStore.getState().instance.post(path, json, options)

export const contextualPut: ApiPutRequest = (path, json, options) =>
  useApiStore.getState().instance.put(path, json, options)

export const contextualPatch: ApiPatchRequest = (path, json, options) =>
  useApiStore.getState().instance.patch(path, json, options)

export const contextualRemove: ApiRemoveRequest = (path, json, options) =>
  useApiStore.getState().instance.remove(path, json, options)

export type ApiInstanceProviderProps = {
  children: ReactNode
  instance?: ApiInstanceFactoryReturn
}

export function ApiInstanceProvider({ children, instance }: ApiInstanceProviderProps) {
  useEffect(() => {
    if (!instance) {
      log.warn('missing API instance information')
      return
    }

    const { setInstance } = useApiStore.getState()

    setInstance(instance)
  }, [instance])

  return <>{children}</>
}

export function useApiInstanceContext() {
  return useApiStore((state) => state.instance)
}
