import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentSchemaV2 } from '../aggregates/index.js'

export const UpdateDocumentRequestBodySchema = DocumentSchemaV2.pick({
  description: true,
  notes: true,
}).deepPartial()

export const UpdateDocumentRequestPathSchema = z.object({
  entityId: CornSchema,
  documentCorn: z.string(),
})

export const UpdateDocumentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema

export const UpdateDocumentRequestSchema = UpdateDocumentRequestBodySchema.merge(
  UpdateDocumentRequestPathSchema
).merge(UpdateDocumentRequestHeadersSchema)

/**
 * Pass `description` and/or `notes`
 *
 * An empty string will set the value as `null` in the DB.
 *
 * Passing `undefined` or omitting will leave the existing value as-is.
 */
export type UpdateDocumentRequestBody = z.infer<typeof UpdateDocumentRequestBodySchema>
export type UpdateDocumentRequestPath = z.infer<typeof UpdateDocumentRequestPathSchema>
export type UpdateDocumentRequest = z.infer<typeof UpdateDocumentRequestSchema>
export type UpdateDocumentResponse = void

export type UpdateDocumentHandler = Handler<UpdateDocumentRequest, UpdateDocumentResponse>
