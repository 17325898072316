import { Container, Image, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { ComposedCard } from '../../Card'
import NotFoundGif from './not-found.gif'

export type NotFoundErrorProps = {
  title?: ReactNode
  copy?: ReactNode
}

export function NotFoundError({ copy, title }: NotFoundErrorProps) {
  return (
    <Container maxW="container.text" my="16">
      <ComposedCard heading={title || 'Whoops!'}>
        {!copy || typeof copy === 'string' ? (
          <Text fontWeight="bold" mb="4">
            {copy || 'Could not find page.'}
          </Text>
        ) : (
          copy
        )}
        <Image mb="-4" mx="auto" src={NotFoundGif} />
      </ComposedCard>
    </Container>
  )
}
