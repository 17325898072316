import { Button, ButtonProps } from '@eigtech/ui-shared-dave'
import { GroupBase } from 'chakra-react-select'
import { FC } from 'react'
import {
  FieldArrayPath,
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useFormContext as useBaseFormContext,
} from 'react-hook-form'
import { BaseProps, FormControl } from 'react-hook-form-chakra'
import { z } from 'zod'
import { CheckboxField, CheckboxFieldComponent } from './components/CheckboxField'
import { CheckboxGroupField, CheckboxGroupFieldComponent } from './components/CheckboxGroupField'
import { ArrayFieldProps, ArrayField as BaseArrayField } from './components/FieldArray/ArrayField'
import { useArrayFieldContext as useBaseArrayFieldContext } from './components/FieldArray/ArrayFieldContext'
import { useArrayFieldRowContext as useBaseArrayFieldRowContext } from './components/FieldArray/ArrayFieldRowContext'
import { Form as BaseForm, FormProps } from './components/Form'
import { InputField, InputFieldComponent } from './components/InputField'
import { MaskedInputField, MaskedInputFieldComponent } from './components/MaskedInputField'
import { NumberInputField, NumberInputFieldProps } from './components/NumberInputField'
import { RadioGroupField, RadioGroupFieldComponent } from './components/RadioGroupField'
import {
  ReactCreatableSelectField as BaseReactCreatableSelectField,
  ReactSelectField as BaseReactSelectField,
  ReactCreatableSelectFieldProps,
  ReactSelectFieldProps,
} from './components/ReactSelectField'
import { SelectField, SelectFieldComponent } from './components/SelectField'
import { SwitchField, SwitchFieldComponent } from './components/SwitchField'
import { TextareaField, TextareaFieldComponent } from './components/TextareaField'
import { WithFieldNameProp } from './types/FieldProps'
import { useForm as useBaseForm } from './useForm'

export function createForm<T extends z.Schema<any, any>>(schema: T, id: string) {
  type TForm = z.infer<T>

  function useForm<TContext = any, TTransformedValues extends FieldValues | undefined = undefined>(
    props?: UseFormProps<TForm, TContext>
  ) {
    return useBaseForm<T, TContext, TTransformedValues>(schema, id, props)
  }

  function useFormContext<TTransformedValues extends FieldValues | undefined = undefined>() {
    return useBaseFormContext<TForm, TTransformedValues>()
  }

  const SubmitButton = ({
    form,
    ...props
  }: Omit<ButtonProps, 'form'> & { form: UseFormReturn<TForm> }) => {
    const {
      formState: { isSubmitting },
    } = form

    return (
      <Button form={id} isLoading={isSubmitting} type="submit" {...props}>
        {props.children ?? 'Submit'}
      </Button>
    )
  }

  const ReactSelectField = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: ReactSelectFieldProps<TForm, Option, IsMulti, Group>
  ) => <BaseReactSelectField<TForm, Option, IsMulti, Group> {...props} />
  const ReactCreatableSelectField = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: ReactCreatableSelectFieldProps<TForm, Option, IsMulti, Group>
  ) => <BaseReactCreatableSelectField<TForm, Option, IsMulti, Group> {...props} />

  const ArrayField = <TFieldArrayName extends FieldArrayPath<TForm> = FieldArrayPath<TForm>>(
    props: ArrayFieldProps<TForm, TFieldArrayName>
  ) => <BaseArrayField {...props} />

  const useArrayFieldContext = <
    TFieldArrayName extends FieldArrayPath<TForm> = FieldArrayPath<TForm>,
    TKeyName extends string = 'id',
  >() => useBaseArrayFieldContext<TForm, TFieldArrayName, TKeyName>()

  const useArrayFieldRowContext = <
    TFieldArrayName extends FieldArrayPath<TForm> = FieldArrayPath<TForm>,
    TKeyName extends string = 'id',
  >() => useBaseArrayFieldRowContext<TForm, TFieldArrayName, TKeyName>()

  return {
    useForm,
    useFormContext,
    Form: (props: Omit<FormProps<TForm>, 'id'>) => <BaseForm<TForm> id={id} {...props} />,
    FormControl: FormControl as FC<WithFieldNameProp<BaseProps, TForm>>,
    SubmitButton,

    Schema: schema,

    CheckboxField: CheckboxField as CheckboxFieldComponent<TForm>,
    CheckboxGroupField: CheckboxGroupField as CheckboxGroupFieldComponent<TForm>,
    InputField: InputField as InputFieldComponent<TForm>,
    MaskedInputField: MaskedInputField as MaskedInputFieldComponent<TForm>,
    NumberInputField: NumberInputField as FC<NumberInputFieldProps<TForm>>,
    RadioGroupField: RadioGroupField as RadioGroupFieldComponent<TForm>,
    ReactSelectField,
    ReactCreatableSelectField,
    SelectField: SelectField as SelectFieldComponent<TForm>,
    SwitchField: SwitchField as SwitchFieldComponent<TForm>,
    TextareaField: TextareaField as TextareaFieldComponent<TForm>,

    ArrayField,
    useArrayFieldContext,
    useArrayFieldRowContext,
  }
}
