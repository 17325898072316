import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { WatchedAlertRuleSchema } from '../base.js'

export const GetWatchedRulesRequestSchema = z.void()
export const GetWatchedRulesResponseSchema = WatchedAlertRuleSchema.array()

export type GetWatchedRulesRequest = z.infer<typeof GetWatchedRulesRequestSchema>
export type GetWatchedRulesResponse = z.infer<typeof GetWatchedRulesResponseSchema>

export type GetWatchedRulesHandler = Handler<GetWatchedRulesRequest, GetWatchedRulesResponse>
