import { format, isValid } from 'date-fns'
import {
  convertDateOnlyStringToDate,
  ConvertDateOnlyStringToDateOptions,
} from './convertDateOnlyStringToDate'
import { isISODate } from './validate'

export type BaseFormatStringAsDateOptions<ValidDate extends boolean> = {
  formatter?: typeof format
} & ConvertDateOnlyStringToDateOptions<ValidDate>

export function baseFormatStringAsDate<ValidDate extends boolean>(
  value: string | number | Date | undefined | null,
  dateFormat: string = 'MM/dd/yyyy',
  { formatter = format, ...options }: BaseFormatStringAsDateOptions<ValidDate>
) {
  if (!value) return '(Empty)'
  if (value instanceof Date && !isValid(value)) return '(Invalid Date)'

  const composedFormatter = (date: Date) => formatter(date, dateFormat)

  try {
    const date =
      typeof value === 'string'
        ? isISODate(value)
          ? value
          : convertDateOnlyStringToDate(value, options)
        : value
    return composedFormatter(new Date(date))
  } catch (error) {
    // log.warn(error);
  }

  return value as string
}
