import { z } from 'zod'
import { NoteSchema } from '../index.js'

const DatabaseArtifactsSchema = z.object({
  PK: z.string(),
  SK: z.string(),
})

export const DatabaseNoteSchema = NoteSchema.merge(DatabaseArtifactsSchema)

export type DatabaseNote = z.infer<typeof DatabaseNoteSchema>
