import { formatUsPhoneToHtml } from '@eigtech/contacts-util'
import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'
import { differenceInDays } from '@eigtech/ui-shared-dates'
import {
  ButtonGroup,
  Center,
  ComposedAlert,
  ComposedCard,
  createIcon,
  FancyLink,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  Stack,
  Text,
} from '@eigtech/ui-shared-dave'
import { formatPhoneNumber } from '@eigtech/ui-shared-phone'
import { BackButton, getRouteApi, LinkIconButton } from '@eigtech/ui-shared-router'
import { minisearch, useQuerySearch } from '@eigtech/ui-shared-search'
import { startCase } from 'lodash-es'
import { useMemo, useState } from 'react'
import { IconType } from 'react-icons'
import {
  HiSortAscending as SortAscendingIcon,
  HiSortDescending as SortDescendingIcon,
} from 'react-icons/hi'
import {
  MdChevronRight,
  MdClose,
  MdEmail,
  MdOutlineCheckBox,
  MdOutlineWarningAmber,
  MdPhone,
  MdSearch,
} from 'react-icons/md'
import { RxQuestionMarkCircled } from 'react-icons/rx'
import { ROUTER_PATHS } from '../../../lib/constants'
import { useFilteredClaims } from './hooks'
import { claimsSortFunctions, inAssignQueueDate } from './utils'

export function ClaimsList() {
  const { assignments } = getRouteApi(ROUTER_PATHS.claims.routeId).useSearch()

  const claims = useFilteredClaims(assignments)

  const [isShrunk, setIsShrunk] = useState(false)

  const [isDescending, setIsDescending] = useState<boolean>(true)
  const [sortBy, setSortBy] = useState<SortBy>('inAssignQueue')

  const { query, setQuery, searchResults } = useQuerySearch(claims, {
    fields: [
      'carrier',
      'catCode',
      'claimNumber',
      'coordinator',
      'lossType',
      'lossLocation',
      'primaryContact',
    ],
    idField: 'claimNumber',
    extractField(document, field) {
      const fieldName = field as keyof typeof document

      if (fieldName === 'coordinator') {
        return document.coordinator?.name
      }

      if (fieldName === 'lossLocation') {
        return `${document.lossLocation.city}, ${document.lossLocation.state}`
      }

      if (fieldName === 'primaryContact') {
        return document.primaryContact?.name
      }

      return minisearch.getDefault('extractField')(document, field)
    },
  })

  const sortedClaims = useMemo(
    () =>
      ((!!query ? searchResults : claims) ?? []).sort((a, b) =>
        claimsSortFunctions[sortBy](isDescending ? a : b, isDescending ? b : a)
      ),
    [claims, isDescending, query, searchResults, sortBy]
  )

  return (
    <Stack spacing={isShrunk ? '2' : '4'}>
      <HStack justifyContent="space-between">
        <BackButton ml="0" to={ROUTER_PATHS.claims.route} />

        <ButtonGroup isAttached ml="auto" size="sm" variant="outline">
          <IconButton
            aria-label="Shrink rows"
            icon={<ShrinkRowIcon />}
            variant={isShrunk ? 'solid' : 'outline'}
            onClick={() => setIsShrunk(true)}
          />
          <IconButton
            aria-label="Expand rows"
            icon={<ExpandRowIcon />}
            variant={!isShrunk ? 'solid' : 'outline'}
            onClick={() => setIsShrunk(false)}
          />
        </ButtonGroup>
      </HStack>

      {!claims.length ? (
        <ComposedAlert
          alert={
            assignments === 'today'
              ? 'No claim assignments for today.'
              : assignments === 'new'
                ? 'No new claim assignments.'
                : 'No claims found.'
          }
        />
      ) : (
        <Stack>
          <FormControl>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={MdSearch} />
              </InputLeftAddon>
              <Input
                bg="white"
                placeholder="Type to search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  aria-label="Clear search"
                  icon={<MdClose />}
                  size="sm"
                  variant="ghost"
                  onClick={() => setQuery('')}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <HStack>
              <IconButton
                aria-label={`Click to sort by: ${isDescending ? 'ascending' : 'descending'}`}
                icon={isDescending ? <SortDescendingIcon /> : <SortAscendingIcon />}
                variant="outline"
                onClick={() => setIsDescending((v) => !v)}
              />
              <Select
                bg="white"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value as SortBy)}
              >
                {Object.keys(claimsSortFunctions).map((key) => (
                  <option key={key} value={key}>
                    {startCase(key)}
                  </option>
                ))}
              </Select>
            </HStack>
          </FormControl>
        </Stack>
      )}

      {!!query && !sortedClaims.length && <ComposedAlert alert="No claims found for search." />}

      {sortedClaims.map((claim) => (
        <Claim key={claim.claimNumber} claim={claim} isShrunk={isShrunk} />
      ))}
    </Stack>
  )
}

function Claim({ claim, isShrunk }: { claim: DelegateAdminClaimSummary; isShrunk: boolean }) {
  const daysLeft = useMemo(() => {
    const inAssignQueue = inAssignQueueDate(claim)

    if (!inAssignQueue) return '??'

    return differenceInDays(new Date(inAssignQueue), Date.now())
  }, [claim])

  const status =
    typeof daysLeft === 'string'
      ? 'unknown'
      : daysLeft < 0
        ? 'bad'
        : daysLeft <= 2
          ? 'okay'
          : 'good'

  const icons: Record<typeof status, IconType> = {
    bad: MdOutlineWarningAmber,
    good: MdOutlineCheckBox,
    okay: MdOutlineCheckBox,
    unknown: RxQuestionMarkCircled,
  }

  const StatusIcon = icons[status]

  const colors: Record<typeof status, string> = {
    bad: 'red.500',
    good: 'green.500',
    okay: 'yellow.500',
    unknown: 'yellow.500',
  }

  const color = colors[status]

  const { name, email, phone } = claim.primaryContact ?? {}

  return (
    <ComposedCard
      borderLeftColor={color}
      borderLeftWidth="3px"
      cardBodyProps={{ px: isShrunk ? '2' : '3', py: isShrunk ? '2' : '4' }}
    >
      <HStack spacing="3">
        <Center flexShrink={0}>
          <Icon as={StatusIcon} boxSize="1.75em" color={color} />
        </Center>

        <Stack flexGrow={1} flexShrink={1} lineHeight={1.2} overflow="hidden" spacing="1">
          <Text fontWeight="bold">{name ?? 'No primary contact yet'}</Text>

          {!isShrunk && (
            <>
              {!!phone ? (
                <FancyLink href={`tel:${formatUsPhoneToHtml(phone)}`} iconPosition="left">
                  {formatPhoneNumber(phone)}
                </FancyLink>
              ) : !!email ? (
                <FancyLink
                  href={`mailto:${email}`}
                  iconPosition="left"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {email}
                </FancyLink>
              ) : (
                <Text>No contact info</Text>
              )}

              <Text>
                {/* {claim.carrier}, {claim.lossType} */}
                {claim.carrier}
              </Text>
            </>
          )}

          <Text fontWeight="semibold">{daysLeft} days left</Text>
        </Stack>

        <ButtonGroup isAttached size="sm" variant="outline">
          {isShrunk &&
            (!!phone ? (
              <IconButton aria-label="Call" as="a" href={`tel:${phone}`} icon={<MdPhone />} />
            ) : !!email ? (
              <IconButton aria-label="Email" as="a" href={`mailto:${email}`} icon={<MdEmail />} />
            ) : (
              <></>
            ))}

          <LinkIconButton
            aria-label={`Go to claim: ${claim.claimNumber}`}
            icon={<MdChevronRight />}
            params={{ claimNumber: claim.claimNumber }}
            to={ROUTER_PATHS.claimDetail.route}
            variant="solid"
          />
        </ButtonGroup>
      </HStack>
    </ComposedCard>
  )
}

const ShrinkRowIcon = createIcon({
  displayName: 'ShrinkRowIcon',
  viewBox: '0 0 12 12',
  defaultProps: { fill: 'currentcolor' },
  path: [
    <path
      key="1"
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0H11.5C11.7761 0 12 0.223858 12 0.5V2.16667C12 2.44281 11.7761 2.66667 11.5 2.66667H0.5C0.223858 2.66667 0 2.44281 0 2.16667V0.5Z"
    ></path>,
    <path
      key="2"
      d="M0 5.16666C0 4.89052 0.223858 4.66666 0.5 4.66666H11.5C11.7761 4.66666 12 4.89052 12 5.16666V6.83333C12 7.10947 11.7761 7.33333 11.5 7.33333H0.5C0.223858 7.33333 0 7.10947 0 6.83333V5.16666Z"
    ></path>,
    <path
      key="3"
      d="M0 9.83333C0 9.55719 0.223858 9.33333 0.5 9.33333H11.5C11.7761 9.33333 12 9.55719 12 9.83333V11.5C12 11.7761 11.7761 12 11.5 12H0.5C0.223858 12 0 11.7761 0 11.5V9.83333Z"
    ></path>,
  ],
})

const ExpandRowIcon = createIcon({
  displayName: 'ExpandRowIcon',
  viewBox: '0 0 12 12',
  defaultProps: { fill: 'currentcolor' },
  path: [
    <path
      key="1"
      d="M0 0.75C0 0.335786 0.335786 0 0.75 0H11.25C11.6642 0 12 0.335787 12 0.75V4.25C12 4.66421 11.6642 5 11.25 5H0.75C0.335786 5 0 4.66421 0 4.25V0.75Z"
    ></path>,
    <path
      key="2"
      d="M0 7.75C0 7.33579 0.335786 7 0.75 7H11.25C11.6642 7 12 7.33579 12 7.75V11.25C12 11.6642 11.6642 12 11.25 12H0.75C0.335786 12 0 11.6642 0 11.25V7.75Z"
    ></path>,
  ],
})

type SortBy = keyof typeof claimsSortFunctions
