import { CustomerCommunicationType } from '@eigtech/claims-v2-types'
import { startCase } from 'lodash-es'

const customerCommunicationTypeLabelRecord: Partial<Record<CustomerCommunicationType, string>> = {
  customerContactedEmail: 'Customer Contacted - Email',
  customerContactedLvm: 'Customer Contacted - Left Voicemail',
}

export const getCustomerCommunicationTypeLabel = (
  customerCommunicationType: CustomerCommunicationType
) =>
  customerCommunicationTypeLabelRecord[customerCommunicationType] ??
  startCase(customerCommunicationType)
