// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/skeleton.ts

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

// const $startColor = cssVar("skeleton-start-color")
// const $endColor = cssVar("skeleton-end-color")

const baseStyle = defineStyle({
  borderRadius: 'md',
})

export const skeletonTheme = defineStyleConfig({
  baseStyle,
})
