import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, InboxMessageIdSchema } from '../../base'

export const UnarchiveInboxMessageRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const UnarchiveInboxMessageRequestPathSchema = z.object({
  inboxId: Auth0IdSchema,
  messageId: InboxMessageIdSchema,
})

export const UnarchiveInboxMessageRequestSchema = UnarchiveInboxMessageRequestPathSchema.merge(
  UnarchiveInboxMessageRequestHeaderSchema
)

export type UnarchiveInboxMessageRequestPath = z.infer<
  typeof UnarchiveInboxMessageRequestPathSchema
>
export type UnarchiveInboxMessageRequest = z.infer<typeof UnarchiveInboxMessageRequestSchema>

export const UnarchiveInboxMessageResponseSchema = z.void()
export type UnarchiveInboxMessageResponse = z.infer<typeof UnarchiveInboxMessageResponseSchema>

export interface UnarchiveInboxMessageHandler
  extends Handler<UnarchiveInboxMessageRequest, UnarchiveInboxMessageResponse> {}
