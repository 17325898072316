import { defineTable, Schema, NewTableRow, TableRow } from 'squid'

export type NewInspectionRecord = NewTableRow<typeof inspectionsTable>
export type InspectionRecord = TableRow<typeof inspectionsTable>

const inspectionsTable = defineTable('inspections', {
  entity_id: Schema.String,
  inspection_id: Schema.String,
  date_completed: Schema.nullable(Schema.String),
  date_canceled: Schema.nullable(Schema.String),
  inspector_role: Schema.nullable(Schema.String),
  notes: Schema.nullable(Schema.String),
  scheduled_date: Schema.nullable(Schema.String),
  status: Schema.String,
})
