import { Handler } from 'aws-lambda'
import z from 'zod'
import { VediStatusImportSchema } from '../vediImport'

export const ImportStatusRequestBodySchema = VediStatusImportSchema.pick({
  claimNumber: true,
  transactionId: true,
  user: true,
  note: true,
  status: true,
  timestamp: true,
})

export const ImportStatusRequestSchema = ImportStatusRequestBodySchema

export type ImportStatusRequest = z.infer<typeof ImportStatusRequestSchema>
export type ImportStatusResponse = void

export type ImportStatusHandler = Handler<ImportStatusRequest, ImportStatusResponse>
