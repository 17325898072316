import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import { BookCornSchema, BookSchema } from '../base/books.js'
import { ServiceEventBaseSchema, serviceEventType } from './service.js'

const bookEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('book', eventName)

export const BookEventSchema = ServiceEventBaseSchema.merge(
  z.object({
    type: bookEventType(),
    book: z.unknown(),
    schemaVersion: z.literal(1),
  })
)

export const BookCreatedEventSchema = BookEventSchema.merge(
  z.object({
    type: bookEventType('created'),
    book: BookSchema,
  })
)

export const BookDeletedEventSchema = BookEventSchema.merge(
  z.object({
    type: bookEventType('deleted'),
    book: z.object({
      bookId: BookCornSchema,
    }),
  })
)

export const BookMetadataUpdatedEventSchema = BookEventSchema.merge(
  z.object({
    type: bookEventType('metadataUpdated'),
    book: BookSchema,
  })
)

export const isBookCreatedEvent = (event: EventBase): event is BookCreatedEvent =>
  BookCreatedEventSchema.safeParse(event).success
export const isBookDeletedEvent = (event: EventBase): event is BookDeletedEvent =>
  BookDeletedEventSchema.safeParse(event).success
export const isBookMetadataUpdatedEvent = (event: EventBase): event is BookMetadataUpdatedEvent =>
  BookMetadataUpdatedEventSchema.safeParse(event).success

export type BookEvent = z.infer<typeof BookEventSchema>
export type BookCreatedEvent = z.infer<typeof BookCreatedEventSchema>
export type BookDeletedEvent = z.infer<typeof BookDeletedEventSchema>
export type BookMetadataUpdatedEvent = z.infer<typeof BookMetadataUpdatedEventSchema>
