import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { AlertSubscriptionSchema } from '../base.js'

export const GetAlertSubscriptionQueryRequestSchema = z.object({
  alertLevel: z.string(),
})

export const GetAlertSubscriptionRequestSchema = GetAlertSubscriptionQueryRequestSchema
export type GetAlertSubscriptionRequest = z.infer<typeof GetAlertSubscriptionRequestSchema>

export const GetAlertSubscriptionResponseSchema = AlertSubscriptionSchema.array()
export type GetAlertSubscriptionResponse = z.infer<typeof GetAlertSubscriptionResponseSchema>

export type GetAlertSubscriptionHandler = Handler<
  GetAlertSubscriptionRequest,
  GetAlertSubscriptionResponse
>
