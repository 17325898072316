import log from '@eigtech/ui-shared-logging'
import { useCallback, useState } from 'react'
import { useToast } from '../components/Chakra/useToast'

export function useCopyToClipboard() {
  const [isCopied, setCopied] = useState(false)
  const [isError, setError] = useState(false)
  const toast = useToast()

  const copyToClipboard = useCallback(
    (text: string | number) => {
      setCopied(false)
      setError(false)

      try {
        navigator.clipboard.writeText(text.toString())
        setCopied(true)
        toast({ title: 'Copied to clipboard!', status: 'success' })
      } catch (error) {
        log.error('could not copy to clipboard', { error })
        setError(true)
      }
    },
    [setCopied, setError, toast]
  )

  return {
    copyToClipboard,
    isCopied,
    isError,
  }
}
