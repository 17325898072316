import { GetTimelineRequest, GetTimelineResponse } from '@eigtech/summarizer-types'
import {
  OmitAuthHeaders,
  contextualGet,
  queryOptions,
  useQueries,
  useQuery,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@eigtech/ui-shared-api'
import { summarizerBasePath, timelineQueryKeys } from './constants'

const getTimeline = ({ entityId, entityType, timelineType }: OmitAuthHeaders<GetTimelineRequest>) =>
  contextualGet<GetTimelineResponse>(
    `${summarizerBasePath}/timeline/${entityType}/${timelineType}/${entityId}`
  )

export const getTimelineQueryOptions = (request: OmitAuthHeaders<GetTimelineRequest>) =>
  queryOptions({
    queryKey: timelineQueryKeys.timeline(request),
    queryFn: () => getTimeline(request),
  })

export const useGetTimeline = (request: OmitAuthHeaders<GetTimelineRequest>) =>
  useQuery(getTimelineQueryOptions(request))

export const useSuspenseGetTimeline = (request: OmitAuthHeaders<GetTimelineRequest>) =>
  useSuspenseQuery(getTimelineQueryOptions(request))

export const useGetTimelines = ({
  entityIds,
  ...request
}: { entityIds: string[] } & Omit<GetTimelineRequest, 'entityId' | 'requestToken'>) =>
  useQueries({
    queries: entityIds.map((entityId) => getTimelineQueryOptions({ ...request, entityId })),
  })

export const useSuspenseGetTimelines = ({
  entityIds,
  ...request
}: { entityIds: string[] } & Omit<GetTimelineRequest, 'entityId' | 'requestToken'>) =>
  useSuspenseQueries({
    queries: entityIds.map((entityId) => getTimelineQueryOptions({ ...request, entityId })),
  })
