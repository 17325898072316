import { Corn } from '@eigtech/shared-corn'
import { useCohortContext } from '@eigtech/ui-shared-api'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  WithDrawerErrorBoundaryProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { ReactNode } from 'react'
import { useCreateEntityNote } from '../../api/createEntityNote'
import { useNotesContext } from '../../context/NotesContext'
import { makeAddNotePayload } from '../../utils/makeAddNotePayload'
import { NoteFields } from './NoteFields'
import { NoteFieldsSchema, NoteFieldsType } from './NoteFieldsSchema'
import { NoteTemplateField } from './NoteTemplateField'
import { PublishNoteField } from './PublishNoteField'

export type CreateEntityNoteDrawerProps = ComposedDrawerWrapperProps & {
  entityCorn: Corn
  noteText?: string
  noteTitle?: string
  templateParams?: Record<string, unknown>
  title?: ReactNode
  includeTemplateField?: boolean
  includePublishField?: boolean
  onSuccess?: () => any
}

export const CreateEntityNoteDrawer = withDrawerErrorBoundary(
  function CreateEntityNoteDrawer({
    entityCorn,
    noteText,
    noteTitle,
    templateParams,
    includePublishField = true,
    includeTemplateField = true,
    onSuccess,
    ...drawerProps
  }: CreateEntityNoteDrawerProps) {
    const toast = useToast()

    const { cohort } = useCohortContext()
    const { dataSource } = useNotesContext()

    const form = useForm({
      defaultValues: {
        title: noteTitle ?? '',
        text: noteText ?? '',
      },
    })

    const { mutateAsync: createNote, isError } = useCreateEntityNote()

    async function onSubmit(data: NoteFieldsType) {
      const payload = makeAddNotePayload({ ...data, cohort, entityCorn, source: dataSource })

      await createNote(payload)

      toast({
        status: 'success',
        title: 'Successfully created note!',
      })

      onSuccess?.()
      drawerProps.onClose()
    }

    return (
      <FormDrawer {...drawerProps} {...baseProps} form={form} onSubmit={onSubmit}>
        {includeTemplateField && <NoteTemplateField templateParams={templateParams} />}

        <NoteFields entityCorn={entityCorn} />

        {includePublishField && <PublishNoteField />}

        {isError && (
          <ComposedAlert
            alert={{ title: 'Error!', description: 'Something went wrong when creating the note.' }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps: WithDrawerErrorBoundaryProps = { title: 'Create Note' }

const { useForm } = createForm(NoteFieldsSchema, 'createNoteForm')
