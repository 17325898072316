import { CreatableProps, CreatableSelect, GroupBase, Props, Select } from 'chakra-react-select'
import { omit, pick, pickBy } from 'lodash-es'
import { FieldValues, useController } from 'react-hook-form'
import { BaseProps, FormControl } from 'react-hook-form-chakra'
import { ConditionalPick } from 'type-fest'
import { WithFieldNameProp } from '../types/FieldProps'

export type ReactSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = WithFieldNameProp<Omit<BaseProps, 'onChange'>, TFieldValues> &
  TopLevelReactSelectProps<Option, IsMulti, Group> & {
    selectProps?: Omit<Props<Option, IsMulti, Group>, TopLevelReactSelectPropsKeys>
  }

export type ReactCreatableSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = WithFieldNameProp<BaseProps, TFieldValues> &
  TopLevelReactCreatableSelectProps<Option, IsMulti, Group> & {
    selectProps?: Omit<Props<Option, IsMulti, Group>, TopLevelReactCreatableSelectPropsKeys>
  }

export function ReactSelectField<
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ReactSelectFieldProps<TFieldValues, Option, IsMulti, Group>) {
  const { control, selectProps, ...rest } = props
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name: props.name,
    control,
  })

  const topLevelProps = pickTopLevelReactSelectProps(rest)
  const fieldProps = omitReactSelectProps(rest)

  return (
    <FormControl {...fieldProps}>
      <Select
        {...field}
        isDisabled={isSubmitting ?? rest.isDisabled}
        isReadOnly={rest.isReadOnly}
        {...selectProps}
        {...topLevelProps}
        onChange={(newValue, actionMeta) => {
          field.onChange(newValue)
          topLevelProps.onChange?.(newValue, actionMeta)
        }}
      />
    </FormControl>
  )
}

export function ReactCreatableSelectField<
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ReactCreatableSelectFieldProps<TFieldValues, Option, IsMulti, Group>) {
  const { control, selectProps, ...rest } = props
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name: props.name,
    control,
  })

  const topLevelProps = pickTopLevelReactCreatableSelectProps(rest)
  const fieldProps = omitReactCreatableSelectProps(rest)

  return (
    <FormControl {...fieldProps}>
      <CreatableSelect {...field} isDisabled={isSubmitting} {...selectProps} {...topLevelProps} />
    </FormControl>
  )
}

export type ReactSelectFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof ReactSelectField<TFieldValues>

export type ReactCreatableSelectFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof ReactSelectField<TFieldValues>

type SelectPropsKeys = keyof Props
type CreatableSelectPropsKeys = keyof CreatableProps<unknown, boolean, GroupBase<unknown>>

const selectProps = {
  'aria-errormessage': false,
  'aria-invalid': false,
  'aria-label': false,
  'aria-labelledby': false,
  'aria-live': false,
  ariaLiveMessages: false,
  autoFocus: true,
  backspaceRemovesValue: true,
  blurInputOnSelect: true,
  captureMenuScroll: true,
  chakraStyles: true,
  className: false,
  classNamePrefix: false,
  classNames: false,
  closeMenuOnScroll: true,
  closeMenuOnSelect: true,
  colorScheme: false,
  components: true,
  controlShouldRenderValue: true,
  defaultInputValue: true,
  defaultMenuIsOpen: true,
  defaultValue: true,
  delimiter: true,
  errorBorderColor: false,
  escapeClearsValue: true,
  filterOption: true,
  focusBorderColor: false,
  form: true,
  formatGroupLabel: true,
  formatOptionLabel: true,
  getOptionLabel: true,
  getOptionValue: true,
  hasStickyGroupHeaders: true,
  hideSelectedOptions: true,
  id: false,
  inputId: false,
  inputValue: false,
  instanceId: false,
  isClearable: true,
  isDisabled: true,
  isInvalid: true,
  isLoading: true,
  isMulti: true,
  isOptionDisabled: true,
  isOptionSelected: true,
  isReadOnly: true,
  isRequired: true,
  isRtl: true,
  isSearchable: true,
  loadingMessage: true,
  maxMenuHeight: false,
  menuIsOpen: true,
  menuPlacement: true,
  menuPortalTarget: true,
  menuPosition: true,
  menuShouldBlockScroll: true,
  menuShouldScrollIntoView: true,
  minMenuHeight: true,
  name: false,
  noOptionsMessage: true,
  onBlur: true,
  onChange: true,
  onFocus: true,
  onInputChange: true,
  onKeyDown: true,
  onMenuClose: true,
  onMenuOpen: true,
  onMenuScrollToBottom: true,
  onMenuScrollToTop: true,
  openMenuOnClick: true,
  openMenuOnFocus: true,
  options: true,
  pageSize: true,
  placeholder: true,
  required: true,
  screenReaderStatus: true,
  selectedOptionColor: false,
  selectedOptionStyle: false,
  size: false,
  styles: false,
  tabIndex: false,
  tabSelectsValue: true,
  tagVariant: false,
  theme: false,
  unstyled: false,
  useBasicStyles: false,
  value: true,
  variant: false,
  selectedOptionColorScheme: false,
} satisfies Record<SelectPropsKeys, boolean>

const creatableSelectProps = {
  ...selectProps,
  allowCreateWhileLoading: true,
  createOptionPosition: true,
  formatCreateLabel: true,
  getNewOptionData: true,
  isValidNewOption: true,
  onCreateOption: true,
} satisfies Record<CreatableSelectPropsKeys, boolean>

type TopLevelReactSelectPropsKeys = keyof ConditionalPick<typeof selectProps, true>
type TopLevelReactCreatableSelectPropsKeys = keyof ConditionalPick<
  typeof creatableSelectProps,
  true
>

type TopLevelReactSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Pick<Props<Option, IsMulti, Group>, TopLevelReactSelectPropsKeys>
type TopLevelReactCreatableSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Pick<CreatableProps<Option, IsMulti, Group>, TopLevelReactCreatableSelectPropsKeys>

const pickTopLevelReactSelectProps = <
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Omit<ReactSelectFieldProps<TFieldValues, Option, IsMulti, Group>, 'selectProps'>
) =>
  pick(props, Object.keys(pickBy(selectProps, (bool) => bool))) as TopLevelReactSelectProps<
    Option,
    IsMulti,
    Group
  >

const pickTopLevelReactCreatableSelectProps = <
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Omit<ReactCreatableSelectFieldProps<TFieldValues, Option, IsMulti, Group>, 'selectProps'>
) =>
  pick(
    props,
    Object.keys(pickBy(creatableSelectProps, (bool) => bool))
  ) as TopLevelReactCreatableSelectProps<Option, IsMulti, Group>

type OmittedReactSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<
  ReactSelectFieldProps<TFieldValues, Option, IsMulti, Group>,
  'selectProps' | TopLevelReactSelectPropsKeys
>

type OmittedReactCreatableSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Omit<
  ReactCreatableSelectFieldProps<TFieldValues, Option, IsMulti, Group>,
  'selectProps' | TopLevelReactCreatableSelectPropsKeys
>

const omitReactSelectProps = <
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Omit<ReactSelectFieldProps<TFieldValues, Option, IsMulti, Group>, 'selectProps'>
) =>
  omit(props, Object.keys(pickBy(selectProps, (bool) => bool))) as OmittedReactSelectProps<
    TFieldValues,
    Option,
    IsMulti,
    Group
  >

const omitReactCreatableSelectProps = <
  TFieldValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Omit<ReactCreatableSelectFieldProps<TFieldValues, Option, IsMulti, Group>, 'selectProps'>
) =>
  omit(
    props,
    Object.keys(pickBy(creatableSelectProps, (bool) => bool))
  ) as OmittedReactCreatableSelectProps<TFieldValues, Option, IsMulti, Group>
