import { Contact, ContactSchema } from '@eigtech/contacts-types'
import { z } from 'zod'

export const isContactSchema = (data: unknown): data is Contact =>
  ContactSchema.safeParse(data).success

export const ServerSideFilterSchema = z.object({
  field: z.string().min(1),
  value: z.string().min(1).or(ContactSchema),
  operator: z
    .enum([
      '=',
      '<',
      '>',
      '>=',
      '<=',
      '!=',
      'IS',
      'IS NOT',
      'LIKE',
      'NOT LIKE',
      'ILIKE',
      'NOT ILIKE',
      'WITHIN',
      'INCLUDES',
      'ANYOF',
    ])
    .optional(),
})

export const ServerSideFilterFormSchema = z.object({
  filter: z.array(ServerSideFilterSchema),
})

export type ServerSideFilterField = z.infer<typeof ServerSideFilterSchema>

export type ServerSideFilterForm = z.infer<typeof ServerSideFilterFormSchema>
