import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, MessageGroupIdSchema } from '../../base/index.js'

export const ListInboxMessageGroupsRequestPathSchema = z.object({
  inboxId: Auth0IdSchema,
})

export const ListInboxMessageGroupsRequestSchema = ListInboxMessageGroupsRequestPathSchema

export type ListInboxMessageGroupsRequestBody = z.infer<
  typeof ListInboxMessageGroupsRequestPathSchema
>
export type ListInboxMessageGroupsRequest = z.infer<typeof ListInboxMessageGroupsRequestSchema>

export const ListInboxMessageGroupsResponseSchema = MessageGroupIdSchema.array()
export type ListInboxMessageGroupsResponse = z.infer<typeof ListInboxMessageGroupsResponseSchema>

export interface ListInboxMessageGroupsHandler
  extends Handler<ListInboxMessageGroupsRequest, ListInboxMessageGroupsResponse> {}
