import { Cohort } from '@eigtech/auth0-types'
import type {
  GetNotebookForCohortRequest,
  GetNotebookForCohortResponse,
} from '@eigtech/notes-types'
import { Corn } from '@eigtech/shared-corn'
import {
  ApiInstanceFactoryReturn,
  HTTPError,
  useApiInstanceContext,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { notebooksBasePath, notebooksQueryKeys } from './constants'

const getNotebookForCohort = async (
  get: ApiInstanceFactoryReturn['get'],
  params: GetNotebookForCohortRequest
) => {
  try {
    const response = await get<GetNotebookForCohortResponse>(
      `${notebooksBasePath}/notebook/${params.entityId}/${params.cohort}`
    )
    return response
  } catch (error) {
    if (error instanceof HTTPError) {
      //This needs to return false, since `undefined` will throw in the the `useQuery`
      //Throws with: Query data cannot be undefined. Please make sure to return a value other than undefined from your query function.
      if (error.response.status === 404) return false
    }
    throw error
  }
}

export function useGetCsrNotebookForEntity(entityCorn: Corn) {
  const { get } = useApiInstanceContext()

  return useQuery({
    queryKey: notebooksQueryKeys.entityNotebook(entityCorn),
    queryFn: () => getNotebookForCohort(get, { entityId: entityCorn, cohort: Cohort.csr }),
  })
}

export function useGetEstimatorNotebookForEntity(entityCorn: Corn) {
  const { get } = useApiInstanceContext()

  return useQuery({
    queryKey: notebooksQueryKeys.entityNotebook(entityCorn),
    queryFn: () => getNotebookForCohort(get, { entityId: entityCorn, cohort: Cohort.estimator }),
  })
}

export function useGetTechnicianNotebookForEntity(entityCorn: Corn) {
  const { get } = useApiInstanceContext()

  return useQuery({
    queryKey: notebooksQueryKeys.entityNotebook(entityCorn),
    queryFn: () => getNotebookForCohort(get, { entityId: entityCorn, cohort: Cohort.technician }),
  })
}

export function useInvalidateNotebooks() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: notebooksQueryKeys.all() })
  }
}

export function useInvalidateEntityNotebook() {
  const queryClient = useQueryClient()

  return (entityCorn: Corn) => {
    queryClient.invalidateQueries({ queryKey: notebooksQueryKeys.entityNotebook(entityCorn) })
  }
}
