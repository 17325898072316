import { Locale } from '@eigtech/ui-shared-dates'
import { createContext, useContext } from 'react'

export type MonthlyCalendarState<DayData> = {
  days: Date[]
  selectedMonth: Date
  locale?: Locale
  onSelectedMonthChange: (date: Date) => any
  events: (DayData & {
    date: Date
  })[]
}

export const MonthlyCalendarContext = createContext<MonthlyCalendarState<any> | undefined>(
  undefined
)

export function useMonthlyCalendar<DayData>() {
  const context = useContext(MonthlyCalendarContext)

  if (!context) {
    throw new Error('useMonthlyCalendar needs to be used with the MonthlyCalendar component')
  }

  return context as MonthlyCalendarState<DayData>
}
