import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'

export const ListMeetingsRequestPathSchema = z.object({
  entityId: CornSchema,
})

export const ListMeetingsRequestHeaderSchema = ApiLambdalerAuthHeaderSchema

export const ListMeetingsRequestSchema = ListMeetingsRequestPathSchema.merge(
  ListMeetingsRequestHeaderSchema
)

export const ListMeetingsResponseSchema = z.array(MeetingDetailsSchema)

export type ListMeetingsRequest = z.infer<typeof ListMeetingsRequestSchema>
export type ListMeetingsRequestHeader = z.infer<typeof ListMeetingsRequestHeaderSchema>
export type ListMeetingsRequestPath = z.infer<typeof ListMeetingsRequestPathSchema>
export type ListMeetingsResponse = z.infer<typeof ListMeetingsResponseSchema>

export interface ListMeetingsHandler extends Handler<ListMeetingsRequest, ListMeetingsResponse> {}
