import { ContactCornSchema } from '@eigtech/contacts-types'
import z from 'zod'

export const CustomerContactOutcomeSchema = z.enum([
  'callbackRequested',
  'emailInvalid',
  'numberInvalid',
  'success',
  'voicemail',
])
export const CustomerContactOutcome = CustomerContactOutcomeSchema.options

export const CustomerContactMethodSchema = z.enum(['phone', 'text', 'email'])
export const CustomerContactMethod = CustomerContactMethodSchema.options

export const CustomerContactAttemptSchema = z.object({
  contactedBy: ContactCornSchema,
  contactAttemptedDate: z.string().datetime(),
  customerContacted: ContactCornSchema.or(z.string()),
  contactOutcome: CustomerContactOutcomeSchema,
  contactMethod: CustomerContactMethodSchema,
})

export type CustomerContactOutcomeEnum = z.infer<typeof CustomerContactOutcomeSchema>
export type CustomerContactMethodEnum = z.infer<typeof CustomerContactMethodSchema>
export type CustomerContactAttempt = z.infer<typeof CustomerContactAttemptSchema>
