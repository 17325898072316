import { Center, CircularProgress } from '@eigtech/ui-shared-dave'
import { MeetingStatus, useMeetingStatus } from 'amazon-chime-sdk-component-library-react'

export function BeforeMeeting() {
  const meetingStatusEnum = useMeetingStatus()
  const isInMeeting = meetingStatusEnum === MeetingStatus.Succeeded
  const isMeetingClosed = meetingStatusEnum === MeetingStatus.Ended

  if (isInMeeting || isMeetingClosed) return null

  return (
    <Center>
      <CircularProgress isIndeterminate size="50" />
    </Center>
  )
}
