// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts

import { inputAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

// const baseStyle = definePartsStyle({
//   field: {},
//   addon: {},
// })

// const sizes = {}

const variantOutline = definePartsStyle({
  field: {
    background: 'white',
  },
})

// const variantFilled = definePartsStyle({
//   field: {},
//   addon: {},
// })

// const variantFlushed = definePartsStyle({
//   field: {},
//   addon: {},
// })

// const variantUnstyled = definePartsStyle({
//   field: {},
//   addon: {},
// })

const variants = {
  outline: variantOutline,
  //   filled: variantFilled,
  //   flushed: variantFlushed,
  //   unstyled: variantUnstyled,
}

export const inputTheme = defineMultiStyleConfig({
  // baseStyle,
  // sizes,
  variants,
  // defaultProps: {},
})
