import z from 'zod'
import { TimelineEntityTypeSchema } from '../timeline'
import { StepFunctionTaskContextSchema } from './context'
import { Handler } from 'aws-lambda'
import { SetupTimelineSyncResponseSchema } from './setup'

export const IncrementPageResponseSchema = z.object({
  entityType: TimelineEntityTypeSchema,
  executionId: z.string(),
  hasMorePages: z.boolean(),
  nextPage: z.number(),
})
export type IncrementPageResponse = z.infer<typeof IncrementPageResponseSchema>

export const IncrementPageInputSchema = z.object({
  setupResults: SetupTimelineSyncResponseSchema,
  incrementPageResults: IncrementPageResponseSchema.optional(),
})
export type IncrementPageInput = z.infer<typeof IncrementPageInputSchema>

export const IncrementPageRequestSchema = z.object({
  context: StepFunctionTaskContextSchema,
  input: IncrementPageInputSchema,
})
export type IncrementPageRequest = z.infer<typeof IncrementPageRequestSchema>

export interface IncrementPageHandler
  extends Handler<IncrementPageRequest, IncrementPageResponse> {}
