import { ClaimStatusSchema } from '@eigtech/claims-v2-types'
import { Contact } from '@eigtech/contacts-types'
import { formatUsPhoneToHtml, getContactName } from '@eigtech/contacts-util'
import { CheckIcon, CloseIcon } from '@eigtech/ui-estimator/components/Core'
import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'
import { CarrierSelect } from '@eigtech/ui-shared-contacts'
import {
  ACTION_MENU_ID,
  ColumnMeta,
  CustomFilterFn,
  DataGridBooleanFilter,
  DataGridDateFilter,
  DataGridFilterContainer,
  DataGridSelectFilter,
  FilterFnOption,
  Table,
  createColumnHelper,
  dateFilterFns,
  dateTimeFilterFns,
  getFilterActualName,
} from '@eigtech/ui-shared-data-grid'
import { STANDARD_DATE_TIME_FORMAT, formatStringAsDate } from '@eigtech/ui-shared-dates'
import {
  FancyLink,
  Icon,
  TableCellProps,
  TableColumnHeaderProps,
  Tooltip,
  chakra,
} from '@eigtech/ui-shared-dave'
import { US_STATES_AS_OPTIONS } from '@eigtech/ui-shared-us-states'
import { startCase } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'
import { FaGavel } from 'react-icons/fa'
import { type MergeDeep } from 'type-fest'
import { ClaimActionMenu } from './ClaimActionMenu'
import { ClaimNumberCell } from './ClaimNumberCell'

export type AugmentedEstimatorClaimSummary = MergeDeep<
  DelegateAdminClaimSummary,
  {
    catCode: string
    primaryContact: {
      name: string
      email: string
      phone: string
    }
    lossLocation: {
      county: string
    }
  }
>

const columnHelper = createColumnHelper<AugmentedEstimatorClaimSummary>()

export function useClaimsColumns(table: React.RefObject<Table<AugmentedEstimatorClaimSummary>>) {
  const [inspectionScheduledFilterFn, setInspectionScheduledFilterFn] =
    useState<FilterFnOption<AugmentedEstimatorClaimSummary>>('dateEquals')
  const [inspectionCompletedFilterFn, setInspectionCompletedFilterFn] =
    useState<FilterFnOption<AugmentedEstimatorClaimSummary>>('dateEquals')
  const [lossDateFilterFn, setLossDateFilterFn] =
    useState<FilterFnOption<AugmentedEstimatorClaimSummary>>('dateEquals')
  const [dateReceivedFilterFn, setDateReceivedFilterFn] =
    useState<FilterFnOption<AugmentedEstimatorClaimSummary>>('dateEquals')

  const columns = useMemo(
    () =>
      [
        columnHelper.display({
          id: ACTION_MENU_ID,
          cell: (cell) => <ClaimActionMenu claim={cell.row.original} />,
          maxSize: 48,
          enableHiding: false,
          meta: {
            hidePinButton: true,
          },
        }),
        columnHelper.accessor('claimNumber', {
          id: 'claimNumber',
          header: 'Claim Number',
          cell: (cell) => <ClaimNumberCell value={cell.getValue()} />,
        }),
        columnHelper.accessor('primaryContact.name', {
          id: 'primaryContactName',
          header: 'Primary Contact',
        }),
        columnHelper.accessor('primaryContact.email', {
          id: 'primaryContactEmail',
          header: 'Email',
          cell(cell) {
            const email = cell.getValue()
            return (
              !!email && (
                <FancyLink href={`mailto:${email}`} iconPosition="left">
                  {email}
                </FancyLink>
              )
            )
          },
        }),
        columnHelper.accessor('primaryContact.phone', {
          id: 'primaryContactPhone',
          header: 'Phone',
          cell(cell) {
            const phoneNumber = cell.getValue()
            return (
              !!phoneNumber && (
                <FancyLink href={`tel:${formatUsPhoneToHtml(phoneNumber)}`} iconPosition="left">
                  {phoneNumber}
                </FancyLink>
              )
            )
          },
        }),
        columnHelper.accessor('carrier', {
          id: 'carrier',
          header: 'Carrier',
          filterFn(row, columnId, filterValue) {
            const carrierName = row.getValue<AugmentedEstimatorClaimSummary['carrier']>(columnId)

            if (!filterValue) return false

            return getContactName(filterValue as Contact) === carrierName
          },
          meta: {
            filterLabel(value) {
              return `is ${getContactName(value as unknown as Contact) ?? '(Unknown)'}`
            },
            FilterComponent: ({ column }) => {
              const ref = useRef<HTMLDivElement>(null)
              return (
                <DataGridFilterContainer
                  ref={ref}
                  column={column}
                  label={
                    <>
                      <chakra.span fontStyle="italic">Carrier</chakra.span> is:
                    </>
                  }
                >
                  <CarrierSelect
                    chakraStyles={{
                      container: (provided) => ({
                        ...provided,
                        w: 'full',
                        mr: '10',
                      }),
                    }}
                    menuPortalTarget={ref.current}
                    styles={{
                      menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 'var(--chakra-zIndices-modal)',
                      }),
                    }}
                    value={column.getFilterValue() as Contact}
                    onChange={(value) => column.setFilterValue(value)}
                  />
                </DataGridFilterContainer>
              )
            },
          },
        }),
        columnHelper.accessor('claimStatus', {
          id: 'claimStatus',
          header: 'Claim Status',
          meta: {
            FilterComponent: (props) => (
              <DataGridSelectFilter
                {...props}
                options={ClaimStatusSchema.options.map((status) => ({
                  label: status,
                  value: status,
                }))}
              />
            ),
          },
        }),
        columnHelper.accessor('latestTimelineEvent', {
          id: 'latestTimelineEvent',
          header: 'Last Event',
        }),
        columnHelper.accessor('dateReceived', {
          id: 'dateReceived',
          header: 'Date Received',
          cell: (cell) => formatStringAsDate(cell.getValue(), STANDARD_DATE_TIME_FORMAT),
          filterFn: dateReceivedFilterFn,
          meta: {
            availableFilterFns: Object.keys({ ...dateFilterFns, ...dateTimeFilterFns }).map(
              (value) => ({
                label: startCase(value),
                value: value as CustomFilterFn,
              })
            ),
            onFilterFnChange: setDateReceivedFilterFn,
          },
        }),
        columnHelper.accessor('lossDate', {
          id: 'lossDate',
          header: 'Loss Date',
          cell: (cell) => formatStringAsDate(cell.getValue(), STANDARD_DATE_TIME_FORMAT),
          filterFn: lossDateFilterFn,
          meta: {
            availableFilterFns: Object.keys({ ...dateFilterFns, ...dateTimeFilterFns }).map(
              (value) => ({
                label: startCase(value),
                value: value as CustomFilterFn,
              })
            ),
            onFilterFnChange: setLossDateFilterFn,
          },
        }),
        columnHelper.accessor('inspectionScheduled', {
          id: 'inspectionScheduled',
          header: 'Inspection Scheduled',
          cell: (cell) => {
            const date = cell.getValue()
            if (!date) return 'TBD'
            return formatStringAsDate(date, STANDARD_DATE_TIME_FORMAT)
          },
          filterFn: inspectionScheduledFilterFn,
          meta: {
            availableFilterFns: [
              ...Object.keys({ ...dateFilterFns, ...dateTimeFilterFns }).map((value) => ({
                label: startCase(value),
                value: value as CustomFilterFn,
              })),
              {
                label: 'Date is TBD',
                value: 'equals', // type hack
              },
            ],
            onFilterFnChange: (fnOption) => {
              if (fnOption === 'equals') {
                // If selection is TBD
                const column = table.current?.getColumn('inspectionScheduled')
                column?.setFilterValue('TBD')
              }
              return setInspectionScheduledFilterFn(fnOption)
            },
            FilterComponent: ({ column }) => {
              const filterName = getFilterActualName(column.getFilterFn()?.name ?? '')

              if (filterName === 'equals') {
                return <></>
              }

              return <DataGridDateFilter column={column} />
            },
          },
        }),
        columnHelper.accessor('inspectionCompleted', {
          id: 'inspectionCompleted',
          header: 'Inspection Completed',
          cell: (cell) => {
            const date = cell.getValue()
            if (!date) return 'TBD'
            return formatStringAsDate(date, STANDARD_DATE_TIME_FORMAT)
          },
          filterFn: inspectionCompletedFilterFn,
          meta: {
            availableFilterFns: [
              ...Object.keys({ ...dateFilterFns, ...dateTimeFilterFns }).map((value) => ({
                label: startCase(value),
                value: value as CustomFilterFn,
              })),
              {
                label: 'Date is TBD',
                value: 'equals', // type hack
              },
            ],
            onFilterFnChange: (fnOption) => {
              if (fnOption === 'equals') {
                // If selection is TBD
                const column = table.current?.getColumn('inspectionCompleted')
                column?.setFilterValue('TBD')
              }
              return setInspectionCompletedFilterFn(fnOption)
            },
            FilterComponent: ({ column }) => {
              const filterName = getFilterActualName(column.getFilterFn()?.name ?? '')

              if (filterName === 'equals') {
                return <></>
              }

              return <DataGridDateFilter column={column} />
            },
          },
        }),
        columnHelper.accessor('hasLegalRep', {
          id: 'hasLegalRep',
          header: () => (
            <Tooltip label="Has Legal Representation?" shouldWrapChildren>
              <Icon as={FaGavel} />
            </Tooltip>
          ),
          cell: (cell) => (cell.getValue() ? <CheckIcon /> : <CloseIcon />),
          meta: {
            headerLabel: 'Has Legal Rep?',
            FilterComponent: ({ column }) => <DataGridBooleanFilter column={column} />,
          },
        }),
        columnHelper.accessor('lossLocation.city', {
          id: 'city',
          header: 'City',
        }),
        columnHelper.accessor('lossLocation.state', {
          id: 'state',
          header: 'State',
          meta: {
            FilterComponent: ({ column }) => (
              <DataGridSelectFilter column={column} options={US_STATES_AS_OPTIONS} />
            ),
          },
        }),
        columnHelper.accessor('lossLocation.county', {
          id: 'county',
          header: 'County',
        }),
        columnHelper.accessor('catCode', {
          id: 'catCode',
          header: 'CAT Code',
        }),
        columnHelper.accessor('hasJob', {
          id: 'hasJob',
          header: 'Has Job?',
          cell: (cell) => (cell.getValue() ? <CheckIcon /> : <CloseIcon />),
          meta: {
            headerLabel: 'Has Job?',
            FilterComponent: ({ column }) => <DataGridBooleanFilter column={column} />,
          },
        }),
      ].map((column) => {
        const columnHeaderProps: TableColumnHeaderProps = {
          py: '1',
          px: '2',
          whiteSpace: 'pre',
          ...(column.meta?.columnHeaderProps ?? {}),
        }

        const cellProps: ColumnMeta<AugmentedEstimatorClaimSummary, any>['cellProps'] = (
          cell,
          index
        ) => {
          const isPinned = cell.column.getIsPinned()
          const pinnedProps: TableCellProps = {
            position: 'sticky',
            border: 'none',
            boxShadow: `${
              index === 0 ? 'inset 1px 0 0 var(--chakra-colors-gray-200), ' : ''
            }inset -1px -1px 0 var(--chakra-colors-gray-200), inset 0 0 0 var(--chakra-colors-gray-200)`,
          }

          return {
            py: '1',
            px: '2',
            borderWidth: '1px',
            borderColor: 'gray.200',
            whiteSpace: 'pre',
            ...(isPinned ? pinnedProps : {}),
            ...(typeof column.meta?.cellProps === 'function'
              ? column.meta?.cellProps(cell, index)
              : column.meta?.cellProps ?? {}),
          }
        }

        return {
          ...column,
          meta: {
            ...(column.meta ?? {}),
            columnHeaderProps,
            cellProps,
          },
        }
      }),
    [
      dateReceivedFilterFn,
      inspectionCompletedFilterFn,
      inspectionScheduledFilterFn,
      lossDateFilterFn,
      table,
    ]
  )
  return {
    columns,
    setInspectionScheduledFilterFn,
  }
}
