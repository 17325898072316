import type { Except, Simplify } from 'type-fest'
import { Corn } from '../types/Corn.js'

/**
 * @example
 * // returns 'corn:claims:claim:223SSU6SKL'
 * makeCorn({
 *   serviceName: 'claims',
 *   resourceType: 'claim',
 *   resourceId: '223SSU6SKL',
 * })
 *
 * // with wrapper function
 * const makeClaimCorn = (claimNumber: string) => makeCorn({
 *     serviceName: 'claims',
 *     resourceType: 'claim',
 *     resourceId: claimNumber,
 *   })
 * @param options.serviceName Name of service for corn (e.g. "claims")
 * @param options.resourceType Type of resource for corn (e.g. "claim")
 * @param options.resourceId Unique ID for resource, such as claim number, guid, etc
 * @returns Valid corn (e.g. "corn:claims:claim:223SSU6SKL")
 */
export function makeCorn<
  ServiceName extends string,
  ResourceType extends string,
  ResourceId extends string,
>({
  serviceName,
  resourceType,
  resourceId,
}: MakeCornProps<
  ServiceName,
  ResourceType,
  ResourceId
>): `corn:${ServiceName}:${ResourceType}:${ResourceId}` {
  return `corn:${serviceName}:${resourceType}:${resourceId}`
}

export type MakeCornProps<
  ServiceName extends string,
  ResourceType extends string,
  ResourceId extends string,
> = {
  serviceName: ServiceName
  resourceType: ResourceType
  resourceId: EnforceNonCornId<ResourceId>
}

export type EnforceNonCornId<T extends string> = T extends Corn ? never : T

export type SetEnforceNonCornId<BaseType, Keys extends keyof BaseType, T extends string> = Simplify<
  Except<BaseType, Keys> & {
    [Key in Keys]: EnforceNonCornId<T>
  }
>
