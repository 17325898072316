import {
  jobDefaultChronicleLabelMap,
  JobDefaultChronicleTypeEnum,
  JobDefaultChronicleTypeSchema,
} from '@eigtech/summarizer-types'

export const getJobTimelineEventLabel = (event: JobDefaultChronicleTypeEnum) =>
  jobDefaultChronicleLabelMap[event]

const historicalPointTypes: string[] = []

export const jobTimelineEventLabels = Object.fromEntries(
  JobDefaultChronicleTypeSchema.options
    .filter((pointType) => !historicalPointTypes.includes(pointType))
    .map((eventKey) => [eventKey, getJobTimelineEventLabel(eventKey)])
) as Record<JobDefaultChronicleTypeEnum, string>

export const jobTimelineEventLabelsArray = [
  ...new Set(Object.values(jobTimelineEventLabels)),
].sort()
