import { Contact } from '@eigtech/contacts-types'
import { getContactName, useGetFieldAdjusterBook } from '@eigtech/ui-shared-contacts'
import {
  FieldValues,
  FormFieldControl,
  FormFieldControlProps,
  WithFieldNameProp,
  useController,
} from '@eigtech/ui-shared-forms'
import { GroupBase, Props, Select, SelectInstance } from 'chakra-react-select'
import { ReactElement, ReactNode, RefAttributes, forwardRef } from 'react'

export const FieldAdjusterSelect = forwardRef(function FieldAdjusterSelect(props, ref) {
  const { data, isPending } = useGetFieldAdjusterBook()
  return (
    <Select
      ref={ref}
      {...props}
      getOptionLabel={(contact) => getContactName(contact)}
      getOptionValue={(contact) => contact.contactId}
      isLoading={isPending}
      menuPortalTarget={document.body}
      options={data?.contacts ?? []}
    />
  )
}) as (
  props: Props<Contact, false, GroupBase<Contact>> &
    RefAttributes<SelectInstance<Contact, false, GroupBase<Contact>>>
) => ReactElement

export type FieldAdjusterSelectFieldProps<TFieldValues extends FieldValues = FieldValues> =
  WithFieldNameProp<
    Omit<Props<Contact, false, GroupBase<Contact>>, keyof FormFieldControlProps>,
    TFieldValues
  > & {
    label: ReactNode
    fieldProps?: Omit<FormFieldControlProps, 'label' | 'name'>
  }

export function FieldAdjusterSelectField<TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  fieldProps,
  ...selectProps
}: FieldAdjusterSelectFieldProps<TFieldValues>) {
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control: fieldProps?.control,
  })

  return (
    <FormFieldControl {...fieldProps} label={label} name={name}>
      <FieldAdjusterSelect {...field} isDisabled={isSubmitting} {...selectProps} />
    </FormFieldControl>
  )
}
