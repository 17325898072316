import {
  BookCorn,
  BookCornSchema,
  ContactCorn,
  GlobalBookCornCanon,
  GlobalBookResourceIdCanon,
  isContactCorn,
} from '@eigtech/contacts-types'
import { EnforceNonCornId, getCornKernels, makeCorn } from '@eigtech/shared-corn'

export const makeBookCorn = <T extends string>(resourceId: EnforceNonCornId<T>): BookCorn =>
  makeCorn({ serviceName: 'contacts', resourceType: 'book', resourceId })

export const makeGlobalBookCorn = (resourceId: GlobalBookResourceIdCanon): GlobalBookCornCanon =>
  `corn:contacts:book:${resourceId}`

export const ensureBookCorn = (bookId: string | BookCorn): BookCorn =>
  isBookCorn(bookId) ? bookId : makeBookCorn(bookId)

export const ensureContactCorn = (contactId: string): ContactCorn =>
  isContactCorn(contactId) ? contactId : `corn:contacts:contact:${contactId}`

export const isBookCorn = (bookId: string): bookId is BookCorn =>
  BookCornSchema.safeParse(bookId).success

export const getGlobalBookResourceId = (corn: GlobalBookCornCanon): GlobalBookResourceIdCanon =>
  getCornKernels(corn).resourceId as GlobalBookResourceIdCanon
