import { ContactCornSchema } from '@eigtech/contacts-types'
import { z } from 'zod'
import { BaseClaimSchema, ClaimNumberSchema } from '../model/base'
import {
  CanceledInspectionSchema,
  CompletedInspectionSchema,
  InspectionDetailsSchema,
  InspectionStatusEnum,
  RequestedInspectionSchema,
  ScheduledInspectionSchema,
} from '../model/inspections'
import { PublicationTargetsSchema } from '../model/publishable'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim'

export const InspectionEventSchema = ClaimEventSchema.merge(
  z.object({
    claim: BaseClaimSchema.merge(
      z.object({
        inspection: InspectionDetailsSchema,
      })
    ),
    metadata: ClaimEventSchema.shape.metadata.and(
      z.object({
        publishToXa: z.boolean(),
      })
    ),
  })
)

// #region Completed
export const ClaimInspectionCompletedEventSchema = InspectionEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.inspectionCompleted),
    claim: BaseClaimSchema.merge(
      z.object({
        inspection: CompletedInspectionSchema,
      })
    ),
  })
)
export const ClaimInspectionCompletedEventInputSchema = z
  .object({
    claimNumber: ClaimNumberSchema,
    completedBy: ContactCornSchema.or(z.string()).optional(),
    publicationTargets: PublicationTargetsSchema.array().optional(),
    requestActor: z.string(),
  })
  .merge(
    InspectionDetailsSchema.omit({ dateCanceled: true, scheduledDate: true }).required({
      dateCompleted: true,
      origin: true,
    })
  )
  .merge(z.object({ status: z.literal(InspectionStatusEnum.completed) }))
// #endregion Completed
// #region Canceled
export const ClaimInspectionCanceledEventSchema = InspectionEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.inspectionCanceled),
    claim: BaseClaimSchema.merge(
      z.object({
        inspection: CanceledInspectionSchema,
      })
    ),
  })
)
export const ClaimInspectionCanceledEventInputSchema = z
  .object({
    canceledBy: ContactCornSchema.or(z.string()).optional(),
    claimNumber: ClaimNumberSchema,
    requestActor: z.string(),
  })
  .merge(
    InspectionDetailsSchema.required({
      dateCanceled: true,
      origin: true,
    })
  )
  .merge(z.object({ status: z.literal(InspectionStatusEnum.canceled) }))
// #endregion Canceled
// #region Scheduled
export const ClaimInspectionScheduledEventSchema = InspectionEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.inspectionScheduled),
    claim: BaseClaimSchema.merge(
      z.object({
        inspection: ScheduledInspectionSchema,
      })
    ),
  })
)
export const ClaimInspectionScheduledEventInputSchema = z
  .object({
    claimNumber: ClaimNumberSchema,
    publicationTargets: PublicationTargetsSchema.array().optional(),
    requestActor: z.string(),
    scheduledBy: ContactCornSchema.or(z.string()).optional(),
  })
  .merge(
    InspectionDetailsSchema.omit({
      canceledBy: true,
      completedBy: true,
      dateCanceled: true,
      dateCompleted: true,
    }).required({ scheduledDate: true, inspectorRole: true, origin: true })
  )
  .merge(z.object({ status: z.literal(InspectionStatusEnum.scheduled) }))
// #endregion Scheduled
// #region Requested
export const ClaimReInspectionRequestedEventSchema = InspectionEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.reInspectionRequested),
    claim: BaseClaimSchema.merge(
      z.object({
        inspection: RequestedInspectionSchema,
      })
    ),
  })
)
export const ClaimReInspectionRequestedEventInputSchema = z
  .object({
    claimNumber: ClaimNumberSchema,
    requestActor: z.string(),
    requestedBy: ContactCornSchema.or(z.string()).optional(),
  })
  .merge(
    InspectionDetailsSchema.omit({
      canceledBy: true,
      completedBy: true,
      dateCanceled: true,
      dateCompleted: true,
      scheduledDate: true,
    }).required({ inspectorRole: true, origin: true })
  )
  .merge(z.object({ status: z.literal(InspectionStatusEnum.requested) }))
// #endregion Requested

export type InspectionEvent = z.infer<typeof InspectionEventSchema>
export type ClaimInspectionCompletedEvent = z.infer<typeof ClaimInspectionCompletedEventSchema>
export type ClaimInspectionCompletedEventInput = z.infer<
  typeof ClaimInspectionCompletedEventInputSchema
>
export type ClaimInspectionCanceledEvent = z.infer<typeof ClaimInspectionCanceledEventSchema>
export type ClaimInspectionCanceledEventInput = z.infer<
  typeof ClaimInspectionCanceledEventInputSchema
>
export type ClaimInspectionScheduledEvent = z.infer<typeof ClaimInspectionScheduledEventSchema>
export type ClaimInspectionScheduledEventInput = z.infer<
  typeof ClaimInspectionScheduledEventInputSchema
>
export type ClaimReInspectionRequestedEvent = z.infer<typeof ClaimReInspectionRequestedEventSchema>
export type ClaimReInspectionRequestedEventInput = z.infer<
  typeof ClaimReInspectionRequestedEventInputSchema
>
