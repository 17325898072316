// import { accordionTheme } from './accordion'
import { alertTheme } from './alert'
// import { avatarTheme } from './avatar'
import { badgeTheme } from './badge'
// import { breadcrumbTheme } from './breadcrumb'
import { buttonTheme } from './button'
import { cardTheme } from './card'
// import { checkboxTheme } from './checkbox'
// import { closeButtonTheme } from './close-button'
// import { codeTheme } from './code'
import { containerTheme } from './container'
// import { dividerTheme } from './divider'
import { drawerTheme } from './drawer'
// import { editableTheme } from './editable'
// import { formTheme } from './form-control'
// import { formErrorTheme } from './form-error'
// import { formLabelTheme } from './form-label'
// import { headingTheme } from './heading'
import { inputTheme } from './input'
// import { kbdTheme } from './kbd'
import { linkTheme } from './link'
// import { listTheme } from './list'
import { menuTheme } from './menu'
// import { modalTheme } from './modal'
// import { numberInputTheme } from './number-input'
// import { pinInputTheme } from './pin-input'
// import { popoverTheme } from './popover'
// import { progressTheme } from './progress'
// import { radioTheme } from './radio'
import { selectTheme } from './select'
import { skeletonTheme } from './skeleton'
// import { skipLinkTheme } from './skip-link'
// import { sliderTheme } from './slider'
// import { spinnerTheme } from './spinner'
// import { statTheme } from './stat'
// import { switchTheme } from './switch'
import { tableTheme } from './table'
// import { tabsTheme } from './tabs'
import { tagTheme } from './tag'
// import { textareaTheme } from './textarea'
import { tooltipTheme } from './tooltip'

export const components = {
  // Accordion: accordionTheme,
  Alert: alertTheme,
  // Avatar: avatarTheme,
  Badge: badgeTheme,
  // Breadcrumb: breadcrumbTheme,
  Button: buttonTheme,
  Card: cardTheme,
  // Checkbox: checkboxTheme,
  // CloseButton: closeButtonTheme,
  // Code: codeTheme,
  Container: containerTheme,
  // Divider: dividerTheme,
  Drawer: drawerTheme,
  // Editable: editableTheme,
  // Form: formTheme,
  // FormError: formErrorTheme,
  // FormLabel: formLabelTheme,
  // Heading: headingTheme,
  Input: inputTheme,
  // Kbd: kbdTheme,
  Link: linkTheme,
  // List: listTheme,
  Menu: menuTheme,
  // Modal: modalTheme,
  // NumberInput: numberInputTheme,
  // PinInput: pinInputTheme,
  // Popover: popoverTheme,
  // Progress: progressTheme,
  // Radio: radioTheme,
  Select: selectTheme,
  Skeleton: skeletonTheme,
  // SkipLink: skipLinkTheme,
  // Slider: sliderTheme,
  // Spinner: spinnerTheme,
  // Stat: statTheme,
  // Switch: switchTheme,
  Table: tableTheme,
  // Tabs: tabsTheme,
  Tag: tagTheme,
  // Textarea: textareaTheme,
  Tooltip: tooltipTheme,
}
