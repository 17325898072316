import { EventBaseSchema, eventType } from '@eigtech/event-stream-types'
import { z } from 'zod'

export const serviceName = 'veriskEDI'

export const veriskEdiServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const VeriskEDIEventSchema = EventBaseSchema.merge(
  z.object({
    type: veriskEdiServiceEventType(),
  })
)
export type VeriskEDIEvent = z.infer<typeof VeriskEDIEventSchema>
