import { isISOish } from '@eigtech/function-utils'
import { ZodSchema, z } from 'zod'
import { timelineServiceCorn } from '../base/service.js'

// Timeline
export const TimelineSourceSchema = z.enum(['Verisk', 'CSR', 'TPLAP', 'Estimator'])
export const TIMELINE_SOURCE = TimelineSourceSchema.Values

export const TimelinePointSchema = z.object({
  id: z.string(),
  createdBy: z.string().optional(),
  dataSource: TimelineSourceSchema.or(z.string()),
  date: z.string().refine(isISOish, { message: 'not an ISO date' }).optional(),
  type: z.string(),
  label: z.string(),
  details: z.record(z.unknown()).optional(),
})

export const TimelineSchema = z.object({
  id: timelineServiceCorn(),
  type: z.string(),
  points: z.array(TimelinePointSchema),
})

export const DefaultTimelineSchema = TimelineSchema.omit({ id: true })

export type DefaultTimeline = z.infer<typeof DefaultTimelineSchema>
export type Timeline = z.infer<typeof TimelineSchema>
export type TimelinePoint = z.infer<typeof TimelinePointSchema>
export type TimelineSource = z.infer<typeof TimelineSourceSchema>

/* eslint-disable @typescript-eslint/no-explicit-any */
export type TimelineEventMapping = {
  schema: ZodSchema<any, any, any>
  handler: (event: any) => TimelinePoint | undefined
}
