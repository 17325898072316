import { pick } from 'lodash-es'
import {
  useApplyVideoObjectFit,
  useAudioVideo,
  useLocalVideo,
} from 'amazon-chime-sdk-component-library-react'
import { useEffect, useRef } from 'react'
import { VideoTile, VideoTileProps } from './VideoTile'
import { MeetingState, useMeetingStore } from '../../context'

export type LocalVideoProps = {
  id?: string
  isActiveVideo?: boolean
} & VideoTileProps

export function LocalVideo({ ...props }: LocalVideoProps) {
  const { tileId, isVideoEnabled } = useLocalVideo()
  const audioVideo = useAudioVideo()
  const videoEl = useRef<HTMLVideoElement>(null)
  useApplyVideoObjectFit(videoEl)

  useEffect(() => {
    if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
      return
    }

    audioVideo.bindVideoElement(tileId, videoEl.current)

    return () => {
      const tile = audioVideo.getVideoTile(tileId)
      if (tile) {
        audioVideo.unbindVideoElement(tileId)
      }
    }
  }, [audioVideo, tileId, isVideoEnabled])

  const { chimeAttendee } = useMeetingStore(stateSelector)

  return (
    <VideoTile
      ref={videoEl}
      attendeeId={chimeAttendee?.AttendeeId}
      id="local"
      isActiveVideo={props.isActiveVideo}
      tileId={tileId === null ? undefined : tileId}
      {...props}
    />
  )
}

const stateSelector = (state: MeetingState) => pick(state, ['chimeAttendee'])
