import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0UserSchema } from '../../logStreamEvents/base/base.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetRecordedUsersRequestSchema = z.void()

export const GetRecordedUsersResponseSchema = z.object({
  users: Auth0UserSchema.array(),
})

export const GetRecordedUsersApiSchema = {
  response: GetRecordedUsersResponseSchema,
} satisfies ApiSchema

export type GetRecordedUsersRequest = z.infer<typeof GetRecordedUsersRequestSchema>
export type GetRecordedUsersResponse = z.infer<typeof GetRecordedUsersResponseSchema>
export type GetRecordedUsersHandler = Handler<GetRecordedUsersRequest, GetRecordedUsersResponse>
