import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import {
  CommunicationTemplateSchema,
  NoteTemplateSchema,
  Template,
  TimelineTemplateSchema,
} from '../aggregates/template.js'

const omitProps = {
  lastUpdated: true,
  createdOn: true,
  modifiedBy: true,
  id: true,
} as const

export const CreateTemplateApiSchema = {
  body: z.discriminatedUnion('type', [
    CommunicationTemplateSchema.omit(omitProps),
    NoteTemplateSchema.omit(omitProps),
    TimelineTemplateSchema.omit(omitProps),
  ]),
  headers: z.object({
    requestActor: z.string().optional(),
  }),
} satisfies ApiSchema

export const CreateTemplateRequestSchema = CreateTemplateApiSchema.body.and(
  CreateTemplateApiSchema.headers
)

export type CreateTemplateHeaderParameters = z.infer<typeof CreateTemplateApiSchema.headers>
export type CreateTemplateRequestBodyParameters = z.infer<typeof CreateTemplateApiSchema.body>
export type CreateTemplateRequest = z.infer<typeof CreateTemplateRequestSchema>
export type CreateTemplateResponse = Template
export type CreateTemplateHandler = Handler<CreateTemplateRequest, CreateTemplateResponse>
