import { CohortEnumSchema } from '@eigtech/auth0-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const AddDocumentCohortRequestPathSchema = z.object({
  entityId: CornSchema,
  cohort: CohortEnumSchema,
  documentCorn: CornSchema,
})

export const AddDocumentCohortRequestSchema = AddDocumentCohortRequestPathSchema

export type AddDocumentCohortRequestPath = z.infer<typeof AddDocumentCohortRequestSchema>
export type AddDocumentCohortRequest = z.infer<typeof AddDocumentCohortRequestSchema>
export type AddDocumentCohortResponse = void

export type AddDocumentCohortHandler = Handler<AddDocumentCohortRequest, AddDocumentCohortResponse>
