import {
  DeprecatedClaimStatusEventNameEnumSchema,
  ProducerClaimStatusEventNameEnumSchema,
} from '@eigtech/claims-types'
import {
  DefaultTimelineTemplateSchema,
  makeTimelineInstructionTemplateSchema,
} from '@eigtech/templates-types'
import z from 'zod'
import { timelineServiceCorn } from '../base/service.js'
import { StatusReductionSchema } from '../base/statusReduction.js'
import { TimelinePointSchema, TimelineSchema } from '../base/timeline.js'

// Timeline Type
export const DefaultClaimStatusTimelineTypeSchema = z.literal('defaultClaimStatus')

// Timeline Corn
export const DefaultClaimStatusTimelineCornSchema = timelineServiceCorn(
  DefaultClaimStatusTimelineTypeSchema.value
)

// Timeline Points
export const DeprecatedDefaultClaimStatusTimelinePointTypeSchema = z.enum([
  DeprecatedClaimStatusEventNameEnumSchema.Enum.fileReviewerAssigned,
  DeprecatedClaimStatusEventNameEnumSchema.Enum.fileReviewerRemoved,
])

export const ProducerDefaultClaimStatusTimelinePointTypeSchema = z.enum([
  ProducerClaimStatusEventNameEnumSchema.Enum.canceled,
  ProducerClaimStatusEventNameEnumSchema.Enum.carrierApprovedEstimate,
  ProducerClaimStatusEventNameEnumSchema.Enum.carrierApprovedEstimateWithExceptions,
  ProducerClaimStatusEventNameEnumSchema.Enum.carrierRejectedEstimate,
  ProducerClaimStatusEventNameEnumSchema.Enum.clientApproved,
  ProducerClaimStatusEventNameEnumSchema.Enum.clientRejected,
  ProducerClaimStatusEventNameEnumSchema.Enum.coordinatorAssigned,
  ProducerClaimStatusEventNameEnumSchema.Enum.coordinatorRemoved,
  ProducerClaimStatusEventNameEnumSchema.Enum.customerContactedEmail,
  ProducerClaimStatusEventNameEnumSchema.Enum.customerContactedLvm,
  ProducerClaimStatusEventNameEnumSchema.Enum.customerNoContact,
  ProducerClaimStatusEventNameEnumSchema.Enum.dateReceived,
  ProducerClaimStatusEventNameEnumSchema.Enum.estimateRevisionRequested,
  ProducerClaimStatusEventNameEnumSchema.Enum.estimateSentToCarrier,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterAssigned,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterContactedCustomer,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterCorrectionUploaded,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterEstimateUploaded,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterInspectionCompleted,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterInspectionScheduled,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterReassigned,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterRemoved,
  ProducerClaimStatusEventNameEnumSchema.Enum.inAssignQueue,
  ProducerClaimStatusEventNameEnumSchema.Enum.initialCustomerContactAttempted,
  ProducerClaimStatusEventNameEnumSchema.Enum.initialCustomerContactFailed,
  ProducerClaimStatusEventNameEnumSchema.Enum.initialCustomerContactSuccess,
  ProducerClaimStatusEventNameEnumSchema.Enum.invalidContactInformation,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobCompleted,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobContracted,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobNotSold,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobSold,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobStarted,
  ProducerClaimStatusEventNameEnumSchema.Enum.lossDate,
  ProducerClaimStatusEventNameEnumSchema.Enum.projectManagerAssigned,
  ProducerClaimStatusEventNameEnumSchema.Enum.projectManagerRemoved,
  ProducerClaimStatusEventNameEnumSchema.Enum.qaApproved,
  ProducerClaimStatusEventNameEnumSchema.Enum.qaApprovedPreliminaryReport,
  ProducerClaimStatusEventNameEnumSchema.Enum.qaApprovedSupplementReport,
  ProducerClaimStatusEventNameEnumSchema.Enum.qaRejected,
  ProducerClaimStatusEventNameEnumSchema.Enum.reInspectionRequested,
  ProducerClaimStatusEventNameEnumSchema.Enum.reviewerAssigned,
  ProducerClaimStatusEventNameEnumSchema.Enum.reviewerRemoved,
  ProducerClaimStatusEventNameEnumSchema.Enum.surveySentToCustomer,
])

export const DefaultClaimStatusTimelinePointTypeLaxSchema = z.enum([
  ...ProducerDefaultClaimStatusTimelinePointTypeSchema.options,
  ...DeprecatedDefaultClaimStatusTimelinePointTypeSchema.options,
])

export const DefaultClaimStatusTimelinePointTypeCanonSchema = z
  .enum([...DefaultClaimStatusTimelinePointTypeLaxSchema.options])
  .transform((pointType) => {
    switch (pointType) {
      case 'fileReviewerAssigned':
        return ProducerDefaultClaimStatusTimelinePointTypeSchema.Enum.reviewerAssigned
      case 'fileReviewerRemoved':
        return ProducerDefaultClaimStatusTimelinePointTypeSchema.Enum.reviewerRemoved
      default:
        return pointType
    }
  })

export const DefaultClaimStatusTimelinePointCanonSchema = TimelinePointSchema.merge(
  z.object({
    type: DefaultClaimStatusTimelinePointTypeCanonSchema,
  })
)

export const DefaultClaimStatusTimelinePointLaxSchema = TimelinePointSchema.merge(
  z.object({
    type: DefaultClaimStatusTimelinePointTypeLaxSchema,
  })
)
// Timeline
export const DefaultClaimStatusTimelineCanonSchema = TimelineSchema.merge(
  z.object({
    id: DefaultClaimStatusTimelineCornSchema,
    type: DefaultClaimStatusTimelineTypeSchema,
    points: z.array(DefaultClaimStatusTimelinePointCanonSchema),
  })
)

export const DefaultClaimStatusTimelineLaxSchema = TimelineSchema.merge(
  z.object({
    id: DefaultClaimStatusTimelineCornSchema,
    type: DefaultClaimStatusTimelineTypeSchema,
    points: z.array(DefaultClaimStatusTimelinePointLaxSchema),
  })
)

// Timeline Template
export type DefaultClaimStatusTimelinePoints =
  typeof ProducerDefaultClaimStatusTimelinePointTypeSchema.options
type DefaultClaimStatusTimelineEvents = typeof ProducerClaimStatusEventNameEnumSchema.options

export const DefaultClaimTimelineInstructionsTemplateSchema = makeTimelineInstructionTemplateSchema<
  DefaultClaimStatusTimelineEvents,
  DefaultClaimStatusTimelinePoints
>(ProducerClaimStatusEventNameEnumSchema, ProducerDefaultClaimStatusTimelinePointTypeSchema)

export const DefaultClaimStatusTimelineTemplateSchema = DefaultTimelineTemplateSchema.merge(
  z.object({
    instructionTemplate: DefaultClaimTimelineInstructionsTemplateSchema,
    timelineType: DefaultClaimStatusTimelineTypeSchema,
  })
)

// Status Reduction
export const DefaultClaimStatusReductionCanonSchema = StatusReductionSchema.merge(
  z.object({
    type: DefaultClaimStatusTimelineTypeSchema,
    timeline: DefaultClaimStatusTimelineCanonSchema,
  })
)

export const DefaultClaimStatusReductionLaxSchema = StatusReductionSchema.merge(
  z.object({
    type: DefaultClaimStatusTimelineTypeSchema,
    timeline: DefaultClaimStatusTimelineLaxSchema,
  })
)

// Types
export type DefaultClaimStatusTimelinePointTypeCanon = z.infer<
  typeof DefaultClaimStatusTimelinePointTypeCanonSchema
>
export type DefaultClaimStatusTimelinePointTypeLax = z.infer<
  typeof DefaultClaimStatusTimelinePointTypeLaxSchema
>
export type DefaultClaimStatusReductionCanon = z.infer<
  typeof DefaultClaimStatusReductionCanonSchema
>
export type DefaultClaimStatusReductionLax = z.infer<typeof DefaultClaimStatusReductionLaxSchema>
export type DefaultClaimStatusTimelineCanon = z.infer<typeof DefaultClaimStatusTimelineCanonSchema>
export type DefaultClaimStatusTimelineLax = z.infer<typeof DefaultClaimStatusTimelineLaxSchema>
export type DefaultClaimStatusTimelineCorn = z.infer<typeof DefaultClaimStatusTimelineCornSchema>
export type DefaultClaimStatusTimelineInstructionsTemplate = z.infer<
  typeof DefaultClaimTimelineInstructionsTemplateSchema
>
export type DefaultClaimStatusTimelinePointLax = z.infer<
  typeof DefaultClaimStatusTimelinePointLaxSchema
>
export type DefaultClaimStatusTimelinePointCanon = z.infer<
  typeof DefaultClaimStatusTimelinePointCanonSchema
>
export type DefaultClaimStatusTimelineTemplate = z.infer<
  typeof DefaultClaimStatusTimelineTemplateSchema
>
export type DefaultClaimStatusTimelineType = z.infer<typeof DefaultClaimStatusTimelineTypeSchema>
