import { GetDocumentUrlRequest, GetDocumentUrlResponse } from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { Corn } from '@eigtech/shared-corn'
import { contextualGet, queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { documentsBasePath, documentsQueryKeys } from './constants'

export type UseGetDocumentUrlProps = {
  entityId: Corn
  documentId: string
}

const getDocumentUrl = ({ documentCorn, entityId }: GetDocumentUrlRequest) =>
  contextualGet<GetDocumentUrlResponse>(`${documentsBasePath}/${entityId}/${documentCorn}/url`)

export function getDocumentUrlQueryOptions({ documentId, entityId }: UseGetDocumentUrlProps) {
  const documentCorn = ensureDocumentCorn(documentId)
  return queryOptions({
    queryKey: documentsQueryKeys.documentUrl({ documentId: documentCorn, entityId }),
    queryFn: () => getDocumentUrl({ documentCorn, entityId }),
  })
}

export const useGetDocumentUrl = (props: UseGetDocumentUrlProps) =>
  useQuery(getDocumentUrlQueryOptions(props))

export function useInvalidateDocumentUrl() {
  const queryClient = useQueryClient()

  return function invalidateDocumentUrl(props: UseGetDocumentUrlProps) {
    queryClient.invalidateQueries({
      queryKey: getDocumentUrlQueryOptions(props).queryKey,
    })
  }
}
