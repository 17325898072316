import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'claims'
export const claimsServiceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const ClaimsServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: claimsServiceEventType(),
  })
)

export type ClaimsServiceEventBase = z.infer<typeof ClaimsServiceEventBaseSchema>
