import { useAuth0 } from '@eigtech/ui-shared-auth'
import { useGetContact, useGetContacts } from '@eigtech/ui-shared-contacts'
import { useGetEstimatorUser } from './getEstimatorUser'

// TODO: This function should be deleted. An email can be associated with multiple contacts.
export function useGetEstimatorUserContact() {
  const { user } = useAuth0()

  const estimatorQuery = useGetEstimatorUser({ auth0Id: user?.sub ?? '', email: user?.email ?? '' })
  const estimator = estimatorQuery?.data?.length ? estimatorQuery.data[0] : undefined

  return useGetContact(estimator?.externalUserId ?? '')
}

export function useGetEstimatorUserContacts() {
  const { user } = useAuth0()

  const estimatorQuery = useGetEstimatorUser({ auth0Id: user?.sub ?? '', email: user?.email ?? '' })

  return useGetContacts(estimatorQuery.data?.map((e) => e?.externalUserId ?? '') ?? [])
}
