import { ContactName } from '@eigtech/ui-shared-contacts'
import { HStack, Tag, Text } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { ClaimUnassignmentDetails } from '@eigtech/summarizer-types'
import { JobId } from '@eigtech/restoration-types'
import { JobLink } from '@eigtech/ui-shared-jobs'

export function UnassignedDetails(props: EventDetailsComponentProps<ClaimUnassignmentDetails>) {
  const {
    details: { assigneeId, assigneeRelationship, assigneeType },
  } = props

  return (
    <HStack>
      <Tag colorScheme="brandPrimary">Claim Unassigned</Tag>
      {assigneeType === 'contact' ? (
        <Text as="span">
          from {assigneeRelationship ?? ''} {<ContactName contactId={assigneeId} />}
        </Text>
      ) : assigneeType === 'job' ? (
        <Text as="span">from job {<JobLink jobId={assigneeId as JobId} />}</Text>
      ) : null}
    </HStack>
  )
}
