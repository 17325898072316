import { z } from 'zod'
import { TimelineSchema } from '../base/timeline.js'

export const StatusReductionSchema = z.object({
  type: z.string(),
  entityId: z.string(), // ie. claimNumber
  timeline: TimelineSchema,
})

export const isStatusReduction = (reduction: StatusReduction): reduction is StatusReduction =>
  StatusReductionSchema.safeParse(reduction).success

export type StatusReduction = z.infer<typeof StatusReductionSchema>
