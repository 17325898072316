import { pick } from 'lodash-es'
import { useIsMutating } from '@eigtech/ui-shared-api'
import { mostReadable, stringToColor } from '@eigtech/ui-shared-color'
import {
  AspectRatio,
  Avatar,
  AvatarProps,
  Box,
  Center,
  chakra,
  CircularProgress,
  Flex,
  forwardRef,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@eigtech/ui-shared-dave'
import { ReactNode, useMemo } from 'react'
import { RiScreenshot2Fill } from 'react-icons/ri'
import { meetingsMutationKeys } from '../../api/constants'
import { MeetingState, useMeetingStore } from '../../context'
import { useCaptureImageFromVideo, useFilteredRoster } from '../../hooks'
import { MicActivity } from '../Status'

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'

export type VideoTileProps = {
  /** The name to show on the video tile */
  name?: string | null
  /** Specifies which CSS object-fit value to apply to the VideoTile so that it resizes to fit its container  */
  objectFit?: ObjectFit
  attendeeId?: string
  tileId?: number
  actionsSlot?: ReactNode
  isActiveVideo?: boolean
} & StackProps

export const VideoTile = forwardRef<VideoTileProps, 'video'>(function VideoTile(
  { attendeeId: baseAttendeeId, name: fallbackName, tileId, actionsSlot, ...props },
  ref
) {
  const roster = useFilteredRoster()

  const { attendeeId, name } = useMemo(() => {
    const attendeeId = baseAttendeeId?.replace('#content', '')
    const name = (!!attendeeId && roster[attendeeId.replace('#content', '')]?.name) ?? fallbackName

    return { attendeeId, name }
  }, [baseAttendeeId, fallbackName, roster])

  const { canScreenshot } = useMeetingStore(stateSelector)
  const isMeAndActive = props.id === 'local' && props.isActiveVideo

  return (
    <>
      <Stack spacing="1" {...props} bg="black" borderRadius="md" maxW="full" overflow="hidden">
        <Box position="relative">
          {!!name && <UserAvatar name={name} />}
          <AspectRatio ratio={16 / 9}>
            <chakra.video ref={ref} sx={{ objectFit: 'contain !important' }} />
          </AspectRatio>
          {canScreenshot && tileId !== undefined && isMeAndActive && (
            <Screenshot isActiveVideo isLocal tileId={tileId} />
          )}
        </Box>

        <HStack justifyContent="space-between" pb="1" px="2" w="full">
          <chakra.header flexGrow={1} flexShrink={1} minW={0}>
            {name && (
              <Tooltip label={name}>
                <Text
                  color="white"
                  fontSize="0.75em"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {name}
                </Text>
              </Tooltip>
            )}
          </chakra.header>

          <Flex alignItems="center">
            {!!attendeeId && <MicActivity attendeeId={attendeeId} mr="0.5" py="1" />}
            {canScreenshot && tileId !== undefined && !isMeAndActive && (
              <Screenshot isActiveVideo={props.isActiveVideo} tileId={tileId} />
            )}
            {actionsSlot}
          </Flex>
        </HStack>
      </Stack>
    </>
  )
})

function Screenshot({
  tileId,
  isLocal,
  isActiveVideo,
}: {
  tileId: number
  isLocal?: boolean
  isActiveVideo?: boolean
}) {
  const { meetingDetails } = useMeetingStore(stateSelector)

  const { canvasRef, downloadRef, capturePhoto } = useCaptureImageFromVideo({ tileId })

  const isUploading = useIsMutating({
    mutationKey: meetingsMutationKeys.uploadScreenshot({
      entityId: meetingDetails?.entityId ?? 'corn:unknown:unknown:unknown',
      meetingId: meetingDetails?.meetingId ?? '',
    }),
  })
  return (
    <>
      {!!isUploading && <CircularProgress isIndeterminate size="1.1em" />}

      <Tooltip label="Capture photo">
        {isLocal && isActiveVideo ? (
          <VideoTileIconButton
            aria-label="Capture photo."
            bottom="1px"
            boxShadow="2xl"
            h="3em"
            icon={
              <Icon
                as={RiScreenshot2Fill}
                fontSize="inherit"
                height="1.75em"
                sx={{ fontSize: 'inherit !important' }}
                width="1.75em"
              />
            }
            position="absolute"
            right="5px"
            w="3em"
            onClick={capturePhoto}
          />
        ) : (
          <VideoTileIconButton
            aria-label="Capture photo."
            h="1.75em"
            icon={
              <Icon
                as={RiScreenshot2Fill}
                fontSize="inherit"
                height="1.25em"
                sx={{ fontSize: 'inherit !important' }}
                width="1.25em"
              />
            }
            variant="ghost"
            w="1.75em"
            onClick={capturePhoto}
          />
        )}
      </Tooltip>

      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <a ref={downloadRef} style={{ display: 'none' }} />
    </>
  )
}

function UserAvatar({ name, ...props }: { name: string } & AvatarProps) {
  const color = stringToColor(name)
  const fontColor = mostReadable(color, ['#fff', '#000'])?.toHexString()
  return (
    <Center h="full" position="absolute" w="full">
      <Avatar bg={color} color={fontColor} name={name} size="sm" {...props} />
    </Center>
  )
}

export const VideoTileIconButton = forwardRef<IconButtonProps, 'button'>((props, ref) => (
  <IconButton
    ref={ref}
    _hover={{
      background: 'whiteAlpha.500',
    }}
    borderRadius="50%"
    color="white"
    fontSize="inherit"
    minW="none"
    p="0"
    {...props}
  />
))

const stateSelector = (state: MeetingState) =>
  pick(state, ['canScreenshot', 'entityId', 'meetingDetails'])
