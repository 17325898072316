import {
  Badge,
  BadgeProps,
  Center,
  chakra,
  forwardRef,
  HStack,
  Icon,
  useDisclosure,
} from '@chakra-ui/react'
import { startCase } from 'lodash-es'
import { MdInfoOutline } from 'react-icons/md'
import { Tooltip } from '../Chakra/Tooltip'

export type DataSourceProps = { children?: string } & Omit<BadgeProps, 'children'>

export const DataSource = forwardRef<DataSourceProps, 'div'>(function DataSource(
  { children, ...props },
  ref
) {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  if (!children) {
    return null
  }

  return (
    <Tooltip isOpen={isOpen} label="Data Source" placement="top" onClose={onClose} onOpen={onOpen}>
      <Badge ref={ref} colorScheme="blue" {...props}>
        <HStack spacing="1">
          <chakra.span>{startCase(children)}</chakra.span>
          <chakra.button onClick={onToggle}>
            <Center mt="-0.5">
              <Icon as={MdInfoOutline} h="4" w="4" />
            </Center>
          </chakra.button>
        </HStack>
      </Badge>
    </Tooltip>
  )
})
