import z from 'zod'
import {
  ClaimAssignmentChronicleType,
  ClaimV1ChronicleType,
  ClaimV2ChronicleType,
} from './claimChronicles'
import { Chronicle } from './timeline'

export const ClaimEstimatorChronicleTypeSchema = z.enum([
  //#region claim v2 events
  ClaimV2ChronicleType.customerContactedEmail,
  ClaimV2ChronicleType.customerContactedLvm,
  ClaimV2ChronicleType.customerNoContact,
  ClaimV2ChronicleType.inspectionCompleted,
  ClaimV2ChronicleType.inspectionScheduled,
  ClaimV2ChronicleType.invalidContactInformation,
  //#endregion claim v2 events
  //#region claim assignment events
  ClaimAssignmentChronicleType.legalRepAssignedToClaim,
  ClaimAssignmentChronicleType.legalRepUnassignedFromClaim,
  ClaimAssignmentChronicleType.policyHolderAssignedToClaim,
  ClaimAssignmentChronicleType.policyHolderUnassignedFromClaim,
  ClaimAssignmentChronicleType.primaryContactAssignedToClaim,
  ClaimAssignmentChronicleType.primaryContactUnassignedFromClaim,
  //#endregion claim assignment events
  //#region claim v1 events
  ClaimV1ChronicleType.surveySentToCustomer,
  ClaimV1ChronicleType.fieldAdjusterContactedCustomer,
  ClaimV1ChronicleType.fieldAdjusterCorrectionUploaded,
  ClaimV1ChronicleType.fieldAdjusterEstimateUploaded,
  //#endregion claim v1 events
  // fieldAdjusterInspectionCompleted
  // fieldAdjusterInspectionScheduled
])

export const ClaimEstimatorChronicleType = ClaimEstimatorChronicleTypeSchema.Enum
export const isClaimEstimatorChronicle = (
  chronicle: Pick<Chronicle, 'type'>
): chronicle is Chronicle & { type: ClaimEstimatorChronicleTypeEnum } =>
  ClaimEstimatorChronicleTypeSchema.safeParse(chronicle.type).success

export type ClaimEstimatorChronicleTypeEnum = z.infer<typeof ClaimEstimatorChronicleTypeSchema>
