import {
  AddCustomerCommunicationBodyRequest,
  AddCustomerCommunicationRequest,
  AddCustomerCommunicationResponse,
  CustomerCommunicationType,
} from '@eigtech/claims-v2-types'
import { ClaimCustomerCommunicationDetails } from '@eigtech/summarizer-types'
import { OmitAuthHeaders, contextualPut, useMutation, useQueryClient } from '@eigtech/ui-shared-api'
import { EntityAdminClaim, claimsQueryKeys, claimsV2BasePath } from '@eigtech/ui-shared-claims'
import { exhaustive } from 'exhaustive'
import { useOptimisticAddClaimTimelineEvent } from './useOptimisticAddClaimTimelineEvent'

const addCustomerCommunication = ({
  claimNumber,
  ...payload
}: OmitAuthHeaders<AddCustomerCommunicationRequest>) =>
  contextualPut<AddCustomerCommunicationResponse, AddCustomerCommunicationBodyRequest>(
    `${claimsV2BasePath}/claim/${claimNumber}/customer-communication`,
    payload
  )

export function useAddCustomerCommunication() {
  const queryClient = useQueryClient()
  const optimisticAddClaimTimelineEvent = useOptimisticAddClaimTimelineEvent('default')

  return useMutation({
    mutationFn: addCustomerCommunication,
    async onSuccess({ customerCommunication }, { claimNumber, publicationTargets }) {
      const queryKey = claimsQueryKeys.detail(claimNumber)

      const commType = customerCommunication.type
      const previousClaim = queryClient.getQueryData<EntityAdminClaim>(queryKey)
      const shouldUpdateClaim = shouldUpdateCommDate(previousClaim, commType)

      if (!!previousClaim && shouldUpdateClaim) {
        await queryClient.cancelQueries({ queryKey })
        queryClient.setQueryData<EntityAdminClaim>(queryKey, () => ({
          ...previousClaim,
          keyDates: {
            ...previousClaim.keyDates,
            customerContactedDate: customerCommunication.date,
          },
        }))
      }

      await optimisticAddClaimTimelineEvent<Partial<ClaimCustomerCommunicationDetails>>({
        claimNumber,
        timelineEvent: commType,
        chronicle: {
          date: customerCommunication.date,
          details: {
            customerCommunication,
            xaStatus: publicationTargets?.includes('XactAnalysis') ? 'sent' : undefined,
          },
        },
      })
    },
  })
}

// returns false if customer was already contacted or the comm type reps a failure
function shouldUpdateCommDate(
  claim: EntityAdminClaim | undefined,
  commType: CustomerCommunicationType
) {
  if (claim?.keyDates?.customerContactedDate !== undefined) {
    return false
  }
  return exhaustive(commType, {
    customerContactedEmail: () => true,
    customerContactedLvm: () => true,
    customerNoContact: () => false,
    initialCustomerContactAttempted: () => false,
    initialCustomerContactFailed: () => false,
    initialCustomerContactSuccess: () => true,
    invalidContactInformation: () => false,
    _: () => false,
  })
}
