import { Contact, isContactCorn } from '@eigtech/contacts-types'
import {
  Icon,
  Skeleton,
  SkeletonProps,
  chakra,
  forwardRef,
  isStyleProp,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { useGetContact } from '../api'
import { getContactName } from '../utils'
import { ViewContactModal } from './ContactDetail'

export type ContactNameProps = SkeletonProps & { contactId: string }

export const ContactName = forwardRef<ContactNameProps, 'span'>(function ContactName(
  { contactId, ...props },
  ref
) {
  const { data: contact, isPending } = useGetContact(contactId)

  if (isContactCorn(contactId)) {
    const contactName = getContactName(contact ?? {})
    return (
      <Skeleton ref={ref} as="span" display="inline" isLoaded={!isPending}>
        {!!contact ? <ViewContact contact={contact}>{contactName}</ViewContact> : contactName}
      </Skeleton>
    )
  }

  const styleProps = Object.fromEntries(Object.entries(props).filter(([key]) => isStyleProp(key)))

  return (
    <chakra.span ref={ref} {...styleProps}>
      {contactId}
    </chakra.span>
  )
})

function ViewContact({ contact, children }: { contact: Contact; children: ReactNode }) {
  const {
    isOpen: isOpenContactModal,
    onOpen: onOpenContactModal,
    onClose: onCloseContactModal,
  } = useDisclosure()

  return (
    <>
      <chakra.button _hover={{ textDecor: 'underline' }} onClick={onOpenContactModal}>
        <Icon as={MdInfoOutline} boxSize="4" mb="-2px" /> {children}
      </chakra.button>

      {isOpenContactModal && (
        <ViewContactModal
          contact={contact}
          isOpen={isOpenContactModal}
          onClose={onCloseContactModal}
        />
      )}
    </>
  )
}
