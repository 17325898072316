import z from 'zod'

export const PortalUserStatusSchema = z.enum(['ACTIVE', 'ACTIVATING', 'INACTIVE', 'DEACTIVATING'])
export const PortalUserStatus = PortalUserStatusSchema.Values

export const PortalUserSchema = z.object({
  auth0Id: z.string(),
  createdBy: z.string(),
  dateCreated: z.string().datetime(),
  dateModified: z.string().datetime(),
  email: z.string(),
  entityPermissions: z.string().array(),
  externalUserId: z.string(),
  modifiedBy: z.string(),
  name: z.string(),
  portalUserId: z.string(),
  status: PortalUserStatusSchema,
})

export type PortalUser = z.infer<typeof PortalUserSchema>
export type PortalUserStatusEnum = z.infer<typeof PortalUserStatusSchema>
