import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import {
  Auth0IdSchema,
  CohortIdSchema,
  InboxContentSchema,
  MessageContextCanonSchema,
  MessageGroupIdSchema,
  OutboxIdSchema,
} from '../../base/message'

export const CreateOutboxMessageRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const CreateOutboxMessageRequestPathSchema = z.object({ outboxId: OutboxIdSchema })

export const CreateOutboxMessageRequestBodySchema = z
  .object({
    content: InboxContentSchema.omit({ contentSchemaVersion: true }),
    context: MessageContextCanonSchema.optional(),
  })
  .merge(z.object({ inboxIds: Auth0IdSchema.or(MessageGroupIdSchema).or(CohortIdSchema).array() }))

export const CreateOutboxMessageRequestSchema = CreateOutboxMessageRequestPathSchema.merge(
  CreateOutboxMessageRequestBodySchema
).merge(CreateOutboxMessageRequestHeaderSchema)

export type CreateOutboxMessageRequestPath = z.infer<typeof CreateOutboxMessageRequestPathSchema>
export type CreateOutboxMessageRequestBody = z.infer<typeof CreateOutboxMessageRequestBodySchema>
export type CreateOutboxMessageRequest = z.infer<typeof CreateOutboxMessageRequestSchema>

export const CreateOutboxMessageResponseSchema = z.void()
export type CreateOutboxMessageResponse = z.infer<typeof CreateOutboxMessageResponseSchema>

export interface CreateOutboxMessageHandler
  extends Handler<CreateOutboxMessageRequest, CreateOutboxMessageResponse> {}
