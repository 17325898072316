import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { ClaimClosedDetails } from '@eigtech/summarizer-types'
import { startCase } from 'lodash-es'
import { isStandardClosedOutcomeReason } from '@eigtech/claims-v2-types'

export function ClosedDetails(props: EventDetailsComponentProps<ClaimClosedDetails>) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const {
    details: {
      outcome: { date, notes, reason },
    },
  } = props

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Tag colorScheme="red">Claim Closed</Tag>
        <Text as="span">
          as <Tag>{isStandardClosedOutcomeReason(reason) ? startCase(reason) : reason}</Tag>
        </Text>
        {!!date && (
          <Text as="span">
            on <PreferredDateTimeComponent property="closedDate">{date}</PreferredDateTimeComponent>
          </Text>
        )}
      </HStack>

      {!!notes && <Blockquote whiteSpace="preserve">{notes}</Blockquote>}
    </VStack>
  )
}
