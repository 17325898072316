import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, ClaimSeveritySchema } from '../model'

const UpdateLossSeveritySchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    severity: ClaimSeveritySchema,
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
  response: z.object({
    claimNumber: ClaimNumberSchema,
    lossDetails: z.object({
      severity: ClaimSeveritySchema,
    }),
  }),
}

export const UpdateLossSeverityRequestSchema = UpdateLossSeveritySchemas.params
  .merge(UpdateLossSeveritySchemas.body)
  .merge(UpdateLossSeveritySchemas.headers)

export type UpdateLossSeverityBodyRequest = z.infer<typeof UpdateLossSeveritySchemas.body>
export type UpdateLossSeverityHeaderRequest = z.infer<typeof UpdateLossSeveritySchemas.headers>
export type UpdateLossSeverityRequest = z.infer<typeof UpdateLossSeverityRequestSchema>
export type UpdateLossSeverityResponse = z.infer<typeof UpdateLossSeveritySchemas.response>

export interface UpdateLossSeverityHandler
  extends Handler<UpdateLossSeverityRequest, UpdateLossSeverityResponse> {}

export const UpdateLossSeverityApiSchema: ApiSchema = UpdateLossSeveritySchemas
