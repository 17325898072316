import { Handler } from 'aws-lambda'
import { Auth0IdSchema, Auth0InboxMessageSchema, InboxMessageIdSchema } from '../../base/index.js'
import { z } from 'zod'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'

export const UpdateInboxMessageStatusRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const UpdateInboxMessageStatusRequestPathSchema = z.object({
  inboxId: Auth0IdSchema,
  messageId: InboxMessageIdSchema,
})
export const UpdateInboxMessageStatusRequestBodySchema = Auth0InboxMessageSchema.pick({
  messageStatus: true,
})

export const UpdateInboxMessageStatusRequestSchema =
  UpdateInboxMessageStatusRequestPathSchema.merge(UpdateInboxMessageStatusRequestBodySchema).merge(
    UpdateInboxMessageStatusRequestHeaderSchema
  )

export type UpdateInboxMessageStatusRequestPath = z.infer<
  typeof UpdateInboxMessageStatusRequestPathSchema
>
export type UpdateInboxMessageStatusRequestBody = z.infer<
  typeof UpdateInboxMessageStatusRequestBodySchema
>
export type UpdateInboxMessageStatusRequest = z.infer<typeof UpdateInboxMessageStatusRequestSchema>

export const UpdateInboxMessageStatusResponseSchema = z.void()
export type UpdateInboxMessageStatusResponse = z.infer<
  typeof UpdateInboxMessageStatusResponseSchema
>

export interface UpdateInboxMessageStatusHandler
  extends Handler<UpdateInboxMessageStatusRequest, UpdateInboxMessageStatusResponse> {}
