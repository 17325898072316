import { ContactCorn, isContactCorn, type GetContactResponse } from '@eigtech/contacts-types'
import {
  contextualGet,
  queryOptions,
  useQueries,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { contactBasePath, contactsQueryKeys } from './constants'
import { ensureContactCorn } from '@eigtech/contacts-util'

export const getContact = (contactId: ContactCorn) =>
  contextualGet<GetContactResponse>(`${contactBasePath}/${contactId}`)

export function getContactQueryOptions(contactId: string) {
  const contactCorn = ensureContactCorn(contactId)

  return queryOptions({
    queryKey: contactsQueryKeys.detail(contactCorn),
    queryFn: () => getContact(contactCorn),
    enabled: isContactCorn(contactCorn),
  })
}

export const useGetContact = (contactId: string) => useQuery(getContactQueryOptions(contactId))

export const useGetContacts = (contactIds: string[]) =>
  useQueries({ queries: contactIds.map((contactId) => getContactQueryOptions(contactId)) })

export function useInvalidateContact() {
  const queryClient = useQueryClient()

  return function invalidateContact(contactId: string) {
    queryClient.invalidateQueries({ queryKey: getContactQueryOptions(contactId).queryKey })
  }
}
