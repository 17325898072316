import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { WatchedAlertRuleSchema } from '../base.js'

export const CreateWatchedAlertBodySchema = WatchedAlertRuleSchema.omit({ id: true })

export const CreateWatchedAlertRequestSchema = CreateWatchedAlertBodySchema
export const CreateWatchedAlertResponseSchema = WatchedAlertRuleSchema.pick({ id: true })

export type CreateWatchedAlertRequest = z.infer<typeof CreateWatchedAlertRequestSchema>
export type CreateWatchedAlertResponse = z.infer<typeof CreateWatchedAlertResponseSchema>

export type CreateWatchedAlertHandler = Handler<
  CreateWatchedAlertRequest,
  CreateWatchedAlertResponse
>
