import { ContactCornSchema } from '@eigtech/contacts-types'
import z from 'zod'
import { JobAppointmentIdSchema } from './ids'

export const AppointmentTypeEnumSchema = z.enum([
  'inspection',
  'mitigation',
  'restoration',
  'review',
])
export const AppointmentTypeSchema = AppointmentTypeEnumSchema.or(z.string())
export const AppointmentChannelSchema = z.enum(['onsite', 'phone', 'video'])
export const JobAppointmentSchema = z.object({
  appointmentId: JobAppointmentIdSchema,
  appointmentChannel: AppointmentChannelSchema,
  appointmentDate: z.string().datetime(),
  appointmentDescription: z.string().optional(),
  appointmentType: AppointmentTypeSchema,
  participants: z.array(ContactCornSchema.or(z.string())),
  appointmentNotes: z.string().optional(),
})

export type AppointmentTypeEnum = z.infer<typeof AppointmentTypeEnumSchema>
export type AppointmentType = z.infer<typeof AppointmentTypeSchema>
export type AppointmentChannel = z.infer<typeof AppointmentChannelSchema>
export type JobAppointment = z.infer<typeof JobAppointmentSchema>
