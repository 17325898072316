import {
  chakra,
  ComposedAlert,
  forwardRef,
  Skeleton,
  TableBodyProps,
  TableCellProps,
  Tbody,
  Td,
  Tr,
} from '@eigtech/ui-shared-dave'
import { flexRender, Row, RowData } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useGetCellWidths } from '../hooks'
import { useDataGridContext } from './DataGridContext'

export const DataGridBody = forwardRef<TableBodyProps, 'tbody'>((props, ref) => {
  const { tableId, isFetching, isPending, isError, table } = useDataGridContext()

  const headerGroups = table.getHeaderGroups()
  const numberOfColumns = headerGroups[headerGroups.length - 1].headers.length

  const numberOfLoadingRows = 3

  const tableBodyId = `${tableId}_Body`
  const tableBodyRowBaseId = `${tableId}_Row`
  const tableBodyRowId = (rowId: string) => `${tableBodyRowBaseId}_${rowId}`

  const tableBodyRowLoadingId = (rowId: number) => `${tableBodyRowId(rowId.toString())}_Loading`
  const tableBodyCellLoadingId = (rowId: number, columnIndex: number) =>
    `${tableBodyRowLoadingId(rowId)}_Column_${columnIndex}`

  const tableRowFetching = `${tableId}_Row_Fetching`
  const tableCellFetching = `${tableId}_Cell_Fetching`
  const tableCellError = `${tableId}_Cell_Error`

  const tableRowEmpty = `${tableId}_Row_Empty`
  const tableCellEmpty = `${tableId}_Cell_Empty`

  const rows = table.getRowModel().rows

  return (
    <Tbody ref={ref} id={tableBodyId} {...props}>
      {isPending ? (
        new Array(numberOfLoadingRows).fill('').map((_, rowIndex) => (
          <Tr key={rowIndex} id={tableBodyRowLoadingId(rowIndex)}>
            {new Array(numberOfColumns).fill('').map((_, columnIndex) => (
              <Td
                key={`${rowIndex}_${columnIndex}`}
                bg="white"
                id={tableBodyCellLoadingId(rowIndex, columnIndex)}
              >
                <Skeleton h="20px" />
              </Td>
            ))}
          </Tr>
        ))
      ) : isError ? (
        <Td bg="white" colSpan={numberOfColumns} id={tableCellError}>
          <ComposedAlert
            alert="Something went wrong retrieving data for this table."
            status="error"
          />
        </Td>
      ) : !!rows.length ? (
        rows.map((row) => <TableRow key={row.id} row={row} />)
      ) : (
        <Tr id={isFetching ? tableRowFetching : tableRowEmpty}>
          <Td
            bg="white"
            colSpan={numberOfColumns}
            id={isFetching ? tableCellFetching : tableCellEmpty}
          >
            {isFetching ? 'Fetching data...' : 'No data to display.'}
          </Td>
        </Tr>
      )}
    </Tbody>
  )
})

function TableRow<TData extends RowData>({ row }: { row: Row<TData> }) {
  const { tableId, onRowClick, table } = useDataGridContext<TData>()

  const { tableBodyCellId, tableBodyRowId } = useMemo(() => {
    const tableBodyRowBaseId = `${tableId}_Row`
    const tableBodyRowId = (rowId: string) => `${tableBodyRowBaseId}_${rowId}`

    const tableBodyCellId = (cellId: string) => `${tableBodyRowBaseId}_${cellId}`

    return { tableBodyRowId, tableBodyCellId }
  }, [tableId])

  const pinned = row.getLeftVisibleCells()
  const widths = useGetCellWidths({ getCellId: tableBodyCellId, pinned })

  return (
    <Tr key={row.id} {...(table.options.meta?.rowProps ?? {})} id={tableBodyRowId(row.id)}>
      {row.getVisibleCells().map((cell, index) => {
        const metaCellProps = cell.column.columnDef.meta?.cellProps
        const normalizedMetaCellProps =
          typeof metaCellProps === 'function' ? metaCellProps(cell, index) : metaCellProps ?? {}

        const isPinned = cell.column.getIsPinned()
        const pinnedProps: TableCellProps = {
          position: 'sticky',
          left: `${widths[cell.id]}px`,
          zIndex: 1,
          boxShadow:
            index === row.getLeftVisibleCells().length - 1
              ? 'inset -1px 0 0 var(--chakra-colors-gray-200)'
              : 'none',
        }

        const [__index, ...cellClassSplit] = cell.id.split('_')
        const cellClass = cellClassSplit.join('_')

        return (
          <Td
            key={cell.id}
            bg="white"
            cursor={!!onRowClick ? 'pointer' : undefined}
            {...(isPinned ? pinnedProps : {})}
            onClick={(event) => {
              if (!((event.target as HTMLElement)?.id === tableBodyCellId(cell.id))) {
                return
              }

              onRowClick?.(row, event)
            }}
            {...normalizedMetaCellProps}
            className={tableBodyCellId(cellClass)}
            id={tableBodyCellId(cell.id)}
            w={`${cell.column.getSize()}px`}
          >
            <chakra.span cursor="text">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </chakra.span>
          </Td>
        )
      })}
    </Tr>
  )
}
