import {
  ButtonGroup,
  ButtonGroupProps,
  Center,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from '@eigtech/ui-shared-dave'
import {
  useContentShareControls,
  useContentShareState,
  useLocalAudioOutput,
  useLocalVideo,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react'
import {
  MdMic,
  MdMicOff,
  MdOutlineScreenShare,
  MdOutlineStopScreenShare,
  MdVideocam,
  MdVideocamOff,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md'

type AudioVideoControlsProps = {
  hideContentShare?: boolean
} & ButtonGroupProps

export function AudioVideoControls({ hideContentShare, ...props }: AudioVideoControlsProps) {
  const { isVideoEnabled, toggleVideo } = useLocalVideo()
  const { muted, toggleMute } = useToggleLocalMute()
  const { isAudioOn, toggleAudio } = useLocalAudioOutput()
  const { toggleContentShare } = useContentShareControls()
  const { isLocalUserSharing } = useContentShareState()

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  return (
    <Center>
      <ButtonGroup isAttached variant="outline" {...props}>
        <Tooltip
          isDisabled={isMobile}
          label={isVideoEnabled ? 'Stop your video' : 'Start your video'}
        >
          <IconButton
            aria-label={isVideoEnabled ? 'Stop your video' : 'Start your video'}
            icon={isVideoEnabled ? <MdVideocam /> : <MdVideocamOff />}
            isRound={false}
            onClick={toggleVideo}
          />
        </Tooltip>
        {!hideContentShare && (
          <Tooltip
            isDisabled={isMobile}
            label={isLocalUserSharing ? 'Stop sharing your screen' : 'Start sharing your screen'}
          >
            <IconButton
              aria-label={
                isLocalUserSharing ? 'Stop sharing your screen' : 'Start sharing your screen'
              }
              icon={isLocalUserSharing ? <MdOutlineScreenShare /> : <MdOutlineStopScreenShare />}
              isRound={false}
              onClick={() => toggleContentShare()}
            />
          </Tooltip>
        )}
        <Tooltip isDisabled={isMobile} label={muted ? 'Unmute mic' : 'Mute mic'}>
          <IconButton
            aria-label={muted ? 'Unmute mic' : 'Mute mic'}
            icon={muted ? <MdMicOff /> : <MdMic />}
            isRound={false}
            onClick={toggleMute}
          />
        </Tooltip>
        <Tooltip isDisabled={isMobile} label={isAudioOn ? 'Speaker Off' : 'Speaker On'}>
          <IconButton
            aria-label={isAudioOn ? 'Speaker Off' : 'Speaker On'}
            icon={isAudioOn ? <MdVolumeUp /> : <MdVolumeOff />}
            isRound={false}
            onClick={toggleAudio}
          />
        </Tooltip>
      </ButtonGroup>
    </Center>
  )
}
