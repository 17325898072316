import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const StopCaptureQueryRequestSchema = z.object({
  m: z.string(),
})

export const StopCaptureRequestSchema = StopCaptureQueryRequestSchema

export type StopCaptureRequest = z.infer<typeof StopCaptureRequestSchema>
export type StopCaptureResponse = void

export type StopCaptureHandler = Handler<StopCaptureRequest, StopCaptureResponse>
