import { ClaimV2Corn } from '@eigtech/claims-v2-types'
import { getCornKernels, isCorn } from '@eigtech/shared-corn'

export const ensureClaimV2Corn = (claimNumber: string | ClaimV2Corn): ClaimV2Corn =>
  isCorn(claimNumber) ? (claimNumber as ClaimV2Corn) : makeClaimV2Corn(claimNumber)
export const makeClaimV2Corn = (resourceId: string): ClaimV2Corn =>
  `corn:claims:claim:${resourceId}`
export const isClaimV2Corn = (entityId: string) => {
  if (!isCorn(entityId)) return false

  const { serviceName, resourceType } = getCornKernels(entityId)
  return serviceName === 'claims' && resourceType === 'claim'
}
