import { $getRoot, $isElementNode, EditorState, LexicalNode } from 'lexical'
import { $isBeautifulMentionNode } from 'lexical-beautiful-mentions'
import { uniqBy } from 'lodash-es'
import { Mention } from '../types/Mention'

export function getMentionsFromEditorState(editorState: EditorState) {
  let mentions: Mention[] = []

  editorState.read(() => {
    const root = $getRoot()

    mentions = $getMentionsFromNode(root)
  })

  return mentions
}

export function $getMentionsFromNode(node: LexicalNode) {
  let mentions: Mention[] = []

  if ($isElementNode(node)) {
    const children = node.getChildren()
    for (const child of children) {
      mentions = [...mentions, ...$getMentionsFromNode(child)]
    }
  } else if ($isBeautifulMentionNode(node)) {
    mentions = [
      ...mentions,
      {
        data: node.getData(),
        trigger: node.getTrigger(),
        value: node.getValue(),
      },
    ]
  }

  return uniqBy(mentions, 'value')
}
