import { ContactSchema } from '@eigtech/contacts-types'
import z from 'zod'
import { EstimatorClaimRecordSchema } from '../base/claim.js'
import { EstimatorQueryModelSchema } from './estimatorQueryModel.js'

const EstimatorContactSummarySchema = z.object({
  email: z.string().optional(),
  name: z.string(),
  phone: z.string().optional(),
})

const EstimatorMinimalContactSchema = z.object({
  id: ContactSchema.shape.contactId,
  name: z.string().optional(),
})

export const EstimatorClaimSummarySchema = z.object({
  carrier: z.string(),
  carrierApprovedEstimate: z.boolean(),
  catCode: z.string().optional(),
  claimNumber: z.string(),
  claimStatus: z.string(),
  coordinator: EstimatorMinimalContactSchema.optional(),
  customerContactedDate: z.string().optional(),
  dateAssigned: z.string(),
  dateReceived: z.string(),
  fieldAdjusterCompletedDate: z.string().optional(),
  fieldAdjusterScheduledDate: z.string().optional(),
  hasLegalRep: z.boolean().optional(),
  hasJob: z.boolean().optional(),
  inAssignQueue: z.string(),
  latestTimelineEvent: z.string(),
  lossDate: z.string(),
  lossType: z.string(),
  lossLocation: z.object({
    city: z.string(),
    state: z.string(),
    county: z.string().optional(),
  }),
  primaryContact: EstimatorContactSummarySchema,
  qaApproved: z.boolean(),
})

export const EstimatorClaimRecordQueryModelSchema =
  EstimatorClaimRecordSchema.merge(EstimatorQueryModelSchema)

export type EstimatorClaimRecordQueryModel = z.infer<typeof EstimatorClaimRecordQueryModelSchema>
export type EstimatorClaimSummary = z.infer<typeof EstimatorClaimSummarySchema>
export type EstimatorContactSummary = z.infer<typeof EstimatorContactSummarySchema>
