import { HStack, Stack, WrapChildrenWithText } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { FieldArrayPath, FieldValues } from 'react-hook-form'
import { ArrayFieldAddButton } from './ArrayFieldButtons'
import { useArrayFieldContext } from './ArrayFieldContext'
import { ArrayFieldRow } from './ArrayFieldRow'
import { ArrayFieldRowContext, useArrayFieldRowContext } from './ArrayFieldRowContext'

export type ArrayFieldRowsProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = {
  emptyMessage?: ReactNode
  RowsContainer?: (props: { children: ReactNode }) => JSX.Element
  RowContainer?: (props: { children: ReactNode; index: number }) => JSX.Element
  renderRow: (
    field: ArrayFieldRowContext<TFieldValues, TFieldArrayName, TKeyName>,
    index: number
  ) => ReactNode
}

export function ArrayFieldRows<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  emptyMessage,
  RowsContainer = DefaultRowsContainer,
  RowContainer,
  renderRow,
}: ArrayFieldRowsProps<TFieldValues, TFieldArrayName, TKeyName>) {
  const { fields } = useArrayFieldContext<TFieldValues>()

  return (
    <RowsContainer>
      {!fields.length ? (
        <WrapChildrenWithText>
          {emptyMessage ?? 'Use the "+" button to add a row'}
        </WrapChildrenWithText>
      ) : (
        fields.map((field, index) => (
          <ArrayFieldRow key={field.id} RowContainer={RowContainer} field={field} index={index}>
            <RenderRow index={index} renderRow={renderRow} />
          </ArrayFieldRow>
        ))
      )}
    </RowsContainer>
  )
}

function DefaultRowsContainer({ children }: { children: ReactNode }) {
  return (
    <Stack>
      <Stack spacing="4">{children}</Stack>

      <HStack justifyContent="flex-end">
        <ArrayFieldAddButton />
      </HStack>
    </Stack>
  )
}

function RenderRow<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  renderRow,
  index,
}: ArrayFieldRowsProps<TFieldValues, TFieldArrayName, TKeyName> & {
  index: number
}) {
  const context = useArrayFieldRowContext<TFieldValues, TFieldArrayName, TKeyName>()

  return <>{renderRow(context, index)}</>
}

export type ArrayFieldRowsComponent<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = typeof ArrayFieldRows<TFieldValues, TFieldArrayName, TKeyName>
