// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/tooltip.ts

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

// const $bg = cssVar('tooltip-bg')
// const $fg = cssVar('tooltip-fg')
// const $arrowBg = cssVar('popper-arrow-bg')

const baseStyle = defineStyle({
  borderRadius: 'md',
})

export const tooltipTheme = defineStyleConfig({
  baseStyle,
})
