import {
  ButtonProps,
  ListIcon as CListIcon,
  Center,
  ComponentWithAs,
  HStack,
  IconProps,
  Text,
  Tooltip,
  chakra,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import { RouterLink } from '@eigtech/ui-shared-router'
import { MouseEventHandler, useId } from 'react'
import { useNavContext } from './NavigationContext'
import { NavItem } from './types'

export type NavButtonProps = ButtonProps & { item: NavItem }

export const NavButton: ComponentWithAs<'button', NavButtonProps> = forwardRef(
  ({ item, ...props }, ref) => {
    const { isCollapsed, setActiveNav, drawer } = useNavContext()

    const isLink = 'url' in item
    const buttonProps: ButtonProps = {
      ...navButtonStyleProps,
      ...('isActiveNav' in item
        ? {
            backgroundColor: item.isActiveNav?.(item.navId)
              ? 'var(--chakra-colors-whiteAlpha-100)'
              : undefined,
          }
        : {}),
      children: (
        <>
          <NavButtonIcon as={item.icon} />
          {isCollapsed ? '' : item.label}
        </>
      ),
    }

    const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      item.onClick?.(e)
      drawer?.onClose()
    }

    const target = useId()

    return (
      <Tooltip isDisabled={!isCollapsed} label={item.label}>
        <HStack justifyContent="space-between" position="relative">
          {isLink ? (
            <chakra.button
              _ref={ref}
              activeProps={{ style: { backgroundColor: 'var(--chakra-colors-whiteAlpha-100)' } }}
              as={RouterLink}
              color="green"
              params={{}}
              search={{}}
              onClick={onClick}
              {...item.url}
              {...buttonProps}
              {...props}
            />
          ) : (
            <chakra.button
              ref={ref}
              onClick={
                'navId' in item
                  ? (e) => {
                      onClick(e)
                      setActiveNav(item.navId)
                    }
                  : onClick
              }
              {...('href' in item
                ? {
                    as: 'a',
                    rel: 'noopener noreferrer nofollow',
                    href: item.href,
                    target: item.target || target,
                  }
                : {})}
              {...buttonProps}
              {...props}
            />
          )}

          {!!item.badge && <Badge badge={item.badge} />}
        </HStack>
      </Tooltip>
    )
  }
)

function Badge({ badge }: Pick<NavItem, 'badge'>) {
  const { isCollapsed } = useNavContext()

  if (!badge) return null

  const badgeAsNumber = typeof badge === 'string' ? parseInt(badge) : badge
  const isLongBadge = badge.toString().length > 2

  return (
    <Center
      background="red.500"
      borderRadius="full"
      h={isLongBadge ? '6' : '5'}
      position="absolute"
      right={isCollapsed ? '0' : '1'}
      top={isCollapsed ? '0' : undefined}
      w={isLongBadge ? '6' : '5'}
    >
      <Text as="span" color="white" fontSize={isLongBadge ? '2xs' : 'xs'} fontWeight="medium">
        {isFinite(badgeAsNumber) && badgeAsNumber > 99 ? '99+' : badge}
      </Text>
    </Center>
  )
}

export const NavButtonIcon: ComponentWithAs<'svg', IconProps> = forwardRef(
  ({ as, ...props }, ref) => <CListIcon ref={ref} as={as} boxSize={6} mr="3" {...props} />
)

export const navButtonStyleProps: ButtonProps = {
  borderRadius: 'md',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  p: '2',
  textDecoration: 'none',
  w: 'full',
  fontWeight: 'semibold',
  _hover: {
    textDecoration: 'none',
    bg: 'rgba(255, 255, 255, 0.1)',
  },
}
