import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AssignableLaxSchema, AssigneeSchema } from '../entities/assignment'

export const AssignRequestApiSchema = {
  body: z.object({
    assignee: AssigneeSchema,
    assignable: AssignableLaxSchema,
  }),
  headers: z.object({ requestActor: z.string().optional() }),
  params: z.object({ assignableId: z.string() }),
} satisfies ApiSchema

export const AssignRequestBodySchema = AssignRequestApiSchema.body
export const AssignPathRequestSchema = AssignRequestApiSchema.params
export const AssignHeadersRequestSchema = AssignRequestApiSchema.headers

export const AssignRequestSchema = AssignRequestBodySchema.merge(AssignPathRequestSchema).merge(
  AssignHeadersRequestSchema
)

export type AssignBodyRequest = z.infer<typeof AssignRequestBodySchema>
export type AssignPathRequest = z.infer<typeof AssignPathRequestSchema>
export type AssignRequest = z.infer<typeof AssignRequestSchema>
export type AssignResponse = void
export type AssignHandler = Handler<AssignRequest, AssignResponse>
