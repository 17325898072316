import { FC, ReactNode, createContext, useContext } from 'react'
import { formatDate } from '../utils'

export type DateTime = string | number | Date | null | undefined

export type DatesContext = {
  PreferredDateTimeComponent: FC<{
    children: DateTime
    format?: string
    secondaryFormat?: string
    property: string
  }>
  preferredFormatDateFunction: (options: {
    dateTime: DateTime
    format?: string
    property: string
  }) => string
}

export function DatesProvider({
  children,
  PreferredDateTimeComponent = DefaultDateComponent,
  preferredFormatDateFunction = defaultFormat,
}: {
  children: ReactNode
} & Partial<DatesContext>) {
  return (
    <DatesContext.Provider value={{ PreferredDateTimeComponent, preferredFormatDateFunction }}>
      {children}
    </DatesContext.Provider>
  )
}

export function useDatesContext() {
  const routes = useContext(DatesContext)

  if (!routes) {
    throw new Error('DatesProvider required to use useDatesContext')
  }

  return routes
}

// defaults

const DefaultDateComponent: DatesContext['PreferredDateTimeComponent'] = ({ children, format }) => (
  <>{formatDate(children, format)}</>
)

const defaultFormat: DatesContext['preferredFormatDateFunction'] = ({ dateTime, format }) =>
  formatDate(dateTime, format)

const defaultContext: DatesContext = {
  PreferredDateTimeComponent: DefaultDateComponent,
  preferredFormatDateFunction: defaultFormat,
}

// Context
const DatesContext = createContext<DatesContext>(defaultContext)
