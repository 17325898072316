import { getCornKernels, isCorn } from '@eigtech/shared-corn'
import {
  DocumentCorn,
  serviceName as documentsServiceName,
  documentResourceType,
} from '@eigtech/documents-types'

export const ensureDocumentCorn = (id: string | DocumentCorn): DocumentCorn =>
  isDocumentCorn(id) ? id : makeDocumentCorn(id)

export const makeDocumentCorn = (resourceId: string): DocumentCorn =>
  `corn:documents:document:${resourceId}`

export const isDocumentCorn = (entityId: string): entityId is DocumentCorn => {
  if (!isCorn(entityId)) return false

  const { serviceName, resourceType } = getCornKernels(entityId)
  return serviceName === documentsServiceName && resourceType === documentResourceType
}
