import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'

export const GetMeetingRequestPathSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
  startTime: z.string().datetime(),
})

export const GetMeetingRequestSchema = GetMeetingRequestPathSchema

export const GetMeetingResponseSchema = MeetingDetailsSchema

export type GetMeetingRequest = z.infer<typeof GetMeetingRequestSchema>
export type GetMeetingRequestPath = z.infer<typeof GetMeetingRequestPathSchema>
export type GetMeetingResponse = z.infer<typeof GetMeetingResponseSchema>

export interface GetMeetingHandler extends Handler<GetMeetingRequest, GetMeetingResponse> {}
