import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const GetScreenshotUrlRequestPathSchema = z.object({
  entityId: CornSchema,
  startTime: z.string(),
  dateAdded: z.string().datetime(),
})
export const GetScreenshotUrlRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const GetScreenshotUrlRequestSchema = GetScreenshotUrlRequestPathSchema.merge(
  GetScreenshotUrlRequestHeaderSchema
)

export type GetScreenshotUrlRequest = z.infer<typeof GetScreenshotUrlRequestSchema>
export type GetScreenshotUrlRequestPath = z.infer<typeof GetScreenshotUrlRequestPathSchema>
export type GetScreenshotUrlResponse = string

export interface GetScreenshotUrlHandler
  extends Handler<GetScreenshotUrlRequest, GetScreenshotUrlResponse> {}
