import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { CallbackRequestedRolesSchema, CallbackUserSchema } from '../../../index.js'

export const CallbackRequestedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema

export const CallbackRequestedRequestPathSchema = z.object({
  entityId: CornSchema,
})

export const CallbackRequestedRequestBodySchema = z.object({
  role: CallbackRequestedRolesSchema,
  notes: z.string(),
  user: CallbackUserSchema.omit({ auth0Id: true }),
})

export const CallbackRequestedRequestSchema = CallbackRequestedRequestHeadersSchema.merge(
  CallbackRequestedRequestPathSchema.merge(CallbackRequestedRequestBodySchema)
)

export type CallbackRequestedRequestPath = z.infer<typeof CallbackRequestedRequestPathSchema>
export type CallbackRequestedRequestBody = z.infer<typeof CallbackRequestedRequestBodySchema>

export type CallbackRequestedRequest = z.infer<typeof CallbackRequestedRequestSchema>

export const CallbackRequestedResponseSchema = z.void()
export type CallbackRequestedResponse = z.infer<typeof CallbackRequestedResponseSchema>

export interface CallbackRequestedHandler
  extends Handler<CallbackRequestedRequest, CallbackRequestedResponse> {}
