import { z } from 'zod'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { CohortEnumSchema } from '@eigtech/auth0-types'
import { CornSchema } from '@eigtech/shared-corn'

export const GetUploadUrlRequestPathSchema = z.object({
  cohort: CohortEnumSchema,
  entityId: CornSchema,
})
export const GetUploadUrlRequestHeadersSchema = ApiLambdalerAuthHeaderSchema

export const GetUploadUrlRequestSchema = GetUploadUrlRequestPathSchema.merge(
  GetUploadUrlRequestHeadersSchema
)

export const GetUploadUrlResponseSchema = z.object({
  fields: z.record(z.string()),
  url: z.string(),
})

export type GetUploadUrlRequest = z.infer<typeof GetUploadUrlRequestSchema>
export type GetUploadUrlResponse = z.infer<typeof GetUploadUrlResponseSchema>
export type GetUploadUrlHandler = Handler<GetUploadUrlRequest, GetUploadUrlResponse>
