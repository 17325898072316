import { UserPreferences } from '@eigtech/ui-estimator/types/UserPreferences'
import { queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { useCheckIfAccessTokenSet } from '@eigtech/ui-shared-auth'
import { GetUserPreferencesResponse } from '@eigtech/user-preferences-types'
import { get } from '../instance'
import { preferencesBasePath, preferencesUi, userQueryKeys } from './constants'

export const getUserPreferences = async () => {
  const response = await get<GetUserPreferencesResponse<UserPreferences>>(
    `${preferencesBasePath}/`,
    { searchParams: { ui: preferencesUi } }
  )

  return response.preferences
}

export const getUserPreferencesQueryOptions = ({ accessTokenSet }: { accessTokenSet: boolean }) =>
  queryOptions({
    queryKey: userQueryKeys.preferences(),
    queryFn: getUserPreferences,
    enabled: accessTokenSet,
  })

export function useGetUserPreferences() {
  const accessTokenSet = useCheckIfAccessTokenSet()

  return useQuery(getUserPreferencesQueryOptions({ accessTokenSet }))
}

export function useInvalidateUserPreferences() {
  const queryClient = useQueryClient()

  return function invalidateUserGroups() {
    queryClient.invalidateQueries({ queryKey: userQueryKeys.preferences() })
  }
}
