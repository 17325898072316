import type {
  GetNotebookForCohortResponse,
  MarkNoteForPublicationRequest,
  MarkNoteForPublicationResponse,
} from '@eigtech/notes-types'
import { Corn } from '@eigtech/shared-corn'
import {
  ApiPatchRequest,
  useApiInstanceContext,
  useMutation,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { notebooksBasePath, notebooksQueryKeys } from './constants'
import { useInvalidateEntityNotebook } from './getNotebooks'

const markNoteForPublication =
  (patch: ApiPatchRequest) =>
  ({ noteId, publicationTargets }: MarkNoteForPublicationRequest) =>
    patch<MarkNoteForPublicationResponse>(`${notebooksBasePath}/publish/${noteId}`, {
      publicationTargets,
    })

export function useMarkNoteForPublication(entityCorn: Corn) {
  const { patch } = useApiInstanceContext()
  const queryClient = useQueryClient()
  const invalidateEntityNotebook = useInvalidateEntityNotebook()

  return useMutation({
    mutationFn: markNoteForPublication(patch),
    async onMutate({ publicationTargets, noteId }) {
      const queryKey = notebooksQueryKeys.entityNotebook(entityCorn)
      await queryClient.cancelQueries({ queryKey })

      const previousNotebook = queryClient.getQueryData<GetNotebookForCohortResponse>(queryKey)

      queryClient.setQueryData<GetNotebookForCohortResponse>(queryKey, (old) => {
        if (!old) return

        const notes = [...old.notes]
        const updatedNoteIndex = notes.findIndex((note) => note.id === noteId)

        if (updatedNoteIndex >= 0) {
          notes.splice(updatedNoteIndex, 1, {
            ...notes[updatedNoteIndex],
            publicationTargets: [
              ...new Set([
                ...(notes[updatedNoteIndex].publicationTargets ?? []),
                ...publicationTargets,
              ]),
            ],
          })
        }

        return {
          ...old,
          notes,
        }
      })

      return { previousNotebook }
    },
    onError(_, __, context) {
      const queryKey = notebooksQueryKeys.entityNotebook(entityCorn)
      queryClient.setQueryData<GetNotebookForCohortResponse>(queryKey, context?.previousNotebook)
    },
    onSettled() {
      setTimeout(() => {
        // claimbook should be invalidated to get the published targets from note
        invalidateEntityNotebook(entityCorn)
      }, 20000)
    },
  })
}
