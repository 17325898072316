import { DocumentTransitMethodSchema, WorkAuthorization } from '@eigtech/restoration-types'
import { startCase } from 'lodash-es'

const partialWorkAuthSentMethodLabels: Partial<Record<WorkAuthorization['sentMethod'], string>> = {}

export const getWorkAuthSentMethodLabel = (method: WorkAuthorization['sentMethod']) =>
  partialWorkAuthSentMethodLabels[method] ?? startCase(method)

export const workAuthSentMethodLabels = Object.fromEntries(
  DocumentTransitMethodSchema.options.map((key) => [key, getWorkAuthSentMethodLabel(key)])
) as Record<WorkAuthorization['sentMethod'], string>
