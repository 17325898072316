import { Book } from '@eigtech/contacts-types'
import { JobCorn } from '@eigtech/restoration-types'
import { ensureJobCorn } from '@eigtech/restoration-util'
import { CurrentClaimCorn, ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { exhaustive } from 'exhaustive'
import { last } from 'lodash-es'
import { EntityBookTypes, isEntityBook } from './isEntityBook'

export type GetBookEntityCornOptions<ShouldValidate extends boolean> = {
  shouldValidate?: ShouldValidate
}

export type GetBookEntityCornReturn<ShouldValidate extends boolean> = ShouldValidate extends false
  ? CurrentClaimCorn | JobCorn | undefined
  : CurrentClaimCorn | JobCorn

export function getBookEntityCorn<ShouldValidate extends boolean = true>(
  book: Book,
  { shouldValidate }: GetBookEntityCornOptions<ShouldValidate> = {}
): GetBookEntityCornReturn<ShouldValidate> {
  const entityId = last(book.bookId.split(':')) as string
  const entityType = book.metadata.type as EntityBookTypes

  if (shouldValidate !== false) {
    if (!isEntityBook(book)) {
      throw new Error('incorrect book type')
    }
  }

  return exhaustive(entityType, {
    claim: () => ensureClaimCorn(entityId),
    job: () => ensureJobCorn(entityId),
    _: () => undefined,
  })
}
