import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const DeleteTemplateApiSchema = {
  params: z.object({
    id: z.string(),
  }),
  headers: z.object({
    requestActor: z.string().optional(),
  }),
} satisfies ApiSchema

export const DeleteTemplateRequestSchema = DeleteTemplateApiSchema.params.merge(
  DeleteTemplateApiSchema.headers
)

export type DeleteTemplateRequestPathParameters = z.infer<typeof DeleteTemplateApiSchema.params>
export type DeleteTemplateRequest = z.infer<typeof DeleteTemplateRequestSchema>
export type DeleteTemplateResponse = void
export type DeleteTemplateHandler = Handler<DeleteTemplateRequest, DeleteTemplateResponse>
