import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const HydrateOrgRequestBodySchema = z.object({
  doWipeDatabase: z.boolean().optional(),
})

export const HydrateOrgRequestSchema = HydrateOrgRequestBodySchema

export const HydrateOrgApiSchema = {
  body: HydrateOrgRequestBodySchema,
} satisfies ApiSchema

export type HydrateOrgRequest = z.infer<typeof HydrateOrgRequestSchema>
export type HandleHydrateOrg = Handler<HydrateOrgRequest, void>
