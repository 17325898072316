import { GetCallbackRequestsByStatusRequest } from '@eigtech/communications-types'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'
import { pick } from 'lodash-es'

export const communicationBasePath = 'communications/v2'

const communicationsBaseQueryKeys = pick(makeBasicQueryKeys('communications'), ['all'])

export const communicationsQueryKeys = {
  ...communicationsBaseQueryKeys,

  entity: () => [...communicationsQueryKeys.all(), 'entity'],
  byEntity: (claimNumber: string) => [...communicationsQueryKeys.entity(), claimNumber],

  status: () => [...communicationsQueryKeys.all(), 'status'],
  byStatus: (prop: GetCallbackRequestsByStatusRequest) => [
    ...communicationsQueryKeys.status(),
    prop,
  ],
}
