import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'contacts'
export const serviceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const ServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: serviceEventType(),
  })
)

export type ServiceEventBase = z.infer<typeof ServiceEventBaseSchema>
