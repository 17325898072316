import { AssignRequest, JobAssigneeRelationship } from '@eigtech/assignments-types'
import { Contact } from '@eigtech/contacts-types'
import { Job } from '@eigtech/restoration-types'
import { ensureJobCorn } from '@eigtech/restoration-util'

export type AssignEntityToJobProps = {
  assigneeRelationship: JobAssigneeRelationship
  jobId: Job['jobId']
  contact: Contact
}

export function makeJobAssignment({
  assigneeRelationship,
  jobId,
  contact,
}: AssignEntityToJobProps): AssignRequest {
  const jobCorn = ensureJobCorn(jobId)

  return {
    assignable: {
      assignableId: jobCorn,
      assigneeRelationship,
      job: { jobId },
      type: 'job',
    },
    assignableId: jobCorn,
    assignee: {
      type: 'contact',
      contact,
      assigneeId: contact.contactId,
    },
  }
}
