import {
  Box,
  BoxProps,
  chakra,
  Checkbox,
  FormControl,
  forwardRef,
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
  Text,
} from '@eigtech/ui-shared-dave'
import { Column, ColumnOrderState } from '@tanstack/react-table'
import type { Identifier } from 'dnd-core'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { MdDragIndicator } from 'react-icons/md'
import { useDataGridContext } from '../DataGridContext'

export const ACTION_MENU_ID = 'actionMenu'

export const DataGridColumnSettings = forwardRef<BoxProps, 'div'>((props, ref) => {
  const { enableReordering, table } = useDataGridContext()

  return (
    <Box ref={ref} {...props}>
      <SimpleGrid columnGap={4} columns={2} rowGap={2}>
        <GridItem colSpan={2}>
          <Text color="blackAlpha.600" fontSize="xs" fontWeight="bold">
            Column settings:
          </Text>
        </GridItem>

        {table.getAllLeafColumns().map(
          (column, index) =>
            (column.getCanHide() ||
              (enableReordering && column.columnDef.meta?.enableReordering !== false)) &&
            column.id !== ACTION_MENU_ID && (
              <GridItem key={column.id}>
                <ColumnSettings column={column} index={index} />
              </GridItem>
            )
        )}
      </SimpleGrid>
    </Box>
  )
})

type DragItem = {
  index: number
  id: string
  type: string
}

type ColumnSettingsProps = {
  column: Column<unknown, unknown>
  index: number
}

function ColumnSettings({ column, index }: ColumnSettingsProps) {
  const { enableReordering, table } = useDataGridContext()
  const { columnOrder } = table.getState()

  const ref = useRef<HTMLDivElement>(null)

  const [{ isOver }, dropRef] = useDrop<
    DragItem,
    unknown,
    { canDrop: boolean; isOver: boolean; handlerId: Identifier | null }
  >({
    accept: 'dataGridColumn',
    collect(monitor) {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const newColumnOrder = reorderColumn(item.id, column.id, columnOrder)
      table.setColumnOrder(newColumnOrder)
    },
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'dataGridColumn',
    item: () => {
      return { id: column.id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  if (enableReordering) {
    dropRef(previewRef(ref))
  }

  const label = (
    <chakra.span fontSize="sm" fontWeight="semibold">
      {column.columnDef.meta?.headerLabel ??
        (typeof column.columnDef.header === 'string' ? column.columnDef.header : column.id)}
    </chakra.span>
  )

  return (
    <>
      {isOver && index === 0 && (
        <Box borderColor="blue.500" borderStyle="dashed" borderWidth="2px" h="2" w="full" />
      )}

      <HStack
        ref={ref}
        borderColor="blue.500"
        borderStyle="dashed"
        borderWidth={isOver ? '3px' : 0}
        justifyContent="space-between"
        opacity={isDragging ? 0.5 : 1}
        px="0.5"
        spacing="1"
      >
        {column.getCanHide() ? (
          <FormControl>
            <Checkbox
              isChecked={column.getIsVisible()}
              onChange={column.getToggleVisibilityHandler()}
            >
              {label}
            </Checkbox>
          </FormControl>
        ) : (
          label
        )}

        {enableReordering && column.columnDef.meta?.enableReordering !== false && (
          <chakra.button
            ref={dragRef}
            alignItems="center"
            display="inline-flex"
            justifyContent="center"
          >
            <Icon as={MdDragIndicator} cursor={isDragging ? 'grabbing' : 'grab'} />
          </chakra.button>
        )}
      </HStack>
    </>
  )
}

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  )
  return [...columnOrder]
}
