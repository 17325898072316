import z from 'zod'
import { Handler } from 'aws-lambda'

/** Mark note for publication */
export const MarkNoteForPublicationBodyRequestSchema = z.object({
  publicationTargets: z.string().array(),
})

export const MarkNoteForPublicationPathRequestSchema = z.object({
  noteId: z.string(),
})

export const MarkNoteForPublicationRequestSchema = MarkNoteForPublicationBodyRequestSchema.merge(
  MarkNoteForPublicationPathRequestSchema
)

export type MarkNoteForPublicationBodyRequest = z.infer<
  typeof MarkNoteForPublicationBodyRequestSchema
>
export type MarkNoteForPublicationPathRequest = z.infer<
  typeof MarkNoteForPublicationPathRequestSchema
>
export type MarkNoteForPublicationRequest = z.infer<typeof MarkNoteForPublicationRequestSchema>
export type MarkNoteForPublicationResponse = void
export type MarkNoteForPublicationHandler = Handler<
  MarkNoteForPublicationRequest,
  MarkNoteForPublicationResponse
>
