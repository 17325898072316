import { claimsQueryKeys as claimsBaseQueryKeys } from '@eigtech/ui-shared-claims'

export const estimatorBasePath = 'estimator'
export const mapboxPlacesBasePath = 'mapbox.places'

export const claimsQueryKeys = {
  ...claimsBaseQueryKeys,
  mapboxLocation: (claimNumber: string) => [
    ...claimsQueryKeys.detail(claimNumber),
    'mapboxLocation',
  ],
}
