import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const AddScreenshotLabelRequestPathSchema = z.object({
  meetingId: z.string(),
  entityId: CornSchema,
  startTime: z.string().datetime(),
})

export const AddScreenshotLabelRequestBodySchema = z.object({
  label: z.string(),
  value: z.string().optional(),
})

export const AddScreenshotLabelRequestSchema = AddScreenshotLabelRequestBodySchema.merge(
  AddScreenshotLabelRequestPathSchema
)

export const AddScreenshotLabelResponseSchema = z.void()

export type AddScreenshotLabelRequest = z.infer<typeof AddScreenshotLabelRequestSchema>
export type AddScreenshotLabelRequestPath = z.infer<typeof AddScreenshotLabelRequestPathSchema>
export type AddScreenshotLabelRequestBody = z.infer<typeof AddScreenshotLabelRequestBodySchema>
export type AddScreenshotLabelResponse = z.infer<typeof AddScreenshotLabelResponseSchema>

export interface AddScreenshotLabelHandler
  extends Handler<AddScreenshotLabelRequest, AddScreenshotLabelResponse> {}
