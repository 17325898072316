import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobExternalIdSchema, JobIdSchema, SubcontractorSchema } from '../../base/index.js'

export const RecordSubcontractorPaidRequestBodySchema = z.object({
  subcontractorPayment: z.object({
    externalId: JobExternalIdSchema.optional(), // e.g. transaction ID
    paidDate: z.string().datetime().optional(),
    paidInFull: z.boolean(),
  }),
  subcontractor: SubcontractorSchema,
})
export const RecordSubcontractorPaidRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordSubcontractorPaidRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordSubcontractorPaidRequestSchema = RecordSubcontractorPaidRequestBodySchema.merge(
  RecordSubcontractorPaidRequestHeadersSchema
).merge(RecordSubcontractorPaidRequestPathSchema)
export const RecordSubcontractorPaidResponseSchema = z.void()
export const RecordSubcontractorPaidApiSchema: ApiSchema = {
  body: RecordSubcontractorPaidRequestBodySchema,
  headers: RecordSubcontractorPaidRequestHeadersSchema,
  params: RecordSubcontractorPaidRequestPathSchema,
}

export interface RecordSubcontractorPaidRequestBody
  extends z.infer<typeof RecordSubcontractorPaidRequestBodySchema> {}
export interface RecordSubcontractorPaidRequestPath
  extends z.infer<typeof RecordSubcontractorPaidRequestPathSchema> {}
export interface RecordSubcontractorPaidRequest
  extends z.infer<typeof RecordSubcontractorPaidRequestSchema> {}
export type RecordSubcontractorPaidResponse = z.infer<typeof RecordSubcontractorPaidResponseSchema>
export interface RecordSubcontractorPaidHandler
  extends Handler<RecordSubcontractorPaidRequest, RecordSubcontractorPaidResponse> {}
