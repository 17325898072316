import { EventBaseSchema, eventType } from '@eigtech/event-stream-types'
import z from 'zod'

export const serviceName = 'alert'

export const DBPartsSchema = z.object({
  PK: z.string(),
  SK: z.string(),
})
export type DBParts = z.infer<typeof DBPartsSchema>

export const alertServiceEventType = <
  U extends AlertLevel | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const AlertServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: alertServiceEventType(),
  })
)

export const AlertLevelSchema = z.enum(['info', 'warn', 'error', 'critical'])
export type AlertLevel = z.infer<typeof AlertLevelSchema>

export const AlertEventSchema = AlertServiceEventBaseSchema.merge(
  z.object({
    type: alertServiceEventType(),
    metadata: z.object({
      service: z.string(),
    }),
    alert: z.string(),
    schemaVersion: z.literal(1),
  })
)
export type AlertEvent = z.infer<typeof AlertEventSchema>

export const AlertEventRecordSchema = AlertEventSchema.merge(DBPartsSchema)
export type AlertEventRecord = z.infer<typeof AlertEventRecordSchema>
/**
 * ### InfoAlertEvent
 *
 * An event representing an informational alert.
 *
 * Example intentions:
 * * "Your batch processing has completed"
 * * "The hourly audit has come back clean"
 */
export const InfoAlertEventSchema = AlertEventSchema.merge(
  z.object({
    type: alertServiceEventType('info'),
  })
)
export type InfoAlertEvent = z.infer<typeof InfoAlertEventSchema>
export const makeInfoAlertEvent = (eventName: string): InfoAlertEvent['type'] =>
  `alert:info:${eventName}`

/**
 * ### WarnAlertEvent
 *
 * An event representing a warning alert.
 *
 * Example intentions:
 * * "We ran into an unexpected scenario, but were able to automatically recover. Everything should be fine now."
 * * "The service is doing something that has been deprecated"
 */
export const WarnAlertEventSchema = AlertEventSchema.merge(
  z.object({
    type: alertServiceEventType('warn'),
  })
)
export type WarnAlertEvent = z.infer<typeof WarnAlertEventSchema>
export const makeWarnAlertEvent = (eventName: string): WarnAlertEvent['type'] =>
  `alert:warn:${eventName}`

/**
 * ### ErrorAlertEvent
 *
 * An event representing an error alert.
 *
 * Example intentions:
 * * "We were unable to complete a non-critical operation"
 * * "Something was not processed correctly and will require a replay"
 * * Stuff that we don't have to get out of bed at 3AM for
 */
export const ErrorAlertEventSchema = AlertEventSchema.merge(
  z.object({
    type: alertServiceEventType('error'),
  })
)
export type ErrorAlertEvent = z.infer<typeof ErrorAlertEventSchema>
export const makeErrorAlertEvent = (eventName: string): ErrorAlertEvent['type'] =>
  `alert:error:${eventName}`
/**
 * ### CriticalAlertEvent
 *
 * An event representing a critical alert.
 *
 * Example intentions:
 * * "A core service is not responding to requests"
 * * "A Large spice in errors has happened"
 * * "Prod is down"
 * * Stuff that we need to get out of bed at 3AM to go fix
 */
export const CriticalAlertEventSchema = AlertEventSchema.merge(
  z.object({
    type: alertServiceEventType('critical'),
  })
)
export type CriticalAlertEvent = z.infer<typeof CriticalAlertEventSchema>
export const makeCriticalAlertEvent = (eventName: string): CriticalAlertEvent['type'] =>
  `alert:critical:${eventName}`

export const AlertEventProperties = AlertEventSchema.keyof().Values

export const WatchedAlertEventConditionTypeSchema = z.object({
  key: z.literal(AlertEventProperties.type),
  value: z.string(),
})
export type WatchedAlertEventConditionType = z.infer<typeof WatchedAlertEventConditionTypeSchema>

export const WatchedAlertEventConditionIdSchema = z.object({
  key: z.literal(AlertEventProperties.id),
  value: z.string(),
})
export type WatchedAlertEventConditionId = z.infer<typeof WatchedAlertEventConditionIdSchema>

export const WatchedAlertEventConditionMetadataSchema = z.object({
  key: z.literal(AlertEventProperties.metadata),
  value: z.string(),
  path: z.string(),
})
export type WatchedAlertEventConditionMetadata = z.infer<
  typeof WatchedAlertEventConditionMetadataSchema
>

export const WatchedAlertEventConditionPartitionKeySchema = z.object({
  key: z.literal(AlertEventProperties.partitionKey),
  value: z.string(),
})
export type WatchedAlertEventConditionPartitionKey = z.infer<
  typeof WatchedAlertEventConditionPartitionKeySchema
>

export const WatchedAlertEventConditionAlertJsonSchema = z.object({
  isJson: z.literal(true),
  path: z.string(),
  value: z.string(),
})
export type WatchedAlertEventConditionAlertJson = z.infer<
  typeof WatchedAlertEventConditionAlertJsonSchema
>

export const WatchedAlertEventConditionAlertNotJsonSchema = z.object({
  isJson: z.literal(false),
  regex: z.string(),
})
export type WatchedAlertEventConditionAlertNotJson = z.infer<
  typeof WatchedAlertEventConditionAlertNotJsonSchema
>

export const WatchedAlertEventConditionDetailsAlertSchema = z.discriminatedUnion('isJson', [
  WatchedAlertEventConditionAlertJsonSchema,
  WatchedAlertEventConditionAlertNotJsonSchema,
])
export type WatchedAlertEventConditionDetailsAlert = z.infer<
  typeof WatchedAlertEventConditionDetailsAlertSchema
>

export const WatchedAlertEventConditionAlertSchema = z.object({
  key: z.literal(AlertEventProperties.alert),
  detailConditions: WatchedAlertEventConditionDetailsAlertSchema,
})
export type WatchedAlertEventConditionAlert = z.infer<typeof WatchedAlertEventConditionAlertSchema>

export const WatchedAlertConditionsSchema = z.discriminatedUnion('key', [
  WatchedAlertEventConditionTypeSchema,
  WatchedAlertEventConditionIdSchema,
  WatchedAlertEventConditionMetadataSchema,
  WatchedAlertEventConditionPartitionKeySchema,
  WatchedAlertEventConditionAlertSchema,
])

export type WatchedAlertConditions = z.infer<typeof WatchedAlertConditionsSchema>

export const WatchedAlertRuleSchema = z.object({
  id: z.string(), // pk id
  label: z.string(), // readable label
  inclusiveConditions: WatchedAlertConditionsSchema.array(),
  exclusiveConditions: WatchedAlertConditionsSchema.array(),
})
export type WatchedAlertRule = z.infer<typeof WatchedAlertRuleSchema>

export const isWatchedAlertEventConditionAlertJsonSchema = (
  event: WatchedAlertEventConditionDetailsAlert
): event is WatchedAlertEventConditionAlertJson =>
  WatchedAlertEventConditionAlertJsonSchema.safeParse(event).success

export const WatchedAlertEventConditionAlertNotJson = (
  event: WatchedAlertEventConditionDetailsAlert
): event is WatchedAlertEventConditionAlertNotJson =>
  WatchedAlertEventConditionAlertNotJsonSchema.safeParse(event).success

export const isWatchedAlertEventConditionAlert = (
  event: WatchedAlertConditions
): event is WatchedAlertEventConditionAlert =>
  WatchedAlertEventConditionAlertSchema.safeParse(event).success

export const isWatchedAlertMetadataCondition = (
  event: WatchedAlertConditions
): event is WatchedAlertEventConditionMetadata =>
  WatchedAlertEventConditionMetadataSchema.safeParse(event).success

export const isWatchedAlertEventConditionId = (
  event: WatchedAlertConditions
): event is WatchedAlertEventConditionId =>
  WatchedAlertEventConditionIdSchema.safeParse(event).success

export const isWatchedAlertEventConditionType = (
  event: WatchedAlertConditions
): event is WatchedAlertEventConditionType =>
  WatchedAlertEventConditionTypeSchema.safeParse(event).success

export const isWatchedAlertEventConditionPartitionKey = (
  event: WatchedAlertConditions
): event is WatchedAlertEventConditionPartitionKey =>
  WatchedAlertEventConditionPartitionKeySchema.safeParse(event).success

export const AlertSubscriptionSchema = z.object({
  alertLevel: AlertLevelSchema,
  email: z.string(),
  subscriptionArn: z.string(),
})
export type AlertSubscription = z.infer<typeof AlertSubscriptionSchema>
