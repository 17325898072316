import z from 'zod'
import { VediImportStateSchema } from '../ediImport'

export const ImportStatusesSchema = z.object({
  latestImportStatus: VediImportStateSchema,
  latestStatusDate: z.string().datetime(),
  statuses: z
    .object({
      importStatus: VediImportStateSchema,
      statusDate: z.string().datetime(),
    })
    .array(),
})
export type ImportStatuses = z.infer<typeof ImportStatusesSchema>
