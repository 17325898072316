import { SerializableReadModelClaim } from '@eigtech/claims-v2-types'
import {
  UseQueryResult,
  UseSuspenseQueryResult,
  contextualGet,
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from '@eigtech/ui-shared-api'
import { claimsQueryKeys, claimsV2BasePath } from './constants'

const getClaim = async <Claim extends Partial<SerializableReadModelClaim>>(claimNumber: string) =>
  contextualGet<Claim>(`${claimsV2BasePath}/claim/${claimNumber}`)

export const getClaimQueryOptions = <Claim extends Partial<SerializableReadModelClaim>>(
  claimNumber: string
) =>
  queryOptions({
    queryKey: claimsQueryKeys.detail(claimNumber),
    queryFn: () => getClaim<Claim>(claimNumber),
    enabled: !!claimNumber,
  })

export const useGetClaim = <Claim extends Partial<SerializableReadModelClaim>>(
  claimNumber: string
) => useQuery(getClaimQueryOptions<Claim>(claimNumber)) as UseQueryResult<Claim>

export const useGetClaimSuspense = <Claim extends Partial<SerializableReadModelClaim>>(
  claimNumber: string
) => useSuspenseQuery(getClaimQueryOptions(claimNumber)) as UseSuspenseQueryResult<Claim>
