import { Handler } from 'aws-lambda'
import { Auth0IdSchema, Auth0InboxMessageSchema } from '../../base/index.js'
import { z } from 'zod'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'

export const ListMessagesRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const ListMessagesRequestQueryStringSchema = z
  .object({
    pageSize: z.coerce.number().optional(),
    startingToken: z.string().optional(),
  })
  .merge(Auth0InboxMessageSchema.pick({ messageStatus: true }).partial())
export const ListMessagesRequestPathSchema = z.object({ inboxId: Auth0IdSchema })

export const ListMessagesRequestSchema = ListMessagesRequestPathSchema.merge(
  ListMessagesRequestHeaderSchema
).merge(ListMessagesRequestQueryStringSchema)

export type ListMessagesRequestPath = z.infer<typeof ListMessagesRequestPathSchema>
export type ListMessagesRequestHeader = z.infer<typeof ListMessagesRequestHeaderSchema>
export type ListMessagesRequestQueryString = z.infer<typeof ListMessagesRequestQueryStringSchema>
export type ListMessagesRequest = z.infer<typeof ListMessagesRequestSchema>

export const ListMessagesResponseSchema = z.object({
  items: Auth0InboxMessageSchema.array(),
  paginationToken: z.record(z.string(), z.string()).optional(),
})

export type ListMessagesResponse = z.infer<typeof ListMessagesResponseSchema>

export interface ListMessagesHandler extends Handler<ListMessagesRequest, ListMessagesResponse> {}
