import {
  type Auth0User,
  type GetUserFromIdRequest,
  type GetUserFromIdResponse,
  type GetUserNameFromIdRequest,
  type GetUserNameFromIdResponse,
} from '@eigtech/auth0-types'
import { isAuth0User } from '@eigtech/shared-auth0'
import { queryOptions, useQuery } from '@tanstack/react-query'
import { contextualGet } from '../../Context'
import { auth0BasePath, auth0QueryKeys } from './constants'

async function getAuth0UserName(params: GetUserNameFromIdRequest) {
  if (!isAuth0User(params.userId)) return params.userId

  const resp = await contextualGet<GetUserNameFromIdResponse>(
    `${auth0BasePath}/${params.userId}/name`
  )

  return resp?.name ?? params.userId
}

export const getAuth0UserNameQueryOptions = (userId: string | undefined) =>
  queryOptions({
    queryKey: auth0QueryKeys.userName(userId ?? ''),
    queryFn: () => getAuth0UserName({ userId: userId ?? '' }),
    retry: 3,
    enabled: !!userId,
  })

export const useGetAuth0UserName = (userId: string | undefined) =>
  useQuery(getAuth0UserNameQueryOptions(userId))

//

async function getAuth0User(params: GetUserFromIdRequest): Promise<Auth0User | undefined> {
  if (!isAuth0User(params.userId)) return

  const resp = await contextualGet<GetUserFromIdResponse>(`${auth0BasePath}/${params.userId}`)

  return resp?.user
}

export const getAuth0UserQueryOptions = (userId: string | undefined) =>
  queryOptions({
    queryKey: auth0QueryKeys.detail(userId ?? ''),
    queryFn: () => getAuth0User({ userId: userId ?? '' }),
    retry: 3,
    enabled: !!userId,
  })

export const useGetAuth0User = (userId: string | undefined) =>
  useQuery(getAuth0UserQueryOptions(userId))
