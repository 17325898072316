import { ReactNode } from 'react'
import { FieldArrayPath, FieldValues, useFieldArray } from 'react-hook-form'
import { BaseProps, FormControl } from 'react-hook-form-chakra'
import { ArrayFieldProvider, ArrayFieldProviderProps } from './ArrayFieldContext'

export type ArrayFieldContainerProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = Pick<
  ArrayFieldProviderProps<TFieldValues, TFieldArrayName, TKeyName>,
  'defaultValue' | 'max' | 'min'
> &
  Omit<BaseProps, 'name' | 'children' | 'defaultValue'> & {
    children: ReactNode
    name: TFieldArrayName
  }

export function ArrayFieldContainer<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>(props: ArrayFieldContainerProps<TFieldValues, TFieldArrayName, TKeyName>) {
  const { defaultValue, control, min, max, ...rest } = props

  const fieldArray = useFieldArray({
    control,
    name: rest.name,
  })

  return (
    <ArrayFieldProvider
      defaultValue={defaultValue}
      fieldArray={fieldArray}
      max={max}
      min={min}
      name={rest.name}
    >
      <FormControl {...rest} />
    </ArrayFieldProvider>
  )
}

export type ArrayFieldContainerComponent<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = typeof ArrayFieldContainer<TFieldValues, TFieldArrayName, TKeyName>
