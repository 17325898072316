import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js'

export const RecordCarrierInvoicedRequestBodySchema = z.object({
  carrierInvoice: z.object({
    externalId: JobExternalIdSchema.optional(),
    carrier: ContactCornSchema.or(z.string()),
    invoicedDate: z.string().datetime(),
    notes: z.string().optional(),
  }),
})
export const RecordCarrierInvoicedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordCarrierInvoicedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordCarrierInvoicedRequestSchema = RecordCarrierInvoicedRequestBodySchema.merge(
  RecordCarrierInvoicedRequestHeadersSchema
).merge(RecordCarrierInvoicedRequestPathSchema)
export const RecordCarrierInvoicedResponseSchema = z.void()
export const RecordCarrierInvoicedApiSchema: ApiSchema = {
  body: RecordCarrierInvoicedRequestBodySchema,
  headers: RecordCarrierInvoicedRequestHeadersSchema,
  params: RecordCarrierInvoicedRequestPathSchema,
}

export interface RecordCarrierInvoicedRequestBody
  extends z.infer<typeof RecordCarrierInvoicedRequestBodySchema> {}
export interface RecordCarrierInvoicedRequestPath
  extends z.infer<typeof RecordCarrierInvoicedRequestPathSchema> {}
export interface RecordCarrierInvoicedRequest
  extends z.infer<typeof RecordCarrierInvoicedRequestSchema> {}
export type RecordCarrierInvoicedResponse = z.infer<typeof RecordCarrierInvoicedResponseSchema>
export interface RecordCarrierInvoicedHandler
  extends Handler<RecordCarrierInvoicedRequest, RecordCarrierInvoicedResponse> {}
