import z from 'zod'

export const KclFilterSchema = z.object({
  pattern: z.string(),
})

export const KclFilterCriteriaSchema = z.object({
  filtersList: KclFilterSchema.array(),
})

export const MappingStateEnumSchema = z.enum(['Enabled', 'Error', 'Disabled', 'Replay'])

export const KclEventSourceMappingSchema = z.object({
  enabled: z.boolean(),
  functionArn: z.string(),
  batchSize: z.number(),
  maximumBatchingWindowInSeconds: z.number(),
  filterCriteria: KclFilterCriteriaSchema,
  state: MappingStateEnumSchema.optional(),
})

export type KclFilter = z.infer<typeof KclFilterSchema>
export type KclFilterCriteria = z.infer<typeof KclFilterCriteriaSchema>
export type KclEventSourceMapping = z.infer<typeof KclEventSourceMappingSchema>
export type MappingStateEnum = z.infer<typeof MappingStateEnumSchema>
