// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/tag.ts

import { tagAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

// const baseStyleContainer = defineStyle({})

// const baseStyleLabel = defineStyle({})

// const baseStyleCloseButton = defineStyle({})

// const baseStyle = definePartsStyle({
//   container: baseStyleContainer,
//   label: baseStyleLabel,
//   closeButton: baseStyleCloseButton,
// })

// const sizes = {
// sm: definePartsStyle({
//   container: {},
//   closeButton: {},
// }),
// }

const variants = {
  subtle: definePartsStyle((props) => ({
    container:
      props.colorScheme === 'brandPrimary'
        ? { bg: 'brandPrimary.100', color: 'brandPrimary.1000' }
        : {},
  })),
}

export const tagTheme = defineMultiStyleConfig({
  variants,
  // baseStyle,
  // sizes,
  defaultProps: {
    colorScheme: 'brandPrimary',
  },
})
