import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const DeleteDocumentRequestPathSchema = z.object({
  entityId: CornSchema,
  documentCorn: z.string(),
})

export const DeleteDocumentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema

export const DeleteDocumentRequestSchema = DeleteDocumentRequestPathSchema.merge(
  DeleteDocumentRequestHeadersSchema
)

/**
 * Pass `description` and/or `notes`
 *
 * An empty string will set the value as `null` in the DB.
 *
 * Passing `undefined` or omitting will leave the existing value as-is.
 */
export type DeleteDocumentRequestPath = z.infer<typeof DeleteDocumentRequestPathSchema>
export type DeleteDocumentRequest = z.infer<typeof DeleteDocumentRequestSchema>
export type DeleteDocumentResponse = void

export type DeleteDocumentHandler = Handler<DeleteDocumentRequest, DeleteDocumentResponse>
