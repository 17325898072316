import { Contact, ContactCommunicationInformation } from '@eigtech/contacts-types'
import { isAnEmail } from '@eigtech/contacts-util'
import { objHasKeys, omitEmptyStringDeep } from '@eigtech/function-utils'
import { cloneDeep } from 'lodash-es'
import { ContactFormContactFields } from '../ContactFormSchema'

export function mapContactToEmails(contact: Partial<Contact>): ContactFormContactFields['emails'] {
  return (
    contact.communicationInformation?.filter(isAnEmail).map((commInfo) => ({
      email: commInfo.emailAddress,
      id: commInfo.id,
      preferenceOrder: commInfo.preferenceOrder,
      telecommunicationEquipmentType: commInfo.telecommunicationEquipmentType,
      telecommunicationUseCode: commInfo.telecommunicationUseCode,
    })) ?? [{ email: '' }]
  )
}

/**
 * Returns a copy of the incomingCommInfo with added email entries
 */
export function mapEmailsToContact(
  emails: ContactFormContactFields['emails'],
  primaryEmailIndex: ContactFormContactFields['primaryEmailIndex'],
  incomingCommInfo = [] as ContactCommunicationInformation[]
): ContactCommunicationInformation[] {
  const communicationInformation = cloneDeep(incomingCommInfo)

  // adds emails into comm info
  emails
    .filter(({ email }) => !!email)
    .forEach(({ id, email: emailAddress, preferenceOrder }, index) => {
      const existingIndex = communicationInformation.findIndex(({ id: i }) => i && i === id)
      communicationInformation.push({
        // split email if it shares the same communication info index
        id: existingIndex ? undefined : id,
        emailAddress,
        preferenceOrder:
          primaryEmailIndex === index ? 0 : preferenceOrder === 0 ? undefined : preferenceOrder,
        telecommunicationEquipmentType: 'Email',
        telecommunicationUseCode: 'Network',
      })
    })

  return communicationInformation.map(omitEmptyStringDeep).filter(objHasKeys)
}
