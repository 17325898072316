import {
  GetInspectionsRequest as BaseGetInspectionsRequest,
  GetInspectionsResponse,
  InspectionSummaryFilter,
} from '@eigtech/summarizer-types'
import {
  OmitAuthHeaders,
  contextualGet,
  queryOptions,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { schedulesQueryKeys } from './constants'
import { claimsQueryKeys, summarizerBasePath } from '../claims'

export type GetInspectionsRequest = Omit<OmitAuthHeaders<BaseGetInspectionsRequest>, 'filter'> & {
  filter: InspectionSummaryFilter[]
}

const getInspections = ({ filter, ...params }: GetInspectionsRequest) =>
  contextualGet<GetInspectionsResponse>(`${summarizerBasePath}/inspections`, {
    searchParams: { ...params, filter: encodeURI(JSON.stringify(filter)) },
  })

export const getClaimInspectionsQueryOptions = (
  claimNumber: string,
  propsRequest?: GetInspectionsRequest
) => {
  const request: GetInspectionsRequest = {
    ...propsRequest,
    filter: [
      ...(propsRequest?.filter ?? []),
      {
        field: 'entityId',
        operator: '=',
        value: claimNumber,
      },
    ],
  }

  return queryOptions({
    queryKey: claimsQueryKeys.filteredSchedule(claimNumber, request),
    queryFn: () => getInspections(request),
  })
}

export const getAllClaimsInspectionsQueryOptions = (request: GetInspectionsRequest) =>
  queryOptions({
    queryKey: schedulesQueryKeys.claimsSchedules(request),
    queryFn: () => getInspections(request),
  })

export function useGetAllClaimsInspections(request: GetInspectionsRequest) {
  return useQuery(getAllClaimsInspectionsQueryOptions(request))
}

export function useGetClaimInspections(claimNumber: string, request?: GetInspectionsRequest) {
  return useQuery(getClaimInspectionsQueryOptions(claimNumber, request))
}

export function useInvalidateAllClaimsSchedules() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: schedulesQueryKeys.claims() })
  }
}

export function useInvalidateClaimSchedules() {
  const queryClient = useQueryClient()

  return (claimNumber: string) => {
    queryClient.invalidateQueries({ queryKey: claimsQueryKeys.schedule(claimNumber) })
  }
}
