import { Checkbox, Stack } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import {
  ContactForm,
  ContactFormArrayField,
  ContactFormInputField,
  useContactFormArrayFieldRowContext,
  useContactFormContext,
} from './ContactFormSchema'
import { ContactInfoRowContainer } from './ContactInfoRowContainer'
import { Controller, useWatch } from '@eigtech/ui-shared-forms'

export function EmailFieldArray() {
  return (
    <ContactFormArrayField
      RowContainer={RowContainer}
      defaultValue={{} as ContactForm['emails'][number]}
      name="emails"
      renderRow={EmailField}
    />
  )
}

function RowContainer(props: { children: ReactNode; index: number }) {
  const isDisabledOverride = useWatch({ name: `emails.${props.index}.isDisabled` })

  return (
    <ContactInfoRowContainer
      {...props}
      indexKey="primaryEmailIndex"
      isDisabled={isDisabledOverride}
    />
  )
}

export function EmailField() {
  const { control } = useContactFormContext()
  const { index } = useContactFormArrayFieldRowContext()

  const isDisabled = useWatch({ control, name: `emails.${index}.isDisabled` })

  return (
    <Stack spacing="2" w="full">
      <ContactFormInputField
        isDisabled={!!isDisabled}
        label="Email"
        name={`emails.${index}.email`}
        type="email"
      />

      <Controller
        control={control}
        name="primaryEmailIndex"
        render={({ field: { onChange: setPrimaryIndex, value: primaryIndex, ...props } }) => (
          <Checkbox
            isChecked={primaryIndex === index}
            isInvalid={false}
            mt="0"
            onChange={(e) => (e.target.checked ? setPrimaryIndex(index) : setPrimaryIndex(null))}
            {...props}
          >
            Is Primary
          </Checkbox>
        )}
      />
    </Stack>
  )
}
