import { ClaimNoteFormDrawer } from '@eigtech/ui-estimator/components/Domains/Claims'
import {
  Box,
  CardAccordion,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { Navigate, createFileRoute, useChildMatches } from '@eigtech/ui-shared-router'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { MdNoteAdd } from 'react-icons/md'
import { TbTimeline } from 'react-icons/tb'
import { useGetClaim } from '../../../api'
import { MobileIconButton, MobileIconLink } from '../../../components/Core'
import { ROUTER_PATHS } from '../../../lib/constants'
import { routes } from './_layout.lazy'

export const Route = createFileRoute('/claims/$claimNumber/_layout/')({
  component: ClaimBase,
})

function ClaimBase() {
  const { claimNumber } = Route.useParams()

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  })

  const matches = useChildMatches()

  if (isMobile && !matches.length) {
    return (
      <Box display={{ base: 'block', lg: 'none' }}>
        <ClaimDetailLinks />
      </Box>
    )
  }

  return <Navigate params={{ claimNumber }} to={ROUTER_PATHS.claimDetailInfo.route} />
}

function ClaimDetailLinks() {
  const { claimNumber } = Route.useParams()

  const { data: claim } = useGetClaim(claimNumber)

  const noteFormDrawer = useDisclosure()

  return (
    <>
      <Stack spacing="4">
        <CardAccordion
          defaultToClosed
          heading={
            <Heading as="h3" size="sm">
              Loss Description
            </Heading>
          }
        >
          {claim?.lossDetails.lossDescription || 'No loss description provided.'}
        </CardAccordion>

        <SimpleGrid columns={[3, 4, 5, 6, 7]} spacing={['2', '4']}>
          <GridItem>
            <MobileIconButton Icon={MdNoteAdd} onClick={noteFormDrawer.onOpen}>
              Add A Note
            </MobileIconButton>
          </GridItem>
          <GridItem>
            <MobileIconLink
              Icon={FaMapMarkedAlt}
              params={{ claimNumber }}
              to={ROUTER_PATHS.claimDetailMap.route}
            >
              View In Map
            </MobileIconLink>
          </GridItem>
          <GridItem>
            <MobileIconLink
              Icon={TbTimeline}
              params={{ claimNumber }}
              to={ROUTER_PATHS.claimDetailTimeline.route}
            >
              View Timeline
            </MobileIconLink>
          </GridItem>

          {routes.map((route) => (
            <GridItem key={route.id}>
              <MobileIconLink Icon={route.icon} params={{ claimNumber }} {...route.url}>
                {route.label}
              </MobileIconLink>
            </GridItem>
          ))}
        </SimpleGrid>
      </Stack>

      {noteFormDrawer.isOpen && (
        <ClaimNoteFormDrawer {...noteFormDrawer} claimNumber={claimNumber} />
      )}
    </>
  )
}
