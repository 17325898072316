import { Row, RowData, Table } from '@tanstack/react-table'
import { createContext, MouseEvent, ReactNode, useContext } from 'react'

export type DataGridStatusProps = {
  isError?: boolean
  isFetching?: boolean
  isPending?: boolean
}

export type DataGridOptionsProps = { enableReordering?: boolean }

export type DataGridEventHandlers<TData extends RowData> = {
  onRowClick?: (row: Row<TData>, event: MouseEvent<HTMLTableDataCellElement>) => any
}

export type DataGridActionsProps = {
  refetch?: () => void
}

export type DataGridContextState<TData extends RowData> = DataGridEventHandlers<TData> &
  DataGridStatusProps &
  DataGridOptionsProps &
  DataGridActionsProps & {
    tableId: string
    table: Table<TData>
  }

const DataGridContext = createContext<DataGridContextState<any> | undefined>(undefined)

export const DataGridConsumer = DataGridContext.Consumer

export function DataGridProvider<TData extends RowData>({
  children,
  value,
}: {
  children?: ReactNode
  value: DataGridContextState<TData>
}) {
  return (
    <DataGridContext.Provider value={value as DataGridContextState<any>}>
      {children}
    </DataGridContext.Provider>
  )
}

export function useDataGridContext<TData extends RowData>() {
  const context = useContext(DataGridContext)

  if (context === undefined) {
    throw new Error('useDataGridContext must be used within a DataGridProvider')
  }

  return context as DataGridContextState<TData>
}
