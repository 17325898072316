import { format, isSameDay } from '@eigtech/ui-shared-dates'
import { Stack, chakra } from '@eigtech/ui-shared-dave'
import { ReactNode, useMemo } from 'react'
import { useMobileMonthlyCalendar } from './MobileMonthlyCalendarContext'

export type MobileMonthlyDayProps<DayData> = {
  renderDay: (
    events: (DayData & {
      date: Date
    })[]
  ) => ReactNode
}

export function MobileMonthlyDay<DayData>({ renderDay }: MobileMonthlyDayProps<DayData>) {
  const { locale, selectedMonth, selectedDate, events: allEvents } = useMobileMonthlyCalendar()
  const dayNumber = format(selectedDate, 'd', { locale })

  const events = useMemo(
    () => allEvents.filter((data) => isSameDay(data.date, selectedDate)),
    [selectedDate, allEvents]
  )

  return (
    <chakra.div p="2" title={`Events for ${format(selectedMonth, 'MMMM')} ${dayNumber}`}>
      <Stack lineHeight={1.2} mb="2" spacing="0" textAlign="center">
        <chakra.span fontSize="sm" fontWeight="bold">
          {format(selectedDate, `MMMM yyyy`, { locale })}
        </chakra.span>
        <chakra.span fontWeight="bold">{format(selectedDate, `EEEE do`, { locale })}</chakra.span>
      </Stack>
      <Stack as="ul" overflowX="hidden" overflowY="auto">
        {renderDay(
          events as (DayData & {
            date: Date
          })[]
        )}
      </Stack>
    </chakra.div>
  )
}
