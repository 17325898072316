import { JobId } from '@eigtech/restoration-types'
import { Text } from '@eigtech/ui-shared-dave'
import { Link, useRoutesContext } from '@eigtech/ui-shared-router'
import { getJobLabelFromId } from '@eigtech/restoration-util'

export type JobLinkProps = {
  jobId: JobId
  jobRoute?: (jobId: string) => string
}

export function JobLink({ jobId, jobRoute: jobRouteOverride }: JobLinkProps) {
  const { jobDetail: jobRouteDefault } = useRoutesContext()
  const jobRoute = jobRouteOverride ?? jobRouteDefault

  const jobNumber = getJobLabelFromId(jobId)

  return !!jobRoute ? (
    <Link params={{}} search={{}} to={jobRoute(jobId)}>
      {jobNumber}
    </Link>
  ) : (
    <Text as="span">{jobNumber}</Text>
  )
}
