export function formatPhoneNumber(phoneNumber: string, extensionPrefix: string = 'x') {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(.*)$/)

  if (!match) return phoneNumber

  const intlCode = match[1] ? '+1 ' : ''
  const parts = [intlCode, match[2], '-', match[3], '-', match[4]]

  const extension = match[5]
  if (!!extension) {
    parts.push(` ${extensionPrefix}${extension}`)
  }

  return parts.join('')
}
