export const textStyles = {
  overline: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    letterSpacing: 'wide',
    textTransform: 'uppercase',
  },
  body: {
    fontSize: 'md',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },
  label: {
    fontSize: 'md',
    fontWeight: 'medium',
  },
}
