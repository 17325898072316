import { createContext, ReactNode, useContext } from 'react'

export const MonthlyBodyContext = createContext<MonthlyBodyState | undefined>(undefined)

export type MonthlyBodyState = {
  day: Date
}

export function useMonthlyBody() {
  const context = useContext(MonthlyBodyContext)

  if (!context) {
    throw new Error('useMonthlyBody needs to be used with the MonthlyBodyProvider component')
  }

  return context as MonthlyBodyState
}

export type MonthlyBodyProviderProps = {
  day: Date
  children: ReactNode
}

export function MonthlyBodyProvider({ day, children }: MonthlyBodyProviderProps) {
  return <MonthlyBodyContext.Provider value={{ day }}>{children}</MonthlyBodyContext.Provider>
}
