import z from 'zod'

export const cornType = <
  T extends string | undefined,
  U extends string | undefined,
  V extends string | undefined,
>(
  serviceName?: T,
  resourceType?: U,
  resourceId?: V
) =>
  z.custom<`corn:${NonNullable<T>}:${NonNullable<U>}:${NonNullable<V>}`>((val) =>
    new RegExp(
      `^corn:${serviceName ? serviceName : '\\w+[-w]*\\w'}+:${resourceType ? resourceType : '\\w+[-w]*\\w'}+:${
        resourceId ? resourceId : '.'
      }+$`,
      'g'
    ).test(val as string)
  )

export const CornSchema = cornType()
export type Corn = z.infer<typeof CornSchema>
