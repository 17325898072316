import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'estimator'
export const estimatorServiceEventType = <
  U extends string | undefined,
  V extends string | undefined
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const EstimatorServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: estimatorServiceEventType(),
  })
)

export type EstimatorServiceEventBase = z.infer<typeof EstimatorServiceEventBaseSchema>
