import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const GetMeetingVideoRequestPathSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
  startTime: z.string().datetime(),
})

export const GetMeetingVideoRequestSchema = GetMeetingVideoRequestPathSchema

export const GetMeetingVideoResponseSchema = z.object({
  videoUrl: z.string(),
})

export type GetMeetingVideoRequest = z.infer<typeof GetMeetingVideoRequestSchema>
export type GetMeetingVideoRequestPath = z.infer<typeof GetMeetingVideoRequestPathSchema>
export type GetMeetingVideoResponse = z.infer<typeof GetMeetingVideoResponseSchema>

export interface GetMeetingVideoHandler
  extends Handler<GetMeetingVideoRequest, GetMeetingVideoResponse> {}
