import { DataGridArrayFilter } from '@eigtech/ui-shared-data-grid'
import { Text } from '@eigtech/ui-shared-dave'
import { Column } from '@tanstack/react-table'
import { TagFilterFnName } from '../../utils/filters/customFilters'
import { getTagFilterActualName } from '../../utils/filters/utils'

export function getTagFilterFnComponent<TData, TValue>({
  column,
}: {
  column: Column<TData, TValue>
}) {
  const filterName = column.getFilterFn()?.name ?? ''

  const notImplemented = <Text>Filter not implemented for: {filterName}</Text>

  const components: Record<TagFilterFnName, JSX.Element> = {
    tagIncludesKey: <DataGridArrayFilter column={column} type="some" />,
  }

  const filterActualName = getTagFilterActualName(filterName)

  return components[filterActualName] ?? notImplemented
}
