import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema, BookSchema } from '../../../base/books.js'
import { ContactSchema } from '../../../base/contacts.js'

export const DeleteBookRequestPathParametersSchema = z.object({
  bookId: BookCornSchema,
})

export const DeleteBookRequestSchema = DeleteBookRequestPathParametersSchema
export const DeleteBookResponseSchema = z.object({
  book: BookSchema.extend({
    contacts: z.array(ContactSchema),
  }).optional(),
})

export type DeleteBookRequestPathParameters = z.infer<typeof DeleteBookRequestPathParametersSchema>

export type DeleteBookRequest = z.infer<typeof DeleteBookRequestSchema>

export type DeleteBookResponse = z.infer<typeof DeleteBookResponseSchema>

export interface DeleteBookHandler extends Handler<DeleteBookRequest, DeleteBookResponse> {}

export const DeleteBookApiSchema: ApiSchema = {
  params: DeleteBookRequestPathParametersSchema,
  response: DeleteBookResponseSchema,
}
