import { Button, ButtonProps, IconButton, IconButtonProps } from '@eigtech/ui-shared-dave'
import { createLink } from '@tanstack/react-router'
import { forwardRef } from 'react'

export const LinkButton = createLink(
  forwardRef<'a', ButtonProps>(function LinkButton(props, ref) {
    return <Button ref={ref} as="a" {...props} />
  })
)

export const LinkIconButton = createLink(
  forwardRef<'a', IconButtonProps>(function LinkIconButton(props, ref) {
    return <IconButton ref={ref} as="a" {...props} />
  })
)
