import {
  CancelClaimInspectionBodyRequest,
  CancelClaimInspectionRequest,
  CancelClaimInspectionResponse,
  CompleteClaimInspectionBodyRequest,
  CompleteClaimInspectionRequest,
  CompleteClaimInspectionResponse,
  InspectionDetails,
  RequestClaimReInspectionBodyRequest,
  RequestClaimReInspectionRequest,
  RequestClaimReInspectionResponse,
  ScheduleClaimInspectionBodyRequest,
  ScheduleClaimInspectionRequest,
  ScheduleClaimInspectionResponse,
} from '@eigtech/claims-v2-types'
import {
  ClaimInspectionCanceledDetails,
  ClaimInspectionCompletedDetails,
  ClaimInspectionScheduledDetails,
  ClaimReInspectionRequestedDetails,
} from '@eigtech/summarizer-types'
import {
  OmitAuthHeaders,
  contextualPost,
  contextualPut,
  useMutation,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import {
  EntityAdminClaim,
  claimsQueryKeys,
  claimsV2BasePath,
  useInvalidateAllClaimsSchedules,
  useInvalidateClaimSchedules,
} from '@eigtech/ui-shared-claims'
import { secondsToMilliseconds } from '@eigtech/ui-shared-dates'
import { useCallback } from 'react'
import { useOptimisticAddClaimTimelineEvent } from './useOptimisticAddClaimTimelineEvent'

const scheduleInspection = ({
  claimNumber,
  ...payload
}: OmitAuthHeaders<ScheduleClaimInspectionRequest>) =>
  contextualPost<ScheduleClaimInspectionResponse, ScheduleClaimInspectionBodyRequest>(
    `${claimsV2BasePath}/claim/${claimNumber}/schedule-inspection`,
    payload
  )

export function useScheduleInspection() {
  const optimisticAddClaimTimelineEvent = useOptimisticAddClaimTimelineEvent('default')
  const optimisticUpsertInspectionOnClaim = useOptimisticUpsertInspectionOnClaim()

  const invalidateSummarizerSchedules = useInvalidateSummarizerSchedules()

  return useMutation({
    mutationFn: scheduleInspection,
    async onSuccess(inspection, { claimNumber }) {
      await Promise.all([
        optimisticUpsertInspectionOnClaim({ ...inspection, claimNumber }),

        optimisticAddClaimTimelineEvent<ClaimInspectionScheduledDetails>({
          claimNumber,
          timelineEvent: 'inspectionScheduled',
          chronicle: {
            date: inspection.scheduledDate,
            details: {
              inspection: {
                ...inspection,
                scheduledDate: inspection.scheduledDate ?? new Date().toISOString(),
                status: 'scheduled',
              },
            },
          },
        }),
      ])

      invalidateSummarizerSchedules(claimNumber)
    },
  })
}

//

const requestReinspection = ({
  claimNumber,
  ...payload
}: OmitAuthHeaders<RequestClaimReInspectionRequest>) =>
  contextualPost<RequestClaimReInspectionResponse, RequestClaimReInspectionBodyRequest>(
    `${claimsV2BasePath}/claim/${claimNumber}/request-reinspection`,
    payload
  )

export function useRequestReinspection() {
  const optimisticAddClaimTimelineEvent = useOptimisticAddClaimTimelineEvent('default')
  const optimisticUpsertInspectionOnClaim = useOptimisticUpsertInspectionOnClaim()

  const invalidateSummarizerSchedules = useInvalidateSummarizerSchedules()

  return useMutation({
    mutationFn: requestReinspection,
    async onSuccess(inspection, { claimNumber }) {
      await Promise.all([
        optimisticUpsertInspectionOnClaim({ ...inspection, claimNumber }),

        optimisticAddClaimTimelineEvent<ClaimReInspectionRequestedDetails>({
          claimNumber,
          timelineEvent: 'reInspectionRequested',
          chronicle: {
            date: new Date().toISOString(),
            details: {
              inspection: {
                ...inspection,
                status: 'requested',
              },
            },
          },
        }),
      ])

      invalidateSummarizerSchedules(claimNumber)
    },
  })
}

//

const completeInspection = ({
  claimNumber,
  ...payload
}: OmitAuthHeaders<CompleteClaimInspectionRequest>): Promise<CompleteClaimInspectionResponse> =>
  contextualPut<CompleteClaimInspectionResponse, CompleteClaimInspectionBodyRequest>(
    `${claimsV2BasePath}/claim/${claimNumber}/complete-inspection`,
    payload
  )

export function useCompleteInspection() {
  const optimisticAddClaimTimelineEvent = useOptimisticAddClaimTimelineEvent('default')
  const optimisticUpsertInspectionOnClaim = useOptimisticUpsertInspectionOnClaim()

  const invalidateSummarizerSchedules = useInvalidateSummarizerSchedules()

  return useMutation({
    mutationFn: completeInspection,
    async onSuccess(inspection, { claimNumber }) {
      await Promise.all([
        optimisticUpsertInspectionOnClaim({ ...inspection, claimNumber }),

        optimisticAddClaimTimelineEvent<ClaimInspectionCompletedDetails>({
          claimNumber,
          timelineEvent: 'inspectionCompleted',
          chronicle: {
            date: inspection.dateCompleted,
            details: {
              inspection: {
                ...inspection,
                dateCompleted: inspection.dateCompleted ?? new Date().toISOString(),
                status: 'completed',
              },
            },
          },
        }),
      ])

      invalidateSummarizerSchedules(claimNumber)
    },
  })
}

//

const cancelInspection = ({
  claimNumber,
  ...payload
}: OmitAuthHeaders<CancelClaimInspectionRequest>) =>
  contextualPut<CancelClaimInspectionResponse, CancelClaimInspectionBodyRequest>(
    `${claimsV2BasePath}/claim/${claimNumber}/cancel-inspection`,
    payload
  )

export function useCancelInspection() {
  const optimisticAddClaimTimelineEvent = useOptimisticAddClaimTimelineEvent('default')
  const optimisticUpsertInspectionOnClaim = useOptimisticUpsertInspectionOnClaim()

  const invalidateSummarizerSchedules = useInvalidateSummarizerSchedules()

  return useMutation({
    mutationFn: cancelInspection,
    async onSuccess(inspection, { claimNumber }) {
      await Promise.all([
        optimisticUpsertInspectionOnClaim({ ...inspection, claimNumber }),

        optimisticAddClaimTimelineEvent<ClaimInspectionCanceledDetails>({
          claimNumber,
          timelineEvent: 'inspectionCanceled',
          chronicle: {
            date: inspection.dateCanceled,
            details: {
              inspection: {
                ...inspection,
                dateCanceled: inspection.dateCanceled ?? new Date().toISOString(),
                status: 'canceled',
              },
            },
          },
        }),
      ])

      invalidateSummarizerSchedules(claimNumber)
    },
  })
}

function useOptimisticUpsertInspectionOnClaim() {
  const queryClient = useQueryClient()

  return useCallback(
    async ({ claimNumber, ...inspection }: InspectionDetails & { claimNumber: string }) => {
      const queryKey = claimsQueryKeys.detail(claimNumber)

      await queryClient.cancelQueries({ queryKey })

      const previousClaim = queryClient.getQueryData<EntityAdminClaim>(queryKey)

      if (!!previousClaim) {
        queryClient.setQueryData<EntityAdminClaim>(queryKey, () => ({
          ...previousClaim,
          inspections: {
            ...(previousClaim?.inspections ?? {}),
            [inspection.inspectionId]: inspection,
          },
        }))
      }
    },
    [queryClient]
  )
}

function useInvalidateSummarizerSchedules() {
  const invalidateAllClaimsSchedules = useInvalidateAllClaimsSchedules()
  const invalidateClaimSchedules = useInvalidateClaimSchedules()

  return (claimNumber: string) => {
    /**
     * Unfortunately, optimistically updating queries for inspections from summarizer
     * is pretty complicated. There can be any number of queries with different filters
     * that should include the specific inspection and determining which queries should
     * remove/add/update the inspection would be complicated and prone to errors. Thankfully,
     * summarizer seems to pick up inspection updates pretty quickly, so simply invalidating
     * inspection queries after a few seconds seems like the best course of action for now.
     */
    setTimeout(() => {
      invalidateAllClaimsSchedules()
      invalidateClaimSchedules(claimNumber)
    }, secondsToMilliseconds(5))
  }
}
