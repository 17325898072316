import {
  formatDateInTimeZone,
  formatStringAsDate,
  STANDARD_DATE_TIME_FORMAT,
} from '@eigtech/ui-shared-dates'

export const formatDate = (
  eventDate: string | number | Date | undefined | null,
  format = STANDARD_DATE_TIME_FORMAT,
  timezone?: string
) => {
  // If no time is specified, just return the date
  if (typeof eventDate === 'string' && eventDate?.toLocaleLowerCase()?.split('t')?.length === 1) {
    return `${eventDate.substring(5, 7)}/${eventDate.substring(8, 10)}/${eventDate.substring(0, 4)}`
  }

  return timezone
    ? formatDateInTimeZone(eventDate, format, timezone)
    : formatStringAsDate(eventDate, format)
}
