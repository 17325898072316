import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/base'
import { LossOfUseSchema } from '../model/lossOfUse'
import { BaseHeaderSchema } from './base'

const UpdateLossOfUseStatusSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
    id: z.string(),
  }),
  body: z.object({
    justification: z.string().optional(),
  }),
  headers: BaseHeaderSchema,
  response: LossOfUseSchema,
}

export const UpdateLossOfUseStatusRequestSchema = UpdateLossOfUseStatusSchemas.params
  .merge(UpdateLossOfUseStatusSchemas.headers)
  .merge(UpdateLossOfUseStatusSchemas.body)

export type UpdateLossOfUseStatusPathRequest = z.infer<typeof UpdateLossOfUseStatusSchemas.params>
export type UpdateLossOfUseStatusBodyRequest = z.infer<typeof UpdateLossOfUseStatusSchemas.body>
export type UpdateLossOfUseStatusHeaderRequest = z.infer<
  typeof UpdateLossOfUseStatusSchemas.headers
>
export type UpdateLossOfUseStatusRequest = z.infer<typeof UpdateLossOfUseStatusRequestSchema>
export type UpdateLossOfUseStatusResponse = z.infer<typeof UpdateLossOfUseStatusSchemas.response>

export interface UpdateLossOfUseStatusHandler
  extends Handler<UpdateLossOfUseStatusRequest, UpdateLossOfUseStatusResponse> {}

export const UpdateLossOfUseStatusApiSchema: ApiSchema = UpdateLossOfUseStatusSchemas
