export type GetGoogleMapQueryLinkProps =
  | { lat: string | number; long: string | number }
  | Partial<{
      addressLine1: string
      addressLine2: string
      city: string
      countyOrParishCode: string
      stateOrProvince: string
      zipOrPostalCode: string
    }>
  | Partial<{
      street1: string
      street2: string
      city: string
      county: string
      state: string
      zipcode: string
    }>
  | string

export function getGoogleMapQueryLink(parts: GetGoogleMapQueryLinkProps) {
  let query: string

  if (typeof parts === 'string') {
    query = parts
  } else if ('lat' in parts) {
    query = `${parts.lat},${parts.long}`
  } else if ('addressLine1' in parts) {
    const region = [parts.city, parts.countyOrParishCode, parts.stateOrProvince]
      .filter((part) => !!part)
      .join(', ')
    const order = [parts.addressLine1, parts.addressLine2, region, parts.zipOrPostalCode].filter(
      (part) => !!part
    )
    query = order.join(' ')
  } else if ('street1' in parts) {
    const region = [parts.city, parts.county, parts.state].filter((part) => !!part).join(', ')
    const order = [parts.street1, parts.street2, region, parts.zipcode].filter((part) => !!part)
    query = order.join(' ')
  } else {
    throw new Error('invalid argument for getGoogleMapQueryLink')
  }

  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`
}
