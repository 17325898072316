import { HasRequiredPermissions } from '@eigtech/ui-shared-auth'
import { List, ListItem, ListItemProps, ListProps, forwardRef } from '@eigtech/ui-shared-dave'
import { NavButton } from './NavButton'
import { NavItem } from './types'

export type NavListProps = ListProps & { items: NavItem[] }

export const NavList = forwardRef<NavListProps, 'ul'>(({ items, ...props }, ref) => {
  return (
    <List ref={ref} spacing="1" {...props}>
      {items.map((item) => (
        <NavListItem key={item.label} item={item} />
      ))}
    </List>
  )
})

export type NavListItemProps = ListItemProps & { item: NavItem }

export const NavListItem = forwardRef<NavListItemProps, 'li'>(({ item, ...props }, ref) => {
  return (
    <HasRequiredPermissions requiredPermissions={item.requiredPermissions}>
      <ListItem ref={ref} {...props}>
        <NavButton item={item} />
      </ListItem>
    </HasRequiredPermissions>
  )
})
