import { Stack } from '@eigtech/ui-shared-dave'
import { isEmpty } from 'lodash-es'
import { z } from 'zod'

export type EventDetailsComponentProps<T> = { details: T; entityId: string }

export type TimelineEventDetailsProps<T extends z.ZodTypeAny> = {
  details: Record<string, unknown>
  entityId: string
  Component: (props: EventDetailsComponentProps<z.infer<T>>) => JSX.Element | null
  Schema: T
}

export function TimelineEventDetails<T extends z.ZodTypeAny>({
  details,
  entityId,
  Component,
  Schema,
}: TimelineEventDetailsProps<T>) {
  const result = Schema.safeParse(details)

  if (!result.success || isEmpty(result.data)) return null

  return (
    <Stack py="2" spacing="3">
      <Component details={result.data} entityId={entityId} />
    </Stack>
  )
}

export function makeDetailsComponent<T extends z.ZodTypeAny>(
  args: Pick<TimelineEventDetailsProps<T>, 'Component' | 'Schema'>
) {
  return function DetailsComponent(props: { details: Record<string, unknown>; entityId: string }) {
    return <TimelineEventDetails {...args} {...props} />
  }
}
