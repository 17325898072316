import { PhoneNumberOptionalSchema } from '@eigtech/function-utils'
import z from 'zod'
import { MeetingDetailsSchema } from './meetingDetails.js'

export const MeetingInviteeSchema = z
  .object({
    auth0Id: z.string().optional(),
    email: z.string().optional(),
    phone: PhoneNumberOptionalSchema,
    name: z.string(),
  })
  .refine((val) => val.email || val.phone)

export const MeetingInviteSchema = z.object({
  dateCreated: z.string().datetime(),
  from: z.object({
    auth0Id: z.string().optional(),
    name: z.string(),
    role: z.string(),
  }),
  to: z.array(MeetingInviteeSchema),
  meetingDetails: MeetingDetailsSchema,
})

export interface MeetingInvite extends z.infer<typeof MeetingInviteSchema> {}
export interface MeetingInvitee extends z.infer<typeof MeetingInviteeSchema> {}
export interface MeetingInviteeWithEmail extends MeetingInvitee {
  email: string
}
export interface MeetingInviteeWithPhone extends MeetingInvitee {
  phone: string
}
