import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { useCheckIfAccessTokenSet } from '@eigtech/ui-shared-auth'

type UsePutPreferencesProps<PreferencesType> = {
  queryKey: QueryKey
  queryFunction: QueryFunction<PreferencesType>
  mergeFunction: (
    oldPreferences: PreferencesType,
    newPreferences: Partial<PreferencesType>
  ) => PreferencesType
  mutationFunction: MutationFunction<PreferencesType, PreferencesType>
}

export function usePutPreferences<PreferencesType>({
  queryKey,
  queryFunction,
  mergeFunction,
  mutationFunction,
}: UsePutPreferencesProps<PreferencesType>) {
  const accessTokenSet = useCheckIfAccessTokenSet()
  const { data: currentPreferences } = useQuery({
    queryKey,
    queryFn: queryFunction,
    enabled: accessTokenSet,
  })

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (preferences: Partial<PreferencesType>) => {
      const wrappedMergeFunction = async () => {
        const oldPreferences =
          currentPreferences ||
          (await queryClient.fetchQuery<PreferencesType, unknown, PreferencesType>({
            queryKey,
            queryFn: queryFunction,
          }))

        return mergeFunction(oldPreferences, preferences)
      }
      const newPreferences = await wrappedMergeFunction()
      return mutationFunction(newPreferences)
    },
    async onMutate(preferences) {
      await queryClient.cancelQueries({ queryKey })

      const previousPreferences = queryClient.getQueryData<PreferencesType>(queryKey)

      if (!!previousPreferences) {
        const newPreferences = await mergeFunction(previousPreferences, preferences)
        queryClient.setQueryData(queryKey, newPreferences)
      }

      return { previousPreferences }
    },
    onError(error, newSettings, context: any) {
      if (!!context?.previousPreferences) {
        queryClient.setQueryData(queryKey, context.previousPreferences)
      }

      queryClient.invalidateQueries({ queryKey })
    },
  })
}
