import { chakra, ComponentWithAs, forwardRef, StyleProps } from '@chakra-ui/react'

export type EmojiProps = {
  label?: string
  symbol: string
} & StyleProps

export const Emoji: ComponentWithAs<'span', EmojiProps> = forwardRef(
  ({ label, symbol, ...props }, ref) => (
    <chakra.span
      ref={ref}
      aria-hidden={!!label ? 'false' : 'true'}
      aria-label={!!label ? label : ''}
      role="img"
      {...props}
    >
      {symbol}
    </chakra.span>
  )
)
