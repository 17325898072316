import z from 'zod'
import { BaseJobSchema } from './baseJob.js'
import { JobService } from './baseJobService.js'

export const DailyJobTypeSchema = z.literal('daily')
export const JOB_TYPE_DAILY = DailyJobTypeSchema.value

export const DailyJobTypeAbbreviationSchema = z.literal('DAY')
export const JOB_TYPE_DAILY_ABBREVIATION = DailyJobTypeAbbreviationSchema.value

export const DailyServiceSchema = z.enum([
  JobService.interior,
  JobService.exterior,
  JobService.roof,
  JobService.detachedStructures,
])

export const DailyJobSchema = BaseJobSchema.merge(
  z.object({
    jobType: DailyJobTypeSchema,
    services: z.array(DailyServiceSchema).transform((val) => Array.from(new Set(val))),
  })
)

export const isDailyService = (s: DailyServiceEnum): s is DailyServiceEnum =>
  DailyServiceSchema.safeParse(s).success

export interface DailyJob extends z.infer<typeof DailyJobSchema> {}
export type DailyServiceEnum = z.infer<typeof DailyServiceSchema>
