import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AttendeeSchema, MeetingSchema } from '../base/chime.js'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'
import { ParticipantSchema } from '../base/participant.js'

export const JoinMeetingAsGuestRequestPathSchema = z.object({
  jwt: z.string(),
})

export const JoinMeetingAsGuestJwtPayloadSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
  participant: ParticipantSchema.omit({ auth0Id: true, attendeeId: true }),
})

export const JoinMeetingAsGuestRequestSchema = JoinMeetingAsGuestRequestPathSchema

export const JoinMeetingAsGuestResponseSchema = z.object({
  meetingDetails: MeetingDetailsSchema,
  chimeMeeting: MeetingSchema,
  chimeAttendee: AttendeeSchema,
})

export type JoinMeetingAsGuestJwtPayload = z.infer<typeof JoinMeetingAsGuestJwtPayloadSchema>
export type JoinMeetingAsGuestRequest = z.infer<typeof JoinMeetingAsGuestRequestSchema>
export type JoinMeetingAsGuestRequestPath = z.infer<typeof JoinMeetingAsGuestRequestPathSchema>
export type JoinMeetingAsGuestResponse = z.infer<typeof JoinMeetingAsGuestResponseSchema>

export interface JoinMeetingAsGuestHandler
  extends Handler<JoinMeetingAsGuestRequest, JoinMeetingAsGuestResponse> {}
