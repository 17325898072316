import { Column, FilterFnOption, filterFns, RowData } from '@tanstack/react-table'
import { mapKeys, startCase } from 'lodash-es'
import { FilterFnName } from '../types'
import { customFilterFns } from './Filters'

export const getColumnTitle = <TData extends RowData, TValue>(
  column: Column<TData, TValue> | undefined
) =>
  column === undefined
    ? ''
    : typeof column.columnDef.header === 'string'
    ? column.columnDef.header
    : startCase(column.id)

const filterDict: Record<FilterFnName, string> = {
  arrIncludes: 'arrIncludes',
  arrIncludesAll: 'arrIncludesAll',
  arrIncludesSome: 'arrIncludesSome',
  equals: 'equals',
  equalsString: 'equalsString',
  includesString: 'includesString',
  includesStringSensitive: 'includesStringSensitive',
  inNumberRange: 'inNumberRange',
  weakEquals: 'weakEquals',
  dateEquals: 'dateEquals',
  dateGreaterThan: 'dateGreaterThan',
  dateGreaterEqualThan: 'dateGreaterEqualThan',
  dateLesserThan: 'dateLesserThan',
  dateLesserEqualThan: 'dateLesserEqualThan',
  dateTimeEquals: 'dateTimeEquals',
  dateTimeGreaterThan: 'dateTimeGreaterThan',
  dateTimeGreaterEqualThan: 'dateTimeGreaterEqualThan',
  dateTimeLesserThan: 'dateTimeLesserThan',
  dateTimeLesserEqualThan: 'dateTimeLesserEqualThan',
}

const allFilterFns = {
  ...filterFns,
  ...customFilterFns,
}

/**
 * This converts the keys of the above filterDict to be their actual minified names
 * @example
 * {
 *   a: 'arrIncludes',
 *   b: 'arrIncludesAll',
 *   // ...
 * }
 */
const filterNameDict = mapKeys(filterDict, (key) => allFilterFns[key as FilterFnName].name)

/**
 *
 * @param filterName the minified filter name
 * @returns the actual filter name for the minified name
 * @example
 * const filterNameDict = {
 *   a: 'arrIncludes',
 *   // ...
 * }
 *
 * getFilterActualName('a')
 * // returns 'arrIncludes'
 */
export const getFilterActualName = (filterName: string | FilterFnOption<any>) =>
  (typeof filterName === 'function'
    ? filterName.name
    : filterNameDict[filterName] ?? filterName) as FilterFnName
