import { ApiSchema } from '@eigtech/gatekeeper-types'
import { PortalConsumerSchema } from '@eigtech/portals-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const ListClaimPortalsRequestPathSchema = z.object({
  claimNumber: z.string(),
})

export const ListClaimPortalsRequestSchema = ListClaimPortalsRequestPathSchema

export const ListClaimPortalsResponseSchema = z.object({
  portals: z.array(PortalConsumerSchema),
})

export type ListClaimPortalsRequestPath = z.infer<typeof ListClaimPortalsRequestPathSchema>
export type ListClaimPortalsRequest = z.infer<typeof ListClaimPortalsRequestSchema>
export type ListClaimPortalsResponse = z.infer<typeof ListClaimPortalsResponseSchema>

export interface ListClaimPortalsHandler
  extends Handler<ListClaimPortalsRequest, ListClaimPortalsResponse> {}

export const ListClaimPortalsApiSchema = {
  params: ListClaimPortalsRequestPathSchema,
  response: ListClaimPortalsResponseSchema,
} satisfies ApiSchema
