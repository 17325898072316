import { isValid, setSeconds } from '@eigtech/ui-shared-dates'
import { FilterFn } from '@tanstack/react-table'

type DateFilter =
  | 'dateEquals'
  | 'dateGreaterThan'
  | 'dateGreaterEqualThan'
  | 'dateLesserThan'
  | 'dateLesserEqualThan'

type DateTimeFilter =
  | 'dateTimeEquals'
  | 'dateTimeGreaterThan'
  | 'dateTimeGreaterEqualThan'
  | 'dateTimeLesserThan'
  | 'dateTimeLesserEqualThan'

// #region Date Filters
const getDates = (value: string | Date, filterValue: string) => {
  const valueDate = new Date(value)

  const time = valueDate.toISOString().split('T')[1]
  const filterWithTime = `${filterValue.split('T')[0]}T${time}`
  const filterDate = new Date(filterWithTime)

  return { valueDate, filterDate }
}

// Keeping these only in a record prevents static builds from minifying the names

export const dateFilterFns: Record<DateFilter, FilterFn<any>> = {
  dateEquals: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const { filterDate, valueDate } = getDates(value, filterValue)

    return valueDate.toDateString() === filterDate.toDateString()
  },
  dateGreaterThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const { filterDate, valueDate } = getDates(value, filterValue)

    return valueDate > filterDate
  },
  dateGreaterEqualThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const { filterDate, valueDate } = getDates(value, filterValue)

    return valueDate >= filterDate
  },
  dateLesserThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const { filterDate, valueDate } = getDates(value, filterValue)

    return valueDate < filterDate
  },
  dateLesserEqualThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const { filterDate, valueDate } = getDates(value, filterValue)

    return valueDate <= filterDate
  },
}
// #endregion

// #region Date Time Filters
// Keeping these only in a record prevents static builds from minifying the names
export const dateTimeFilterFns: Record<DateTimeFilter, FilterFn<any>> = {
  dateTimeEquals: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const valueDate = setSeconds(new Date(value), 0).getTime()
    const filterDate = setSeconds(new Date(filterValue), 0).getTime()

    return valueDate === filterDate
  },
  dateTimeGreaterThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const valueDate = setSeconds(new Date(value), 0).getTime()
    const filterDate = setSeconds(new Date(filterValue), 0).getTime()

    return valueDate > filterDate
  },
  dateTimeGreaterEqualThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const valueDate = setSeconds(new Date(value), 0).getTime()
    const filterDate = setSeconds(new Date(filterValue), 0).getTime()

    return valueDate >= filterDate
  },
  dateTimeLesserThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const valueDate = setSeconds(new Date(value), 0).getTime()
    const filterDate = setSeconds(new Date(filterValue), 0).getTime()

    return valueDate < filterDate
  },
  dateTimeLesserEqualThan: (row, columnId, filterValue: string) => {
    const value = row.getValue<string | Date | undefined>(columnId)
    if (!value || !isValid(new Date(value))) return false

    const valueDate = setSeconds(new Date(value), 0).getTime()
    const filterDate = setSeconds(new Date(filterValue), 0).getTime()

    return valueDate <= filterDate
  },
}

// #endregion

export const customFilterFns = { ...dateFilterFns, ...dateTimeFilterFns }

export type CustomFilterFn = keyof typeof customFilterFns
