import { format, isSameDay } from '@eigtech/ui-shared-dates'
import { chakra, HStack, SimpleGrid, Stack } from '@eigtech/ui-shared-dave'
import { ReactNode, useMemo } from 'react'
import { handleOmittedDays } from '../utils'
import { MonthlyBodyProvider, useMonthlyBody } from './MonthlyBodyContext'
import { useMonthlyCalendar } from './MonthlyCalendarContext'

export type MonthlyBodyProps = {
  /*
    skip days, an array of days, starts at sunday (0), saturday is 6
    ex: [0,6] would remove sunday and saturday from rendering
  */
  omitDays?: number[]
  children: ReactNode
}

export function MonthlyBody({ omitDays, children }: MonthlyBodyProps) {
  const { days, locale } = useMonthlyCalendar()
  const { headings, daysToRender, padding } = handleOmittedDays({
    days,
    omitDays,
    locale,
  })

  const headingProps = {
    borderBottom: '2px',
    borderBottomColor: 'gray.200',
    borderRight: '2px',
    borderRightColor: 'gray.200',
    padding: '2',
  }

  return (
    <chakra.div
      bg="white"
      borderLeft="2px"
      borderLeftColor="gray.200"
      borderTop="2px"
      borderTopColor="gray.200"
    >
      <SimpleGrid columns={[1, 2, 4, 7]}>
        {headings.map((day) => (
          <chakra.div key={day.day} {...headingProps} title="Day of Week">
            {day.label}
          </chakra.div>
        ))}
        {padding.map((_, index) => (
          <chakra.div key={index} {...headingProps} title="Empty Day" />
        ))}
        {daysToRender.map((day) => (
          <MonthlyBodyProvider key={day.toISOString()} day={day}>
            {children}
          </MonthlyBodyProvider>
        ))}
      </SimpleGrid>
    </chakra.div>
  )
}

export type MonthlyDayProps<DayData> = {
  renderDays: (events: DayData[]) => ReactNode
}

export function MonthlyDay<DayData>({ renderDays }: MonthlyDayProps<DayData>) {
  const { locale, selectedMonth, events: allEvents } = useMonthlyCalendar<DayData>()
  const { day } = useMonthlyBody()
  const dayNumber = format(day, 'd', { locale })

  const events = useMemo(
    () =>
      [...allEvents]
        .filter((data) => isSameDay(data.date, day))
        .sort((a, b) => a.date.getTime() - b.date.getTime()),
    [day, allEvents]
  )

  return (
    <chakra.div
      borderBottom="2px"
      borderBottomColor="gray.200"
      borderRight="2px"
      borderRightColor="gray.200"
      h="48"
      p="2"
      title={`Events for ${format(selectedMonth, 'MMMM')} ${dayNumber}`}
    >
      <HStack justifyContent="space-between">
        <chakra.span fontWeight="bold">{dayNumber}</chakra.span>
        <chakra.span display={{ base: 'block', lg: 'none' }}>
          {format(day, 'EEEE', { locale })}
        </chakra.span>
      </HStack>
      <Stack as="ul" maxH="36" overflowX="hidden" overflowY="auto">
        {renderDays(events)}
      </Stack>
    </chakra.div>
  )
}
