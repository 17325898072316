import { Checkbox, GridItem, SimpleGrid, Stack } from '@eigtech/ui-shared-dave'
import {
  ContactForm,
  ContactFormArrayField,
  ContactFormInputField,
  ContactFormMaskedInputField,
  useContactFormArrayFieldRowContext,
  useContactFormContext,
} from './ContactFormSchema'
import { ContactInfoRowContainer } from './ContactInfoRowContainer'
import { ReactNode } from 'react'
import { Controller } from '@eigtech/ui-shared-forms'

export function PhoneFieldArray() {
  return (
    <ContactFormArrayField
      RowContainer={RowContainer}
      defaultValue={{ extensionPrefix: 'x' } as ContactForm['phones'][number]}
      name="phones"
      renderRow={PhoneField}
    />
  )
}

function RowContainer(props: { children: ReactNode; index: number }) {
  return <ContactInfoRowContainer {...props} indexKey="primaryPhoneIndex" />
}

export function PhoneField() {
  const { control } = useContactFormContext()
  const { index, name } = useContactFormArrayFieldRowContext<'phones'>()

  return (
    <Stack spacing={1}>
      <SimpleGrid columns={6} gap="4">
        <GridItem colSpan={4}>
          <ContactFormMaskedInputField
            label="Phone"
            mask="000-000-0000"
            name={`phones.${index}.phone`}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <ContactFormInputField
            inputProps={{ maxLength: 10 }}
            label="Extension"
            name={`${name}.extension`}
          />
        </GridItem>
      </SimpleGrid>

      <Controller
        control={control}
        name="primaryPhoneIndex"
        render={({ field: { onChange: setPrimaryIndex, value: primaryIndex, ...props } }) => (
          <Checkbox
            isChecked={primaryIndex === index}
            isInvalid={false}
            mt="0"
            onChange={(e) => (e.target.checked ? setPrimaryIndex(index) : setPrimaryIndex(null))}
            {...props}
          >
            Is Primary
          </Checkbox>
        )}
      />
    </Stack>
  )
}
