import { DocumentColumn } from '@eigtech/ui-shared-documents'
import { FilterFn } from '@tanstack/react-table'

export declare const filterFns: {
  tagIncludesKey: FilterFn<DocumentColumn>
}
export type TagFilterFnName = keyof typeof filterFns

export const tagFilterFns: Record<TagFilterFnName, FilterFn<DocumentColumn>> = {
  tagIncludesKey: (row, _, filterValues: string[]) =>
    row.original.tags?.some((tag) =>
      typeof tag === 'string'
        ? filterValues.some((filter) => filter === tag)
        : filterValues.some((filter) => filter === tag.key)
    ) ?? false,
}

export const tagFilterLabels: Record<TagFilterFnName, (keys: string[]) => string> = {
  tagIncludesKey: (keys: string[]) => `includes "${keys.join(' or ')}"`,
}

export type TagFilterFn = keyof typeof tagFilterFns
