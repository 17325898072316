import { JobAssigneeRelationship } from '@eigtech/assignments-types'
import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

const jobAssignmentFields = {
  job_id: Schema.String,
  assignee_type: Schema.String,
  assignee_id: Schema.String,
}
const jobMonoAssignmentTable = defineTable('job_poly_assignment', jobAssignmentFields)
const jobPolyAssignmentTable = defineTable('job_poly_assignment', jobAssignmentFields)

export type NewJobMonoAssignmentRecord = NewTableRow<typeof jobMonoAssignmentTable> & {
  assignee_type: JobAssigneeRelationship
}

export type NewJobPolyAssignmentRecord = NewTableRow<typeof jobPolyAssignmentTable> & {
  assignee_type: JobAssigneeRelationship
}
export type JobMonoAssignmentRecord = TableRow<typeof jobMonoAssignmentTable>
export type JobPolyAssignmentRecord = TableRow<typeof jobPolyAssignmentTable>
