import { GetTimelineRequest } from '@eigtech/summarizer-types'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import { claimsQueryKeys } from '@eigtech/ui-shared-claims'
import { jobsQueryKeys } from '@eigtech/ui-shared-jobs'
import { exhaustive } from 'exhaustive'

export const summarizerBasePath = 'summarizer'

export const timelineQueryKeys = {
  timeline({ entityId, entityType, timelineType }: OmitAuthHeaders<GetTimelineRequest>) {
    const detailKey = exhaustive(entityType, {
      claim: () => claimsQueryKeys.detail(entityId),
      job: () => jobsQueryKeys.detail(entityId),
    })

    return [...detailKey, 'timeline', timelineType]
  },
}
