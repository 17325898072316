import {
  AssignmentsServiceEventBaseSchema,
  ClaimAssigneeRelationshipCanonSchema,
  JobAssigneeRelationshipEnumSchema,
} from '@eigtech/assignments-types'
import { ClaimsV2ServiceEventBaseSchema } from '@eigtech/claims-v2-types'
import { RestorationServiceEventBaseSchema } from '@eigtech/restoration-types'
import z from 'zod'

export const SummarizerProjectorEventSchema = z.union([
  AssignmentsServiceEventBaseSchema,
  ClaimsV2ServiceEventBaseSchema,
  RestorationServiceEventBaseSchema,
])

export type SummarizerProjectorEvent = z.infer<typeof SummarizerProjectorEventSchema>

export const SummarizerClaimAssigneeRelationshipEnumSchema = ClaimAssigneeRelationshipCanonSchema
export const SummarizerJobAssigneeRelationshipEnumSchema = JobAssigneeRelationshipEnumSchema
export const SummarizerJobAssigneeRelationshipEnum = z.nativeEnum(
  SummarizerJobAssigneeRelationshipEnumSchema.Values
)

export type SummarizerClaimAssigneeRelationship = z.infer<
  typeof SummarizerClaimAssigneeRelationshipEnumSchema
>
export type SummarizerJobAssigneeRelationship = z.infer<
  typeof SummarizerJobAssigneeRelationshipEnum
>
