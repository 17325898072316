import {
  ComponentWithAs,
  createIcon,
  forwardRef,
  IconProps,
  Link,
  LinkProps,
} from '@chakra-ui/react'

export type FancyLinkProps = {
  customIcon?: ComponentWithAs<'svg', IconProps>
  iconPosition?: 'left' | 'right'
  iconProps?: IconProps
} & LinkProps

export const FancyLink = forwardRef<FancyLinkProps, 'a'>(
  (
    {
      children,
      customIcon,
      href,
      iconPosition = 'right',
      iconProps = {},
      isExternal = false,
      ...props
    },
    ref
  ) => {
    const isPhone = href?.startsWith('tel:')
    const isEmail = href?.startsWith('mailto:')

    const Icon = forwardRef<IconProps, 'svg'>((props, ref) => {
      let Icon = InternalLinkIcon
      if (isPhone) Icon = PhoneLinkIcon
      if (isEmail) Icon = EmailLinkIcon
      if (isExternal) Icon = ExternalLinkIcon
      if (!!customIcon) Icon = customIcon

      return <Icon ref={ref} mb="0.5" mx="1" {...iconProps} {...props} />
    })

    return (
      <Link ref={ref} href={href} isExternal={isExternal} {...props}>
        {iconPosition === 'left' && <Icon />}
        {children}
        {iconPosition === 'right' && <Icon />}
      </Link>
    )
  }
)

export const ExternalLinkIcon = createIcon({
  displayName: 'ExternalLinkIcon',
  d: 'M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z',
})

export const InternalLinkIcon = createIcon({
  displayName: 'InternalLinkIcon',
  d: 'M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z',
})

export const PhoneLinkIcon = createIcon({
  displayName: 'PhoneLinkIcon',
  d: 'M17.38 10.79l-2.2-2.2c-.28-.28-.36-.67-.25-1.02.37-1.12.57-2.32.57-3.57 0-.55.45-1 1-1H20c.55 0 1 .45 1 1 0 9.39-7.61 17-17 17-.55 0-1-.45-1-1v-3.49c0-.55.45-1 1-1 1.24 0 2.45-.2 3.57-.57.35-.12.75-.03 1.02.24l2.2 2.2c2.83-1.45 5.15-3.76 6.59-6.59z',
})

export const EmailLinkIcon = createIcon({
  displayName: 'EmailLinkIcon',
  d: 'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z',
})
