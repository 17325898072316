import { mapKeys } from 'lodash-es'
import { FilterFnOption } from '@eigtech/ui-shared-data-grid'
import { TagFilterFnName, tagFilterFns } from './customFilters'

const filterDict: Record<TagFilterFnName, string> = {
  tagIncludesKey: 'tagIncludesKey',
}

const filterNameDict = mapKeys(filterDict, (key) => tagFilterFns[key as TagFilterFnName].name)

export const getTagFilterActualName = (filterName: string | FilterFnOption<any>) =>
  (typeof filterName === 'function'
    ? filterName.name
    : filterNameDict[filterName] ?? filterName) as TagFilterFnName
