import { InspectionStatus } from '@eigtech/claims-v2-types'
import { CustomThemeTypings } from '@eigtech/ui-shared-dave'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'

const inspectionStatusLabelRecord: Partial<Record<InspectionStatus, string>> = {}

export const getInspectionStatusLabel = (status: InspectionStatus) =>
  inspectionStatusLabelRecord[status] ?? startCase(status)

export const getInspectionStatusColorScheme = (inspectionStatus: InspectionStatus) =>
  exhaustive(inspectionStatus, {
    canceled: (): CustomThemeTypings['colorSchemes'] => 'yellow',
    completed: (): CustomThemeTypings['colorSchemes'] => 'green',
    requested: (): CustomThemeTypings['colorSchemes'] => 'purple',
    scheduled: (): CustomThemeTypings['colorSchemes'] => 'blue',
  })
