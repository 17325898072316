import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'

export const GetContactRequestPathParametersSchema = z.object({
  contactId: ContactCornSchema,
})

export const GetContactRequestSchema = GetContactRequestPathParametersSchema

export const GetContactResponseSchema = ContactSchema

export type GetContactRequestPathParameters = z.infer<typeof GetContactRequestPathParametersSchema>

export type GetContactRequest = z.infer<typeof GetContactRequestSchema>

export type GetContactResponse = z.infer<typeof GetContactResponseSchema>

export interface GetContactHandler extends Handler<GetContactRequest, GetContactResponse> {}

export const GetContactApiSchema: ApiSchema = {
  params: GetContactRequestPathParametersSchema,
  response: GetContactResponseSchema,
}
