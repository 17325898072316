import { Button, SimpleGrid, Stack, useFormControlContext } from '@eigtech/ui-shared-dave'
import { useArrayFieldRemoveButtonProps } from '@eigtech/ui-shared-forms'
import { US_STATES_AS_OPTIONS } from '@eigtech/ui-shared-us-states'
import { ReactNode } from 'react'
import { MdDelete } from 'react-icons/md'
import {
  ContactForm,
  ContactFormAddressTypesFieldSchema,
  ContactFormArrayField,
  ContactFormInputField,
  ContactFormSelectField,
  useContactFormArrayFieldRowContext,
} from './ContactFormSchema'

export function AddressFieldArray() {
  return (
    <ContactFormArrayField
      RowContainer={RowContainer}
      defaultValue={{ addressType: '', state: '' } as ContactForm['addresses'][number]}
      name="addresses"
      renderRow={AddressField}
    />
  )
}

function RowContainer({ children, index }: { children: ReactNode; index: number }) {
  const { id } = useFormControlContext()

  const rowId = `${id}-row-${index}`

  const removeButtonProps = useArrayFieldRemoveButtonProps()

  return (
    <Stack id={rowId} mb="4" spacing="4">
      {children}
      <Button {...removeButtonProps} colorScheme="red" rightIcon={<MdDelete />} variant="outline">
        Remove Address
      </Button>
    </Stack>
  )
}

export function AddressField() {
  const { index } = useContactFormArrayFieldRowContext()

  return (
    <Stack>
      <ContactFormInputField label="Street Address" name={`addresses.${index}.streetAddress`} />
      <ContactFormInputField label="Street Address 2" name={`addresses.${index}.streetAddress2`} />
      <SimpleGrid columns={2} spacing="2">
        <ContactFormInputField label="City" name={`addresses.${index}.city`} />
        <SimpleGrid columns={2} spacing="2">
          <ContactFormSelectField
            label="State"
            name={`addresses.${index}.state`}
            options={US_STATES_AS_OPTIONS}
            placeholder="Select State"
          />
          <ContactFormInputField
            inputProps={{ max: 5 }}
            label="ZIP Code"
            name={`addresses.${index}.zip`}
          />
        </SimpleGrid>
      </SimpleGrid>
      <ContactFormSelectField
        label="Address Type"
        name={`addresses.${index}.addressType`}
        options={ContactFormAddressTypesFieldSchema.options.map((option) => ({
          value: option,
        }))}
        placeholder="Select Address Type"
      />
    </Stack>
  )
}
