import { EstimateId, EstimateReview } from '@eigtech/claims-v2-types'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { useCohortContext } from '@eigtech/ui-shared-api'
import { useUserContext } from '@eigtech/ui-shared-auth'
import { getContactName, useGetContact } from '@eigtech/ui-shared-contacts'
import { DataGrid } from '@eigtech/ui-shared-data-grid'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import {
  Badge,
  Button,
  ButtonGroup,
  ComposedAlert,
  ComposedCard,
  ExternalLinkIcon,
  HStack,
  Property,
  PropertyList,
  SkeletonWrapper,
  Stack,
  Text,
  UseDisclosureReturn,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import {
  useGetEntityDocuments,
  useOrganizeDocuments,
  useSharedDocumentsColumns,
} from '@eigtech/ui-shared-documents'
import { LinkButton, useRoutesContext } from '@eigtech/ui-shared-router'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'
import { useMemo } from 'react'
import { useGetClaim } from '../api'
import { DelegateAdminClaim } from '../types/DelegateAdminClaim'
import { EntityAdminClaim } from '../types/EntityAdminClaim'
import { renderEstimateAmount } from './renderEstimateAmount'

export type ClaimEstimateDetailProps = {
  claimNumber: string
  estimateId: EstimateId
  ReviewEstimateDrawer?: (
    props: { claimNumber: string; estimateId: EstimateId } & UseDisclosureReturn
  ) => JSX.Element
}

export function ClaimEstimateDetail({
  claimNumber,
  estimateId,
  ReviewEstimateDrawer,
}: ClaimEstimateDetailProps) {
  const { data: claim, isPending } = useGetClaim<DelegateAdminClaim | EntityAdminClaim>(claimNumber)

  const estimate = claim?.estimates?.[estimateId]

  const { PreferredDateTimeComponent } = useDatesContext()
  const { PreferredUserComponent } = useUserContext()

  const { claimDetailDocuments } = useRoutesContext()

  if (!estimate && !isPending) {
    return <ComposedAlert alert="Could not find estimate" status="error" />
  }

  return (
    <HStack alignItems="flex-start" spacing="8">
      <Stack spacing="6" w="35%">
        <ComposedCard heading="Summary">
          <SkeletonWrapper h="60" isLoaded={!isPending}>
            <PropertyList>
              {Object.entries(estimate?.estimate.summary ?? {}).map(
                ([estimateType, estimateAmount]) => (
                  <Property
                    key={estimateType}
                    label={startCase(estimateType)}
                    value={!!estimateAmount ? renderEstimateAmount(estimateAmount) : '(None)'}
                    valueProps={{
                      textAlign: 'right',
                      justifyContent: 'flex-end',
                    }}
                  />
                )
              )}
            </PropertyList>
          </SkeletonWrapper>
        </ComposedCard>

        <ComposedCard heading="Details">
          <SkeletonWrapper h="60" isLoaded={!isPending}>
            <PropertyList labelWidth="40%">
              <Property label="ID" value={estimate?.estimate.estimateId} />
              <Property label="Type" value={estimate?.estimate.estimateType} />
              <Property label="External Source" value={estimate?.estimate.externalSource} />
              <Property label="External Source ID" value={estimate?.estimate.externalSourceId} />
              <Property
                label="Submission Date"
                value={
                  <PreferredDateTimeComponent property="submissionDate">
                    {estimate?.estimate.submissionDate}
                  </PreferredDateTimeComponent>
                }
              />
              <Property
                label="Submitted By"
                value={
                  !!estimate?.estimate.submittedBy && (
                    <PreferredUserComponent
                      property="submittedBy"
                      user={estimate.estimate.submittedBy}
                    />
                  )
                }
              />
            </PropertyList>
          </SkeletonWrapper>
        </ComposedCard>

        <ComposedCard heading="Notes">
          <SkeletonWrapper h="60" isLoaded={!isPending}>
            <Text whiteSpace="preserve">{estimate?.estimate?.notes}</Text>
          </SkeletonWrapper>
        </ComposedCard>
      </Stack>

      <Stack flex={1} spacing="6">
        <ComposedCard
          actions={
            claimDetailDocuments && (
              <LinkButton
                rightIcon={<ExternalLinkIcon />}
                size="sm"
                target={`${claimNumber}_documents`}
                to={claimDetailDocuments(claimNumber)}
                variant="outline"
              >
                View Full Table
              </LinkButton>
            )
          }
          heading="Claim Documents"
        >
          <DocumentsTable claimNumber={claimNumber} />
        </ComposedCard>

        <ComposedCard
          actions={
            <Actions
              ReviewEstimateDrawer={ReviewEstimateDrawer}
              claimNumber={claimNumber}
              estimateId={estimateId}
            />
          }
          heading="Reviews"
        >
          <SkeletonWrapper h="96" isLoaded={!isPending}>
            {!estimate?.reviews?.length ? (
              <ComposedAlert alert="Estimate has not been reviewed" status="warning" />
            ) : (
              <Stack spacing="4">
                {estimate.reviews.map((review, index) => (
                  <Review key={index} review={review} />
                ))}
              </Stack>
            )}
          </SkeletonWrapper>
        </ComposedCard>
      </Stack>
    </HStack>
  )
}

function Actions({ claimNumber, estimateId, ReviewEstimateDrawer }: ClaimEstimateDetailProps) {
  const { data: claim } = useGetClaim<DelegateAdminClaim | EntityAdminClaim>(claimNumber)

  const reviewDrawer = useDisclosure()

  if (claim?.isReadOnly) {
    return null
  }
  return (
    <ButtonGroup size="sm">
      {!!ReviewEstimateDrawer && (
        <>
          <Button onClick={reviewDrawer.onOpen}>Add Review</Button>
          {reviewDrawer.isOpen && (
            <ReviewEstimateDrawer
              {...reviewDrawer}
              claimNumber={claimNumber}
              estimateId={estimateId}
            />
          )}
        </>
      )}
    </ButtonGroup>
  )
}

function Review({ review }: { review: EstimateReview }) {
  const { PreferredDateTimeComponent } = useDatesContext()

  const { data: reviewerContact } = useGetContact(review.reviewerContact ?? '')

  const badgeColorScheme = exhaustive(review.outcome, {
    approved: () => 'green',
    rejected: () => 'red',
    withExceptions: () => 'yellow',
    _: () => 'primary',
  })

  return (
    <ComposedCard
      actions={
        <PreferredDateTimeComponent property="reviewedOn">
          {review.reviewedOn}
        </PreferredDateTimeComponent>
      }
      boxShadow="none"
      heading={
        <HStack spacing="1">
          <Badge colorScheme={badgeColorScheme}>{startCase(review.outcome)}</Badge>
          <Text as="span">by</Text>
          {reviewerContact ? (
            <Text as="span">
              {getContactName(reviewerContact)} ({startCase(review.reviewerRole)})
            </Text>
          ) : (
            <Text as="span">{startCase(review.reviewerRole)}</Text>
          )}
        </HStack>
      }
      variant="outline"
    >
      {review.additionalInformation || '(No additional information)'}
    </ComposedCard>
  )
}

function DocumentsTable({ claimNumber }: { claimNumber: string }) {
  const columns = useDocumentsColumns()

  const claimCorn = ensureClaimCorn(claimNumber)

  const { cohort } = useCohortContext()
  const {
    data: documents,
    isPending,
    isFetching,
    isError,
  } = useGetEntityDocuments({ cohort, entityId: claimCorn })

  const groupedDocuments = useOrganizeDocuments(documents)

  return (
    <DataGrid
      columns={columns}
      data={groupedDocuments}
      isError={isError}
      isFetching={isFetching}
      isPending={isPending}
    />
  )
}

function useDocumentsColumns() {
  const sharedColumns = useSharedDocumentsColumns()

  return useMemo(
    () => [
      sharedColumns.title,
      sharedColumns.description,
      sharedColumns.filename,
      sharedColumns.tags,
    ],
    [sharedColumns.description, sharedColumns.filename, sharedColumns.tags, sharedColumns.title]
  )
}
