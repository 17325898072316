import { ComposedModal, ComposedModalWrapperProps } from '@eigtech/ui-shared-dave'
import { MediaCarousel, MediaCarouselProps } from './MediaCarousel'

export type MediaCarouselModalProps = ComposedModalWrapperProps & MediaCarouselProps

export function MediaCarouselModal({ documents, ...modalProps }: MediaCarouselModalProps) {
  return (
    <ComposedModal
      bodyProps={{ minH: '0', maxH: 'full', display: 'flex', flexDir: 'column' }}
      contentProps={{
        maxH: 'calc(100vh - (var(--chakra-space-16) * 2))',
        overflow: 'hidden',
      }}
      size="6xl"
      stackProps={{
        minH: '0',
        maxH: 'full',
        overflow: 'hidden',
      }}
      {...modalProps}
      title="Media"
    >
      <MediaCarousel documents={documents} />
    </ComposedModal>
  )
}
