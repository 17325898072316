import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const StartCaptureQueryRequestSchema = z.object({
  m: z.string(),
})

export const StartCaptureRequestSchema = StartCaptureQueryRequestSchema

export type StartCaptureRequest = z.infer<typeof StartCaptureRequestSchema>
export type StartCaptureResponse = void

export type StartCaptureHandler = Handler<StartCaptureRequest, StartCaptureResponse>
