import { CornSchema } from '@eigtech/shared-corn'
import z from 'zod'

export const MeetingConcatenationSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
  meetingStartTime: z.string(),
  meetingEndTime: z.string().optional(),
  mediaConcatenationPipeline: z.record(z.string(), z.unknown()),
})

/**
 * @param mediaConcatenationPipeline @eigtech/aws-sdk/chime-sdk-media-pipelines -> MediaConcatenationPipeline
 */
export type MeetingConcatenation = z.infer<typeof MeetingConcatenationSchema>
