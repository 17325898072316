import { forwardRef, Heading, HStack, StackProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { PageToolbar } from './PageToolbar'

export type PageHeaderBaseProps = { toolbar?: ReactNode; toolbarItems?: ReactNode[] }

export type PageHeaderProps = StackProps & PageHeaderBaseProps

export const PageHeader = forwardRef<PageHeaderProps, 'div'>(
  ({ children, toolbar, toolbarItems, ...props }, ref) => (
    <HStack ref={ref} gap="6" justifyContent="space-between" {...props}>
      {typeof children === 'string' ? (
        <Heading as="h1" size="lg">
          {children}
        </Heading>
      ) : (
        children
      )}

      {!!toolbar ? toolbar : !!toolbarItems?.length && <PageToolbar toolbarItems={toolbarItems} />}
    </HStack>
  )
)
