import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'
import { JobLocationSchema } from '../../base/jobLocation.js'

export const UpdateJobLocationRequestBodySchema = JobLocationSchema
export const UpdateJobLocationRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const UpdateJobLocationRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const UpdateJobLocationRequestSchema = UpdateJobLocationRequestBodySchema.merge(
  UpdateJobLocationRequestHeadersSchema
).merge(UpdateJobLocationRequestPathSchema)
export const UpdateJobLocationResponseSchema = z.object({
  jobId: JobIdSchema,
  jobLocation: JobLocationSchema,
})
export const UpdateJobLocationApiSchema: ApiSchema = {
  body: UpdateJobLocationRequestBodySchema,
  headers: UpdateJobLocationRequestHeadersSchema,
  params: UpdateJobLocationRequestPathSchema,
  response: UpdateJobLocationResponseSchema,
}

export type UpdateJobLocationRequestBody = z.infer<typeof UpdateJobLocationRequestBodySchema>
export interface UpdateJobLocationRequest extends z.infer<typeof UpdateJobLocationRequestSchema> {}
export type UpdateJobLocationResponse = z.infer<typeof UpdateJobLocationResponseSchema>
export interface UpdateJobLocationHandler
  extends Handler<UpdateJobLocationRequest, UpdateJobLocationResponse> {}
