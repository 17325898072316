import { LossReportEntityId } from '@eigtech/riddler-types'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'

export const riddlerBasePath = 'riddler'

const lossReportBaseQueryKeys = makeBasicQueryKeys('loss-report')

export const lossReportQueryKeys = {
  ...lossReportBaseQueryKeys,
  latest: (claimNumber: LossReportEntityId) => [
    ...lossReportQueryKeys.detail(claimNumber),
    'latest',
  ],
}
