import { createIcon, chakra } from '@chakra-ui/react'

export const XaGlobeIcon = createIcon({
  displayName: 'XaGlobeIcon',
  viewBox: '0 0 1200 1200',
  path: (
    <chakra.g fill="#0d749c" stroke="none" transform="translate(0, 100%) scale(1, -1)">
      <chakra.path
        d="M525 1190 c-57 -9 -139 -30 -134 -35 2 -1 45 1 94 6 81 8 202 6 295
-6 63 -8 -5 15 -90 31 -83 15 -92 15 -165 4z"
      />
      <chakra.path
        d="M373 1125 c-68 -10 -91 -19 -132 -50 -28 -20 -51 -38 -51 -40 0 -1
30 4 67 11 168 36 511 36 681 0 l62 -13 -21 24 c-36 37 -98 61 -189 73 -111
13 -314 11 -417 -5z"
      />
      <chakra.path
        d="M415 1039 c-210 -17 -269 -37 -306 -103 -10 -16 -8 -18 12 -13 103
26 197 32 484 32 258 0 325 -3 399 -18 48 -10 90 -16 92 -14 2 2 -11 20 -28
40 -28 32 -44 39 -106 52 -80 16 -306 36 -392 34 -30 -1 -100 -5 -155 -10z"
      />
      <chakra.path
        d="M260 915 c-160 -19 -189 -29 -206 -72 -8 -19 -13 -37 -11 -39 2 -2
28 0 58 6 80 14 307 30 441 30 l117 0 24 33 c13 17 27 38 31 45 10 16 -309 15
-454 -3z"
      />
      <chakra.path
        d="M205 790 c-109 -9 -174 -20 -179 -28 -2 -4 -7 -23 -11 -42 l-6 -35
103 6 c57 4 182 7 279 8 l177 1 16 25 c9 14 23 36 32 50 l16 25 -173 -1 c-96
-1 -210 -5 -254 -9z"
      />
      <chakra.path
        d="M830 753 l-31 -48 153 -7 c84 -3 172 -9 196 -11 35 -4 42 -2 38 10
-3 8 -6 22 -6 31 0 9 -4 22 -8 29 -7 11 -97 25 -239 38 l-73 6 -30 -48z"
      />
      <chakra.path
        d="M220 654 c0 -3 12 -25 26 -50 l26 -44 101 0 102 0 33 50 32 50 -160
0 c-88 0 -160 -3 -160 -6z"
      />
      <chakra.path
        d="M741 617 c-17 -25 -31 -49 -31 -52 0 -4 106 -5 235 -3 196 2 237 6
245 18 9 14 5 58 -6 70 -3 2 -97 6 -208 8 l-203 4 -32 -45z"
      />
      <chakra.path
        d="M10 494 c5 -19 10 -37 12 -40 10 -9 128 -20 128 -12 0 5 -10 27 -22
48 -22 38 -25 40 -74 40 l-52 0 8 -36z"
      />
      <chakra.path
        d="M835 522 l-160 -3 -34 -51 -33 -51 208 6 c177 6 352 20 359 29 1 2 5
20 9 41 l7 37 -98 -2 c-54 -1 -170 -4 -258 -6z"
      />
      <chakra.path
        d="M314 495 c9 -14 23 -36 32 -50 9 -14 21 -25 28 -25 10 0 66 79 66 94
0 3 -32 6 -71 6 l-71 0 16 -25z"
      />
      <chakra.path
        d="M30 417 c0 -17 33 -79 45 -83 19 -8 159 -32 163 -28 2 1 -10 23 -27
47 -28 42 -34 45 -88 51 -32 4 -66 9 -75 12 -10 3 -18 3 -18 1z"
      />
      <chakra.path
        d="M1105 408 c-72 -10 -345 -28 -440 -28 l-81 0 -32 -45 -32 -45 153 0
c149 0 269 10 396 31 59 11 64 14 78 46 8 19 13 38 11 42 -2 4 -25 3 -53 -1z"
      />
      <chakra.path
        d="M90 294 c0 -4 10 -23 23 -41 19 -28 32 -35 92 -47 90 -19 125 -24
125 -19 0 2 -14 21 -31 42 -28 35 -36 39 -107 51 -41 7 -82 14 -89 17 -7 3
-13 2 -13 -3z"
      />
      <chakra.path
        d="M1078 295 c-13 -13 -232 -36 -401 -42 -188 -6 -188 -6 -206 -32 -40
-58 -51 -54 170 -48 206 5 375 26 414 52 29 18 61 75 42 75 -7 0 -16 -2 -19
-5z"
      />
      <chakra.path
        d="M194 161 c34 -38 79 -58 156 -72 99 -17 382 -18 491 -1 77 12 92 18
135 54 l49 41 -35 -7 c-109 -22 -265 -36 -410 -36 -119 0 -170 -4 -179 -12
-10 -11 -15 -10 -25 4 -8 11 -38 21 -82 28 -38 6 -80 14 -94 17 -25 6 -25 6
-6 -16z"
      />
      <chakra.path
        d="M790 58 c-30 -5 -140 -8 -244 -6 -169 3 -186 2 -156 -10 128 -52 305
-49 435 9 45 20 42 21 -35 7z"
      />
    </chakra.g>
  ),
})
