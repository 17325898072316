import { Contact } from '@eigtech/contacts-types'
import { Stack, Tag, Text, Wrap, WrapItem } from '@eigtech/ui-shared-dave'
import { FieldValues, ReactSelectField, ReactSelectFieldProps } from '@eigtech/ui-shared-forms'
import { GroupBase, SelectComponentsConfig, chakraComponents } from 'chakra-react-select'
import { getContactName, getContactRoleLabel } from '../../utils'

export type ContactSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  IsMulti extends boolean = false,
> = ReactSelectFieldProps<TFieldValues, Contact, IsMulti> & { contacts: Contact[] }

export function ContactSelectField<
  TFieldValues extends FieldValues = FieldValues,
  IsMulti extends boolean = false,
>({ contacts, ...props }: ContactSelectFieldProps<TFieldValues, IsMulti>) {
  return (
    <ReactSelectField
      components={selectComponents<IsMulti>()}
      getOptionLabel={(contact) => getContactName(contact)}
      getOptionValue={(contact) => contact.contactId}
      options={contacts}
      {...props}
    />
  )
}

const selectComponents = <IsMulti extends boolean = false>(): SelectComponentsConfig<
  Contact,
  IsMulti,
  GroupBase<Contact>
> => ({
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      <Stack spacing="0.5">
        <Text as="span">{children}</Text>
        <Wrap>
          {props.data.roles.map((role) => (
            <WrapItem key={role}>
              <Tag>{getContactRoleLabel(role)}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Stack>
    </chakraComponents.Option>
  ),
})
