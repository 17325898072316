import { CohortEnumSchema } from '@eigtech/auth0-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

/** Add cohort to note */
export const AddCohortToNoteBodyRequestSchema = z.object({
  cohort: CohortEnumSchema,
})

export const AddCohortToNotePathRequestSchema = z.object({
  noteId: z.string(),
})

export const AddCohortToNoteRequestSchema = AddCohortToNoteBodyRequestSchema.merge(
  AddCohortToNotePathRequestSchema
)

export type AddCohortToNoteBodyRequest = z.infer<typeof AddCohortToNoteBodyRequestSchema>
export type AddCohortToNotePathRequest = z.infer<typeof AddCohortToNotePathRequestSchema>
export type AddCohortToNoteRequest = z.infer<typeof AddCohortToNoteRequestSchema>
export type AddCohortToNoteResponse = void
export type AddCohortToNoteHandler = Handler<AddCohortToNoteRequest, AddCohortToNoteResponse>
