import { ProducerClaimStatusEventNameEnumSchema } from '@eigtech/claims-types'
import {
  DefaultTimelineTemplateSchema,
  makeTimelineInstructionTemplateSchema,
} from '@eigtech/templates-types'
import z from 'zod'
import { timelineServiceCorn } from '../base/service.js'
import { StatusReduction, StatusReductionSchema } from '../base/statusReduction.js'
import { TimelinePointSchema, TimelineSchema } from '../base/timeline.js'

// Timeline Type
export const DefaultClaimStageTimelineTypeSchema = z.literal('defaultClaimStage')

// Timeline Corn
export const DefaultClaimStageTimelineCornSchema = timelineServiceCorn(
  DefaultClaimStageTimelineTypeSchema.value
)

// Timeline Points
export const DefaultClaimStageTimelinePointTypeSchema = z.enum([
  'coordinator',
  'rejected',
  'inspection',
  'qa',
  'carrier',
  'closed',
])

export const DefaultClaimStageTimelinePointSchema = TimelinePointSchema.merge(
  z.object({
    type: DefaultClaimStageTimelinePointTypeSchema,
  })
)

// Timeline
export const DefaultClaimStageTimelineSchema = TimelineSchema.merge(
  z.object({
    id: DefaultClaimStageTimelineCornSchema,
    type: DefaultClaimStageTimelineTypeSchema,
    points: z.array(TimelinePointSchema),
  })
)

// Timeline Template
type DefaultClaimStageTimelinePoints = typeof DefaultClaimStageTimelinePointTypeSchema.options
type DefaultClaimStageTimelineEvents = typeof ProducerClaimStatusEventNameEnumSchema.options

export const DefaultClaimStageTimelineInstructionsTemplateSchema =
  makeTimelineInstructionTemplateSchema<
    DefaultClaimStageTimelineEvents,
    DefaultClaimStageTimelinePoints
  >(ProducerClaimStatusEventNameEnumSchema, DefaultClaimStageTimelinePointTypeSchema)

export const DefaultClaimStageTimelineTemplateSchema = DefaultTimelineTemplateSchema.merge(
  z.object({
    instructionTemplate: DefaultClaimStageTimelineInstructionsTemplateSchema,
    timelineType: DefaultClaimStageTimelineTypeSchema,
  })
)

// Status Reduction
export const DefaultClaimStageReductionSchema = StatusReductionSchema.merge(
  z.object({
    type: DefaultClaimStageTimelineTypeSchema,
    timeline: DefaultClaimStageTimelineSchema,
  })
)

export const isDefaultClaimStageReduction = (
  reduction: StatusReduction
): reduction is DefaultClaimStageReduction =>
  DefaultClaimStageReductionSchema.safeParse(reduction).success

// Types
export type DefaultClaimStageReduction = z.infer<typeof DefaultClaimStageReductionSchema>
export type DefaultClaimStageTimeline = z.infer<typeof DefaultClaimStageTimelineSchema>
export type DefaultClaimStageTimelineCorn = z.infer<typeof DefaultClaimStageTimelineCornSchema>
export type DefaultClaimStageTimelineInstructionsTemplate = z.infer<
  typeof DefaultClaimStageTimelineInstructionsTemplateSchema
>
export type DefaultClaimStageTimelinePoint = z.infer<typeof DefaultClaimStageTimelinePointSchema>
export type DefaultClaimStageTimelinePointType = z.infer<
  typeof DefaultClaimStageTimelinePointTypeSchema
>
export type DefaultClaimStageTimelineTemplate = z.infer<
  typeof DefaultClaimStageTimelineTemplateSchema
>
export type DefaultClaimStageTimelineType = z.infer<typeof DefaultClaimStageTimelineTypeSchema>
