import { ClaimDefaultChronicleTypeSchema } from '@eigtech/summarizer-types'
import { getClaimTimelineEventLabel } from '@eigtech/ui-shared-claims'
import { zodEnumWithUserFriendlyError } from '@eigtech/ui-shared-forms'
import { z } from 'zod'

export const AvailableEventWithMaxFutureDateSchema = zodEnumWithUserFriendlyError(
  [ClaimDefaultChronicleTypeSchema.Enum.inspectionScheduled],
  { optionFormatter: getClaimTimelineEventLabel }
)

export const AvailableEventWithMaxTodayDateSchema = zodEnumWithUserFriendlyError(
  [
    ClaimDefaultChronicleTypeSchema.Enum.initialCustomerContactSuccess,
    ClaimDefaultChronicleTypeSchema.Enum.customerContactedLvm,
    ClaimDefaultChronicleTypeSchema.Enum.customerContactedEmail,
    ClaimDefaultChronicleTypeSchema.Enum.customerNoContact,
    ClaimDefaultChronicleTypeSchema.Enum.invalidContactInformation,
    ClaimDefaultChronicleTypeSchema.Enum.inspectionCompleted,
    ClaimDefaultChronicleTypeSchema.Enum.jobCompleted,
    ClaimDefaultChronicleTypeSchema.Enum.jobNotSold,
    ClaimDefaultChronicleTypeSchema.Enum.jobSold,
    ClaimDefaultChronicleTypeSchema.Enum.jobStarted,
    ClaimDefaultChronicleTypeSchema.Enum.qaApproved,
    ClaimDefaultChronicleTypeSchema.Enum.qaApprovedPreliminaryReport,
    ClaimDefaultChronicleTypeSchema.Enum.qaApprovedSupplementReport,
    ClaimDefaultChronicleTypeSchema.Enum.qaRejected,
    ClaimDefaultChronicleTypeSchema.Enum.reInspectionRequested,
  ],
  { optionFormatter: getClaimTimelineEventLabel }
)

export const AvailableEventSchema = z.enum([
  ...AvailableEventWithMaxTodayDateSchema.options,
  ...AvailableEventWithMaxFutureDateSchema.options,
])

export const eventsWithFutureDate: string[] = AvailableEventWithMaxFutureDateSchema.options

export type AvailableEvent = z.infer<typeof AvailableEventSchema>
export type AvailableEventWithMaxFutureDate = z.infer<typeof AvailableEventWithMaxFutureDateSchema>
