import { createMongoAbility } from '@casl/ability'
import { createContextualCan, useAbility as useBaseAbility } from '@casl/react'
import { createContext, useContext } from 'react'

export const ability = createMongoAbility()

export const AbilityContext = createContext(ability)

export const useAbilityContext = () => useContext(AbilityContext)

export const useAbility = () => useBaseAbility(AbilityContext)

export const Can = createContextualCan(AbilityContext.Consumer)
