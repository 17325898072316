// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/foundations/colors.ts

import { theme as baseTheme } from '@chakra-ui/react'

// colors generated from:
// https://maketintsandshades.com/

export const colors = {
  brandPrimary: {
    50: '#E7EEF0',
    100: '#CEDDE0',
    200: '#9ebbc2',
    300: '#6d98a3',
    400: '#3d7685',
    500: '#0C5466',
    600: '#0B4C5C',
    700: '#0A4352',
    800: '#083B47',
    900: '#07323D',
    1000: '#062A33',
  },
  brandSecondary: {
    50: '#E9F6F4',
    100: '#BCE4DF',
    200: '#8FD2CA',
    300: '#62BFB4',
    400: '#35AD9F',
    500: '#1FA494',
    600: '#1C9485',
    700: '#198376',
    800: '#167368',
    900: '#136259',
    1000: '#10524A',
  },
  brandCoolGrey: '#EFEDED',
  brandBlue: '#2A2163',
  brandCharcoal: '#45474A',
  danger: baseTheme.colors.red,
  info: baseTheme.colors.cyan,
  success: baseTheme.colors.green,
  warning: baseTheme.colors.yellow,
}
