import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { EstimatorClaimDetailsSchema } from '../../base/claim'

export const GetEstimatorClaimPathRequestSchema = z.object({
  claimNumber: z.string(),
})
export type GetEstimatorClaimPathRequest = z.infer<typeof GetEstimatorClaimPathRequestSchema>

export const GetEstimatorClaimRequestSchema = GetEstimatorClaimPathRequestSchema

export type GetEstimatorClaimRequest = z.infer<typeof GetEstimatorClaimRequestSchema>
export const GetEstimatorClaimResponseSchema = EstimatorClaimDetailsSchema
export type GetEstimatorClaimResponse = z.infer<typeof GetEstimatorClaimResponseSchema>

export interface GetEstimatorClaimHandler
  extends Handler<GetEstimatorClaimRequest, GetEstimatorClaimResponse> {}

export const GetEstimatorClaimApiSchema = {
  params: GetEstimatorClaimPathRequestSchema,
  response: GetEstimatorClaimResponseSchema,
} satisfies ApiSchema
