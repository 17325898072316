import { minutesToMilliseconds } from '@eigtech/ui-shared-dates'
import { asyncForEach, wait } from '@eigtech/ui-shared-dave'
import { Sentry } from '@eigtech/ui-shared-sentry'
import {
  DefaultError,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import ky from 'ky'
import { chunk } from 'lodash-es'
import { useState } from 'react'

const apiInstance = ky.create({
  timeout: minutesToMilliseconds(10),
  hooks: {
    afterResponse: [
      async (__input, __options, response) => {
        if (response.status >= 400) {
          const data = await response.text()

          Sentry.addBreadcrumb({
            type: 'error',
            category: 'error',
            level: 'error',
            message: response.statusText,
            data: {
              response: data,
            },
          })
        }
      },
    ],
  },
})

export type GetUploadFileUrlResponse = {
  fields: Record<string, string>
  url: string
}

export type UseGetUploadFileUrlProps<TQueryKey extends QueryKey = QueryKey> = UseQueryOptions<
  GetUploadFileUrlResponse,
  DefaultError,
  GetUploadFileUrlResponse,
  TQueryKey
>

export function useGetUploadFileUrl<TQueryKey extends QueryKey = QueryKey>(
  queryProps: UseGetUploadFileUrlProps<TQueryKey>
) {
  return useQuery({
    staleTime: 25 * 60 * 1000,
    ...queryProps,
  })
}
export type FileUpload = {
  file: File
  meta?: Record<string, string>
  'Content-Type'?: string
}

export type UploadFileProps = {
  url: string
  fields: Record<string, string>
} & FileUpload

function uploadFile({ url, file, fields, meta = {}, ...other }: UploadFileProps) {
  const formData = new FormData()

  // The fields must be included in the request.
  // Since we can't omit them, '' (an empty string) has been designated as "fallback to default"
  // on the server side.
  // I'm also not totally sure what kinds of restrictions exist on these fields in terms of character count limit, encoding, etc.
  // We'll want to make sure that whatever limits do exist, we enforce them on the UI side.

  const normalizedMeta = Object.entries(meta).map(([key, value]) => [`x-amz-meta-${key}`, value])
  const allFields = [...normalizedMeta, ...Object.entries(other), ...Object.entries(fields)]

  allFields.forEach(([key, value]) => {
    formData.append(key, value)
  })

  formData.append('file', file)

  return apiInstance.post(url, { body: formData, timeout: 10 * 60 * 1000 })
}

export function useUploadFile<TQueryKey extends QueryKey = QueryKey>(
  props: UseGetUploadFileUrlProps<TQueryKey>,
  options: UseMutationOptions<any, unknown, FileUpload, unknown> & {
    defaultMeta?: Record<string, string>
  } = {}
) {
  const { data, isPending, isError, isFetching } = useGetUploadFileUrl(props)

  const mutation = useMutation({
    ...options,
    mutationFn: ({ meta, ...file }: FileUpload) => {
      if (!data) {
        throw new Error('Url & field missing')
      }

      return uploadFile({
        ...data,
        ...file,
        meta: { ...(options.defaultMeta ?? {}), ...(meta ?? {}) },
      })
    },
  })

  return {
    ...mutation,
    isPendingUploadUrl: isPending,
    isUploadUrlError: isError,
    isFetchingUploadUrl: isFetching,
  }
}

export function useUploadMultipleFiles<TQueryKey extends QueryKey = QueryKey>(
  props: UseGetUploadFileUrlProps<TQueryKey>,
  options: UseMutationOptions<any, unknown, FileUpload[], unknown> & {
    defaultMeta?: Record<string, string>
  } = {}
) {
  const { data, isPending, isError, isFetching } = useGetUploadFileUrl(props)

  const [filesUploaded, setFilesUploaded] = useState(0)
  const [numberOfFiles, setNumberOfFiles] = useState(0)

  const mutation = useMutation({
    ...options,
    mutationFn: async (files: FileUpload[]) => {
      if (!data) {
        throw new Error('Url & field missing')
      }

      setNumberOfFiles(files.length)

      const batches = chunk(files, 2)

      await asyncForEach(batches, async (batch) => {
        const promises = batch.map(({ meta, ...file }) =>
          uploadFile({
            ...data,
            ...file,
            meta: { ...(options.defaultMeta ?? {}), ...(meta ?? {}) },
          })
        )

        await Promise.all(promises)

        setFilesUploaded((current) => current + promises.length)

        await wait(100)
      })
    },
    onSettled() {
      setFilesUploaded(0)
      setNumberOfFiles(0)
    },
  })

  return {
    mutation,
    numberOfFiles,
    filesUploaded,
    isPendingUploadUrl: isPending,
    isUploadUrlError: isError,
    isFetchingUploadUrl: isFetching,
  }
}
