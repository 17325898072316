// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts

import { Styles } from '@chakra-ui/theme-tools'

export const styles: Styles = {
  global: {
    'html, body': { bg: 'gray.100' },
    body: {
      fontSize: 'md',
      fontWeight: 'normal',
      letterSpacing: 'normal',
    },
    a: {
      color: 'brandPrimary.500',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}
