import { ReactNode } from 'react'
import { mostReadable, TinyColor } from '@eigtech/ui-shared-color'
import { chakra, Stack, ThemeTypings } from '@eigtech/ui-shared-dave'

/*
  Over time there may be more useful event item components that can be included
  in the library
*/

export type DefaultMonthlyEventItemProps = {
  children?: ReactNode
  color?: ThemeTypings['colors']
  date: string
}

export const DefaultMonthlyEventItem = ({
  children,
  color = 'blue.100',
  date,
}: DefaultMonthlyEventItemProps) => {
  const bgColor = new TinyColor(color)

  const textColor = bgColor.isValid
    ? mostReadable(color as string, ['#fff', '#000'])?.toHexString()
    : undefined

  const dateTextColor = bgColor.isValid
    ? mostReadable(color as string, ['#CBD5E0', '#2D3748'])?.toHexString()
    : 'gray.500'

  return (
    <chakra.li bg={color} borderRadius="md" p="2">
      <Stack
        color={textColor}
        flex={1}
        fontSize="sm"
        justifyContent="space-between"
        lineHeight={1.2}
        spacing="1"
      >
        <chakra.h3 color={dateTextColor} flexShrink={0} fontWeight="semibold">
          {date}
        </chakra.h3>

        {typeof children === 'string' ? <chakra.p>{children}</chakra.p> : children}
      </Stack>
    </chakra.li>
  )
}
