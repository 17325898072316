import z from 'zod'
import { claimsServiceCorn } from '../base/service.js'

export const LossLocationCornSchema = claimsServiceCorn('lossLocation')

export const LossLocationCoordinateSchema = z.object({
  accuracy: z.enum([
    'ADDRESS',
    'STREET',
    'ZIP',
    'CITY',
    'STATE',
    'COUNTRY',
    'CITY BLOCK',
    'INTERSECTION',
    'ZIP7',
    'ZIP9',
  ]),
  latitude: z.number(),
  longitude: z.number(),
})

export const LossLocationStreetAddressSchema = z.object({
  streetOrMailingAddress: z.string(),
  streetName: z.optional(z.string()),
  dwellingNumber: z.optional(z.string()),
})

export const LossLocationSchema = z.object({
  id: z.string(),
  claimNumber: z.string(),
  streetAddress: LossLocationStreetAddressSchema,
  otherDesignation: z.optional(z.string()),
  city: z.string(),
  stateOrProvince: z.string(),
  zipOrPostalCode: z.string(),
  country: z.optional(z.string()),
  addressType: z.optional(z.string()), //Convert to enum https://hl7-definition.caristix.com/v2/HL7v2.5.1/Tables/0190
  otherGeographicDesignation: z.optional(z.string()),
  countyOrParishCode: z.optional(z.string()),
  censusTract: z.optional(z.string()),
  effectiveDate: z.optional(z.string()),
  expirationDate: z.optional(z.string()),
  location: z.optional(LossLocationCoordinateSchema),
  rawAddress: z.string(),
})

export type LossLocationStreetAddress = z.infer<typeof LossLocationStreetAddressSchema>
export type LossLocation = z.infer<typeof LossLocationSchema>
export type LossLocationCorn = z.infer<typeof LossLocationCornSchema>
export type LossLocationCoordinate = z.infer<typeof LossLocationCoordinateSchema>
