import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { WatchedAlertRuleSchema } from '../base.js'

export const DeleteWatchedRuleRequestQuerySchema = WatchedAlertRuleSchema.pick({ id: true })

export const DeleteWatchedRuleRequestSchema = DeleteWatchedRuleRequestQuerySchema

export const DeleteWatchedRuleResponseSchema = z.void()

export type DeleteWatchedRuleRequest = z.infer<typeof DeleteWatchedRuleRequestSchema>
export type DeleteWatchedRuleResponse = z.infer<typeof DeleteWatchedRuleResponseSchema>

export type DeleteWatchedRuleHandler = Handler<DeleteWatchedRuleRequest, DeleteWatchedRuleResponse>
