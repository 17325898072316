import { CohortEnumSchema } from '@eigtech/auth0-types'
import {
  DocumentPublicationAttributes,
  DocumentV2 as DocumentType,
  PublicationTargets,
  PublicationTargetsSchema,
  videoTypeRegex,
} from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { Corn } from '@eigtech/shared-corn'
import { getAvailableExportCohorts, getCohortLabel } from '@eigtech/ui-shared-cohorts'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  Stack,
  WithDrawerErrorBoundaryProps,
  pluralize,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'
import { ReactNode, useMemo } from 'react'
import { z } from 'zod'
import { useMarkDocumentForPublication } from '../../api/publishDocument'
import {
  ExternalDocumentPublicationTargets,
  ExternalDocumentPublicationTargetsSchema,
  getPublicationTargetLabel,
} from '../../utils/publicationTargets'

export type ExportDocumentDrawerBaseProps = {
  documents: DocumentType[] | (() => DocumentType[])
  additionalPublicationTargets?: { value: string; label: string | ReactNode }[]
  excludedPublicationTargets?: ExternalDocumentPublicationTargets[]
  documentType: 'document' | 'media'
  onSubmit?: () => void
}

export type ExportDocumentDrawerProps = ComposedDrawerWrapperProps & ExportDocumentDrawerBaseProps

export const ExportDocumentDrawer = withDrawerErrorBoundary(
  function ExportDocumentDrawer({
    additionalPublicationTargets = [],
    documents: propsDocuments,
    documentType: entityType,
    excludedPublicationTargets,
    onSubmit: onExport,
    ...drawerProps
  }: ExportDocumentDrawerProps) {
    const form = useForm({
      defaultValues: {
        publicationCohorts: [CohortEnumSchema.Enum.csr],
      },
    })

    const toast = useToast()
    const { mutateAsync: exportDocuments } = useMarkDocumentForPublication()

    const documents = useMemo(
      () => (Array.isArray(propsDocuments) ? propsDocuments : propsDocuments()),
      [propsDocuments]
    )

    const hasVideoFile = documents.some(({ filename }) => filename.match(videoTypeRegex))

    const numberOfSelectedRows = documents.length

    async function handleExport({ publicationTargets, publicationCohorts }: ExportForm) {
      const requests = publicationTargets
        .map((target) => {
          const publicationTarget = getPublicationTarget(target)

          return documents.map((document) =>
            exportDocuments({
              documentCorn: ensureDocumentCorn(document.id),
              entityId: document.entityId,
              publicationTargets: [publicationTarget],
              publicationAttributes: getPublicationAttributes(publicationTarget, {
                originalTarget: target as Corn,
                publicationTargets,
                publicationCohorts,
              }),
            })
          )
        })
        .flat()

      await Promise.all(requests)

      const label = pluralize(entityType, numberOfSelectedRows)

      toast({
        status: 'success',
        title: `Successfully marked selected ${label} for export`,
        description: `It will take a few minutes for selected ${label} to be fully exported and available in the selected target locations.`,
      })

      onExport?.()
      drawerProps.onClose()
    }

    const availablePublicationTargets = [
      ...ExternalDocumentPublicationTargetsSchema.options.map((target) => ({
        label: getPublicationTargetLabel(target),
        value: target,
      })),
      ...additionalPublicationTargets,
    ].filter(
      (target) =>
        !excludedPublicationTargets?.includes(target.value as ExternalDocumentPublicationTargets)
    )

    const targets = form.watch('publicationTargets')

    const isPublishingToDocuments =
      !!targets?.length && targets.some((value) => getPublicationTarget(value) === 'documents')
    const isPublishingToExternal =
      !!targets?.length &&
      targets.some((value) =>
        ExternalDocumentPublicationTargetsSchema.options.includes(
          getPublicationTarget(value) as ExternalDocumentPublicationTargets
        )
      )

    const availableExportCohorts = getAvailableExportCohorts(targets)

    return (
      <FormDrawer
        {...drawerProps}
        {...baseProps}
        form={form}
        title={`Export ${numberOfSelectedRows} ${startCase(
          pluralize(entityType, numberOfSelectedRows)
        )}`}
        onSubmit={handleExport}
      >
        <Stack spacing="8">
          <Stack>
            <ComposedAlert
              alert={{
                title: 'Publication Targets',
                description: `Where would you like the selected ${pluralize(
                  entityType,
                  numberOfSelectedRows
                )} to be exported to?`,
              }}
            />
            <CheckboxGroupField
              label="Publication Targets"
              name="publicationTargets"
              options={availablePublicationTargets ?? []}
            />
          </Stack>

          {isPublishingToExternal && hasVideoFile && (
            <ComposedAlert
              alert={{
                title: 'Video files not supported by external applications',
                description:
                  'Any video files selected will not be published to selected external applications (XactAnalysis, Symbility, etc.). All other selected file types will still be marked for publication.',
              }}
              status="warning"
            />
          )}

          {isPublishingToDocuments && (
            <Stack>
              <ComposedAlert
                alert={{
                  title: 'Group Visibility',
                  description: `You may select which groups of users you would like this document to be visible to. Every document will always be visible to users in the coordinator group.`,
                }}
              />
              <CheckboxGroupField
                label="Group Visibility"
                name="publicationCohorts"
                options={availableExportCohorts.map((value) => ({
                  label: getCohortLabel(value),
                  value,
                  checkboxProps: { isDisabled: value === CohortEnumSchema.Values.csr },
                }))}
              />
            </Stack>
          )}
        </Stack>
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps = { title: 'Export Documents' } satisfies WithDrawerErrorBoundaryProps

const getPublicationTarget = (target: string): PublicationTargets =>
  ExternalDocumentPublicationTargetsSchema.options.includes(
    target as ExternalDocumentPublicationTargets
  )
    ? (target as ExternalDocumentPublicationTargets)
    : PublicationTargetsSchema.Values.documents

const getPublicationAttributes = (
  target: PublicationTargets,
  { publicationCohorts, originalTarget }: ExportForm & { originalTarget: Corn }
) =>
  exhaustive(target, {
    documents: (): DocumentPublicationAttributes => ({
      entityId: originalTarget,
      additionalCohorts: [
        ...new Set([
          ...(publicationCohorts ?? []),
          CohortEnumSchema.Values.csr,
          CohortEnumSchema.Values.super,
        ]),
      ],
    }),
    Symbility: () => ({}),
    XactAnalysis: () => ({}),
    Xactimate: () => ({}),
    _: () => ({}),
  })

const ExportFormSchema = z.object({
  publicationTargets: z.string().array(),
  publicationCohorts: CohortEnumSchema.array().optional(),
})

type ExportForm = z.infer<typeof ExportFormSchema>

const { useForm, CheckboxGroupField } = createForm(ExportFormSchema, 'exportDocumentForm')
