import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

export type NewJobRecord = NewTableRow<typeof jobTable>
export type JobRecord = TableRow<typeof jobTable>

const jobTable = defineTable('job', {
  closed_reason: Schema.nullable(Schema.String),
  description: Schema.nullable(Schema.String),
  id: Schema.String,
  is_closed: Schema.Boolean,
  latest_timeline_event: Schema.String,
  latest_work_auth_status: Schema.nullable(Schema.String),
  number: Schema.Number,
  related_claim_number: Schema.nullable(Schema.String),
  type: Schema.String,
})
