import { DocumentCornSchema } from '@eigtech/documents-types'
import { eventType } from '@eigtech/event-stream-types'
import { NoteSchema } from '@eigtech/notes-types'
import z from 'zod'
import { XAImportAssignmentRequestSchema } from './assignment'
import { XAStatusEnum } from './status'

export const VediImportStateSchema = z.enum(['sent', 'failed', 'confirmed', 'pending'])
export const VediImportState = VediImportStateSchema.Enum
export type VediImportStateEnum = z.infer<typeof VediImportStateSchema>

export const VediImportTypeSchema = z.enum([
  'assignment',
  'assignRecipient',
  'document',
  'note',
  'status',
])
export const VediImportType = VediImportTypeSchema.Enum
export type VediImportTypeEnum = z.infer<typeof VediImportTypeSchema>

// Base import
export const VediImportSchema = z.object({
  claimNumber: z.string(),
  dateRecorded: z.string().datetime(),
  expiresAt: z.number().optional(),
  error: z.string().optional(),
  importState: VediImportStateSchema,
  importType: VediImportTypeSchema,
  lastUpdated: z.string().datetime(),
  originatingEvent: eventType().optional(),
  transactionId: z.string().optional(),
  user: z.string(),
})
export type VediImport = z.infer<typeof VediImportSchema>
export const isVediImport = (result: unknown): result is VediImport => {
  return VediImportSchema.safeParse(result).success
}

// Assignment import
export const VediAssignmentImportSchema = VediImportSchema.merge(
  XAImportAssignmentRequestSchema
).merge(
  z.object({
    importType: z.literal(VediImportType.assignment),
  })
)
export type VediAssignmentImport = z.infer<typeof VediAssignmentImportSchema>
export const isVediAssignmentImport = (result: unknown): result is VediAssignmentImport => {
  return VediAssignmentImportSchema.safeParse(result).success
}

// Assign Recipient import
export const VediAssignRecipientImportSchema = VediImportSchema.required({
  transactionId: true,
}).merge(z.object({ importType: z.literal(VediImportType.assignRecipient), xactnetId: z.string() }))
export type VediAssignRecipientImport = z.infer<typeof VediAssignRecipientImportSchema>
export const isVediAssignRecipientImport = (
  result: unknown
): result is VediAssignRecipientImport => {
  return VediAssignRecipientImportSchema.safeParse(result).success
}

// Document import
export const VediDocumentImportSchema = VediImportSchema.required({
  transactionId: true,
}).merge(
  z.object({
    description: z.string(),
    documentId: DocumentCornSchema,
    fileName: z.string(),
    importType: z.literal(VediImportType.document),
    mimeBoundary: z.string(),
    mimeType: z.string().optional(),
    preApprove: z.boolean(),
    s3Location: z.object({ bucket: z.string(), key: z.string() }),
    sendToXm8: z.boolean(),
    transactionId: z.string(),
  })
)
export type VediDocumentImport = z.infer<typeof VediDocumentImportSchema>
export const isVediDocumentImport = (result: unknown): result is VediDocumentImport => {
  return VediDocumentImportSchema.safeParse(result).success
}

// Note import
export const VediNoteImportSchema = VediImportSchema.required({ transactionId: true }).merge(
  z.object({
    importType: z.literal(VediImportType.note),
    note: NoteSchema,
    pages: z
      .object({
        pageContent: z.string(),
        pageError: z.string().optional(),
        pageNumber: z.number(),
        pageStatus: VediImportStateSchema,
      })
      .array(),
  })
)
export type VediNoteImport = z.infer<typeof VediNoteImportSchema>
export const isVediNoteImport = (result: unknown): result is VediNoteImport => {
  return VediNoteImportSchema.safeParse(result).success
}

// Status import
export const VediStatusImportSchema = VediImportSchema.required({ transactionId: true }).merge(
  z.object({
    importType: z.literal(VediImportType.status),
    note: z.string(),
    status: XAStatusEnum,
    timestamp: z.string().datetime(),
  })
)
export type VediStatusImport = z.infer<typeof VediStatusImportSchema>
export const isVediStatusImport = (result: unknown): result is VediStatusImport => {
  return VediStatusImportSchema.safeParse(result).success
}

export const VediImportFilterOptionsSchema = VediImportSchema.omit({ importState: true })
  .partial()
  .merge(
    z.object({
      importStates: VediImportStateSchema.array().optional(),
    })
  )
export type VediImportFilterOptions = z.infer<typeof VediImportFilterOptionsSchema>
