import {
  useGetAssignedClaims,
  useGetUserPreferences,
  usePutUserPreferences,
} from '@eigtech/ui-estimator/api'
import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'
import {
  ACTION_MENU_ID,
  DataGrid,
  FilterFnName,
  OnChangeFn,
  Table,
  TableState,
} from '@eigtech/ui-shared-data-grid'
import { formatStringAsDate } from '@eigtech/ui-shared-dates'
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  Stack,
  Wrap,
  WrapItem,
  chakra,
} from '@eigtech/ui-shared-dave'
import { formatPhoneNumber } from '@eigtech/ui-shared-phone'
import { isFunction, uniq } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'
import { AugmentedEstimatorClaimSummary, useClaimsColumns } from './claimsColumns'

export type ClaimQuickFilter = {
  title: string
  description?: string
  filters: { columnId: string; fnOption: FilterFnName; filterValue: string }[]
}

export type ClaimExcludedValue = {
  columnId: string
  description: string
  excludedValue: string
}

const claimQuickFilters: (ClaimQuickFilter & { id: `preset-${string}` })[] = [
  {
    id: 'preset-inspection-today',
    title: "Today's Inspections",
    description: 'View claims with inspections scheduled for today.',
    filters: [
      {
        columnId: 'inspectionScheduled',
        fnOption: 'dateEquals',
        filterValue: formatStringAsDate(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd'),
      },
    ],
  },
  {
    id: 'preset-inspection-unscheduled',
    title: 'Not Scheduled',
    description: 'View claims that are have not been scheduled yet.',
    filters: [
      {
        columnId: 'inspectionScheduled',
        fnOption: 'equals',
        filterValue: 'TBD',
      },
    ],
  },
]

const claimExcludedValues: ClaimExcludedValue[] = [
  {
    columnId: 'claimStatus',
    description: 'Hide claims in QA Review',
    excludedValue: 'QA Review',
  },
  {
    columnId: 'claimStatus',
    description: 'Hide claims in Carrier Review',
    excludedValue: 'Carrier Review',
  },
]

export function ClaimsTable() {
  const { data, isPending, isError, isFetching } = useGetAssignedClaims()

  const { data: userPreferences } = useGetUserPreferences()
  const { mutateAsync: updateUserPreferences } = usePutUserPreferences()

  const claims = useMemo(
    () =>
      (data ?? [])
        .map((claim): AugmentedEstimatorClaimSummary => {
          const phoneNumber = claim.primaryContact?.phone ?? ''
          const formattedPhoneNumber = !!phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber

          return {
            catCode: '',
            ...claim,
            lossLocation: {
              county: '',
              ...claim.lossLocation,
            },
            inspectionCompleted: claim.inspectionCompleted ?? 'TBD',
            inspectionScheduled: claim.inspectionScheduled ?? 'TBD',
            primaryContact: {
              ...claim.primaryContact,
              name: claim.primaryContact?.name ?? '',
              email: claim.primaryContact?.email ?? '',
              phone: formattedPhoneNumber,
            },
          }
        })
        .filter(
          (c) =>
            !userPreferences?.claimsColumnsExcludedValues?.claimStatus?.includes(
              c.claimStatus ?? ''
            )
        ),
    [data, userPreferences]
  )

  const [pageIndex, setPageIndex] = useState(0)

  const table = useRef<Table<AugmentedEstimatorClaimSummary>>(null)
  const { columns: claimsColumns, setInspectionScheduledFilterFn } = useClaimsColumns(table)

  const tableState = useMemo<
    Pick<
      TableState,
      'columnOrder' | 'columnPinning' | 'columnVisibility' | 'pagination' | 'sorting'
    > & { columnExcludedValues: Record<string, string[]> }
  >(
    () => ({
      columnExcludedValues: userPreferences?.claimsColumnsExcludedValues ?? {},
      columnOrder: userPreferences?.claimsColumnsOrder?.length
        ? userPreferences.claimsColumnsOrder
        : claimsColumns.map((column) => column.id as string),
      columnPinning: userPreferences?.claimsPinnedColumns ?? {},
      columnVisibility: userPreferences?.claimsColumns ?? {},
      pagination: {
        ...(userPreferences?.claimsPagination ?? { pageIndex: 0, pageSize: 10 }),
        pageIndex,
      },
      sorting: userPreferences?.claimsSorting ?? [],
    }),
    [claimsColumns, pageIndex, userPreferences]
  )

  const previousTableState = useRef(tableState)
  useEffect(() => {
    previousTableState.current = tableState
  })

  const {
    setColumnOrder,
    setColumnPinning,
    setColumnVisibility,
    setColumnExcludedValues,
    setPagination,
    setSorting,
  } = useMemo(() => {
    const updater =
      <T,>(updateFn: (state: T) => void, previousState: () => T): OnChangeFn<T> =>
      (state) => {
        const newState = isFunction(state) ? state(previousState()) : state
        updateFn(newState)
      }

    return {
      setColumnExcludedValues: updater(
        (state) => updateUserPreferences({ claimsColumnsExcludedValues: state }),
        () => previousTableState.current.columnExcludedValues
      ),
      setColumnOrder: updater(
        (state) =>
          updateUserPreferences({
            claimsColumnsOrder: state,
          }),
        () => previousTableState.current.columnOrder
      ),
      setColumnPinning: updater(
        (state) =>
          updateUserPreferences({
            claimsPinnedColumns: {
              ...state,
              left: uniq([ACTION_MENU_ID, ...(state.left ?? [])]),
            },
          }),
        () => previousTableState.current.columnPinning
      ),
      setColumnVisibility: updater(
        (state) => updateUserPreferences({ claimsColumns: state }),
        () => previousTableState.current.columnVisibility
      ),
      setPagination: updater(
        (state) => {
          setPageIndex(state.pageIndex)
          updateUserPreferences({ claimsPagination: state })
        },
        () => previousTableState.current.pagination
      ),
      setSorting: updater(
        (state) => updateUserPreferences({ claimsSorting: state }),
        () => previousTableState.current.sorting
      ),
    }
  }, [updateUserPreferences])

  return (
    <Stack spacing="8">
      <Stack>
        <Heading as="h2" size="md">
          Preferences
        </Heading>
        <CheckboxGroup
          value={Object.entries(tableState.columnExcludedValues).flatMap(
            ([columnId, excludedValues]) => excludedValues.map((value) => `${columnId}|${value}`)
          )}
          onChange={(selections) => {
            const claimsColumnsExcludedValues = selections.reduce(
              (acc, val) => {
                const [columnId, excludedValue] = (val as string).split('|')
                return {
                  ...acc,
                  [columnId]: [...(acc[columnId] ?? []), excludedValue],
                }
              },
              {} as Record<string, string[]>
            )
            setColumnExcludedValues(claimsColumnsExcludedValues)
          }}
        >
          <Stack>
            {tableState.columnExcludedValues &&
              claimExcludedValues.map(({ columnId, description, excludedValue }) => (
                <Box key={`${columnId}-${excludedValue}`}>
                  <Checkbox value={`${columnId}|${excludedValue}`}>{description}</Checkbox>
                </Box>
              ))}
          </Stack>
        </CheckboxGroup>
      </Stack>

      <Stack>
        <Heading as="h2" size="md">
          Quick Filters
        </Heading>
        <Wrap spacing="4">
          {claimQuickFilters.map(({ id, title, description, filters }) => (
            <WrapItem key={id} position="relative">
              <Button
                alignItems="flex-start"
                display="flex"
                flexDirection="column"
                h="full"
                py="4"
                textAlign="left"
                variant="outline"
                w="full"
                whiteSpace="normal"
                onClick={() => {
                  table.current?.resetColumnFilters()

                  filters.forEach(({ columnId, filterValue, fnOption }) => {
                    if (columnId === 'inspectionScheduled') {
                      setInspectionScheduledFilterFn(fnOption)
                    }

                    const column = table.current?.getColumn(columnId)
                    column?.setFilterValue(filterValue)
                  })
                }}
              >
                <chakra.span>{title}</chakra.span>
                {!!description && <chakra.span fontWeight="normal">{description}</chakra.span>}
                <chakra.span fontSize="sm" fontWeight="normal" mt="2"></chakra.span>
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      </Stack>

      <DataGrid
        ref={table}
        autoResetPageIndex={false}
        columns={claimsColumns}
        data={claims}
        enablePinning
        enableReordering
        getRowId={getRowId}
        id={id}
        isError={isError}
        isFetching={isFetching}
        isPending={isPending}
        meta={meta}
        state={tableState}
        onColumnOrderChange={setColumnOrder}
        onColumnPinningChange={setColumnPinning}
        onColumnVisibilityChange={setColumnVisibility}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
      />
    </Stack>
  )
}

const meta = {
  rowProps: {
    fontSize: '15px',
    sx: {
      '&:last-of-type': {
        td: {
          borderColor: 'gray.200',
        },
      },
      '&:hover': {
        td: {
          background: 'gray.50',
          borderColor: 'gray.200',
        },
        '+ tr': {
          td: {
            borderTopColor: 'gray.200',
          },
        },
      },
    },
  },
}

const id = 'claimTable'

const getRowId = (row: DelegateAdminClaimSummary) => row.claimNumber
