interface Term {
  expirationDate?: string
  effectiveStartDate?: string
}

const expirationIsDefined = ({ expirationDate }: Term) => !!expirationDate
const isExpired = ({ expirationDate }: Term) =>
  expirationDate && expirationDate < new Date().toISOString()
const effectiveStartIsDefined = ({ effectiveStartDate }: Term) => !!effectiveStartDate
const isPremature = ({ effectiveStartDate }: Term) =>
  effectiveStartDate && effectiveStartDate > new Date().toISOString()

export const isInEffect = (term: Term) => {
  let inEffect = true
  if (expirationIsDefined(term)) inEffect = !isExpired(term)
  if (effectiveStartIsDefined(term)) inEffect = inEffect && !isPremature(term)
  return inEffect
}
