import z from 'zod'

export const Auth0ZoneTypeSchema = z.enum(['csr', 'estimator', 'tplap', 'external-estimator'])
export const auth0ZoneType = Auth0ZoneTypeSchema.Values
export type Auth0ZoneTypeEnum = z.infer<typeof Auth0ZoneTypeSchema>

export const Auth0ZoneSchema = z.object({
  domain: z.string(),
  connection: z.string(),
  clientId: z.string(),
  orgId: z.string(),
  type: Auth0ZoneTypeSchema,
  createPassword: z.boolean(),
  sendWelcomeEmail: z.boolean(),
  sendInviteEmail: z.boolean(),
})
export type Auth0Zone = z.infer<typeof Auth0ZoneSchema>
