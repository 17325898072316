import {
  GetMeetingRequest,
  GetMeetingUploadUrlRequest,
  GetMeetingVideoShareLinkRequestPath,
  GetScreenshotLabelsRequest,
  GetScreenshotUrlRequest,
  ListMeetingsRequest,
} from '@eigtech/meetings-types'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'
import { pick } from 'lodash-es'

export const meetingsBasePath = 'meetings'

const { all } = makeBasicQueryKeys('meetings')

export const meetingsQueryKeys = {
  all,

  list: (request: ListMeetingsRequest) => [
    ...meetingsQueryKeys.all(),
    pick(request, ['entityId']),
    'list',
  ],

  details: (request: ListMeetingsRequest) => [
    ...meetingsQueryKeys.all(),
    pick(request, ['entityId']),
    'details',
  ],
  detail: (request: QueryKeyMeetingDetail) => [
    ...meetingsQueryKeys.details({ entityId: request.entityId }),
    pick(request, ['meetingId']),
  ],
  video: (request: QueryKeyMeetingDetail) => [...meetingsQueryKeys.detail(request), 'video'],
  transcript: (request: QueryKeyMeetingDetail) => [
    ...meetingsQueryKeys.detail(request),
    'transcript',
  ],
  shareUrl: (request: GetMeetingVideoShareLinkRequestPath) => [
    ...meetingsQueryKeys.detail(request),
    'shareUrl',
  ],

  uploads: (request: QueryKeyMeetingDetail) => [...meetingsQueryKeys.detail(request), 'uploads'],
  uploadUrl: (request: QueryKeyMeetingDetail) => [
    ...meetingsQueryKeys.detail(request),
    'uploadUrl',
  ],

  screenshot: (request: GetScreenshotUrlRequest) => [
    ...meetingsQueryKeys.all(),
    'screenshots',
    request,
  ],

  screenshotLabels: (request: GetScreenshotLabelsRequest) => [
    ...meetingsQueryKeys.all(),
    'screenshotLabels',
    request,
  ],
}

export const meetingsMutationKeys = {
  all,

  uploadScreenshot: (request: QueryKeyMeetingDetail) => [
    ...meetingsMutationKeys.all(),
    'uploadScreenshot',
    request,
  ],
}

export const meetingsVideoUploadQueryKeys = {
  all,
  list: (request: QueryKeyMeetingVideoUpload) => [
    ...meetingsQueryKeys.all(),
    pick(request, ['entityId']),
    'list',
  ],
  details: (request: QueryKeyMeetingVideoUpload) => [
    ...meetingsQueryKeys.all(),
    pick(request, ['entityId']),
    'details',
  ],
  detail: (request: QueryKeyMeetingVideoUpload) => [
    ...meetingsVideoUploadQueryKeys.details({ entityId: request.entityId }),
  ],
  uploadUrl: (request: QueryKeyMeetingVideoUpload) => [
    ...meetingsVideoUploadQueryKeys.detail(request),
    'uploadUrl',
  ],
}

export type QueryKeyMeetingDetail = Pick<GetMeetingRequest, 'meetingId' | 'entityId'>
export type QueryKeyMeetingVideoUpload = Pick<GetMeetingUploadUrlRequest, 'entityId'>
