import {
  Box,
  ButtonGroup,
  forwardRef,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  StackProps,
  Text,
} from '@eigtech/ui-shared-dave'
import { debounce } from 'lodash-es'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { useDataGridContext } from './DataGridContext'

export type DataGridPaginationProps = StackProps

export const DataGridPagination = forwardRef<DataGridPaginationProps, 'div'>((props, ref) => {
  const { isFetching, table } = useDataGridContext()

  const pageCount = table.getPageCount()
  useEffect(() => {
    const currentPage = table.getState().pagination.pageIndex + 1

    if (pageCount < currentPage) {
      table.setPageIndex(pageCount - 1)
    }
  }, [pageCount, table])

  const handleInputChange = useMemo(
    () =>
      debounce((e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checkValidity()) return

        const page = e.target.value ? Number(e.target.value) - 1 : 0
        table.setPageIndex(page)
      }, 300),
    [table]
  )

  return (
    <HStack
      ref={ref}
      background="gray.100"
      borderRadius="lg"
      justifyContent="space-between"
      p="4"
      {...props}
    >
      <ButtonGroup spacing="2">
        <IconButton
          {...iconButtonProps}
          aria-label="Go to first page."
          icon={<Icon as={FiChevronsLeft} h="5" w="5" />}
          isDisabled={isFetching || !table.getCanPreviousPage()}
          onClick={() => table.setPageIndex(0)}
        />

        <IconButton
          {...iconButtonProps}
          aria-label="Go to previous page."
          icon={<Icon as={FiChevronLeft} h="5" w="5" />}
          isDisabled={isFetching || !table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        />

        <IconButton
          {...iconButtonProps}
          aria-label="Go to next page."
          icon={<Icon as={FiChevronRight} h="5" w="5" />}
          isDisabled={isFetching || !table.getCanNextPage()}
          onClick={() => table.nextPage()}
        />

        <IconButton
          {...iconButtonProps}
          aria-label="Go to last page."
          icon={<Icon as={FiChevronsRight} h="5" w="5" />}
          isDisabled={isFetching || !table.getCanNextPage()}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        />
      </ButtonGroup>

      <Box>
        <Text as="span">
          Page{' '}
          <strong>
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </strong>{' '}
        </Text>
        <Text as="span">
          | Go to page:{' '}
          <Input
            background="white"
            defaultValue={table.getState().pagination.pageIndex + 1}
            max={table.getPageCount()}
            min={0}
            pattern="[0-9]"
            step={1}
            sx={{
              ':invalid': {
                borderColor: 'red.500',
                boxShadow: `0 0 0 1px var(--chakra-colors-red-500)`,
              },
            }}
            type="number"
            width="100px"
            onChange={handleInputChange}
          />
        </Text>{' '}
      </Box>

      <Select
        background="white"
        value={table.getState().pagination.pageSize}
        width="auto"
        onChange={(e) => {
          table.setPageSize(Number(e.target.value))
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>
    </HStack>
  )
})

const iconButtonProps = {
  isRound: false,
}
