import {
  ButtonGroup,
  ButtonGroupProps,
  ExpandingIconButton,
  forwardRef,
  useBreakpointValue,
} from '@eigtech/ui-shared-dave'
import {
  MeetingStatus,
  useContentShareControls,
  useContentShareState,
  useLocalAudioOutput,
  useLocalVideo,
  useMeetingStatus,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react'
import { ReactNode } from 'react'
import {
  MdMic,
  MdMicOff,
  MdOutlineScreenShare,
  MdOutlineStopScreenShare,
  MdVideocam,
  MdVideocamOff,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md'
import { TbDoorExit } from 'react-icons/tb'
import { useLeaveMeeting } from '../../api'

export type FloatingMeetingControlsProps = {
  leftButtons?: ReactNode[]
  rightButtons?: ReactNode[]
} & ButtonGroupProps

export const FloatingMeetingControls = forwardRef<FloatingMeetingControlsProps, 'div'>(
  ({ leftButtons = [], rightButtons = [], ...props }, ref) => {
    const { mutateAsync: leaveMeeting, isPending: isLeaving } = useLeaveMeeting()

    const meetingStatusEnum = useMeetingStatus()
    const isInMeeting = meetingStatusEnum === MeetingStatus.Succeeded

    const { isVideoEnabled, toggleVideo } = useLocalVideo()
    const { muted, toggleMute } = useToggleLocalMute()
    const { isAudioOn, toggleAudio } = useLocalAudioOutput()
    const { toggleContentShare } = useContentShareControls()
    const { isLocalUserSharing } = useContentShareState()

    const sx = useBreakpointValue({
      base: {
        '& .expanding-icon-button': {
          flexGrow: 1,
          borderRadius: 'none',
          borderBottom: '0',
          '&:first-of-type': {
            borderLeft: '0',
          },
          '&:last-of-type': {
            borderRight: '0',
          },
        },
      },
      md: undefined,
    })

    if (!isInMeeting) return null

    return (
      <ButtonGroup
        ref={ref}
        bg="white"
        borderRadius={['none', 'md']}
        bottom={['0', '4']}
        id="floating-meeting-controls"
        isAttached
        isDisabled={isLeaving}
        left={['0', 'auto']}
        position="fixed"
        right={['0', '4']}
        shadow="md"
        sx={sx}
        variant="outline"
        {...props}
      >
        {leftButtons}

        <ExpandingIconButton icon={muted ? <MdMicOff /> : <MdMic />} onClick={toggleMute}>
          {muted ? 'Unmute' : 'Mute'}
        </ExpandingIconButton>

        <ExpandingIconButton
          icon={isAudioOn ? <MdVolumeUp /> : <MdVolumeOff />}
          onClick={toggleAudio}
        >
          {isAudioOn ? 'Speaker Off' : 'Speaker On'}
        </ExpandingIconButton>

        <ExpandingIconButton
          icon={isLocalUserSharing ? <MdOutlineScreenShare /> : <MdOutlineStopScreenShare />}
          onClick={() => toggleContentShare()}
        >
          {isLocalUserSharing ? 'Stop Sharing Screen' : 'Start Sharing Screen'}
        </ExpandingIconButton>

        <ExpandingIconButton
          icon={isVideoEnabled ? <MdVideocam /> : <MdVideocamOff />}
          onClick={toggleVideo}
        >
          {isVideoEnabled ? 'Stop Video' : 'Start Video'}
        </ExpandingIconButton>

        <ExpandingIconButton icon={<TbDoorExit />} onClick={() => leaveMeeting()}>
          Leave
        </ExpandingIconButton>

        {rightButtons}
      </ButtonGroup>
    )
  }
)
