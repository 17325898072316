import { forwardRef, Icon, IconProps } from '@eigtech/ui-shared-dave'
import { BiLinkExternal } from 'react-icons/bi'
import { FaInfo, FaMapMarkedAlt } from 'react-icons/fa'
import { IoDownload } from 'react-icons/io5'
import {
  MdAdd,
  MdCheck,
  MdClose,
  MdContentCopy,
  MdDelete,
  MdEdit,
  MdInfoOutline,
  MdLink,
  MdMoreHoriz,
} from 'react-icons/md'

export const AddIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdAdd} />
))

export const CheckIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdCheck} />
))

export const CloseIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdClose} />
))

export const CopyIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdContentCopy} />
))

export const DeleteIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdDelete} />
))

export const DownloadIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={IoDownload} />
))

export const EditIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdEdit} />
))

export const ExternalIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={BiLinkExternal} />
))

export const InfoIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={FaInfo} />
))

export const InfoOutlineIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdInfoOutline} />
))

export const LinkIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdLink} />
))

export const MapIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={FaMapMarkedAlt} />
))

export const MenuIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdMoreHoriz} />
))
