import { AssignBodyRequest, AssignRequest, AssignResponse } from '@eigtech/assignments-types'
import { ApiPostRequest } from '@eigtech/ui-shared-api'
import { assignmentsBasePath } from './constants'

export const assign =
  (post: ApiPostRequest) =>
  ({ assignableId, ...body }: AssignRequest) =>
    post<AssignResponse, AssignBodyRequest>(`${assignmentsBasePath}/${assignableId}`, body, {
      responseType: 'none',
    })
