import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { OrganizationMemberSchema } from '../../logStreamEvents/base/base.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetOrgMembersRequestPathSchema = z.object({
  orgId: z.string(),
})
export const GetOrgMembersRequestSchema = GetOrgMembersRequestPathSchema

export const GetOrgMembersResponseSchema = z.object({
  members: OrganizationMemberSchema.array(),
})

export const GetOrgMembersApiSchema = {
  params: GetOrgMembersRequestPathSchema,
  response: GetOrgMembersResponseSchema,
} satisfies ApiSchema

export type GetOrgMembersRequest = z.infer<typeof GetOrgMembersRequestSchema>
export type GetOrgMembersResponse = z.infer<typeof GetOrgMembersResponseSchema>
export type GetOrgMembersHandler = Handler<GetOrgMembersRequest, GetOrgMembersResponse>
