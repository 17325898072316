import {
  JOB_TYPE_DAILY,
  JOB_TYPE_DAILY_ABBREVIATION,
  JOB_TYPE_EMERGENCY_SERVICES,
  JOB_TYPE_EMERGENCY_SERVICES_ABBREVIATION,
  JOB_TYPE_OTR,
  JobAppointmentIdSchema,
  JobId,
  JobTypeAbbreviationEnum,
  JobTypeAbbreviationSchema,
  JobTypeEnum,
  SubcontractorDocumentIdSchema,
  WorkAuthorizationIdSchema,
} from '@eigtech/restoration-types'
import { typeid } from '@eigtech/typeid'
import { exhaustive } from 'exhaustive'

export const jobTypeFromAbbreviation = (jobTypeAbbreviation: JobTypeAbbreviationEnum) =>
  exhaustive(jobTypeAbbreviation, {
    DAY: () => JOB_TYPE_DAILY,
    EMS: () => JOB_TYPE_EMERGENCY_SERVICES,
    OTR: () => JOB_TYPE_OTR,
  })

export const jobAbbreviationFromJobType = (jobType: JobTypeEnum) =>
  exhaustive(jobType, {
    daily: () => JOB_TYPE_DAILY_ABBREVIATION,
    emergencyServices: () => JOB_TYPE_EMERGENCY_SERVICES_ABBREVIATION,
    otr: () => JOB_TYPE_OTR,
  })

//
export const makeJobId = (
  jobNumber: number,
  jobType: JobTypeEnum,
  stateAbbreviation: string
): JobId => {
  if (stateAbbreviation.length !== 2) throw new Error('invalid state abbreviation')
  const upperStateAbbreviation = stateAbbreviation.toUpperCase()
  const jobTypeAbbreviation = jobAbbreviationFromJobType(jobType)
  const year = new Date().getFullYear()
  const jobFactor = (jobNumber * 7).toString().padStart(8, '0')

  return `job_${year}${jobFactor}-${jobTypeAbbreviation}-${upperStateAbbreviation}`
}

export const destructureJobId = (
  jobId: JobId
): {
  jobNumber: number
  jobFactor: number
  jobType: JobTypeEnum
  stateAbbreviation: string
  year: string
} => {
  const parsed = jobId.split('_')[1]
  const [jobFactorWithYear, jobTypeAbbreviation, stateAbbreviation] = parsed.split('-')
  const jobType = jobTypeFromAbbreviation(JobTypeAbbreviationSchema.parse(jobTypeAbbreviation))
  const year = jobFactorWithYear.substring(0, 4)
  const jobFactor = parseInt(jobFactorWithYear.substring(4))
  const jobNumber = jobFactor / 7

  return {
    year,
    jobFactor,
    jobNumber,
    jobType,
    stateAbbreviation,
  }
}

export const makeJobAppointmentId = () =>
  JobAppointmentIdSchema.parse(typeid('jobappointment').toString())

export const makeSubcontractorDocumentId = () =>
  SubcontractorDocumentIdSchema.parse(typeid('subcontractordocument').toString())

export const makeWorkAuthorizationId = (requestId: string) =>
  WorkAuthorizationIdSchema.parse(`workauthorization_${requestId}`)
