import { Button } from '@eigtech/ui-shared-dave'
import { useEndMeetingHelper } from '../../../hooks'

export function EndMeetingControls() {
  const { canEndMeeting, canLeaveMeeting, endMeeting, isOrganizer, isPending, leaveMeeting } =
    useEndMeetingHelper()

  return (
    <>
      {canEndMeeting && (
        <Button
          colorScheme="red"
          isLoading={isPending}
          variant="outline"
          onClick={() => endMeeting()}
        >
          End Meeting
        </Button>
      )}
      {canLeaveMeeting && !isOrganizer && (
        <Button
          colorScheme="red"
          isLoading={isPending}
          variant="outline"
          onClick={() => leaveMeeting()}
        >
          Leave Meeting
        </Button>
      )}
    </>
  )
}
