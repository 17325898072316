import { ApiSchema } from '@eigtech/gatekeeper-types'
import { PortalConsumerSchema } from '@eigtech/portals-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const ListUserPortalsRequestPathSchema = z.object({
  email: z.string(),
})

export const ListUserPortalsRequestSchema = ListUserPortalsRequestPathSchema

export const ListUserPortalsResponseSchema = z.object({
  portals: z.array(PortalConsumerSchema),
})

export type ListUserPortalsRequestPath = z.infer<typeof ListUserPortalsRequestPathSchema>
export type ListUserPortalsRequest = z.infer<typeof ListUserPortalsRequestSchema>
export type ListUserPortalsResponse = z.infer<typeof ListUserPortalsResponseSchema>

export interface ListUserPortalsHandler
  extends Handler<ListUserPortalsRequest, ListUserPortalsResponse> {}

export const ListUserPortalsApiSchema = {
  params: ListUserPortalsRequestPathSchema,
  response: ListUserPortalsResponseSchema,
} satisfies ApiSchema
