import { JobTypeEnum, JobTypeSchema } from '@eigtech/restoration-types'
import { startCase } from 'lodash-es'

const partialJobTypeLabels: Partial<Record<JobTypeEnum, string>> = {
  otr: 'OTR',
}

export const getJobTypeLabel = (type: JobTypeEnum) => partialJobTypeLabels[type] ?? startCase(type)

export const jobTypeLabels = Object.fromEntries(
  JobTypeSchema.options.map((key) => [key, getJobTypeLabel(key)])
) as Record<JobTypeEnum, string>
