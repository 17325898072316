import { createFileRoute } from '@eigtech/ui-shared-router'
import { ROUTER_PATHS } from '../../lib/constants'
import { Navigate } from '@eigtech/ui-shared-router'

export const Route = createFileRoute('/documentation/')({
  component: Redirect,
})

function Redirect() {
  return <Navigate to={ROUTER_PATHS.documentation.route} />
}
