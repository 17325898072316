import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'communications'
export const communicationsServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const callbackEventType = <V extends string | undefined>(eventName?: V) =>
  communicationsServiceEventType('callback', eventName)

export const CommunicationsServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: communicationsServiceEventType(),
  })
)

export const CommunicationEventSchema = CommunicationsServiceEventBaseSchema.merge(
  z.object({
    type: communicationsServiceEventType(),
    contact: z.unknown(),
    schemaVersion: z.literal(1),
  })
)

export type CommunicationEvent = z.infer<typeof CommunicationEventSchema>
export type CommunicationsServiceEventBase = z.infer<typeof CommunicationsServiceEventBaseSchema>
