import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { ClaimReopenedDetails } from '@eigtech/summarizer-types'

export function ReopenedDetails(props: EventDetailsComponentProps<ClaimReopenedDetails>) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const {
    details: {
      outcome: { date, notes },
    },
  } = props

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Tag colorScheme="yellow">Claim Reopened</Tag>
        {!!date && (
          <Text as="span">
            on <PreferredDateTimeComponent property="closedDate">{date}</PreferredDateTimeComponent>
          </Text>
        )}
      </HStack>

      {!!notes && <Blockquote whiteSpace="preserve">{notes}</Blockquote>}
    </VStack>
  )
}
