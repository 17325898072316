import {
  GetCallbackRequestsByEntityRequest,
  GetCallbackRequestsByEntityResponse,
  GetCallbackRequestsByStatusRequest,
  GetCallbackRequestsByStatusResponse,
} from '@eigtech/communications-types'
import { JobCorn } from '@eigtech/restoration-types'
import { ensureJobCorn } from '@eigtech/restoration-util'
import { CurrentClaimCorn, ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { isCorn } from '@eigtech/shared-corn'
import {
  contextualGet,
  queryOptions,
  useQueries,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { useMemo } from 'react'
import { communicationBasePath, communicationsQueryKeys } from './constants'

const getCallbackRequestsByEntity = ({ entityId }: GetCallbackRequestsByEntityRequest) =>
  contextualGet<GetCallbackRequestsByEntityResponse>(
    `${communicationBasePath}/callback/entity/${entityId}`
  )

export const getCallbackRequestsByEntityQueryOptions = (
  request: GetCallbackRequestsByEntityRequest
) =>
  queryOptions({
    queryKey: communicationsQueryKeys.byEntity(request.entityId),
    queryFn: () => getCallbackRequestsByEntity(request),
    enabled: isCorn(request.entityId),
  })

export const useGetCallbackRequestsByEntity = (request: GetCallbackRequestsByEntityRequest) =>
  useQuery(getCallbackRequestsByEntityQueryOptions(request))

export const useGetCallbackRequestsByEntities = (requests: GetCallbackRequestsByEntityRequest[]) =>
  useQueries({
    queries: requests.map((request) => getCallbackRequestsByEntityQueryOptions(request)),
  })

export const useGetCallbackRequestsByClaim = (claimNumber: string) =>
  useGetCallbackRequestsByEntity({ entityId: ensureClaimCorn(claimNumber) })

export const useGetCallbackRequestsByClaims = (claimNumbers: string[]) =>
  useGetCallbackRequestsByEntities(claimNumbers.map((id) => ({ entityId: ensureClaimCorn(id) })))

export const useGetCallbackRequestsByJob = (jobId: string) =>
  useGetCallbackRequestsByEntity({ entityId: ensureJobCorn(jobId) })

export function useGetCallbackRequestsByJobs(jobIds: string[]) {
  return useGetCallbackRequestsByEntities(jobIds.map((id) => ({ entityId: ensureJobCorn(id) })))
}

export function useInvalidateCallbackRequestsByEntity() {
  const queryClient = useQueryClient()

  return function invalidateCallbackRequestsByEntity(entityId: CurrentClaimCorn | JobCorn) {
    queryClient.invalidateQueries({
      queryKey: getCallbackRequestsByEntityQueryOptions({ entityId }).queryKey,
    })
  }
}

export function useInvalidateCallbackRequestsByClaim() {
  const invalidateQueries = useInvalidateCallbackRequestsByEntity()

  return function invalidateCallbackRequestsByClaim(claimNumber: string) {
    invalidateQueries(ensureClaimCorn(claimNumber))
  }
}

export function useInvalidateCallbackRequestsByJob() {
  const invalidateQueries = useInvalidateCallbackRequestsByEntity()

  return function invalidateCallbackRequestsByJob(jobId: string) {
    invalidateQueries(ensureJobCorn(jobId))
  }
}

//

const getCallbackRequestsByStatus = ({ status }: GetCallbackRequestsByStatusRequest) =>
  contextualGet<GetCallbackRequestsByStatusResponse>(
    `${communicationBasePath}/callback/status/${status}`
  )

export const getCallbackRequestsByStatusQueryOptions = (
  props: GetCallbackRequestsByStatusRequest
) =>
  queryOptions({
    queryKey: communicationsQueryKeys.byStatus(props),
    queryFn: () => getCallbackRequestsByStatus(props),
  })

export const useGetCallbackRequestsByStatus = (props: GetCallbackRequestsByStatusRequest) =>
  useQuery(getCallbackRequestsByStatusQueryOptions(props))

export function useGetActiveCallbackRequests() {
  const requestedQuery = useGetCallbackRequestsByStatus({ status: 'REQUESTED' })
  const attemptedQuery = useGetCallbackRequestsByStatus({ status: 'ATTEMPTED' })
  const pendingQuery = useGetCallbackRequestsByStatus({ status: 'PENDING' })

  return useMemo(() => {
    const requested = requestedQuery.data ?? []
    const attempted = attemptedQuery.data ?? []
    const pending = pendingQuery.data ?? []

    const status = {
      isPending: requestedQuery.isPending || attemptedQuery.isPending || pendingQuery.isPending,
      isFetching: requestedQuery.isFetching || attemptedQuery.isFetching || pendingQuery.isFetching,
      isError: requestedQuery.isError || attemptedQuery.isError || pendingQuery.isError,
    }

    return {
      data: [...(requested ?? []), ...(attempted ?? []), ...(pending ?? [])],
      requested: requested ?? [],
      attempted: attempted ?? [],
      pending: pending ?? [],
      ...status,
    }
  }, [
    attemptedQuery.data,
    attemptedQuery.isError,
    attemptedQuery.isFetching,
    attemptedQuery.isPending,
    pendingQuery.data,
    pendingQuery.isError,
    pendingQuery.isFetching,
    pendingQuery.isPending,
    requestedQuery.data,
    requestedQuery.isError,
    requestedQuery.isFetching,
    requestedQuery.isPending,
  ])
}

export function useInvalidateCallbackRequestsByStatus() {
  const queryClient = useQueryClient()

  return function invalidateCallbackRequestsByStatus(props: GetCallbackRequestsByStatusRequest) {
    queryClient.invalidateQueries({
      queryKey: getCallbackRequestsByStatusQueryOptions(props).queryKey,
    })
  }
}

//

export function useInvalidateCallbackRequests() {
  const queryClient = useQueryClient()

  return function invalidateCallbackRequests() {
    queryClient.invalidateQueries({ queryKey: communicationsQueryKeys.all() })
  }
}
