import { Contact, ContactCorn } from '@eigtech/contacts-types'
import { ensureBookCorn, isContactActive } from '@eigtech/contacts-util'
import { Corn, getResourceId } from '@eigtech/shared-corn'
import { getContactName, useGetAllGlobalBooks, useGetBooks } from '@eigtech/ui-shared-contacts'
import { uniqBy } from 'lodash-es'
import { useMemo } from 'react'

export type UseGetTaggableContactsProps = {
  entityCorn: Corn | Corn[]
}

export type TaggableContact = {
  id: ContactCorn
  name: string
  roles: Contact['roles']
  value: string
}

export function useGetTaggableContacts({ entityCorn }: UseGetTaggableContactsProps) {
  const queries = useGetAllGlobalBooks()

  const booksQueries = useGetBooks(
    [entityCorn].flat().map((entityCorn) => ensureBookCorn(getResourceId(entityCorn)))
  )

  return useMemo(() => {
    function mapQueries(result: typeof queries | typeof booksQueries) {
      return result
        .flatMap(({ data }) => data?.contacts ?? [])
        .filter(isContactActive)
        .map(
          (contact): TaggableContact => ({
            id: contact.contactId,
            name: getContactName(contact),
            roles: contact.roles,
            value: `${getContactName(contact)}(${contact.contactId})`,
          })
        )
    }

    return uniqBy([...mapQueries(booksQueries), ...mapQueries(queries)], 'id')
  }, [booksQueries, queries])
}
