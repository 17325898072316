import { cornType } from '@eigtech/shared-corn'
import z from 'zod'

export const claimsV2ServiceCorn = <V extends string | undefined>(eventName?: V) =>
  cornType('claims', eventName)
export const ClaimsV2ServiceCornSchema = claimsV2ServiceCorn()
export const ClaimV2CornSchema = claimsV2ServiceCorn('claim')

export const isClaimV2Corn = (entityId: string): entityId is ClaimV2Corn =>
  ClaimV2CornSchema.safeParse(entityId).success

export type ClaimV2Corn = z.infer<typeof ClaimV2CornSchema>
export type ClaimsV2ServiceCorn = z.infer<typeof ClaimsV2ServiceCornSchema>
