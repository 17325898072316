// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/link.ts

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const baseStyle = defineStyle({
  color: 'brandPrimary.500',
})

export const linkTheme = defineStyleConfig({
  baseStyle,
})
