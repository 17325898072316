import { BoxProps } from '@eigtech/ui-shared-dave'
import { useAttendeeAudioStatus, useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { useEffect, useRef } from 'react'
import { MicVolumeIndicator } from '../Icons'

export type MicActivityProps = {
  /** The Chime attendee ID */
  attendeeId: string
} & BoxProps

export function MicActivity({ attendeeId, ...props }: MicActivityProps) {
  const audioVideo = useAudioVideo()
  const bgEl = useRef<HTMLDivElement>(null)
  const { signalStrength, muted } = useAttendeeAudioStatus(attendeeId)

  useEffect(() => {
    if (!audioVideo || !attendeeId || !bgEl.current) {
      return
    }

    const callback = (__: string, volume: number | null) => {
      if (bgEl.current) {
        bgEl.current.style.transform = `scaleY(${volume})`
      }
    }

    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, callback)

    return () => {
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback)
    }
  }, [attendeeId, audioVideo])

  return <MicVolumeIndicator ref={bgEl} {...props} muted={muted} signalStrength={signalStrength} />
}
