import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import { BookCornSchema } from '../base/books.js'
import { ContactCornSchema, ContactSchema, ExternalSystemId } from '../base/contacts.js'
import { ServiceEventBaseSchema, serviceEventType } from './service.js'

//Schemas
export const contactEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('contact', eventName)

export const claimContactEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('claimContact', eventName)

export const ContactEventSchema = ServiceEventBaseSchema.merge(
  z.object({
    type: contactEventType(),
    contact: z.unknown(),
  })
)

export const ContactCreatedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('created'),
    contact: ContactSchema,
  })
)

export const ContactDeletedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('deleted'),
    contact: ContactSchema,
  })
)

export const ContactAddedToBookEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('addedToBook'),
    contact: z.object({
      contactId: ContactCornSchema,
      bookId: BookCornSchema,
    }),
  })
)

export const ContactRemovedFromBookEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('removedFromBook'),
    contact: z.object({
      contactId: ContactCornSchema,
      bookId: BookCornSchema,
    }),
  })
)

export const ContactUpdatedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('updated'),
    contact: ContactSchema,
  })
)

export const ContactActivatedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('activated'),
    contact: ContactSchema,
  })
)

export const ContactDeactivatedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('deactivated'),
    contact: ContactSchema,
  })
)

export const ContactExternalSystemIdAddedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('externalSystemIdAdded'),
    contact: z.object({
      info: ContactSchema,
      externalSystem: z.object({
        id: z.string(),
        service: ExternalSystemId,
      }),
    }),
  })
)

export const ContactExternalSystemIdRemovedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('externalSystemIdRemoved'),
    contact: z.object({
      info: ContactSchema,
      externalSystem: z.object({
        id: z.string(),
        service: ExternalSystemId,
      }),
    }),
  })
)

export const ContactMergedEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('merged'),
    contact: z.object({
      baseId: ContactSchema.shape.contactId,
      otherId: ContactSchema.shape.contactId,
      merged: ContactSchema,
    }),
  })
)

export const ContactRestoredEventSchema = ContactEventSchema.merge(
  z.object({
    type: contactEventType('restored'),
    contact: ContactSchema,
  })
)

//typeguards
export const isContactCreatedEvent = (event: EventBase): event is ContactCreatedEvent =>
  ContactCreatedEventSchema.safeParse(event).success
export const isContactAddedToBookEvent = (event: EventBase): event is ContactAddedToBookEvent =>
  ContactAddedToBookEventSchema.safeParse(event).success
export const isContactRemovedFromBookEvent = (
  event: EventBase
): event is ContactRemovedFromBookEvent =>
  ContactRemovedFromBookEventSchema.safeParse(event).success
export const isContactUpdatedEvent = (event: EventBase): event is ContactUpdatedEvent =>
  ContactUpdatedEventSchema.safeParse(event).success

//types
export type ContactEvent = z.infer<typeof ContactEventSchema>
export type ContactCreatedEvent = z.infer<typeof ContactCreatedEventSchema>
export type ContactDeletedEvent = z.infer<typeof ContactDeletedEventSchema>
export type ContactAddedToBookEvent = z.infer<typeof ContactAddedToBookEventSchema>
export type ContactRemovedFromBookEvent = z.infer<typeof ContactRemovedFromBookEventSchema>
export type ContactUpdatedEvent = z.infer<typeof ContactUpdatedEventSchema>
export type ContactActivatedEvent = z.infer<typeof ContactActivatedEventSchema>
export type ContactDeactivatedEvent = z.infer<typeof ContactDeactivatedEventSchema>
export type ContactExternalSystemIdAddedEvent = z.infer<
  typeof ContactExternalSystemIdAddedEventSchema
>
export type ContactExternalSystemIdRemovedEvent = z.infer<
  typeof ContactExternalSystemIdRemovedEventSchema
>
export type ContactMergedEvent = z.infer<typeof ContactMergedEventSchema>
export type ContactRestoredEvent = z.infer<typeof ContactRestoredEventSchema>
