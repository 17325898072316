import { cornType } from '@eigtech/shared-corn'
import z from 'zod'

export const claimResourceType = 'claim'

export const claimsServiceCorn = <V extends string | undefined>(eventName?: V) =>
  cornType('claims', eventName)

export const ClaimsServiceCornSchema = claimsServiceCorn()
export type ClaimsServiceCorn = z.infer<typeof ClaimsServiceCornSchema>
