import { chakra } from '@chakra-ui/react'

export const Blockquote = chakra('blockquote', {
  baseStyle: {
    borderLeftColor: 'gray.300',
    borderLeftWidth: '3px',
    ml: '1',
    pl: '2',
  },
})
