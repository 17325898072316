import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'

export const DeleteContactRequestPathSchema = z.object({
  contactId: ContactCornSchema,
})
export const DeleteContactRequestQuerySchema = z.object({ force: z.literal('true').optional() })

export const DeleteContactRequestSchema = DeleteContactRequestPathSchema.merge(
  DeleteContactRequestQuerySchema
)
export const DeleteContactResponseSchema = z.object({
  contact: ContactSchema.optional(),
})

export type DeleteContactRequestPathParams = z.infer<typeof DeleteContactRequestPathSchema>
export type DeleteContactRequestQueryParams = z.infer<typeof DeleteContactRequestQuerySchema>
export type DeleteContactRequest = z.infer<typeof DeleteContactRequestSchema>

export type DeleteContactResponse = z.infer<typeof DeleteContactResponseSchema>

export interface DeleteContactHandler
  extends Handler<DeleteContactRequest, DeleteContactResponse> {}
export const DeleteContactApiSchema: ApiSchema = {
  params: DeleteContactRequestPathSchema,
  query: DeleteContactRequestQuerySchema,
  response: DeleteContactResponseSchema,
}
