import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import {
  CommunicationTemplateSchema,
  NoteTemplateSchema,
  TimelineTemplateSchema,
} from '../aggregates/template.js'

const omitProps = {
  lastUpdated: true,
  createdOn: true,
  modifiedBy: true,
} as const

export const UpdateTemplateApiSchema = {
  params: z.object({
    id: z.string(),
  }),
  headers: z.object({
    requestActor: z.string().optional(),
  }),
  body: z.discriminatedUnion('type', [
    CommunicationTemplateSchema.omit(omitProps),
    NoteTemplateSchema.omit(omitProps),
    TimelineTemplateSchema.omit(omitProps),
  ]),
} satisfies ApiSchema

export const UpdateTemplateRequestSchema = UpdateTemplateApiSchema.params
  .and(UpdateTemplateApiSchema.body)
  .and(UpdateTemplateApiSchema.headers)

export type UpdateTemplateRequestPathParameters = z.infer<typeof UpdateTemplateApiSchema.params>
export type UpdateTemplateHeaderParameters = z.infer<typeof UpdateTemplateApiSchema.headers>
export type UpdateTemplateRequestBodyParameters = z.infer<typeof UpdateTemplateApiSchema.body>
export type UpdateTemplateRequest = z.infer<typeof UpdateTemplateRequestSchema>
export type UpdateTemplateResponse = void
export type UpdateTemplateHandler = Handler<UpdateTemplateRequest, UpdateTemplateResponse>
