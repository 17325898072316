import z from 'zod'

export const ShareLinkSchema = z.object({
  dateCreated: z.string(),
  expirationDate: z.string(),
  requestActor: z.string(),
  url: z.string(),
})

export type ShareLink = z.infer<typeof ShareLinkSchema>
