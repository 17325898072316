import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const GetTranscriptUrlRequestPathSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
})

export const GetTranscriptUrlResponseSchema = z.object({
  url: z.string(),
})

export const GetTranscriptUrlRequestHeaderSchema = ApiLambdalerAuthHeaderSchema

export const GetTranscriptUrlRequestSchema = GetTranscriptUrlRequestPathSchema.merge(
  GetTranscriptUrlRequestHeaderSchema
)

export type GetTranscriptUrlRequest = z.infer<typeof GetTranscriptUrlRequestSchema>
export type GetTranscriptUrlRequestPath = z.infer<typeof GetTranscriptUrlRequestPathSchema>
export type GetTranscriptUrlResponse = z.infer<typeof GetTranscriptUrlResponseSchema>

export interface GetTranscriptUrlHandler
  extends Handler<GetTranscriptUrlRequest, GetTranscriptUrlResponse> {}
