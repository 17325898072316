import {
  Box,
  ButtonGroup,
  chakra,
  forwardRef,
  layoutPropNames,
  omitThemingProps,
  RadioGroupContext,
  RadioGroupProps,
  RadioProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'
import { createContext, useContext, useMemo } from 'react'
import { callAll, splitObject } from '../../../utils'

export type RadioButtonContext = RadioGroupContext

const RadioButtonGroupContext = createContext<RadioButtonContext | undefined>(undefined)

export function useRadioButtonGroupContext() {
  const context = useContext(RadioButtonGroupContext)

  if (!context) {
    throw new Error('useRadioButtonGroupContext needs to be used within RadioButtonGroup')
  }

  return context
}

export type RadioButtonGroupProps = RadioGroupProps

export const RadioButtonGroup = forwardRef<RadioButtonGroupProps, 'div'>((props, ref) => {
  const { colorScheme, size, variant, children, isDisabled, isFocusable, ...rest } = props

  const { value, onChange, getRootProps, name, htmlProps } = useRadioGroup(rest)

  const group = useMemo(
    () => ({
      name,
      size,
      onChange,
      colorScheme,
      value,
      variant,
      isDisabled,
      isFocusable,
    }),
    [name, size, onChange, colorScheme, value, variant, isDisabled, isFocusable]
  )

  return (
    <RadioButtonGroupContext.Provider value={group}>
      <ButtonGroup
        isAttached
        sx={{ '> *:not(:last-child)': { mr: '-1px' } }}
        {...getRootProps(htmlProps, ref)}
      >
        {children}
      </ButtonGroup>
    </RadioButtonGroupContext.Provider>
  )
})

export type RadioButtonProps = Omit<RadioProps, 'spacing' | 'variant' | 'colorScheme'>

export const RadioButton = forwardRef<RadioButtonProps, 'input'>((props, ref) => {
  const group = useRadioButtonGroupContext()
  const { onChange: onChangeProp, value: valueProp } = props

  const ownProps = omitThemingProps(props)

  const {
    children,
    isDisabled = group?.isDisabled,
    isFocusable = group?.isFocusable,
    inputProps: htmlInputProps,
    ...rest
  } = ownProps

  let isChecked = props.isChecked
  if (group?.value != null && valueProp != null) {
    isChecked = group.value === valueProp
  }

  let onChange = onChangeProp
  if (group?.onChange && valueProp != null) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const name = props?.name ?? group?.name

  const { getInputProps, getCheckboxProps, getLabelProps, getRootProps, htmlProps } = useRadio({
    ...rest,
    isChecked,
    isFocusable,
    isDisabled,
    onChange,
    name,
  })

  const [layoutProps, otherProps] = splitObject(htmlProps, layoutPropNames as any)

  const checkboxProps = getCheckboxProps(otherProps)
  const inputProps = getInputProps(htmlInputProps, ref)
  const labelProps = getLabelProps()
  const rootProps = { ...layoutProps, ...getRootProps() }

  return (
    <Box as="label" borderRadius="md" borderWidth="1px" overflow="hidden" {...rootProps}>
      <input {...inputProps} />
      <Box
        _checked={{
          bg: 'brandPrimary.500',
          color: 'white',
          borderColor: 'brandPrimary.500',
        }}
        cursor="pointer"
        px={4}
        py={2}
        {...checkboxProps}
      >
        {children && <chakra.span {...labelProps}>{children}</chakra.span>}
      </Box>
    </Box>
  )
})
