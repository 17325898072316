import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { AlertEventSchema, WatchedAlertRuleSchema } from '../base.js'

export const GetWatchedAlertsRequestQuerySchema = WatchedAlertRuleSchema.pick({ id: true })

export const GetWatchedAlertsRequestSchema = GetWatchedAlertsRequestQuerySchema

export const GetWatchedAlertsResponseSchema = z.object({
  alerts: AlertEventSchema.array(),
})

export type GetWatchedAlertsRequest = z.infer<typeof GetWatchedAlertsRequestSchema>
export type GetWatchedAlertsResponse = z.infer<typeof GetWatchedAlertsResponseSchema>

export type GetWatchedAlertsHandler = Handler<GetWatchedAlertsRequest, GetWatchedAlertsResponse>
