import { PublicationTargetsSchema as BasePublicationTargetsSchema } from '@eigtech/documents-types'
import { startCase } from 'lodash-es'
import { z } from 'zod'

export const ExternalDocumentPublicationTargetsSchema = BasePublicationTargetsSchema.extract([
  'Symbility',
  'XactAnalysis',
  'Xactimate',
])

export type ExternalDocumentPublicationTargets = z.infer<
  typeof ExternalDocumentPublicationTargetsSchema
>

const publicationTargetsLabelMap: Partial<Record<ExternalDocumentPublicationTargets, string>> = {
  XactAnalysis: 'XA',
  Xactimate: 'XA (via Xactimate)',
}

export const getPublicationTargetLabel = (target: ExternalDocumentPublicationTargets) =>
  publicationTargetsLabelMap[target] ?? startCase(target)
