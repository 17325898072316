import { Button, ButtonProps, forwardRef, useDisclosure } from '@eigtech/ui-shared-dave'
import { MediaCarouselProps } from './MediaCarousel'
import { MediaCarouselModal } from './MediaCarouselModal'

export type MediaCarouselButtonProps = Omit<ButtonProps, 'onClick'> & MediaCarouselProps

export const MediaCarouselButton = forwardRef<MediaCarouselButtonProps, 'button'>(
  function MediaCarouselButton({ documents, ...props }, ref) {
    const modal = useDisclosure()
    return (
      <>
        <Button ref={ref} {...props} onClick={() => modal.onOpen()}>
          {props.children ?? 'View Media'}
        </Button>

        {modal.isOpen && <MediaCarouselModal {...modal} documents={documents} />}
      </>
    )
  }
)
