import { Auth0RoleNameEnum, CohortEnum, CohortEnumSchema } from '@eigtech/auth0-types'

export const roleToCohort = {
  'CSR - Project Manager': CohortEnumSchema.Enum.csr,
  'CSR - Reviewer': CohortEnumSchema.Enum.csr,
  'CSR - Super Admin': CohortEnumSchema.Enum.csr,
  'CSR - User': CohortEnumSchema.Enum.csr,
  'Estimator - User': CohortEnumSchema.Enum.estimator,
  'TPLAP - Field Technician': CohortEnumSchema.Enum.technician,
  'TPLAP - Subcontractor': CohortEnumSchema.Enum.technician,
  'TPLAP - User': CohortEnumSchema.Enum.insured,
  'Ultra Super Admin': CohortEnumSchema.Enum.super,
} satisfies Record<Auth0RoleNameEnum, CohortEnum>
