import {
  formatUsPhoneToHtml,
  getContactEmails,
  getContactPhoneNumber,
} from '@eigtech/contacts-util'
import { useGetContactOfTypeFromClaim } from '@eigtech/ui-shared-assignments'
import { getContactName } from '@eigtech/ui-shared-contacts'
import {
  Badge,
  Box,
  CircularProgress,
  FancyLink,
  HStack,
  Heading,
  Icon,
  IconProps,
  Page,
  PageTabs,
  Skeleton,
  Stack,
  TabList,
  Text,
  forwardRef,
  getGoogleMapQueryLink,
  useBreakpointValue,
} from '@eigtech/ui-shared-dave'
import { formatPhoneNumber } from '@eigtech/ui-shared-phone'
import {
  BackButton,
  LinkButton,
  LinkTab,
  Outlet,
  createLazyFileRoute,
  useChildMatches,
} from '@eigtech/ui-shared-router'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { IoMdDocument } from 'react-icons/io'
import {
  MdCalendarMonth,
  MdInfo,
  MdMeetingRoom,
  MdAssignment,
  MdOutlineStickyNote2,
  MdPeople,
  MdPhoneCallback,
  MdPhoto,
} from 'react-icons/md'
import { TbWorldDollar } from 'react-icons/tb'
import { useGetClaim } from '../../../api'
import { ROUTER_PATHS } from '../../../lib/constants'

export const Route = createLazyFileRoute('/claims/$claimNumber/_layout')({
  component: ClaimDetail,
})

function ClaimDetail() {
  const { claimNumber } = Route.useParams()

  const { isPending, isError } = useGetClaim(claimNumber)

  const backButton = useBreakpointValue({
    base: null,
    md: <BackButton to={ROUTER_PATHS.home.route}>← Claims</BackButton>,
  })

  return (
    <Page
      aboveHeader={backButton}
      belowHeader={<ClaimDetailMenu />}
      errorMessage="Something went wrong trying to retrieve this claim."
      heading={<ClaimDetailHeader />}
      isError={isError}
      isPending={isPending}
    >
      <Outlet />
    </Page>
  )
}

function ClaimDetailHeader() {
  const { claimNumber } = Route.useParams()

  const { data: claim, isPending, isFetching } = useGetClaim(claimNumber)

  const { contacts: primaryContacts } = useGetContactOfTypeFromClaim({
    contactType: 'primaryContact',
    claimNumber,
  })
  const primaryContact = primaryContacts?.[0]

  const primaryContactName = getContactName(primaryContact ?? {})
  const phone = getContactPhoneNumber(primaryContact ?? {})
  const emails = getContactEmails(primaryContact ?? {})

  const { addressLine1, addressLine2, city, stateOrProvince, zipOrPostalCode } =
    claim?.lossLocation?.address ?? {}
  const fullAddress = [addressLine1, addressLine2, city, stateOrProvince, zipOrPostalCode]
    .filter((value) => !!value)
    .join(', ')

  return (
    <Stack spacing="1">
      <HStack>
        <Skeleton isLoaded={!isPending}>
          <Heading as="h1" size="lg">
            {!!claim ? primaryContactName : isPending}
          </Heading>
        </Skeleton>

        {claim?.isReadOnly && <Badge colorScheme="yellow">Read-only Claim</Badge>}

        {!!(isFetching || isPending) && <CircularProgress isIndeterminate size="2rem" />}
      </HStack>

      <Stack spacing="0">
        <Text>{claimNumber}</Text>

        <Skeleton isLoaded={!isPending}>
          {isPending
            ? 'Loading phone...'
            : !!phone && (
                <FancyLink href={`tel:${formatUsPhoneToHtml(phone)}`} iconPosition="left">
                  {formatPhoneNumber(phone)}
                </FancyLink>
              )}
        </Skeleton>
        <Skeleton isLoaded={!isPending}>
          {isPending
            ? 'Loading email...'
            : !!emails &&
              emails.map((email) => (
                <FancyLink key={email} href={`mailto:${email}`} iconPosition="left">
                  {email}
                </FancyLink>
              ))}
        </Skeleton>
        <Skeleton isLoaded={!isPending}>
          {!!claim?.lossLocation?.address ? (
            <FancyLink
              customIcon={MapIcon}
              href={getGoogleMapQueryLink(claim.lossLocation.address)}
              iconPosition="left"
              isExternal
            >
              {fullAddress}
            </FancyLink>
          ) : (
            'Loading...'
          )}
        </Skeleton>
      </Stack>
    </Stack>
  )
}

function ClaimDetailMenu() {
  const { claimNumber } = Route.useParams()

  const matches = useChildMatches()
  const tabIndex =
    routes.findIndex((route) => matches.some((match) => match.routeId === route.id)) ?? 0

  const isClaimHome = matches.some((match) => match.routeId === '/claims/$claimNumber/_layout/')

  return (
    <>
      <Box bg="white" borderRadius="md" display={{ base: 'block', md: 'none' }}>
        <LinkButton
          params={{ claimNumber }}
          size="sm"
          to={isClaimHome ? ROUTER_PATHS.home.route : ROUTER_PATHS.claimDetail.route}
          variant="outline"
          w="full"
        >
          ← {isClaimHome ? 'All Claims' : 'Claim Home'}
        </LinkButton>
      </Box>

      <Box display={{ base: 'none', md: 'block' }}>
        <PageTabs index={tabIndex}>
          <TabList>
            {routes.map((tab) => (
              <LinkTab
                key={tab.id}
                data-id={tab.id}
                params={{ claimNumber }}
                position="relative"
                {...tab.url}
              >
                {tab.label}
              </LinkTab>
            ))}
          </TabList>
        </PageTabs>
      </Box>
    </>
  )
}

export const routes = [
  {
    url: { to: ROUTER_PATHS.claimDetailInfo.route },
    id: ROUTER_PATHS.claimDetailInfo.routeId,
    label: 'Details',
    icon: MdInfo,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailInspections.route },
    id: ROUTER_PATHS.claimDetailInspections.routeId,
    label: 'Inspections',
    icon: MdCalendarMonth,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailEstimates.route },
    id: ROUTER_PATHS.claimDetailEstimates.routeId,
    label: 'Estimates',
    icon: TbWorldDollar,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailContacts.route },
    id: ROUTER_PATHS.claimDetailContacts.routeId,
    label: 'Contacts',
    icon: MdPeople,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailDocuments.route },
    id: ROUTER_PATHS.claimDetailDocuments.routeId,
    label: 'Documents',
    icon: IoMdDocument,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailMedia.route },
    id: ROUTER_PATHS.claimDetailMedia.routeId,
    label: 'Media',
    icon: MdPhoto,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailNotes.route },
    id: ROUTER_PATHS.claimDetailNotes.routeId,
    label: 'Notes',
    icon: MdOutlineStickyNote2,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailCallbacks.route },
    id: ROUTER_PATHS.claimDetailCallbacks.routeId,
    label: 'Callbacks',
    icon: MdPhoneCallback,
  },
  {
    url: { to: ROUTER_PATHS.claimDetailMeetings.route },
    id: ROUTER_PATHS.claimDetailMeetings.routeId,
    label: 'Meetings',
    icon: MdMeetingRoom,
  },
  ...(import.meta.env.VITE_ENABLE_LOSS_REPORT === 'true'
    ? [
        {
          url: { to: ROUTER_PATHS.claimDetailLossReport.route },
          id: ROUTER_PATHS.claimDetailLossReport.routeId,
          label: 'Loss Report',
          icon: MdAssignment,
        },
      ]
    : []),
]

const MapIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={FaMapMarkedAlt} />
))
