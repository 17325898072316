import {
  Box,
  forwardRef,
  GridItem,
  GridProps,
  IconButton,
  IconButtonProps,
  SimpleGrid,
  Tooltip,
} from '@eigtech/ui-shared-dave'
import { useLocalVideo, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react'
import { pick } from 'lodash-es'
import { BsPinAngle as UnPinnedIcon, BsPinAngleFill as PinnedIcon } from 'react-icons/bs'
import { MeetingState, useMeetingStore } from '../../context'
import { useFilteredRoster } from '../../hooks'
import { LocalVideo } from './LocalVideo'
import { RemoteVideo } from './RemoteVideo'
import { VideoTile, VideoTileProps } from './VideoTile'

export type RosterVideosProps = GridProps

export const RosterVideos = forwardRef<RosterVideosProps, 'div'>((props, ref) => {
  const { chimeAttendee, selectedSpeaker, setSelectedSpeaker } = useMeetingStore(stateSelector)
  const roster = useFilteredRoster()

  const { isVideoEnabled } = useLocalVideo()

  const { attendeeIdToTileId } = useRemoteVideoTileState()

  return (
    <SimpleGrid ref={ref} columns={[2, 3, 4, 5, 6]} gap={2} {...props}>
      {Object.values(roster).map((attendee) => {
        const tileId = attendeeIdToTileId[attendee.chimeAttendeeId]
        const isSelf = chimeAttendee?.AttendeeId === attendee.chimeAttendeeId
        const isPinned = selectedSpeaker === attendee.chimeAttendeeId

        const sharedProps: VideoTileProps = {
          attendeeId: attendee.chimeAttendeeId,
          name: attendee.name,
          actionsSlot: isSelf ? undefined : (
            <Tooltip label={`${isPinned ? 'Unpin' : 'Pin'} user`}>
              <VideoTileIconButton
                aria-label={`${isPinned ? 'Unpin' : 'Pin'} user.`}
                icon={isPinned ? <PinnedIcon /> : <UnPinnedIcon />}
                onClick={() => {
                  if (isSelf) return
                  setSelectedSpeaker(isPinned ? null : attendee.chimeAttendeeId)
                }}
              />
            </Tooltip>
          ),
        }

        return (
          <GridItem
            key={attendee.chimeAttendeeId}
            cursor={isSelf ? undefined : 'pointer'}
            outline="solid"
            outlineColor={isPinned ? 'blue.300' : 'transparent'}
          >
            <Box>
              {isSelf && isVideoEnabled ? (
                <LocalVideo {...sharedProps} />
              ) : tileId >= 0 ? (
                <RemoteVideo {...sharedProps} tileId={tileId} />
              ) : (
                <VideoTile {...sharedProps}></VideoTile>
              )}
            </Box>
          </GridItem>
        )
      })}
    </SimpleGrid>
  )
})

const stateSelector = (state: MeetingState) =>
  pick(state, ['chimeAttendee', 'setSelectedSpeaker', 'selectedSpeaker'])

const VideoTileIconButton = forwardRef<IconButtonProps, 'button'>((props, ref) => (
  <IconButton
    ref={ref}
    _hover={{
      background: 'whiteAlpha.500',
    }}
    color="white"
    size="xs"
    variant="ghost"
    {...props}
  />
))
