import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, ClaimFactorsSchema } from '../model'
import { BaseHeaderSchema } from './base'

const UpdateClaimFactorsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    claimFactors: ClaimFactorsSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    claimFactors: ClaimFactorsSchema.array(),
  }),
}

export const UpdateClaimFactorsRequestSchema = UpdateClaimFactorsSchemas.params
  .merge(UpdateClaimFactorsSchemas.body)
  .merge(UpdateClaimFactorsSchemas.headers)

export type UpdateClaimFactorsBodyRequest = z.infer<typeof UpdateClaimFactorsSchemas.body>
export type UpdateClaimFactorsHeaderRequest = z.infer<typeof UpdateClaimFactorsSchemas.headers>
export type UpdateClaimFactorsRequest = z.infer<typeof UpdateClaimFactorsRequestSchema>
export type UpdateClaimFactorsResponse = z.infer<typeof UpdateClaimFactorsSchemas.response>

export interface UpdateClaimFactorsHandler
  extends Handler<UpdateClaimFactorsRequest, UpdateClaimFactorsResponse> {}

export const UpdateClaimFactorsApiSchema: ApiSchema = UpdateClaimFactorsSchemas
