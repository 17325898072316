import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'portals'
export const portalsServiceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const PortalsServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: portalsServiceEventType(),
  })
)

export type PortalsServiceEventBase = z.infer<typeof PortalsServiceEventBaseSchema>
