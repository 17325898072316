import z from 'zod'
import { Handler } from 'aws-lambda'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'

export const ImportClaimAssignmentRequestBodySchema = z.object({
  claimNumber: z.string(),
  transactionId: z.string().optional(),
  xactnetId: z.string().min(1),
})
export const ImportClaimAssignmentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})

export const ImportApiResponseSchema = z.object({
  success: z.boolean(),
  message: z.string(),
})

export const ImportClaimAssignmentRequestSchema = ImportClaimAssignmentRequestBodySchema.merge(
  ImportClaimAssignmentRequestHeadersSchema
)

export type ImportClaimAssignmentRequest = z.infer<typeof ImportClaimAssignmentRequestSchema>
export type ImportClaimAssignmentResponse = void
export type ImportApiResponse = z.infer<typeof ImportApiResponseSchema>

export type ImportClaimAssignmentHandler = Handler<
  ImportClaimAssignmentRequest,
  ImportClaimAssignmentResponse
>

export const ImportClaimAssignmentApiSchema: ApiSchema = {
  body: ImportClaimAssignmentRequestBodySchema,
  headers: ImportClaimAssignmentRequestHeadersSchema,
  response: ImportApiResponseSchema,
}
