import { Checkbox, CheckboxGroup, CheckboxProps, Stack, StackProps } from '@eigtech/ui-shared-dave'
import { FC, ReactNode } from 'react'
import { Control, FieldValues, get, useController } from 'react-hook-form'
import { BaseProps, FormControl } from 'react-hook-form-chakra'
import type { RequireAtLeastOne } from 'type-fest'
import { WithFieldNameProp } from '../types/FieldProps'

export type CheckboxGroupFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  WithFieldNameProp<CheckboxGroupContainerProps, TFieldValues>,
  'children'
> &
  RequireAtLeastOne<
    {
      children?: ReactNode
      options?: { value: string; label?: ReactNode; checkboxProps?: CheckboxProps }[]
      checkboxProps?: CheckboxProps
    },
    'children' | 'options'
  >

export function CheckboxGroupField<TFieldValues extends FieldValues = FieldValues>({
  children,
  options,
  checkboxProps: baseCheckboxProps,
  ...props
}: CheckboxGroupFieldProps<TFieldValues>) {
  return (
    <CheckboxGroupContainer {...props}>
      {children ??
        (options ?? []).map(({ value, label, checkboxProps }) => (
          <CheckboxGroupControl
            key={value}
            isDisabled={props.isDisabled}
            isReadOnly={props.isReadOnly}
            {...baseCheckboxProps}
            {...checkboxProps}
            name={props.name}
            value={value}
          >
            {label ?? value}
          </CheckboxGroupControl>
        ))}
    </CheckboxGroupContainer>
  )
}

export type CheckboxGroupFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof CheckboxGroupField<TFieldValues>

//

export interface CheckboxGroupContainerProps extends BaseProps {
  stackProps?: StackProps
  children: ReactNode
}

export const CheckboxGroupContainer: FC<CheckboxGroupContainerProps> = (
  props: CheckboxGroupContainerProps
) => {
  const { name, label, control, children, stackProps, ...rest } = props
  const {
    field: { ref, ...field },
  } = useController({
    control,
    name,
    defaultValue: props.defaultValue || [],
  })

  return (
    <FormControl control={control} label={label as string} name={name} {...rest}>
      <CheckboxGroup {...field}>
        <Stack ref={ref} mt={1} pl={4} spacing={1} {...stackProps}>
          {children}
        </Stack>
      </CheckboxGroup>
    </FormControl>
  )
}

export interface CheckboxGroupControlProps extends CheckboxProps {
  name: string
  label?: ReactNode
  control?: Control<any, any>
}

export const CheckboxGroupControl: FC<CheckboxGroupControlProps> = (
  props: CheckboxGroupControlProps
) => {
  const { name, control, label, children, value, ...rest } = props
  const {
    formState: { errors, isSubmitting },
  } = useController({ name, control })

  const error = get(errors, name, '')

  return (
    <Checkbox isDisabled={isSubmitting} isInvalid={!!error} value={value} {...rest}>
      {label}
      {children}
    </Checkbox>
  )
}
