import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  JobIdSchema,
  SubcontractorIssuedDocumentSchema,
  SubcontractorSchema,
} from '../../base/index.js'

export const RecordSubcontractorDocumentIssuedRequestBodySchema = z.object({
  subcontractorDocument: SubcontractorIssuedDocumentSchema.omit({ subcontractorDocumentId: true }),
  subcontractor: SubcontractorSchema,
})
export const RecordSubcontractorDocumentIssuedRequestHeadersSchema =
  ApiLambdalerAuthHeaderSchema.pick({ requestActor: true })
export const RecordSubcontractorDocumentIssuedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordSubcontractorDocumentIssuedRequestSchema =
  RecordSubcontractorDocumentIssuedRequestBodySchema.merge(
    RecordSubcontractorDocumentIssuedRequestHeadersSchema
  ).merge(RecordSubcontractorDocumentIssuedRequestPathSchema)

export const RecordSubcontractorDocumentIssuedResponseSchema = z.object({
  jobId: JobIdSchema,
  subcontractorDocument: SubcontractorIssuedDocumentSchema,
  subcontractor: SubcontractorSchema,
})
export const RecordSubcontractorDocumentIssuedApiSchema: ApiSchema = {
  body: RecordSubcontractorDocumentIssuedRequestBodySchema,
  headers: RecordSubcontractorDocumentIssuedRequestHeadersSchema,
  params: RecordSubcontractorDocumentIssuedRequestPathSchema,
  response: RecordSubcontractorDocumentIssuedResponseSchema,
}

export interface RecordSubcontractorDocumentIssuedRequestBody
  extends z.infer<typeof RecordSubcontractorDocumentIssuedRequestBodySchema> {}
export interface RecordSubcontractorDocumentIssuedRequestPath
  extends z.infer<typeof RecordSubcontractorDocumentIssuedRequestPathSchema> {}
export interface RecordSubcontractorDocumentIssuedRequest
  extends z.infer<typeof RecordSubcontractorDocumentIssuedRequestSchema> {}
export type RecordSubcontractorDocumentIssuedResponse = z.infer<
  typeof RecordSubcontractorDocumentIssuedResponseSchema
>
export interface RecordSubcontractorDocumentIssuedHandler
  extends Handler<
    RecordSubcontractorDocumentIssuedRequest,
    RecordSubcontractorDocumentIssuedResponse
  > {}
