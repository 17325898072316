import { OutboxMessageSchema } from '@eigtech/messaging-types'
import z from 'zod'
import { MessagingEventSchema, messagingServiceEventType } from '../base'

const outboxMessageEventType = <V extends string | undefined>(eventName?: V) =>
  messagingServiceEventType('outboxMessage', eventName)

export const OutboxMessageCreatedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: outboxMessageEventType('created'),
    message: OutboxMessageSchema,
  })
)

export type OutboxMessageCreatedEvent = z.infer<typeof OutboxMessageCreatedEventSchema>
