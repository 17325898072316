import { forwardRef, SimpleGrid, SimpleGridProps, Skeleton, SkeletonProps } from '@chakra-ui/react'
import { random, times } from 'lodash-es'

type GridLoaderProps = {
  skeletonProps?: SkeletonProps
  numberOfSkeletonLoaders?: number
} & SimpleGridProps

export const GridLoader = forwardRef<GridLoaderProps, 'div'>(function GridLoader(
  { numberOfSkeletonLoaders = 4, skeletonProps = {}, ...props },
  ref
) {
  return (
    <SimpleGrid ref={ref} columns={[1, 2]} gap="4" {...props}>
      {times(numberOfSkeletonLoaders, (index) => (
        <Skeleton key={index} h={[56, `${320 * random(0.9, 1.1)}px`]} w="full" {...skeletonProps} />
      )).map((loader) => loader)}
    </SimpleGrid>
  )
})
