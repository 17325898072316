import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  JobIdSchema,
  SubcontractorReceivedDocumentSchema,
  SubcontractorSchema,
} from '../../base/index.js'

export const RecordSubcontractorDocumentReceivedRequestBodySchema = z.object({
  subcontractorDocument: SubcontractorReceivedDocumentSchema.partial({
    subcontractorDocumentId: true,
  }),
  subcontractor: SubcontractorSchema,
})
export const RecordSubcontractorDocumentReceivedRequestHeadersSchema =
  ApiLambdalerAuthHeaderSchema.pick({ requestActor: true })
export const RecordSubcontractorDocumentReceivedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordSubcontractorDocumentReceivedRequestSchema =
  RecordSubcontractorDocumentReceivedRequestBodySchema.merge(
    RecordSubcontractorDocumentReceivedRequestHeadersSchema
  ).merge(RecordSubcontractorDocumentReceivedRequestPathSchema)

export const RecordSubcontractorDocumentReceivedResponseSchema = z.object({
  jobId: JobIdSchema,
  subcontractorDocument: SubcontractorReceivedDocumentSchema,
  subcontractor: SubcontractorSchema,
})
export const RecordSubcontractorDocumentReceivedApiSchema: ApiSchema = {
  body: RecordSubcontractorDocumentReceivedRequestBodySchema,
  headers: RecordSubcontractorDocumentReceivedRequestHeadersSchema,
  params: RecordSubcontractorDocumentReceivedRequestPathSchema,
  response: RecordSubcontractorDocumentReceivedResponseSchema,
}

export interface RecordSubcontractorDocumentReceivedRequestBody
  extends z.infer<typeof RecordSubcontractorDocumentReceivedRequestBodySchema> {}
export interface RecordSubcontractorDocumentReceivedRequestPath
  extends z.infer<typeof RecordSubcontractorDocumentReceivedRequestPathSchema> {}
export interface RecordSubcontractorDocumentReceivedRequest
  extends z.infer<typeof RecordSubcontractorDocumentReceivedRequestSchema> {}
export type RecordSubcontractorDocumentReceivedResponse = z.infer<
  typeof RecordSubcontractorDocumentReceivedResponseSchema
>
export interface RecordSubcontractorDocumentReceivedHandler
  extends Handler<
    RecordSubcontractorDocumentReceivedRequest,
    RecordSubcontractorDocumentReceivedResponse
  > {}
