import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ReactNode, useCallback } from 'react'
import { useNavigate } from '@eigtech/ui-shared-router'

const domain = import.meta.env.VITE_AUTH0_DOMAIN
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID
const audience = import.meta.env.VITE_AUTH0_AUDIENCE
const organizationId = import.meta.env.VITE_AUTH0_ORGANIZATION_ID

export type Auth0ProviderWithHistoryProps = {
  children: ReactNode
}

export function Auth0ProviderWithHistory({ children }: Auth0ProviderWithHistoryProps) {
  if (typeof domain !== 'string' || typeof clientId !== 'string') {
    throw new Error('VITE_AUTH0_DOMAIN & VITE_AUTH0_CLIENT_ID env variables need to be set.')
  }

  const navigate = useNavigate()

  const onRedirectCallback = useCallback(
    (appState: AppState | undefined) => {
      navigate({ params: {}, search: {}, to: appState?.returnTo || window.location.pathname })
    },
    [navigate]
  )

  return (
    <Auth0Provider
      audience={audience}
      clientId={clientId}
      domain={domain}
      organization={organizationId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
