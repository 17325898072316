import {
  DefaultTimelineTemplateSchema,
  makeTimelineInstructionTemplateSchema,
} from '@eigtech/templates-types'
import z from 'zod'
import { timelineServiceCorn } from '../base/service.js'
import { StatusReduction, StatusReductionSchema } from '../base/statusReduction.js'
import { TimelinePointSchema, TimelineSchema } from '../base/timeline.js'
import { TimelineAssignmentsEventTypeSchema } from '../events/assignments.js'

// Timeline Type
export const JobAssignmentsTimelineTypeSchema = z.literal('jobAssignments')

// Timeline Corn
export const JobAssignmentsTimelineCornSchema = timelineServiceCorn(
  JobAssignmentsTimelineTypeSchema.value
)

// Timeline Points
export const JobAssignmentsTimelinePointTypeSchema = z.enum([
  // Claim Assignable
  'jobAssignedToClaim',
  'jobUnassignedFromClaim',

  // Contact Assignee - Assigned
  'approverAssignedToJob',
  'coordinatorAssignedToJob',
  'dispatcherAssignedToJob',
  'fieldTechnicianAssignedToJob',
  'primaryContactAssignedToJob',
  'projectManagerAssignedToJob',
  'subcontractorAssignedToJob',

  // Contact Assignee - Unassigned
  'approverUnassignedFromJob',
  'coordinatorUnassignedFromJob',
  'dispatcherUnassignedFromJob',
  'fieldTechnicianUnassignedFromJob',
  'primaryContactUnassignedFromJob',
  'projectManagerUnassignedFromJob',
  'subcontractorUnassignedFromJob',
])

export const JobAssignmentsTimelinePointSchema = TimelinePointSchema.merge(
  z.object({
    type: JobAssignmentsTimelinePointTypeSchema,
  })
)
// Timeline
export const JobAssignmentsTimelineSchema = TimelineSchema.merge(
  z.object({
    id: JobAssignmentsTimelineCornSchema,
    type: JobAssignmentsTimelineTypeSchema,
    points: z.array(JobAssignmentsTimelinePointSchema),
  })
)

// Timeline Template
export type JobAssignmentsTimelineEnum = typeof JobAssignmentsTimelinePointTypeSchema.enum
export type JobAssignmentsTimelinePoints = typeof JobAssignmentsTimelinePointTypeSchema.options
export type AssignmentsTimelineEvents = typeof TimelineAssignmentsEventTypeSchema.options

export const JobAssignmentsTimelineInstructionsTemplateSchema =
  makeTimelineInstructionTemplateSchema<JobAssignmentsTimelinePoints, JobAssignmentsTimelinePoints>(
    JobAssignmentsTimelinePointTypeSchema,
    JobAssignmentsTimelinePointTypeSchema
  )

export const JobAssignmentsTimelineTemplateSchema = DefaultTimelineTemplateSchema.merge(
  z.object({
    instructionTemplate: JobAssignmentsTimelineInstructionsTemplateSchema,
    timelineType: JobAssignmentsTimelineTypeSchema,
  })
)

// Status Reduction
export const JobAssignmentsReductionSchema = StatusReductionSchema.merge(
  z.object({
    type: JobAssignmentsTimelineTypeSchema,
    timeline: JobAssignmentsTimelineSchema,
  })
)

export const isAssignmentsReduction = (
  reduction: StatusReduction
): reduction is JobAssignmentsReduction =>
  JobAssignmentsReductionSchema.safeParse(reduction).success

// Types
export type JobAssignmentsReduction = z.infer<typeof JobAssignmentsReductionSchema>
export type JobAssignmentsTimeline = z.infer<typeof JobAssignmentsTimelineSchema>
export type JobAssignmentsTimelineInstructionsTemplate = z.infer<
  typeof JobAssignmentsTimelineInstructionsTemplateSchema
>
export type JobAssignmentsTimelinePoint = z.infer<typeof JobAssignmentsTimelinePointSchema>
export type JobAssignmentsTimelinePointType = z.infer<typeof JobAssignmentsTimelinePointTypeSchema>
export type JobAssignmentsTimelineTemplate = z.infer<typeof JobAssignmentsTimelineTemplateSchema>

export type JobAssignmentsTimelineType = z.infer<typeof JobAssignmentsTimelineTypeSchema>
export type JobAssignmentsTimelineCorn = z.infer<typeof JobAssignmentsTimelineCornSchema>
