import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { GlobalBookCornCanonSchema } from '../../../base/books.js'

export const ListGlobalBooksRequestQueryStringSchema = z.object({
  name: z.string().optional(),
})

export const ListGlobalBooksRequestSchema = ListGlobalBooksRequestQueryStringSchema

/**
 * Allow deprecated book IDs so they can be deleted manually
 */
export const ListGlobalBooksResponseItemSchema = z.object({
  bookId: GlobalBookCornCanonSchema,
  name: z.string(),
  numContacts: z.number(),
})

export const ListGlobalBooksResponseSchema = z.object({
  books: ListGlobalBooksResponseItemSchema.array(),
})

export type ListGlobalBooksRequestQueryString = z.infer<
  typeof ListGlobalBooksRequestQueryStringSchema
>

export type ListGlobalBooksRequest = z.infer<typeof ListGlobalBooksRequestSchema>

export type ListGlobalBooksResponseItem = z.infer<typeof ListGlobalBooksResponseItemSchema>

export type ListGlobalBooksResponse = z.infer<typeof ListGlobalBooksResponseSchema>

export interface ListGlobalBooksHandler
  extends Handler<ListGlobalBooksRequest, ListGlobalBooksResponse> {}

export const ListGlobalBooksApiSchema: ApiSchema = {
  query: ListGlobalBooksRequestQueryStringSchema,
  response: ListGlobalBooksResponseSchema,
}
