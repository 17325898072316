import { ContactCornSchema, ContactSchema } from '@eigtech/contacts-types'
import z from 'zod'

export const ScheduledInspectionSchema = z.object({
  claimNumber: z.string(),
  contactId: ContactCornSchema,
  dateInspectionScheduled: z.string().datetime(),
  policyHolder: ContactSchema.optional(),
  primaryContact: ContactSchema.optional(),
})

export type ScheduledInspection = z.infer<typeof ScheduledInspectionSchema>
