import { EventBase } from '@eigtech/event-stream-types'

import z from 'zod'
import {
  AppointmentChannelSchema,
  BaseJobSchema,
  CarrierInvoiceSchema,
  CarrierPaymentSchema,
  CustomerContactAttemptSchema,
  CustomerContactOutcomeSchema,
  DocumentTransitMethodSchema,
  JobAppointmentSchema,
  JobBillCollectionSchema,
  JobDepositOverrideApprovalSchema,
  JobDepositPaymentSchema,
  JobLocationSchema,
  JobSchema,
  JobServiceSchema,
  JobTypeSchema,
  SubcontractorDocumentKindSchema,
  SubcontractorIssuedDocumentSchema,
  SubcontractorPaymentSchema,
  SubcontractorReceivedDocumentSchema,
  SubcontractorSchema,
  WorkAuthorizationIdSchema,
  WorkAuthorizationRecipientSchema,
  WorkAuthorizationStatus,
} from '../base/index.js'
import { RestorationServiceEventBaseSchema, restorationServiceEventType } from './service.js'

export const jobEventType = <V extends string | undefined>(eventName?: V) =>
  restorationServiceEventType('job', eventName)

export const JobEventTypeSchema = z.enum([
  'appointmentCompleted',
  'appointmentScheduled',
  'billSentToCollections',
  'cancelled',
  'carrierInvoiced',
  'carrierMadePayment',
  'completed',
  'customerContactAttempted',
  'depositOverrideApproved',
  'depositPaid',
  'descriptionUpdated',
  'locationUpdated',
  'received',
  'servicesAdded',
  'servicesRemoved',
  'started',
  'subcontractorPaid',
  'subdocumentIssued',
  'subdocumentReceived',
  'withdrawn',
  'workAuthorizationCompleted',
  'workAuthorizationDeclined',
  'workAuthorizationExpired',
  'workAuthorizationOverrideApproved',
  'workAuthorizationReassigned',
  'workAuthorizationRecalled',
  'workAuthorizationSent',
  'workAuthorizationRecipientSigned',
  'reopened',
])
export const JobEventType = JobEventTypeSchema.Enum

export const JobEventSchema = RestorationServiceEventBaseSchema.merge(
  z.object({
    type: jobEventType(),
    job: JobSchema.and(z.unknown()),
    metadata: RestorationServiceEventBaseSchema.shape.metadata.and(
      z.object({
        jobType: JobTypeSchema,
        requestActor: z.string(),
      })
    ),
  })
)
export const isJobEvent = (event: EventBase) => JobEventSchema.safeParse(event).success

//
//
export const JobReceivedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.received),
    job: JobSchema,
  })
)
export const isJobReceivedEvent = (event: EventBase) =>
  JobReceivedEventSchema.safeParse(event).success

//
//
export const JobStartedEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobStartedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.started),
    job: JobSchema.and(JobStartedEventDetailsSchema),
  })
)
export const isJobStartedEvent = (event: EventBase) =>
  JobStartedEventSchema.safeParse(event).success

//
//
export const CustomerContactAttemptedEventDetailsSchema = CustomerContactAttemptSchema
export const CustomerContactAttemptedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.customerContactAttempted),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        contactOutcome: CustomerContactOutcomeSchema,
      })
    ),
    job: JobSchema.and(CustomerContactAttemptedEventDetailsSchema),
  })
)
export const isCustomerContactAttemptedEvent = (event: EventBase) =>
  CustomerContactAttemptedEventSchema.safeParse(event).success

//
//
export const JobAppointmentScheduledEventDetailsSchema = JobAppointmentSchema.omit({
  appointmentNotes: true,
})
export const JobAppointmentScheduledEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.appointmentScheduled),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        appointmentChannel: AppointmentChannelSchema,
      })
    ),
    job: JobSchema.and(JobAppointmentScheduledEventDetailsSchema),
  })
)
export const isJobAppointmentScheduledEvent = (
  event: EventBase
): event is JobAppointmentScheduledEvent =>
  JobAppointmentScheduledEventSchema.safeParse(event).success

//
//
export const JobAppointmentCompletedEventDetailsSchema =
  JobAppointmentScheduledEventDetailsSchema.merge(
    z.object({
      appointmentId: z.string().optional(),
      appointmentNotes: z.string().optional(),
    })
  )
export const JobAppointmentCompletedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.appointmentCompleted),
    metadata: JobAppointmentScheduledEventSchema.shape.metadata,
    job: JobAppointmentScheduledEventSchema.shape.job.and(
      JobAppointmentCompletedEventDetailsSchema
    ),
  })
)
export const isJobAppointmentCompletedEvent = (event: EventBase) =>
  JobAppointmentCompletedEventSchema.safeParse(event).success

//
//
export const JobSubcontractorDocumentIssuedEventDetailsSchema = z.object({
  subcontractorDocument: SubcontractorIssuedDocumentSchema,
  subcontractor: SubcontractorSchema,
})
export const JobSubcontractorDocumentIssuedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.subdocumentIssued),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentKind: SubcontractorDocumentKindSchema.or(z.string()),
        documentIssuedMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobSubcontractorDocumentIssuedEventDetailsSchema),
  })
)
export const isJobSubcontractorDocumentIssuedEvent = (
  event: EventBase
): event is JobSubcontractorDocumentIssuedEvent =>
  JobSubcontractorDocumentIssuedEventSchema.safeParse(event).success

//
//
export const JobSubcontractorDocumentReceivedEventDetailsSchema = z.object({
  subcontractorDocument: SubcontractorReceivedDocumentSchema,
  subcontractor: SubcontractorSchema,
})
export const JobSubcontractorDocumentReceivedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.subdocumentReceived),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentKind: SubcontractorDocumentKindSchema.or(z.string()),
        documentReceivedMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobSubcontractorDocumentReceivedEventDetailsSchema),
  })
)
export const isJobSubcontractorDocumentReceivedEvent = (
  event: EventBase
): event is JobSubcontractorDocumentReceivedEvent =>
  JobSubcontractorDocumentReceivedEventSchema.safeParse(event).success

//
//
export const JobWorkAuthorizationSentEventDetailsSchema = z.object({
  workAuthorization: z.object({
    sentDate: z.string().datetime(),
    recipient: WorkAuthorizationRecipientSchema,
    sentMethod: DocumentTransitMethodSchema,
    status: z.literal(WorkAuthorizationStatus.sent),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationSentEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationSent),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationSentEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationSentEvent = (
  event: EventBase
): event is JobWorkAuthorizationSentEvent =>
  JobWorkAuthorizationSentEventSchema.safeParse(event).success

//
//
export const JobWorkAuthorizationCompletedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    approvedBy: WorkAuthorizationRecipientSchema,
    dateClosed: z.string().datetime(),
    effectiveDate: z.string().datetime(),
    signer: WorkAuthorizationRecipientSchema,
    status: z.literal(WorkAuthorizationStatus.completed),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationCompletedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationCompleted),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationCompletedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationCompletedEvent = (
  event: EventBase
): event is JobWorkAuthorizationCompletedEvent =>
  JobWorkAuthorizationCompletedEventSchema.safeParse(event).success

//#region RecipientSigned
export const JobWorkAuthorizationRecipientSignedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    signedOn: z.string().datetime(),
    workAuthorizationId: WorkAuthorizationIdSchema,
    status: z.literal(WorkAuthorizationStatus.signed),
    signer: WorkAuthorizationRecipientSchema,
  }),
})
export const JobWorkAuthorizationRecipientSignedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationRecipientSigned),
    job: JobSchema.and(JobWorkAuthorizationRecipientSignedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationRecipientSignedEvent = (
  event: EventBase
): event is JobWorkAuthorizationRecipientSignedEvent =>
  JobWorkAuthorizationRecipientSignedEventSchema.safeParse(event).success
//#endregion RecipientSigned
//
//
export const JobWorkAuthorizationRecalledEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateClosed: z.string().datetime(),
    status: z.literal(WorkAuthorizationStatus.recalled),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationRecalledEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationRecalled),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationRecalledEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationRecalledEvent = (
  event: EventBase
): event is JobWorkAuthorizationRecalledEvent =>
  JobWorkAuthorizationRecalledEventSchema.safeParse(event).success

//
//
export const JobWorkAuthorizationExpiredEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateClosed: z.string().datetime(),
    status: z.literal(WorkAuthorizationStatus.expired),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationExpiredEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationExpired),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationExpiredEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationExpiredEvent = (
  event: EventBase
): event is JobWorkAuthorizationExpiredEvent =>
  JobWorkAuthorizationExpiredEventSchema.safeParse(event).success

//
//
export const JobWorkAuthorizationDeclinedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateClosed: z.string().datetime(),
    status: z.literal(WorkAuthorizationStatus.declined),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationDeclinedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationDeclined),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationDeclinedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationDeclinedEvent = (
  event: EventBase
): event is JobWorkAuthorizationDeclinedEvent =>
  JobWorkAuthorizationDeclinedEventSchema.safeParse(event).success

//
//
export const JobWorkAuthorizationReassignedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    dateReassigned: z.string().datetime(),
    recipient: WorkAuthorizationRecipientSchema,
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationReassignedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationReassigned),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        documentTransitMethod: DocumentTransitMethodSchema.or(z.string()),
      })
    ),
    job: JobSchema.and(JobWorkAuthorizationReassignedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationReassignedEvent = (
  event: EventBase
): event is JobWorkAuthorizationReassignedEvent =>
  JobWorkAuthorizationReassignedEventSchema.safeParse(event).success

//
//
export const JobWorkAuthorizationOverrideApprovedEventDetailsSchema = z.object({
  workAuthorization: z.object({
    effectiveDate: z.string().datetime(),
    approvedBy: WorkAuthorizationRecipientSchema,
    status: z.literal(WorkAuthorizationStatus.override_approved),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const JobWorkAuthorizationOverrideApprovedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.workAuthorizationOverrideApproved),
    job: JobSchema.and(JobWorkAuthorizationOverrideApprovedEventDetailsSchema),
  })
)
export const isJobWorkAuthorizationOverrideApprovedEvent = (
  event: EventBase
): event is JobWorkAuthorizationOverrideApprovedEvent =>
  JobWorkAuthorizationOverrideApprovedEventSchema.safeParse(event).success

//
//
export const JobCarrierInvoicedEventDetailsSchema = z.object({
  carrierInvoice: CarrierInvoiceSchema,
})
export const JobCarrierInvoicedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.carrierInvoiced),
    job: JobSchema.and(JobCarrierInvoicedEventDetailsSchema),
  })
)
export const isJobCarrierInvoicedEvent = (event: EventBase): event is JobCarrierInvoicedEvent =>
  JobCarrierInvoicedEventSchema.safeParse(event).success

//
//
export const JobCarrierMadePaymentEventDetailsSchema = z.object({
  carrierPayment: CarrierPaymentSchema,
})
export const JobCarrierMadePaymentEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.carrierMadePayment),
    job: JobSchema.and(JobCarrierMadePaymentEventDetailsSchema),
  })
)
export const isJobCarrierMadePaymentEvent = (
  event: EventBase
): event is JobCarrierMadePaymentEvent => JobCarrierMadePaymentEventSchema.safeParse(event).success

//
//
export const JobBillSentToCollectionsEventDetailsSchema = z.object({
  billCollection: JobBillCollectionSchema,
})
export const JobBillSentToCollectionsEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.billSentToCollections),
    job: JobSchema.and(JobBillSentToCollectionsEventDetailsSchema),
  })
)

//
//
export const JobSubcontractorPaidEventDetailsSchema = z.object({
  subcontractorPayment: SubcontractorPaymentSchema,
  subcontractor: SubcontractorSchema,
})
export const JobSubcontractorPaidEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.subcontractorPaid),
    job: JobSchema.and(JobSubcontractorPaidEventDetailsSchema),
  })
)
export const isJobSubcontractorPaidEvent = (event: EventBase): event is JobSubcontractorPaidEvent =>
  JobSubcontractorPaidEventSchema.safeParse(event).success

//
//
export const JobLocationUpdatedEventDetailsSchema = z.object({
  previousLocation: JobLocationSchema,
})
export const JobLocationUpdatedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.locationUpdated),
    job: JobSchema.and(JobLocationUpdatedEventDetailsSchema),
  })
)

//
//
export const JobDescriptionUpdatedEventDetailsSchema = z.object({
  previousDescription: BaseJobSchema.shape.jobDescription,
})
export const JobDescriptionUpdatedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.descriptionUpdated),
    job: JobSchema.and(JobDescriptionUpdatedEventDetailsSchema),
  })
)

//
//
export const JobCompletedEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobCompletedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.completed),
    job: JobSchema.and(JobCompletedEventDetailsSchema),
  })
)

//
//
export const JobWithdrawnEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobWithdrawnEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.withdrawn),
    job: JobSchema.and(JobWithdrawnEventDetailsSchema),
  })
)

//
//
export const JobReopenedEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobReopenedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.reopened),
    job: JobSchema.and(JobReopenedEventDetailsSchema),
  })
)

//
//
export const JobCancelledEventDetailsSchema = z.object({
  notes: z.string().optional(),
  occurredAt: z.string().datetime(),
})
export const JobCancelledEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.cancelled),
    job: JobSchema.and(JobCancelledEventDetailsSchema),
  })
)
export const isJobCancelledEvent = (event: EventBase): event is JobCancelledEvent =>
  JobCancelledEventSchema.safeParse(event).success

//
//
export const JobDepositPaidEventDetailsSchema = JobDepositPaymentSchema
export const JobDepositPaidEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.depositPaid),
    metadata: JobEventSchema.shape.metadata.and(
      z.object({
        paidInFull: z.boolean(),
      })
    ),
    job: JobSchema.and(JobDepositPaidEventDetailsSchema),
  })
)
export const isJobDepositPaidEvent = (event: EventBase): event is JobDepositPaidEvent =>
  JobDepositPaidEventSchema.safeParse(event).success

//
//
export const JobDepositOverrideApprovedEventDetailsSchema = JobDepositOverrideApprovalSchema
export const JobDepositOverrideApprovedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.depositOverrideApproved),
    job: JobSchema.and(JobDepositOverrideApprovedEventDetailsSchema),
  })
)

//
//
export const JobServicesAddedEventDetailsSchema = z.object({
  servicesAdded: JobServiceSchema.array(),
})
export const JobServicesAddedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.servicesAdded),
    job: JobSchema.and(JobServicesAddedEventDetailsSchema),
  })
)
export const isJobServicesAddedEvent = (event: EventBase) =>
  JobServicesAddedEventSchema.safeParse(event).success

//
//
export const JobServicesRemovedEventDetailsSchema = z.object({
  servicesRemoved: JobServiceSchema.array(),
})
export const JobServicesRemovedEventSchema = JobEventSchema.merge(
  z.object({
    type: jobEventType(JobEventType.servicesRemoved),
    job: JobSchema.and(JobServicesRemovedEventDetailsSchema),
  })
)
export const isJobServicesRemovedEvent = (event: EventBase) =>
  JobServicesRemovedEventSchema.safeParse(event).success

export interface JobEvent extends z.infer<typeof JobEventSchema> {}
export type JobEventTypeEnum = z.infer<typeof JobEventTypeSchema>

export interface JobStartedEventDetails extends z.infer<typeof JobStartedEventDetailsSchema> {}
export interface JobStartedEvent extends z.infer<typeof JobStartedEventSchema> {}
export interface CustomerContactAttemptedEventDetails
  extends z.infer<typeof CustomerContactAttemptedEventDetailsSchema> {}
export interface CustomerContactAttemptedEvent
  extends z.infer<typeof CustomerContactAttemptedEventSchema> {}
export interface JobAppointmentCompletedEventDetails
  extends z.infer<typeof JobAppointmentCompletedEventDetailsSchema> {}
export interface JobAppointmentCompletedEventDetails
  extends z.infer<typeof JobAppointmentCompletedEventDetailsSchema> {}
export interface JobAppointmentCompletedEvent
  extends z.infer<typeof JobAppointmentCompletedEventSchema> {}
export interface JobAppointmentScheduledEventDetails
  extends z.infer<typeof JobAppointmentScheduledEventDetailsSchema> {}
export interface JobAppointmentScheduledEvent
  extends z.infer<typeof JobAppointmentScheduledEventSchema> {}
export interface JobSubcontractorDocumentIssuedEventDetails
  extends z.infer<typeof JobSubcontractorDocumentIssuedEventDetailsSchema> {}
export interface JobSubcontractorDocumentIssuedEvent
  extends z.infer<typeof JobSubcontractorDocumentIssuedEventSchema> {}
export interface JobSubcontractorDocumentReceivedEventDetails
  extends z.infer<typeof JobSubcontractorDocumentReceivedEventDetailsSchema> {}
export interface JobSubcontractorDocumentReceivedEvent
  extends z.infer<typeof JobSubcontractorDocumentReceivedEventSchema> {}
export interface JobSubcontractorPaidEventDetails
  extends z.infer<typeof JobSubcontractorPaidEventDetailsSchema> {}
export interface JobSubcontractorPaidEvent
  extends z.infer<typeof JobSubcontractorPaidEventSchema> {}
export type JobWorkAuthorizationEvent =
  | JobWorkAuthorizationCompletedEvent
  | JobWorkAuthorizationDeclinedEvent
  | JobWorkAuthorizationExpiredEvent
  | JobWorkAuthorizationOverrideApprovedEvent
  | JobWorkAuthorizationReassignedEvent
  | JobWorkAuthorizationRecalledEvent
  | JobWorkAuthorizationSentEvent
export interface JobWorkAuthorizationSentEventDetails
  extends z.infer<typeof JobWorkAuthorizationSentEventDetailsSchema> {}
export interface JobWorkAuthorizationSentEvent
  extends z.infer<typeof JobWorkAuthorizationSentEventSchema> {}
export interface JobWorkAuthorizationCompletedEventDetails
  extends z.infer<typeof JobWorkAuthorizationCompletedEventDetailsSchema> {}
export interface JobWorkAuthorizationCompletedEvent
  extends z.infer<typeof JobWorkAuthorizationCompletedEventSchema> {}
export interface JobWorkAuthorizationRecipientSignedEventDetails
  extends z.infer<typeof JobWorkAuthorizationRecipientSignedEventDetailsSchema> {}
export interface JobWorkAuthorizationRecipientSignedEvent
  extends z.infer<typeof JobWorkAuthorizationRecipientSignedEventSchema> {}
export interface JobWorkAuthorizationDeclinedEventDetails
  extends z.infer<typeof JobWorkAuthorizationDeclinedEventDetailsSchema> {}
export interface JobWorkAuthorizationDeclinedEvent
  extends z.infer<typeof JobWorkAuthorizationDeclinedEventSchema> {}
export interface JobWorkAuthorizationExpiredEventDetails
  extends z.infer<typeof JobWorkAuthorizationExpiredEventDetailsSchema> {}
export interface JobWorkAuthorizationExpiredEvent
  extends z.infer<typeof JobWorkAuthorizationExpiredEventSchema> {}
export interface JobWorkAuthorizationReassignedEventDetails
  extends z.infer<typeof JobWorkAuthorizationReassignedEventDetailsSchema> {}
export interface JobWorkAuthorizationReassignedEvent
  extends z.infer<typeof JobWorkAuthorizationReassignedEventSchema> {}
export interface JobWorkAuthorizationRecalledEventDetails
  extends z.infer<typeof JobWorkAuthorizationRecalledEventDetailsSchema> {}
export interface JobWorkAuthorizationRecalledEvent
  extends z.infer<typeof JobWorkAuthorizationRecalledEventSchema> {}
export interface JobWorkAuthorizationOverrideApprovedEventDetails
  extends z.infer<typeof JobWorkAuthorizationOverrideApprovedEventDetailsSchema> {}
export interface JobWorkAuthorizationOverrideApprovedEvent
  extends z.infer<typeof JobWorkAuthorizationOverrideApprovedEventSchema> {}
export interface JobWorkAuthorizationRecipientSignedEvent
  extends z.infer<typeof JobWorkAuthorizationRecipientSignedEventSchema> {}
export interface JobCarrierMadePaymentEventDetails
  extends z.infer<typeof JobCarrierMadePaymentEventDetailsSchema> {}
export interface JobCarrierMadePaymentEvent
  extends z.infer<typeof JobCarrierMadePaymentEventSchema> {}
export interface JobCarrierInvoicedEventDetails
  extends z.infer<typeof JobCarrierInvoicedEventDetailsSchema> {}
export interface JobCarrierInvoicedEvent extends z.infer<typeof JobCarrierInvoicedEventSchema> {}
export interface JobBillSentToCollectionsEventDetails
  extends z.infer<typeof JobBillSentToCollectionsEventDetailsSchema> {}
export interface JobBillSentToCollectionsEvent
  extends z.infer<typeof JobBillSentToCollectionsEventSchema> {}
export interface JobReceivedEvent extends z.infer<typeof JobReceivedEventSchema> {}
export interface JobLocationUpdatedEventDetails
  extends z.infer<typeof JobLocationUpdatedEventDetailsSchema> {}
export interface JobLocationUpdatedEvent extends z.infer<typeof JobLocationUpdatedEventSchema> {}
export interface JobDescriptionUpdatedEventDetails
  extends z.infer<typeof JobDescriptionUpdatedEventDetailsSchema> {}
export interface JobDescriptionUpdatedEvent
  extends z.infer<typeof JobDescriptionUpdatedEventSchema> {}
export interface JobCompletedEventDetails extends z.infer<typeof JobCompletedEventDetailsSchema> {}
export interface JobCompletedEvent extends z.infer<typeof JobCompletedEventSchema> {}
export interface JobWithdrawnEventDetails extends z.infer<typeof JobWithdrawnEventDetailsSchema> {}
export interface JobWithdrawnEvent extends z.infer<typeof JobWithdrawnEventSchema> {}
export interface JobCancelledEventDetails extends z.infer<typeof JobCancelledEventDetailsSchema> {}
export interface JobCancelledEvent extends z.infer<typeof JobCancelledEventSchema> {}
export interface JobReopenedEventDetails extends z.infer<typeof JobReopenedEventDetailsSchema> {}
export interface JobReopenedEvent extends z.infer<typeof JobReopenedEventSchema> {}
export interface JobDepositPaidEventDetails
  extends z.infer<typeof JobDepositPaidEventDetailsSchema> {}
export interface JobDepositPaidEvent extends z.infer<typeof JobDepositPaidEventSchema> {}
export interface JobDepositOverrideApprovedEventDetails
  extends z.infer<typeof JobDepositOverrideApprovedEventDetailsSchema> {}
export interface JobDepositOverrideApprovedEvent
  extends z.infer<typeof JobDepositOverrideApprovedEventSchema> {}

export interface JobServicesAddedEventDetails
  extends z.infer<typeof JobServicesAddedEventDetailsSchema> {}
export interface JobServicesAddedEvent extends z.infer<typeof JobServicesAddedEventSchema> {}
export interface JobServicesRemovedEventDetails
  extends z.infer<typeof JobServicesRemovedEventDetailsSchema> {}
export interface JobServicesRemovedEvent extends z.infer<typeof JobServicesRemovedEventSchema> {}

export type JobEventDiscriminator =
  | JobReceivedEvent
  | JobStartedEvent
  | CustomerContactAttemptedEvent
  | JobAppointmentScheduledEvent
  | JobAppointmentCompletedEvent
  | JobSubcontractorDocumentIssuedEvent
  | JobSubcontractorDocumentReceivedEvent
  | JobWorkAuthorizationSentEvent
  | JobWorkAuthorizationRecalledEvent
  | JobWorkAuthorizationReassignedEvent
  | JobWorkAuthorizationDeclinedEvent
  | JobWorkAuthorizationExpiredEvent
  | JobWorkAuthorizationCompletedEvent
  | JobWorkAuthorizationOverrideApprovedEvent
  | JobWorkAuthorizationRecipientSignedEvent
  | JobLocationUpdatedEvent
  | JobDescriptionUpdatedEvent
  | JobCompletedEvent
  | JobWithdrawnEvent
  | JobCancelledEvent
  | JobReopenedEvent
  | JobDepositPaidEvent
  | JobDepositOverrideApprovedEvent
  | JobCarrierInvoicedEvent
  | JobCarrierMadePaymentEvent
  | JobServicesAddedEvent
  | JobServicesRemovedEvent
