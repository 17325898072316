import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'

export const JobClosedReasonEnumSchema = z.enum(['completed', 'withdrawn', 'cancelled'])
export type JobClosedReason = z.infer<typeof JobClosedReasonEnumSchema>

export const CloseJobRequestBodySchema = z.object({
  closedReason: JobClosedReasonEnumSchema,
  closingNotes: z.string().optional(),
  closedDate: z.string().datetime(),
})
export const CloseJobRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const CloseJobRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const CloseJobRequestSchema = CloseJobRequestBodySchema.merge(
  CloseJobRequestHeadersSchema
).merge(CloseJobRequestPathSchema)
export const CloseJobResponseSchema = z.void()
export const CloseJobApiSchema: ApiSchema = {
  body: CloseJobRequestBodySchema,
  headers: CloseJobRequestHeadersSchema,
  params: CloseJobRequestPathSchema,
}

export type CloseJobRequestBody = z.infer<typeof CloseJobRequestBodySchema>
export interface CloseJobRequest extends z.infer<typeof CloseJobRequestSchema> {}
export type CloseJobResponse = z.infer<typeof CloseJobResponseSchema>
export interface CloseJobHandler extends Handler<CloseJobRequest, CloseJobResponse> {}
