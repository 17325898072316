import { PublicationTargetsSchema } from '@eigtech/claims-v2-types'
import { DATE_TIME_INPUT_FORMAT, format } from '@eigtech/ui-shared-dates'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  WithDrawerErrorBoundaryProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { useCancelInspection, useCompleteInspection } from '@eigtech/ui-shared-timeline'
import { exhaustive } from 'exhaustive'
import { startCase, uniqBy } from 'lodash-es'
import { z } from 'zod'
import { PublicationTargetsField } from '../Fields/PublicationTargetsField'
import { ContactSchema } from '@eigtech/contacts-types'
import {
  ContactSelectField,
  useGetBook,
  useGetCsrBook,
  useGetFieldAdjusterBook,
  useGetReviewerBook,
} from '@eigtech/ui-shared-contacts'
import { useMemo } from 'react'
import { makeBookCorn } from '@eigtech/contacts-util'

export type UpdateInspectionDrawerProps = ComposedDrawerWrapperProps & {
  action: 'cancel' | 'complete'
  claimNumber: string
  inspectionId: string
}

export const UpdateInspectionDrawer = withDrawerErrorBoundary(
  function UpdateInspectionDrawer(props: UpdateInspectionDrawerProps) {
    const { action, claimNumber, inspectionId, ...drawerProps } = props

    const form = useForm({
      defaultValues: {
        date: format(new Date(), DATE_TIME_INPUT_FORMAT) as unknown as Date,
      },
    })

    const { data: claimBook } = useGetBook(makeBookCorn(claimNumber))
    const { data: csrBook } = useGetCsrBook()
    const { data: fieldAdjuster } = useGetFieldAdjusterBook()
    const { data: reviewerBook } = useGetReviewerBook()

    const contactOptions = useMemo(
      () =>
        uniqBy(
          [
            ...(claimBook?.contacts ?? []),
            ...(csrBook?.contacts ?? []),
            ...(fieldAdjuster?.contacts ?? []),
            ...(reviewerBook?.contacts ?? []),
          ],
          'contactId'
        ),
      [claimBook?.contacts, csrBook?.contacts, fieldAdjuster?.contacts, reviewerBook?.contacts]
    )

    const toast = useToast()

    const { mutateAsync: cancelInspection, isError: isCancelError } = useCancelInspection()
    const { mutateAsync: completeInspection, isError: isCompleteError } = useCompleteInspection()

    const isError = isCancelError || isCompleteError

    async function onSubmit({ actor, date, ...data }: UpdateInspectionForm) {
      const basePayload = {
        claimNumber,
        inspectionId,
      }

      const request = () =>
        exhaustive(action, {
          cancel: () =>
            cancelInspection({
              ...data,
              canceledBy: actor.contactId,
              dateCanceled: date.toISOString(),
              ...basePayload,
            }),
          complete: () =>
            completeInspection({
              ...data,
              completedBy: actor.contactId,
              dateCompleted: date.toISOString(),
              ...basePayload,
            }),
        })

      await request()

      toast({
        status: 'success',
        title: `Successfully ${pastTense[action]} inspection!`,
        description: 'It may take a few minutes for changes to be reflected.',
      })

      drawerProps.onClose()
    }

    return (
      <FormDrawer {...baseDrawerProps(props)} {...drawerProps} form={form} onSubmit={onSubmit}>
        <InputField label={`${pastTense[action]} Date`} name="date" type="datetime-local" />

        <ContactSelectField
          contacts={contactOptions}
          label={`${pastTense[action]} By`}
          name="actor"
        />

        <TextareaField label="Notes" name="notes" rows={6} />

        {action === 'complete' && <PublicationTargetsField claimNumber={claimNumber} />}

        {isError && (
          <ComposedAlert
            alert={{
              title: `Failed to ${action} inspection`,
              description:
                'Please try again. If the issue persists, please contact EIG Tech support.',
            }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  (props) => baseDrawerProps(props)
)

const baseDrawerProps = (props: UpdateInspectionDrawerProps): WithDrawerErrorBoundaryProps => ({
  title: `${startCase(props.action)} Inspection`,
})

const pastTense: Record<UpdateInspectionDrawerProps['action'], string> = {
  cancel: 'Canceled',
  complete: 'Completed',
}

const UpdateInspectionFormSchema = z.object({
  actor: ContactSchema,
  date: z.coerce.date(),
  notes: z.string(),
  publicationTargets: PublicationTargetsSchema.array().optional(),
})

type UpdateInspectionForm = z.infer<typeof UpdateInspectionFormSchema>

const { useForm, InputField, TextareaField } = createForm(
  UpdateInspectionFormSchema,
  'updateInspectionForm'
)
