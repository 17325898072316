import { Handler } from 'aws-lambda'
import z from 'zod'
import { ProducerUserTypesEnumSchema } from '../../base'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const AddUserByUserTypeRequestBodySchema = z.object({
  userId: z.string(),
  userType: ProducerUserTypesEnumSchema,
})

export const AddUserByUserTypeRequestSchema = AddUserByUserTypeRequestBodySchema
export const AddUserByUserTypeResponseSchema = z.void()
export const AddUserByUserTypeApiSchema = {
  body: AddUserByUserTypeRequestBodySchema,
} satisfies ApiSchema

export type AddUserByUserTypeRequest = z.infer<typeof AddUserByUserTypeRequestSchema>
export type AddUserByUserTypeRequestBody = z.infer<typeof AddUserByUserTypeRequestBodySchema>
export type AddUserByUserTypeResponse = z.infer<typeof AddUserByUserTypeResponseSchema>

export interface AddUserByUserTypeHandler
  extends Handler<AddUserByUserTypeRequest, AddUserByUserTypeResponse> {}
