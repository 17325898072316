import { z } from 'zod'
import { EndMeetingResponseSchema } from '../api/endMeeting.js'
import { JoinMeetingResponseSchema } from '../api/joinMeeting.js'
import { StartMeetingResponseSchema } from '../api/startMeeting.js'
import { UpdateMeetingRequestSchema } from '../api/updateMeeting.js'
import { MeetingInviteSchema } from '../base/meetingInvite.js'
import { MeetingsServiceEventBaseSchema, serviceEventType } from './service.js'

export const meetingEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('meeting', eventName)

export const MeetingEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType(),
    meeting: z.unknown(),
  })
)

export const MeetingStartedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType('started'),
    meeting: StartMeetingResponseSchema,
  })
)

export const MeetingEndedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType('ended'),
    meeting: EndMeetingResponseSchema,
  })
)

export const MeetingJoinedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType('joined'),
    meeting: JoinMeetingResponseSchema,
  })
)

export const MeetingInviteSentEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType('inviteSent'),
    meeting: MeetingInviteSchema,
  })
)

export const MeetingUpdatedEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: meetingEventType('descriptionUpdated'),
    meeting: UpdateMeetingRequestSchema,
  })
)

export type MeetingStartedEvent = z.infer<typeof MeetingStartedEventSchema>
export type MeetingEndedEvent = z.infer<typeof MeetingEndedEventSchema>
export type MeetingJoinedEvent = z.infer<typeof MeetingJoinedEventSchema>
export type MeetingInviteSentEvent = z.infer<typeof MeetingInviteSentEventSchema>
export type MeetingUpdatedEvent = z.infer<typeof MeetingUpdatedEventSchema>
