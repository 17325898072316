import { CurrentClaimCornSchema } from '@eigtech/shared-corn-helper'
import { CornSchema } from '@eigtech/shared-corn'
import { z } from 'zod'

export const CallbackRequestedRolesSchema = z.enum([
  'approver',
  'coordinator',
  'dispatcher',
  'fieldAdjuster',
  'projectManager',
])
export const CallbackRequestedTimeOfDaySchema = z.enum(['any', 'morning', 'afternoon', 'evening'])
export const CallbackQueryableStatusSchema = z.enum(['REQUESTED', 'PENDING', 'ATTEMPTED'])

export const CallbackUserSchema = z.object({
  auth0Id: z.string(),
  name: z.string(),
  contactMethod: z.union([
    z.object({
      email: z.string(),
    }),
    z.object({
      phone: z.string(),
      timeOfDay: CallbackRequestedTimeOfDaySchema,
    }),
  ]),
})

export const CallbackStatusEnum = z.enum(['REQUESTED', 'COMPLETED', 'PENDING', 'ATTEMPTED'])
export const CallbackRequestSchemaV1 = z.object({
  createdDate: z.string(),
  lastUpdatedDate: z.string().optional(),
  claimNumber: CurrentClaimCornSchema.describe('deprecated'),
  status: CallbackStatusEnum,
  role: CallbackRequestedRolesSchema,
  notes: z.string().optional(),
  user: CallbackUserSchema,
  expiresAt: z.number().optional(),
})

export const CallbackRequestSchemaV2 = CallbackRequestSchemaV1.merge(
  z.object({ entityId: CornSchema })
).omit({ claimNumber: true })

export const CallbackRequestSchema = CallbackRequestSchemaV2.or(CallbackRequestSchemaV1).transform(
  (callback, ctx) => {
    if ('entityId' in callback) return callback
    if ('claimNumber' in callback) {
      const { claimNumber, ...rest } = callback
      return { ...rest, entityId: claimNumber }
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Entity id is required`,
      path: ['entityId'],
    })
    return z.NEVER
  }
)

export const QueryModelSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  'GSI-PK-1': z.string().optional(),
  'GSI-SK-1': z.string().optional(),
  'GSI-PK-2': z.string().optional(),
  'GSI-SK-2': z.string().optional(),
})

export type CallbackRequestedRoles = z.infer<typeof CallbackRequestedRolesSchema>
export type CallbackRequestedTimeOfDay = z.infer<typeof CallbackRequestedTimeOfDaySchema>
export type CallbackQueryableStatus = z.infer<typeof CallbackQueryableStatusSchema>

export type CallbackRequestV1 = z.infer<typeof CallbackRequestSchemaV1>
export type CallbackRequestV2 = z.infer<typeof CallbackRequestSchemaV2>
export type CallbackRequest = z.infer<typeof CallbackRequestSchema>

export type CallbackUser = z.infer<typeof CallbackUserSchema>
export type QueryModel = z.infer<typeof QueryModelSchema>

export const CallbackRequestQueryModelSchema = CallbackRequestSchema.and(QueryModelSchema)
export type CallbackRequestQueryModel = z.infer<typeof CallbackRequestQueryModelSchema>
