import { useGetAuth0UserName } from '@eigtech/ui-shared-api'
import { forwardRef, Skeleton, SkeletonProps, Text, TextProps } from '@eigtech/ui-shared-dave'

export type Auth0UserNameProps = {
  user: string | undefined
  skeletonProps?: SkeletonProps
} & TextProps

export const Auth0UserName = forwardRef<Auth0UserNameProps, 'p'>(
  ({ user, skeletonProps, ...props }, ref) => {
    const { data, isError, isPending } = useGetAuth0UserName(user)

    return (
      <Skeleton as="span" isLoaded={!isPending} {...skeletonProps}>
        <Text ref={ref} {...props} as="span">
          {isPending ? 'Loading...' : isError ? user : data}
        </Text>
      </Skeleton>
    )
  }
)
