import { ClaimDefaultChronicleTypeEnum } from '../../claimDefaultCompiler'

type ClaimDefaultChronicleLabelMap = {
  [key in ClaimDefaultChronicleTypeEnum]: string
}

export const claimDefaultChronicleLabelMap: ClaimDefaultChronicleLabelMap = {
  canceled: 'Canceled',
  carrierApprovedEstimate: 'Carrier Reviewed',
  carrierApprovedEstimateWithExceptions: 'Carrier Reviewed with Exceptions',
  carrierAssignedToClaim: 'Carrier Assigned to Claim',
  carrierRejected: 'Carrier Rejected',
  carrierRejectedEstimate: 'Carrier Rejected',
  carrierReviewed: 'Carrier Reviewed',
  carrierReviewedWithExceptions: 'Carrier Reviewed with Exceptions',
  carrierUnassignedFromClaim: 'Carrier Unassigned from Claim',
  catCodeUpdated: 'CAT Code Updated',
  clientApproved: 'Client Approved',
  clientRejected: 'Client Rejected',
  closed: 'Claim Closed',
  coordinatorAssignedToClaim: 'Coordinator Assigned',
  coordinatorUnassignedFromClaim: 'Coordinator Unassigned from Claim',
  customerContactedEmail: 'Customer Contacted - Email',
  customerContactedLvm: 'Customer Contacted - LVM',
  customerNoContact: 'Customer No Contact',
  dateReceived: 'Date Received',
  estimateReceived: 'Estimate Uploaded',
  estimateRevisionRequested: 'Estimate Revision Requested',
  estimateSentToCarrier: 'Estimate Sent to Carrier',
  fieldAdjusterAssignedToClaim: 'Field Adjuster Assigned',
  fieldAdjusterContactedCustomer: 'Customer Contacted - Field Adjuster',
  fieldAdjusterCorrectionUploaded: 'Correction Uploaded',
  fieldAdjusterEstimateUploaded: 'Estimate Uploaded',
  fieldAdjusterUnassignedFromClaim: 'Field Adjuster Unassigned from Claim',
  inAssignQueue: 'In Assign Queue',
  initialCustomerContactAttempted: 'Initial Customer Contact Attempted',
  initialCustomerContactFailed: 'Initial Customer Contact Failed',
  initialCustomerContactSuccess: 'Initial Customer Contact Success',
  inspectionCanceled: 'Inspection Canceled',
  inspectionCompleted: 'Inspection Completed',
  inspectionScheduled: 'Inspection Scheduled',
  invalidContactInformation: 'Invalid Contact Information',
  inspectionTechnicianAssignedToClaim: 'Inspection Technician Assigned to Claim',
  inspectionTechnicianUnassignedFromClaim: 'Inspection Technician Unassigned from Claim',
  jobAssignedToClaim: 'Job Assigned to Claim',
  jobCompleted: 'Job Completed',
  jobContracted: 'Job Started',
  jobNotSold: 'Job Not Sold',
  jobSold: 'Job Sold',
  jobStarted: 'Job Started',
  jobUnassignedFromClaim: 'Job Unassigned from Claim',
  legalRepAssignedToClaim: 'Legal Rep Assigned to Claim',
  legalRepUnassignedFromClaim: 'Legal Rep Unassigned from Claim',
  lossDate: 'Loss Date',
  lossOfUseAdded: 'Loss Of Use Added',
  lossOfUseApproved: 'Loss Of Use Approved',
  lossOfUseCanceled: 'Loss Of Use Canceled',
  lossOfUseDenied: 'Loss Of Use Denied',
  lossOfUsePending: 'Loss Of Use Pending',
  lossOfUseReceiptAdded: 'Loss Of Use Receipt Added',
  lossOfUseReceiptRemoved: 'Loss Of Use Receipt Removed',
  lossSeverityUpdated: 'Loss Severity Updated',
  lossTypeUpdated: 'Loss Type Updated',
  markedForPublication: 'Marked for Publication',
  policyHolderAssignedToClaim: 'Policy Holder Assigned to Claim',
  policyHolderUnassignedFromClaim: 'Policy Holder Unassigned from Claim',
  primaryContactAssignedToClaim: 'Primary Contact Assigned to Claim',
  primaryContactUnassignedFromClaim: 'Primary Contact Unassigned from Claim',
  projectManagerAssignedToClaim: 'Project Manager Assigned',
  projectManagerUnassignedFromClaim: 'Project Manager Unassigned from Claim',
  qaApproved: 'QA Approved',
  qaApprovedPreliminaryReport: 'QA Approved Preliminary Report',
  qaApprovedSupplementReport: 'QA Approved Supplement Report',
  qaRejected: 'QA Rejected',
  reInspectionRequested: 'Inspection Requested',
  reopened: 'Claim Reopened',
  reviewCompleted: 'Review Completed',
  reviewerAssignedToClaim: 'Reviewer Assigned',
  reviewerUnassignedFromClaim: 'Reviewer Unassigned from Claim',
  reviewRequested: 'Review Requested',
  surveySentToCustomer: 'Survey Sent to Customer',
}
