import z from 'zod'
import { ClaimV1ChronicleType, EstimateChronicleType } from './claimChronicles'
import { Chronicle } from './timeline'

export const ClaimReviewerChronicleTypeSchema = z.enum([
  //#region estimate events
  EstimateChronicleType.qaApproved,
  EstimateChronicleType.qaRejected,
  //#endregion estimate events
  //#region claim v1 events
  ClaimV1ChronicleType.qaApprovedSupplementReport,
  //#endregion claim v1 eventgs
])

export const ClaimReviewerChronicleType = ClaimReviewerChronicleTypeSchema.Enum
export const isClaimReviewerChronicle = (
  chronicle: Pick<Chronicle, 'type'>
): chronicle is Chronicle & { type: ClaimReviewerChronicleTypeEnum } =>
  ClaimReviewerChronicleTypeSchema.safeParse(chronicle.type).success

export type ClaimReviewerChronicleTypeEnum = z.infer<typeof ClaimReviewerChronicleTypeSchema>
