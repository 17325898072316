import {
  RequestClaimLossReportRequest,
  RequestClaimLossReportResponse,
} from '@eigtech/riddler-types'
import { useMutation } from '@eigtech/ui-shared-api'
import { post } from '../instance'
import { riddlerBasePath } from './constants'
import { useInvalidateLatestLossReport } from './getLatestLossReport'

const requestLossReport = async ({ entityId }: RequestClaimLossReportRequest) => {
  await post<RequestClaimLossReportResponse, RequestClaimLossReportRequest>(
    `${riddlerBasePath}/lossreport/${entityId}`,
    {
      entityId,
    }
  )
}

export const useRequestLossReport = () => {
  const invalidateQueries = useInvalidateLatestLossReport()

  return useMutation({
    mutationFn: requestLossReport,
    onSuccess(__, { entityId }) {
      invalidateQueries(entityId)
    },
  })
}
