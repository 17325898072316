import { Locale, startOfWeek } from '@eigtech/ui-shared-dates'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

export type WeeklyCalendarState = {
  week: Date
  selectedDay?: Date
  locale?: Locale
  changeSelectedDay: (day?: Date) => any
}

const WeeklyContext = createContext<WeeklyCalendarState | undefined>(undefined)

export function useWeeklyCalendar() {
  const context = useContext(WeeklyContext)

  if (!context) {
    throw new Error('useWeeklyCalendar needs to be used with the WeeklyCalendar component')
  }

  return context
}

export type WeeklyCalendarProps = {
  week: Date
  children: ReactNode
  locale?: Locale
}

export function WeeklyCalendar({ locale, week, children }: WeeklyCalendarProps) {
  const [selectedDay, setSelectedDay] = useState<Date>()

  //clear the selected day if the week changes
  useEffect(() => {
    setSelectedDay(undefined)
  }, [week])

  return (
    <WeeklyContext.Provider
      value={{
        locale,
        selectedDay,
        week: startOfWeek(week),
        changeSelectedDay: setSelectedDay,
      }}
    >
      {children}
    </WeeklyContext.Provider>
  )
}
