import z from 'zod'
import {
  InboxSchema,
  MessageGroupSchema,
  MessagingEventSchema,
  messagingServiceEventType,
} from '../base'

const messageGroupEventType = <V extends string | undefined>(eventName?: V) =>
  messagingServiceEventType('messageGroup', eventName)

export const MessageGroupUpsertEventSchema = MessagingEventSchema.merge(
  z.object({
    type: messageGroupEventType('upsert'),
    messageGroup: MessageGroupSchema,
  })
)

export const MessageGroupRemovedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: messageGroupEventType('removed'),
    messageGroup: MessageGroupSchema.pick({ groupId: true }),
  })
)

export const MessageGroupInboxAddedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: messageGroupEventType('inboxAdded'),
    messageGroup: MessageGroupSchema.pick({ groupId: true }).merge(
      InboxSchema.pick({ inboxId: true })
    ),
  })
)

export const MessageGroupInboxRemovedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: messageGroupEventType('inboxRemoved'),
    messageGroup: MessageGroupSchema.pick({ groupId: true }).merge(
      InboxSchema.pick({ inboxId: true })
    ),
  })
)

export type MessageGroupUpsertEvent = z.infer<typeof MessageGroupUpsertEventSchema>
export type MessageGroupRemovedEvent = z.infer<typeof MessageGroupRemovedEventSchema>
export type MessageGroupInboxAddedEvent = z.infer<typeof MessageGroupInboxAddedEventSchema>
export type MessageGroupInboxRemovedEvent = z.infer<typeof MessageGroupInboxRemovedEventSchema>
