import { ClaimLossType } from '@eigtech/claims-v2-types'
import { XALossTypeEnumSchema } from '@eigtech/verisk-edi-interface-types'
import { startCase } from 'lodash-es'

const lossTypeLabelRecord: Record<ClaimLossType, string> = {
  [XALossTypeEnumSchema.Values['DRAIN BCK']]: 'Drain Backup',
  [XALossTypeEnumSchema.Values['ICE/SNOW']]: 'Ice/Snow',
}

export const getLossTypeLabel = (lossType: ClaimLossType) =>
  lossTypeLabelRecord[lossType] ??
  (lossType.toLocaleUpperCase() === lossType ? startCase(lossType.toLocaleLowerCase()) : lossType)
