import {
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputInput,
  NumberInputStepper,
} from '@eigtech/ui-shared-dave'
import { FC, ReactNode } from 'react'
import { FieldValues, get, useController } from 'react-hook-form'
import {
  NumberInputControlProps as BaseNumberInputControlProps,
  FormControl,
} from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type NumberInputFieldProps<TFieldValues extends FieldValues = FieldValues> =
  WithFieldNameProp<NumberInputControlProps, TFieldValues>

export function NumberInputField<TFieldValues extends FieldValues = FieldValues>({
  children,
  numberInputProps,
  ...props
}: NumberInputFieldProps<TFieldValues>) {
  return (
    <NumberInputControl
      numberInputProps={{
        isDisabled: props.isDisabled,
        isReadOnly: props.isReadOnly,
        width: 'full',
        ...numberInputProps,
      }}
      {...props}
    >
      {children}
    </NumberInputControl>
  )
}

export type NumberInputFieldComponent<TFieldValues extends FieldValues = FieldValues> = FC<
  NumberInputFieldProps<TFieldValues>
>

//

type NumberInputControlProps = BaseNumberInputControlProps & {
  leftAddon?: ReactNode
  rightAddon?: ReactNode
  leftElement?: ReactNode
  rightElement?: ReactNode
}

const NumberInputControl: FC<NumberInputControlProps> = (props: NumberInputControlProps) => {
  const {
    name,
    control,
    label,
    showStepper = true,
    children,
    numberInputProps,
    leftAddon,
    leftElement,
    rightAddon,
    rightElement,
    ...rest
  } = props
  const {
    field,
    fieldState: { isTouched },
    formState: { isSubmitting, errors },
  } = useController({
    name,
    control,
    defaultValue: numberInputProps?.defaultValue || '',
  })
  const error = get(errors, name, '')
  const { ref, ...restField } = field

  return (
    <FormControl control={control} label={label} name={name} {...rest}>
      <InputGroup>
        {typeof leftAddon === 'string' ? <InputLeftAddon>{leftAddon}</InputLeftAddon> : leftAddon}
        {typeof leftElement === 'string' ? (
          <InputLeftElement>{leftElement}</InputLeftElement>
        ) : (
          leftElement
        )}

        <NumberInput
          {...restField}
          id={name}
          isDisabled={isSubmitting}
          isInvalid={!!error && isTouched}
          {...numberInputProps}
          value={field.value ?? ''}
        >
          <NumberInputInput
            ref={ref}
            name={name}
            {...(leftAddon ? { borderLeftRadius: 0 } : {})}
            {...(leftElement ? { pl: 'var(--input-height)' } : {})}
            {...(rightAddon ? { borderRightRadius: 0 } : {})}
            {...(rightElement ? { pr: 'calc(var(--input-height) + var(--chakra-sizes-6))' } : {})}
          />
          {showStepper && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
          {children}
        </NumberInput>

        {typeof rightElement === 'string' ? (
          <InputRightElement right="var(--chakra-sizes-6)">{rightElement}</InputRightElement>
        ) : (
          rightElement
        )}
        {typeof rightAddon === 'string' ? (
          <InputRightAddon>{rightAddon}</InputRightAddon>
        ) : (
          rightAddon
        )}
      </InputGroup>
    </FormControl>
  )
}
