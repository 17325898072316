import z from 'zod'
import {
  ArchivedInboxMessageSchema,
  Auth0InboxMessageSchema,
  InboxMessageSchema,
  MessagingEventSchema,
  messagingServiceEventType,
} from '../base'

const inboxMessageEventType = <V extends string | undefined>(eventName?: V) =>
  messagingServiceEventType('inboxMessage', eventName)

export const InboxMessageEventSchema = MessagingEventSchema.merge(
  z.object({
    type: inboxMessageEventType('created'),
    message: InboxMessageSchema,
  })
)

export const InboxMessageStatusUpdatedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: inboxMessageEventType('updated'),
    message: Auth0InboxMessageSchema.pick({ inboxId: true, messageStatus: true, messageId: true }),
  })
)

export const InboxAllMessageStatusesUpdatedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: inboxMessageEventType('allUpdated'),
    message: Auth0InboxMessageSchema.pick({ inboxId: true, messageStatus: true }),
  })
)

export const InboxMessageArchivedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: inboxMessageEventType('archived'),
    message: ArchivedInboxMessageSchema.pick({ inboxId: true, messageId: true }),
  })
)

export const InboxAllMessagesArchivedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: inboxMessageEventType('allArchived'),
    message: ArchivedInboxMessageSchema.pick({ inboxId: true }),
  })
)

export const InboxMessageUnarchivedEventSchema = MessagingEventSchema.merge(
  z.object({
    type: inboxMessageEventType('unarchived'),
    message: ArchivedInboxMessageSchema.pick({ messageId: true }),
  })
)

export type InboxMessageEvent = z.infer<typeof InboxMessageEventSchema>
export type InboxMessageStatusUpdatedEvent = z.infer<typeof InboxMessageStatusUpdatedEventSchema>
export type InboxAllMessageStatusesUpdatedEvent = z.infer<
  typeof InboxAllMessageStatusesUpdatedEventSchema
>
export type InboxMessageArchivedEvent = z.infer<typeof InboxMessageArchivedEventSchema>
export type InboxAllMessageArchivedEvent = z.infer<typeof InboxAllMessagesArchivedEventSchema>
export type InboxMessageUnarchivedEvent = z.infer<typeof InboxMessageUnarchivedEventSchema>
