import { Corn, CornSchema } from '../types/Corn.js'

/**
 * @example
 * // returns true
 * isCorn('corn:claims:claim:223SSU6SKL')
 * // returns false
 * isCorn('223SSU6SKL')
 * // returns false
 * isCorn('claims:claim:223SSU6SKL')
 * @param corn String to verify meets our corn standards.
 * @returns Whether string is valid corn or not.
 */
export function isCorn(corn: string): corn is Corn {
  return CornSchema.safeParse(corn).success
}
