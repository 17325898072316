import {
  ClaimCorn,
  ClaimStatusCorn,
  LossDetailsCorn,
  LossLocationCorn,
  claimResourceType,
  serviceName as claimsServiceName,
} from '@eigtech/claims-types'
import { getCornKernels, isCorn } from '@eigtech/shared-corn'

export const ensureClaimCorn = (claimNumber: string | ClaimCorn): ClaimCorn =>
  isCorn(claimNumber) ? (claimNumber as ClaimCorn) : makeClaimCorn(claimNumber)
export const makeClaimCorn = (resourceId: string): ClaimCorn => `corn:claims:claim:${resourceId}`
export const makeLossLocationCorn = (resourceId: string): LossLocationCorn =>
  `corn:claims:lossLocation:${resourceId}`
export const makeLossDetailsCorn = (resourceId: string): LossDetailsCorn =>
  `corn:claims:lossDetails:${resourceId}`
export const makeClaimStatusCorn = (resourceId: string): ClaimStatusCorn =>
  `corn:claims:status:${resourceId}`
export const isClaimCorn = (entityId: string) => {
  if (!isCorn(entityId)) return false

  const { serviceName, resourceType } = getCornKernels(entityId)
  return serviceName === claimsServiceName && resourceType === claimResourceType
}
