import { useAuth0 } from '@eigtech/ui-shared-auth'
import { MeetingStatus, useMeetingStatus } from 'amazon-chime-sdk-component-library-react'
import { pick } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useEndMeeting, useLeaveMeeting } from '../api'
import { NonNullMeetingState, useMeetingStoreDangerously } from '../context'

export function useEndMeetingHelper() {
  const { meetingDetails } = useMeetingStoreDangerously(stateSelector)

  const { mutateAsync, isPending: isEnding } = useEndMeeting()
  const { mutateAsync: leaveMeeting, isPending: isLeaving } = useLeaveMeeting()

  const meetingStatusEnum = useMeetingStatus()
  const isInMeeting = useMemo(
    () => meetingStatusEnum === MeetingStatus.Succeeded,
    [meetingStatusEnum]
  )
  const isPending = useMemo(() => isLeaving || isEnding, [isLeaving, isEnding])

  const { user } = useAuth0()
  const isOrganizer = useMemo(
    () => meetingDetails?.organizer === user?.sub,
    [meetingDetails?.organizer, user?.sub]
  )
  const canEndMeeting = useMemo(
    () => !!(isOrganizer && isInMeeting && meetingDetails?.startTime),
    [isInMeeting, isOrganizer, meetingDetails?.startTime]
  )

  const endMeeting = useCallback(() => {
    if (canEndMeeting) {
      mutateAsync(pick(meetingDetails, ['startTime', 'entityId', 'meetingId']))
    } else {
      leaveMeeting()
    }
  }, [canEndMeeting, mutateAsync, meetingDetails, leaveMeeting])

  return {
    isPending,
    isOrganizer,
    canEndMeeting,
    canLeaveMeeting: isInMeeting,
    endMeeting,
    leaveMeeting,
  }
}

const stateSelector = (state: NonNullMeetingState) => pick(state, ['meetingDetails'])
