/**
 * Returns a new object with nested values removed when they match any value included in `valuesToOmit`
 * @param obj The object that needs purified
 * @param valuesToOmit Values to not include in returned object
 * @returns The object with all matching params removed (note: does not preserve Set type(s))
 *
 * ```ts
 * const myObj = { a: 1, b: '', c: 'Hi' }
 * const noEmptyStrings = omitDeep(myObj, [''])
 * console.log(noEmptyStrings)
 * // OUTPUT: { a: 1, c: 'Hi' }
 * ```
 */
export const omitDeep = <T extends object>(
  obj: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valuesToOmit: Array<any>
): T => {
  return JSON.parse(
    JSON.stringify(obj, (__key, value) => {
      if (value instanceof Set) {
        value = Array.from(value)
      }
      return valuesToOmit.some((omitValue) => omitValue === value) ? undefined : value
    })
  )
}

export const omitNullDeep = <T extends object>(obj: T): T => {
  return omitDeep<T>(obj, [null])
}

export const omitEmptyStringDeep = <T extends object>(obj: T): T => {
  return omitDeep<T>(obj, [''])
}

export const objHasKeys = <T extends object>(obj: T) => Object.keys(obj).length > 0
