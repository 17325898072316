import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, InboxSchema } from '../../base/index.js'

export const GetInboxRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const GetInboxRequestPathSchema = z.object({ inboxId: Auth0IdSchema })

export const GetInboxRequestSchema = GetInboxRequestPathSchema.merge(GetInboxRequestHeaderSchema)

export type GetInboxRequestPath = z.infer<typeof GetInboxRequestPathSchema>
export type GetInboxRequest = z.infer<typeof GetInboxRequestSchema>

export const GetInboxResponseSchema = InboxSchema

export type GetInboxResponse = z.infer<typeof GetInboxResponseSchema>

export interface GetInboxHandler extends Handler<GetInboxRequest, GetInboxResponse> {}
