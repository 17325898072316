import { ComposedCard, Container, Text } from '@eigtech/ui-shared-dave'

export function Forbidden() {
  return (
    <Container maxW="container.text" my="16">
      <ComposedCard heading="Error">
        <Text mb="4">You do not have permissions to view this page.</Text>
      </ComposedCard>
    </Container>
  )
}
