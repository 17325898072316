import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'restoration'
export const restorationServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const RestorationServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: restorationServiceEventType(),
  })
)

export type RestorationServiceEventBase = z.infer<typeof RestorationServiceEventBaseSchema>
