import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { PortalUserSchema } from '../../base/user.js'

export const UpdatePortalUserRequestBodySchema = PortalUserSchema.pick({
  auth0Id: true,
  email: true,
  externalUserId: true,
  name: true,
}).merge(
  z.object({
    entityPermissions: PortalUserSchema.shape.entityPermissions.optional(),
    portalUserId: PortalUserSchema.shape.portalUserId.optional(),
    requestActor: z.string(),
  })
)

export const UpdatePortalUserRequestSchema = UpdatePortalUserRequestBodySchema

export const UpdatePortalUserResponseSchema = z.object({ user: PortalUserSchema.or(z.null()) })

export type UpdatePortalUserRequestBody = z.infer<typeof UpdatePortalUserRequestBodySchema>
export type UpdatePortalUserRequest = z.infer<typeof UpdatePortalUserRequestSchema>

export type UpdatePortalUserResponse = z.infer<typeof UpdatePortalUserResponseSchema>

export interface UpdatePortalUserHandler
  extends Handler<UpdatePortalUserRequest, UpdatePortalUserResponse> {}

export const UpdatePortalUserApiSchema: ApiSchema = {
  body: UpdatePortalUserRequestBodySchema,
  response: UpdatePortalUserResponseSchema,
}
