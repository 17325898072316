import z from 'zod'
import { cornType } from '@eigtech/shared-corn'

export * from './message.js'
export * from './service.js'
export * from './assignments.js'

export const messagingServiceCorn = <V extends string | undefined>(eventName?: V) =>
  cornType('messages', eventName)

export const MessagingServiceCornSchema = messagingServiceCorn()
export type MessagingServiceCorn = z.infer<typeof MessagingServiceCornSchema>
