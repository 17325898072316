import { z } from 'zod'
import {
  BookSchema,
  BookTypeEnumSchema,
  GlobalBookCornCanonSchema,
  GlobalBookCornLaxSchema,
} from '../base/books.js'
import { BookContactQueryModelSchema } from './contact.js'

export const BookQueryModelSchema = BookSchema.extend({
  PK: z.string(), //'BOOK#<<bookId>>'
  SK: z.string(), //'<<bookId>>'
  'GSI-PK-1': BookTypeEnumSchema,
  'GSI-SK-1': z.string(), //'<<bookId>>'
  contacts: z.record(z.string(), BookContactQueryModelSchema),
})

export const GlobalBookQueryModelLaxSchema = BookSchema.extend({
  bookId: GlobalBookCornLaxSchema,
  PK: z.string(), //'BOOK#<<bookId>>'
  SK: z.string(), //'<<bookId>>'
  'GSI-PK-1': z.literal(BookTypeEnumSchema.Values['global']),
  'GSI-SK-1': z.string(), //'<<bookId>>'
  contacts: z.record(z.string(), BookContactQueryModelSchema),
})

export const GlobalBookQueryModelCanonSchema = BookSchema.extend({
  bookId: GlobalBookCornCanonSchema,
  PK: z.string(), //'BOOK#<<bookId>>'
  SK: z.string(), //'<<bookId>>'
  'GSI-PK-1': z.literal(BookTypeEnumSchema.Values['global']),
  'GSI-SK-1': z.string(), //'<<bookId>>'
  contacts: z.record(z.string(), BookContactQueryModelSchema),
})

export type BookQueryModel = z.infer<typeof BookQueryModelSchema>
export type GlobalBookQueryModelCanon = z.infer<typeof GlobalBookQueryModelCanonSchema>
export type GlobalBookQueryModelLax = z.infer<typeof GlobalBookQueryModelLaxSchema>
