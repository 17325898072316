import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { DocumentTransitMethodSchema, JobIdSchema } from '../../base/index.js'

export const RecordWorkAuthorizationSentRequestBodySchema = z.object({
  workAuthorization: z.object({
    sentDate: z.string().datetime(),
    recipient: ContactCornSchema.or(z.string()),
    requestId: z.string(),
    sentMethod: DocumentTransitMethodSchema,
  }),
})
export const RecordWorkAuthorizationSentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordWorkAuthorizationSentRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordWorkAuthorizationSentRequestSchema =
  RecordWorkAuthorizationSentRequestBodySchema.merge(
    RecordWorkAuthorizationSentRequestHeadersSchema
  ).merge(RecordWorkAuthorizationSentRequestPathSchema)
export const RecordWorkAuthorizationSentResponseSchema = z.object({
  jobId: JobIdSchema,
  workAuthorization: z.object({
    workAuthorizationId: z.string(),
    sentDate: z.string().datetime(),
    recipient: ContactCornSchema.or(z.string()),
    sentMethod: DocumentTransitMethodSchema,
  }),
})
export const RecordWorkAuthorizationSentApiSchema: ApiSchema = {
  body: RecordWorkAuthorizationSentRequestBodySchema,
  headers: RecordWorkAuthorizationSentRequestHeadersSchema,
  params: RecordWorkAuthorizationSentRequestPathSchema,
  response: RecordWorkAuthorizationSentResponseSchema,
}

export interface RecordWorkAuthorizationSentRequestBody
  extends z.infer<typeof RecordWorkAuthorizationSentRequestBodySchema> {}
export interface RecordWorkAuthorizationSentRequestPath
  extends z.infer<typeof RecordWorkAuthorizationSentRequestPathSchema> {}
export interface RecordWorkAuthorizationSentRequest
  extends z.infer<typeof RecordWorkAuthorizationSentRequestSchema> {}
export type RecordWorkAuthorizationSentResponse = z.infer<
  typeof RecordWorkAuthorizationSentResponseSchema
>
export interface RecordWorkAuthorizationSentHandler
  extends Handler<RecordWorkAuthorizationSentRequest, RecordWorkAuthorizationSentResponse> {}
