import { NoteTemplate } from '@eigtech/templates-types'
import { ContextualCan } from '@eigtech/ui-shared-auth'
import { HStack, IconButton, Tooltip } from '@eigtech/ui-shared-dave'
import { ReactSelectField, useFormContext } from '@eigtech/ui-shared-forms'
import { LinkIconButton, useRoutesContext } from '@eigtech/ui-shared-router'
import { useGetTemplates } from '@eigtech/ui-shared-templates'
import { has, isEmpty, set, template } from 'lodash-es'
import { BiLinkExternal } from 'react-icons/bi'
import { MdRefresh } from 'react-icons/md'
import { NoteFieldsType } from './NoteFieldsSchema'

export type NoteTemplateFieldProps = {
  templateParams?: Record<string, unknown>
}

export function NoteTemplateField(props: NoteTemplateFieldProps) {
  return (
    <ContextualCan I="read" a="template">
      <Component {...props} />
    </ContextualCan>
  )
}

function Component({ templateParams }: NoteTemplateFieldProps) {
  const form = useFormContext<NoteFieldsType>()
  const { templates: templatesPath } = useRoutesContext()

  const {
    data: templates = [],
    isLoading: isLoadingTemplates,
    refetch: refetchTemplates,
  } = useGetTemplates({
    type: 'templates:types:note',
  })

  function handleTemplateSelected(selectedTemplate: NoteTemplate | null | undefined) {
    if (!selectedTemplate) return

    if (!('textTemplate' in selectedTemplate)) return

    if (!isEmpty(selectedTemplate.textTemplateParams)) {
      const validatedParams = { ...templateParams }

      selectedTemplate.textTemplateParams?.map((param) => {
        const found = has(validatedParams, param)
        if (!found) {
          set(validatedParams, param, `<%= ${param} %>`)
        }
      })

      const compiledTemplate = template(selectedTemplate.textTemplate)
      const compiledBody = compiledTemplate(validatedParams)

      form.setValue('text', compiledBody, { shouldValidate: true })
      return
    }

    form.setValue('text', selectedTemplate.textTemplate, { shouldValidate: true })
  }

  return (
    <HStack alignItems="flex-end">
      <ReactSelectField
        getOptionLabel={(template) => template.name}
        getOptionValue={(template) => template.id}
        isClearable
        isLoading={isLoadingTemplates}
        label="Note Template"
        name="template"
        options={templates as NoteTemplate[]}
        onChange={handleTemplateSelected}
      />

      <Tooltip label="Refetch templates">
        <IconButton
          aria-label="Refetch templates."
          icon={<MdRefresh />}
          variant="outline"
          onClick={() => refetchTemplates()}
        />
      </Tooltip>

      {templatesPath && (
        <Tooltip label="Go to templates">
          <LinkIconButton
            aria-label="Go to templates."
            icon={<BiLinkExternal />}
            target="templates"
            to={templatesPath}
            variant="outline"
          />
        </Tooltip>
      )}
    </HStack>
  )
}
