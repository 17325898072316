import { isClaimCorn } from '@eigtech/claims-types'
import { Corn, getResourceId } from '@eigtech/shared-corn'
import { useGetClaim } from '../api'
import { DelegateAdminClaim } from '../types/DelegateAdminClaim'
import { EntityAdminClaim } from '../types/EntityAdminClaim'
import { canPublishClaimInfoToXA } from '../utils'

export type UseCanPublishClaimInfoToVeriskProps =
  | { claim: DelegateAdminClaim | EntityAdminClaim | undefined }
  | { claimNumber: string }
  | { claimCorn: Corn }

export function useCanPublishClaimInfoToXA(props: UseCanPublishClaimInfoToVeriskProps) {
  const claimNumber =
    'claimNumber' in props
      ? props.claimNumber
      : 'claimCorn' in props
        ? // only get resource id if for sure is claim corn
          isClaimCorn(props.claimCorn)
          ? getResourceId(props.claimCorn)
          : ''
        : // use empty string if no claimNumber or claimCorn in props
          ''

  const { data: claim } = useGetClaim<DelegateAdminClaim | EntityAdminClaim>(claimNumber)

  return canPublishClaimInfoToXA('claim' in props ? props.claim : claim)
}
