import { CohortEnumSchema } from '@eigtech/auth0-types'
import z from 'zod'
import { MeetingsDataSourceSchema } from './dataSource.js'

export const ScreenshotSchema = z.object({
  author: z.string().optional(),
  cohort: CohortEnumSchema,
  dateAdded: z.string().datetime(),
  description: z.string().optional(),
  label: z.string().optional(),
  filename: z.string(),
  meetingId: z.string(),
  notes: z.string(),
  objectKey: z.string(),
  source: MeetingsDataSourceSchema,
  publishedOn: z.string().datetime().optional(),
})

export type Screenshot = z.infer<typeof ScreenshotSchema>
