import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import { ClaimSchema } from '../aggregates/claim.js'
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js'

const claimEventType = <V extends string | undefined>(eventName?: V) =>
  claimsServiceEventType('claim', eventName)

export const ClaimEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: claimEventType(),
    claim: z.unknown(),
    schemaVersion: z.literal(1).or(z.literal(2)),
  })
)

export const ClaimCreatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType('created'),
    claim: ClaimSchema,
  })
)

export const ClaimUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType('updated'),
    claim: ClaimSchema,
  })
)

export const isClaimCreatedEvent = (event: EventBase): event is ClaimCreatedEvent =>
  ClaimCreatedEventSchema.safeParse(event).success
export const isClaimUpdatedEvent = (event: EventBase): event is ClaimUpdatedEvent =>
  ClaimUpdatedEventSchema.safeParse(event).success

export type ClaimEvent = z.infer<typeof ClaimEventSchema>
export type ClaimCreatedEvent = z.infer<typeof ClaimCreatedEventSchema>
export type ClaimUpdatedEvent = z.infer<typeof ClaimUpdatedEventSchema>
