import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const ListOrgsRequestSchema = z.void()

export const ListOrgsResponseSchema = z.object({
  orgs: z.array(z.object({ name: z.string(), value: z.string() })),
})

export const ListOrgsApiSchema = {
  response: ListOrgsResponseSchema,
} satisfies ApiSchema

export type ListOrgsRequest = z.infer<typeof ListOrgsRequestSchema>
export type ListOrgsResponse = z.infer<typeof ListOrgsResponseSchema>
export type ListOrgsHandler = Handler<ListOrgsRequest, ListOrgsResponse>
