import { getDateForFilename } from '@eigtech/ui-shared-dates'
import { RefObject } from 'react'

export type UseGetFileFromCanvasProps = {
  canvasRef: RefObject<HTMLCanvasElement | null>
  meetingId: string
}

export async function getFileFromCanvas({ canvasRef, meetingId }: UseGetFileFromCanvasProps) {
  const canvasContext = canvasRef.current?.getContext('2d')
  if (!canvasContext) throw new Error('Missing canvas context')

  const blob = await toBlob(canvasContext.canvas)

  const date = getDateForFilename()
  const filename = `${meetingId}__${date}.png`

  return new File([blob], filename, {
    lastModified: Date.now(),
    type: 'image/png',
  })
}

const toBlob = (canvas: HTMLCanvasElement) =>
  new Promise<Blob>((resolve, reject) =>
    canvas.toBlob((blob) => (!!blob ? resolve(blob) : reject(new Error('Invalid image data'))))
  )
