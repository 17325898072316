import {
  DocumentCorn,
  ExportImagesRequest,
  GetUploadUrlRequest,
  ListDocumentsRequest,
} from '@eigtech/documents-types'
import { Corn } from '@eigtech/shared-corn'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'

export const documentsBasePath = 'documents/v2'

const { all, list } = makeBasicQueryKeys('documents')

export const documentsQueryKeys = {
  all,

  list,
  imagesExport: (entityId: ExportImagesRequest['entityId']) => [
    ...documentsQueryKeys.entityDocuments(entityId),
    'export',
  ],
  entityDocuments: (entityId: ListDocumentsRequest['entityId']) => [
    ...documentsQueryKeys.list(),
    entityId,
  ],
  entityDocumentsWithTags: (entityId: ListDocumentsRequest['entityId']) => [
    ...documentsQueryKeys.entityDocuments(entityId),
    'tags',
  ],

  documents: () => [...all(), 'documents'],
  document: ({ documentId, entityId }: { documentId: DocumentCorn; entityId: Corn }) => [
    ...documentsQueryKeys.documents(),
    { documentId, entityId },
  ],
  documentPublications: (req: { documentId: DocumentCorn; entityId: Corn }) => [
    ...documentsQueryKeys.document(req),
    'publications',
  ],
  documentUrl: (req: { documentId: DocumentCorn; entityId: Corn }) => [
    ...documentsQueryKeys.document(req),
    'documentUrl',
  ],
  uploadUrl: ({ cohort, entityId }: GetUploadUrlRequest) => [
    ...documentsQueryKeys.all(),
    'upload',
    { cohort, entityId },
  ],
}
