export function getCreatedByLabel(label: string) {
  switch (label) {
    case 'contactsEvent':
    case 'CONTACT_MERGED':
      return 'Automated Process'
    case 'System Generated':
      return 'EIG Systems'
    default:
      return label
  }
}
