import { CohortEnum } from '@eigtech/auth0-types'
import { ListDocumentsRequest, ListDocumentsResponse } from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { Corn } from '@eigtech/shared-corn'
import { contextualGet, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { getTagsByResourceQueryOptions } from '@eigtech/ui-shared-tags'
import { useMemo } from 'react'
import { DocumentV2WithTags } from '../types/DocumentV2WithTags'
import { documentsBasePath, documentsQueryKeys } from './constants'

const getEntityDocuments = ({ cohort, entityId }: ListDocumentsRequest) =>
  contextualGet<ListDocumentsResponse>(`${documentsBasePath}/${cohort}/${entityId}`)

const getEntityDocumentsQueryOptions = (props: ListDocumentsRequest) => ({
  queryKey: documentsQueryKeys.entityDocuments(props.entityId),
  queryFn: () => getEntityDocuments(props),
})

export const useGetEntityDocuments = (props: ListDocumentsRequest) =>
  useQuery(getEntityDocumentsQueryOptions(props))

export const useGetCsrEntityDocuments = (entityId: ListDocumentsRequest['entityId']) =>
  useGetEntityDocuments({ entityId, cohort: 'csr' })

export const useGetCsrEntityDocumentsWithTags = (entityId: ListDocumentsRequest['entityId']) => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: documentsQueryKeys.entityDocumentsWithTags(entityId),
    queryFn: async () => {
      const entityDocuments = await queryClient.fetchQuery(
        getEntityDocumentsQueryOptions({ entityId, cohort: 'csr' })
      )

      const documentsWithTags = (await Promise.all(
        entityDocuments.map(async (document) => ({
          ...document,
          tags: await queryClient.fetchQuery(
            getTagsByResourceQueryOptions({
              resourceCorn: ensureDocumentCorn(document.id),
            })
          ),
        }))
      )) satisfies DocumentV2WithTags[]

      return documentsWithTags
    },
  })
}

export const useGetEstimatorEntityDocuments = (entityId: ListDocumentsRequest['entityId']) =>
  useGetEntityDocuments({ entityId, cohort: 'estimator' })

export function useGetTplapEntityDocuments(
  cohort: Extract<CohortEnum, 'insured' | 'technician'>,
  entityId: Corn
) {
  return useGetEntityDocuments({ entityId, cohort })
}

export function useGetEntityDocument(props: ListDocumentsRequest & { documentId: string }) {
  const query = useGetEntityDocuments(props)

  const document = useMemo(
    () => query.data?.find((document) => document.id === props.documentId),
    [props.documentId, query.data]
  )

  return {
    document,
    query,
  }
}

export function useInvalidateEntityDocuments() {
  const queryClient = useQueryClient()

  return function invalidateEntityDocuments(entityId: Corn) {
    queryClient.invalidateQueries({ queryKey: documentsQueryKeys.entityDocuments(entityId) })
  }
}
