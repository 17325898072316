import { NoteDataSourceEnum } from '@eigtech/notes-types'
import { ReactNode, createContext, useContext } from 'react'

export type NotesContext = {
  dataSource: NoteDataSourceEnum
}

export function NotesProvider({
  children,
  dataSource,
}: {
  children: ReactNode
} & NotesContext) {
  return <NotesContext.Provider value={{ dataSource }}>{children}</NotesContext.Provider>
}

export function useNotesContext() {
  const routes = useContext(NotesContext)

  if (!routes) {
    throw new Error('NotesProvider required to use useNotesContext')
  }

  return routes
}

// Context
const NotesContext = createContext<NotesContext | null>(null)
