import { ReactNode, createContext, useCallback, useContext } from 'react'
import { FieldArrayPath, FieldArrayWithId, FieldValues, useFormContext } from 'react-hook-form'
import { useArrayFieldContext } from './ArrayFieldContext'

export type ArrayFieldRowContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = {
  field: FieldArrayWithId<TFieldValues, TFieldArrayName, TKeyName>
  index: number
  isFirst: boolean
  isLast: boolean
  name: `${TFieldArrayName}.${number}`
  remove: () => void
}

const ArrayFieldRowContext = createContext<ArrayFieldRowContext | undefined>(undefined)

export type ArrayFieldRowProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = {
  children: ReactNode
  field: FieldArrayWithId<TFieldValues, TFieldArrayName, TKeyName>
  index: number
}

export function ArrayFieldRowProvider<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  children,
  index,
  field,
  ...value
}: ArrayFieldRowProviderProps<TFieldValues, TFieldArrayName, TKeyName>) {
  const { clearErrors } = useFormContext()
  const {
    fields,
    name,
    remove: baseRemove,
  } = useArrayFieldContext<TFieldValues, TFieldArrayName, TKeyName>()

  const remove = useCallback(() => {
    clearErrors(`${name}.${index}`)
    baseRemove(index)
  }, [baseRemove, clearErrors, index, name])

  return (
    <ArrayFieldRowContext.Provider
      value={{
        ...value,
        field: field as unknown as FieldArrayWithId,
        index,
        isFirst: index === 0,
        isLast: index === fields.length - 1,
        name: `${name}.${index}`,
        remove,
      }}
    >
      {children}
    </ArrayFieldRowContext.Provider>
  )
}

export function useArrayFieldRowContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>() {
  const context = useContext(ArrayFieldRowContext)

  if (!context) {
    throw new Error('ArrayFieldRowProvider required to use useArrayFieldRowContext')
  }

  return context as unknown as ArrayFieldRowContext<TFieldValues, TFieldArrayName, TKeyName>
}
