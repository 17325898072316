import { Operator as BaseOperator } from '@eigtech/summarizer-types'
import { pick } from 'lodash-es'

export type OperatorKey = Exclude<BaseOperator, 'ILIKE' | 'NOT ILIKE'> | 'WITHIN'

export type Operator =
  | {
      label: string
      value: BaseOperator
    }
  | {
      label: string
      value: OperatorKey
      filter: BaseOperator
    }

export const operators = {
  '=': {
    label: 'Equal to',
    value: '=',
  },
  '<': {
    label: 'Less than',
    value: '<',
  },
  '>': {
    label: 'Greater than',
    value: '>',
  },
  '>=': {
    label: 'Greater than or equal to',
    value: '>=',
  },
  '<=': {
    label: 'Less than or equal to',
    value: '<=',
  },
  '!=': {
    label: 'Not equal to',
    value: '!=',
  },
  IS: {
    label: 'Is',
    value: 'IS',
  },
  'IS NOT': {
    label: 'Is Not',
    value: 'IS NOT',
  },
  LIKE: {
    label: 'Matches',
    value: 'LIKE',
  },
  'NOT LIKE': {
    label: 'Does not match',
    value: 'NOT LIKE',
  },
  WITHIN: {
    label: 'Within the last',
    value: 'WITHIN',
    filter: '>=',
  },
  INCLUDES: {
    label: 'Includes',
    value: 'INCLUDES',
  },
  ANYOF: {
    label: 'Any Of',
    value: 'ANYOF',
  },
} as const satisfies Record<OperatorKey, Operator>

export const stringOperators = Object.values(pick(operators, ['=', '!=']))

export const likeOperators = Object.values(pick(operators, ['LIKE', 'NOT LIKE']))

export const dateOperators = [
  ...Object.values(pick(operators, ['WITHIN', '<', '>', '>=', '<=', 'LIKE', 'NOT LIKE'])),
]

export const arrayOperators = Object.values(pick(operators, ['INCLUDES']))
export const booleanOperators = Object.values(pick(operators, ['=', '!=']))

export const isOperators = Object.values(pick(operators, ['IS']))
export const isNotOperators = Object.values(pick(operators, ['IS NOT']))

export function getFieldsByType<Field extends string = string>(
  fieldOperators: Partial<Record<Field, Operator[]>>
) {
  const dateFields: Field[] = Object.entries(fieldOperators)
    .filter((operator) => operator[1] === dateOperators)
    .map(([key]) => key as Field)

  const isFields: Field[] = Object.entries(fieldOperators)
    .filter((operator) => operator[1] === isOperators)
    .map(([key]) => key as Field)

  const stringFields: Field[] = Object.entries(fieldOperators)
    .filter((operator) => operator[1] === stringOperators)
    .map(([key]) => key as Field)

  return { dateFields, isFields, stringFields }
}
