import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema } from '../../../base/contacts.js'

export const RestoreContactRequestPathSchema = z.object({
  contactId: ContactCornSchema,
})
export const RestoreContactRequestHeaderSchema = z.object({ requestActor: z.string().optional() })
export const RestoreContactRequestSchema = RestoreContactRequestPathSchema.merge(
  RestoreContactRequestHeaderSchema
)
export type RestoreContactRequestPath = z.infer<typeof RestoreContactRequestPathSchema>
export type RestoreContactRequestHeader = z.infer<typeof RestoreContactRequestHeaderSchema>
export type RestoreContactRequest = z.infer<typeof RestoreContactRequestSchema>
export type RestoreContactResponse = void
export interface RestoreContactHandler
  extends Handler<RestoreContactRequest, RestoreContactResponse> {}

export const RestoreContactApiSchema: ApiSchema = {
  headers: RestoreContactRequestHeaderSchema,
  params: RestoreContactRequestPathSchema,
}
