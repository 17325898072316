import {
  GetAssignmentsForAssignableRequest,
  GetAssignmentsForAssignableResponse,
} from '@eigtech/assignments-types'
import { contextualGet } from '@eigtech/ui-shared-api'
import { assignmentsBasePath } from './constants'

export const getAssignmentsForAssignable = ({ assignableId }: GetAssignmentsForAssignableRequest) =>
  contextualGet<GetAssignmentsForAssignableResponse>(
    `${assignmentsBasePath}/assignable/${assignableId}`
  )
