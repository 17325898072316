import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

export type NewClaimReviewRecord = NewTableRow<typeof claimReviewsTable>
export type ClaimReviewRecord = TableRow<typeof claimReviewsTable>

const claimReviewsTable = defineTable('claim_reviews', {
  claim_number: Schema.String,
  reviewer_id: Schema.String,
  name: Schema.String,
})
