import { CohortEnumSchema } from '@eigtech/auth0-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const ExportImagesPathRequestSchema = z.object({
  entityId: CornSchema,
  cohort: CohortEnumSchema,
})
export type ExportImagesPathRequest = z.infer<typeof ExportImagesPathRequestSchema>

export const ExportImagesRequestSchema = ExportImagesPathRequestSchema
export const ExportImagesResponseSchema = z.string()

export type ExportImagesRequest = z.infer<typeof ExportImagesRequestSchema>
export type ExportImagesResponse = z.infer<typeof ExportImagesResponseSchema>
export type ExportImagesHandler = Handler<ExportImagesRequest, ExportImagesResponse>
