import { DocumentV2, videoTypeRegex } from '@eigtech/documents-types'
import { DataGrid, DataGridProps, Table } from '@eigtech/ui-shared-data-grid'
import { Image, Skeleton, chakra } from '@eigtech/ui-shared-dave'
import {
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { useGetDocumentUrl } from '../api/getDocumentUrl'
import { useOrganizeMedia } from '../hooks/useOrganizeDocuments'
import { DocumentV2WithTags } from '../types/DocumentV2WithTags'
import {
  DocumentColumn,
  documentColumnHelper,
  hiddenDocumentColumns,
  useSharedDocumentsColumns,
} from './DocumentsTableColumns'

export type MediaTableProps = {
  media: DocumentV2WithTags[] | undefined
  enableBulkActions: boolean
  ActionMenu?: FC<{ document: DocumentColumn }>
  hideColumns?: Partial<Record<keyof ReturnType<typeof useSharedDocumentsColumns>, boolean>>
} & Omit<DataGridProps<DocumentColumn>, 'data' | 'columns'>

export const MediaTable = forwardRef(function MediaTable(
  { media, ActionMenu, enableBulkActions, hideColumns, ...tableProps }: MediaTableProps,
  ref: ForwardedRef<Table<DocumentColumn>>
) {
  const tableRef = useRef<Table<DocumentColumn>>(null!)
  useImperativeHandle(ref, () => ({
    ...tableRef.current,
  }))

  const columns = useMediaColumns({
    ActionMenu,
    enableBulkActions,
    hideColumns,
  })

  const groupedMedia = useOrganizeMedia(media)

  return (
    <DataGrid
      ref={tableRef}
      {...tableProps}
      columns={columns}
      data={groupedMedia}
      getSubRows={(row) => row.subRows}
      initialState={hiddenDocumentColumns}
      state={tableProps.state}
    />
  )
})

export function useMediaColumns({
  ActionMenu,
  enableBulkActions,
  hideColumns,
}: Pick<MediaTableProps, 'enableBulkActions' | 'hideColumns'> & {
  ActionMenu?: FC<{ document: DocumentColumn }>
}) {
  const sharedColumns = useSharedDocumentsColumns()

  return useMemo(
    () => [
      ...(enableBulkActions ? [sharedColumns.actionMenu({ ActionMenu })] : []),
      ...(enableBulkActions && !hideColumns?.importStatus ? [sharedColumns.importStatus] : []),
      ...(!hideColumns?.title ? [sharedColumns.title] : []),
      ...(!hideColumns?.description ? [sharedColumns.description] : []),
      ...(!hideColumns?.filename ? [sharedColumns.filename] : []),
      ...(!hideColumns?.visibility ? [sharedColumns.visibility] : []),
      ...(!hideColumns?.exportHistory ? [sharedColumns.exportHistory] : []),
      ...(!hideColumns?.createdDate ? [sharedColumns.createdDate] : []),
      ...(!hideColumns?.modifiedDate ? [sharedColumns.modifiedDate] : []),
      ...(!hideColumns?.type ? [sharedColumns.type] : []),
      ...(!hideColumns?.source ? [sharedColumns.source] : []),
      ...(!hideColumns?.dates ? [sharedColumns.dates] : []),
      ...(!hideColumns?.meta ? [sharedColumns.meta] : []),
      ...(!hideColumns?.tags && sharedColumns.tags ? [sharedColumns.tags] : []),
      documentColumnHelper.display({
        id: 'download',
        header: '',
        maxSize: 80,
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        cell: (cell) => <Media {...cell.row.original} />,
      }),
    ],
    [ActionMenu, enableBulkActions, hideColumns, sharedColumns]
  )
}

function Media({ id, entityId, filename }: DocumentV2) {
  const { data: url, refetch } = useGetDocumentUrl({ entityId, documentId: id })

  useEffect(() => {
    refetch()
  }, [refetch])

  return filename.match(videoTypeRegex) ? (
    <chakra.video autoPlay muted src={url} w="full" />
  ) : (
    <chakra.a href={url} target={id}>
      <Image fallback={<Skeleton h="20" w="20" />} minW="20" src={url} />
    </chakra.a>
  )
}
