import {
  ContactRoleProducer as BaseContactRole,
  GlobalBookResourceIdCanon,
} from '@eigtech/contacts-types'

const globalBookToRoleMap = {
  carrier: 'carrier',
  csr: 'csr',
  deskAdjuster: 'deskAdjuster',
  fieldAdjuster: 'fieldAdjuster',
  fieldTechnician: 'fieldTechnician',
  projectManager: 'projectManager',
  reviewer: 'reviewer',
  subcontractor: 'subcontractor',
} satisfies Record<GlobalBookResourceIdCanon, BaseContactRole>

export const getDefaultRoleFromBookName = (bookName: string) => {
  const role = globalBookToRoleMap[bookName as GlobalBookResourceIdCanon]

  return role as typeof role | undefined
}
