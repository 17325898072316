import { GetProfilePhotoUploadUrlResponse } from '@eigtech/auth0-types'
import { contextualGet, queryOptions, useUploadFile } from '@eigtech/ui-shared-api'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  DropzoneFilePreview,
  DropzoneRef,
  useToast,
} from '@eigtech/ui-shared-dave'
import { DropzoneField, FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { useRef } from 'react'
import { z } from 'zod'

type UserProfileImageDrawerProps = ComposedDrawerWrapperProps

export function UserProfileImageDrawer({ ...drawerProps }: UserProfileImageDrawerProps) {
  const dropzoneRef = useRef<DropzoneRef>(null!)

  const toast = useToast()

  const { mutateAsync: uploadProfileImage, isError } = useUploadUserProfileImage()

  async function onSubmit(data: UserProfileImageForm) {
    const file = data.file[0]
    if (!file) return

    await uploadProfileImage({ file })

    toast({
      status: 'success',
      title: 'Successfully uploaded profile image!',
      description: 'It will take a few minutes for changes to take effect.',
    })

    drawerProps.onClose()
  }

  const form = useForm()

  return (
    <FormDrawer {...drawerProps} form={form} title="Update Profile Image" onSubmit={onSubmit}>
      {isError && (
        <ComposedAlert
          alert={{
            title: 'Something went wrong',
            description:
              'File could not be uploaded. Please try again. If the issue persists, contact EIG customer service.',
          }}
          status="error"
        />
      )}

      <DropzoneField
        accept={ACCEPTED_FILE_TYPES.join(', ')}
        dropzoneRef={dropzoneRef}
        filesPreview={(value, { removeFile }) => (
          <>
            {!!value.length && (
              <DropzoneFilePreview file={value[0]} onRemove={() => removeFile(0)} />
            )}
          </>
        )}
        maxFiles={1}
        name="file"
        rootProps={{ h: '52' }}
      />
    </FormDrawer>
  )
}
const FileSchema = z
  .instanceof(File)
  .refine((file) => file.size < MAX_FILE_SIZE, `File size must be less than 20MB.`)
  .refine(
    (file) => ACCEPTED_FILE_TYPES.includes(file.type),
    'Only jpeg and png file types are accepted.'
  )

const UserProfileImageFormSchema = z.object({
  file: z
    .array(FileSchema)
    .refine((files) => files?.length !== 0, 'Files are required.')
    .refine((files) => files?.length === 1, 'Can only upload one file.')
    .transform((files) => Array.from(files)),
})

type UserProfileImageForm = z.infer<typeof UserProfileImageFormSchema>

const formId = 'userProfileImageForm'

const { useForm } = createForm(UserProfileImageFormSchema, formId)

const MAX_FILE_SIZE = 20 * 1000 * 1000

const ACCEPTED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  // 'image/heic',
  // 'image/gif',
]

const uploadUserProfileImageQueryOptions = () =>
  queryOptions({
    queryKey: ['user', 'all', 'profile-upload'],
    queryFn: () => contextualGet<GetProfilePhotoUploadUrlResponse>('auth/upload-url'),
  })

export const useUploadUserProfileImage = () => useUploadFile(uploadUserProfileImageQueryOptions())
