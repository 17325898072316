import { ButtonGroup, ButtonGroupProps, forwardRef, useBreakpointValue } from '@chakra-ui/react'
import { ReactNode } from 'react'

export type PageToolbarBaseProps = { toolbarItems: ReactNode[] }

export type PageToolbarProps = ButtonGroupProps & PageToolbarBaseProps

export const PageToolbar = forwardRef<PageToolbarProps, 'div'>(
  ({ toolbarItems, ...props }, ref) => {
    const orientation = useBreakpointValue<'horizontal' | 'vertical'>({
      base: 'vertical',
      md: 'horizontal',
    })

    const size = useBreakpointValue<'md' | 'sm'>({
      base: 'sm',
      md: 'md',
    })
    return (
      <ButtonGroup ref={ref} orientation={orientation} size={size} variant="outline" {...props}>
        {toolbarItems.map((item) => item)}
      </ButtonGroup>
    )
  }
)
