import { extendTheme, theme as baseTheme } from '@eigtech/ui-shared-dave'

type Dict<T = any> = Record<string, T>

export const theme: Dict = extendTheme(baseTheme, {
  components: {
    Drawer: {
      sizes: {
        nav: {
          dialog: { maxW: 64 },
        },
      },
    },
  },
})
