import { CurrentClaimCornSchema } from '@eigtech/shared-corn-helper'
import { ContactCornSchema, ContactSchema } from '@eigtech/contacts-types'
import { JobCornSchema, JobIdSchema, JobSchema } from '@eigtech/restoration-types'
import z from 'zod'

/**
 * Enums
 */
export const AssignableTypeEnumSchema = z.enum(['claim', 'job'])
export const AssignableTypeEnum = AssignableTypeEnumSchema.Enum

// Assignables have assignees
// E.G. claims have contacts
export type AssignableType = z.infer<typeof AssignableTypeEnumSchema>

export const AssigneeTypeEnumSchema = z.enum(['contact', 'job'])
export const AssigneeTypeEnum = AssigneeTypeEnumSchema.Enum

// Assignees are assigned to assignables
// E.g. contacts are assigned to claims
export type AssigneeType = z.infer<typeof AssigneeTypeEnumSchema>

export const DeprecatedClaimAssigneeRelationshipSchema = z.enum(['fileReviewer'])
export const ClaimAssigneeRelationshipProducerSchema = z.enum([
  'carrier',
  'coordinator',
  'fieldAdjuster',
  'inspectionTechnician',
  'job',
  'legalRep',
  'policyHolder',
  'primaryContact',
  'projectManager',
  'reviewer',
])

export const ClaimAssigneeRelationshipLaxSchema = z.enum([
  ...ClaimAssigneeRelationshipProducerSchema.options,
  ...DeprecatedClaimAssigneeRelationshipSchema.options,
])

export const ClaimAssigneeRelationshipCanonSchema = z
  .enum([...ClaimAssigneeRelationshipLaxSchema.options])
  .transform((relationship) => {
    switch (relationship) {
      case 'fileReviewer':
        return ClaimAssigneeRelationshipProducerSchema.Enum.reviewer
      default:
        return relationship
    }
  })

export type ClaimAssigneeRelationshipCanon = z.infer<typeof ClaimAssigneeRelationshipCanonSchema>
export type ClaimAssigneeRelationshipLax = z.infer<typeof ClaimAssigneeRelationshipLaxSchema>

export const ClaimAssigneeRelationshipCanonEnum = ClaimAssigneeRelationshipProducerSchema.Enum
export const ClaimAssigneeRelationshipLaxEnum = ClaimAssigneeRelationshipLaxSchema.Enum

export type ClaimAssigneeProducerRelationship = z.infer<
  typeof ClaimAssigneeRelationshipProducerSchema
>

export const JobAssigneeRelationshipEnumSchema = z.enum([
  'approver',
  'coordinator',
  'dispatcher',
  'fieldTechnician',
  'primaryContact',
  'projectManager',
  'subcontractor',
])
export const JobAssigneeRelationshipEnum = JobAssigneeRelationshipEnumSchema.Enum

export type JobAssigneeRelationship = z.infer<typeof JobAssigneeRelationshipEnumSchema>
/**
 * Assignees
 */
export const ContactAssigneeSchema = z.object({
  type: z.literal(AssigneeTypeEnum.contact),
  assigneeId: ContactCornSchema,
  contact: ContactSchema,
})

export const JobAssigneeSchema = z.object({
  type: z.literal(AssigneeTypeEnum.job),
  assigneeId: JobCornSchema,
  job: JobSchema.and(z.object({ isClosed: z.boolean().optional() })),
})

export const AssigneeSchema = z.discriminatedUnion('type', [
  ContactAssigneeSchema,
  JobAssigneeSchema,
])

export type ContactAssignee = z.infer<typeof ContactAssigneeSchema>
export type JobAssignee = z.infer<typeof JobAssigneeSchema>
export type Assignee = z.infer<typeof AssigneeSchema>
/**
 * Assignable Entities
 */
export const ClaimAssignableLaxSchema = z.object({
  type: z.literal(AssignableTypeEnum.claim),
  assigneeRelationship: ClaimAssigneeRelationshipLaxSchema,
  assignableId: CurrentClaimCornSchema,
  claim: z.object({
    claimNumber: CurrentClaimCornSchema,
  }),
})

export const ClaimAssignableCanonSchema = z.object({
  type: z.literal(AssignableTypeEnum.claim),
  assigneeRelationship: ClaimAssigneeRelationshipCanonSchema,
  assignableId: CurrentClaimCornSchema,
  claim: z.object({
    claimNumber: CurrentClaimCornSchema,
  }),
})

export const JobAssignableSchema = z.object({
  type: z.literal(AssignableTypeEnum.job),
  assigneeRelationship: JobAssigneeRelationshipEnumSchema,
  assignableId: JobCornSchema,
  job: z.object({
    jobId: JobIdSchema,
  }),
})

export const AssignableLaxSchema = z.discriminatedUnion('type', [
  ClaimAssignableLaxSchema,
  JobAssignableSchema,
])

export const AssignableCanonSchema = z.discriminatedUnion('type', [
  ClaimAssignableCanonSchema,
  JobAssignableSchema,
])

export const AssigneeRelationshipCanonSchema = z.union([
  ClaimAssigneeRelationshipCanonSchema,
  JobAssigneeRelationshipEnumSchema,
])

export type ClaimAssignableLax = z.infer<typeof ClaimAssignableLaxSchema>
export type ClaimAssignableCanon = z.infer<typeof ClaimAssignableCanonSchema>
export type JobAssignable = z.infer<typeof JobAssignableSchema>
export type AssignableCanon = z.infer<typeof AssignableCanonSchema>
export type AssigneeRelationshipCanon = z.infer<typeof AssigneeRelationshipCanonSchema>

/**
 * Assignment
 */
export const AssignmentCanonSchema = z.object({
  assignable: AssignableCanonSchema,
  assignee: AssigneeSchema,
  requestActor: z.string(),
  assignmentDate: z.string().datetime(),
})

export const AssignmentLaxSchema = z.object({
  assignable: AssignableLaxSchema,
  assignee: AssigneeSchema,
  requestActor: z.string(),
  assignmentDate: z.string().datetime(),
})

export type Assignment = z.infer<typeof AssignmentCanonSchema>

export const DatabaseAssignmentSchema = AssignmentCanonSchema.merge(
  z.object({
    PK: z.string(),
    SK: z.string(),
    'GSI-PK-1': z.string(),
    'GSI-SK-1': z.string(),
  })
)

export type DatabaseAssignment = z.infer<typeof DatabaseAssignmentSchema>
