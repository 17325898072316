import {
  ContactRolesConsumerSchema,
  GlobalBookCornCanon,
  GlobalBookCornCanonSchema,
  GlobalBookCornLax,
  GlobalBookQueryModelCanon,
  GlobalBookQueryModelCanonSchema,
  GlobalBookQueryModelLax,
  GlobalBookQueryModelLaxSchema,
  GlobalBookResourceIdCanonSchema,
  GlobalBookResourceIdLaxSchema,
} from '@eigtech/contacts-types'
import { getCornKernels, getResourceId } from '@eigtech/shared-corn'

GlobalBookQueryModelLaxSchema
GlobalBookQueryModelCanonSchema

export const laxToCanonGlobalBookCorn = (corn: GlobalBookCornLax): GlobalBookCornCanon =>
  `corn:contacts:book:${GlobalBookResourceIdCanonSchema.parse(getCornKernels(corn).resourceId)}`

export const laxToCanonGlobalBookQueryModel = (
  queryModel: GlobalBookQueryModelLax
): GlobalBookQueryModelCanon => ({
  ...queryModel,
  bookId: laxToCanonGlobalBookCorn(queryModel.bookId),
  contacts: Object.fromEntries(
    Object.entries(queryModel.contacts).map(([contactId, contact]) => {
      const { roles: rolesIn } = contact
      const roleArrayOrSet = ContactRolesConsumerSchema.parse(rolesIn)
      return [
        contactId,
        {
          ...contact,
          roles: Array.from(roleArrayOrSet),
        },
      ]
    })
  ),
})

export const isGlobalBookId = (bookId: string): bookId is GlobalBookCornCanon => {
  const parseResult = GlobalBookCornCanonSchema.safeParse(bookId)
  return (
    parseResult.success &&
    GlobalBookResourceIdLaxSchema.safeParse(getResourceId(parseResult.data)).success
  )
}
