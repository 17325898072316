import { z } from 'zod'
import { summarizerServiceCorn } from '../base'
import { TimelineEntityType, TimelineSchema, TimelineType } from './timeline'

export const JobTimelineTypeSchema = z.enum([TimelineType.default, TimelineType.insured])
export const JobTimelineType = JobTimelineTypeSchema.Enum
export type JobTimelineTypeEnum = z.infer<typeof JobTimelineTypeSchema>

export const JobTimelineSchema = TimelineSchema.merge(
  z.object({
    entityType: z.literal(TimelineEntityType.job),
    timelineType: JobTimelineTypeSchema,
  })
)
export const JobDefaultTimelineSchema = JobTimelineSchema.merge(
  z.object({
    timelineId: summarizerServiceCorn('jobDefaultTimeline'),
    timelineType: z.literal(JobTimelineType.default),
  })
)
export const JobInsuredTimelineSchema = JobTimelineSchema.merge(
  z.object({
    timelineId: summarizerServiceCorn('jobInsuredTimeline'),
    timelineType: z.literal(JobTimelineType.insured),
  })
)

export type JobTimeline = z.infer<typeof JobTimelineSchema>
export type JobDefaultTimeline = z.infer<typeof JobDefaultTimelineSchema>
export type JobInsuredTimeline = z.infer<typeof JobInsuredTimelineSchema>
