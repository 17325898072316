import { forwardRef } from '@chakra-ui/react'
import { ComposedAlert, ComposedAlertProps } from '../Chakra/Alert'

export type PageAlertProps = ComposedAlertProps

export const PageAlert = forwardRef<PageAlertProps, 'div'>((props, ref) => (
  <ComposedAlert ref={ref} alignSelf="center" maxW="container.text" {...props} />
))

export type PageErrorProps = ComposedAlertProps

export const PageError = forwardRef<PageErrorProps, 'div'>((props, ref) => (
  <PageAlert ref={ref} status="error" {...props} />
))
