import { useQueryClient } from '@eigtech/ui-shared-api'
import { claimsQueryKeys } from './constants'

export function useInvalidateClaimsSummaries() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: claimsQueryKeys.list() })
  }
}

export function useInvalidateClaims() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: claimsQueryKeys.all() })
  }
}

export function useInvalidateClaim() {
  const queryClient = useQueryClient()

  return (claimNumber: string) => {
    queryClient.invalidateQueries({ queryKey: claimsQueryKeys.detail(claimNumber) })
  }
}
