import { MongoAbility } from '@casl/ability'
import { PermissionGenerator } from '../permissionsFactory'
import { Permissions, Role, Rule } from '../types'
import { ability as baseAbility } from './context'

export function updateAbility<Action extends string, Subject extends string>(
  permissions: Permissions<Action, Subject>,
  roles: Role[],
  additionalPermissionsGenerators: PermissionGenerator<Action, Subject>[] = []
) {
  let newPermissions = permissions.map((permission) => {
    const split = permission.split(':')
    const action = split[split.length - 1] as Action
    const subject = split.slice(0, -1).join(':') as Subject
    return { action, subject } as Rule<Action, Subject>
  })

  additionalPermissionsGenerators.forEach((generator) => {
    newPermissions = [...newPermissions, ...generator(permissions, roles)]
  })

  const ability = baseAbility as MongoAbility<[Action, Subject]>

  ability.update(newPermissions)
}
