import { ClaimReviewCompletedDetails } from '@eigtech/summarizer-types'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Text, VStack } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'

export function ReviewCompletedDetails(
  props: EventDetailsComponentProps<ClaimReviewCompletedDetails>
) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const { dateFlagged, reviewers, reviewerNotes } = props.details.review

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text as="span">Review Completed</Text>
        {reviewers && reviewers.length > 0 && (
          <Text>{`by ${reviewers.map((r) => r.name).join(',')} on `}</Text>
        )}
        <PreferredDateTimeComponent property="requestedDate">
          {dateFlagged}
        </PreferredDateTimeComponent>
      </HStack>

      {!!reviewerNotes && <Blockquote whiteSpace="preserve">{reviewerNotes}</Blockquote>}
    </VStack>
  )
}
