import {
  ClaimInspectionCanceledDetails,
  ClaimInspectionCompletedDetails,
  ClaimInspectionScheduledDetails,
  ClaimReInspectionRequestedDetails,
} from '@eigtech/summarizer-types'
import { getInspectionStatusColorScheme, getInspectionStatusLabel } from '@eigtech/ui-shared-claims'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, VStack, chakra } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { startCase } from 'lodash-es'

type Details =
  | ClaimInspectionCanceledDetails
  | ClaimInspectionCompletedDetails
  | ClaimInspectionScheduledDetails
  | ClaimReInspectionRequestedDetails

export function InspectionDetails(props: EventDetailsComponentProps<Details>) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const {
    details: { inspection },
  } = props

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <chakra.span>Inspection</chakra.span>

        <Tag colorScheme={getInspectionStatusColorScheme(inspection.status)}>
          {getInspectionStatusLabel(inspection.status)}
        </Tag>

        {!!inspection.inspectorRole && (
          <>
            <chakra.span>for</chakra.span>
            <Tag>{startCase(inspection.inspectorRole)}</Tag>
          </>
        )}

        {'dateCanceled' in inspection && !!inspection.dateCanceled ? (
          <chakra.span>
            on{' '}
            <PreferredDateTimeComponent property="dateCanceled">
              {inspection.dateCanceled}
            </PreferredDateTimeComponent>
          </chakra.span>
        ) : 'dateCompleted' in inspection && !!inspection.dateCompleted ? (
          <chakra.span>
            on{' '}
            <PreferredDateTimeComponent property="dateCompleted">
              {inspection.dateCompleted}
            </PreferredDateTimeComponent>
          </chakra.span>
        ) : (
          'scheduledDate' in inspection &&
          !!inspection.scheduledDate && (
            <chakra.span>
              on{' '}
              <PreferredDateTimeComponent property="scheduledDate">
                {inspection.scheduledDate}
              </PreferredDateTimeComponent>
            </chakra.span>
          )
        )}
      </HStack>

      {!!inspection.notes && <Blockquote whiteSpace="preserve">{inspection.notes}</Blockquote>}
    </VStack>
  )
}
