import { BeautifulMentionsItemData } from 'lexical-beautiful-mentions'
import { z } from 'zod'

export type BaseMentionData = {
  [key: string]: BeautifulMentionsItemData
}

export type Mention<TData extends BaseMentionData | undefined = BaseMentionData | undefined> = {
  trigger: string
  value: string
  data: TData
}

export const makeMentionSchema = <TDataSchema extends z.ZodTypeAny>(dataSchema: TDataSchema) =>
  z.object({
    trigger: z.string(),
    value: z.string(),
    data: dataSchema,
  })
