import type {
  ListTagsByFilterRequest,
  ListTagsByKeyRequest,
  ListTagsByResourceRequest,
} from '@eigtech/resource-tagging-types'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'

export const tagsBasePath = 'tags'

const { all, list } = makeBasicQueryKeys('tags')

export const tagsQueryKeys = {
  all,
  list,
  filteredList: (
    params: ListTagsByKeyRequest | ListTagsByResourceRequest | ListTagsByFilterRequest
  ) => [...tagsQueryKeys.list(), params],
}
