import { Operator } from '@eigtech/summarizer-types'
import { DATE_INPUT_FORMAT, addDays, format, toZonedTime } from '@eigtech/ui-shared-dates'
import { operators } from './constants'

type Filter<Field extends string = string> = {
  field: Field
  value: string
  operator?: Operator | 'WITHIN'
}

type ReturnFilter<Field extends string = string> = {
  field: Field
  operator?: Operator
  value: string
}

export type NormalizeFilterProps<Field extends string = string> =
  | {
      filters: Filter<Field>[]
    }
  | {
      filters: Filter<Field>[]
      dateFields: Field[]
      timezone: string
    }

type LikeOperator = 'ILIKE' | 'LIKE' | 'NOT ILIKE' | 'NOT LIKE'

const isLikeOperator = (operator?: string): operator is LikeOperator =>
  ['ILIKE', 'LIKE', 'NOT ILIKE', 'NOT LIKE'].includes(operator ?? '')
const normalizeLikeOperator = (operator: LikeOperator): 'ILIKE' | 'NOT ILIKE' =>
  operator === 'LIKE' ? 'ILIKE' : 'NOT ILIKE'

export function normalizeFilters<Field extends string = string>({
  filters,
  ...props
}: NormalizeFilterProps<Field>) {
  return filters
    .map((filter): ReturnFilter<Field> => {
      if ('dateFields' in props && props.dateFields.includes(filter.field)) {
        const normalizedFilterOperator =
          filter.operator === 'WITHIN' ? operators.WITHIN.filter : filter.operator
        const normalizedFilterValue =
          filter.operator === 'WITHIN'
            ? addDays(Date.now(), parseInt(filter.value)).toISOString()
            : filter.value

        const date = toZonedTime(normalizedFilterValue, props.timezone)
        const normalizedDate = normalizedFilterOperator === '>' ? addDays(date, 1) : date

        const value = format(normalizedDate, DATE_INPUT_FORMAT)
        const normalizedValue = isLikeOperator(filter.operator) ? `%${value}%` : value

        return {
          field: filter.field,
          operator: normalizedFilterOperator,
          value: normalizedValue,
        }
      } else if (isLikeOperator(filter.operator)) {
        return {
          field: filter.field,
          operator: normalizeLikeOperator(filter.operator),
          value: `%${filter.value}%`,
        }
      } else if (filter.operator === 'IS' || filter.operator === 'IS NOT') {
        return {
          ...filter,
          operator: filter.value === 'true' ? 'IS NOT' : filter.operator,
          value: 'NULL',
        }
      }

      const operator = !!filter.operator ? operators[filter.operator] : undefined
      const filterOperator = !!operator && 'filter' in operator ? operator.filter : operator?.value

      return {
        ...filter,
        operator: filterOperator,
      }
    })
    .flat()
}
