import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobSummaryFieldSchema, JobSummarySchema } from '../../queryModels'
import { PageResponsePropertiesSchema, makePageRequestSchema } from '../pagination'

export const GetJobSummariesInternalSchemas = {
  query: makePageRequestSchema(JobSummaryFieldSchema),
  response: z
    .object({
      jobs: JobSummarySchema.array(),
    })
    .merge(PageResponsePropertiesSchema),
}

export const GetJobSummariesSchemas = {
  ...GetJobSummariesInternalSchemas,
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestToken: true }).required({
    requestToken: true,
  }),
  response: z
    .object({
      jobs: JobSummarySchema.partial().array(),
    })
    .merge(PageResponsePropertiesSchema),
}

export const GetJobSummariesInternalRequestSchema = GetJobSummariesInternalSchemas.query
export const GetJobSummariesRequestSchema = GetJobSummariesSchemas.query.merge(
  GetJobSummariesSchemas.headers
)
export const GetJobSummariesInternalResponseSchema = GetJobSummariesInternalSchemas.response
export const GetJobSummariesResponseSchema = GetJobSummariesSchemas.response

export type GetJobSummariesRequest = z.infer<typeof GetJobSummariesRequestSchema>
export type GetJobSummariesInternalRequest = z.infer<typeof GetJobSummariesInternalRequestSchema>
export type GetJobSummariesResponse = z.infer<typeof GetJobSummariesResponseSchema>
export type GetJobSummariesInternalResponse = z.infer<typeof GetJobSummariesInternalResponseSchema>
export interface GetJobSummariesHandler
  extends Handler<GetJobSummariesRequest, GetJobSummariesResponse> {}
export interface GetJobSummariesInternalHandler
  extends Handler<GetJobSummariesInternalRequest, GetJobSummariesInternalResponse> {}

export const GetJobSummariesApiSchema: ApiSchema = GetJobSummariesSchemas
export const GetJobSummariesInternalApiSchema: ApiSchema = GetJobSummariesInternalSchemas
