import {
  CallbackRequest,
  CallbackResponseRequest,
  CallbackResponseRequestBody,
  CallbackResponseResponse,
} from '@eigtech/communications-types'
import { contextualPost, useMutation, useQueryClient } from '@eigtech/ui-shared-api'
import { secondsToMilliseconds } from '@eigtech/ui-shared-dates'
import { asyncForEach } from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { communicationBasePath, communicationsQueryKeys } from './constants'
import { useInvalidateCallbackRequests } from './getCallbackRequests'

const respondToCallback = ({ entityId, ...payload }: CallbackResponseRequest) =>
  contextualPost<CallbackResponseResponse, CallbackResponseRequestBody>(
    `${communicationBasePath}/callback/${entityId}/response`,
    payload,
    { responseType: 'none' }
  )

export function useRespondToCallback() {
  const queryClient = useQueryClient()
  const invalidateCallbackRequests = useInvalidateCallbackRequests()

  return useMutation({
    mutationFn: respondToCallback,
    async onMutate({ entityId, role, status }) {
      const allPrevious = queryClient.getQueriesData<CallbackRequest[]>({
        queryKey: communicationsQueryKeys.all(),
      })

      await asyncForEach(allPrevious, async ([queryKey, data]) => {
        await queryClient.cancelQueries({ queryKey })

        queryClient.setQueryData<CallbackRequest[]>(
          queryKey,
          data?.map((callback) => ({
            ...callback,
            status:
              callback.entityId === entityId &&
              callback.role === role &&
              callback.status !== 'COMPLETED'
                ? status
                : callback.status,
          }))
        )
      })

      return { previous: allPrevious }
    },
    async onError(error, __, context) {
      log.error('could not update callback response', { error })

      if (!!context?.previous) {
        await asyncForEach(context.previous, async ([queryKey, data]) => {
          await queryClient.cancelQueries({ queryKey })

          queryClient.setQueryData<CallbackRequest[]>(queryKey, data)
        })
      }

      // invalidate immediately on error
      invalidateCallbackRequests()
    },
    onSuccess() {
      // it takes a bit for timeline events to show up
      setTimeout(invalidateCallbackRequests, secondsToMilliseconds(20))
    },
  })
}
