import { SoftDateSchema } from '@eigtech/function-utils'
import { z } from 'zod'
import { ClaimDataSourceSchema } from './base'

export const InspectorRoleSchema = z.enum(['fieldAdjuster'])

export const InspectionStatusSchema = z.enum(['canceled', 'completed', 'requested', 'scheduled'])

export const InspectionStatusEnum = InspectionStatusSchema.Enum

export const InspectionOriginSchema = ClaimDataSourceSchema

export const InspectionDetailsSchema = z.object({
  canceledBy: z.string().optional(),
  completedBy: z.string().optional(),
  dateCompleted: SoftDateSchema.optional(),
  dateCanceled: SoftDateSchema.optional(),
  inspectionId: z.string(),
  inspectorRole: InspectorRoleSchema.optional(),
  notes: z.string().optional(),
  origin: InspectionOriginSchema.optional(),
  requestedBy: z.string().optional(),
  scheduledDate: SoftDateSchema.optional(),
  scheduledBy: z.string().optional(),
  status: InspectionStatusSchema,
})

export const RequestedInspectionSchema = InspectionDetailsSchema.omit({
  canceledBy: true,
  completedBy: true,
  dateCanceled: true,
  dateCompleted: true,
  scheduledDate: true,
}).merge(z.object({ status: z.literal(InspectionStatusEnum.requested) }))

export const ScheduledInspectionSchema = InspectionDetailsSchema.omit({
  canceledBy: true,
  completedBy: true,
  dateCanceled: true,
  dateCompleted: true,
})
  .required({
    scheduledDate: true,
  })
  .merge(z.object({ status: z.literal(InspectionStatusEnum.scheduled) }))

export const CompletedInspectionSchema = InspectionDetailsSchema.omit({
  dateCanceled: true,
})
  .required({
    dateCompleted: true,
  })
  .merge(z.object({ status: z.literal(InspectionStatusEnum.completed) }))

export const CanceledInspectionSchema = InspectionDetailsSchema.omit({
  dateCompleted: true,
})
  .required({
    dateCanceled: true,
  })
  .merge(z.object({ status: z.literal(InspectionStatusEnum.canceled) }))

export type InspectionOrigin = z.infer<typeof InspectionOriginSchema>
export type InspectionRole = z.infer<typeof InspectorRoleSchema>
export type InspectionStatus = z.infer<typeof InspectionStatusSchema>
export type InspectionDetails = z.infer<typeof InspectionDetailsSchema>
export type RequestedInspection = z.infer<typeof RequestedInspectionSchema>
export type ScheduledInspection = z.infer<typeof ScheduledInspectionSchema>
export type CompletedInspection = z.infer<typeof CompletedInspectionSchema>
export type CanceledInspection = z.infer<typeof CanceledInspectionSchema>

export const isRequestedInspection = (i: InspectionDetails): i is RequestedInspection =>
  i.status === 'requested'

export const isScheduledInspection = (i: InspectionDetails): i is ScheduledInspection =>
  i.status === 'scheduled' && !!i.scheduledDate

export const isCompletedInspection = (i: InspectionDetails): i is CompletedInspection =>
  i.status === 'completed' && !!i.scheduledDate

export const isCanceledInspection = (i: InspectionDetails): i is CanceledInspection =>
  i.status === 'canceled' && !!i.scheduledDate
