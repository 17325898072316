import { isDefined } from '@eigtech/function-utils'
import { HStack, IconButtonProps, chakra, useFormControlContext } from '@eigtech/ui-shared-dave'
import { ArrayFieldRemoveButton, useArrayFieldRemoveButtonProps } from '@eigtech/ui-shared-forms'
import { ReactNode } from 'react'
import {
  ContactFormCommunicationInformationFields,
  useContactFormContext,
} from './ContactFormSchema'

export type ContactInfoRowContainerProps = { children: ReactNode } & RemoveContactInfoButtonProps

export function ContactInfoRowContainer({
  children,
  ...buttonProps
}: ContactInfoRowContainerProps) {
  const { id } = useFormControlContext()

  const rowId = `${id}-row-${buttonProps.index}`

  return (
    <HStack alignItems="flex-start" id={rowId}>
      {children}
      <chakra.div pt="8">
        <RemoveContactInfoButton {...buttonProps} />
      </chakra.div>
    </HStack>
  )
}

type RemoveContactInfoButtonProps = {
  index: number
  indexKey?: keyof ContactFormCommunicationInformationFields
} & Omit<IconButtonProps, 'aria-label'>

function RemoveContactInfoButton({
  index,
  indexKey,
  isDisabled: isDisabledOverride,
  ...props
}: RemoveContactInfoButtonProps) {
  const { onClick, isDisabled: isDisabledDefault } = useArrayFieldRemoveButtonProps()

  const isDisabled = isDisabledOverride ?? isDisabledDefault

  const { setValue, getValues } = useContactFormContext()

  function handleRemove() {
    if (indexKey !== undefined) {
      const value = getValues()[indexKey]

      if (isDefined(value) && value >= index) {
        setValue(indexKey, value === index ? null : value - 1)
      }
    }

    onClick()
  }

  return <ArrayFieldRemoveButton {...props} isDisabled={isDisabled} onClick={handleRemove} />
}
