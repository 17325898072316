import {
  ContactRoleProducerSchema as BaseContactRoleSchema,
  ContactRoleProducerSchema,
} from '@eigtech/contacts-types'
import { z } from 'zod'

export const ContactFormContactRoleEnumSchema = z.enum([
  BaseContactRoleSchema.Enum.agent,
  BaseContactRoleSchema.Enum.deskAdjuster,
  BaseContactRoleSchema.Enum.legalRepresentation,
  BaseContactRoleSchema.Enum.mortgageHolder,
  BaseContactRoleSchema.Enum.other,
  BaseContactRoleSchema.Enum.otherContact,
  BaseContactRoleSchema.Enum.policyHolder,
  BaseContactRoleSchema.Enum.primaryContact,
  BaseContactRoleSchema.Enum.propertyAccessContact,
  BaseContactRoleSchema.Enum.qa,
  BaseContactRoleSchema.Enum.thirdPartyClaimant,
])

export const ContactFormRoleFieldSchema = z.object({
  role: z.enum([...ContactRoleProducerSchema.options], {
    errorMap: (__a, __b) => ({ message: 'Please select a role' }),
  }),
})

export type ContactFormContactRole = z.infer<typeof ContactFormContactRoleEnumSchema>
export type ContactFormRoleField = z.infer<typeof ContactFormRoleFieldSchema>
