import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema, BookSchema } from '../../../base/books.js'
import { ContactSchema } from '../../../base/contacts.js'

export const GetBookRequestPathParametersSchema = z.object({
  bookId: BookCornSchema,
})

export const GetBookRequestSchema = GetBookRequestPathParametersSchema

export const GetBookResponseSchema = BookSchema.extend({
  contacts: ContactSchema.array(),
})

export type GetBookRequestPathParameters = z.infer<typeof GetBookRequestPathParametersSchema>

export type GetBookRequest = z.infer<typeof GetBookRequestSchema>

export type GetBookResponse = z.infer<typeof GetBookResponseSchema>

export interface GetBookHandler extends Handler<GetBookRequest, GetBookResponse> {}

export const GetBookApiSchema: ApiSchema = {
  params: GetBookRequestPathParametersSchema,
  response: GetBookResponseSchema,
}
