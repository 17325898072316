// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/menu.ts

import { menuAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

// const $bg = cssVar('menu-bg')
// const $shadow = cssVar('menu-shadow')

const baseStyleList = defineStyle({
  py: '1',
  px: '2',
})

const baseStyleItem = defineStyle({
  borderRadius: 'md',
  my: '1',
})

// const baseStyleGroupTitle = defineStyle({})

// const baseStyleCommand = defineStyle({})

// const baseStyleDivider = defineStyle({})

// const baseStyleButton = defineStyle({})

const baseStyle = definePartsStyle({
  // button: baseStyleButton,
  list: baseStyleList,
  item: baseStyleItem,
  // groupTitle: baseStyleGroupTitle,
  // command: baseStyleCommand,
  // divider: baseStyleDivider,
})

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
})
