import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'
import { JobServicesSchema } from '../../base/jobService.js'

export const RemoveJobServicesRequestBodySchema = z.object({
  services: JobServicesSchema,
})
export const RemoveJobServicesRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RemoveJobServicesRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RemoveJobServicesRequestSchema = RemoveJobServicesRequestBodySchema.merge(
  RemoveJobServicesRequestHeadersSchema
).merge(RemoveJobServicesRequestPathSchema)
export const RemoveJobServicesResponseSchema = z.object({
  jobId: JobIdSchema,
  services: JobServicesSchema,
})
export const RemoveJobServicesApiSchema: ApiSchema = {
  body: RemoveJobServicesRequestBodySchema,
  headers: RemoveJobServicesRequestHeadersSchema,
  params: RemoveJobServicesRequestPathSchema,
  response: RemoveJobServicesResponseSchema,
}

export type RemoveJobServicesRequestBody = z.infer<typeof RemoveJobServicesRequestBodySchema>
export interface RemoveJobServicesRequest extends z.infer<typeof RemoveJobServicesRequestSchema> {}
export type RemoveJobServicesResponse = z.infer<typeof RemoveJobServicesResponseSchema>
export interface RemoveJobServicesHandler
  extends Handler<RemoveJobServicesRequest, RemoveJobServicesResponse> {}
