import { DelegateAdminClaim } from '../types/DelegateAdminClaim'
import { EntityAdminClaim } from '../types/EntityAdminClaim'

/**
 * @description Check if the claim was created by EIG, and if it was published
 */
export const canPublishClaimInfoToXA = (claim?: EntityAdminClaim | DelegateAdminClaim) =>
  !!claim?.externalSystemIds?.verisk

/**
 * @description Check if the claim was created by EIG, and if it was published
 */
export const canPublishClaimInfoToSymbility = (claim?: EntityAdminClaim | DelegateAdminClaim) =>
  !!claim?.externalSystemIds?.symbility
