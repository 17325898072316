import { ContactCorn } from '@eigtech/contacts-types'
import { useQuery } from '@eigtech/ui-shared-api'
import {
  GetInspectionsRequest,
  getAllClaimsInspectionsQueryOptions,
  getClaimInspectionsQueryOptions,
} from '@eigtech/ui-shared-claims'
import { useMemo } from 'react'
import { useGetEstimatorUserContacts } from '../user'

export function useGetAllClaimsInspections(request?: GetInspectionsRequest) {
  const queries = useGetEstimatorUserContacts()

  const contactIds = useMemo(
    () =>
      queries
        .map(({ data }) => data?.contactId)
        .filter((contactId): contactId is ContactCorn => !!contactId),
    [queries]
  )

  return useQuery(
    getAllClaimsInspectionsQueryOptions({
      ...request,
      filter: [
        ...(request?.filter ?? []),
        {
          field: 'fieldAdjusterId',
          operator: 'ANYOF',
          value: contactIds,
        },
      ],
    })
  )
}

export function useGetClaimInspections(claimNumber: string, request?: GetInspectionsRequest) {
  const queries = useGetEstimatorUserContacts()

  const contactIds = useMemo(
    () =>
      queries
        .map(({ data }) => data?.contactId)
        .filter((contactId): contactId is ContactCorn => !!contactId),
    [queries]
  )

  return useQuery(
    getClaimInspectionsQueryOptions(claimNumber, {
      ...request,
      filter: [
        ...(request?.filter ?? []),
        {
          field: 'fieldAdjusterId',
          operator: 'ANYOF',
          value: contactIds,
        },
      ],
    })
  )
}
