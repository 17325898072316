/**
 * @description gets the filename from a url
 * @param url
 * @returns the name and extension of the file
 */
export const getUrlFilename = (url?: string) => {
  let filename = undefined
  let extension = undefined
  if (url) {
    const parts = url.split(/[#?]/)
    if (parts.length > 0) {
      filename = parts[0].split('/').pop()
      if (filename) extension = filename.split('.').pop()
    }
  }

  return { filename, extension }
}
