import { Action, Subject } from '@eigtech/ui-estimator/lib/permissions'
import { useGetUserInbox } from '@eigtech/ui-shared-messaging'
import {
  Nav as BaseNav,
  NavId as BaseNavId,
  Navs as BaseNavs,
  createNavItemHelper,
} from '@eigtech/ui-shared-navigation'
import { useMemo } from 'react'
import { IoHome as HomeIcon } from 'react-icons/io5'
import {
  MdCalendarMonth as CalendarIcon,
  MdPhoneCallback as CallbackIcon,
  MdInbox as InboxIcon,
} from 'react-icons/md'
import { useGetEstimatorCallbacks } from '../../../api'
import { ROUTER_PATHS } from '../../../lib/constants'

export type NavId = BaseNavId<'main'>

export type Nav = BaseNav<Action, Subject, NavId>

export type Navs = BaseNavs<Action, Subject, NavId>

const helper = createNavItemHelper<Action, Subject, NavId>()

export function useNavs() {
  const { data: inbox } = useGetUserInbox()

  const { callbacks } = useGetEstimatorCallbacks()

  const navs = useMemo(() => {
    const mainNavItems: Nav = [
      helper.link({
        label: 'Home',
        url: { to: ROUTER_PATHS.claims.route },
        icon: HomeIcon,
      }),
      helper.link({
        label: 'Inbox',
        url: { to: ROUTER_PATHS.inbox.route },
        icon: InboxIcon,
        badge: inbox?.unread,
      }),
      helper.link({
        label: 'Callbacks',
        url: { to: ROUTER_PATHS.callbacks.route },
        icon: CallbackIcon,
        badge: callbacks.length,
      }),
      helper.link({
        label: 'Schedule',
        url: { to: ROUTER_PATHS.inspectionsSchedule.route },
        icon: CalendarIcon,
      }),
    ]

    const navs = {
      main: mainNavItems,
    } satisfies Navs

    return navs
  }, [callbacks.length, inbox?.unread])

  return navs
}
