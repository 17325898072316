export function formatArrayAsStringList(
  value: any[],
  options: Pick<Intl.ListFormatOptions, 'type'> = { type: 'conjunction' }
) {
  if ('ListFormat' in Intl) {
    const formatter = new Intl.ListFormat('en', {
      style: 'long',
      ...options,
    })
    return formatter.format(value)
  }

  const cloned = [...value]
  const last = cloned.pop()
  return `${cloned.join(', ')}, ${options.type === 'disjunction' ? 'or' : 'and'} ${last}`
}
