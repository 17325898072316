import { ClaimLossOfUseDetails } from '@eigtech/summarizer-types'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { startCase } from 'lodash-es'

export function LossOfUseStatusDetails(props: EventDetailsComponentProps<ClaimLossOfUseDetails>) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const { type, justification, requestedDate, status } = props.details.lossOfUse

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Tag>{startCase(type)}</Tag>
        <Text as="span">loss of use {status} on</Text>
        <PreferredDateTimeComponent property="requestedDate">
          {requestedDate}
        </PreferredDateTimeComponent>
      </HStack>

      {!!justification && <Blockquote whiteSpace="preserve">{justification}</Blockquote>}
    </VStack>
  )
}
