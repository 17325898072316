import { User } from '@auth0/auth0-react'
import { mostReadable, stringToColor } from '@eigtech/ui-shared-color'
import { getUserName } from './getUserName'

export function getUserColor(user?: string | User) {
  const name = typeof user === 'string' ? user : getUserName(user)
  return stringToColor(name)
}

export function getUserColors(user?: string | User) {
  const color = getUserColor(user)
  const fontColor = mostReadable(color, ['#fff', '#000'])?.toHexString()

  return { color, fontColor }
}
