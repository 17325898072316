import {
  Locale,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  getYear,
  isSameDay,
  isSameMonth,
  startOfMonth,
  subMonths,
} from '@eigtech/ui-shared-dates'
import {
  Button,
  HStack,
  SimpleGrid,
  Stack,
  StyleProps,
  Text,
  chakra,
} from '@eigtech/ui-shared-dave'
import { ReactNode, useMemo, useState } from 'react'
import { handleOmittedDays } from '../utils'
import {
  MobileMonthlyCalendarContext,
  useMobileMonthlyCalendar,
} from './MobileMonthlyCalendarContext'

export type MobileMonthlyCalendarProps<DayData> = {
  locale?: Locale
  children: ReactNode
  selectedMonth: Date
  onSelectedMonthChange: (date: Date) => any
  events: (DayData & { date: Date })[]
}

export function MobileMonthlyCalendar<DayData>({
  locale,
  selectedMonth,
  onSelectedMonthChange,
  children,
  events,
}: MobileMonthlyCalendarProps<DayData>) {
  const monthStart = startOfMonth(selectedMonth)
  const days = eachDayOfInterval({
    start: monthStart,
    end: endOfMonth(monthStart),
  })

  const todayIsInSelectedMonth = isSameMonth(monthStart, new Date())

  const [selectedDate, setSelectedDate] = useState(todayIsInSelectedMonth ? new Date() : monthStart)

  return (
    <MobileMonthlyCalendarContext.Provider
      value={{
        days,
        locale,
        onSelectedMonthChange,
        selectedMonth: monthStart,
        selectedDate,
        setSelectedDate,
        events,
      }}
    >
      {children}
    </MobileMonthlyCalendarContext.Provider>
  )
}

export type MobileMonthlyNavProps = {
  /*
    skip days, an array of days, starts at sunday (0), saturday is 6
    ex: [0,6] would remove sunday and saturday from rendering
  */
  omitDays?: number[]
}

export function MobileMonthlyNav({ omitDays }: MobileMonthlyNavProps) {
  const { days, locale, selectedMonth, onSelectedMonthChange } = useMobileMonthlyCalendar()

  const { headings, daysToRender, padding } = handleOmittedDays({
    days,
    omitDays,
    locale,
  })

  return (
    <Stack mb="2">
      <HStack justifyContent="space-between">
        <Button
          size="sm"
          variant="outline"
          w="20"
          onClick={() => onSelectedMonthChange(subMonths(selectedMonth, 1))}
        >
          Previous
        </Button>

        <Text fontWeight="bold" textAlign="center" title="Current Month">
          {format(
            selectedMonth,
            getYear(selectedMonth) === getYear(new Date()) ? 'LLLL' : 'LLLL yyyy',
            { locale }
          )}
        </Text>

        <Button
          size="sm"
          variant="outline"
          w="20"
          onClick={() => onSelectedMonthChange(addMonths(selectedMonth, 1))}
        >
          Next
        </Button>
      </HStack>

      <chakra.div
        bg="white"
        borderLeft="1px"
        borderLeftColor="gray.200"
        borderTop="1px"
        borderTopColor="gray.200"
      >
        <SimpleGrid columns={7}>
          {headings.map((day) => (
            <chakra.div key={day.day} {...cellProps} fontWeight="bold" title="Day of Week">
              {day.label[0]}
            </chakra.div>
          ))}
          {padding.map((_, index) => (
            <chakra.div key={index} {...cellProps} title="Empty Day" />
          ))}
          {daysToRender.map((day) => (
            <MobileMonthlyNavDay key={day.getTime()} day={day} />
          ))}
        </SimpleGrid>
      </chakra.div>
    </Stack>
  )
}

function MobileMonthlyNavDay({ day }: { day: Date }) {
  const { selectedDate, setSelectedDate, events } = useMobileMonthlyCalendar()

  const today = new Date()

  const hasEvents = useMemo(
    () => !!events.filter((data) => isSameDay(data.date, day)).length,
    [day, events]
  )

  return (
    <chakra.div key={day.getTime()} {...cellProps} position="relative">
      <chakra.button
        bg={
          isSameDay(day, selectedDate)
            ? 'brandPrimary.200'
            : isSameDay(day, today)
              ? 'brandPrimary.50'
              : undefined
        }
        borderRadius="full"
        fontWeight={hasEvents ? 'bold' : undefined}
        h="6"
        w="6"
        onClick={() => setSelectedDate(day)}
      >
        {hasEvents && (
          <chakra.div
            bg="red.300"
            borderRadius="full"
            h="1.5"
            position="absolute"
            right="0.5"
            top="0.5"
            w="1.5"
          />
        )}
        <chakra.span>{format(day, 'd')}</chakra.span>
      </chakra.button>
    </chakra.div>
  )
}

const cellProps: StyleProps = {
  borderBottom: '1px',
  borderBottomColor: 'gray.200',
  borderRight: '1px',
  borderRightColor: 'gray.200',
  padding: '1',
  textAlign: 'center',
  fontSize: 'sm',
}
