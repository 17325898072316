import z from 'zod'
import {
  AssignableTypeEnumSchema,
  AssigneeSchema,
  AssigneeType,
  AssigneeTypeEnumSchema,
} from '../entities/assignment'
import { AssignmentsServiceEventBaseSchema, serviceEventType } from './service'

export const assigneeUpdatedEventType = <V extends AssigneeType>(assigneeType?: V) =>
  serviceEventType(assigneeType, 'updated')

export const AssigneeUpdatedEventSchema = AssignmentsServiceEventBaseSchema.merge(
  z.object({
    type: assigneeUpdatedEventType(),
    metadata: z.object({
      assignableType: AssignableTypeEnumSchema,
      assigneeType: AssigneeTypeEnumSchema,
      assigneeRelationship: z.string(),
    }),
    assignment: AssigneeSchema,
  })
)

export type AssigneeUpdatedEvent = z.infer<typeof AssigneeUpdatedEventSchema>
