import {
  Link as CLink,
  LinkProps as CLinkProps,
  MenuItem,
  MenuItemProps,
  Tab,
  TabProps,
} from '@eigtech/ui-shared-dave'
import { createLink } from '@tanstack/react-router'
import { forwardRef } from 'react'

export const Link = createLink(
  forwardRef<'a', CLinkProps>(function Link(props, ref) {
    return <CLink ref={ref} {...props} />
  })
)

export const LinkMenuItem = createLink(
  forwardRef<'a', MenuItemProps>(function LinkMenuItem(props, ref) {
    return <MenuItem ref={ref} as="a" {...props} />
  })
)

export const LinkTab = createLink(
  forwardRef<'a', TabProps>(function LinkTab(props, ref) {
    return <Tab ref={ref} as="a" {...props} />
  })
)
