import { DocumentV2 } from '@eigtech/documents-types'
import { ComposedAlert, forwardRef, Skeleton, Stack, StackProps } from '@eigtech/ui-shared-dave'
import { range } from 'lodash-es'
import { useOrganizeMedia } from '../hooks/useOrganizeDocuments'
import { MediaCard, MediaCardProps } from './MediaCard'

export type MediaListProps = {
  documents: DocumentV2[] | undefined
  isPending?: boolean
  isError?: boolean
} & Pick<MediaCardProps, 'entityId' | 'MediaActions'> &
  StackProps

export const MediaList = forwardRef<MediaListProps, 'div'>(
  ({ entityId, documents, isError, isPending, MediaActions, ...props }, ref) => {
    const organizedMedia = useOrganizeMedia(documents)

    return (
      <Stack ref={ref} spacing="4" {...props}>
        {isError ? (
          <ComposedAlert alert="Something went wrong retrieving media." status="error" />
        ) : isPending ? (
          range(0, 3).map((i) => <Skeleton key={i} h="40" w="full" />)
        ) : !organizedMedia.length ? (
          <ComposedAlert alert="No media found." />
        ) : (
          organizedMedia.map((media) => (
            <MediaCard
              key={media.id}
              MediaActions={MediaActions}
              entityId={entityId}
              media={media}
            />
          ))
        )}
      </Stack>
    )
  }
)
