import { Book, Contact } from '@eigtech/contacts-types'
import {
  formatUsPhoneToHtml,
  getContactEmailAddress,
  getContactEmails,
  getContactPhoneNumber,
} from '@eigtech/contacts-util'
import { useGetContactAssignmentForClaim } from '@eigtech/ui-shared-assignments'
import {
  ComposedCard,
  ComposedCardProps,
  FancyLink,
  HStack,
  Heading,
  PropertyList,
  PropertyProps,
  ResponsiveProperty,
  Tag,
  Wrap,
  WrapItem,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import { formatPhoneNumber } from '@eigtech/ui-shared-phone'
import { startCase } from 'lodash-es'
import { augmentContact } from '../types'
import { getContactRoleLabel } from '../utils'
import { ContactActionMenu } from './ContactActionMenu'

export type ContactCardProps = {
  appType: 'csr' | 'estimator'
  book: Book
  contact: Contact
  disableActions?: boolean
} & ComposedCardProps

export const ContactCard = forwardRef<ContactCardProps, 'div'>(function ContactCard(
  { appType, book, contact: baseContact, disableActions, ...props },
  ref
) {
  const contact = augmentContact(baseContact)

  const primaryEmail = getContactEmailAddress(contact)
  const otherEmails = getContactEmails(contact)?.filter((email) => email !== primaryEmail)
  const phoneNumber = getContactPhoneNumber(contact)

  const { data: assignments } = useGetContactAssignmentForClaim({
    book,
    contactId: baseContact.contactId,
  })

  const primaryTag = assignments?.map((assignment) =>
    startCase(assignment.assignable.assigneeRelationship)
  )

  return (
    <ComposedCard
      ref={ref}
      {...props}
      heading={
        <HStack>
          <Heading as="h2" flex="1" size="md" textAlign="left">
            {contact.contactName}
          </Heading>

          <HStack>
            {primaryTag?.length && (
              <Wrap>
                {primaryTag.map((primaryTag) => (
                  <Tag key={primaryTag} colorScheme="blue" fontWeight="bold" size="sm">
                    {primaryTag}
                  </Tag>
                ))}
              </Wrap>
            )}

            {!disableActions && (
              <ContactActionMenu appType={appType} book={book} contact={contact} />
            )}
          </HStack>
        </HStack>
      }
    >
      <PropertyList>
        <Property
          label="Primary Email"
          value={
            !!primaryEmail ? (
              <FancyLink key={primaryEmail} href={`mailto:${primaryEmail}`} iconPosition="left">
                {primaryEmail}
              </FancyLink>
            ) : (
              '(None)'
            )
          }
        />
        {otherEmails && otherEmails.length > 0 && (
          <Property
            label="Other Emails"
            value={otherEmails.map((email) => (
              <FancyLink key={email} href={`mailto:${email}`} iconPosition="left">
                {email}
              </FancyLink>
            ))}
          />
        )}
        <Property
          label="Phone"
          value={
            !!phoneNumber ? (
              <FancyLink href={`tel:${formatUsPhoneToHtml(phoneNumber)}`} iconPosition="left">
                {formatPhoneNumber(phoneNumber)}
              </FancyLink>
            ) : (
              '(None)'
            )
          }
        />
        <Property
          label="Roles"
          value={
            <Wrap>
              {contact.roles.map(getContactRoleLabel).map((role) => (
                <WrapItem key={role}>
                  <Tag size="sm">{role}</Tag>
                </WrapItem>
              ))}
            </Wrap>
          }
        />
        <Property label="Description" value={contact.description || '(None)'} />
        <Property label="Data Source" value={contact.metadata.dataSource} />
      </PropertyList>
    </ComposedCard>
  )
})
const Property = forwardRef<PropertyProps, 'div'>((props, ref) => (
  <ResponsiveProperty ref={ref} labelWidth={['full', '25%', '20%']} {...props} />
))
