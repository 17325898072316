import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'
import { pick } from 'lodash-es'
import { ListMessagesRequest } from './getMessages'

export const messageBasePath = 'messaging'

const messagingBaseQueryKey = ['messaging']

export const inboxQueryKeys = {
  ...pick(makeBasicQueryKeys([...messagingBaseQueryKey, 'inbox']), ['all', 'details', 'detail']),
  messages: (inboxId: string) => [...inboxQueryKeys.detail(inboxId), 'messages'],
  filteredMessages: ({ inboxId, ...request }: ListMessagesRequest) => [
    ...inboxQueryKeys.messages(inboxId),
    request,
  ],
}
