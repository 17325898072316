import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AppointmentChannelSchema, AppointmentTypeSchema } from '../../base/appointment.js'
import { JobAppointmentIdSchema, JobIdSchema } from '../../base/ids.js'

export const RecordAppointmentScheduledRequestBodySchema = z.object({
  appointmentChannel: AppointmentChannelSchema,
  appointmentDate: z.string().datetime(),
  appointmentDescription: z.string().optional(),
  appointmentType: AppointmentTypeSchema,
  participants: z.array(ContactCornSchema.or(z.string())),
})

export const RecordAppointmentScheduledRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordAppointmentScheduledRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordAppointmentScheduledRequestSchema =
  RecordAppointmentScheduledRequestBodySchema.merge(
    RecordAppointmentScheduledRequestHeadersSchema
  ).merge(RecordAppointmentScheduledRequestPathSchema)
export const RecordAppointmentScheduledResponseSchema = z.object({
  jobId: JobIdSchema,
  appointmentId: JobAppointmentIdSchema,
  appointmentChannel: AppointmentChannelSchema,
  appointmentDate: z.string().datetime(),
  appointmentDescription: z.string().optional(),
  appointmentType: AppointmentTypeSchema,
  participants: z.array(ContactCornSchema.or(z.string())),
})
export const RecordAppointmentScheduledApiSchema: ApiSchema = {
  body: RecordAppointmentScheduledRequestBodySchema,
  headers: RecordAppointmentScheduledRequestHeadersSchema,
  params: RecordAppointmentScheduledRequestPathSchema,
  response: RecordAppointmentScheduledResponseSchema,
}

export interface RecordAppointmentScheduledRequestBody
  extends z.infer<typeof RecordAppointmentScheduledRequestBodySchema> {}
export interface RecordAppointmentScheduledRequestPath
  extends z.infer<typeof RecordAppointmentScheduledRequestPathSchema> {}
export interface RecordAppointmentScheduledRequest
  extends z.infer<typeof RecordAppointmentScheduledRequestSchema> {}
export type RecordAppointmentScheduledResponse = z.infer<
  typeof RecordAppointmentScheduledResponseSchema
>
export interface RecordAppointmentScheduledHandler
  extends Handler<RecordAppointmentScheduledRequest, RecordAppointmentScheduledResponse> {}
