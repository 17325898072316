import { Chronicle } from '@eigtech/summarizer-types'
import { getTimelinePointMainDate } from './getTimelineDates'

export const makeTimelinePointComparator =
  (descending = false) =>
  (a: Pick<Chronicle, 'date' | 'label'>, b: Pick<Chronicle, 'date' | 'label'>) =>
    timelinePointsComparator(a, b, descending)

export const timelinePointsComparator = (
  a: Pick<Chronicle, 'date' | 'label'>,
  b: Pick<Chronicle, 'date' | 'label'>,
  desc = false
) => {
  if (a.date && b.date) {
    return a.date < b.date ? (desc ? 1 : -1) : desc ? -1 : 1
  }
  if (!a.date && !b.date) {
    return 0
  }
  if (!a.date || !b.date) {
    return a.date ? -1 : 1
  }
  if (a.date === b.date) {
    return a.label < b.label ? -1 : 1
  }
  return 0
}

export const timelinePointsMainDateComparator = (a: Chronicle, b: Chronicle) => {
  const aDate = getTimelinePointMainDate(a)
  const bDate = getTimelinePointMainDate(b)

  if (aDate && bDate) {
    return new Date(bDate).getTime() - new Date(aDate).getTime()
  }

  if (!aDate && !bDate) {
    return 0
  }

  if (!aDate || !bDate) {
    return aDate ? -1 : 1
  }

  if (aDate === bDate) {
    return a.label < b.label ? -1 : 1
  }

  return 0
}
