/**
 * Convert any string to a hex color
 *
 * @param string A string to convert
 * @param number offset
 * @returns A standard 6 character hex color
 *
 * @example
 * // returns '#6a1125'
 * stringToColor('example')
 */
export function stringToColor(string: string, offset = 5) {
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << offset) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}
