import {
  ContactRoleProducer as BaseContactRole,
  Book,
  Contact,
  ContactRoleConsumerSchema,
} from '@eigtech/contacts-types'
import { ContactFormContactRole, ContactFormRoleField } from '../ContactFormSchema'
import { getDefaultRoleFromBookName } from './getDefaultRoleFromBookName'

export function mapContactToRoles(contact: Partial<Contact>, book: Book | undefined) {
  const bookName = book?.metadata.name ?? ''
  const defaultRole = getDefaultRoleFromBookName(bookName ?? '')

  const fallback = [{ role: defaultRole ?? ('' as ContactFormContactRole) }]

  return (
    contact.roles?.map((role) => {
      const transformedRole = ContactRoleConsumerSchema.safeParse(role)

      return {
        role: (transformedRole.success ? transformedRole.data : role) as ContactFormContactRole,
      }
    }) ?? fallback
  )
}

export function mapRolesToContact(roles: Array<ContactFormRoleField>): BaseContactRole[] {
  return roles.filter(({ role }) => !!role).map(({ role }) => role)
}
