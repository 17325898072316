import { CallbackRequest } from '@eigtech/communications-types'

export const statuses: Record<CallbackRequest['status'], string> = {
  ATTEMPTED: 'Attempted',
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  REQUESTED: 'Requested',
}

export const statusesArray = Object.entries(statuses).map(([status, label]) => ({
  value: status as CallbackRequest['status'],
  label,
}))
