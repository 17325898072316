import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, Auth0InboxMessageSchema } from '../../base/index.js'

export const UpdateAllInboxMessageStatusesRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const UpdateAllInboxMessageStatusesRequestPathSchema = z.object({
  inboxId: Auth0IdSchema,
})
export const UpdateAllInboxMessageStatusesRequestBodySchema = Auth0InboxMessageSchema.pick({
  messageStatus: true,
})

export const UpdateAllInboxMessageStatusesRequestSchema =
  UpdateAllInboxMessageStatusesRequestPathSchema.merge(
    UpdateAllInboxMessageStatusesRequestBodySchema
  ).merge(UpdateAllInboxMessageStatusesRequestHeaderSchema)

export type UpdateAllInboxMessageStatusesRequestPath = z.infer<
  typeof UpdateAllInboxMessageStatusesRequestPathSchema
>
export type UpdateAllInboxMessageStatusesRequestBody = z.infer<
  typeof UpdateAllInboxMessageStatusesRequestBodySchema
>
export type UpdateAllInboxMessageStatusesRequest = z.infer<
  typeof UpdateAllInboxMessageStatusesRequestSchema
>

export const UpdateAllInboxMessageStatusesResponseSchema = z.void()
export type UpdateAllInboxMessageStatusesResponse = z.infer<
  typeof UpdateAllInboxMessageStatusesResponseSchema
>

export interface UpdateAllInboxMessageStatusesHandler
  extends Handler<UpdateAllInboxMessageStatusesRequest, UpdateAllInboxMessageStatusesResponse> {}
