import { Handler } from 'aws-lambda'
import z from 'zod'
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js'

//"description": "Delete members from an organization",
//path "/api/v2/organizations/org_Miqv9tEm8irQwL17/members"
export const UserRemovedFromOrgBodySchema = SuccessApiOpDetailsSchema.extend({
  request: SuccessApiOpDetailsSchema.shape.request.extend({
    method: z.literal('delete'),
    query: z.object({}),
    body: z.object({
      members: z.string().array(),
    }),
  }),
  response: SuccessApiOpDetailsSchema.shape.response.extend({
    body: z.object({}),
  }),
})

export const UserRemovedFromOrgPathSchema = z.object({
  orgId: z.string(),
})

export const UserRemovedFromOrgRequestSchema = UserRemovedFromOrgBodySchema.merge(
  UserRemovedFromOrgPathSchema
)

export type UserRemovedFromOrgRequest = z.infer<typeof UserRemovedFromOrgRequestSchema>
export type UserRemovedFromOrgResponse = void

export type UserRemovedFromOrgHandler = Handler<
  UserRemovedFromOrgRequest,
  UserRemovedFromOrgResponse
>
