import { isISOish } from '@eigtech/function-utils'
import { z } from 'zod'
import { claimsServiceCorn } from '../base/service.js'

export const ClaimStatusCornSchema = claimsServiceCorn('status')

export const ClaimStatusAdminEventNameSchema = z.enum(['idUpdated', 'deleted'])
export const DeprecatedClaimStatusEventNameEnumSchema = z.enum([
  'fileReviewerAssigned',
  'fileReviewerRemoved',
])
export const ProducerClaimStatusEventNameEnumSchema = z.enum([
  'canceled',
  'carrierApprovedEstimate',
  'carrierApprovedEstimateWithExceptions',
  'carrierRejectedEstimate',
  'claimInvoiceCreated',
  'clientApproved',
  'clientRejected',
  'coordinatorAssigned',
  'coordinatorRemoved',
  'customerContactedEmail',
  'customerContactedLvm',
  'customerNoContact',
  'dateReceived',
  'estimateRevisionRequested',
  'estimateSentToCarrier',
  'fieldAdjusterAssigned',
  'fieldAdjusterContactedCustomer',
  'fieldAdjusterCorrectionUploaded',
  'fieldAdjusterEstimateUploaded',
  'fieldAdjusterInspectionCompleted',
  'fieldAdjusterInspectionScheduled',
  'fieldAdjusterReassigned',
  'fieldAdjusterRemoved',
  'inAssignQueue',
  'initialCustomerContactAttempted',
  'initialCustomerContactFailed',
  'initialCustomerContactSuccess',
  'invalidContactInformation',
  'jobCompleted',
  'jobContracted',
  'jobNotSold',
  'jobSold',
  'jobStarted',
  'lossDate',
  'otrInvoked',
  'projectManagerAssigned',
  'projectManagerRemoved',
  'qaApproved',
  'qaApprovedPreliminaryReport',
  'qaApprovedSupplementReport',
  'qaRejected',
  'reInspectionRequested',
  'reviewerAssigned',
  'reviewerRemoved',
  'surveySentToCustomer',
  'wadSentToCustomer',
])

export const ClaimStatusEventNameLaxSchema = z.enum([
  ...ProducerClaimStatusEventNameEnumSchema.options,
  ...DeprecatedClaimStatusEventNameEnumSchema.options,
])

export const ClaimStatusEventNameCanonSchema = z
  .enum([...ClaimStatusEventNameLaxSchema.options])
  .transform((eventName) => {
    switch (eventName) {
      case 'fileReviewerAssigned':
        return ProducerClaimStatusEventNameEnumSchema.Enum.reviewerAssigned
      case 'fileReviewerRemoved':
        return ProducerClaimStatusEventNameEnumSchema.Enum.reviewerRemoved
      default:
        return eventName
    }
  })

export const ClaimStatusSourceSchema = z.enum([
  'Verisk',
  'CSR',
  'MOCKERY',
  'Assignments',
  'Estimator',
  'Symbility',
])
export const ClaimStatusSource = ClaimStatusSourceSchema.Values

export const ClaimStatusCanonSchema = z.object({
  id: z.string(),
  claimNumber: z.string(),
  statusEvent: ClaimStatusEventNameCanonSchema,
  date: z.string().refine(isISOish, { message: 'Not nearly ISO enough' }),
  dataSource: ClaimStatusSourceSchema.or(z.string()),
  createdBy: z.string().optional(),
  publicationTargets: z.string().array().optional(),
  notes: z.string().optional(),
})

export const ClaimStatusLaxSchema = z.object({
  id: z.string(),
  claimNumber: z.string(),
  statusEvent: ClaimStatusEventNameLaxSchema,
  date: z.string().refine(isISOish, { message: 'Not nearly ISO enough' }),
  dataSource: ClaimStatusSourceSchema.or(z.string()),
  createdBy: z.string().optional(),
  publicationTargets: z.string().array().optional(),
})

export const PublishableEventSchema = z.enum([
  ProducerClaimStatusEventNameEnumSchema.Enum.carrierApprovedEstimate,
  ProducerClaimStatusEventNameEnumSchema.Enum.carrierRejectedEstimate,
  ProducerClaimStatusEventNameEnumSchema.Enum.clientApproved,
  ProducerClaimStatusEventNameEnumSchema.Enum.clientRejected,
  ProducerClaimStatusEventNameEnumSchema.Enum.customerContactedEmail,
  ProducerClaimStatusEventNameEnumSchema.Enum.customerContactedLvm,
  ProducerClaimStatusEventNameEnumSchema.Enum.customerNoContact,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterContactedCustomer,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterInspectionCompleted,
  ProducerClaimStatusEventNameEnumSchema.Enum.fieldAdjusterInspectionScheduled,
  ProducerClaimStatusEventNameEnumSchema.Enum.initialCustomerContactSuccess,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobCompleted,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobContracted,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobNotSold,
  ProducerClaimStatusEventNameEnumSchema.Enum.jobStarted,
  ProducerClaimStatusEventNameEnumSchema.Enum.qaApproved,
  ProducerClaimStatusEventNameEnumSchema.Enum.qaRejected,
  ProducerClaimStatusEventNameEnumSchema.Enum.reInspectionRequested,
])

export const publishableEvents = PublishableEventSchema.options

export type PublishableEvent = z.infer<typeof PublishableEventSchema>

export type ClaimStatusCanon = z.infer<typeof ClaimStatusCanonSchema>
export type ClaimStatusLax = z.infer<typeof ClaimStatusLaxSchema>
export type ClaimStatusCorn = z.infer<typeof ClaimStatusCornSchema>
export type ClaimStatusEventNameCanon = z.infer<typeof ClaimStatusEventNameCanonSchema>
export type ClaimStatusEventNameLax = z.infer<typeof ClaimStatusEventNameLaxSchema>
export type ClaimStatusAdminEventName = z.infer<typeof ClaimStatusAdminEventNameSchema>
export type ClaimStatusSourceEnum = z.infer<typeof ClaimStatusSourceSchema>
