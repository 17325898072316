import { Contact } from '@eigtech/contacts-types'
import { getContactName, getContactRoleLabel, getPreferredContactMethod } from '../utils'

export type AugmentedContact = Omit<Contact, 'description'> & {
  contactName: string
  preferredContact: string
  labeledRoles: string[]
  rolesString: string
  description: string
}

export const augmentContact = (contact: Contact): AugmentedContact => ({
  picture: '',
  ...contact,
  description: contact.description ?? '',
  metadata: {
    licenseNumber: '',
    inactive: !!contact.metadata.inactive,
    removed: !!contact.metadata.removed,
    ...contact.metadata,
  },
  contactName: getContactName(contact),
  preferredContact: getPreferredContactMethod(contact),
  labeledRoles: contact.roles.map(getContactRoleLabel),
  rolesString: contact.roles.map(getContactRoleLabel).join(', '),
})
