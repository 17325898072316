import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ImportStatusesSchema } from './importStatus'

export const NoteIdSchema = z.string()

export const GetNoteImportStatusRequestQueryStringSchema = z.object({
  claimNumber: z.string().optional(),
  transactionId: z.string().optional(),
})

export const GetNoteImportStatusRequestSchema = GetNoteImportStatusRequestQueryStringSchema

export const GetNoteImportStatusResponseSchema = z.record(NoteIdSchema, ImportStatusesSchema)

export type GetNoteImportStatusRequest = z.infer<typeof GetNoteImportStatusRequestSchema>
export type GetNoteImportStatusResponse = z.infer<typeof GetNoteImportStatusResponseSchema>

export type GetNoteImportStatusHandler = Handler<
  GetNoteImportStatusRequest,
  GetNoteImportStatusResponse
>

export const GetNoteImportStatusApiSchema: ApiSchema = {
  query: GetNoteImportStatusRequestQueryStringSchema,
  response: GetNoteImportStatusResponseSchema,
}
