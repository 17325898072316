import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import log from '@eigtech/ui-shared-logging'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useEffect } from 'react'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { subscribeWithSelector } from 'zustand/middleware'

export type GetAccessToken = (options?: GetTokenSilentlyOptions) => Promise<string>

export type AuthState = {
  accessToken: null | string
  hasSetAccessToken: boolean
  getAccessToken: GetAccessToken
  setGetAccessToken: (getAccessToken: GetAccessToken) => any
}

export const useAuthStore = create<AuthState>()(
  subscribeWithSelector((set) => {
    function setAccessToken(accessToken: string) {
      const decodedToken = jwtDecode<JwtPayload>(accessToken)

      if (!!decodedToken.exp) {
        const expires = decodedToken.exp * 1000
        const timeUntilExpires = expires - Date.now()
        setTimeout(() => {
          setGetAccessToken(useAuthStore.getState().getAccessToken)
        }, timeUntilExpires)
      }

      set(() => ({ accessToken }))
    }

    async function setGetAccessToken(getAccessToken: GetAccessToken) {
      const accessToken = await getAccessToken()
      setAccessToken(accessToken)
      set(() => ({
        getAccessToken,
        hasSetAccessToken: true,
      }))
    }

    return {
      accessToken: null,
      hasSetAccessToken: false,
      getAccessToken: () => Promise.resolve(''),
      setGetAccessToken,
    }
  })
)

export function useCheckIfAccessTokenSet() {
  return useAuthStore((state) => state.hasSetAccessToken)
}

export function useSyncGetAccessTokenToStore() {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()

  const { hasSetAccessToken, setGetAccessToken } = useAuthStore(
    ({ hasSetAccessToken, setGetAccessToken }) => ({ hasSetAccessToken, setGetAccessToken }),
    shallow
  )

  useEffect(() => {
    if (!isAuthenticated) return
    setGetAccessToken(getAccessTokenSilently)
  }, [isAuthenticated, setGetAccessToken, getAccessTokenSilently])

  useEffect(() => {
    if (!user) return

    async function logUserAndToken() {
      log.info('user', user)
      const token = await getAccessTokenSilently()
      const decodedToken = jwtDecode(token)
      log.info('token', { token, decodedToken })
    }

    logUserAndToken()
  }, [getAccessTokenSilently, user])

  return { hasSetAccessToken }
}

export const accessToken = () => useAuthStore.getState().accessToken

export const getAccessToken = () => useAuthStore.getState().getAccessToken()
export const getHasSetAccessToken = () => useAuthStore.getState().hasSetAccessToken
