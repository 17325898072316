import { JobId, isJobCorn } from '@eigtech/restoration-types'
import { getCornKernels, isCorn } from '@eigtech/shared-corn'
import { getJobLabelFromId } from '@eigtech/ui-shared-jobs'

export function getExportEntityLabel(entityCorn: string) {
  const resourceId = isCorn(entityCorn) ? getCornKernels(entityCorn).resourceId : entityCorn

  if (isJobCorn(entityCorn)) {
    return getJobLabelFromId(resourceId as JobId)
  }

  return resourceId
}
