import { Contact, ContactAddress } from '@eigtech/contacts-types'
import { objHasKeys, omitEmptyStringDeep } from '@eigtech/function-utils'
import { UsStateAbbreviated } from '@eigtech/ui-shared-us-states'
import { ContactFormAddressField, ContactFormAddressTypesFieldSchema } from '../ContactFormSchema'

export function mapContactToAddresses(contact: Partial<Contact>) {
  return contact.addresses?.map((address) => ({
    city: address?.city,
    state: (address?.stateOrProvince ?? '') as UsStateAbbreviated,
    streetAddress: address?.streetAddress?.streetOrMailingAddress,
    streetAddress2: address?.otherDesignation,
    zip: address?.zipOrPostalCode,
    // some address types come in as a string that does not match the AddressType schema
    addressType: ContactFormAddressTypesFieldSchema.safeParse(address?.addressType).success
      ? address.addressType
      : '',
    rawAddress: address.rawAddress,
  }))
}

export function mapAddressesToContact(addresses: ContactFormAddressField[]): ContactAddress[] {
  return addresses
    .map((address) => ({
      id: address.id,
      streetAddress: !!address.streetAddress
        ? { streetOrMailingAddress: address.streetAddress }
        : undefined,
      otherDesignation: address.streetAddress2,
      city: address.city,
      stateOrProvince: address.state,
      zipOrPostalCode: address.zip,
      rawAddress:
        [address.streetAddress, address.streetAddress2, address.city, address.state, address.zip]
          .filter((value) => !!value)
          .join(', ') ||
        // some contact info will come in with only a rawAddress. if the address is not updated,
        // just pass the rawAddress along to prevent breakage
        (address.rawAddress ?? ''),

      addressType: address.addressType || undefined,
      location: address.location,
    }))
    .map(omitEmptyStringDeep)
    .filter(objHasKeys)
}
