import { Radio, RadioProps } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { FieldValues } from 'react-hook-form'
import { RadioGroupControl, RadioGroupControlProps } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'
import type { RequireAtLeastOne } from 'type-fest'

export type RadioGroupFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  WithFieldNameProp<RadioGroupControlProps, TFieldValues>,
  'children'
> &
  RequireAtLeastOne<
    {
      children?: ReactNode
      options?: { value: string; label?: string }[]
      radioProps?: RadioProps
    },
    'children' | 'options'
  >

export function RadioGroupField<TFieldValues extends FieldValues = FieldValues>({
  children,
  options,
  radioProps,
  ...props
}: RadioGroupFieldProps<TFieldValues>) {
  return (
    <RadioGroupControl {...props}>
      {children ??
        (options ?? []).map(({ value, label }) => (
          <Radio isDisabled={props.isDisabled} {...radioProps} key={value} value={value}>
            {label ?? value}
          </Radio>
        ))}
    </RadioGroupControl>
  )
}

export type RadioGroupFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof RadioGroupField<TFieldValues>
