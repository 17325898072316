import {
  ClaimDefaultChronicleTypeEnum,
  GetTimelineResponse,
  TimelineTypeEnum,
} from '@eigtech/summarizer-types'
import { useQueryClient } from '@eigtech/ui-shared-api'
import { useAuth0 } from '@eigtech/ui-shared-auth'
import { getClaimTimelineEventLabel, useInvalidateClaim } from '@eigtech/ui-shared-claims'
import { secondsToMilliseconds } from '@eigtech/ui-shared-dates'
import { useCallback } from 'react'
import { timelineQueryKeys } from '../constants'

type AddClaimTimelineEventOptions<TDetails> = {
  claimNumber: string
  timelineEvent: ClaimDefaultChronicleTypeEnum
  chronicle: {
    date?: string
    details?: TDetails
  }
}

export function useOptimisticAddClaimTimelineEvent(timelineType: TimelineTypeEnum) {
  const invalidateClaim = useInvalidateClaim()
  const { user } = useAuth0()
  const queryClient = useQueryClient()

  return useCallback(
    async <TDetails>({
      claimNumber,
      timelineEvent,
      chronicle,
    }: AddClaimTimelineEventOptions<TDetails>) => {
      const queryKey = timelineQueryKeys.timeline({
        entityId: claimNumber,
        entityType: 'claim',
        timelineType,
      })

      await queryClient.cancelQueries({ queryKey })

      const previous = queryClient.getQueryData<GetTimelineResponse>(queryKey)

      if (!!previous) {
        queryClient.setQueryData<GetTimelineResponse>(queryKey, () => ({
          ...previous,
          timeline: {
            ...previous.timeline,
            chronicles: [
              ...(previous.timeline?.chronicles ?? []),
              {
                createdBy: user?.sub ?? 'User',
                date: new Date().toISOString(),
                domain: 'claims-v2',
                label: getClaimTimelineEventLabel(timelineEvent),
                type: timelineEvent,
                ...chronicle,
                details: {
                  recordedDate: new Date().toISOString(),
                  dataSource: 'EIG',
                  ...chronicle.details,
                },
              },
            ],
          },
        }))
      }

      setTimeout(() => {
        invalidateClaim(claimNumber)
      }, secondsToMilliseconds(45))
    },
    [invalidateClaim, queryClient, timelineType, user?.sub]
  )
}
