import { z } from 'zod'

const BaseTemplateSchema = z.object({
  id: z.string(),
  name: z.string(),
  lastUpdated: z.string().optional(),
  createdOn: z.string(),
  modifiedBy: z.string().optional(),
})

// Communication Template
export const CommunicationTemplateTypeSchema = z.literal('templates:types:communication')

export const CommunicationTemplateSchema = BaseTemplateSchema.merge(
  z.object({
    type: CommunicationTemplateTypeSchema,
    smsTemplate: z.string().optional().nullable(),
    subject: z.string().optional(),
    htmlTemplate: z.string(),
    textTemplate: z.string().optional(),
  })
)

// Note template
export const NoteTemplateTypeSchema = z.literal('templates:types:note')

export const NoteTemplateSchema = BaseTemplateSchema.merge(
  z.object({
    type: NoteTemplateTypeSchema,
    textTemplate: z.string(),
    textTemplateParams: z.string().array().optional(),
  })
)

// Timeline template
export const TimelineTemplateTypeSchema = z.literal('templates:types:timeline')
export const TimelineInstructionOptionsSchema = z.object({
  from: z.string().array().optional(),
  label: z.string(),
  noRepeat: z.boolean().optional(),
  on: z.string().array().optional(),
  overridesOrder: z.boolean().optional(),
  order: z.number().optional(),
  requires: z.string().array().optional(),
  shouldNotSupersede: z.string().array().optional(),
  to: z.array(
    z.object({
      on: z.string().array(),
      key: z.string().or(z.undefined()),
      requires: z.string().array().optional(),
    })
  ),
  unique: z.boolean().optional(),
})

export const TimelineInstructionTemplateSchema = z.record(
  z.string(),
  TimelineInstructionOptionsSchema
)

export const TimelineTemplateSchema = BaseTemplateSchema.merge(
  z.object({
    type: TimelineTemplateTypeSchema,
    instructionTemplate: TimelineInstructionTemplateSchema,
    timelineType: z.string(),
    isLinear: z.boolean().optional(),
  })
)

export const DefaultTimelineTemplateSchema = TimelineTemplateSchema.omit({
  createdOn: true,
  id: true,
}).merge(
  z.object({
    createdOn: TimelineTemplateSchema.shape.createdOn.optional(),
    id: TimelineTemplateSchema.shape.id.optional(),
  })
)

// Util method for timeline service to validate specific templates
export const makeTimelineInstructionTemplateSchema = <
  T extends [string, ...string[]],
  U extends [string, ...string[]],
>(
  eventType: z.ZodEnum<T>,
  pointType: z.ZodEnum<U>
) =>
  z.record(
    pointType,
    z.object({
      from: pointType.array().optional(),
      label: z.string(),
      on: eventType.array().optional(),
      order: z.number().optional(),
      overridesOrder: z.boolean().optional(),
      noRepeat: z.boolean().optional(),
      requires: pointType.array().optional(),
      shouldNotSupersede: pointType.array().optional(),
      to: z.array(
        z.object({
          on: eventType.array(),
          key: pointType.or(z.undefined()),
          requires: pointType.array().optional(),
        })
      ),
      unique: z.boolean().optional(),
    })
  )

// Template
export const TemplateSchema = z.discriminatedUnion('type', [
  CommunicationTemplateSchema,
  NoteTemplateSchema,
  TimelineTemplateSchema,
])

export const TemplateRecordSchema = z
  .object({
    PK: z.string(),
    SK: z.string(),
    'GSI-PK-1': z.string(),
    'GSI-SK-1': z.string(),
    deleted: z.boolean().optional(),
  })
  .and(TemplateSchema)

export const TemplateTypesSchema = CommunicationTemplateTypeSchema.or(NoteTemplateTypeSchema).or(
  TimelineTemplateTypeSchema
)

export type Template = z.infer<typeof TemplateSchema>
export type TemplateRecord = z.infer<typeof TemplateRecordSchema>

export type CommunicationTemplate = z.infer<typeof CommunicationTemplateSchema>
export type CommunicationTemplateType = z.infer<typeof CommunicationTemplateTypeSchema>

export type NoteTemplate = z.infer<typeof NoteTemplateSchema>
export type NoteTemplateType = z.infer<typeof NoteTemplateTypeSchema>

/**
  @param type Type of template: "templates:types:timeline"
  @param instructionTemplate The instructions used to construct timeline points. Refer to `@eigtech/templates-type.TimelineInstructionTemplate`.
  @param timelineType Determines which type of timeline should use this template. Refer to `@eigtech/timeline-types.TimelineType`.
 */
export type TimelineTemplate = z.infer<typeof TimelineTemplateSchema>
export type DefaultTimelineTemplate = z.infer<typeof DefaultTimelineTemplateSchema>

/**
 * Provides instructions to construct a timeline based on events. The instructions make the following assertions:
 * * Empty arrays are treated as ANY
 * * Order matters in arrays. First items always get priority.
 *
 * The instructions are carried out in the following order:
 * 1. Check `to` parameter in the latest timeline point if it exists and evaluate its parameters.
 *    * Check `on` to see if it triggers the condition.
 *    * If the `on` includes the event, check `requires` to ensure a previous timeline point has happened.
 *    * If it has, use the `key` as the next timeline point.
 *    * If `key` is undefined, no timeline point is added.
 * 2. Check ON for matches
 *    * Ensure the latest timeline point is included in the `from` timeline points.
 *    * Ensure that `requires` timeline points have happened.
 *    * If it passes the `on` and `requires` checks, the timeline point is added.
  @param label The label for the timeline point.
  @param on The `eventName` that triggers the timeline point.
  @param from Includes the previous timeline point required to allow the construction of this timeline point.
  @param requires Includes any required timeline points that must exist in order for the construction of this timeline point.
  @param to Includes rules to prevent or allow the next timeline point. This parameter takes priority over all others.
 */
export type TimelineInstructionTemplate = z.infer<typeof TimelineInstructionTemplateSchema>
export type TimelineTemplateType = z.infer<typeof TimelineTemplateTypeSchema>
export type TimelineInstructionOptions = z.infer<typeof TimelineInstructionOptionsSchema>

export type TemplateTypes = z.infer<typeof TemplateTypesSchema>
