import { getResourceId } from '@eigtech/shared-corn'
import { ExpandingIconButton } from '@eigtech/ui-shared-dave'
import {
  FloatingMeetingControls as BaseFloatingMeetingControls,
  MeetingState,
  useMeetingStore,
} from '@eigtech/ui-shared-meetings'
import { useMatches, useNavigate } from '@eigtech/ui-shared-router'
import { MdLink } from 'react-icons/md'
import { ROUTER_PATHS } from '../../../../lib/constants'

export function FloatingMeetingControls() {
  const navigate = useNavigate()
  const matches = useMatches({
    experimental_returnIntersection: true,
  })

  const { meetingDetails } = useMeetingStore(stateSelector)

  const claimNumber = meetingDetails?.entityId ? getResourceId(meetingDetails.entityId) : undefined

  const isOnMeetingPage = matches.some(
    ({ params, routeId }) =>
      routeId.includes('meetings') &&
      params.meetingId === meetingDetails?.meetingId &&
      params.meetingStartTime === meetingDetails?.startTime &&
      params.claimNumber === claimNumber
  )

  return (
    <BaseFloatingMeetingControls
      leftButtons={[
        ...(!!meetingDetails && !!claimNumber && !isOnMeetingPage
          ? [
              <ExpandingIconButton
                key="meeting"
                icon={<MdLink />}
                onClick={() =>
                  navigate({
                    to: ROUTER_PATHS.claimDetailMeeting.route,
                    params: {
                      claimNumber,
                      meetingId: meetingDetails.meetingId,
                      meetingStartTime: meetingDetails.startTime,
                    },
                  })
                }
              >
                Go To Meeting
              </ExpandingIconButton>,
            ]
          : []),
      ]}
    />
  )
}

const stateSelector = ({ meetingDetails }: MeetingState) => ({ meetingDetails })
