import { MenuIcon } from '@eigtech/ui-estimator/components/Core'
import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useCopyToClipboard,
} from '@eigtech/ui-shared-dave'
import { LinkMenuItem } from '@eigtech/ui-shared-router'
import { ROUTER_PATHS } from '../../../../lib/constants'

export function ClaimActionMenu({ claim }: { claim: DelegateAdminClaimSummary }) {
  const { copyToClipboard } = useCopyToClipboard()

  return (
    <Menu computePositionOnMount>
      <MenuButton aria-label="Open claim menu" as={IconButton} icon={<MenuIcon />} size="sm" />
      <Portal>
        <MenuList>
          <LinkMenuItem
            params={{ claimNumber: claim.claimNumber }}
            to={ROUTER_PATHS.claimDetail.route}
          >
            Open Claim
          </LinkMenuItem>
          <MenuItem onClick={() => copyToClipboard(claim.claimNumber)}>Copy Claim Number</MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
