import { FieldValues } from 'react-hook-form'
import { CheckboxSingleControl, CheckboxSingleProps } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type CheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> = WithFieldNameProp<
  CheckboxSingleProps,
  TFieldValues
>

export function CheckboxField<TFieldValues extends FieldValues = FieldValues>({
  children,
  checkBoxProps,
  ...props
}: CheckboxFieldProps<TFieldValues>) {
  return (
    <CheckboxSingleControl
      checkBoxProps={{
        isDisabled: props.isDisabled ?? checkBoxProps?.isDisabled,
        isReadOnly: props.isReadOnly ?? checkBoxProps?.isReadOnly,
        textStyle: 'label',
        ...checkBoxProps,
      }}
      {...props}
    >
      {children}
    </CheckboxSingleControl>
  )
}

export type CheckboxFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof CheckboxField<TFieldValues>
