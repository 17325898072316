import { DocumentV2 } from '@eigtech/documents-types'
import { DataGrid, DataGridProps, Table } from '@eigtech/ui-shared-data-grid'
import { Flex, IconButton } from '@eigtech/ui-shared-dave'
import {
  FC,
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { IoDownload } from 'react-icons/io5'
import { useGetDocumentUrl } from '../api/getDocumentUrl'
import { useOrganizeDocuments } from '../hooks/useOrganizeDocuments'
import { DocumentV2WithTags } from '../types/DocumentV2WithTags'
import {
  DocumentColumn,
  documentColumnHelper,
  hiddenDocumentColumns,
  useSharedDocumentsColumns,
} from './DocumentsTableColumns'

export type DocumentsTableProps = {
  documents: DocumentV2WithTags[] | undefined
  enableBulkActions: boolean
  ActionMenu?: FC<{ document: DocumentColumn }>
  hideColumns?: Partial<Record<keyof ReturnType<typeof useSharedDocumentsColumns>, boolean>>
} & Omit<DataGridProps<DocumentColumn>, 'data' | 'columns'>

export const DocumentsTable = forwardRef(function DocumentsTable(
  { documents, ActionMenu, enableBulkActions, hideColumns, ...tableProps }: DocumentsTableProps,
  ref: ForwardedRef<Table<DocumentColumn>>
) {
  const tableRef = useRef<Table<DocumentColumn>>(null!)
  useImperativeHandle(ref, () => ({
    ...tableRef.current,
  }))

  const columns = useDocumentsColumns({
    ActionMenu,
    enableBulkActions,
    hideColumns,
  })

  const groupedDocuments = useOrganizeDocuments(documents)

  return (
    <DataGrid
      ref={tableRef}
      {...tableProps}
      columns={columns}
      data={groupedDocuments}
      getSubRows={(row) => row.subRows}
      initialState={hiddenDocumentColumns}
      state={{ ...tableProps.state }}
    />
  )
})

export function useDocumentsColumns({
  enableBulkActions,
  hideColumns,
  ActionMenu,
}: Pick<DocumentsTableProps, 'enableBulkActions' | 'hideColumns'> & {
  ActionMenu?: FC<{ document: DocumentColumn }>
}) {
  const sharedColumns = useSharedDocumentsColumns()

  return useMemo(
    () => [
      ...(enableBulkActions ? [sharedColumns.actionMenu({ ActionMenu })] : []),
      ...(enableBulkActions && !hideColumns?.importStatus ? [sharedColumns.importStatus] : []),
      ...(!hideColumns?.title ? [sharedColumns.title] : []),
      ...(!hideColumns?.description ? [sharedColumns.description] : []),
      ...(!hideColumns?.filename ? [sharedColumns.filename] : []),
      ...(!hideColumns?.visibility ? [sharedColumns.visibility] : []),
      ...(!hideColumns?.exportHistory ? [sharedColumns.exportHistory] : []),
      ...(!hideColumns?.createdDate ? [sharedColumns.createdDate] : []),
      ...(!hideColumns?.modifiedDate ? [sharedColumns.modifiedDate] : []),
      ...(!hideColumns?.source ? [sharedColumns.source] : []),
      ...(!hideColumns?.dates ? [sharedColumns.dates] : []),
      ...(!hideColumns?.meta ? [sharedColumns.meta] : []),
      ...(!hideColumns?.type ? [sharedColumns.type] : []),
      ...(!hideColumns?.tags ? [sharedColumns.tags] : []),
      documentColumnHelper.display({
        id: 'download',
        header: '',
        maxSize: 80,
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        cell: (cell) => <DocumentDownload {...cell.row.original} />,
      }),
    ],
    [ActionMenu, enableBulkActions, hideColumns, sharedColumns]
  )
}

function DocumentDownload({ id, entityId }: DocumentV2) {
  const { data: url, isLoading, isError, refetch } = useGetDocumentUrl({ entityId, documentId: id })

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <Flex justifyContent="flex-end">
      <IconButton
        aria-label="Download file."
        as="a"
        href={url}
        icon={<IoDownload />}
        isDisabled={isError}
        isLoading={isLoading}
        target={id}
        variant="outline"
      />
    </Flex>
  )
}
