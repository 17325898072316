import { Dropzone, DropzoneProps } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import { BaseProps, FormControl } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type DropzoneFieldProps<TFieldValues extends FieldValues = FieldValues> = WithFieldNameProp<
  Omit<DropzoneProps, keyof BaseProps>,
  TFieldValues
> & {
  label?: ReactNode
  fieldProps?: Omit<BaseProps, 'label' | 'name'>
}

export function DropzoneField<TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  fieldProps,
  ...dropzoneProps
}: DropzoneFieldProps<TFieldValues>) {
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control: fieldProps?.control,
  })

  return (
    <FormControl {...fieldProps} label={label} name={name}>
      <Dropzone
        {...field}
        isDisabled={isSubmitting ?? fieldProps?.isDisabled}
        isReadOnly={fieldProps?.isReadOnly}
        {...dropzoneProps}
      />
    </FormControl>
  )
}

export type DropzoneFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof DropzoneField<TFieldValues>
