import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { AlertSubscriptionSchema } from '../base.js'

export const CreateAlertSubscriptionBodyRequestSchema = AlertSubscriptionSchema.pick({
  alertLevel: true,
  email: true,
})

export const CreateAlertSubscriptionRequestSchema = CreateAlertSubscriptionBodyRequestSchema
export type CreateAlertSubscriptionRequest = z.infer<typeof CreateAlertSubscriptionRequestSchema>

export const CreateAlertSubscriptionResponseSchema = AlertSubscriptionSchema
export type CreateAlertSubscriptionResponse = z.infer<typeof CreateAlertSubscriptionResponseSchema>

export type CreateAlertSubscriptionHandler = Handler<
  CreateAlertSubscriptionRequest,
  CreateAlertSubscriptionResponse
>
