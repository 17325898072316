import {
  WorkAuthorizationStatusEnum,
  WorkAuthorizationStatusEnumSchema,
} from '@eigtech/restoration-types'
import type { CustomThemeTypings } from '@eigtech/ui-shared-dave'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'

const partialWorkAuthStatusLabels: Partial<Record<WorkAuthorizationStatusEnum, string>> = {}

export const getWorkAuthStatusLabel = (status: WorkAuthorizationStatusEnum) =>
  partialWorkAuthStatusLabels[status] ?? startCase(status)

export const workAuthStatusLabels = Object.fromEntries(
  WorkAuthorizationStatusEnumSchema.options.map((key) => [key, getWorkAuthStatusLabel(key)])
) as Record<WorkAuthorizationStatusEnum, string>

export const getWorkAuthStatusColorScheme = (status: WorkAuthorizationStatusEnum) =>
  exhaustive(status, {
    completed: (): CustomThemeTypings['colorSchemes'] => 'green',
    declined: (): CustomThemeTypings['colorSchemes'] => 'red',
    expired: (): CustomThemeTypings['colorSchemes'] => 'red',
    override_approved: (): CustomThemeTypings['colorSchemes'] => 'green',
    recalled: (): CustomThemeTypings['colorSchemes'] => 'orange',
    sent: (): CustomThemeTypings['colorSchemes'] => 'blue',
    signed: (): CustomThemeTypings['colorSchemes'] => 'green',
  })
