import { Handler } from 'aws-lambda'
import z from 'zod'
import { VediImportSchema } from '../vediImport'

export const GetClaimImportsApiSchema = {
  params: z.object({
    claimNumber: z.string(),
  }),
  response: z.object({
    results: VediImportSchema.array(),
  }),
}

export type GetClaimImportsRequest = z.infer<typeof GetClaimImportsApiSchema.params>
export type GetClaimImportsResponse = z.infer<typeof GetClaimImportsApiSchema.response>
export type GetClaimImportsHandler = Handler<GetClaimImportsRequest, GetClaimImportsResponse>
