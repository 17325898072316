import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const StartTranscriptionQueryRequestSchema = z.object({
  m: z.string(),
})

export const StartTranscriptionRequestSchema = StartTranscriptionQueryRequestSchema

export type StartTranscriptionRequest = z.infer<typeof StartTranscriptionRequestSchema>
export type StartTranscriptionResponse = void

export type StartTranscriptionHandler = Handler<
  StartTranscriptionRequest,
  StartTranscriptionResponse
>
