import { mimicTypeIdSchema } from '@eigtech/typeid'
import z from 'zod'

export const quickbooksIdPrefix = 'quickbooks'
export const QuickbooksIdSchema = mimicTypeIdSchema<typeof quickbooksIdPrefix>(quickbooksIdPrefix)
export type QuickbooksId = z.infer<typeof QuickbooksIdSchema>

export const JobExternalIdTypeEnumSchema = z.enum([quickbooksIdPrefix])
export type JobExternalIdTypeEnum = z.infer<typeof JobExternalIdTypeEnumSchema>

export const JobExternalIdSchema = QuickbooksIdSchema
export type JobExternalId = z.infer<typeof JobExternalIdSchema>
