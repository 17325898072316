import { forwardRef, useBreakpointValue } from '@chakra-ui/react'
import { Property, PropertyProps } from '../../Property'

export const ResponsiveProperty = forwardRef<PropertyProps, 'div'>((props, ref) => {
  const margin = useBreakpointValue({
    base: '0',
    md: '0.5rem',
  })

  return (
    <Property
      ref={ref}
      alignItems={['flex-start', 'center']}
      flexDir={['column', 'row']}
      labelWidth={['full', '40%', '35%', '30%']}
      sx={{
        '& .saas-property__label': {
          paddingBottom: margin,
        },
      }}
      {...props}
    />
  )
})
