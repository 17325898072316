import { z } from 'zod'

export const S3NewItemEventNameSchema = z.enum(['ObjectCreated:Copy', 'ObjectCreated:Put'])

export const S3NewItemMessageRecordSchema = z.object({
  eventVersion: z.string(),
  eventSource: z.string(),
  awsRegion: z.string(),
  eventTime: z.string().datetime(),
  eventName: S3NewItemEventNameSchema,
  userIdentity: z.object({
    principalId: z.string(),
  }),
  requestParameters: z.object({
    sourceIPAddress: z.string().ip(),
  }),
  responseElements: z.object({
    'x-amz-request-id': z.string(),
    'x-amz-id-2': z.string(),
  }),
  s3: z.object({
    s3SchemaVersion: z.string(),
    configurationId: z.string(),
    bucket: z.object({
      name: z.string(),
      ownerIdentity: z.object({
        principalId: z.string(),
      }),
      arn: z.string(),
    }),
    object: z.object({
      key: z.string(),
      size: z.number(),
      eTag: z.string(),
      sequencer: z.string(),
    }),
  }),
})

export const S3NewItemMessageSchema = z.object({
  Records: S3NewItemMessageRecordSchema.array(),
})

export type S3NewItemMessageRecord = z.infer<typeof S3NewItemMessageRecordSchema>
export type S3Message = z.infer<typeof S3NewItemMessageSchema>
