import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, ClaimFactorsSchema } from '../model/base'
import { BaseHeaderSchema } from './base'

const AddClaimFactorsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    claimFactors: ClaimFactorsSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    claimFactors: ClaimFactorsSchema.array(),
  }),
}

export const AddClaimFactorsRequestSchema = AddClaimFactorsSchemas.params
  .merge(AddClaimFactorsSchemas.body)
  .merge(AddClaimFactorsSchemas.headers)

export type AddClaimFactorsBodyRequest = z.infer<typeof AddClaimFactorsSchemas.body>
export type AddClaimFactorsHeaderRequest = z.infer<typeof AddClaimFactorsSchemas.headers>
export type AddClaimFactorsRequest = z.infer<typeof AddClaimFactorsRequestSchema>
export type AddClaimFactorsResponse = z.infer<typeof AddClaimFactorsSchemas.response>

export interface AddClaimFactorsHandler
  extends Handler<AddClaimFactorsRequest, AddClaimFactorsResponse> {}

export const AddClaimFactorsApiSchema: ApiSchema = AddClaimFactorsSchemas
