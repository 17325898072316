import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema } from '../../../base/contacts.js'

export const DeactivateContactRequestPathSchema = z.object({ contactId: ContactCornSchema })

export const DeactivateContactRequestHeadersSchema = ApiLambdalerAuthHeaderSchema
export const DeactivateContactRequestSchema = DeactivateContactRequestPathSchema.merge(
  DeactivateContactRequestHeadersSchema
)

export type DeactivateContactPath = z.infer<typeof DeactivateContactRequestPathSchema>
export type DeactivateContactRequest = z.infer<typeof DeactivateContactRequestSchema>

export type DeactivateContactResponse = void

export interface DeactivateContactHandler
  extends Handler<DeactivateContactRequest, DeactivateContactResponse> {}

export const DeactivateContactApiSchema: ApiSchema = {
  params: DeactivateContactRequestPathSchema,
  headers: DeactivateContactRequestHeadersSchema,
}
