import {
  IconButton,
  IconButtonProps,
  forwardRef,
  useFormControlContext,
} from '@eigtech/ui-shared-dave'
import { MdAdd, MdDelete } from 'react-icons/md'
import { useArrayFieldContext } from './ArrayFieldContext'
import { useArrayFieldRowContext } from './ArrayFieldRowContext'

export type ArrayFieldAddButtonProps = Partial<IconButtonProps>

export const ArrayFieldAddButton = forwardRef<ArrayFieldAddButtonProps, 'button'>((props, ref) => {
  const { append, defaultValue, max, fields } = useArrayFieldContext()

  const { id } = useFormControlContext()
  const addButtonId = `${id}-add-row`

  return (
    <IconButton
      ref={ref}
      aria-label="Add Row"
      icon={<MdAdd />}
      id={addButtonId}
      isDisabled={!!(max && fields.length >= max)}
      onClick={() => append(defaultValue ?? {})}
      {...props}
    />
  )
})

export function useArrayFieldAddButtonProps() {
  const { append, defaultValue, max, fields } = useArrayFieldContext()

  const isDisabled = !!(max && fields.length >= max)

  const { id } = useFormControlContext()
  const addButtonId = `${id}-add-row`

  return {
    isDisabled,
    onClick: () => append(defaultValue ?? {}),
    id: addButtonId,
  }
}

export type ArrayFieldRemoveButtonProps = Partial<IconButtonProps>

export const ArrayFieldRemoveButton = forwardRef<ArrayFieldRemoveButtonProps, 'button'>(
  (props, ref) => {
    const removeButtonProps = useArrayFieldRemoveButtonProps()

    return (
      <IconButton
        ref={ref}
        aria-label="Remove Row"
        colorScheme="red"
        icon={<MdDelete />}
        variant="outline"
        {...removeButtonProps}
        {...props}
      />
    )
  }
)

export function useArrayFieldRemoveButtonProps() {
  const { min, fields } = useArrayFieldContext()
  const { isFirst, remove, index } = useArrayFieldRowContext()

  const isDisabled = isFirst && !!(min && fields.length <= min)

  const { id } = useFormControlContext()
  const removeButtonId = `${id}-row-${index}-remove-row`

  return {
    isDisabled,
    onClick: remove,
    id: removeButtonId,
  }
}
