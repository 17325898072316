import {
  MarkForPublicationBodyRequest,
  MarkForPublicationPathRequest,
} from '@eigtech/documents-types'
import type { MarkNoteForPublicationResponse } from '@eigtech/notes-types'
import { contextualPatch, useMutation } from '@eigtech/ui-shared-api'
import { documentsBasePath } from './constants'
import { useInvalidateDocumentPublications } from './getDocumentPublications'
import { useInvalidateEntityDocuments } from './getEntityDocuments'

const markDocumentForPublication = ({
  documentCorn,
  entityId,
  ...payload
}: MarkForPublicationBodyRequest & MarkForPublicationPathRequest) =>
  contextualPatch<MarkNoteForPublicationResponse, MarkForPublicationBodyRequest>(
    `${documentsBasePath}/${entityId}/${documentCorn}/publish`,
    payload,
    { responseType: 'none' }
  )

export function useMarkDocumentForPublication() {
  const invalidateClaimDocuments = useInvalidateEntityDocuments()
  const invalidateDocumentPublications = useInvalidateDocumentPublications()

  return useMutation({
    mutationFn: markDocumentForPublication,
    onSuccess(__, { entityId, documentCorn }) {
      invalidateClaimDocuments(entityId)

      setTimeout(() => {
        invalidateDocumentPublications({ entityId, documentCorn })
      }, 1000)
    },
  })
}
