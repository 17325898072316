import { ComposedCard } from '@eigtech/ui-shared-dave'
import { ClaimsTable } from './Table'

export function DesktopClaimsDashboard() {
  return (
    <ComposedCard>
      <ClaimsTable />
    </ComposedCard>
  )
}
