import { Button, ButtonProps, forwardRef, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export type ExpandingIconButtonProps = {
  icon: ButtonProps['leftIcon']
  iconPosition?: 'left' | 'right'
} & Omit<ButtonProps, 'rightIcon' | 'leftIcon'>

export const ExpandingIconButton = forwardRef<ExpandingIconButtonProps, 'button'>(
  ({ children, icon, iconPosition = 'left', ...props }, ref) => {
    const isMobile = useBreakpointValue({
      base: true,
      md: false,
    })
    return isMobile ? (
      <IconButton
        ref={ref}
        aria-label={typeof children === 'string' ? children : 'Perform action'}
        className="expanding-icon-button expanding-icon-button--mobile"
        icon={icon}
        {...props}
      />
    ) : (
      <Button
        ref={ref}
        as={motion.button}
        className="expanding-icon-button"
        exit="collapsed"
        iconSpacing={0}
        initial="collapsed"
        px="3"
        whileHover="open"
        {...{ [iconPosition === 'left' ? 'leftIcon' : 'rightIcon']: icon }}
        {...props}
      >
        <motion.span
          style={{
            overflow: 'hidden',
            opacity: 0,
            width: 0,
          }}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          variants={{
            open: {
              opacity: 1,
              width: 'auto',
              [iconPosition === 'left' ? 'marginLeft' : 'marginRight']: '0.25em',
            },
            collapsed: {
              opacity: 0,
              width: 0,
              [iconPosition === 'left' ? 'marginLeft' : 'marginRight']: '0',
            },
          }}
        >
          {children}
        </motion.span>
      </Button>
    )
  }
)
