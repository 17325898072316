import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'
import { JobServicesSchema } from '../../base/jobService.js'

export const AddJobServicesRequestBodySchema = z.object({
  services: JobServicesSchema,
})
export const AddJobServicesRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const AddJobServicesRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const AddJobServicesRequestSchema = AddJobServicesRequestBodySchema.merge(
  AddJobServicesRequestHeadersSchema
).merge(AddJobServicesRequestPathSchema)
export const AddJobServicesResponseSchema = z.object({
  jobId: JobIdSchema,
  services: JobServicesSchema,
})
export const AddJobServicesApiSchema: ApiSchema = {
  body: AddJobServicesRequestBodySchema,
  headers: AddJobServicesRequestHeadersSchema,
  params: AddJobServicesRequestPathSchema,
  response: AddJobServicesResponseSchema,
}

export type AddJobServicesRequestBody = z.infer<typeof AddJobServicesRequestBodySchema>
export type AddJobServicesRequestPath = z.infer<typeof AddJobServicesRequestPathSchema>
export interface AddJobServicesRequest extends z.infer<typeof AddJobServicesRequestSchema> {}
export type AddJobServicesResponse = z.infer<typeof AddJobServicesResponseSchema>
export interface AddJobServicesHandler
  extends Handler<AddJobServicesRequest, AddJobServicesResponse> {}
