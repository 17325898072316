import { Contact, ContactCommunicationInformation } from '@eigtech/contacts-types'
import { isInEffect } from '../utils/inEffect'
import { hasLowerPreferenceOrder, isAPhoneNumber, isPrimary } from './helpers'

export const getContactPrimaryPhone = (contact: Partial<Contact>) => {
  const potentialPhones = contact.communicationInformation?.filter(
    (comm) => isAPhoneNumber(comm) && isInEffect(comm)
  )

  if (!potentialPhones?.length) {
    return undefined
  }

  const { preferredMethodOfContact } = contact

  const preferredPhone = potentialPhones.reduce(
    (prev, cur) => {
      // Respect preferredMethodOfContact
      if (
        preferredMethodOfContact === cur.telecommunicationUseCode &&
        preferredMethodOfContact !== prev.telecommunicationUseCode
      ) {
        return cur
      }
      // Prefer a primary communication
      if (isPrimary(cur) && !isPrimary(prev)) {
        return cur
      }
      // Prefer lower preference order
      if (hasLowerPreferenceOrder(cur, prev)) {
        return cur
      }
      // Prefer a defined preferenceOrder
      if (prev.preferenceOrder === undefined && cur.preferenceOrder !== undefined) {
        return cur
      }

      return prev
    },
    potentialPhones[0] // Initialize to first candidate
  )

  return preferredPhone
}

export const getContactPhoneNumber = (
  contact: Partial<Contact>,
  formatted = false
): string | undefined => {
  const preferredPhone = getContactPrimaryPhone(contact)
  if (!preferredPhone) {
    return
  }

  if (formatted && preferredPhone.telephoneNumber !== undefined) {
    return `${preferredPhone?.countryCode ? '+' + preferredPhone?.countryCode + ' ' : ''}${
      preferredPhone?.areaCode ? `(${preferredPhone?.areaCode}) ` : ''
    }${preferredPhone?.telephoneNumber}${
      preferredPhone?.extension ? ' x' + preferredPhone?.extension : ''
    }`
  }

  return preferredPhone?.unformattedTelephoneNumber
    ? `${preferredPhone?.unformattedTelephoneNumber}`
    : [
        preferredPhone?.countryCode,
        preferredPhone?.areaCode,
        preferredPhone?.telephoneNumber,
        preferredPhone?.extension,
      ]
        .filter((v) => !!v)
        .join('')
}

export const getUsPhoneFromUnformattedNumber = (
  unformattedTelephoneNumber: string | number
): ContactCommunicationInformation => {
  const cleaned = `${unformattedTelephoneNumber}`.replace(/\D/g, '')
  const match = cleaned.match(
    /^(?<countryCode>1|)?(?<areaCode>\d{3})(?<telephoneNumber>\d{7})(?<extension>\d{1,5})?$/
  )

  if (!match || !match?.groups) return { unformattedTelephoneNumber: cleaned }

  const phone = match.groups

  return {
    areaCode: phone.areaCode ? Number.parseInt(phone.areaCode) : undefined,
    countryCode: phone.countryCode ? Number.parseInt(phone.countryCode) : 1,
    extension: phone.extension,
    telephoneNumber: `${phone.telephoneNumber.substring(0, 3)}-${phone.telephoneNumber.substring(
      3,
      7
    )}`,
    unformattedTelephoneNumber: cleaned,
  }
}

/**
 * Number to be used in a hyperlink -> <a html="tel:<formatUnformattedNumberToHtml(unformattedNumber)>...</a>"
 * @param unformattedTelephoneNumber Purity in numbers
 * @returns yo mama
 */
export const formatUsPhoneToHtml = (phone: string | number) => {
  const { areaCode, countryCode, extension, telephoneNumber } = getUsPhoneFromUnformattedNumber(
    `${phone}`
  )
  const cleanedTelephone = `${telephoneNumber}`.replace(/\D/g, '')

  return `+${[countryCode, areaCode, cleanedTelephone].filter((v) => !!v).join('')}${
    extension ? `p${extension}` : ''
  }`
}
