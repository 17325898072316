import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, MessageGroupIdSchema } from '../../base/index.js'

export const AddMessageGroupInboxRequestPathSchema = z.object({
  groupId: MessageGroupIdSchema,
  inboxId: Auth0IdSchema,
})

export const AddMessageGroupInboxRequestSchema = AddMessageGroupInboxRequestPathSchema

export type AddMessageGroupInboxRequest = z.infer<typeof AddMessageGroupInboxRequestSchema>

export const AddMessageGroupInboxResponseSchema = z.void()
export type AddMessageGroupInboxResponse = z.infer<typeof AddMessageGroupInboxResponseSchema>

export interface AddMessageGroupInboxHandler
  extends Handler<AddMessageGroupInboxRequest, AddMessageGroupInboxResponse> {}
