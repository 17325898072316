import { chakra, Select } from '@eigtech/ui-shared-dave'
import { RowData } from '@tanstack/react-table'
import { getColumnTitle } from '../utils'
import { DataGridFilterContainer } from './DataGridFilterContainer'
import { WithColumnProps } from '../../types'

export function DataGridSelectFilter<TData extends RowData, TValue>({
  column,
  options,
}: WithColumnProps<TData, TValue> & { options: { label: string; value: string }[] }) {
  return (
    <DataGridFilterContainer
      column={column}
      label={
        <>
          <chakra.span fontStyle="italic">{getColumnTitle(column)}</chakra.span> equals:
        </>
      }
    >
      <Select
        mr="10"
        value={(column.getFilterValue() as string) ?? ''}
        onChange={(event) => {
          column.setFilterValue(event.target.value)
        }}
      >
        <option disabled value="">
          Select option
        </option>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </DataGridFilterContainer>
  )
}
