import {
  TelecommunicationEquipmentTypeSchema,
  TelecommunicationUseCodeSchema,
} from '@eigtech/contacts-types'
import { z } from 'zod'

export const ContactFormEmailFieldSchema = z.object({
  id: z.string().optional(),
  email: z.string().email().optional().or(z.literal('')),
  preferenceOrder: z.number().optional(),
  // pass through via API: preferenceOrder, telecommunicationUseCode, telecommunicationEquipmentType
  telecommunicationEquipmentType: TelecommunicationEquipmentTypeSchema.optional(),
  telecommunicationUseCode: TelecommunicationUseCodeSchema.optional(),
  isDisabled: z.boolean().optional(),
})

export type ContactFormEmailField = z.infer<typeof ContactFormEmailFieldSchema>
