import { ActivateContactRequest, ActivateContactResponse } from '@eigtech/contacts-types'
import { contextualPut, useMutation } from '@eigtech/ui-shared-api'
import { contactBasePath } from './constants'
import { useInvalidateGlobalBooks } from './getBooks'
import { useInvalidateContact } from './getContacts'

const activateContact = ({ contactId }: ActivateContactRequest) =>
  contextualPut<ActivateContactResponse>(`${contactBasePath}/${contactId}/activate`, undefined, {
    responseType: 'none',
  })

export function useActivateContact() {
  const invalidateContact = useInvalidateContact()
  const invalidateBooks = useInvalidateGlobalBooks()

  return useMutation({
    mutationFn: activateContact,
    onSuccess(__, { contactId }) {
      invalidateContact(contactId)
      invalidateBooks()
    },
  })
}
