import { TemplateSchema } from '@eigtech/templates-types'
import { makeMentionSchema } from '@eigtech/ui-shared-mentions'
import { z } from 'zod'

const NoteMentionSchema = makeMentionSchema(z.object({ id: z.string() }))

export const NoteFieldsSchema = z.object({
  publishOnCreate: z.boolean().optional(),
  template: TemplateSchema.nullable().optional(),
  text: z.string().min(1, 'This field is required'),
  title: z.string().min(1, 'This field is required'),
  mentions: NoteMentionSchema.array().optional(),
})

export type NoteFieldsType = z.infer<typeof NoteFieldsSchema>

export type NoteMention = z.infer<typeof NoteMentionSchema>

export const isNoteMention = (mention: unknown): mention is NoteMention =>
  NoteMentionSchema.safeParse(mention).success
