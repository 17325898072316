import { Container, ContainerProps, forwardRef } from '@chakra-ui/react'
import { ElementType } from 'react'
import { PageErrorBoundary } from './PageErrorBoundary'

export type PageContainerBaseProps = {
  ErrorBoundary?: ElementType<any>
  isFullWidth?: boolean
}

export type PageContainerProps = ContainerProps & PageContainerBaseProps

export const PageContainer = forwardRef<PageContainerProps, 'div'>(
  ({ ErrorBoundary = PageErrorBoundary, children, isFullWidth, ...props }, ref) => (
    <ErrorBoundary>
      <Container ref={ref} maxW={isFullWidth ? 'none' : 'container.xl'} px={[3, 4]} {...props}>
        {children}
      </Container>
    </ErrorBoundary>
  )
)
