import { Handler } from 'aws-lambda'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { z } from 'zod'
import { CornSchema } from '@eigtech/shared-corn'
import { DocumentPublicationRecordSchema } from '../aggregates'

// get document Publications
export const GetDocumentPublicationsPathRequestSchema = z.object({
  entityId: CornSchema,
  documentCorn: z.string(),
})
export const GetDocumentPublicationsHeadersRequestSchema = ApiLambdalerAuthHeaderSchema
export const GetDocumentPublicationsRequestSchema = GetDocumentPublicationsPathRequestSchema.merge(
  GetDocumentPublicationsHeadersRequestSchema
)
export const GetDocumentPublicationsResponseSchema = DocumentPublicationRecordSchema.omit({
  PK: true,
  SK: true,
}).array()

export type GetDocumentPublicationsPathRequest = z.infer<
  typeof GetDocumentPublicationsPathRequestSchema
>

export type GetDocumentPublicationsRequest = z.infer<typeof GetDocumentPublicationsRequestSchema>

export type GetDocumentPublicationsResponse = z.infer<typeof GetDocumentPublicationsResponseSchema>
export type GetDocumentPublicationsHandler = Handler<
  GetDocumentPublicationsRequest,
  GetDocumentPublicationsResponse
>
