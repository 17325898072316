import { CohortEnumSchema } from '@eigtech/auth0-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentSchemaV2 } from '../aggregates/documents.js'

// list documents
export const ListDocumentsPathRequestSchema = z.object({
  entityId: CornSchema,
  cohort: CohortEnumSchema,
})
export type ListDocumentsPathRequest = z.infer<typeof ListDocumentsPathRequestSchema>

export const ListDocumentsRequestSchema = ListDocumentsPathRequestSchema
export const ListDocumentsResponseSchema = z.array(DocumentSchemaV2)

export type ListDocumentsRequest = z.infer<typeof ListDocumentsRequestSchema>
export type ListDocumentsResponse = z.infer<typeof ListDocumentsResponseSchema>
export type ListDocumentsHandler = Handler<ListDocumentsRequest, ListDocumentsResponse>
