import { queryOptions, useQueries, useQuery } from '@eigtech/ui-shared-api'
import { DelegateAdminClaim, claimsV2BasePath } from '@eigtech/ui-shared-claims'
import { get } from '../instance'
import { claimsQueryKeys } from './constants'

const getClaim = (claimNumber: string) =>
  get<DelegateAdminClaim>(`${claimsV2BasePath}/claim/${claimNumber}`)

export const getClaimQueryOptions = (claimNumber: string) =>
  queryOptions({
    queryKey: claimsQueryKeys.detail(claimNumber),
    queryFn: () => getClaim(claimNumber),
  })

export const useGetClaim = (claimNumber: string) => useQuery(getClaimQueryOptions(claimNumber))

export const useGetClaimsDetails = (claimNumbers: string[] | undefined) =>
  useQueries({
    queries: (claimNumbers ?? []).map(getClaimQueryOptions),
  })
