import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'claims-v2'

export const claimsV2ServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const ClaimsV2ServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: claimsV2ServiceEventType(),
  })
)

export type ClaimsV2ServiceEventBase = z.infer<typeof ClaimsV2ServiceEventBaseSchema>
