// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/table.ts

import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

// const baseStyle = definePartsStyle({})

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
})

// const variantSimple = definePartsStyle({
//   th: {},
//   td: {},
//   caption: {},
//   tfoot: {},
// })

// const variantStripe = definePartsStyle({
//   th: {},
//   td: {},
//   caption: {},
//   tfoot: {},
// })

const variantHover = definePartsStyle((props) => {
  const { colorScheme: c } = props

  return {
    tr: {
      '&:last-of-type': {
        td: {
          borderBottom: '1px',
          borderColor: mode(`${c}.100`, `${c}.700`)(props),
        },
      },
      '&:hover': {
        td: {
          background: mode(`${c}.50`, `${c}.700`)(props),
          borderColor: 'transparent',
          '&:first-of-type': {
            borderTopLeftRadius: 'lg',
            borderBottomLeftRadius: 'lg',
          },
          '&:last-of-type': {
            borderTopRightRadius: 'lg',
            borderBottomRightRadius: 'lg',
          },
        },
        '+ tr': {
          td: {
            borderTopColor: 'transparent',
          },
        },
      },
    },
    th: {
      color: mode(`gray.600`, `gray.400`)(props),
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    td: {
      borderTop: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      transition: 'border 100ms, background 100ms',
      ...numericStyles,
    },
    caption: {
      color: mode(`gray.600`, `gray.100`)(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  }
})

const variants = {
  // simple: variantSimple,
  // striped: variantStripe,
  hover: variantHover,
  // unstyled: defineStyle({}),
}

// const sizes = {
//   sm: definePartsStyle({
//     th: {},
//     td: {},
//     caption: {},
//   }),
// }

export const tableTheme = defineMultiStyleConfig({
  // baseStyle,
  variants,
  // sizes,
  defaultProps: {
    variant: 'hover',
  },
})
