import { IS_DEV } from '.'

const STAGE_NAME = import.meta.env.VITE_STAGE_NAME ?? 'prod'

const isProd = STAGE_NAME.includes('prod')

/**
 * Use to check if in production environment. Should be `false`
 * for local, UAT, dev, and QA environments. IS_DEV will only be
 * `true` for running UI locally.
 */
export const IS_NOT_PROD_ENV = IS_DEV || !isProd

export const IS_PROD_ENV = !IS_NOT_PROD_ENV
