import { getAccessToken, useAuth0, withAuthenticationRequired } from '@eigtech/ui-shared-auth'
import { Container, GlobalLoader, NotFoundError } from '@eigtech/ui-shared-dave'
import log, { RemoteLogger } from '@eigtech/ui-shared-logging'
import { createRootRoute } from '@eigtech/ui-shared-router'
import { pick } from 'lodash-es'
import { useEffect } from 'react'
import { AppContainer, App as BaseApp, PreAuthProviders } from '../components/Core/App'

const remoteLog = new RemoteLogger(log, {
  ui: 'estimator',
  apiBaseUrl: import.meta.env.VITE_API_URL,
  getAccessToken,
})

export const Route = createRootRoute({
  component: () => (
    <PreAuthProviders>
      {/* <Outlet /> located in apps/cinnamon/ui-estimator/src/components/Core/App/App.tsx */}
      <AuthGuard />
    </PreAuthProviders>
  ),
  notFoundComponent: () => (
    <AppContainer>
      <Container maxW="container.text">
        <NotFoundError />
      </Container>
    </AppContainer>
  ),
})

function AuthGuard() {
  const { isLoading, user } = useAuth0()

  useEffect(() => {
    if (!user) return

    remoteLog.userContext = pick(user, ['sub', 'email', 'name'])
  }, [user])

  return (
    <>
      {isLoading && <GlobalLoader />}
      <ProtectedApp />
    </>
  )
}

const ProtectedApp = withAuthenticationRequired(BaseApp)
