import { zodMerge } from '@eigtech/function-utils'
import z from 'zod'

export const AnyOfOperatorSchema = z.literal('ANYOF')
export const IncludesOperatorSchema = z.literal('INCLUDES')
export const NullOperatorSchema = z.enum(['IS', 'IS NOT'])
export const StringOperatorSchema = z.enum([
  '=',
  '<',
  '>',
  '>=',
  '<=',
  '!=',
  'LIKE',
  'NOT LIKE',
  'ILIKE',
  'NOT ILIKE',
])

export const OperatorSchema = z.enum([
  AnyOfOperatorSchema.value,
  IncludesOperatorSchema.value,
  ...NullOperatorSchema.options,
  ...StringOperatorSchema.options,
])

export const StringFilterSchema = z.object({
  value: z.string(),
  operator: StringOperatorSchema.optional(),
})
export const isStringFilter = (filterProps: FilterBase): filterProps is StringFilter =>
  StringFilterSchema.safeParse(filterProps).success

export const AnyOfFilterSchema = z.object({
  value: z.string().or(z.string().array()),
  operator: AnyOfOperatorSchema,
})
export const isAnyOfFilter = (filterProps: FilterBase): filterProps is AnyOfFilter =>
  AnyOfFilterSchema.safeParse(filterProps).success

export const IncludesFilterSchema = z.object({
  value: z.string(),
  operator: IncludesOperatorSchema,
})
export const isIncludesFilter = (filterProps: FilterBase): filterProps is IncludesFilter =>
  IncludesFilterSchema.safeParse(filterProps).success

export const NullFilterSchema = z.object({
  value: z.literal('NULL'),
  operator: NullOperatorSchema,
})
export const isNullFilter = (filterProps: FilterBase): filterProps is NullFilter =>
  NullFilterSchema.safeParse(filterProps).success

export const FilterBaseSchema = z.union([
  AnyOfFilterSchema,
  IncludesFilterSchema,
  NullFilterSchema,
  StringFilterSchema,
])

export const FilterBaseParamsSchema = z.object({
  page: z.number().min(1),
  pageSize: z.number().min(10),
  sortAscending: z.boolean(),
})

export const OperatorEnum = OperatorSchema.Enum
export type Operator = z.infer<typeof OperatorSchema>
export type FilterBase = z.infer<typeof FilterBaseSchema>
export type FilterBaseParams = z.infer<typeof FilterBaseParamsSchema>
export type AnyOfFilter = z.infer<typeof AnyOfFilterSchema>
export type IncludesFilter = z.infer<typeof IncludesFilterSchema>
export type NullFilter = z.infer<typeof NullFilterSchema>
export type StringFilter = z.infer<typeof StringFilterSchema>

export const makeSummaryFilterSchema = <T extends z.ZodTypeAny>(fieldSchema: T) =>
  zodMerge(
    FilterBaseSchema,
    z.object({
      field: fieldSchema,
    })
  )

export type FieldFilter<T extends string = string> = FilterBase & { field: T }

export const makeSummaryParamsSchema = <TField extends z.ZodTypeAny, TFilter extends z.ZodTypeAny>(
  fieldSchema: TField,
  filterSchema: TFilter
) =>
  FilterBaseParamsSchema.merge(
    z.object({
      sortBy: fieldSchema,
      filter: z.array(filterSchema),
    })
  )
