import z from 'zod'
import { BaseJobSchema } from './baseJob.js'
import { JobService } from './baseJobService.js'

export const OtrJobTypeSchema = z.literal('otr')
export const JOB_TYPE_OTR = OtrJobTypeSchema.value

export const OtrJobTypeAbbreviationSchema = z.literal('OTR')
export const JOB_TYPE_OTR_ABBREVIATION = OtrJobTypeAbbreviationSchema.value

export const OtrServiceSchema = z.enum([JobService.notImplemented])

export const OtrJobSchema = BaseJobSchema.merge(
  z.object({
    jobType: OtrJobTypeSchema,
    services: z.array(OtrServiceSchema).transform((val) => Array.from(new Set(val))),
  })
)

export const isOtrService = (s: OtrServiceEnum): s is OtrServiceEnum =>
  OtrServiceSchema.safeParse(s).success

/**
 * WARNING: NOT IMPLEMENTED
 */
export interface OtrJob extends z.infer<typeof OtrJobSchema> {}
export type OtrServiceEnum = z.infer<typeof OtrServiceSchema>
