import { MeetingState, useMeetingStore } from './MeetingState'
import { useEffect } from 'react'
import { useWakeLock } from '@eigtech/ui-shared-dave'

const stateSelector = ({ chimeMeeting }: MeetingState) => ({ chimeMeeting })

export function WakeLockHandler() {
  const { chimeMeeting } = useMeetingStore(stateSelector)

  const {
    isSupported: wakeLockIsSupported,
    request: requestWakeLock,
    release: releaseWakeLock,
  } = useWakeLock()

  useEffect(() => {
    if (!wakeLockIsSupported) return

    if (!!chimeMeeting) {
      requestWakeLock()
    } else {
      releaseWakeLock()
    }

    return () => {
      if (!wakeLockIsSupported) return
      releaseWakeLock()
    }
  }, [chimeMeeting, releaseWakeLock, requestWakeLock, wakeLockIsSupported])

  return <></>
}
