import {
  As,
  Container,
  ContainerProps,
  forwardRef,
  Heading,
  Icon,
  IconProps,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export type EmptyStateProps = {
  icon: As
  iconProps?: IconProps
  heading: ReactNode
  children: ReactNode
} & ContainerProps

export const EmptyState = forwardRef<EmptyStateProps, 'div'>(
  ({ children, icon, iconProps = {}, heading, ...props }, ref) => {
    return (
      <Container
        ref={ref}
        borderColor="gray.300"
        borderRadius="md"
        borderWidth="1px"
        px="4"
        py="8"
        w="full"
        {...props}
      >
        <Stack maxW="container.text" mx="auto" spacing="6" textAlign="center">
          <Icon as={icon} h="8" mx="auto" w="8" {...iconProps} />
          <Stack spacing="4">
            {typeof heading === 'string' ? (
              <Heading as="h3" size="md">
                {heading}
              </Heading>
            ) : (
              heading
            )}
            {typeof children === 'string' ? <Text>{children}</Text> : children}
          </Stack>
        </Stack>
      </Container>
    )
  }
)
