import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ClaimSchema } from '../../aggregates/claim.js'
import { ClaimStatusCanonSchema } from '../../aggregates/claimStatus.js'
import { LossDetailsSchema } from '../../aggregates/lossDetails.js'
import { LossLocationSchema } from '../../aggregates/lossLocation.js'

export const UpsertClaimRequestBodySchema = z.object({
  claim: ClaimSchema.omit({
    lossDetailsId: true,
    lossLocationId: true,
  }),
  lossDetails: LossDetailsSchema.omit({ id: true, claimNumber: true }),
  lossLocation: LossLocationSchema.omit({ id: true, claimNumber: true }),
  statusEvents: ClaimStatusCanonSchema.omit({ id: true, claimNumber: true }).array(),
})

export const UpsertClaimRequestSchema = UpsertClaimRequestBodySchema

export const UpsertClaimResponseSchema = z.void()

export type UpsertClaimRequestBody = z.infer<typeof UpsertClaimRequestBodySchema>
export type UpsertClaimRequest = z.infer<typeof UpsertClaimRequestSchema>

export type UpsertClaimResponse = z.infer<typeof UpsertClaimResponseSchema>

export interface UpsertClaimHandler extends Handler<UpsertClaimRequest, UpsertClaimResponse> {}
