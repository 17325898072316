import { ReactNode, createContext, useContext } from 'react'
import { FieldArray, FieldArrayPath, FieldValues, UseFieldArrayReturn } from 'react-hook-form'

export type ArrayFieldContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName> & {
  defaultValue?: FieldArray<TFieldValues, TFieldArrayName>
  max?: number
  min?: number
  name: TFieldArrayName
}

const ArrayFieldContext = createContext<ArrayFieldContext | undefined>(undefined)

export type ArrayFieldProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = {
  children?: ReactNode
  defaultValue?: FieldArray<TFieldValues, TFieldArrayName>
  fieldArray: UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>
  max?: number
  min?: number
  name: TFieldArrayName
}

export function ArrayFieldProvider<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>({
  children,
  defaultValue,
  fieldArray,
  max,
  min,
  name,
}: ArrayFieldProviderProps<TFieldValues, TFieldArrayName, TKeyName>) {
  return (
    <ArrayFieldContext.Provider
      value={{ ...fieldArray, defaultValue, min, max, name } as unknown as ArrayFieldContext}
    >
      {children}
    </ArrayFieldContext.Provider>
  )
}

export function useArrayFieldContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>() {
  const context = useContext(ArrayFieldContext)

  if (!context) {
    throw new Error('ArrayFieldProvider required to use useArrayFieldContext')
  }

  return context as unknown as ArrayFieldContext<TFieldValues, TFieldArrayName, TKeyName>
}
