import { ListMeetingsRequest, ListMeetingsResponse } from '@eigtech/meetings-types'
import {
  QueryClient,
  contextualGet,
  getAuth0UserNameQueryOptions,
  queryOptions,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { meetingsBasePath, meetingsQueryKeys } from './constants'

const listMeetings = ({ entityId }: ListMeetingsRequest) =>
  contextualGet<ListMeetingsResponse>(`${meetingsBasePath}/${entityId}`)

export const listMeetingsQueryOptions = (
  { entityId }: ListMeetingsRequest,
  queryClient: QueryClient
) =>
  queryOptions({
    queryKey: meetingsQueryKeys.list({ entityId }),
    queryFn: async () => {
      const meetings = await listMeetings({ entityId })

      return Promise.all(
        meetings.map(async (meeting) => ({
          ...meeting,
          organizerName: await queryClient.fetchQuery(
            getAuth0UserNameQueryOptions(meeting.organizer)
          ),
        }))
      )
    },
  })

export function useListMeetings(request: ListMeetingsRequest) {
  const queryClient = useQueryClient()

  return useQuery(listMeetingsQueryOptions(request, queryClient))
}

export function useInvalidateListMeetings() {
  const queryClient = useQueryClient()

  return function invalidateListMeetings({ entityId }: ListMeetingsRequest) {
    queryClient.invalidateQueries({ queryKey: meetingsQueryKeys.list({ entityId }) })
  }
}
