import {
  InspectionDetails,
  InspectorRoleSchema,
  PublicationTargetsSchema,
  ScheduleClaimInspectionRequest,
} from '@eigtech/claims-v2-types'
import { ContactSchema } from '@eigtech/contacts-types'
import { makeBookCorn } from '@eigtech/contacts-util'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import {
  ContactSelectField,
  useGetBook,
  useGetCsrBook,
  useGetFieldAdjusterBook,
} from '@eigtech/ui-shared-contacts'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { useScheduleInspection } from '@eigtech/ui-shared-timeline'
import { startCase, uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import { z } from 'zod'
import { PublicationTargetsField } from '../Fields/PublicationTargetsField'
import { useGetClaimInspections } from '../api'

export type ScheduleInspectionDrawerProps = ComposedDrawerWrapperProps & {
  claimNumber: string
  inspection?: InspectionDetails
}

export const ScheduleInspectionDrawer = withDrawerErrorBoundary(
  function ScheduleInspectionDrawer(props: ScheduleInspectionDrawerProps) {
    const { claimNumber, inspection, ...drawerProps } = props

    const form = useForm({
      defaultValues: {
        inspectorRole:
          inspection?.inspectorRole ||
          (InspectorRoleSchema.options.length === 1
            ? InspectorRoleSchema.Values.fieldAdjuster
            : undefined),
      },
    })

    const { data: claimBook } = useGetBook(makeBookCorn(claimNumber))
    const { data: csrBook } = useGetCsrBook()
    const { data: fieldAdjuster } = useGetFieldAdjusterBook()

    const contactOptions = useMemo(
      () =>
        uniqBy(
          [
            ...(claimBook?.contacts ?? []),
            ...(csrBook?.contacts ?? []),
            ...(fieldAdjuster?.contacts ?? []),
          ],
          'contactId'
        ),
      [claimBook?.contacts, csrBook?.contacts, fieldAdjuster?.contacts]
    )

    const toast = useToast()

    const { mutateAsync: scheduleInspection, isError } = useScheduleInspection()
    const { data: requestedInspections } = useGetClaimInspections(claimNumber, {
      filter: [
        {
          field: 'status',
          operator: 'ANYOF',
          value: ['requested'],
        },
      ],
    })

    async function onSubmit({ date, inspectorRole, scheduledBy, ...data }: ScheduleInspectionForm) {
      const payload: OmitAuthHeaders<ScheduleClaimInspectionRequest> = {
        ...data,
        claimNumber,
        inspectorRole,
        scheduledBy: scheduledBy.contactId,
        scheduledDate: date.toISOString(),
      }

      const requestedInspection = requestedInspections?.inspections?.find(
        (inspection) => inspection.inspectorRole === inspectorRole
      )

      if (inspection) {
        payload.inspectionId = inspection.inspectionId
        payload.inspectorRole = inspection.inspectorRole ?? 'fieldAdjuster'
      } else if (requestedInspection) {
        payload.inspectionId = requestedInspection.inspectionId
      }

      await scheduleInspection(payload)

      toast({
        status: 'success',
        title: `Successfully scheduled an inspection!`,
        description: 'It may take a few minutes for changes to be reflected.',
      })

      drawerProps.onClose()
    }

    return (
      <FormDrawer {...baseDrawerProps} {...drawerProps} form={form} onSubmit={onSubmit}>
        <InputField label="Inspection Date" name="date" type="datetime-local" />

        <SelectField
          isDisabled={InspectorRoleSchema.options.length === 1}
          label="Inspector Role"
          name="inspectorRole"
          options={InspectorRoleSchema.options.map((value) => ({
            label: startCase(value),
            value,
          }))}
        />

        <ContactSelectField contacts={contactOptions} label="Scheduled By" name="scheduledBy" />

        <TextareaField label="Notes" name="notes" rows={6} />

        <PublicationTargetsField claimNumber={claimNumber} />

        {isError && (
          <ComposedAlert
            alert={{
              title: `Failed to schedule an inspection`,
              description:
                'Please try again. If the issue persists, please contact EIG Tech support.',
            }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  () => baseDrawerProps
)

const baseDrawerProps = {
  title: 'Schedule Inspection',
}

const ScheduleInspectionFormSchema = z.object({
  date: z.coerce.date(),
  inspectorRole: InspectorRoleSchema,
  notes: z.string(),
  publicationTargets: PublicationTargetsSchema.array().optional(),
  scheduledBy: ContactSchema,
})

type ScheduleInspectionForm = z.infer<typeof ScheduleInspectionFormSchema>

const { useForm, InputField, SelectField, TextareaField } = createForm(
  ScheduleInspectionFormSchema,
  'scheduleInspectionForm'
)
