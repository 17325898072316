import { cardAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

// const $bg = cssVar('card-bg')
const $paddingY = cssVar('card-padding-y')
const $paddingX = cssVar('card-padding-x')

const baseStyle = definePartsStyle({
  container: {
    backgroundColor: 'white',
  },
  body: {
    py: $paddingY.reference,
    px: $paddingX.reference,
  },
  header: {
    borderBottomColor: 'gray.100',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    py: $paddingY.reference,
    px: $paddingX.reference,
  },
  footer: {
    py: $paddingY.reference,
    px: $paddingX.reference,
  },
})

const sizes = {
  sm: definePartsStyle({
    container: {
      [$paddingY.variable]: 'space.2',
      [$paddingX.variable]: 'space.4',
    },
  }),
  md: definePartsStyle({
    container: {
      [$paddingY.variable]: 'space.4',
      [$paddingX.variable]: 'space.6',
    },
    header: {
      py: '3',
    },
  }),
  lg: definePartsStyle({
    container: {
      [$paddingY.variable]: 'space.5',
      [$paddingX.variable]: 'space.7',
    },
    header: {
      py: '4',
    },
  }),
}

// const variants = {
//   elevated: definePartsStyle({
//     container: {},
//   }),
// }

export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  // variants,
  sizes,
  // defaultProps: {},
})
