import { z } from 'zod'

export const InitialClaimActionsSchema = z.enum([
  'requiresEmergencyBoardUp',
  'requiresFireOrSmokeMitigation',
  'requiresGeneralContractor',
  'requiresRoofTarping',
  'requiresTreeRemoval',
  'requiresWaterExtraction',
])

export type InitialClaimAction = z.infer<typeof InitialClaimActionsSchema>
