import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const notesServiceName = 'notes'
export const serviceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(notesServiceName, entity, eventName)

export const NotesServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: serviceEventType(),
  })
)

export type NotesServiceEventBase = z.infer<typeof NotesServiceEventBaseSchema>
