import { ClaimEventType, EstimateEventType } from '@eigtech/claims-v2-types'
import z from 'zod'

export const PublishableClaimEventSchema = z.enum([
  ClaimEventType.closed,
  ClaimEventType.customerContactedEmail,
  ClaimEventType.customerContactedLvm,
  ClaimEventType.customerNoContact,
  ClaimEventType.initialCustomerContactSuccess,
  ClaimEventType.inspectionCompleted,
  ClaimEventType.inspectionScheduled,
  EstimateEventType.reviewed,
])
export const PublishableClaimEvent = PublishableClaimEventSchema.Enum
export type PublishableClaimEventEnum = z.infer<typeof PublishableClaimEventSchema>
export const isPublishableClaimEvent = (
  eventName: string
): eventName is PublishableClaimEventEnum =>
  PublishableClaimEventSchema.safeParse(eventName).success
