import { forwardRef, Input, InputProps } from '@chakra-ui/react'
import { debounce } from 'lodash-es'
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'

export type DebouncedInputProps = InputProps & { debounce?: number }

export const DebouncedInput = forwardRef<DebouncedInputProps, 'input'>(
  (
    { onChange = () => {}, debounce: debounceTime = 500, value: initialValue = '', ...props },
    ref
  ) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    const debouncedOnChange = useMemo<ChangeEventHandler<HTMLInputElement>>(
      () => debounce(onChange, debounceTime),
      [onChange, debounceTime]
    )

    return (
      <Input
        ref={ref}
        {...props}
        value={value}
        onChange={(event) => {
          setValue(event.target.value)
          debouncedOnChange(event)
        }}
      />
    )
  }
)
