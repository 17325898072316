import { ContactMethodSchema, ExternalSystemIdsSchema } from '@eigtech/contacts-types'
import { createForm } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { ContactFormAddressFieldSchema } from './AddressFieldSchema'
import { ContactFormEmailFieldSchema } from './EmailFieldSchema'
import { ContactFormPhoneFieldSchema } from './PhoneFieldSchema'
import { ContactFormRoleFieldSchema } from './RoleFieldSchema'

export const ContactFormCommunicationInformationFieldsSchema = z.object({
  primaryEmailIndex: z.number().or(z.null()).optional(),
  primaryPhoneIndex: z.number().or(z.null()).optional(),
})

export type ContactFormCommunicationInformationFields = z.infer<
  typeof ContactFormCommunicationInformationFieldsSchema
>

export const ContactFormContactFieldsSchema = ContactFormCommunicationInformationFieldsSchema.merge(
  z.object({
    emails: z.array(ContactFormEmailFieldSchema),
    phones: z.array(ContactFormPhoneFieldSchema),
    preferredMethodOfContact: ContactMethodSchema.optional(),
  })
)

export type ContactFormContactFields = z.infer<typeof ContactFormContactFieldsSchema>

export const ContactFormSchema = ContactFormContactFieldsSchema.merge(
  z.object({
    nameId: z.string().optional(),
    givenName: z
      .string()
      .min(1, { message: 'First name must be at least 1 character' })
      .regex(/^[^\[\]\(\)]+$/, { message: 'Name cannot contain the following characters: []()' }),
    familyName: z
      .string()
      .regex(/^[^\[\]\(\)]+$/, { message: 'Name cannot contain the following characters: []()' })
      .or(z.literal(''))
      .or(z.undefined()),
    addresses: z.array(ContactFormAddressFieldSchema),
    roles: z.array(ContactFormRoleFieldSchema).min(1, { message: 'At least 1 role is required' }),
    description: z.string().optional().or(z.literal('')),
    // pass through via API: externalSystemIds
    externalSystemIds: ExternalSystemIdsSchema.optional(),
  })
)

export type ContactForm = z.infer<typeof ContactFormSchema>

export const {
  useForm: useContactForm,
  useFormContext: useContactFormContext,
  useArrayFieldRowContext: useContactFormArrayFieldRowContext,
  useArrayFieldContext: useContactFormArrayFieldContext,
  InputField: ContactFormInputField,
  MaskedInputField: ContactFormMaskedInputField,
  ArrayField: ContactFormArrayField,
  CheckboxField: ContactFormCheckboxField,
  SelectField: ContactFormSelectField,
} = createForm(ContactFormSchema, 'contactForm')
