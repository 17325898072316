import { ComposedCard, ComposedCardProps, forwardRef, Stack } from '@eigtech/ui-shared-dave'
import { AfterMeeting } from './AfterMeeting'
import { BeforeMeeting } from './BeforeMeeting'
import { InMeeting } from './InMeeting'

export type MeetingControlsProps = ComposedCardProps

export const MeetingControls = forwardRef<MeetingControlsProps, 'div'>((props, ref) => {
  return (
    <ComposedCard ref={ref} {...props}>
      <Stack>
        <BeforeMeeting />
        <InMeeting />
        <AfterMeeting />
      </Stack>
    </ComposedCard>
  )
})
