import { FC, ReactNode, createContext, useContext } from 'react'

export type UserContext = {
  PreferredUserComponent: FC<{
    user: string
    property: string
  }>
}

export function UserProvider({
  children,
  PreferredUserComponent = DefaultUserComponent,
}: {
  children: ReactNode
} & Partial<UserContext>) {
  return <UserContext.Provider value={{ PreferredUserComponent }}>{children}</UserContext.Provider>
}

export function useUserContext() {
  const routes = useContext(UserContext)

  if (!routes) {
    throw new Error('UserProvider required to use useUserContext')
  }

  return routes
}

// defaults

const DefaultUserComponent: UserContext['PreferredUserComponent'] = ({ user }) => <>{user}</>

const defaultContext: UserContext = {
  PreferredUserComponent: DefaultUserComponent,
}

// Context
const UserContext = createContext<UserContext>(defaultContext)
