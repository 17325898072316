import { Locale } from '@eigtech/ui-shared-dates'
import { createContext, useContext } from 'react'

export type MobileMonthlyCalendarState<DayData> = {
  days: Date[]
  locale?: Locale
  selectedMonth: Date
  onSelectedMonthChange: (date: Date) => any
  selectedDate: Date
  setSelectedDate: (date: Date) => any
  events: (DayData & {
    date: Date
  })[]
}

export const MobileMonthlyCalendarContext = createContext<
  MobileMonthlyCalendarState<any> | undefined
>(undefined)

export function useMobileMonthlyCalendar<DayData>() {
  const context = useContext(MobileMonthlyCalendarContext)

  if (!context) {
    throw new Error('useMobileMonthlyCalendar needs to be used with the MonthlyCalendar component')
  }

  return context as MobileMonthlyCalendarState<DayData>
}
