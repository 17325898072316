import {
  chakra,
  ComponentWithAs,
  forwardRef,
  HStack,
  Icon,
  IconButton,
  TableHeadProps,
  Text,
  Th,
  Thead,
  Tr,
} from '@eigtech/ui-shared-dave'
import { flexRender, Header, RowData, SortDirection } from '@tanstack/react-table'
import { ReactNode } from 'react'
import { BsPinAngle as UnPinnedIcon, BsPinAngleFill as PinnedIcon } from 'react-icons/bs'
import { useGetCellWidths } from '../hooks'
import { useDataGridContext } from './DataGridContext'

export const DataGridHead: ComponentWithAs<'thead', TableHeadProps> = forwardRef((props, ref) => {
  const { tableId, table } = useDataGridContext()

  const tableHeadId = `${tableId}_Head`
  const tableHeaderRowId = (rowId: string) => `${tableId}_HeaderRow_${rowId}`
  const tableHeaderCellId = (rowId: string, cellId: string) =>
    `${tableHeaderRowId(rowId)}_HeaderCell_${cellId}`

  return (
    <Thead ref={ref} id={tableHeadId} {...props}>
      {table.getHeaderGroups().map((headerGroup) => (
        <Tr key={headerGroup.id} id={tableHeaderRowId(headerGroup.id)}>
          {headerGroup.headers.map((header) => (
            <HeaderCell
              key={header.id}
              getCellId={(id: string) => tableHeaderCellId(headerGroup.id, id)}
              header={header}
              id={tableHeaderCellId(headerGroup.id, header.id)}
            />
          ))}
        </Tr>
      ))}
    </Thead>
  )
})

function HeaderCell<TData extends RowData, TValue>({
  header,
  id,
  getCellId,
}: {
  header: Header<TData, TValue>
  id: string
  getCellId: (id: string) => string
}) {
  const { table } = useDataGridContext()

  const canSort = header.column.getCanSort()
  const isSorted = header.column.getIsSorted()

  type SortIconKey = SortDirection | 'false'

  const sortIcons: Record<SortIconKey, ReactNode> = {
    asc: <AscendingIcon />,
    desc: <DescendingIcon />,
    false: <UnsortedIcon />,
  }

  const sortIcon = (
    <chakra.span id={`${id}_sort`}>{sortIcons[isSorted.toString() as SortIconKey]}</chakra.span>
  )

  const canPin = header.column.getCanPin()
  const isPinned = header.column.getIsPinned()

  const pinned = table.getLeftFlatHeaders()

  const widths = useGetCellWidths({ getCellId, pinned })

  const content = flexRender(header.column.columnDef.header, header.getContext())

  const Sort = canSort && (
    <IconButton
      aria-label="Toggle sort column."
      icon={sortIcon}
      size="xs"
      variant="ghost"
      onClick={header.column.getToggleSortingHandler()}
    />
  )

  const Pin = canPin && !header.column.columnDef.meta?.hidePinButton && (
    <IconButton
      aria-label="Toggle pin column."
      icon={isPinned ? <PinnedIcon /> : <UnPinnedIcon />}
      size="xs"
      variant="ghost"
      onClick={() => header.column.pin(isPinned ? false : 'left')}
    />
  )

  return (
    <Th
      bg="white"
      {...(header.column.columnDef.meta?.columnHeaderProps ?? {})}
      id={id}
      position="sticky"
      top="0"
      w={`${header.getSize()}px`}
      zIndex={isPinned ? 3 : 2}
      {...(isPinned ? { left: `${widths[header.id]}px` } : {})}
    >
      {header.isPlaceholder ? null : (
        <HStack justifyContent="space-between" w="full">
          {typeof content === 'string' ? <Text as="span">{content}</Text> : content}
          {(Sort || Pin) && (
            <HStack spacing="0">
              {Sort}
              {Pin}
            </HStack>
          )}
        </HStack>
      )}
    </Th>
  )
}

const AscendingIcon = () => (
  <Icon
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
    <line x1="12" x2="12" y1="5" y2="19"></line>
    <line x1="16" x2="12" y1="9" y2="5"></line>
    <line x1="8" x2="12" y1="9" y2="5"></line>
  </Icon>
)

const DescendingIcon = () => (
  <Icon
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
    <line x1="12" x2="12" y1="5" y2="19"></line>
    <line x1="16" x2="12" y1="15" y2="19"></line>
    <line x1="8" x2="12" y1="15" y2="19"></line>
  </Icon>
)

const UnsortedIcon = () => (
  <Icon
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
    <path d="M3 9l4 -4l4 4m-4 -4v14"></path>
    <path d="M21 15l-4 4l-4 -4m4 4v-14"></path>
  </Icon>
)
