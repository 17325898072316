import { getDay, Locale } from '@eigtech/ui-shared-dates'
import { daysInWeek } from './daysInWeek'

export type OmittedDaysProps = {
  days: Date[]
  omitDays?: number[]
  locale?: Locale
}

export function handleOmittedDays({ days, omitDays, locale }: OmittedDaysProps) {
  let headings = daysInWeek({ locale })
  let daysToRender = days

  // omit the headings and days of the week that were passed in
  if (omitDays) {
    headings = daysInWeek({ locale }).filter((day) => !omitDays.includes(day.day))
    daysToRender = days.filter((day) => !omitDays.includes(getDay(day)))
  }

  // omit the padding if an omitted day was before the start of the month
  let firstDayOfMonth = getDay(daysToRender[0]) as number
  if (omitDays) {
    const subtractOmittedDays = omitDays.filter((day) => day < firstDayOfMonth).length
    firstDayOfMonth = firstDayOfMonth - subtractOmittedDays
  }
  const padding = new Array(firstDayOfMonth).fill(0)

  return { headings, daysToRender, padding }
}
