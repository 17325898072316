import { StyleProps, Text, forwardRef, randomString } from '@eigtech/ui-shared-dave'
import { FC, Fragment, useMemo } from 'react'
import { InlineMention } from './MentionsInput'

export type MentionsDisplayProps = StyleProps & {
  text: string
  triggers: string[]
  MentionComponent?: FC<{ children: string }>
}

export const MentionsDisplay = forwardRef<MentionsDisplayProps, 'div'>(function MentionsDisplay(
  { MentionComponent = InlineMention, text, triggers, ...props },
  ref
) {
  const replaced = useMemo(() => {
    // This matches our old mentions from react-mentions
    // @[User Name](corn:contacts:contact:userId)
    const legacyRegex = /@\[(.*?)\]\((.*?)\)/g

    // New regex for lexical-beautiful-mentions
    const regex = new RegExp(`\\[(${triggers.join('|')})([^\\]]+)\\]`, 'g')

    const uniqId = `$${randomString()}$`

    // wrap each lexical mention with the unique id
    let replaced = text.replaceAll(regex, (match) => `${uniqId}${match}${uniqId}`)

    // If triggers include the @ symbol, wrap each legacy mention with the unique id
    if (triggers.includes('@')) {
      replaced = replaced.replaceAll(legacyRegex, (match) => `${uniqId}${match}${uniqId}`)
    }

    // Split on the unique id, and replace each lexical and legacy mention string
    // with a component to properly display the mention
    return replaced.split(uniqId).map((text, index) => {
      if (text.match(regex)) {
        return <MentionComponent key={index}>{text.slice(1, -1)}</MentionComponent>
      }

      if (triggers.includes('@') && text.match(legacyRegex)) {
        return (
          <MentionComponent key={index}>{text.replace(legacyRegex, '@$1($2)')}</MentionComponent>
        )
      }

      return text
    })
  }, [MentionComponent, text, triggers])

  return (
    <Text ref={ref} className="mentions-display" position="relative" {...props}>
      {replaced.map((text, index) => (
        <Fragment key={index}>{text}</Fragment>
      ))}
    </Text>
  )
})
