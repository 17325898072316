import { useBreakpointValue, useDisclosure } from '@eigtech/ui-shared-dave'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { NavId } from './types'

export type NavContextState = {
  forceCollapse: boolean
  activeNav: NavId
  setActiveNav: Dispatch<SetStateAction<NavId>>
  isCollapsed: boolean
} & Pick<NavProviderProps, 'setIsCollapsed' | 'drawer'>

export const NavContext = createContext<NavContextState>({} as unknown as NavContextState)

export function useNavContext() {
  const context = useContext(NavContext)

  if (context === undefined) {
    throw new Error('useNavContext must be used within a NavProvider')
  }
  return context
}

export type NavProviderProps = {
  children: ReactNode
  defaultActiveNav?: NavId
  drawer: ReturnType<typeof useDisclosure> | undefined
  activeNav?: NavId
  setActiveNav?: (nav: NavId | ((prevState: string) => string)) => void
  isMobileNav?: boolean
  isCollapsed?: boolean
  setIsCollapsed?: (value: boolean) => void
}

export function NavProvider({
  children,
  defaultActiveNav = 'main',
  drawer,
  activeNav: propsActiveNav,
  setActiveNav: propsSetActiveNav,
  isMobileNav,
  isCollapsed: isCollapsedSetting,
  setIsCollapsed,
}: NavProviderProps) {
  const forceCollapse = !!useBreakpointValue({ base: true, lg: false })
  const isCollapsed = !isMobileNav && !!(forceCollapse || isCollapsedSetting)

  const [activeNav, setActiveNav] = useState<NavId>(defaultActiveNav)

  return (
    <NavContext.Provider
      value={{
        drawer,
        forceCollapse,
        isCollapsed,
        setIsCollapsed,
        activeNav: propsActiveNav ?? activeNav,
        setActiveNav: propsSetActiveNav ?? setActiveNav,
      }}
    >
      {children}
    </NavContext.Provider>
  )
}
