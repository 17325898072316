import { ListUserPortalsResponse } from '@eigtech/estimator-types'
import {
  GetClaimSummariesRequest as BaseGetClaimSummariesRequest,
  ClaimSummaryFilter,
  PageResponseProperties,
} from '@eigtech/summarizer-types'
import { OmitAuthHeaders, queryOptions, useQuery } from '@eigtech/ui-shared-api'
import { User, useAuth0 } from '@eigtech/ui-shared-auth'
import { DelegateAdminClaimSummary, summarizerBasePath } from '@eigtech/ui-shared-claims'
import { normalizeFilters } from '@eigtech/ui-shared-summarizer'
import { get } from '../instance'
import { claimsQueryKeys, estimatorBasePath } from './constants'

const getAssignedClaims = async ({ auth0Id, email }: { auth0Id: string; email: string }) => {
  const { portals } = await get<ListUserPortalsResponse>(
    `${estimatorBasePath}/user/${auth0Id}/${email}/portals`
  )

  const claimNumbers = portals
    .filter((portal) => portal.entityType === 'claim' && portal.status === 'ACTIVE')
    .map((portal) => portal.entityId)

  const { claims } = await getClaimsSummaries({
    filter: normalizeFilters({
      filters: [
        {
          field: 'claimNumber',
          value: claimNumbers.join(','),
          operator: 'ANYOF',
        },
        {
          field: 'claimStatus',
          value: 'Closed',
          operator: '!=',
        },
      ],
    }),
    page: 1,
    pageSize: 1000,
    sortAscending: false,
    sortBy: 'inAssignQueue',
  })

  return claims
}

// type UIClaimSummaryField = 'claimNumber'

// type UIClaimSummaryFilter = Pick<ClaimSummaryFilter, 'value'> & {
//   operator?: ClaimSummaryFilter['operator'] | 'WITHIN'
//   field: UIClaimSummaryField
// }

type GetClaimSummariesRequest = Omit<OmitAuthHeaders<BaseGetClaimSummariesRequest>, 'filter'> & {
  filter: (Omit<ClaimSummaryFilter, 'value'> & { value: string })[]
}

const getClaimsSummaries = async ({ filter, ...params }: GetClaimSummariesRequest) =>
  get<{ claims: DelegateAdminClaimSummary[] } & PageResponseProperties>(
    `${summarizerBasePath}/claims`,
    {
      searchParams: { ...params, filter: encodeURI(JSON.stringify(filter)) },
    }
  )

export function getAssignedClaimsQueryOptions(user: User | undefined) {
  const auth0Id = user?.sub ?? ''
  const email = user?.email ?? ''

  return queryOptions({
    queryKey: claimsQueryKeys.list(),
    queryFn: () => getAssignedClaims({ auth0Id, email }),
    enabled: !!auth0Id && !!email,
  })
}

export function useGetAssignedClaims() {
  const { user } = useAuth0()

  return useQuery(getAssignedClaimsQueryOptions(user))
}
