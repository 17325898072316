import { useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react'
import { memo } from 'react'
import { useFilteredRoster } from '../../hooks'
import { RemoteVideo } from './RemoteVideo'

export const RemoteVideos = memo(function RemoteVideos() {
  const roster = useFilteredRoster()
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState()

  return (
    <>
      {tiles.map((tileId) => {
        const attendee = roster[tileIdToAttendeeId[tileId]] || {}
        const { name }: any = attendee
        return <RemoteVideo key={tileId} name={name} tileId={tileId} />
      })}
    </>
  )
})
