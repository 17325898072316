import { Book, BookTypeEnum } from '@eigtech/contacts-types'

export const entityBookTypes = ['claim', 'job'] satisfies BookTypeEnum[]
export type EntityBookTypes = (typeof entityBookTypes)[number]

export const isEntityBook = (book: Book) => {
  const entityType = book.metadata.type as EntityBookTypes

  return entityBookTypes.includes(entityType)
}
