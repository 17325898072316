import { CohortEnum, CohortEnumSchema } from '@eigtech/auth0-types'
import {
  DocumentPublicationAttributes,
  DocumentSchemaV2,
  GetDocumentPublicationsResponse,
} from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { getCornKernels, isCorn } from '@eigtech/shared-corn'
import { getCohortLabel } from '@eigtech/ui-shared-cohorts'
import {
  ACTION_MENU_ID,
  CellContext,
  DataGridSelectFilter,
  FilterFnOption,
  createColumnHelper,
} from '@eigtech/ui-shared-data-grid'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  ComposedModal,
  ComposedModalWrapperProps,
  DataSource,
  FancyLink,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Stack,
  StackDivider,
  Tag,
  Text,
  Tooltip,
  Wrap,
  chakra,
  useCopyToClipboard,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import {
  TagsList,
  getTagFilterFnComponent,
  tagFilterFns,
  useGetTagKeysList,
} from '@eigtech/ui-shared-tags'
import { last, startCase } from 'lodash-es'
import { FC, ReactNode, useMemo, useState } from 'react'
import { FaCaretDown, FaCaretRight } from 'react-icons/fa'
import { MdInfoOutline, MdMoreHoriz } from 'react-icons/md'
import { useGetDocumentPublications } from '../api/getDocumentPublications'
import { useGetDocumentUrl } from '../api/getDocumentUrl'
import { AugmentedDocument } from '../types/AugmentedDocument'
import { getDocumentDescription, getDocumentTitle } from '../utils/getDocumentDetails'
import { getExportEntityLabel } from '../utils/getExportEntityLabel'

export type DocumentColumn = AugmentedDocument & { subRows?: AugmentedDocument[] }

export const documentColumnHelper = createColumnHelper<DocumentColumn>()

export const hiddenDocumentColumns = {
  columnVisibility: { createdDate: false, modifiedDate: false, documentType: false, source: false },
}

export function useSharedDocumentsColumns() {
  const { PreferredDateTimeComponent: DateComponent } = useDatesContext()

  const [createdDateFilterFn, setCreatedDateFilterFn] =
    useState<FilterFnOption<DocumentColumn>>('dateEquals')
  const [modifiedDateFilterFn, setModifiedDateFilterFn] =
    useState<FilterFnOption<DocumentColumn>>('dateEquals')

  const { data: tagKeys } = useGetTagKeysList()

  return useMemo(
    () => ({
      [ACTION_MENU_ID]: ({
        ActionMenu = DocumentActionMenu,
      }: {
        ActionMenu?: FC<{ document: DocumentColumn }>
      } = {}) =>
        documentColumnHelper.display({
          id: ACTION_MENU_ID,
          cell: (cell) => <ActionMenu document={cell.row.original} />,
          maxSize: 48,
          enableHiding: false,
          meta: {
            hidePinButton: true,
          },
        }),
      importStatus: documentColumnHelper.display({
        id: 'importStatus',
        maxSize: 20,
        header: ({ table }) => (
          <Center w="full">
            <Checkbox
              isChecked={table.getIsAllRowsSelected()}
              isIndeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </Center>
        ),
        cell: ({ row }) =>
          row.getCanSelect() && (
            <Center>
              <Checkbox isChecked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
            </Center>
          ),
        enableHiding: false,
        enableSorting: false,
        meta: {
          hidePinButton: true,
          enableReordering: false,
        },
      }),
      title: documentColumnHelper.accessor('title', {
        id: 'title',
        header: 'File',
        cell: (cell) => <DocumentTitle cell={cell} />,
      }),
      description: documentColumnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        cell: (cell) => getDocumentDescription(cell.row.original),
      }),
      filename: documentColumnHelper.accessor('filename', {
        id: 'fileName',
        header: 'File Name',
      }),
      fileType: documentColumnHelper.accessor('filename', {
        id: 'fileType',
        header: 'File Type',
        cell: (cell) => last(cell.getValue().split('.'))?.toLocaleUpperCase(),
      }),
      visibility: documentColumnHelper.accessor('userCohorts', {
        id: 'cohorts',
        header: 'Visibility',
        enableSorting: false,
        filterFn: 'arrIncludes',
        meta: {
          filterLabel: (cohort) => `includes "${getCohortLabel(cohort as unknown as CohortEnum)}"`,
          arrayFilterOptions: CohortEnumSchema.options
            .filter((cohort) => cohort !== 'super')
            .map((cohort) => ({
              label: getCohortLabel(cohort),
              value: cohort,
            })),
        },
        cell: (cell) => (
          <Wrap spacing="1">
            {cell
              .getValue()
              .filter((cohort) => cohort !== 'super')
              .map((cohort) => (
                <Tag key={cohort}>{getCohortLabel(cohort)}</Tag>
              ))}
          </Wrap>
        ),
      }),
      exportHistory: documentColumnHelper.display({
        id: 'exportHistory',
        header: 'Exports',
        cell: ({ row: { original: document } }) => <ExportHistory {...document} />,
      }),
      createdDate: documentColumnHelper.accessor('createdDate', {
        id: 'createdDate',
        header: 'Created',
        filterFn: createdDateFilterFn,
        enableHiding: false,
        meta: {
          availableFilterFns: [
            { label: 'Date Equals', value: 'dateEquals' },
            { label: 'Date Greater Than', value: 'dateGreaterThan' },
            { label: 'Date Lesser Than', value: 'dateLesserThan' },
          ],
          onFilterFnChange: setCreatedDateFilterFn,
        },
        cell: (cell) => <DateComponent property="createdDate">{cell.getValue()}</DateComponent>,
      }),
      modifiedDate: documentColumnHelper.accessor('modifiedDate', {
        id: 'modifiedDate',
        header: 'Last Modified',
        filterFn: modifiedDateFilterFn,
        enableHiding: false,
        meta: {
          availableFilterFns: [
            { label: 'Date Equals', value: 'dateEquals' },
            { label: 'Date Greater Than', value: 'dateGreaterThan' },
            { label: 'Date Lesser Than', value: 'dateLesserThan' },
          ],
          onFilterFnChange: setModifiedDateFilterFn,
        },
        cell: (cell) => <DateComponent property="modifiedDate">{cell.getValue()}</DateComponent>,
      }),
      dates: documentColumnHelper.display({
        id: 'dates',
        header: 'Dates',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row: { original: document } }) => (
          <Stack fontSize="sm">
            <Stack>
              <Text as="span" fontSize="xs" textStyle="overline">
                Created
              </Text>
              <DateComponent property="createdDate">{document.createdDate}</DateComponent>
            </Stack>
            <Stack>
              <Text as="span" fontSize="xs" textStyle="overline">
                Last Modified
              </Text>
              <DateComponent property="modifiedDate">{document.modifiedDate}</DateComponent>
            </Stack>
          </Stack>
        ),
      }),
      type: documentColumnHelper.accessor('type', {
        id: 'documentType',
        header: 'Document Type',
        enableHiding: false,
      }),
      source: documentColumnHelper.accessor('source', {
        id: 'source',
        header: 'Data Source',
        enableHiding: false,
        meta: {
          FilterComponent: (props) => (
            <DataGridSelectFilter
              {...props}
              options={DocumentSchemaV2.shape.source.options.map((value) => ({
                label: value,
                value,
              }))}
            />
          ),
        },
        cell: (cell) => <DataSource>{cell.getValue()}</DataSource>,
      }),
      meta: documentColumnHelper.display({
        id: 'meta',
        header: 'Meta',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row: { original: document } }) => (
          <Wrap>
            {!!document.type && <Badge colorScheme="blue">{startCase(document.type)}</Badge>}
            <DataSource>{document.source}</DataSource>
          </Wrap>
        ),
      }),
      tags: documentColumnHelper.accessor('tags.key', {
        id: 'tags.key',
        header: 'Tags',
        filterFn: tagFilterFns.tagIncludesKey,
        meta: {
          filterLabel: (tagKeys: string[]) => `includes "${tagKeys.join(' or ')}"`,
          arrayFilterOptions: tagKeys,
          FilterComponent: getTagFilterFnComponent,
        },
        cell: ({ row: { original: document } }) => (
          <TagsList resourceCorn={ensureDocumentCorn(document.id)} />
        ),
      }),
    }),
    [DateComponent, createdDateFilterFn, modifiedDateFilterFn, tagKeys]
  )
}

export type DocumentActionMenuProps = {
  document: DocumentColumn
  children?: ReactNode | ((props: { document: DocumentColumn }) => ReactNode)
}

export function DocumentActionMenu({ document, children }: DocumentActionMenuProps) {
  const { copyToClipboard } = useCopyToClipboard()

  return (
    <>
      <Menu computePositionOnMount>
        <MenuButton
          aria-label="Open document menu"
          as={IconButton}
          icon={<MdMoreHoriz />}
          size="sm"
        />
        <Portal>
          <MenuList>
            <MenuItem onClick={() => copyToClipboard(document.id)}>Copy Document ID</MenuItem>
            {typeof children === 'function' ? children({ document }) : children}
          </MenuList>
        </Portal>
      </Menu>
    </>
  )
}

function DocumentTitle({ cell }: { cell: CellContext<AugmentedDocument, string> }) {
  const document = cell.row.original

  const {
    data: url,
    isPending,
    isError,
  } = useGetDocumentUrl({
    entityId: document.entityId,
    documentId: document.id,
  })

  return (
    <HStack pl={cell.row.depth * 8} spacing="0.5">
      {cell.row.getCanExpand() && (
        <Tooltip label="See previous versions of document">
          <IconButton
            aria-label="See previous versions of document."
            icon={<Icon as={cell.row.getIsExpanded() ? FaCaretDown : FaCaretRight} />}
            isRound={false}
            ml="-3"
            size="sm"
            variant="ghost"
            onClick={cell.row.getToggleExpandedHandler()}
          />
        </Tooltip>
      )}
      {isError ? (
        <Text as="span">{getDocumentTitle(document)}</Text>
      ) : (
        <Skeleton isLoaded={!isPending}>
          <FancyLink href={url} isExternal target={document.id}>
            {getDocumentTitle(document)}
          </FancyLink>
        </Skeleton>
      )}
    </HStack>
  )
}

function ExportHistory({ entityId, id }: AugmentedDocument) {
  const { PreferredDateTimeComponent: DateComponent } = useDatesContext()

  const documentCorn = ensureDocumentCorn(id)
  const { data: publications } = useGetDocumentPublications({ entityId, documentCorn })

  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!publications?.length) {
    return null
  }

  const latestExport = [...publications].sort(
    (a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
  )[0]

  return (
    <>
      <Stack fontSize="sm">
        <Text as="span" fontSize="xs" textStyle="overline">
          Last Export
        </Text>
        <DateComponent property="publishedAt">{latestExport.publishedAt}</DateComponent>
        <Button size="sm" variant="outline" onClick={onOpen}>
          More Details
        </Button>
      </Stack>

      {isOpen && (
        <ExportHistoryModal isOpen={isOpen} publications={publications} onClose={onClose} />
      )}
    </>
  )
}

function ExportHistoryModal({
  publications,
  ...modalProps
}: ComposedModalWrapperProps & { publications: GetDocumentPublicationsResponse }) {
  const { PreferredDateTimeComponent: DateComponent } = useDatesContext()

  return (
    <ComposedModal {...modalProps} title="Export History">
      <Stack divider={<StackDivider />} pb="4" spacing="4">
        {publications.map(({ publishedAt, target, publicationAttributes }) => {
          const { additionalCohorts, entityId } = (publicationAttributes ??
            {}) as Partial<DocumentPublicationAttributes>

          const entityType = !!entityId && isCorn(entityId) && getCornKernels(entityId).resourceType

          return (
            <Stack
              key={`${target}${publishedAt}`}
              alignItems="flex-start"
              lineHeight={1.2}
              spacing="2"
            >
              <Text as="span" textStyle="overline">
                <DateComponent property="publishedAt">{publishedAt}</DateComponent>
              </Text>

              <Box>
                <Tooltip label="Published to:" placement="top">
                  <Badge colorScheme="blue">
                    <HStack spacing="1">
                      <chakra.span mt="0.5">
                        {target === 'XactAnalysis'
                          ? 'XactAnalysis'
                          : target === 'Xactimate'
                            ? 'Xactimate'
                            : !!entityId
                              ? `${
                                  entityType ? `${startCase(entityType)}: ` : ''
                                }${getExportEntityLabel(entityId)}`
                              : 'Unknown'}
                      </chakra.span>

                      <Center>
                        <Icon as={MdInfoOutline} h="4" w="4" />
                      </Center>
                    </HStack>
                  </Badge>
                </Tooltip>
              </Box>

              {!!additionalCohorts?.length && (
                <Tooltip label="Visible to group(s):" placement="top">
                  <Wrap spacing="1">
                    {additionalCohorts
                      .filter((cohort) => cohort !== CohortEnumSchema.Values.super)
                      .map(getCohortLabel)
                      .map((cohort) => (
                        <Tag key={cohort}>{cohort}</Tag>
                      ))}
                  </Wrap>
                </Tooltip>
              )}
            </Stack>
          )
        })}
      </Stack>
    </ComposedModal>
  )
}
