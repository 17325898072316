import { Cohort } from '@eigtech/auth0-types'
import { NoteDataSource } from '@eigtech/notes-types'
import { apiInstance } from '@eigtech/ui-estimator/api'
import { AbilityContext, ability } from '@eigtech/ui-estimator/lib/permissions'
import { theme } from '@eigtech/ui-estimator/lib/theme'
import { ApiInstanceProvider, CohortProvider } from '@eigtech/ui-shared-api'
import { Auth0ProviderWithHistory } from '@eigtech/ui-shared-auth'
import { DaveProvider } from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { LoggerProvider, MeetingStateProvider } from '@eigtech/ui-shared-meetings'
import { NotesProvider } from '@eigtech/ui-shared-notes'
import { RoutesProvider } from '@eigtech/ui-shared-router'
import { ReactNode } from 'react'
import { IconContext } from 'react-icons'
import { ROUTES_CONTEXT } from '../../../lib/constants'

const meetingLog = log.getLogger('claimDetailMeeting')

meetingLog.setDefaultLevel(import.meta.env.PROD ? 'silent' : 'info')

const logger = {
  ...meetingLog,
  setLogLevel: meetingLog.setLevel,
  getLogLevel: meetingLog.getLevel,
}

/**
 * Pre Auth Providers are imported above the App.tsx level in main.tsx
 */
export function PreAuthProviders({ children }: { children: ReactNode }) {
  return (
    // @ts-ignore
    <LoggerProvider logger={logger}>
      <CohortProvider cohort={Cohort.estimator}>
        <MeetingStateProvider canScreenshot>
          <DaveProvider theme={theme}>
            <IconContext.Provider value={{ style: { fontSize: '1.25em' } }}>
              <AbilityContext.Provider value={ability}>
                <RoutesProvider routes={ROUTES_CONTEXT}>
                  <NotesProvider dataSource={NoteDataSource.Estimator}>
                    <Auth0ProviderWithHistory>
                      <ApiInstanceProvider instance={apiInstance}>{children}</ApiInstanceProvider>
                    </Auth0ProviderWithHistory>
                  </NotesProvider>
                </RoutesProvider>
              </AbilityContext.Provider>
            </IconContext.Provider>
          </DaveProvider>
        </MeetingStateProvider>
      </CohortProvider>
    </LoggerProvider>
  )
}
