import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { CallbackRequestSchemaV2 } from '../../../index.js'

export const CallbackResponseRequestPathSchema = CallbackRequestSchemaV2.pick({
  entityId: true,
})

export const CallbackResponseRequestBodySchema = CallbackRequestSchemaV2.pick({
  status: true,
  notes: true,
  role: true,
})

export const CallbackResponseRequestSchema = CallbackResponseRequestPathSchema.merge(
  CallbackResponseRequestBodySchema
)

export type CallbackResponseRequestPath = z.infer<typeof CallbackResponseRequestPathSchema>
export type CallbackResponseRequestBody = z.infer<typeof CallbackResponseRequestBodySchema>

export type CallbackResponseRequest = z.infer<typeof CallbackResponseRequestSchema>

export const CallbackResponseResponseSchema = z.void()
export type CallbackResponseResponse = z.infer<typeof CallbackResponseResponseSchema>

export interface CallbackResponseHandler
  extends Handler<CallbackResponseRequest, CallbackResponseResponse> {}
