import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema, BookMetadataSchema } from '../../../base/books.js'

export const UpdateBookMetadataRequestBodySchema = BookMetadataSchema

export const UpdateBookMetadataRequestPathParametersSchema = z.object({
  bookId: BookCornSchema,
})

export const UpdateBookMetadataRequestSchema = UpdateBookMetadataRequestBodySchema.and(
  UpdateBookMetadataRequestPathParametersSchema
)

export type UpdateBookMetadataRequestBody = z.infer<typeof UpdateBookMetadataRequestBodySchema>

export type UpdateBookMetadataRequestPathParameters = z.infer<
  typeof UpdateBookMetadataRequestPathParametersSchema
>

export type UpdateBookMetadataRequest = z.infer<typeof UpdateBookMetadataRequestSchema>

export type UpdateBookMetadataResponse = void

export interface UpdateBookMetadataHandler
  extends Handler<UpdateBookMetadataRequest, UpdateBookMetadataResponse> {}

export const UpdateBookMetadataApiSchema: ApiSchema = {
  body: UpdateBookMetadataRequestBodySchema,
  params: UpdateBookMetadataRequestPathParametersSchema,
}
