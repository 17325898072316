import { useMemo } from 'react'
import {
  ContactSelectField,
  useGetBook,
  useGetCsrBook,
  useGetFieldAdjusterBook,
  useGetReviewerBook,
} from '@eigtech/ui-shared-contacts'
import { InspectorRoleSchema, RequestClaimReInspectionRequest } from '@eigtech/claims-v2-types'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { useRequestReinspection } from '@eigtech/ui-shared-timeline'
import { startCase, uniqBy } from 'lodash-es'
import { z } from 'zod'
import { makeBookCorn } from '@eigtech/contacts-util'
import { ContactSchema } from '@eigtech/contacts-types'

export type RequestInspectionDrawerProps = ComposedDrawerWrapperProps & {
  claimNumber: string
}

export const RequestInspectionDrawer = withDrawerErrorBoundary(
  function RequestInspectionDrawer(props: RequestInspectionDrawerProps) {
    const { claimNumber, ...drawerProps } = props

    const form = useForm({
      defaultValues: {
        inspectorRole:
          InspectorRoleSchema.options.length === 1
            ? InspectorRoleSchema.Values.fieldAdjuster
            : undefined,
      },
    })

    const { data: claimBook } = useGetBook(makeBookCorn(claimNumber))
    const { data: csrBook } = useGetCsrBook()
    const { data: fieldAdjuster } = useGetFieldAdjusterBook()
    const { data: reviewerBook } = useGetReviewerBook()

    const contactOptions = useMemo(
      () =>
        uniqBy(
          [
            ...(claimBook?.contacts ?? []),
            ...(csrBook?.contacts ?? []),
            ...(fieldAdjuster?.contacts ?? []),
            ...(reviewerBook?.contacts ?? []),
          ],
          'contactId'
        ),
      [claimBook?.contacts, csrBook?.contacts, fieldAdjuster?.contacts, reviewerBook?.contacts]
    )

    const toast = useToast()

    const { mutateAsync: requestInspection, isError } = useRequestReinspection()

    async function onSubmit({ requestedBy, ...data }: RequestInspectionForm) {
      const payload: OmitAuthHeaders<RequestClaimReInspectionRequest> = {
        claimNumber,
        requestedBy: requestedBy.contactId,
        ...data,
      }

      await requestInspection(payload)

      toast({
        status: 'success',
        title: `Successfully requested an inspection!`,
        description: 'It may take a few minutes for changes to be reflected.',
      })

      drawerProps.onClose()
    }

    return (
      <FormDrawer {...baseDrawerProps} {...drawerProps} form={form} onSubmit={onSubmit}>
        <SelectField
          isDisabled={InspectorRoleSchema.options.length === 1}
          label="Inspector Role"
          name="inspectorRole"
          options={InspectorRoleSchema.options.map((value) => ({
            label: startCase(value),
            value,
          }))}
        />

        <ContactSelectField contacts={contactOptions} label="Requested By" name="requestedBy" />

        <TextareaField label="Notes" name="notes" rows={6} />

        {isError && (
          <ComposedAlert
            alert={{
              title: `Failed to request an inspection`,
              description:
                'Please try again. If the issue persists, please contact EIG Tech support.',
            }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  () => baseDrawerProps
)

const baseDrawerProps = {
  title: 'Request Inspection',
}

const RequestInspectionFormSchema = z.object({
  requestedBy: ContactSchema,
  inspectorRole: InspectorRoleSchema,
  notes: z.string(),
})

type RequestInspectionForm = z.infer<typeof RequestInspectionFormSchema>

const { useForm, SelectField, TextareaField } = createForm(
  RequestInspectionFormSchema,
  'requestInspectionForm'
)
