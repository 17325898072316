import {
  Abilities as BaseAbilities,
  Permission as BasePermission,
  Permissions as BasePermissions,
  permissionsFactory,
  RequiredPermission as BaseRequiredPermission,
  Rule as BaseRule,
  WithRequiredPermissionsProps as BaseWithRequiredPermissionsProps,
} from '@eigtech/ui-shared-auth'
import { z } from 'zod'

export const ActionSchema = z.union([
  z.literal('create'),
  z.literal('read'),
  z.literal('update'),
  z.literal('delete'),
])
export type Action = z.infer<typeof ActionSchema>

export const SubjectSchema = z.string()
export type Subject = z.infer<typeof SubjectSchema>

export type Abilities = BaseAbilities<Action, Subject>

export type Permission = BasePermission<Action, Subject>
export type Permissions = BasePermissions<Action, Subject>

export type Rule = BaseRule<Action, Subject>

export type RequiredPermission = BaseRequiredPermission<Action, Subject>

export type WithRequiredPermissionsProps = BaseWithRequiredPermissionsProps<Action, Subject>

const {
  AbilityContext,
  Can,
  ability,
  updateAbility,
  useAbility,
  useAbilityContext,
  useHasRequiredPermissions,
  useKeepAbilityUpToDate,
  withRequiredPermissions,
} = permissionsFactory()

export {
  AbilityContext,
  Can,
  ability,
  updateAbility,
  useAbility,
  useAbilityContext,
  useHasRequiredPermissions,
  useKeepAbilityUpToDate,
  withRequiredPermissions,
}
