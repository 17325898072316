import z from 'zod'
import { BaseJobSchema } from './baseJob.js'
import { JobService } from './baseJobService.js'

export const DailyServicesJobTypeSchema = z.literal('DailyServices')
export const JOB_TYPE_DAILY_SERVICES = DailyServicesJobTypeSchema.value

export const DailyServicesJobTypeAbbreviationSchema = z.literal('DAILY')
export const JOB_TYPE_DAILY_SERVICES_ABBREVIATION = DailyServicesJobTypeAbbreviationSchema.value

export const DailyServicesServiceSchema = z.enum([
  JobService.exterior,
  JobService.interior,
  JobService.roof,
  JobService.detachedStructures,
])

export const DailyServicesJobSchema = BaseJobSchema.merge(
  z.object({
    jobType: DailyServicesJobTypeSchema,
    services: z.array(DailyServicesServiceSchema).transform((val) => Array.from(new Set(val))),
  })
)

export const isDailyServiceservices = (
  s: DailyServicesServiceEnum
): s is DailyServicesServiceEnum => DailyServicesServiceSchema.safeParse(s).success

export interface DailyServicesJob extends z.infer<typeof DailyServicesJobSchema> {}
export type DailyServicesServiceEnum = z.infer<typeof DailyServicesServiceSchema>
