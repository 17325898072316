import { User } from '@auth0/auth0-react'
import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  ComponentWithAs,
  forwardRef,
  Tooltip,
} from '@eigtech/ui-shared-dave'
import { motion } from 'framer-motion'
import { getUserColors, getUserName } from '../utils'
import { getUserPicture } from '../utils/getUserPicture'

export type UserAvatarProps = AvatarProps & {
  avatarSrc?: string
  disableTooltip?: boolean
  isIdle?: boolean
  shouldAnimate?: boolean
  user: string | User
}

export const UserAvatar: ComponentWithAs<'span', UserAvatarProps> = forwardRef(
  ({ avatarSrc, disableTooltip, isIdle, shouldAnimate, user, ...props }, ref) => {
    const name = typeof user === 'string' ? user : getUserName(user)
    const picture = avatarSrc || getUserPicture(user)
    const { color, fontColor } = getUserColors(user)

    return (
      <Tooltip
        bg={color}
        borderRadius="md"
        color={fontColor}
        hasArrow
        isDisabled={disableTooltip}
        label={name}
      >
        <Avatar
          ref={ref}
          as={shouldAnimate ? motion.span : 'span'}
          {...(shouldAnimate ? animationProps : {})}
          bg={color}
          color={fontColor}
          cursor={!!picture ? 'help' : 'auto'}
          mr="2"
          name={name}
          size="sm"
          src={picture}
          {...props}
        >
          {isIdle !== undefined && (
            <AvatarBadge bg={isIdle ? 'gray.400' : 'green.500'} boxSize="0.9em" />
          )}
        </Avatar>
      </Tooltip>
    )
  }
)

const animationProps = {
  layout: true,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}
