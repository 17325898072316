import { useApplyVideoObjectFit, useAudioVideo } from 'amazon-chime-sdk-component-library-react'
import { useEffect, useImperativeHandle, useRef } from 'react'
import { VideoTile, VideoTileProps } from './VideoTile'
import { forwardRef } from '@eigtech/ui-shared-dave'

export type RemoveVideoProps = {
  /** The tile ID to bind the video element to */
  tileId: number
} & VideoTileProps

export const RemoteVideo = forwardRef<RemoveVideoProps, 'video'>(function RemoteVideo(
  { tileId, ...props },
  ref
) {
  const audioVideo = useAudioVideo()
  const videoEl = useRef<HTMLVideoElement>(null)

  useApplyVideoObjectFit(videoEl)

  useImperativeHandle(ref, () => videoEl.current)

  useEffect(() => {
    if (!audioVideo || !videoEl.current) {
      return
    }

    audioVideo.bindVideoElement(tileId, videoEl.current)

    return () => {
      const tile = audioVideo.getVideoTile(tileId)
      if (tile) {
        audioVideo.unbindVideoElement(tileId)
      }
    }
  }, [audioVideo, tileId])

  return <VideoTile ref={videoEl} {...props} tileId={tileId} />
})
