import {
  Box,
  chakra,
  BoxProps,
  Button,
  Center,
  Flex,
  forwardRef,
  GridItem,
  HStack,
  Image,
  Input,
  InputProps,
  SimpleGrid,
  Stack,
  Text,
  IconButton,
} from '@chakra-ui/react'
import {
  ChangeEvent,
  DragEvent,
  DragEventHandler,
  ForwardedRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { IoMdDocument } from 'react-icons/io'
import { MdClose } from 'react-icons/md'
import { ComposedCard, ComposedCardProps } from '../../Card'

export type DropzoneRef = {
  removeFile: (index: number) => void
  removeAllFiles: () => void
}

type FilePreviewFunction = (
  files: File[],
  options: { removeFile: (index: number) => void }
) => JSX.Element

export type DropzoneProps = {
  defaultValue?: File[]
  dropzoneRef?: ForwardedRef<DropzoneRef>
  filesPreview?: FilePreviewFunction | ReactNode | false
  onChange?: (value: File[], meta: { addedFiles: File[] }) => any
  rootProps?: BoxProps
  value?: File[]
  maxFiles?: number
} & Omit<InputProps, 'value' | 'onChange' | 'defaultValue'>

export const Dropzone = forwardRef<DropzoneProps, 'input'>(
  (
    {
      defaultValue,
      dropzoneRef,
      filesPreview,
      maxFiles,
      onChange: propsOnChange,
      rootProps,
      value: propsValue,
      ...props
    },
    ref
  ) => {
    const rootRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const [internalValue, setInternalValue] = useState(defaultValue ?? [])

    const { value } = useMemo(() => {
      const isControlled = typeof propsValue !== 'undefined'
      const value = isControlled ? propsValue : internalValue

      return { isControlled, value }
    }, [propsValue, internalValue])

    const onChange = useCallback(
      (event: ChangeEvent<HTMLInputElement> | File[], replace?: boolean) => {
        const files = Array.isArray(event) ? event : Array.from(event.target.files ?? [])

        const newValue = (!!replace ? files : [...files, ...value]).slice(0, maxFiles)

        propsOnChange?.(newValue, { addedFiles: files })
        setInternalValue(newValue)
      },
      [maxFiles, propsOnChange, value]
    )

    const [isDragActive, setIsDragActive] = useState(false)

    const onDragEnter = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
      event.preventDefault()
      event.stopPropagation()

      if (!isEvtWithFiles(event)) return

      setIsDragActive(true)
    }, [])

    const onDragLeave = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
      event.preventDefault()
      event.stopPropagation()

      setIsDragActive(false)
    }, [])

    const onDragOver = useCallback<DragEventHandler<HTMLDivElement>>((event) => {
      event.preventDefault()
      event.stopPropagation()
    }, [])

    const onDrop = useCallback<DragEventHandler>(
      (event) => {
        event.preventDefault()
        event.stopPropagation()

        setIsDragActive(false)

        if (!isEvtWithFiles(event)) return

        const files = !!event.dataTransfer.items
          ? Array.from(event.dataTransfer.items)
              .map((item) => item.getAsFile())
              .filter((item): item is File => !!item)
          : Array.from(event.dataTransfer.files)

        onChange(files)
      },
      [onChange]
    )

    const removeFile = useCallback(
      (index: number) => {
        const copy = [...value]
        copy.splice(index, 1)
        onChange(copy, true)
      },
      [value, onChange]
    )

    const removeAllFiles = useCallback(() => {
      onChange([], true)
    }, [onChange])

    useImperativeHandle(ref, () => inputRef.current, [inputRef])
    useImperativeHandle(
      dropzoneRef,
      () => ({
        removeFile,
        removeAllFiles,
      }),
      [removeFile, removeAllFiles]
    )

    return (
      <Stack>
        <Center
          ref={rootRef}
          bg={isDragActive ? 'blue.50' : 'blackAlpha.50'}
          borderColor={isDragActive ? 'blue.500' : 'blackAlpha.500'}
          borderStyle="dashed"
          borderWidth="2px"
          cursor="pointer"
          p="8"
          w="full"
          {...rootProps}
          onClick={() => inputRef.current?.click()}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <Input
            key={JSON.stringify(value)}
            ref={inputRef}
            display="none"
            multiple={maxFiles !== 1}
            type="file"
            onChange={onChange}
            {...props}
          />
          <Text>
            Drag and drop {maxFiles === 1 ? 'a file' : 'some files'} here, or click to select{' '}
            {maxFiles === 1 ? 'a file' : 'files'}
          </Text>
        </Center>

        <Stack spacing="4">
          {!!value.length && (
            <Flex justifyContent="flex-end">
              <Button colorScheme="red" variant="outline" onClick={removeAllFiles}>
                Remove All Files
              </Button>
            </Flex>
          )}

          {filesPreview === false
            ? null
            : typeof filesPreview === 'function'
              ? filesPreview(value, { removeFile })
              : !!filesPreview
                ? filesPreview
                : !!value.length && (
                    <SimpleGrid columns={[1, 2, 3, 4, 5]} gap={[2, 4]}>
                      {value.map((file, index) => (
                        <GridItem key={file.name}>
                          <DropzoneFilePreview
                            file={file}
                            isDisabled={props.isDisabled}
                            onRemove={() => removeFile(index)}
                          />
                        </GridItem>
                      ))}
                    </SimpleGrid>
                  )}
        </Stack>
      </Stack>
    )
  }
)

type DropzoneFilePreviewProps = {
  cardProps?: ComposedCardProps
  file: File
  footer?: ComposedCardProps['footer']
  isDisabled?: boolean
  onRemove?: () => any
}

export function DropzoneFilePreview({
  cardProps,
  file,
  footer,
  isDisabled,
  onRemove,
}: DropzoneFilePreviewProps) {
  const preview = useMemo(() => URL.createObjectURL(file), [file])

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isImage = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(file.type)
  const isVideo = ['video/mp4', 'video/mov'].includes(file.type)

  return (
    <ComposedCard
      cardBodyProps={{ px: 0, py: 0 }}
      cardFooterProps={{ py: 2, px: 4 }}
      variant="outline"
      {...cardProps}
      footer={
        footer ?? (
          <HStack>
            <Text fontSize="sm" fontWeight="bold">
              {file.name}
            </Text>
          </HStack>
        )
      }
    >
      <Box h="52" overflow="hidden" position="relative" w="full">
        <Center h="full">
          {isImage ? (
            <Image
              flexGrow={0}
              h="full"
              objectFit="contain"
              src={preview}
              w="full"
              onLoad={() => {
                URL.revokeObjectURL(preview)
              }}
            />
          ) : isVideo ? (
            <chakra.video
              controls
              flexGrow={0}
              h="full"
              objectFit="contain"
              src={preview}
              w="full"
              onLoad={() => {
                URL.revokeObjectURL(preview)
              }}
            />
          ) : (
            <IoMdDocument size="4em" />
          )}
        </Center>

        {!!onRemove && (
          <IconButton
            aria-label="Remove file"
            colorScheme="blackAlpha"
            icon={<MdClose />}
            isDisabled={isDisabled}
            position="absolute"
            right="0.5"
            size="xs"
            top="0.5"
            onClick={onRemove}
          />
        )}
      </Box>
    </ComposedCard>
  )
}

function isEvtWithFiles(event: DragEvent) {
  if (!('dataTransfer' in event)) return

  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
  return event.dataTransfer.types.some(
    (type) => type === 'Files' || type === 'application/x-moz-file'
  )
}
