import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

export type NewLossOfUseRecord = NewTableRow<typeof lossOfUseTable>
export type LossOfUseRecord = TableRow<typeof lossOfUseTable>
export type ReceiptRecord = {
  document_id: string
  receipt_date_received: string
}

const lossOfUseTable = defineTable('loss_of_use', {
  id: Schema.String,
  claim_number: Schema.String,
  status: Schema.String,
  duration_in_days: Schema.Number,
  type: Schema.String,
  amount_requested: Schema.Number,
  last_modified: Schema.nullable(Schema.String),
  receipts: Schema.nullable(
    Schema.JSON(
      Schema.Object({
        document_id: Schema.String,
        receipt_date_received: Schema.String,
      })
    )
  ),
})
