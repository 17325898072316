import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  PortalConsumerInSchema,
  PortalDeactivatedCodeEnumSchema,
  PortalEntityTypeSchema,
  PortalZoneSchema,
  StagedPortalConsumerInSchema,
} from '../../base/portal.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const DeactivatePortalRequestBodySchema = z.object({
  deactivatedCode: PortalDeactivatedCodeEnumSchema,
  deactivatedReason: z.string().optional(),
  externalUserId: z.string(),
  entityId: z.string(),
  entityType: PortalEntityTypeSchema,
  requestActor: z.string(),
  zone: PortalZoneSchema,
})

export const DeactivatePortalRequestSchema = DeactivatePortalRequestBodySchema

export const DeactivatePortalResponseSchema = z.object({
  portal: PortalConsumerInSchema.or(StagedPortalConsumerInSchema).or(z.null()),
})

export type DeactivatePortalRequestBody = z.infer<typeof DeactivatePortalRequestBodySchema>
export type DeactivatePortalRequest = z.infer<typeof DeactivatePortalRequestSchema>
export type DeactivatePortalResponse = z.infer<typeof DeactivatePortalResponseSchema>

export interface DeactivatePortalHandler
  extends Handler<DeactivatePortalRequest, DeactivatePortalResponse> {}

export const DeactivatePortalApiSchema: ApiSchema = {
  body: DeactivatePortalRequestBodySchema,
  response: DeactivatePortalResponseSchema,
}
