import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'messaging'
export const messagingServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const MessagingEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: messagingServiceEventType(),
  })
)

export const MessagingEventSchema = MessagingEventBaseSchema.merge(
  z.object({
    type: messagingServiceEventType(),
    message: z.unknown(),
    schemaVersion: z.literal(1),
  })
)

export type MessagingEvent = z.infer<typeof MessagingEventSchema>
export type MessagingEventBase = z.infer<typeof MessagingEventBaseSchema>
