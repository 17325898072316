import { IncomingHttpHeaders } from 'http'
import { ParsedExportBaseSchema } from './parsedBase.js'
import z from 'zod'

/** This type is temporary and should be updated when we understand more about what this event will look like */
export type RawNoteExport = {
  payload: string
  headers: IncomingHttpHeaders
}

export const ParsedNoteExportSchema = ParsedExportBaseSchema.merge(
  z.object({
    note: z.string(),
    type: z.string(),
    userName: z.string(),
  })
)
export type ParsedNoteExport = z.infer<typeof ParsedNoteExportSchema>

export type XANoteDoc = {
  XACTDOC: {
    XACTNET_INFO: {
      CONTROL_POINTS: {
        CONTROL_POINT: {
          NOTES: string
          stamp: string
          type: string
          userName: string
        }
      }
      transactionId: string
    }
  }
}
