import { HTTPError } from '@eigtech/ui-shared-api'
import { useAuth0, useSyncGetAccessTokenToStore } from '@eigtech/ui-shared-auth'
import { IS_DEV } from '@eigtech/ui-shared-constants'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  CircularProgress,
  ComposedAlert,
  Container,
  Flex,
  GlobalLoader,
  HStack,
  Page,
  Stack,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { FaviconAlerter } from '@eigtech/ui-shared-messaging'
import { Outlet } from '@eigtech/ui-shared-router'
import { Sentry } from '@eigtech/ui-shared-sentry'
import { exhaustive } from 'exhaustive'
import { ReactNode, Suspense, useEffect, useMemo } from 'react'
import {
  useGetEstimatorUser,
  useGetEstimatorUserContact,
  useGetUserPreferences,
} from '../../../api'
import { useHeapIdentityTracking } from '../../../lib/hooks'
import { useKeepAbilityUpToDate } from '../../../lib/permissions'
import { FloatingMeetingControls } from '../../Domains/Claims'
import { Navigation, TopToolbar } from '../../Toolbars'
import { DinoGame } from '../DinoGame'
import { EstimatorLicenseDrawer } from '../EstimatorLicense'
import { PostAuthProviders } from './PostAuthProviders'

const STAGE_NAME = import.meta.env.VITE_STAGE_NAME
if (IS_DEV) {
  document.title = `EIG Estimator Portal - LOCAL DEV`
} else if (typeof STAGE_NAME === 'string' && !STAGE_NAME.startsWith('prod')) {
  document.title = `EIG Estimator Portal - ${STAGE_NAME}`
}

function App() {
  useHeapIdentityTracking()

  const { user, isLoading: isPendingAuth } = useAuth0()

  const { isPending: isPendingPreferences } = useGetUserPreferences()

  const {
    data: userStatus,
    error: userStatusError,
    isPending: isPendingUserStatus,
  } = useGetEstimatorUser({ auth0Id: user?.sub ?? '', email: user?.email ?? '' })

  useEffect(() => {
    if (!user) return

    Sentry.setUser({
      email: user.email,
      id: user.sub,
      username: user.name,
      ip_address: '{{auto}}',
    })
  }, [user])

  const estimator = userStatus?.length ? userStatus[0] : undefined

  const userStatusErrorStatus = useMemo(
    () =>
      userStatusError && userStatusError instanceof HTTPError
        ? userStatusError.response.status
        : undefined,
    [userStatusError]
  )

  const showError = useMemo(
    () => !!userStatusErrorStatus && userStatusErrorStatus !== 404 && userStatusErrorStatus !== 403,
    [userStatusErrorStatus]
  )

  const isPending = useMemo(
    () => isPendingPreferences || isPendingUserStatus || isPendingAuth,
    [isPendingPreferences, isPendingUserStatus, isPendingAuth]
  )

  if (isPending) {
    return <GlobalLoader />
  }

  return (
    <>
      {exhaustive(estimator?.status ?? '_', {
        ACTIVE: UserStatusActive,
        ACTIVATING: UserStatusActivating,
        DEACTIVATING: UserStatusInactive,
        INACTIVE: UserStatusInactive,
        _: () => (showError ? <UserStatusError /> : UserStatusNotFound),
      })}
    </>
  )
}

export function AppContainer({ children }: { children: ReactNode }) {
  return (
    <Flex w="full">
      <Navigation
        display={{
          base: 'none',
          md: 'flex',
        }}
        flexShrink={0}
        zIndex={2}
      />
      <Box flex="1" minW="0px" pb="20">
        <TopToolbar />
        <LicenseAlert />
        <Box position="relative">
          <Suspense>
            {children}

            <FloatingMeetingControls />
            <FaviconAlerter faviconId="favicon" />
          </Suspense>
        </Box>
      </Box>
    </Flex>
  )
}

function UserStatusActive() {
  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  )
}

function LicenseAlert() {
  const { data: contact } = useGetEstimatorUserContact()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!contact || !!contact.metadata.licenseNumber) {
    return null
  }

  return (
    <>
      <Alert status="warning">
        <AlertIcon />
        <Stack
          alignItems={{ base: 'stretch', md: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          w="full"
        >
          <AlertTitle>You have not set your license number yet</AlertTitle>
          <Button onClick={onOpen}>Update License Number</Button>
        </Stack>
      </Alert>

      {isOpen && <EstimatorLicenseDrawer isOpen={isOpen} onClose={onClose} />}
    </>
  )
}

function UserStatusActivating() {
  return (
    <Page>
      <Center>
        <Alert maxW="container.md" status="info">
          <HStack spacing="4">
            <CircularProgress isIndeterminate size={8} />
            <AlertTitle>Locating your claims...</AlertTitle>
          </HStack>
        </Alert>
      </Center>
      <DinoGame />
    </Page>
  )
}

function UserStatusInactive() {
  return (
    <Page>
      <ComposedAlert
        alert="Your account has be deactivated. Please contact customer support."
        status="error"
      />
    </Page>
  )
}

function UserStatusNotFound() {
  return (
    <Page>
      <ComposedAlert
        alert="There are no claims associated with this account. Please contact customer support."
        status="error"
      />
    </Page>
  )
}

function UserStatusError() {
  return (
    <Page>
      <ComposedAlert
        alert="There is an issue retrieving your account information. Please contact customer support."
        status="error"
      />
    </Page>
  )
}

function AuthLoader() {
  const { isAuthenticated: isAuth0Authenticated, isLoading: isPendingAuth, error } = useAuth0()

  const { hasSetAccessToken } = useSyncGetAccessTokenToStore()
  const hasUpdatedAbility = useKeepAbilityUpToDate()

  const isPending = useMemo(
    () => isPendingAuth || !(isAuth0Authenticated && hasSetAccessToken && hasUpdatedAbility),
    [isPendingAuth, isAuth0Authenticated, hasSetAccessToken, hasUpdatedAbility]
  )

  if (!!error) {
    return (
      <Container maxW="container.text">
        <ComposedAlert
          alert={{
            title: 'Auth Error',
            description:
              'Something went wrong authenticating you. Please try again. If the issue persists, please contact us.',
          }}
          shadow="md"
          status="error"
        />
      </Container>
    )
  }

  if (isPending) {
    return <GlobalLoader />
  }

  return (
    <PostAuthProviders>
      <App />
    </PostAuthProviders>
  )
}

export default AuthLoader
