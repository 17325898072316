export function getTimeZoneNiceName(timeZone: string) {
  if (['GMT', 'UTC'].includes(timeZone)) return timeZone

  const split = timeZone.split('/')
  const city = split[1]

  if (!city) {
    throw new Error(`Incorrect time zone string passed: ${timeZone}`)
  }

  return city.replaceAll('_', ' ')
}

export function getTimeZoneAbbreviation(timeZone: string) {
  return new Date()
    .toLocaleTimeString('en-us', { timeZone: timeZone, timeZoneName: 'short' })
    .split(' ')[2]
}
