import { PublicationTargetsSchema } from '@eigtech/claims-v2-types'
import { CheckboxGroupField } from '@eigtech/ui-shared-forms'
import { useGetClaim } from '../api'
import { EntityAdminClaim } from '../types/EntityAdminClaim'
import { DelegateAdminClaim } from '../types/DelegateAdminClaim'

export type PublicationTargetsFieldProps = {
  claimNumber: string
}

export function PublicationTargetsField({ claimNumber }: PublicationTargetsFieldProps) {
  const { data: claim } = useGetClaim<EntityAdminClaim | DelegateAdminClaim>(claimNumber)

  if (
    !claim ||
    ('dataSource' in claim &&
      claim.dataSource !== 'Verisk' &&
      !claim.publicationAttributes?.includes('XactAnalysis'))
  ) {
    return null
  }

  return (
    <CheckboxGroupField
      helperText="You can optionally select to publish this update to the selected target(s)."
      label="Publication Targets"
      name="publicationTargets"
      options={PublicationTargetsSchema.options.map((value) => ({ label: value, value }))}
    />
  )
}
