import { FormControl, FormErrorMessage, Stack } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { FieldArrayPath, FieldValues, get, useFormState } from 'react-hook-form'
import { ArrayFieldContainer } from './ArrayFieldContainer'
import { ArrayFieldProviderProps } from './ArrayFieldContext'
import { ArrayFieldRows, ArrayFieldRowsProps } from './ArrayFieldRows'
import { BaseProps } from 'react-hook-form-chakra'

export type ArrayFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
> = Pick<
  Partial<ArrayFieldRowsProps<TFieldValues, TFieldArrayName>>,
  'renderRow' | 'RowContainer' | 'RowsContainer' | 'emptyMessage'
> &
  Pick<BaseProps, 'control' | 'id'> &
  Pick<ArrayFieldProviderProps<TFieldValues>, 'defaultValue' | 'max' | 'min'> & {
    children?: ReactNode
    label?: string
    name: TFieldArrayName
  }

export function ArrayField<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>({
  children,
  control,
  emptyMessage,
  renderRow,
  RowContainer,
  RowsContainer,
  ...props
}: ArrayFieldProps<TFieldValues, TFieldArrayName>) {
  function Fallback() {
    if (!!renderRow) {
      return (
        <ArrayFieldRows
          RowContainer={RowContainer}
          RowsContainer={RowsContainer}
          emptyMessage={emptyMessage}
          renderRow={renderRow}
        />
      )
    }

    return <></>
  }

  const { errors } = useFormState({ control })

  const rootError = get(errors, `${props.name}.root`)

  return (
    <FormControl isInvalid={!!rootError}>
      <ArrayFieldContainer {...props}>
        <Stack>{children || <Fallback />}</Stack>
      </ArrayFieldContainer>
      <FormErrorMessage>{rootError?.message}</FormErrorMessage>
    </FormControl>
  )
}

export type ArrayFieldComponent<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
> = typeof ArrayField<TFieldValues, TFieldArrayName>
