import { ContactLocationSchema } from '@eigtech/contacts-types'
import { zodEnumWithUserFriendlyError } from '@eigtech/ui-shared-forms'
import { US_STATES_ABBREVIATED } from '@eigtech/ui-shared-us-states'
import { z } from 'zod'

export const ContactFormAddressTypesFieldSchema = zodEnumWithUserFriendlyError(
  ['Firm/Business', 'Home', 'Legal Address', 'Mailing', 'Office'],
  { optionFormatter: (option) => option }
)

export const ContactFormAddressFieldSchema = z.object({
  id: z.string().optional(),
  streetAddress: z.string().optional(),
  streetAddress2: z.string().optional(),
  city: z.string().optional(),
  state: z.enum(US_STATES_ABBREVIATED).optional().or(z.literal('')),
  zip: z.string().max(5, 'String cannot contain more than 5 characters').optional(),
  addressType: ContactFormAddressTypesFieldSchema.optional().or(z.literal('')),
  // pass through via API: location, rawAddress
  location: ContactLocationSchema.optional(),
  rawAddress: z.string().optional(),
})

export type ContactFormAddressField = z.infer<typeof ContactFormAddressFieldSchema>
