import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  ClaimNumberSchema,
  ExternalSystemIdSchema,
  ExternalSystemRecordSchema,
} from '../model/base'
import { BaseHeaderSchema } from './base'

const UpsertExternalSystemIdSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    key: ExternalSystemIdSchema,
    value: z.string(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    externalSystemIds: ExternalSystemRecordSchema,
  }),
}

export const UpsertExternalSystemIdRequestSchema = UpsertExternalSystemIdSchemas.params
  .merge(UpsertExternalSystemIdSchemas.body)
  .merge(UpsertExternalSystemIdSchemas.headers)

export type UpsertExternalSystemIdBodyRequest = z.infer<typeof UpsertExternalSystemIdSchemas.body>
export type UpsertExternalSystemIdHeaderRequest = z.infer<
  typeof UpsertExternalSystemIdSchemas.headers
>
export type UpsertExternalSystemIdRequest = z.infer<typeof UpsertExternalSystemIdRequestSchema>
export type UpsertExternalSystemIdResponse = z.infer<typeof UpsertExternalSystemIdSchemas.response>

export interface UpsertExternalSystemIdHandler
  extends Handler<UpsertExternalSystemIdRequest, UpsertExternalSystemIdResponse> {}

export const UpsertExternalSystemIdApiSchema: ApiSchema = UpsertExternalSystemIdSchemas
