import z from 'zod'
import { Handler } from 'aws-lambda'

export const RecordBatchCompleteRequestSchema = z.object({
  executionId: z.string(),
  error: z.string().optional(),
  hasError: z.boolean(),
})

export const ReplayRecordSchema = z.object({
  executionId: z.string(),
  setupDetails: z.object({
    detachTime: z.number(),
    targetProjectorArn: z.string(),
    eventSourceMappingUUID: z.string(),
    manifestKey: z.string(),
    policyArn: z.string(),
    replayStreamArn: z.string(),
    replayUntil: z.number(),
    shardCount: z.number(),
    prevFilters: z.record(z.unknown()),
  }),
})

export const BatchRecordSchema = z.object({
  executionId: z.string(),
  currentTaskToken: z.string(),
  moreBatches: z.boolean(),
  windowStart: z.number().optional(),
  windowCursor: z.number(),
  awaitingConfirmation: z.number(),
  confirmationsReceived: z.number(),
})

//We don't have a zod type for DeleteEventSourceMappingCommandOutput and I don't want to make one
//So we'll just extend the ts type here and leave the zod type as-is
export interface ReplayRecord extends z.infer<typeof ReplayRecordSchema> {
  setupDetails: z.infer<typeof ReplayRecordSchema.shape.setupDetails>
}

export type BatchRecord = z.infer<typeof BatchRecordSchema>

export type RecordBatchCompleteRequest = z.infer<typeof RecordBatchCompleteRequestSchema>
export type RecordBatchCompleteResponse = void
export type RecordBatchCompleteHandler = Handler<
  RecordBatchCompleteRequest,
  RecordBatchCompleteResponse
>
