import { CornSchema } from '@eigtech/shared-corn'
import z from 'zod'

export const MeetingTranscriptIndexCreatedSchema = z.object({
  artifactBucket: z.string(),
  artifactKey: z.string(),
  entityId: CornSchema,
  meetingId: z.string(),
})

export type MeetingTranscriptIndexCreated = z.infer<typeof MeetingTranscriptIndexCreatedSchema>
