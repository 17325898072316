import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model'

export const UpdateCatCodeSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    catCode: z.string(),
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
  response: z.object({
    claimNumber: ClaimNumberSchema,
    lossDetails: z.object({
      catCode: z.string(),
    }),
  }),
}

export const UpdateCatCodeRequestSchema = UpdateCatCodeSchemas.params
  .merge(UpdateCatCodeSchemas.body)
  .merge(UpdateCatCodeSchemas.headers)

export type UpdateCatCodeBodyRequest = z.infer<typeof UpdateCatCodeSchemas.body>
export type UpdateCatCodeHeaderRequest = z.infer<typeof UpdateCatCodeSchemas.headers>
export type UpdateCatCodeRequest = z.infer<typeof UpdateCatCodeRequestSchema>
export type UpdateCatCodeResponse = z.infer<typeof UpdateCatCodeSchemas.response>

export interface UpdateCatCodeHandler
  extends Handler<UpdateCatCodeRequest, UpdateCatCodeResponse> {}

export const UpdateCatCodeApiSchema: ApiSchema = UpdateCatCodeSchemas
