import { EntityAssignedEventSchema, EntityUnassignedEventSchema } from '@eigtech/assignments-types'
import { z } from 'zod'

export const TimelineAssignmentsEventTypeSchema = z.enum(['assigned', 'unassigned'])

export const TimelineAssignmentsEventSchema = z.union([
  EntityAssignedEventSchema,
  EntityUnassignedEventSchema,
])

export type TimelineAssignmentsEventType = z.infer<typeof TimelineAssignmentsEventTypeSchema>
export type TimelineAssignmentsEvent = z.infer<typeof TimelineAssignmentsEventSchema>
