import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const GetMeetingVideoShareLinkRequestPathSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
})

export const GetMeetingVideoShareLinkRequestHeadersSchema = ApiLambdalerAuthHeaderSchema
export const GetMeetingVideoShareLinkRequestSchema =
  GetMeetingVideoShareLinkRequestPathSchema.merge(GetMeetingVideoShareLinkRequestHeadersSchema)

export const GetMeetingVideoShareLinkResponseSchema = z.object({
  shareLink: z.string(),
})

export type GetMeetingVideoShareLinkRequest = z.infer<typeof GetMeetingVideoShareLinkRequestSchema>
export type GetMeetingVideoShareLinkRequestPath = z.infer<
  typeof GetMeetingVideoShareLinkRequestPathSchema
>
export type GetMeetingVideoShareLinkResponse = z.infer<
  typeof GetMeetingVideoShareLinkResponseSchema
>

export interface GetMeetingVideoShareLinkHandler
  extends Handler<GetMeetingVideoShareLinkRequest, GetMeetingVideoShareLinkResponse> {}
