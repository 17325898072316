import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AttendeeSchema, MeetingSchema } from '../base/chime.js'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'
import { ParticipantSchema } from '../base/participant.js'

export const StartMeetingRequestBodySchema = z.object({
  participant: ParticipantSchema.omit({ auth0Id: true, attendeeId: true }),
  description: z.string().optional(),
})

export const StartMeetingRequestPathSchema = z.object({
  cohort: CohortEnumSchema,
  entityId: CornSchema,
})

export const StartMeetingRequestHeaderSchema = ApiLambdalerAuthHeaderSchema

export const StartMeetingRequestSchema = StartMeetingRequestPathSchema.merge(
  StartMeetingRequestHeaderSchema.merge(StartMeetingRequestBodySchema)
)

export const StartMeetingResponseSchema = z.object({
  meetingDetails: MeetingDetailsSchema,
  chimeMeeting: MeetingSchema,
  chimeAttendee: AttendeeSchema,
})

export type StartMeetingRequest = z.infer<typeof StartMeetingRequestSchema>
export type StartMeetingRequestBody = z.infer<typeof StartMeetingRequestBodySchema>
export type StartMeetingRequestHeader = z.infer<typeof StartMeetingRequestHeaderSchema>
export type StartMeetingRequestPath = z.infer<typeof StartMeetingRequestPathSchema>
export type StartMeetingResponse = z.infer<typeof StartMeetingResponseSchema>

export interface StartMeetingHandler extends Handler<StartMeetingRequest, StartMeetingResponse> {}
