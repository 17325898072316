import { IS_DEV, IS_PROD_ENV } from '@eigtech/ui-shared-constants'
import { createRouter } from '@eigtech/ui-shared-router'
import * as Sentry from '@sentry/react'

export { Sentry }

const STAGE_NAME = import.meta.env.VITE_STAGE_NAME ?? 'prod'

export const initSentry = ({
  release,
  router,
}: {
  release: string
  router: ReturnType<typeof createRouter>
}) =>
  Sentry.init({
    dsn: 'https://ed6db6b29324bd9590f153722895071d@o4508133620187136.ingest.us.sentry.io/4508133621497856',
    environment: STAGE_NAME,
    enabled: !IS_DEV && (STAGE_NAME.includes('prod') || STAGE_NAME === 'test'),
    release,
    integrations: [
      Sentry.browserTracingIntegration(),
      ...(IS_PROD_ENV
        ? [
            Sentry.replayIntegration({
              maskAllText: false,
              maskAllInputs: false,
              networkDetailAllowUrls: [
                /^https:\/\/api\.[a-zA-Z0-9-]+\.eigtech\.io\/?.*$/,
                /^https:\/\/[a-zA-Z0-9-]+-api\.[a-zA-Z0-9-]+\.eigtech\.io\/?.*$/,
                /^https:\/\/.*\.amazonaws\.com\/.*/,
              ],
            }),
          ]
        : []),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.captureConsoleIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [
          [400, 402],
          [405, 499],
          [500, 599],
        ],
      }),
      Sentry.sessionTimingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/[a-zA-Z0-9-]+-api\.[a-zA-Z0-9-]+\.eigtech\.io\/?.*$/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    sendDefaultPii: true, // This option is required for capturing headers and cookies.
  })
