import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { MeetingDetailsSchema } from '../base/meetingDetails.js'

export const EndMeetingRequestPathSchema = z.object({
  entityId: CornSchema,
  startTime: z.string().datetime(),
})

export const EndMeetingRequestSchema = EndMeetingRequestPathSchema

export const EndMeetingResponseSchema = MeetingDetailsSchema

export type EndMeetingRequest = z.infer<typeof EndMeetingRequestSchema>
export type EndMeetingRequestPath = z.infer<typeof EndMeetingRequestPathSchema>
export type EndMeetingResponse = z.infer<typeof EndMeetingResponseSchema>

export interface EndMeetingHandler extends Handler<EndMeetingRequest, EndMeetingResponse> {}
