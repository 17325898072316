import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { AlertSubscriptionSchema } from '../base.js'

export const DeleteAlertSubscriptionBodyRequestSchema = AlertSubscriptionSchema.pick({
  alertLevel: true,
  email: true,
})

export const DeleteAlertSubscriptionRequestSchema = DeleteAlertSubscriptionBodyRequestSchema
export type DeleteAlertSubscriptionRequest = z.infer<typeof DeleteAlertSubscriptionRequestSchema>

export const DeleteAlertSubscriptionResponseSchema = z.void()
export type DeleteAlertSubscriptionResponse = z.infer<typeof DeleteAlertSubscriptionResponseSchema>

export type DeleteAlertSubscriptionHandler = Handler<
  DeleteAlertSubscriptionRequest,
  DeleteAlertSubscriptionResponse
>
