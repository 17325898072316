import { forwardRef, keyframes } from '@chakra-ui/react'
import { ComposedAlert, ComposedAlertProps } from '../Chakra/Alert'

export const PartyAlert = forwardRef<ComposedAlertProps, 'div'>(function PartyAlert(props, ref) {
  return (
    <ComposedAlert
      ref={ref}
      {...props}
      animation={`${gradientAnimation} 3s linear infinite`}
      background={`repeating-linear-gradient(${rotation}, ${gradientColors}, ${gradientColors
        .split(', ')
        .reverse()
        .join(', ')} ${d})`}
      backgroundSize={`calc(${d}/${sinus}) 200%`}
      borderRadius="md"
      iconProps={{ color: 'black' }}
    />
  )
})

// I'm honestly not 100% sure how this all works. I stole if from stack overflow
// https://stackoverflow.com/questions/63787241/css-how-to-create-an-infinitely-moving-repeating-linear-gradient
const d = '50rem'
const sinus = '0.707'
const rotation = '-45deg'

const gradientColors = '#F47174, #ffb347, #FDFD96, #C3EFC3, #93CAED, #AF8FE9'

const gradientAnimation = keyframes`
  0% {
    background-position: calc(${d}/${sinus}) 0;
  }
`
