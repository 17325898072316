import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema } from '../../../base/books.js'
import { ContactCornSchema } from '../../../base/contacts.js'

export const ListBooksByContactRequestPathSchema = z.object({
  contactId: ContactCornSchema,
})

export const ListBooksByContactRequestSchema = ListBooksByContactRequestPathSchema

export const ListBooksByContactResponseSchema = z.object({
  bookCorns: BookCornSchema.array(),
})

export type ListBooksByContactRequestPathParameters = z.infer<
  typeof ListBooksByContactRequestPathSchema
>

export type ListBooksByContactRequest = z.infer<typeof ListBooksByContactRequestSchema>

export type ListBooksByContactResponse = z.infer<typeof ListBooksByContactResponseSchema>

export interface ListBooksByContactHandler
  extends Handler<ListBooksByContactRequest, ListBooksByContactResponse> {}

export const ListBooksByContactApiSchema: ApiSchema = {
  params: ListBooksByContactRequestPathSchema,
  response: ListBooksByContactResponseSchema,
}
