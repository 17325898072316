import { Handler } from 'aws-lambda'
import z from 'zod'
import { SuccessApiOpDetailsSchema } from './base/auth0Events/SuccessApiOperationDetails.js'

//description: "Update a User"
//path "/api/v2/users/auth0%7C63377dca5965a4fa66570452"
export const UserUpdatedBodySchema = SuccessApiOpDetailsSchema.extend({
  request: SuccessApiOpDetailsSchema.shape.request.extend({
    method: z.literal('patch'),
    query: z.object({}),
    body: z.object({
      name: z.string().optional(),
      user_metadata: z.record(z.string(), z.unknown()).optional(),
    }),
    channel: z.string().optional(),
    ip: z.string().optional(),
    auth: z.record(z.string(), z.unknown()).optional(),
  }),
  response: SuccessApiOpDetailsSchema.shape.response.extend({
    statusCode: z.number().optional(),
    body: z.object({
      created_at: z.string(),
      email: z.string(),
      email_verified: z.boolean(),
      identities: z
        .object({
          connection: z.string(),
          user_id: z.string(),
          provider: z.string(),
          isSocial: z.boolean(),
        })
        .array(),
      last_login: z.string().optional(),
      logins_count: z.number().optional(),
      name: z.string(),
      nickname: z.string(),
      picture: z.string(),
      updated_at: z.string(),
      user_id: z.string(),
      app_metadata: z
        .object({
          contactIds: z.array(z.string()).optional(),
          domainOrigin: z.string().optional(),
          eigtech: z.boolean().optional(),
          tester: z.boolean().optional(),
        })
        .or(z.record(z.string(), z.unknown()))
        .optional(),
      user_metadata: z
        .object({
          picture: z.string(),
          birthdata: z.string().optional(),
        })
        .or(z.record(z.string(), z.unknown()))
        .optional(),
    }),
  }),
})

export const UserUpdatedPathSchema = z.object({
  userId: z.string(),
})
export const UserUpdatedRequestSchema = UserUpdatedBodySchema.merge(UserUpdatedPathSchema)

export type UserUpdatedRequest = z.infer<typeof UserUpdatedRequestSchema>
export type UserUpdatedResponse = void

export type UserUpdatedHandler = Handler<UserUpdatedRequest, UserUpdatedResponse>
