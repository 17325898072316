import { MeetingStatus, useMeetingStatus } from 'amazon-chime-sdk-component-library-react'
import { useEffect } from 'react'

export function useConfirmLeave() {
  const meetingStatusEnum = useMeetingStatus()

  useEffect(() => {
    function onBeforeUnload() {
      return true
    }

    if (meetingStatusEnum === MeetingStatus.Succeeded) {
      window.onbeforeunload = onBeforeUnload
    } else {
      window.onbeforeunload = null
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [meetingStatusEnum])
}
