import {
  ACCEPTED_CUSTOM_EXTENSIONS,
  ACCEPTED_FILE_TYPES,
  ACCEPTED_VIDEO_TYPES,
  MAX_FILE_SIZE,
  MAX_VIDEO_FILE_SIZE,
} from '@eigtech/documents-types'
import isValidFilename from 'valid-filename'
import { z } from 'zod'

const invalidTypeError =
  'Only image, Word, Excel, PDF, Xactimate, MP4 and MOV file types are accepted.'
const videoTooBigError =
  'The video is too long.  We recommend taking videos that are only a few minutes long for each room or prompt.'
const fileTooBigError = 'File size must be less than 20MB.'

export const FileSchema = z.object({
  file: z.instanceof(File).superRefine((file, ctx) => {
    if (ACCEPTED_VIDEO_TYPES.includes(file.type)) {
      //Video File type check
      if (file.size > MAX_VIDEO_FILE_SIZE) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          message: videoTooBigError,
        })
      }
    } else if (
      ACCEPTED_FILE_TYPES.includes(file.type) ||
      ACCEPTED_CUSTOM_EXTENSIONS.some((extension) => file.name.toLowerCase().endsWith(extension))
    ) {
      //Approved file type
      if (file.size > MAX_FILE_SIZE) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          message: fileTooBigError,
        })
      }
    } else {
      //Non-video, valid file type
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        fatal: true,
        message: invalidTypeError,
      })
    }
  }),
  notes: z
    .string()
    .max(50, 'String cannot contain more than 50 characters')
    .refine(
      (filenameWithoutExtension) => isValidFilename(`${filenameWithoutExtension}.txt`),
      'Filename must be valid.'
    ),
  description: z.string().max(500, 'String cannot contain more than 500 characters'),
})

export const FileUploadSchema = z.object({
  files: z
    .array(FileSchema)
    .refine((files) => files?.length >= 1, 'Files are required.')
    .refine((files) => files?.length <= 20, 'Can only upload less than 20 files at a time.')
    .transform((files) => Array.from(files)),
})

export type FileUploadForm = z.infer<typeof FileUploadSchema>
