import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const StopTranscriptionQueryRequestSchema = z.object({
  m: z.string(),
})

export const StopTranscriptionRequestSchema = StopTranscriptionQueryRequestSchema

export type StopTranscriptionRequest = z.infer<typeof StopTranscriptionRequestSchema>
export type StopTranscriptionResponse = void

export type StopTranscriptionHandler = Handler<StopTranscriptionRequest, StopTranscriptionResponse>
