import { z } from 'zod'
import { BaseClaimSchema, ClaimFactorsSchema } from '../model/base'
import { ClaimEventSchema, claimEventType } from './claim'

export const ClaimFactorsAddedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType('claimFactorsAdded'),
    claim: BaseClaimSchema.merge(
      z.object({
        claimFactors: ClaimFactorsSchema.array(),
      })
    ),
  })
)

export const ClaimFactorsRemovedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType('claimFactorsRemoved'),
    claim: BaseClaimSchema.merge(
      z.object({
        claimFactors: ClaimFactorsSchema.array(),
      })
    ),
  })
)

export const ClaimFactorsUpdatedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType('claimFactorsUpdated'),
    claim: BaseClaimSchema.merge(
      z.object({
        claimFactors: ClaimFactorsSchema.array(),
      })
    ),
  })
)

export type ClaimFactorsAddedEvent = z.infer<typeof ClaimFactorsAddedEventSchema>
export type ClaimFactorsRemovedEvent = z.infer<typeof ClaimFactorsRemovedEventSchema>
export type ClaimFactorsUpdatedEvent = z.infer<typeof ClaimFactorsUpdatedEventSchema>
