import z from 'zod'
import { Handler } from 'aws-lambda'
import { NotebookQuerySchema } from './notebook.js'

// Get Notebook
export const GetNotebookForCohortPathRequestSchema = z.object({
  cohort: z.string(),
  entityId: z.string(),
})

export const GetNotebookForCohortRequestSchema = GetNotebookForCohortPathRequestSchema

export const GetNotebookForCohortResponseSchema = NotebookQuerySchema

export type GetNotebookForCohortPathRequest = z.infer<typeof GetNotebookForCohortPathRequestSchema>
export type GetNotebookForCohortRequest = z.infer<typeof GetNotebookForCohortRequestSchema>
export type GetNotebookForCohortResponse = z.infer<typeof GetNotebookForCohortResponseSchema>
export type GetNotebookForCohortHandler = Handler<
  GetNotebookForCohortRequest,
  GetNotebookForCohortResponse
>
