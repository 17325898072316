import { z } from 'zod'
import { BookCornSchema, ContactDataSourceSchema, ContactSchema } from '@eigtech/contacts-types'

import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

const serviceName = 'contacts'
const serviceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)
const bookEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('book', eventName)
const claimBookEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('claimBook', eventName)
const claimContactEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('claimContact', eventName)

const ClaimContactSchema = z.object({
  contact: ContactSchema,
  claimNumber: z.string(),
})

const ServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: serviceEventType(),
  })
)

const BookEventSchema = ServiceEventBaseSchema.merge(
  z.object({
    type: bookEventType(),
    book: z.unknown(),
    schemaVersion: z.literal(1),
  })
)

const ClaimContactEventSchema = ServiceEventBaseSchema.merge(
  z.object({
    type: claimContactEventType(),
    claimContact: ClaimContactSchema,
    metadata: ServiceEventBaseSchema.shape.metadata.and(
      z.object({
        requestedBy: z.string().optional(),
        dataSource: ContactDataSourceSchema.or(z.string()),
      })
    ),
  })
)

export const DeprecatedClaimBookMetadataUpdatedEventSchema = BookEventSchema.merge(
  z.object({
    type: claimBookEventType('metadataUpdated'),
    book: z.object({
      bookId: BookCornSchema,
      primaryContact: ContactSchema.or(z.undefined()),
      carrier: ContactSchema.or(z.undefined()),
      fieldAdjuster: ContactSchema.or(z.undefined()),
      coordinator: ContactSchema.or(z.undefined()),
      policyHolder: ContactSchema.or(z.undefined()),
      hasLegalRep: z.boolean(),
    }),
  })
)

export const DeprecatedClaimBookLegalRepEventSchema = BookEventSchema.merge(
  z.object({
    type: claimBookEventType('legalRepSet'),
    book: z.object({
      bookId: BookCornSchema,
      hasLegalRep: z.boolean(),
    }),
  })
)

export const DeprecatedCarrierSetEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('carrierSet'),
    claimContact: ClaimContactSchema,
  })
)

export const DeprecatedPolicyHolderSetEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('policyHolderSet'),
    claimContact: ClaimContactSchema,
  })
)

export const DeprecatedPrimaryContactSetEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('primaryContactSet'),
    claimContact: ClaimContactSchema,
  })
)

export const DeprecatedPrimaryFieldAdjusterSetEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('primaryFieldAdjusterSet'),
    claimContact: ClaimContactSchema,
  })
)

export const DeprecatedPrimaryFieldAdjusterRemovedEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('primaryFieldAdjusterRemoved'),
    claimContact: ClaimContactSchema.omit({ contact: true }),
  })
)

export const DeprecatedPrimaryCoordinatorSetEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('primaryCoordinatorSet'),
    claimContact: ClaimContactSchema,
  })
)

export const DeprecatedPrimaryCoordinatorRemovedEventSchema = ClaimContactEventSchema.merge(
  z.object({
    type: claimContactEventType('primaryCoordinatorRemoved'),
    claimContact: ClaimContactSchema.omit({ contact: true }),
  })
)

export const DeprecatedContactEventSchema = DeprecatedClaimBookMetadataUpdatedEventSchema.or(
  DeprecatedCarrierSetEventSchema
)
  .or(DeprecatedPolicyHolderSetEventSchema)
  .or(DeprecatedPrimaryContactSetEventSchema)
  .or(DeprecatedPrimaryFieldAdjusterSetEventSchema)
  .or(DeprecatedPrimaryFieldAdjusterRemovedEventSchema)
  .or(DeprecatedPrimaryCoordinatorSetEventSchema)
  .or(DeprecatedPrimaryCoordinatorRemovedEventSchema)

export type ClaimBookMetadataUpdatedEvent = z.infer<
  typeof DeprecatedClaimBookMetadataUpdatedEventSchema
>

export type DeprecatedClaimBookMetadataUpdatedEvent = z.infer<
  typeof DeprecatedClaimBookMetadataUpdatedEventSchema
>
export type DeprecatedClaimBookLegalRepEvent = z.infer<
  typeof DeprecatedClaimBookLegalRepEventSchema
>
export type DeprecatedCarrierSetEvent = z.infer<typeof DeprecatedCarrierSetEventSchema>
export type DeprecatedPolicyHolderSetEvent = z.infer<typeof DeprecatedPolicyHolderSetEventSchema>
export type DeprecatedPrimaryContactSetEvent = z.infer<
  typeof DeprecatedPrimaryContactSetEventSchema
>
export type DeprecatedPrimaryFieldAdjusterSetEvent = z.infer<
  typeof DeprecatedPrimaryFieldAdjusterSetEventSchema
>
export type DeprecatedPrimaryFieldAdjusterRemovedEvent = z.infer<
  typeof DeprecatedPrimaryFieldAdjusterRemovedEventSchema
>
export type DeprecatedPrimaryCoordinatorSetEvent = z.infer<
  typeof DeprecatedPrimaryCoordinatorSetEventSchema
>
export type DeprecatedPrimaryCoordinatorRemovedEvent = z.infer<
  typeof DeprecatedPrimaryCoordinatorRemovedEventSchema
>
export type DeprecatedContactEvent = z.infer<typeof DeprecatedContactEventSchema>
