import { ContextualCan } from '@eigtech/ui-shared-auth'
import { ComposedAlert, Stack } from '@eigtech/ui-shared-dave'
import { CheckboxField } from '@eigtech/ui-shared-forms'

export function PublishNoteField() {
  return (
    <ContextualCan I="publishToXa" a="note">
      <Component />
    </ContextualCan>
  )
}

function Component() {
  return (
    <Stack spacing="4">
      <ComposedAlert
        alert={{
          title: 'Publishing to XA',
          description:
            'Selecting this option will publish this note to XA, where it will be visible on the claim in that system.',
        }}
      />

      <CheckboxField
        fontWeight="semibold"
        label="Would you like to publish this note to XA?"
        name="publishOnCreate"
      />
    </Stack>
  )
}
