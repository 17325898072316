import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  PortalConsumerSchema,
  PortalEntityTypeSchema,
  PortalZoneSchema,
} from '../../base/portal.js'

export const ListUserPortalsRequestPathSchema = z.object({
  email: z.string().transform((val) => decodeURI(val)),
  entityType: PortalEntityTypeSchema,
})

export const ListUserPortalsRequestQueryStringSchema = z.object({
  zone: PortalZoneSchema.optional(),
  externalUserId: z.string().optional(),
})

export const ListUserPortalsRequestSchema = ListUserPortalsRequestPathSchema.merge(
  ListUserPortalsRequestQueryStringSchema
)

export const ListUserPortalsResponseSchema = z.object({ portals: z.array(PortalConsumerSchema) })

export type ListUserPortalsRequestPath = z.infer<typeof ListUserPortalsRequestPathSchema>
export type ListUserPortalsRequest = z.infer<typeof ListUserPortalsRequestSchema>
export type ListUserPortalsResponse = z.infer<typeof ListUserPortalsResponseSchema>

export interface ListUserPortalsHandler
  extends Handler<ListUserPortalsRequest, ListUserPortalsResponse> {}

export const ListUserPortalsApiSchema: ApiSchema = {
  params: ListUserPortalsRequestPathSchema,
  query: ListUserPortalsRequestQueryStringSchema,
  response: ListUserPortalsResponseSchema,
}
