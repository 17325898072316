import { ReactNode, useMemo } from 'react'
import { ContextualCan } from '..'
import { WithRequiredPermissionsProps } from './types'
import { AllowUndefined } from '@eigtech/ui-shared-dave'

export type HasRequiredPermissionsProps<
  A extends string = string,
  S extends string = string,
> = AllowUndefined<WithRequiredPermissionsProps<A, S>, 'requiredPermissions'> & {
  passThrough?: boolean
  children: ReactNode | ((isAllowed: boolean) => ReactNode)
}

export function HasRequiredPermissions<A extends string = string, S extends string = string>({
  children,
  requiredPermissions: propsRequiredPermissions,
  passThrough,
}: HasRequiredPermissionsProps<A, S>) {
  const RequiredPermissions = useMemo(() => {
    const requiredPermissions = [propsRequiredPermissions ?? []].flat()

    return requiredPermissions.reduce(
      (Accumulator, permission) =>
        function RequiredPermission({ children }: Pick<HasRequiredPermissionsProps, 'children'>) {
          return (
            <Accumulator>
              <ContextualCan I={permission.action} a={permission.subject} passThrough={passThrough}>
                {(isAllowed) => (typeof children === 'function' ? children(isAllowed) : children)}
              </ContextualCan>
            </Accumulator>
          )
        },
      ({ children }: Pick<HasRequiredPermissionsProps, 'children'>) => (
        <>{typeof children === 'function' ? children(true) : children}</>
      )
    )
  }, [passThrough, propsRequiredPermissions])

  return (
    <RequiredPermissions>
      {(isAllowed) => (typeof children === 'function' ? children(isAllowed) : children)}
    </RequiredPermissions>
  )
}
