import { ApiSchema } from '@eigtech/gatekeeper-types'
import { NoteSchema } from '@eigtech/notes-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const XAPublicationTarget = 'XactAnalysis'
export const XM8PublicationTarget = 'Xactimate'

export const ImportNoteRequestBodySchema = z.object({
  claimNumber: z.string().optional(),
  transactionId: z.string().optional(),
  note: NoteSchema,
})

export const ImportNoteRequestSchema = ImportNoteRequestBodySchema

export type ImportNoteRequest = z.infer<typeof ImportNoteRequestSchema>
export type ImportNoteResponse = void

export type ImportNoteHandler = Handler<ImportNoteRequest, ImportNoteResponse>

export const ImportNoteApiSchema: ApiSchema = {
  body: ImportNoteRequestBodySchema,
}
