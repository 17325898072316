import { LinkProps as CLinkProps, forwardRef } from '@eigtech/ui-shared-dave'
import { LinkProps } from '@tanstack/react-router'
import { RefAttributes } from 'react'
import { Link } from './Link'

type LinkDangerouslyProps = LinkProps<any, any, any, any, any>

export const LinkDangerously = forwardRef<LinkDangerouslyProps, 'a'>((props, ref) => (
  // @ts-ignore
  <Link params={(p) => p} search={(s) => s} {...props} ref={ref} />
)) as (
  props: LinkProps<any, any, any, any, any> & CLinkProps & RefAttributes<HTMLAnchorElement>
) => JSX.Element
