import { Handler } from 'aws-lambda'
import z from 'zod'

export const ReplayRequestSchema = z.object({
  targetProjectorArn: z.string(),
  startAt: z.string().datetime().optional(),
  windowStart: z.number().optional(),
  windowCursor: z.number().optional(),
})

export type ReplayRequest = z.infer<typeof ReplayRequestSchema>

export type ReplayResponse = {
  executionArn?: string
}

export type ReplayHandler = Handler<ReplayRequest, ReplayResponse>

export type StartReplayInput = {
  targetProjectorArn: string
  startAt?: string
  windowStart?: number
  windowCursor?: number
}

type StepFunctionTaskContext = {
  Execution: {
    Id: string
    Name: string
    RoleArn: string
    StartTime: string
  }
  State: {
    EnteredTime: string
    Name: string
    RetryCount: number
  }
  StateMachine: {
    Id: string
    Name: string
  }
  Task: {
    Token: string
  }
}

export type SetupReplayRequest = {
  context: StepFunctionTaskContext
  input: StartReplayInput & {
    executionId: string
  }
}

export type SetupReplayResponse = {
  executionId: string
  replayStreamArn: string
  windowStart?: number | null
  windowCursor?: number | null
  replayUntil: number
  manifestKey: string
}

export type SetupReplayHandler = Handler<SetupReplayRequest, SetupReplayResponse>

export type SignalOnSetupRequest = {
  token: string
  input: StartReplayInput & {
    setupResults: SetupReplayResponse
  }
}

export type SignalOnSetupResponse = Record<string, unknown>

export type SignalOnSetupHandler = Handler<SignalOnSetupRequest, void>

export type PublishBatchReplayRequest = {
  token: string
  input: StartReplayInput & {
    setupResults: SetupReplayResponse
    publishResults?: PublishBatchReplayResponse
  }
}

export type PublishBatchReplayResponse = {
  moreBatches: boolean
  windowStart?: number
  windowCursor?: number
}

export type PublishBatchReplayHandler = Handler<PublishBatchReplayRequest, void>

export type PublishCompleteReplayRequest = {
  token: string
  input: StartReplayInput & {
    setupResults: SetupReplayResponse
  }
}
export type PublishCompleteReplayHandler = Handler<PublishCompleteReplayRequest, void>

export type AlertReplayRequest = {
  input: unknown
  severity: 'normal' | 'critical'
}

export type AlertReplayResponse = void

export type AlertReplayHandler = Handler<AlertReplayRequest, AlertReplayResponse>

export type ErrorTeardownRequest = {
  Error: string
  Cause: string
}

export type SuccessTeardownRequest = StartReplayInput & {
  setupResults: SetupReplayResponse
  publishResults: PublishBatchReplayResponse
}

export type TeardownErrorDetails = {
  executionId: string
}

export type TeardownReplayRequest = ErrorTeardownRequest | SuccessTeardownRequest

export type TeardownReplayResponse = string

export type TeardownReplayHandler = Handler<TeardownReplayRequest, TeardownReplayResponse>

export type ReplayEndedEvent = {
  type: 'ReplayEnd'
  token: string
}
