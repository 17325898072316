import { DocumentCornSchema } from '@eigtech/documents-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { LossOfUseReceiptAddedEventSchema } from '../events/lossOfUse'
import { ClaimNumberSchema } from '../model'
import { BaseHeaderSchema } from './base'

const AddLossOfUseReceiptSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
    documentCorn: DocumentCornSchema,
    id: z.string(),
  }),
  body: z.object({
    note: z.string().optional().nullable(),
  }),
  headers: BaseHeaderSchema,
  response: LossOfUseReceiptAddedEventSchema.shape.claim.shape.lossOfUse,
}

export const AddLossOfUseReceiptRequestSchema = AddLossOfUseReceiptSchemas.params
  .merge(AddLossOfUseReceiptSchemas.headers)
  .merge(AddLossOfUseReceiptSchemas.body)

const AddLossOfUseReceiptInputSchema = AddLossOfUseReceiptRequestSchema.merge(
  z.object({
    documentCorn: DocumentCornSchema,
    receiptDateReceived: z.string().datetime(),
  })
)

export type AddLossOfUseReceiptInput = z.infer<typeof AddLossOfUseReceiptInputSchema>
export type AddLossOfUseReceiptHeaderRequest = z.infer<typeof AddLossOfUseReceiptSchemas.headers>
export type AddLossOfUseReceiptBodyRequest = z.infer<typeof AddLossOfUseReceiptSchemas.body>
export type AddLossOfUseReceiptRequest = z.infer<typeof AddLossOfUseReceiptRequestSchema>
export type AddLossOfUseReceiptResponse = z.infer<typeof AddLossOfUseReceiptSchemas.response>

export interface AddLossOfUseReceiptHandler
  extends Handler<AddLossOfUseReceiptRequest, AddLossOfUseReceiptResponse> {}

export const AddLossOfUseReceiptApiSchema: ApiSchema = AddLossOfUseReceiptSchemas
