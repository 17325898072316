import { HTMLInputTypeAttribute } from 'react'
import { FieldValues } from 'react-hook-form'
import { InputControl, InputControlProps } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type InputFieldProps<TFieldValues extends FieldValues = FieldValues> = WithFieldNameProp<
  InputControlProps,
  TFieldValues
> & { type?: HTMLInputTypeAttribute }

export function InputField<TFieldValues extends FieldValues = FieldValues>(
  props: InputFieldProps<TFieldValues>
) {
  return (
    <InputControl
      {...props}
      inputProps={{
        isDisabled: props.isDisabled,
        isReadOnly: props.isReadOnly,
        type: props.type ?? 'text',
        ...props.inputProps,
      }}
    />
  )
}

export type InputFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof InputField<TFieldValues>
