import z from 'zod'
import { NotebookCornSchema, NoteSchema } from '../base/index.js'
import { NotesServiceEventBaseSchema, serviceEventType } from './notesService.js'

export const noteEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('note', eventName)

export const notebookEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType('notebook', eventName)

export const NoteEventSchema = NotesServiceEventBaseSchema.merge(
  z.object({
    type: noteEventType(),
  })
)

export const NotebookEventSchema = NotesServiceEventBaseSchema.merge(
  z.object({
    type: notebookEventType(),
  })
)

export const NoteAddedEventSchema = NoteEventSchema.merge(
  z.object({
    type: noteEventType('added'),
    note: NoteSchema.merge(z.object({ notebookId: NotebookCornSchema })).partial({
      entityId: true,
    }),
  })
)

export const NoteRemovedEventSchema = NoteEventSchema.merge(
  z.object({
    type: noteEventType('removed'),
    note: NoteSchema.merge(z.object({ notebookId: NotebookCornSchema })),
  })
)

export const NoteMarkedForPublicationEventSchema = NoteEventSchema.merge(
  z.object({
    type: noteEventType('markedForPublication'),
    note: NoteSchema,
  })
)

export const NoteTaggedContactsEventSchema = NoteEventSchema.merge(
  z.object({
    type: noteEventType('taggedContacts'),
    note: NoteSchema,
  })
)

export type NoteEvent = z.infer<typeof NoteEventSchema>
export type NoteAddedEvent = z.infer<typeof NoteAddedEventSchema>
export type NoteRemovedEvent = z.infer<typeof NoteRemovedEventSchema>
export type NoteMarkedForPublicationEvent = z.infer<typeof NoteMarkedForPublicationEventSchema>
export type NoteTaggedContactsEvent = z.infer<typeof NoteTaggedContactsEventSchema>
