import { CohortEnumSchema } from '@eigtech/auth0-types'
import { ContactCornSchema } from '@eigtech/contacts-types'
import z from 'zod'
import { NoteCornSchema } from './corn.js'

export const NoteDataSourceSchema = z.enum([
  'Verisk',
  'CSR',
  'Estimator',
  'TPLAP',
  'EmailSending',
  'EmailReceiving',
  'Meetings',
])
export const NoteDataSource = NoteDataSourceSchema.Values

export const NoteSchema = z.object({
  id: NoteCornSchema,
  entityId: z.string(),
  sourceId: z.string().optional(),
  text: z.string(),
  createdOn: z.string().datetime(),
  createdBy: z.string(),
  title: z.string().optional(),
  templateId: z.string().optional(),
  category: z.string().optional(),
  dataSource: NoteDataSourceSchema.or(z.string()).optional(),
  userCohorts: CohortEnumSchema.array(),
  publicationTargets: z.string().array().optional(),
  taggedContacts: ContactCornSchema.array().optional(),
})

export type Note = z.infer<typeof NoteSchema>
export type NoteDataSourceEnum = z.infer<typeof NoteDataSourceSchema>
