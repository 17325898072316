import { ComposedAlert, Page } from '@eigtech/ui-shared-dave'
import { Navigate, createFileRoute } from '@eigtech/ui-shared-router'
import { z } from 'zod'
import { ROUTER_PATHS } from '../../lib/constants'

const RedirectSearchSchema = z.object({
  claimNumber: z.string().catch(''),
  meetingId: z.string().catch(''),
  startTime: z.string().catch(''),
})

export const Route = createFileRoute('/meeting')({
  component: RedirectToMeeting,
  validateSearch: RedirectSearchSchema.parse,
})

function RedirectToMeeting() {
  const { claimNumber, meetingId, startTime } = Route.useSearch()

  if (!(claimNumber && meetingId && startTime)) {
    return (
      <Page>
        <ComposedAlert
          alert={{
            title: 'Could not find meeting details',
            description: 'Required meeting information is missing. Please try again.',
          }}
          status="error"
        />
      </Page>
    )
  }

  return (
    <Navigate
      params={{ claimNumber, meetingId, meetingStartTime: startTime }}
      to={ROUTER_PATHS.claimDetailMeeting.route}
    />
  )
}
