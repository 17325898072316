import z from 'zod'
import { Handler } from 'aws-lambda'
import { CohortEnumSchema } from '@eigtech/auth0-types'

/** Remove cohort from note */
export const RemoveCohortFromNoteBodyRequestSchema = z.object({
  cohort: CohortEnumSchema,
})

export const RemoveCohortFromNotePathRequestSchema = z.object({
  noteId: z.string(),
})

export const RemoveCohortFromNoteRequestSchema = RemoveCohortFromNoteBodyRequestSchema.merge(
  RemoveCohortFromNotePathRequestSchema
)

export type RemoveCohortFromNoteBodyRequest = z.infer<typeof RemoveCohortFromNoteBodyRequestSchema>
export type RemoveCohortFromNotePathRequest = z.infer<typeof RemoveCohortFromNotePathRequestSchema>
export type RemoveCohortFromNoteRequest = z.infer<typeof RemoveCohortFromNoteRequestSchema>
export type RemoveCohortFromNoteResponse = void
export type RemoveCohortFromNoteHandler = Handler<
  RemoveCohortFromNoteRequest,
  RemoveCohortFromNoteResponse
>
