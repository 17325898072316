import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, LocationSchema } from '../model'

export const UpdateLossLocationSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: LocationSchema,
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
}

export const UpdateLossLocationRequestSchema = UpdateLossLocationSchemas.params
  .merge(UpdateLossLocationSchemas.body)
  .merge(UpdateLossLocationSchemas.headers)

export const UpdateLossLocationResponseSchema = z.object({
  claimNumber: ClaimNumberSchema,
  lossLocation: LocationSchema,
})

export type UpdateLossLocationBodyRequest = z.infer<typeof UpdateLossLocationSchemas.body>
export type UpdateLossLocationHeaderRequest = z.infer<typeof UpdateLossLocationSchemas.headers>
export type UpdateLossLocationRequest = z.infer<typeof UpdateLossLocationRequestSchema>
export type UpdateLossLocationResponse = z.infer<typeof UpdateLossLocationResponseSchema>

export interface UpdateLossLocationHandler
  extends Handler<UpdateLossLocationRequest, UpdateLossLocationResponse> {}

export const UpdateLossLocationApiSchema: ApiSchema = UpdateLossLocationSchemas
