// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/badge.ts

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const baseStyle = defineStyle({
  borderRadius: 'xl',
})

// const $bg = cssVar('badge-bg')
// const $fg = cssVar('badge-color')

// const variantSolid = defineStyle({})

// const variantSubtle = defineStyle({})

// const variantOutline = defineStyle({})

// const variants = {
//   solid: variantSolid,
//   subtle: variantSubtle,
//   outline: variantOutline,
// }

const sizes = {
  md: defineStyle({
    px: 3,
    py: 1,
  }),
  sm: defineStyle({
    px: 1,
    py: 0,
    borderRadius: 'md',
  }),
}

export const badgeTheme = defineStyleConfig({
  baseStyle,
  // variants,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'brandPrimary',
  },
})
