import { useGetUserPreferences, usePutUserPreferences } from '@eigtech/ui-estimator/api'

export function useNavCollapsedSetting() {
  const { data: userSettings } = useGetUserPreferences()

  const isCollapsed = !!userSettings?.collapsedNav

  const { mutate: putUserPreferences } = usePutUserPreferences()
  function setIsCollapsed(value: boolean) {
    putUserPreferences({ collapsedNav: value })
  }

  return { isCollapsed, setIsCollapsed }
}
