import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import {
  ClaimStatusAdminEventName,
  ClaimStatusCanon,
  ClaimStatusCanonSchema,
  ClaimStatusEventNameCanon,
  ClaimStatusLax,
  ClaimStatusLaxSchema,
} from '../aggregates/claimStatus.js'
import { ClaimsServiceEventBaseSchema, claimsServiceEventType } from './service.js'

type ClaimStatusEventType = ClaimStatusEventNameCanon | ClaimStatusAdminEventName

const statusEventType = <V extends ClaimStatusEventType | undefined>(eventName?: V) =>
  claimsServiceEventType('status', eventName)

export const ClaimStatusEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType(),
    status: ClaimStatusCanonSchema.and(z.record(z.unknown())),
  })
)

export const ClaimStatusEventLaxSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType(),
    status: ClaimStatusLaxSchema.and(z.record(z.unknown())),
  })
)

export const LossDateEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('lossDate'),
  })
)
export const InitialCustomerContactAttemptedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('initialCustomerContactAttempted'),
  })
)
export const InitialCustomerContactSuccessEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('initialCustomerContactSuccess'),
  })
)
export const InitialCustomerContactFailedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('initialCustomerContactFailed'),
  })
)
export const InvalidContactInformationEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('invalidContactInformation'),
  })
)
export const CustomerNoContactEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('customerNoContact'),
  })
)
export const FieldAdjusterAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterAssigned'),
  })
)
export const FieldAdjusterReassignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterReassigned'),
  })
)
export const FieldAdjusterRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterRemoved'),
  })
)
export const FieldAdjusterContactedCustomerEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterContactedCustomer'),
  })
)
export const FieldAdjusterCorrectionUploadedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterCorrectionUploaded'),
  })
)
export const FieldAdjusterInspectionScheduledEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterInspectionScheduled'),
    status: ClaimStatusEventSchema.shape.status.and(
      z.object({
        scheduledDate: z.string().optional(),
      })
    ),
  })
)
export const FieldAdjusterInspectionCompletedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterInspectionCompleted'),
  })
)
export const JobNotSoldEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('jobNotSold'),
  })
)
export const FieldAdjusterEstimateUploadedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('fieldAdjusterEstimateUploaded'),
  })
)
export const ClientApprovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('clientApproved'),
  })
)
export const ClientRejectedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('clientRejected'),
  })
)
export const DateReceivedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('dateReceived'),
  })
)
export const QaApprovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaApproved'),
  })
)
export const QaApprovedPreliminaryReportEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaApprovedPreliminaryReport'),
  })
)
export const QaApprovedSupplementReportEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaApprovedSupplementReport'),
  })
)
export const QARejectedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('qaRejected'),
  })
)
export const EstimateSentToCarrierEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('estimateSentToCarrier'),
  })
)
export const ClaimInvoiceCreatedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('claimInvoiceCreated'),
  })
)
export const ReInspectionRequestedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('reInspectionRequested'),
  })
)
export const EstimateRevisionRequestedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('estimateRevisionRequested'),
  })
)
export const OTRInvokedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('otrInvoked'),
  })
)
export const WADSentToCustomerEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('wadSentToCustomer'),
  })
)
export const CarrierApprovedEstimateEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('carrierApprovedEstimate'),
  })
)
export const CarrierApprovedEstimateWithExceptionsEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('carrierApprovedEstimateWithExceptions'),
  })
)
export const CarrierRejectedEstimateEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('carrierRejectedEstimate'),
  })
)
export const JobContractedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('jobContracted'),
  })
)
export const JobCompletedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('jobCompleted'),
  })
)
export const CanceledEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('canceled'),
  })
)
export const CoordinatorAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('coordinatorAssigned'),
  })
)
export const CoordinatorRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('coordinatorRemoved'),
  })
)
export const InAssignQueueEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('inAssignQueue'),
  })
)
export const SurveySentToCustomerEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('surveySentToCustomer'),
  })
)

export const CustomerContactedEmailEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('customerContactedEmail'),
    status: ClaimStatusCanonSchema,
  })
)
export const CustomerContactedLvmEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('customerContactedLvm'),
    status: ClaimStatusCanonSchema,
  })
)
export const JobSoldEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('jobSold'),
    status: ClaimStatusCanonSchema,
  })
)
export const JobStartedEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('jobStarted'),
    status: ClaimStatusCanonSchema,
  })
)

export const StatusIdUpdatedEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('idUpdated'),
    status: z.object({
      previous: ClaimStatusCanonSchema,
      current: ClaimStatusCanonSchema,
    }),
  })
)

export const StatusDeletedEventSchema = ClaimsServiceEventBaseSchema.merge(
  z.object({
    type: statusEventType('deleted'),
    status: ClaimStatusCanonSchema,
  })
)

export const ProjectManagerAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('projectManagerAssigned'),
  })
)
export const ProjectManagerRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('projectManagerRemoved'),
  })
)

export const ReviewerAssignedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('reviewerAssigned'),
  })
)
export const ReviewerRemovedEventSchema = ClaimStatusEventSchema.merge(
  z.object({
    type: statusEventType('reviewerRemoved'),
  })
)
////////////////
// TypeGuards //
////////////////
export const isFieldAdjusterInspectionScheduledEvent = (
  event: EventBase
): event is FieldAdjusterInspectionScheduledEvent =>
  FieldAdjusterInspectionScheduledEventSchema.safeParse(event).success
export const isQaApprovedEvent = (event: EventBase): event is QaApprovedEvent =>
  QaApprovedEventSchema.safeParse(event).success
export const isQaRejectedEvent = (event: EventBase): event is QARejectedEvent =>
  QARejectedEventSchema.safeParse(event).success

export const isCustomerContactedEmailEvent = (
  event: EventBase
): event is CustomerContactedEmailEvent =>
  CustomerContactedEmailEventSchema.safeParse(event).success
export const isCustomerContactedLvmEvent = (event: EventBase): event is CustomerContactedLvmEvent =>
  CustomerContactedLvmEventSchema.safeParse(event).success
export const isJobSoldEvent = (event: EventBase): event is JobSoldEvent =>
  JobSoldEventSchema.safeParse(event).success
export const isJobStartedEvent = (event: EventBase): event is JobStartedEvent =>
  JobStartedEventSchema.safeParse(event).success

///////////////////
// TYPES EXPORTS //
///////////////////
export type ClaimStatusEventCanon = z.infer<typeof ClaimStatusEventSchema> & {
  status: ClaimStatusCanon
}
export type ClaimStatusEventLax = z.infer<typeof ClaimStatusEventLaxSchema> & {
  status: ClaimStatusLax
}

export type LossDateEvent = z.infer<typeof LossDateEventSchema>
export type InitialCustomerContactAttemptedEvent = z.infer<
  typeof InitialCustomerContactAttemptedEventSchema
>
export type InitialCustomerContactSuccessEvent = z.infer<
  typeof InitialCustomerContactSuccessEventSchema
>
export type InitialCustomerContactFailedEvent = z.infer<
  typeof InitialCustomerContactFailedEventSchema
>
export type InvalidContactInformationEvent = z.infer<typeof InvalidContactInformationEventSchema>
export type CustomerNoContactEvent = z.infer<typeof CustomerNoContactEventSchema>
export type FieldAdjusterAssignedEvent = z.infer<typeof FieldAdjusterAssignedEventSchema>
export type FieldAdjusterReassignedEvent = z.infer<typeof FieldAdjusterReassignedEventSchema>
export type FieldAdjusterRemovedEvent = z.infer<typeof FieldAdjusterRemovedEventSchema>
export type FieldAdjusterContactedCustomerEvent = z.infer<
  typeof FieldAdjusterContactedCustomerEventSchema
>
export type FieldAdjusterCorrectionUploadedEvent = z.infer<
  typeof FieldAdjusterCorrectionUploadedEventSchema
>
export type FieldAdjusterInspectionScheduledEvent = z.infer<
  typeof FieldAdjusterInspectionScheduledEventSchema
>
export type FieldAdjusterInspectionCompletedEvent = z.infer<
  typeof FieldAdjusterInspectionCompletedEventSchema
>
export type JobNotSoldEvent = z.infer<typeof JobNotSoldEventSchema>
export type FieldAdjusterEstimateUploadedEvent = z.infer<
  typeof FieldAdjusterEstimateUploadedEventSchema
>
export type ClientApprovedEvent = z.infer<typeof ClientApprovedEventSchema>
export type ClientRejectedEvent = z.infer<typeof ClientRejectedEventSchema>
export type DateReceivedEvent = z.infer<typeof DateReceivedEventSchema>
export type QaApprovedEvent = z.infer<typeof QaApprovedEventSchema>
export type QARejectedEvent = z.infer<typeof QARejectedEventSchema>
export type EstimateSentToCarrierEvent = z.infer<typeof EstimateSentToCarrierEventSchema>
export type ClaimInvoiceCreatedEvent = z.infer<typeof ClaimInvoiceCreatedEventSchema>
export type ReInspectionRequestedEvent = z.infer<typeof ReInspectionRequestedEventSchema>
export type EstimateRevisionRequestedEvent = z.infer<typeof EstimateRevisionRequestedEventSchema>
export type OTRInvokedEvent = z.infer<typeof OTRInvokedEventSchema>
export type WADSentToCustomerEvent = z.infer<typeof WADSentToCustomerEventSchema>
export type CarrierApprovedEstimateEvent = z.infer<typeof CarrierApprovedEstimateEventSchema>
export type CarrierApprovedEstimateWithExceptionsEvent = z.infer<
  typeof CarrierApprovedEstimateWithExceptionsEventSchema
>
export type CarrierRejectedEstimateEvent = z.infer<typeof CarrierRejectedEstimateEventSchema>
export type JobContractedEvent = z.infer<typeof JobContractedEventSchema>
export type JobCompletedEvent = z.infer<typeof JobCompletedEventSchema>
export type CanceledEvent = z.infer<typeof CanceledEventSchema>
export type CoordinatorAssignedEvent = z.infer<typeof CoordinatorAssignedEventSchema>
export type CoordinatorRemovedEvent = z.infer<typeof CoordinatorRemovedEventSchema>
export type InAssignQueueEvent = z.infer<typeof InAssignQueueEventSchema>
export type SurveySentToCustomerEvent = z.infer<typeof SurveySentToCustomerEventSchema>
export type ReviewerAssignedEvent = z.infer<typeof ReviewerAssignedEventSchema>

export type StatusIdUpdatedEvent = z.infer<typeof StatusIdUpdatedEventSchema>
export type StatusDeletedEvent = z.infer<typeof StatusDeletedEventSchema>

export type CustomerContactedEmailEvent = z.infer<typeof CustomerContactedEmailEventSchema>
export type CustomerContactedLvmEvent = z.infer<typeof CustomerContactedLvmEventSchema>
export type JobSoldEvent = z.infer<typeof JobSoldEventSchema>
export type JobStartedEvent = z.infer<typeof JobStartedEventSchema>
