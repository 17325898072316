import { ContactRoleConsumer } from '@eigtech/contacts-types'
import { startCase } from 'lodash-es'

const roleLabels: Partial<Record<ContactRoleConsumer, string>> = {
  csr: 'Coordinator (CSR)',
  policyHolder: 'Policyholder',
  qa: 'QA',
  thirdPartyClaimant: 'Third-Party Claimant',
}

export const getContactRoleLabel = (role: string | ContactRoleConsumer) =>
  roleLabels[role as ContactRoleConsumer] || startCase(role)
