import {
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  MaskedInput,
  MaskedInputProps,
} from '@eigtech/ui-shared-dave'
import { FieldValues, Path, useController } from 'react-hook-form'
import { FormControl, InputControlProps } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type MaskedInputFieldProps<TFieldValues extends FieldValues = FieldValues> =
  WithFieldNameProp<InputControlProps, TFieldValues> & Pick<MaskedInputProps, 'mask'>

export function MaskedInputField<TFieldValues extends FieldValues = FieldValues>(
  props: MaskedInputFieldProps<TFieldValues>
) {
  const {
    name,
    control,
    label,
    inputProps,
    leftAddon,
    rightAddon,
    leftElement,
    rightElement,
    mask,
    ...rest
  } = props

  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
    defaultValue: (inputProps?.defaultValue || '') as Path<TFieldValues>,
  })

  return (
    <FormControl control={control} label={label} name={name} {...rest}>
      <InputGroup>
        {typeof leftAddon === 'string' ? <InputLeftAddon>{leftAddon}</InputLeftAddon> : leftAddon}
        {typeof leftElement === 'string' ? (
          <InputLeftElement>{leftElement}</InputLeftElement>
        ) : (
          leftElement
        )}
        <MaskedInput
          {...field}
          id={name}
          {...inputProps}
          isDisabled={isSubmitting ?? rest.isDisabled ?? inputProps?.isDisabled}
          isReadOnly={rest.isReadOnly ?? inputProps?.isReadOnly}
          mask={mask}
          value={field.value ?? ''}
          onChange={(e) => {
            field.onChange(e)
          }}
        />
        {typeof rightElement === 'string' ? (
          <InputRightElement>{rightElement}</InputRightElement>
        ) : (
          rightElement
        )}
        {typeof rightAddon === 'string' ? (
          <InputRightAddon>{rightAddon}</InputRightAddon>
        ) : (
          rightAddon
        )}
      </InputGroup>
    </FormControl>
  )
}

export type MaskedInputFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof MaskedInputField<TFieldValues>
