import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseSyntheticEvent, useCallback, useMemo, useRef } from 'react'
import {
  FieldValues,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormProps,
  useForm as useBaseForm,
} from 'react-hook-form'
import { z } from 'zod'
import { Form as BaseForm, FormProps } from './components/Form'

export function useForm<
  T extends z.Schema<any, any>,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(schema: T, propsId: string, props?: UseFormProps<z.infer<T>, TContext>) {
  type TForm = z.infer<T>

  const id = useRef(propsId)

  const { current: resolver } = useRef(zodResolver(schema))

  const form = useBaseForm<TForm, TContext, TTransformedValues>({
    resolver: resolver,
    mode: 'onTouched',
    ...props,
  })

  const handleSubmit: UseFormHandleSubmit<TForm, TTransformedValues> = useCallback(
    (onValid, onInvalid) => {
      const newOnValid = (async (data: TForm & FieldValues, event?: BaseSyntheticEvent) => {
        try {
          await onValid(data, event)
        } catch (error) {
          log.error(`could not submit form: ${id.current}`)
        }
      }) as TTransformedValues extends FieldValues
        ? SubmitHandler<TTransformedValues>
        : SubmitHandler<z.infer<T>>

      return form.handleSubmit(newOnValid, onInvalid)
    },
    [form]
  )

  const SubmitButton = useCallback(
    (props: Omit<ButtonProps, 'form'>) => {
      const {
        formState: { isSubmitting },
      } = form

      return (
        <Button
          form={id.current}
          id={`${id.current}-submit`}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          {...props}
        >
          {props.children ?? 'Submit'}
        </Button>
      )
    },
    [form]
  )

  const SubmitIconButton = useMemo(
    () =>
      forwardRef<Omit<IconButtonProps, 'form'>, 'button'>(function SubmitIconButton(props, ref) {
        const {
          formState: { isSubmitting },
        } = form

        return (
          <IconButton
            ref={ref}
            form={id.current}
            id={`${id.current}-submit`}
            isLoading={isSubmitting}
            type="submit"
            {...props}
          />
        )
      }),
    [form]
  )

  const Form = useCallback(
    <
      TOnSubmit extends SubmitHandler<z.infer<T>> = TTransformedValues extends FieldValues
        ? SubmitHandler<TTransformedValues>
        : SubmitHandler<z.infer<T>>,
    >(
      props: Omit<FormProps<TForm, TContext, TTransformedValues>, 'id' | 'form' | 'onSubmit'> & {
        onSubmit: TOnSubmit
      }
    ) => (
      <BaseForm
        form={form}
        id={id.current}
        {...props}
        onSubmit={handleSubmit(
          props.onSubmit as unknown as TTransformedValues extends FieldValues
            ? SubmitHandler<TTransformedValues>
            : SubmitHandler<z.infer<T>>
        )}
      />
    ),
    [form, handleSubmit]
  )

  return {
    ...form,
    Form,
    SubmitButton,
    SubmitIconButton,
    handleSubmit,
  }
}

export type UseFormReturn<
  T extends z.Schema<any, any>,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = ReturnType<typeof useForm<T, TContext, TTransformedValues>>
