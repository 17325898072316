import { Button, ButtonProps } from '@eigtech/ui-shared-dave'
import { createLink } from '@tanstack/react-router'
import { forwardRef } from 'react'

export const BackButton = createLink(
  forwardRef<'a', ButtonProps>(function BackButton(props, ref) {
    return (
      <Button ref={ref} ml={['-2', '-4']} size="sm" variant="ghost" {...props}>
        {props.children ?? '← Back'}
      </Button>
    )
  })
)
