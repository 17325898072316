import {
  AssignRequest,
  ClaimAssigneeRelationshipCanonSchema,
  ClaimAssigneeRelationshipLax,
} from '@eigtech/assignments-types'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { Contact } from '@eigtech/contacts-types'
import { Job } from '@eigtech/restoration-types'
import { ensureJobCorn } from '@eigtech/restoration-util'
import { exhaustive } from 'exhaustive'

export type AssignEntityToClaimProps = {
  assigneeRelationship: ClaimAssigneeRelationshipLax
  claimNumber: string
} & ({ type: 'contact'; entity: Contact } | { type: 'job'; entity: Job })

export function makeClaimAssignment({
  assigneeRelationship,
  claimNumber,
  ...baseAssignee
}: AssignEntityToClaimProps): AssignRequest {
  const claimCorn = ensureClaimCorn(claimNumber)

  const assignee = exhaustive.tag(baseAssignee, 'type', {
    contact: ({ entity: contact }): AssignRequest['assignee'] => ({
      type: 'contact',
      assigneeId: contact.contactId,
      contact,
    }),
    job: ({ entity: job }): AssignRequest['assignee'] => ({
      type: 'job',
      assigneeId: ensureJobCorn(job.jobId),
      job,
    }),
  })

  return {
    assignable: {
      assignableId: claimCorn,
      assigneeRelationship: ClaimAssigneeRelationshipCanonSchema.parse(assigneeRelationship),
      claim: { claimNumber: claimCorn },
      type: 'claim',
    },
    assignableId: claimCorn,
    assignee,
  }
}
