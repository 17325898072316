import {
  Box,
  ButtonGroup,
  forwardRef,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  StackProps,
  Tooltip,
} from '@eigtech/ui-shared-dave'
import { ReactNode, useMemo } from 'react'
import { MdRefresh, MdOutlineSettingsInputComposite as SettingsIcon } from 'react-icons/md'
import { useDataGridContext } from '../DataGridContext'
import { ACTION_MENU_ID, DataGridColumnSettings } from './DataGridColumnSettings'
import { DataGridGlobalSearch } from './DataGridGlobalSearch'
import { DataGridFilterList, DataGridFiltersForm } from './Filters'

export type DataGridToolsProps = {
  beforeSettingsButtons?: ReactNode
  afterSettingsButtons?: ReactNode
} & StackProps

export const DataGridTools = forwardRef<DataGridToolsProps, 'div'>(
  ({ beforeSettingsButtons, afterSettingsButtons, ...props }, ref) => {
    const { enableReordering, refetch, table } = useDataGridContext()

    const columns = table.getAllLeafColumns()

    const {
      bulkActions,
      canReorderColumns,
      canToggleColumns,
      canFilterColumns,
      canFilterGlobal,
      hasSettings,
    } = useMemo(() => {
      const canReorderColumns = enableReordering

      const canToggleColumns = columns.some(
        (column) => column.getCanHide() && column.id !== ACTION_MENU_ID
      )

      const canFilterColumns = columns.some((column) => column.getCanFilter())
      const canFilterGlobal =
        table.options.meta?.manualGlobalFilter ||
        columns.some((column) => column.getCanGlobalFilter())

      const bulkActions = table.options.meta?.bulkActions

      const hasSettings =
        canReorderColumns || canToggleColumns || canFilterColumns || canFilterGlobal || bulkActions

      return {
        bulkActions,
        canReorderColumns,
        canToggleColumns,
        canFilterColumns,
        canFilterGlobal,
        hasSettings,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, enableReordering, table, table.options.meta])

    if (!hasSettings) return null

    return (
      <Stack ref={ref} {...props}>
        <HStack>
          {beforeSettingsButtons}

          <ButtonGroup isAttached variant="outline">
            {(canToggleColumns || canReorderColumns) && (
              <Popover isLazy>
                <PopoverTrigger>
                  <IconButton
                    aria-label="Open table settings."
                    icon={
                      <>
                        <Tooltip label="Open table settings">
                          <Box bottom="0" left="0" position="absolute" right="0" top="0"></Box>
                        </Tooltip>
                        <SettingsIcon />
                      </>
                    }
                    isRound={false}
                  />
                </PopoverTrigger>
                <PopoverContent w="auto">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontWeight="bold">Table Settings</PopoverHeader>
                  <PopoverBody>
                    {(canToggleColumns || canReorderColumns) && <DataGridColumnSettings />}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}

            {canFilterGlobal && <DataGridGlobalSearch />}

            {canFilterColumns && <DataGridFiltersForm />}
          </ButtonGroup>

          {refetch && (
            <Tooltip label="Refresh data">
              <IconButton
                aria-label="Refresh data"
                icon={<Icon as={MdRefresh} />}
                variant="outline"
                onClick={refetch}
              />
            </Tooltip>
          )}

          {afterSettingsButtons}
        </HStack>

        <DataGridFilterList />

        {bulkActions}
      </Stack>
    )
  }
)
