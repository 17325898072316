import { queryClient } from '@eigtech/ui-estimator/store/queryClient'
import { QueryClientProvider } from '@eigtech/ui-shared-api'
import { Auth0UserName, UserProvider } from '@eigtech/ui-shared-auth'
import { ReactNode } from 'react'

/**
 * Post Auth Providers are imported at the App.tsx level
 */
export function PostAuthProviders({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider PreferredUserComponent={Auth0UserName}>{children}</UserProvider>
    </QueryClientProvider>
  )
}
