import { EstimateIdSchema } from '@eigtech/claims-v2-types'
import { ClaimDetailEstimateDetail } from '@eigtech/ui-estimator/components/Domains/Claims/Views/ClaimDetailEstimateDetail'
import { createFileRoute } from '@eigtech/ui-shared-router'
import { z } from 'zod'

const ParamsSchema = z.object({
  estimateId: EstimateIdSchema,
  claimNumber: z.string(),
})

export const Route = createFileRoute('/claims/$claimNumber/_layout/estimates/$estimateId')({
  component: ClaimDetailEstimateDetail,
  parseParams: ParamsSchema.parse,
})
