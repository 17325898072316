import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ScheduledInspectionSchema } from '../../base/scheduledInspection.js'

export const ListUserScheduledInspectionsRequestPathSchema = z.object({
  email: z.string(),
})

export const ListUserScheduledInspectionsRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const ListUserScheduledInspectionsRequestSchema =
  ListUserScheduledInspectionsRequestPathSchema.merge(
    ListUserScheduledInspectionsRequestHeaderSchema
  )

export const ListUserScheduledInspectionsResponseSchema = z.object({
  inspectorEmail: z.string(),
  inspections: ScheduledInspectionSchema.array(),
})

export type ListUserScheduledInspectionsRequestPath = z.infer<
  typeof ListUserScheduledInspectionsRequestPathSchema
>
export type ListUserScheduledInspectionsRequest = z.infer<
  typeof ListUserScheduledInspectionsRequestSchema
>
export type ListUserScheduledInspectionsResponse = z.infer<
  typeof ListUserScheduledInspectionsResponseSchema
>

export interface ListUserScheduledInspectionsHandler
  extends Handler<ListUserScheduledInspectionsRequest, ListUserScheduledInspectionsResponse> {}

export const ListUserScheduledInspectionsApiSchema = {
  headers: ListUserScheduledInspectionsRequestHeaderSchema,
  params: ListUserScheduledInspectionsRequestPathSchema,
  response: ListUserScheduledInspectionsResponseSchema,
} satisfies ApiSchema
