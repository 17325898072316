import { useMemo } from 'react'
import { WithRequiredPermissionsProps } from '../types'
import { useAbility } from './context'

export type UseHasRequiredPermissionsProps<
  Action extends string,
  Subject extends string,
> = WithRequiredPermissionsProps<Action, Subject>['requiredPermissions']

export function useHasRequiredPermissions<Action extends string, Subject extends string>(
  baseRequiredPermissions: UseHasRequiredPermissionsProps<Action, Subject>
) {
  const ability = useAbility()

  return useMemo(() => {
    const requiredPermissions = Array.isArray(baseRequiredPermissions)
      ? baseRequiredPermissions
      : [baseRequiredPermissions]

    return requiredPermissions.every(({ action, subject }) => {
      return (ability.can as unknown as (a: any, s: any) => boolean)(action, subject)
    })
  }, [ability, baseRequiredPermissions])
}
