import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0UserSchema, RoleSchema } from '../../logStreamEvents/base/base.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const AddUserRequestPathSchema = z.object({
  userId: z.string(),
})
export const AddUserRequestSchema = AddUserRequestPathSchema

export const AddUserResponseSchema = z.object({
  user: Auth0UserSchema,
  roles: RoleSchema.array(),
})

export const AddUserApiSchema = {
  params: AddUserRequestPathSchema,
  response: AddUserResponseSchema,
} satisfies ApiSchema

export type AddUserRequest = z.infer<typeof AddUserRequestSchema>
export type AddUserResponse = z.infer<typeof AddUserResponseSchema>
export type AddUserHandler = Handler<AddUserRequest, AddUserResponse>
