import {
  GetUploadScreenshotUrlRequest,
  GetUploadScreenshotUrlResponse,
} from '@eigtech/meetings-types'
import {
  contextualGet,
  queryOptions,
  useUploadFile,
  useUploadMultipleFiles,
} from '@eigtech/ui-shared-api'
import { useToast } from '@eigtech/ui-shared-dave'
import { meetingsBasePath, meetingsMutationKeys, meetingsQueryKeys } from './constants'
import { useInvalidateGetMeetingUploads } from './getMeeting'

const getUploadScreenshotUrl = ({ cohort, entityId, meetingId }: GetUploadScreenshotUrlRequest) =>
  contextualGet<GetUploadScreenshotUrlResponse>(
    `${meetingsBasePath}/meeting/upload-screenshot-url/${entityId}/${meetingId}/${cohort}`
  )

const uploadScreenshotQueryOptions = (request: GetUploadScreenshotUrlRequest) =>
  queryOptions({
    queryKey: meetingsQueryKeys.uploadUrl(request),
    queryFn: () => getUploadScreenshotUrl(request),
  })

export function useUploadScreenshot(request: GetUploadScreenshotUrlRequest) {
  const toast = useToast()
  const invalidateUploads = useInvalidateGetMeetingUploads()
  return useUploadFile(uploadScreenshotQueryOptions(request), {
    defaultMeta: { label: '', description: '' },
    mutationKey: meetingsMutationKeys.uploadScreenshot(request),
    onSuccess() {
      toast({
        status: 'success',
        title: 'Successfully uploaded screenshot!',
        duration: 5000,
      })
      setTimeout(
        () => invalidateUploads({ entityId: request.entityId, meetingId: request.meetingId }),
        1000
      )
    },
    onError() {
      toast({
        status: 'error',
        title: 'Could not upload screenshot.',
      })
    },
  })
}

export function useUploadMultipleScreenshots(request: GetUploadScreenshotUrlRequest) {
  return useUploadMultipleFiles(uploadScreenshotQueryOptions(request), {
    defaultMeta: { label: '', description: '' },
  })
}
