/**
 * Resolves a promise after a specified number of milliseconds
 *
 * @param ms amount of time in milliseconds before delay resolves
 */
export const delay = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Rejects a promise after a specified number of milliseconds
 *
 * @param ms amount of time in milliseconds before delay resolves
 */
export const timeout = async (ms: number) => new Promise((_, reject) => setTimeout(reject, ms))

//Regex is ported from is-iso-date lib on npm
const isoDateRegExp = new RegExp(
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
)

//Supports ISO date and date only - (yyyy-mm-dd)
const partialIsoDateRegExp = new RegExp(
  /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\d)(?:[^a-zA-Z0-9 ]|$)/
)

/**
 * Validates that a string is an ISO formatted date
 *
 * @param str value to check if this is an ISO date
 */
export const isISODate = (str: string) => {
  return isoDateRegExp.test(str)
}

/**
 * Validates that a string is a partial/full ISO formatted date. Partial is date only (yyyy-mm-dd).
 *
 * @param str value to check if this is an ISO date
 */
export const isISOish = (str: string) => {
  return partialIsoDateRegExp.test(str)
}

/**
 * Returns async function resolving promise after number of
 * milliseconds that increases by `increment` each call
 *
 * @param increment Amount to increase delay in ms each call
 * @returns Async function resolving after current ms count
 */
export const makeIncrementingDelay = (increment = 10, startMs = 0) => {
  let ms = startMs

  return async () => {
    await delay(ms)
    ms += increment
  }
}
