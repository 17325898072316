import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { MessageGroupIdSchema, MessageGroupSchema } from '../../base/index.js'

export const UpsertMessageGroupRequestPathSchema = z.object({
  groupId: MessageGroupIdSchema.optional(),
})

export const UpsertMessageGroupRequestBodySchema = MessageGroupSchema.omit({
  groupId: true,
})

export const UpsertMessageGroupRequestSchema = UpsertMessageGroupRequestBodySchema.merge(
  UpsertMessageGroupRequestPathSchema
)

export type UpsertMessageGroupRequestBody = z.infer<typeof UpsertMessageGroupRequestBodySchema>
export type UpsertMessageGroupRequest = z.infer<typeof UpsertMessageGroupRequestSchema>

export const UpsertMessageGroupResponseSchema = z.void()
export type UpsertMessageGroupResponse = z.infer<typeof UpsertMessageGroupResponseSchema>

export interface UpsertMessageGroupHandler
  extends Handler<UpsertMessageGroupRequest, UpsertMessageGroupResponse> {}
