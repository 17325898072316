import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

export type NewSubcontractorDocumentRecord = NewTableRow<typeof subcontractorDocumentTable>
export type SubcontractorDocumentRecord = TableRow<typeof subcontractorDocumentTable>

const subcontractorDocumentTable = defineTable('subcontractor_document', {
  id: Schema.Number,
  job_id: Schema.String,
  subcontractor_id: Schema.String,
  external_id: Schema.nullable(Schema.String),
  kind: Schema.String,
  received_date: Schema.String,
  received_method: Schema.String,
  issued_date: Schema.nullable(Schema.String),
  issued_method: Schema.nullable(Schema.String),
})
