import { defineTable, Schema, NewTableRow, TableRow } from 'squid'

export type NewCoordinatorRecord = NewTableRow<typeof coordinatorTable>
export type CoordinatorRecord = TableRow<typeof coordinatorTable>

const coordinatorTable = defineTable('coordinator', {
  id: Schema.String,
  name: Schema.String,
  email: Schema.nullable(Schema.String),
  phone: Schema.nullable(Schema.String),
  raw_address: Schema.nullable(Schema.String),
  street_1: Schema.nullable(Schema.String),
  street_2: Schema.nullable(Schema.String),
  city: Schema.nullable(Schema.String),
  state: Schema.nullable(Schema.String),
  zipcode: Schema.nullable(Schema.String),
})
