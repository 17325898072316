import { DocumentV2 } from '@eigtech/documents-types'
import { getFilenameWithoutExtension } from '@eigtech/function-utils'
import { AugmentedDocument } from '../types/AugmentedDocument'
import { getDocumentDescription, getDocumentTitle } from '../utils/getDocumentDetails'
import { getDocumentTypeLabel } from '../utils/getDocumentTypeLabel'

export function augmentDocument(document: DocumentV2): AugmentedDocument {
  return {
    ...document,
    title: getDocumentTitle(document),
    description: getDocumentDescription(document),
    type: getDocumentTypeLabel(document.type ?? ''),
    filenameWithoutExtension:
      document.source === 'Verisk'
        ? getFilenameWithoutExtension(document.filename)
        : document.notes.replace(/[ ]/g, '_'),
  }
}
