import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { CallbackRequestSchema } from '../../../index.js'

export const GetCallbackRequestsByEntityRequestPathSchema = z.object({
  entityId: CornSchema,
})
export type GetCallbackRequestsByEntityRequestPath = z.infer<
  typeof GetCallbackRequestsByEntityRequestPathSchema
>

export const GetCallbackRequestsByEntityRequestSchema = GetCallbackRequestsByEntityRequestPathSchema

export type GetCallbackRequestsByEntityRequest = z.infer<
  typeof GetCallbackRequestsByEntityRequestSchema
>

export const GetCallbackRequestsByEntityResponseSchema = CallbackRequestSchema.array()

export type GetCallbackRequestsByEntityResponse = z.infer<
  typeof GetCallbackRequestsByEntityResponseSchema
>

export interface GetCallbackRequestsByEntityHandler
  extends Handler<GetCallbackRequestsByEntityRequest, GetCallbackRequestsByEntityResponse> {}
