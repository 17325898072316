import {
  AssigneeTypeEnumSchema,
  AssignmentLaxSchema,
  ClaimAssigneeRelationshipLaxSchema,
} from '@eigtech/assignments-types'
import {
  BaseEstimateSchema,
  CanceledInspectionSchema,
  ClaimDataSourceSchema,
  ClosedOutcomeSchema,
  CompletedInspectionSchema,
  CompletedReviewSchema,
  CustomerCommunicationSchema,
  DateOfLossSchema,
  EstimateReviewSchema,
  LossOfUseReceiptRemovedSchema,
  LossOfUseReceiptSchema,
  LossOfUseSchema,
  PendingReviewSchema,
  ReopenedOutcomeSchema,
  RequestedInspectionSchema,
  ScheduledInspectionSchema,
} from '@eigtech/claims-v2-types'
import { VediImportStateSchema } from '@eigtech/verisk-edi-interface-types'
import { z } from 'zod'

export const ClaimAssignmentDetailsSchema = AssignmentLaxSchema.omit({ requestActor: true }).merge(
  z.object({
    assigneeRelationship: ClaimAssigneeRelationshipLaxSchema.optional(),
    recordedDate: z.string().datetime(),
  })
)

export const ClaimUnassignmentDetailsSchema = z.object({
  assigneeRelationship: ClaimAssigneeRelationshipLaxSchema.optional(),
  assigneeType: AssigneeTypeEnumSchema.optional(),
  assignableId: z.string(),
  assigneeId: z.string(),
  recordedDate: z.string().datetime(),
})

export const ClaimBasicDetailsSchema = z
  .object({
    recordedDate: z.string().datetime(),
    dataSource: ClaimDataSourceSchema.optional(),
    xaStatus: VediImportStateSchema.optional(),
  })
  .passthrough()

export const ClaimClosedDetailsSchema = z.object({
  outcome: ClosedOutcomeSchema,
})

export const ClaimReopenedDetailsSchema = z.object({
  outcome: ReopenedOutcomeSchema,
})

export const ClaimCreatedDetailsSchema = z.object({
  lossDate: DateOfLossSchema,
})

export const ClaimDateReceivedDetailsSchema = z.object({
  dateReceived: z.string().datetime(),
})

export const ClaimLossDateDetailsSchema = z.object({
  lossDate: DateOfLossSchema,
})

export const ClaimEstimateReceivedDetailsSchema = BaseEstimateSchema.merge(
  z.object({
    recordedDate: z.string().datetime(),
  })
)

export const ClaimEstimateReviewedDetailsSchema = z.object({
  review: EstimateReviewSchema,
  estimate: BaseEstimateSchema,
  recordedDate: z.string().datetime(),
})

export const ClaimInspectionCompletedDetailsSchema = z.object({
  inspection: CompletedInspectionSchema.partial({ inspectionId: true }),
})

export const ClaimInspectionCanceledDetailsSchema = z.object({
  inspection: CanceledInspectionSchema,
})

export const ClaimInspectionScheduledDetailsSchema = z.object({
  inspection: ScheduledInspectionSchema.partial({ inspectionId: true }),
})

export const ClaimReInspectionRequestedDetailsSchema = z.object({
  inspection: RequestedInspectionSchema,
})

export const ClaimLossOfUseDetailsSchema = z.object({
  lossOfUse: LossOfUseSchema,
})

export const ClaimLossOfUseReceiptAddedDetailsSchema = z.object({
  lossOfUse: LossOfUseSchema.omit({ receipts: true }).merge(
    z.object({
      receipt: LossOfUseReceiptSchema,
    })
  ),
})

export const ClaimLossOfUseReceiptRemovedDetailsSchema = z.object({
  lossOfUse: LossOfUseSchema.omit({ receipts: true }).merge(
    z.object({
      receipt: LossOfUseReceiptRemovedSchema,
    })
  ),
})

export const ClaimReviewRequestedDetailsSchema = z.object({
  review: PendingReviewSchema,
})

export const ClaimReviewCompletedDetailsSchema = z.object({
  review: CompletedReviewSchema,
})

export const ClaimCustomerCommunicationDetailsSchema = z.object({
  customerCommunication: CustomerCommunicationSchema,
  xaStatus: VediImportStateSchema.optional(),
  dataSource: ClaimDataSourceSchema.optional(),
})

export type ClaimAssignmentDetails = z.infer<typeof ClaimAssignmentDetailsSchema>
export type ClaimBasicDetails = z.infer<typeof ClaimBasicDetailsSchema>
export type ClaimClosedDetails = z.infer<typeof ClaimClosedDetailsSchema>
export type ClaimReopenedDetails = z.infer<typeof ClaimReopenedDetailsSchema>
export type ClaimCreatedDetails = z.infer<typeof ClaimCreatedDetailsSchema>
export type ClaimEstimateReceivedDetails = z.infer<typeof ClaimEstimateReceivedDetailsSchema>
export type ClaimEstimateReviewedDetails = z.infer<typeof ClaimEstimateReviewedDetailsSchema>
export type ClaimInspectionCompletedDetails = z.infer<typeof ClaimInspectionCompletedDetailsSchema>
export type ClaimInspectionCanceledDetails = z.infer<typeof ClaimInspectionCanceledDetailsSchema>
export type ClaimInspectionScheduledDetails = z.infer<typeof ClaimInspectionScheduledDetailsSchema>
export type ClaimReInspectionRequestedDetails = z.infer<
  typeof ClaimReInspectionRequestedDetailsSchema
>

export type ClaimDateReceivedDetails = z.infer<typeof ClaimDateReceivedDetailsSchema>
export type ClaimLossDateDetails = z.infer<typeof ClaimLossDateDetailsSchema>
export type ClaimLossOfUseDetails = z.infer<typeof ClaimLossOfUseDetailsSchema>
export type ClaimLossOfUseReceiptAddedDetails = z.infer<
  typeof ClaimLossOfUseReceiptAddedDetailsSchema
>
export type ClaimLossOfUseReceiptRemovedDetails = z.infer<
  typeof ClaimLossOfUseReceiptRemovedDetailsSchema
>
export type ClaimUnassignmentDetails = z.infer<typeof ClaimUnassignmentDetailsSchema>
export type ClaimReviewRequestedDetails = z.infer<typeof ClaimReviewRequestedDetailsSchema>
export type ClaimReviewCompletedDetails = z.infer<typeof ClaimReviewCompletedDetailsSchema>

export type ClaimCustomerCommunicationDetails = z.infer<
  typeof ClaimCustomerCommunicationDetailsSchema
>
