import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  BaseClaimSchema,
  BaseLossDetailsSchema,
  ClaimPrecursorSchema,
  LocationSchema,
} from '../model'

export const CreateClaimBodyRequestSchema = ClaimPrecursorSchema.omit({ dataSource: true }).merge(
  z.object({
    carrier: z.string(),
    lossDetails: BaseLossDetailsSchema.required({ dateOfLoss: true }),
    lossLocation: LocationSchema,
  })
)

export const CreateClaimHeaderRequestSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
}).required({ requestActor: true })

export const CreateClaimInternalRequestSchema = CreateClaimBodyRequestSchema

export const CreateClaimRequestSchema = CreateClaimBodyRequestSchema.merge(
  CreateClaimHeaderRequestSchema
)
export const CreateClaimRequestInputSchema = BaseClaimSchema
export const CreateClaimResponseSchema = BaseClaimSchema

export type CreateClaimBodyRequest = z.infer<typeof CreateClaimBodyRequestSchema>
export type CreateClaimHeaderRequest = z.infer<typeof CreateClaimHeaderRequestSchema>
export type CreateClaimInternalRequest = z.infer<typeof CreateClaimInternalRequestSchema>
export type CreateClaimRequest = z.infer<typeof CreateClaimRequestSchema>

export type CreateClaimRequestInput = z.infer<typeof CreateClaimRequestInputSchema>
export type CreateClaimResponse = z.infer<typeof CreateClaimResponseSchema>

export interface CreateClaimHandler extends Handler<CreateClaimRequest, CreateClaimResponse> {}

export const CreateClaimApiSchema: ApiSchema = {
  body: CreateClaimBodyRequestSchema,
  headers: CreateClaimHeaderRequestSchema,
}
