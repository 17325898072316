import {
  BookCorn,
  ContactCorn,
  GetBookResponse,
  GetGlobalBookResponse,
  GlobalBookCornCanon,
  ListBooksByContactResponse,
  ListGlobalBooksResponse,
  isContactCorn,
} from '@eigtech/contacts-types'
import {
  ensureBookCorn,
  ensureContactCorn,
  isBookCorn,
  isGlobalBookId,
  makeGlobalBookCorn,
} from '@eigtech/contacts-util'
import {
  contextualGet,
  queryOptions,
  useQueries,
  useQuery,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import {
  bookBasePath,
  booksBasePath,
  booksQueryKeys,
  contactsBasePath,
  contactsQueryKeys,
  globalBookBasePath,
} from './constants'

const getBook = (bookId: BookCorn) => contextualGet<GetBookResponse>(`${bookBasePath}/${bookId}`)

const getGlobalBook = (bookId: GlobalBookCornCanon) =>
  contextualGet<GetGlobalBookResponse>(`${globalBookBasePath}/${bookId}`)

export function getBookQueryOptions(bookId: string) {
  const bookCorn = ensureBookCorn(bookId)

  return queryOptions({
    queryKey: booksQueryKeys.detail(bookCorn),
    queryFn: () => (isGlobalBookId(bookCorn) ? getGlobalBook(bookCorn) : getBook(bookCorn)),
    enabled: isBookCorn(bookCorn),
  })
}

export const useGetBook = (bookId: string) => useQuery(getBookQueryOptions(bookId))

export const useGetBooks = (bookIds: string[]) =>
  useQueries({
    queries: bookIds.map((bookId) => getBookQueryOptions(bookId)),
  })

export function useInvalidateBook() {
  const queryClient = useQueryClient()

  return function invalidateBook(bookId: string) {
    queryClient.invalidateQueries({ queryKey: getBookQueryOptions(bookId).queryKey })
  }
}

export function useInvalidateBooksDetails() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: booksQueryKeys.details() })
  }
}

//

const getContactBooks = async (contactId: ContactCorn) =>
  (
    await contextualGet<ListBooksByContactResponse>(
      `${contactsBasePath}/contact-books/${contactId}`
    )
  ).bookCorns

export function getBooksByContactIdQueryOptions(contactId: string) {
  const contactCorn = ensureContactCorn(contactId)

  return queryOptions({
    queryKey: contactsQueryKeys.books(contactCorn),
    queryFn: () => getContactBooks(contactCorn),
    enabled: isContactCorn(contactCorn),
  })
}

export const useGetBooksByContactId = (contactId: string) =>
  useQuery(getBooksByContactIdQueryOptions(contactId))

export function useInvalidateContactBooks() {
  const queryClient = useQueryClient()

  return function invalidateContactBooks(contactId: string) {
    queryClient.invalidateQueries({ queryKey: getBooksByContactIdQueryOptions(contactId).queryKey })
  }
}

//

const getGlobalBooks = async () =>
  (await contextualGet<ListGlobalBooksResponse>(booksBasePath)).books

export const getGlobalBooksQueryOptions = () =>
  queryOptions({ queryKey: booksQueryKeys.list(), queryFn: () => getGlobalBooks() })

export const useGetGlobalBooks = () => useQuery(getGlobalBooksQueryOptions())

export function useInvalidateGlobalBooks() {
  const queryClient = useQueryClient()

  return function invalidateGlobalBooks() {
    queryClient.invalidateQueries({ queryKey: getGlobalBooksQueryOptions().queryKey })
  }
}

//

export const useGetCsrBook = () => useGetBook(makeGlobalBookCorn('csr'))

export const useGetCarrierBook = () => useGetBook(makeGlobalBookCorn('carrier'))

export const useGetFieldAdjusterBook = () => useGetBook(makeGlobalBookCorn('fieldAdjuster'))

export const useGetFieldTechnicianBook = () => useGetBook(makeGlobalBookCorn('fieldTechnician'))

export const useGetProjectManagerBook = () => useGetBook(makeGlobalBookCorn('projectManager'))

export const useGetReviewerBook = () => useGetBook(makeGlobalBookCorn('reviewer'))

//

export function useGetAllGlobalBooks() {
  const { data: books } = useGetGlobalBooks()

  return useQueries({
    queries: (books ?? []).map((book) => getBookQueryOptions(book.bookId)),
  })
}
