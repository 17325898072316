import { Contact, ContactCommunicationInformation } from '@eigtech/contacts-types'
import { getPreferredCommunicationInformation } from '@eigtech/contacts-util'
import { formatPhoneNumber } from '@eigtech/ui-shared-phone'

export function getPreferredContactMethod(contact: Contact): string {
  const defaultValue = '(Unknown)'
  const info = getPreferredCommunicationInformation(contact)

  return !!info
    ? getContactPhoneNumberFormatted(info) || info?.emailAddress || defaultValue
    : defaultValue
}

const getContactPhoneNumberFormatted = (info: ContactCommunicationInformation) => {
  return info.unformattedTelephoneNumber === undefined
    ? undefined
    : formatPhoneNumber(
        `${info.unformattedTelephoneNumber}${info.extension ?? ''}` as string,
        info.extensionPrefix
      )
}
