import { z } from 'zod'
import {
  ClaimClosedEvent,
  ClaimCreatedEvent,
  ClaimInspectionCanceledEvent,
  ClaimInspectionCompletedEvent,
  ClaimInspectionScheduledEvent,
  ClaimReInspectionRequestedEvent,
  ClaimReopenedEvent,
} from '../events'
import { ClaimEventType } from '../events/claim'
import { EstimateEventType, EstimateReceivedEvent, EstimateReviewedEvent } from '../events/estimate'

// These types are specific to claim statuses in the read model

export const ClaimStatusRelatedEventTypeSchema = z.enum([
  ClaimEventType.claimCreated,
  ClaimEventType.inspectionCanceled,
  ClaimEventType.inspectionCompleted,
  ClaimEventType.inspectionScheduled,
  ClaimEventType.closed,
  ClaimEventType.reInspectionRequested,
  ClaimEventType.reopened,
  EstimateEventType.received,
  EstimateEventType.reviewed,
])
export type ClaimStatusRelatedEventTypeEnum = z.infer<typeof ClaimStatusRelatedEventTypeSchema>
export const ClaimStatusRelatedEventType = ClaimStatusRelatedEventTypeSchema.Enum
export const isClaimStatusRelatedEvent = (
  eventType: string
): eventType is ClaimStatusRelatedEventTypeEnum =>
  ClaimStatusRelatedEventTypeSchema.safeParse(eventType).success

export type ClaimStatusRelatedEvent =
  | ClaimCreatedEvent
  | ClaimInspectionCanceledEvent
  | ClaimInspectionCompletedEvent
  | ClaimInspectionScheduledEvent
  | ClaimClosedEvent
  | ClaimReInspectionRequestedEvent
  | ClaimReopenedEvent
  | EstimateReceivedEvent
  | EstimateReviewedEvent

export enum ClaimStatus {
  carrier = 'Carrier Review',
  coordinator = 'Coordinator Review',
  closed = 'Closed',
  inspection = 'Inspection',
  qa = 'QA Review',
  rejected = 'Rejected',
}
export const ClaimStatusSchema = z.enum([
  ClaimStatus.carrier,
  ClaimStatus.closed,
  ClaimStatus.coordinator,
  ClaimStatus.inspection,
  ClaimStatus.qa,
  ClaimStatus.rejected,
])
export type ClaimStatusEnum = z.infer<typeof ClaimStatusSchema>
