import { Skeleton, Stack } from '@chakra-ui/react'

export function PageLoader() {
  return (
    <Stack>
      <Skeleton h="12" />
      <Skeleton h="12" />
      <Skeleton h="12" />
    </Stack>
  )
}
