import {
  TelecommunicationEquipmentTypeSchema,
  TelecommunicationUseCodeSchema,
} from '@eigtech/contacts-types'
import { z } from 'zod'

export const ContactFormPhoneFieldSchema = z.object({
  id: z.string().optional(),
  phone: z
    .string()
    .length(12, { message: 'Phone must be 12 characters' })
    .optional()
    .transform((value) => value?.replaceAll('-', ''))
    .refine((value) => /^\d+$/.test(value ?? ''), {
      message: 'Phone must contain only numbers',
    })
    .or(z.literal('')),
  extensionPrefix: z
    .string()
    .max(10, { message: 'Extension prefix must be less than or equal to 10 characters' })
    .optional()
    .or(z.literal('')),
  extension: z
    .string()
    .max(10, { message: 'Extension must be less than or equal to 10 characters' })
    .refine((value) => /^\d+$/.test(value ?? ''), {
      message: 'Extension must contain only numbers',
    })
    .optional()
    .or(z.literal('')),
  preferenceOrder: z.number().optional(),
  // pass through via API:countryCode, telecommunicationUseCode, telecommunicationEquipmentType
  countryCode: z.number().optional(),
  telecommunicationEquipmentType: TelecommunicationEquipmentTypeSchema.optional(),
  telecommunicationUseCode: TelecommunicationUseCodeSchema.optional(),
})

export type ContactFormPhoneField = z.infer<typeof ContactFormPhoneFieldSchema>
