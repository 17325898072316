import { HStack, Tag, Text } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { ClaimAssignmentDetails } from '@eigtech/summarizer-types'
import { ContactName } from '@eigtech/ui-shared-contacts'
import { JobId } from '@eigtech/restoration-types'
import { JobLink } from '@eigtech/ui-shared-jobs'

export function AssignedDetails(props: EventDetailsComponentProps<ClaimAssignmentDetails>) {
  const {
    details: {
      assigneeRelationship,
      assignee: { assigneeId, type },
    },
  } = props

  return (
    <HStack>
      <Tag colorScheme="brandPrimary">Claim Assigned</Tag>
      {type === 'contact' ? (
        <Text as="span">
          to {assigneeRelationship ?? ''} {<ContactName contactId={assigneeId} />}
        </Text>
      ) : type === 'job' ? (
        <Text as="span">to job {<JobLink jobId={assigneeId as JobId} />}</Text>
      ) : null}
    </HStack>
  )
}
