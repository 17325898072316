import { minutesToMilliseconds } from '@eigtech/ui-shared-dates'
import { QueryClient, QueryClientConfig } from '@tanstack/react-query'
import { HTTPError } from 'ky'

export type EigQueryClientConfig = {
  onInvalidateQueries?: QueryClient['invalidateQueries']
}

export class EigQueryClient extends QueryClient {
  baseInvalidateQueries: QueryClient['invalidateQueries']
  private onInvalidateQueries: QueryClient['invalidateQueries']

  constructor(config: QueryClientConfig = {}, { onInvalidateQueries }: EigQueryClientConfig = {}) {
    super(config)

    this.onInvalidateQueries = !!onInvalidateQueries ? onInvalidateQueries : async () => {}
    this.baseInvalidateQueries = super.invalidateQueries

    const self = this

    const invalidateQueries: QueryClient['invalidateQueries'] = async function () {
      self.onInvalidateQueries.call(self, ...arguments)
      self.baseInvalidateQueries.call(self, ...arguments)
    }

    this.invalidateQueries = invalidateQueries
  }
}

export const defaultQueryClientConfig = (): QueryClientConfig => ({
  defaultOptions: {
    queries: {
      staleTime: minutesToMilliseconds(10),
      retry(failureCount, error: any) {
        // don't retry if error is 404 (not found)
        if (error instanceof HTTPError) {
          const responseStatus = (error as HTTPError).response.status
          if ([403, 404].includes(responseStatus)) return false
        }

        return failureCount < 3
      },
    },
  },
})
