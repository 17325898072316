import {
  BoxProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableProps,
  TableRowProps,
} from '@eigtech/ui-shared-dave'
import { Cell, Column, FilterFn, FilterFnOption, RowData, Table } from '@tanstack/react-table'
import { omit } from 'lodash-es'
import {
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  memo,
  ReactNode,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  DataGridActionsProps,
  DataGridContextState,
  DataGridEventHandlers,
  DataGridOptionsProps,
  DataGridProvider,
  DataGridStatusProps,
} from './DataGridContext'
import { DataGridErrorBoundary } from './DataGridErrorBoundary'
import { DataGridTable } from './DataGridTable'
import { useDataGrid, UseDataGridProps } from './useDataGrid'

export type FilterComponent<TData extends RowData, TValue> = FunctionComponent<{
  column: Column<TData, TValue>
  instance: DataGridContextState<TData>
}>

type FilterLabel<TData extends RowData, TValue> = (
  value: TValue,
  column: Column<TData, TValue>
) => string

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    globalFilterLabel?: (value: any) => string
    manualGlobalFilter?: boolean
    FilterComponents?: Record<string, FilterComponent<TData, any>>
    filterLabels?: Record<string, FilterLabel<TData, any>>
    rowProps?: TableRowProps
    bulkActions?: ReactNode
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    arrayFilterOptions?: (string | { label: string; value: string })[]
    availableFilterFns?: (FilterFnOption<TData> | { label: string; value: FilterFnOption<TData> })[]
    cellProps?: TableCellProps | ((cell: Cell<TData, TValue>, index: number) => TableCellProps)
    columnHeaderProps?:
      | TableColumnHeaderProps
      | ((cell: Cell<TData, TValue>) => TableColumnHeaderProps)
    enableReordering?: boolean
    FilterComponent?: FilterComponent<TData, TValue>
    filterLabel?: FilterLabel<TData, TValue>
    headerLabel?: string
    hidePinButton?: boolean
    onFilterFnChange?: (value: FilterFnOption<TData>) => any
  }

  interface FilterFns {
    dateEquals: FilterFn<unknown>
    dateGreaterThan: FilterFn<unknown>
    dateGreaterEqualThan: FilterFn<unknown>
    dateLesserThan: FilterFn<unknown>
    dateLesserEqualThan: FilterFn<unknown>
    dateTimeEquals: FilterFn<unknown>
    dateTimeGreaterThan: FilterFn<unknown>
    dateTimeGreaterEqualThan: FilterFn<unknown>
    dateTimeLesserThan: FilterFn<unknown>
    dateTimeLesserEqualThan: FilterFn<unknown>
  }
}

export type OtherDataGridProps<TData extends RowData> = DataGridStatusProps &
  DataGridOptionsProps &
  DataGridActionsProps &
  DataGridEventHandlers<TData> & {
    id?: string
    tableProps?: TableProps
    containerProps?: BoxProps
  }

export type DataGridProps<TData extends RowData> = UseDataGridProps<TData> &
  OtherDataGridProps<TData>

function DataGridComponent<TData extends RowData>(
  // Do not spread/rest props, it can cause instability and infinite rerenders
  props: DataGridProps<TData>,
  ref: ForwardedRef<Table<TData>>
) {
  // attempt to protect from foot gun
  useEffect(() => {
    if (props.enableReordering && !props.columns.every((column) => !!column.id)) {
      throw new Error('Column reordering will not work unless every column has an id')
    }
  }, [props.enableReordering, props.columns])

  const dataGridProps = useMemo(() => omit(props, otherDataGridPropsKeys), [props])
  // the `useDataGrid` hook should only be passed the props it needs
  const table = useDataGrid(dataGridProps)

  const tableRef = useRef(table)

  useImperativeHandle(ref, () => ({
    ...tableRef.current,
  }))

  const fallbackId = useId()

  const context: DataGridContextState<TData> = useMemo(() => {
    const id = props.id ?? fallbackId

    const additionalContextProps: Pick<DataGridContextState<TData>, AdditionalContextProps> = {
      enableReordering: props.enableReordering,
      isError: props.isError,
      isFetching: props.isFetching,
      isPending: props.isPending,
      onRowClick: props.onRowClick,
      refetch: props.refetch,
    }

    return {
      tableId: `${id}_DataGrid`,
      ...additionalContextProps,
      table,
    }
  }, [
    fallbackId,
    props.enableReordering,
    props.id,
    props.isError,
    props.isFetching,
    props.isPending,
    props.onRowClick,
    props.refetch,
    table,
  ])

  return (
    <DataGridErrorBoundary>
      <DataGridProvider value={context}>
        <DndProvider backend={HTML5Backend}>
          <DataGridTable {...(props.tableProps ?? {})} containerProps={props.containerProps} />
        </DndProvider>
      </DataGridProvider>
    </DataGridErrorBoundary>
  )
}

export const DataGrid = memo(forwardRef(DataGridComponent)) as <TData extends RowData>(
  props: DataGridProps<TData> & { ref?: ForwardedRef<Table<TData>> }
) => ReturnType<typeof DataGridComponent>

type AdditionalContextProps = keyof Required<
  DataGridStatusProps & DataGridOptionsProps & DataGridEventHandlers<unknown> & DataGridActionsProps
>

const otherDataGridPropsRecord: Record<keyof OtherDataGridProps<any>, ''> = {
  enableReordering: '',
  id: '',
  isError: '',
  isFetching: '',
  isPending: '',
  onRowClick: '',
  tableProps: '',
  containerProps: '',
  refetch: '',
}

const otherDataGridPropsKeys = Object.keys(
  otherDataGridPropsRecord
) as (keyof OtherDataGridProps<any>)[]
