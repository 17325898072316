import z from 'zod'

// these roles currently live in Auth0 and are provided here to help key conditional logic from
// provided Auth0 tokens
export const Auth0RoleNameSchema = z.enum([
  'CSR - Project Manager',
  'CSR - Reviewer',
  'CSR - Super Admin',
  'CSR - User',
  'Estimator - User',
  'TPLAP - Field Technician',
  'TPLAP - Subcontractor',
  'TPLAP - User',
  'Ultra Super Admin',
])
export type Auth0RoleNameEnum = z.infer<typeof Auth0RoleNameSchema>
export const Auth0RoleName = Auth0RoleNameSchema.Values

export const Auth0TokenRoleKey = 'http://eigtech.io/claims/roles'

export const Auth0TokenSchema = z.object({
  [Auth0TokenRoleKey]: Auth0RoleNameSchema.array(),
  email: z.string(),
  iss: z.string(),
  sub: z.string(),
  aud: z.string().array(),
  iat: z.number(),
  exp: z.number(),
  scope: z.string(),
  org_id: z.string(),
  azp: z.string(),
  permissions: z.string().array(),
})
export type Auth0Token = z.infer<typeof Auth0TokenSchema>
