import { z } from 'zod'

export const DeprecatedUserTypesEnumSchema = z.enum(['fileReviewer'])
export const ProducerUserTypesEnumSchema = z.enum([
  'coordinator',
  'estimator',
  'projectManager',
  'fieldTechnician',
  'reviewer',
  'superAdmin',
  'csrAdmin',
  'insured',
  'subcontractor',
])

export const UserTypesEnumLaxSchema = z.enum([
  ...ProducerUserTypesEnumSchema.options,
  ...DeprecatedUserTypesEnumSchema.options,
])

export const UserTypesEnumCanonSchema = z
  .enum([...UserTypesEnumLaxSchema.options])
  .transform((userType) => {
    switch (userType) {
      case 'fileReviewer':
        return ProducerUserTypesEnumSchema.Enum.reviewer
      default:
        return userType
    }
  })

export const UserTypesCanon = ProducerUserTypesEnumSchema.enum
export type UserTypesEnumCanon = z.infer<typeof UserTypesEnumCanonSchema>
export type UserTypesEnumLax = z.infer<typeof UserTypesEnumLaxSchema>
