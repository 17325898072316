import { InboxMessage, ListMessagesResponse } from '@eigtech/messaging-types'
import { QueryKey, useQueryClient } from '@eigtech/ui-shared-api'
import { inboxQueryKeys } from './constants'

type Ids = { messageId: string; inboxId: string }

export function useOptimisticallyUpdateMessage() {
  const queryClient = useQueryClient()

  return {
    async onMutate({ messageId, inboxId }: Ids, updatedMessage: Partial<InboxMessage>) {
      const baseQueryKey = inboxQueryKeys.messages(inboxId)

      await queryClient.cancelQueries({ queryKey: baseQueryKey })

      const previousMessagesQueries = queryClient.getQueriesData<ListMessagesResponse>({
        queryKey: baseQueryKey,
      })

      queryClient.setQueriesData<ListMessagesResponse>({ queryKey: baseQueryKey }, (messages) => {
        const newMessages = [...(messages?.items ?? [])]
        const messageToUpdateIndex = newMessages.findIndex(
          (message) => message.messageId === messageId
        )

        if (messageToUpdateIndex >= 0) {
          newMessages.splice(messageToUpdateIndex, 1, {
            ...newMessages[messageToUpdateIndex],
            ...updatedMessage,
          })
        }

        return { ...messages, items: newMessages }
      })

      return { previousMessagesQueries }
    },
    onError(
      __: unknown,
      { inboxId }: Ids,
      context:
        | { previousMessagesQueries: [QueryKey, ListMessagesResponse | undefined][] }
        | undefined
    ) {
      context?.previousMessagesQueries.forEach(([queryKey, messages]) => {
        queryClient.setQueryData(queryKey, messages)
      })

      queryClient.invalidateQueries({ queryKey: inboxQueryKeys.messages(inboxId) })
    },
  }
}
