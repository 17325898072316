import { InspectionDetails, RequestedInspection } from '@eigtech/claims-v2-types'
import { useGetContactOfTypeFromClaim } from '@eigtech/ui-shared-assignments'
import {
  DefaultMonthlyEventItem,
  MobileMonthlyCalendar,
  MobileMonthlyDay,
  MobileMonthlyNav,
  MonthlyBody,
  MonthlyCalendar,
  MonthlyDay,
  MonthlyNav,
} from '@eigtech/ui-shared-calendar'
import { getInspectionStatusColorScheme, getInspectionStatusLabel } from '@eigtech/ui-shared-claims'
import { getContactName } from '@eigtech/ui-shared-contacts'
import { format, formatDate, fromZonedTime, isISODate } from '@eigtech/ui-shared-dates'
import {
  As,
  Box,
  Button,
  ButtonGroup,
  ComposedAlert,
  ComposedMenu,
  ComposedModal,
  HStack,
  Heading,
  Icon,
  MenuItem,
  Stack,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
  useToken,
} from '@eigtech/ui-shared-dave'
import { Link, useRoutesContext } from '@eigtech/ui-shared-router'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'
import { useMemo } from 'react'
import { FaHardHat } from 'react-icons/fa'
import { MdQuestionMark } from 'react-icons/md'
import { Merge } from 'type-fest'
import { ScheduleInspectionDrawer } from './ScheduleInspectionDrawer'
import { UpdateInspectionDrawer } from './UpdateInspectionDrawer'

export type InspectionCalendarProps = {
  currentClaimNumber?: string
  inspections: (InspectionDetails & { claimNumber: string })[]
  selectedMonth: Date
  onSelectedMonthChange: (selectedMonth: Date) => any
}

export function InspectionCalendar({
  currentClaimNumber,
  inspections,
  selectedMonth,
  onSelectedMonthChange,
}: InspectionCalendarProps) {
  const scheduledInspections = useMemo(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return (inspections ?? [])
      .filter((inspection): inspection is ScheduledInspectionDetails => !!inspection.scheduledDate)
      .map((inspection) => ({
        ...inspection,
        date: isISODate(inspection.scheduledDate)
          ? new Date(inspection.scheduledDate)
          : fromZonedTime(inspection.scheduledDate, timezone),
      }))
  }, [inspections])

  const requestedInspections = useMemo(
    () =>
      (inspections ?? []).filter(
        (inspection): inspection is RequestedInspection & { claimNumber: string } =>
          inspection.status === 'requested'
      ),
    [inspections]
  )

  return (
    <>
      <Stack spacing="6">
        <Stack spacing="4">
          {requestedInspections.map((inspection) => (
            <RequestedInspectionAlert
              key={inspection.inspectionId}
              claimNumber={inspection.claimNumber}
              inspection={inspection}
            />
          ))}
        </Stack>
        <Box display={{ base: 'none', md: 'block' }}>
          <MonthlyCalendar<(typeof scheduledInspections)[number]>
            events={scheduledInspections}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          >
            <MonthlyNav />
            <MonthlyBody>
              <MonthlyDay<(typeof scheduledInspections)[number]>
                renderDays={(data) =>
                  data.map((item) => (
                    <InspectionEvent
                      key={item.inspectionId}
                      claimNumber={item.claimNumber}
                      item={item}
                      shouldLinkToClaim={!currentClaimNumber}
                    />
                  ))
                }
              />
            </MonthlyBody>
          </MonthlyCalendar>
        </Box>

        <Box display={{ base: 'block', md: 'none' }}>
          <MobileMonthlyCalendar
            events={scheduledInspections}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          >
            <MobileMonthlyNav />
            <MobileMonthlyDay<(typeof scheduledInspections)[number]>
              renderDay={(data) =>
                data.map((item) => (
                  <InspectionEvent
                    key={item.inspectionId}
                    claimNumber={item.claimNumber}
                    item={item}
                    shouldLinkToClaim={!currentClaimNumber}
                  />
                ))
              }
            />
          </MobileMonthlyCalendar>
        </Box>
      </Stack>
    </>
  )
}

function RequestedInspectionAlert({
  claimNumber,
  inspection,
}: {
  claimNumber: string
  inspection: RequestedInspection
}) {
  const scheduleRequestedInspectionDrawer = useDisclosure()
  const cancelDrawer = useDisclosure()

  return (
    <>
      <ComposedAlert
        alert={{
          title: `Inspection requested for ${startCase(inspection.inspectorRole ?? 'Unknown')}`,
          description: (
            <ButtonGroup mt="2" size="sm">
              <Button onClick={scheduleRequestedInspectionDrawer.onOpen}>
                Schedule Requested Inspection
              </Button>
              <Button variant="outline" onClick={cancelDrawer.onOpen}>
                Cancel Inspection Request
              </Button>
            </ButtonGroup>
          ),
        }}
        status="warning"
      />

      {scheduleRequestedInspectionDrawer.isOpen && (
        <ScheduleInspectionDrawer
          {...scheduleRequestedInspectionDrawer}
          claimNumber={claimNumber}
          inspection={inspection}
        />
      )}

      {cancelDrawer.isOpen && (
        <UpdateInspectionDrawer
          {...cancelDrawer}
          action="cancel"
          claimNumber={claimNumber}
          inspectionId={inspection.inspectionId}
        />
      )}
    </>
  )
}

function InspectionEvent({
  shouldLinkToClaim,
  claimNumber,
  item,
}: {
  claimNumber: string
  item: ScheduledInspectionDetails & { date: Date }
  shouldLinkToClaim?: boolean
}) {
  const color = `${getInspectionStatusColorScheme(item.status)}.200`

  const colorHex = useToken('colors', color)

  const isISO = isISODate(item.scheduledDate)
  const formattedDate = isISO
    ? format(new Date(item.scheduledDate), 'h:mm aa')
    : formatDate(item.scheduledDate)

  const { contacts: fieldAdjusterContacts } = useGetContactOfTypeFromClaim({
    contactType: 'fieldAdjuster',
    claimNumber,
  })

  const Inspector = !item.inspectorRole ? (
    <Text>No inspector</Text>
  ) : (
    exhaustive(item.inspectorRole, {
      fieldAdjuster: () => (
        <Info icon={FaHardHat} label="Field Adjuster">
          {getContactName(fieldAdjusterContacts?.at(0) ?? {})}
        </Info>
      ),
      _: () => (
        <Info icon={MdQuestionMark} label="Unknown inspector role">
          (Unknown)
        </Info>
      ),
    })
  )

  const notesDrawer = useDisclosure()

  const { claimDetailInfo } = useRoutesContext()

  return (
    <>
      <DefaultMonthlyEventItem color={colorHex} date={formattedDate}>
        <Stack>
          <Heading as="h3" size="sm">
            {getInspectionStatusLabel(item.status)}
          </Heading>

          {shouldLinkToClaim &&
            (claimDetailInfo ? (
              <Link fontWeight="bold" to={claimDetailInfo(claimNumber)}>
                {claimNumber}
              </Link>
            ) : (
              <Text fontWeight="bold">{claimNumber}</Text>
            ))}

          {Inspector}

          <ButtonGroup orientation="vertical" size="xs" variant="outline">
            <InspectionActions claimNumber={claimNumber} inspection={item} />

            <Button onClick={notesDrawer.onOpen}>See Notes</Button>
          </ButtonGroup>
        </Stack>
      </DefaultMonthlyEventItem>

      {notesDrawer.isOpen && (
        <ComposedModal {...notesDrawer} closeOnOverlayClick title="Inspection Notes">
          <Text whiteSpace="preserve">{item.notes || '(None)'}</Text>
        </ComposedModal>
      )}
    </>
  )
}

function InspectionActions({
  claimNumber,
  inspection,
}: {
  claimNumber: string
  inspection: ScheduledInspectionDetails
}) {
  const completeDrawer = useDisclosure()
  const cancelDrawer = useDisclosure()

  if (inspection.status !== 'scheduled') {
    return null
  }

  return (
    <>
      <ComposedMenu trigger="Actions">
        <MenuItem onClick={completeDrawer.onOpen}>Mark Completed</MenuItem>
        <MenuItem onClick={cancelDrawer.onOpen}>Mark Canceled</MenuItem>
      </ComposedMenu>

      {completeDrawer.isOpen && (
        <UpdateInspectionDrawer
          {...completeDrawer}
          action="complete"
          claimNumber={claimNumber}
          inspectionId={inspection.inspectionId}
        />
      )}

      {cancelDrawer.isOpen && (
        <UpdateInspectionDrawer
          {...cancelDrawer}
          action="cancel"
          claimNumber={claimNumber}
          inspectionId={inspection.inspectionId}
        />
      )}
    </>
  )
}

function Info({ children, icon, label }: { label: string; icon: As; children: string }) {
  return (
    <Tooltip label={label} placement="left">
      <HStack alignItems="flex-start" spacing="1">
        <Icon as={icon} />
        <chakra.span>{children}</chakra.span>
      </HStack>
    </Tooltip>
  )
}

type ScheduledInspectionDetails = Merge<
  InspectionDetails,
  {
    scheduledDate: string
    claimNumber: string
  }
>
