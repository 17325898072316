import {
  ArchiveAllInboxMessageRequest,
  ArchiveInboxMessageRequest,
  ArchiveInboxMessageResponse,
  GetInboxResponse,
  ListMessagesResponse,
} from '@eigtech/messaging-types'
import {
  ApiPostRequest,
  useApiInstanceContext,
  useMutation,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { useToast } from '@eigtech/ui-shared-dave'
import { inboxQueryKeys, messageBasePath } from './constants'
import { useOptimisticallyUpdateMessage } from './hooks'

export const archiveMessage =
  (post: ApiPostRequest) =>
  ({ inboxId, messageId }: ArchiveInboxMessageRequest) =>
    post<ArchiveInboxMessageResponse>(
      `${messageBasePath}/inbox/${inboxId}/${encodeURIComponent(messageId)}/archive`,
      null,
      { responseType: 'none' }
    )

export const archiveAllMessages =
  (post: ApiPostRequest) =>
  ({ inboxId }: ArchiveAllInboxMessageRequest) =>
    post<ArchiveInboxMessageResponse>(`${messageBasePath}/inbox/${inboxId}/all/archive`, null, {
      responseType: 'none',
    })

export function useArchiveAllMessage() {
  const { post } = useApiInstanceContext()
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: archiveAllMessages(post),
    async onMutate({ inboxId }) {
      const baseQueryKey = inboxQueryKeys.messages(inboxId)

      // update inbox query
      const inboxQueryKey = inboxQueryKeys.detail(inboxId)

      await queryClient.cancelQueries({ queryKey: inboxQueryKey })

      const previousInbox = queryClient.getQueryData<GetInboxResponse>(inboxQueryKey)

      // reset inbox
      if (!!previousInbox) {
        queryClient.setQueryData<GetInboxResponse>(inboxQueryKey, () => ({
          ...previousInbox,
          unread: 0,
          read: 0,
        }))
      }

      // update messages queries
      await queryClient.cancelQueries({ queryKey: baseQueryKey })

      const previousMessagesQueries = queryClient.getQueriesData<ListMessagesResponse>({
        queryKey: baseQueryKey,
      })

      // all messages were archived
      queryClient.setQueriesData<ListMessagesResponse>({ queryKey: baseQueryKey }, (messages) => ({
        ...messages,
        items: [],
      }))

      return { previousMessagesQueries, previousInbox }
    },
    onError(__, request, context) {
      toast({
        status: 'error',
        title: 'Failed to archived all message',
      })

      queryClient.setQueryData(inboxQueryKeys.detail(request.inboxId), context?.previousInbox)
    },
  })
}

export function useArchiveMessage() {
  const { post } = useApiInstanceContext()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { onError } = useOptimisticallyUpdateMessage()

  return useMutation({
    mutationFn: archiveMessage(post),
    onSuccess() {
      toast({
        status: 'success',
        title: 'Successfully archived message!',
      })
    },
    async onMutate({ inboxId, messageId }) {
      const baseQueryKey = inboxQueryKeys.messages(inboxId)

      // update inbox query
      const inboxQueryKey = inboxQueryKeys.detail(inboxId)

      await queryClient.cancelQueries({ queryKey: inboxQueryKey })

      const previousInbox = queryClient.getQueryData<GetInboxResponse>(inboxQueryKey)
      const allMessages = queryClient
        .getQueriesData<ListMessagesResponse>({ queryKey: baseQueryKey })
        .flatMap(([__, data]) => data?.items ?? [])

      const message = allMessages.find(
        (message) => message.messageId === messageId && message.inboxId === inboxId
      )

      if (!!(previousInbox && message)) {
        queryClient.setQueryData<GetInboxResponse>(inboxQueryKey, () => {
          const isRead = !!message.dateRead

          return {
            ...previousInbox,
            unread: previousInbox.unread - (!isRead ? 1 : 0),
            read: previousInbox.read - (isRead ? 1 : 0),
          }
        })
      }

      // update messages queries
      await queryClient.cancelQueries({ queryKey: baseQueryKey })

      const previousMessagesQueries = queryClient.getQueriesData<ListMessagesResponse>({
        queryKey: baseQueryKey,
      })

      queryClient.setQueriesData<ListMessagesResponse>({ queryKey: baseQueryKey }, (messages) => {
        const newMessages = [...(messages?.items ?? [])]

        const messageToUpdateIndex = newMessages.findIndex(
          (message) => message.messageId === messageId
        )

        if (messageToUpdateIndex >= 0) {
          newMessages.splice(messageToUpdateIndex, 1)
        }

        return { ...messages, items: newMessages }
      })

      return { previousMessagesQueries, previousInbox }
    },
    onError(error, request, context) {
      toast({
        status: 'error',
        title: 'Failed to archived message',
      })

      queryClient.setQueryData(inboxQueryKeys.detail(request.inboxId), context?.previousInbox)

      onError(error, request, context)
    },
  })
}
