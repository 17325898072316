import { useAuth0 } from '@auth0/auth0-react'
import { MongoAbility, RawRuleOf } from '@casl/ability'
import { BoundCanProps, useAbility as useBaseAbility } from '@casl/react'
import jwtDecode from 'jwt-decode'
import { ComponentType, Context, FunctionComponent, useContext, useEffect, useState } from 'react'
import {
  Auth0AccessTokenJwtPayload,
  Permissions,
  Role,
  WithRequiredPermissionsProps,
} from './types'
import {
  ability as baseAbility,
  AbilityContext as BaseAbilityContext,
  Can as CanBase,
  updateAbility as baseUpdateAbility,
  useHasRequiredPermissions as useHasRequiredPermissionsBase,
  UseHasRequiredPermissionsProps,
  withRequiredPermissions as withRequiredPermissionsBase,
} from './utils'
import { Auth0TokenRoleKey } from '@eigtech/auth0-types'

export type PermissionGenerator<Action extends string, Subject extends string> = (
  basePermissions: Permissions<Action, Subject>,
  roles: Role[]
) => RawRuleOf<MongoAbility<[Action, Subject]>>[]

export type PermissionsFactoryProps<Action extends string, Subject extends string> = {
  additionalPermissionsGenerators?: PermissionGenerator<Action, Subject>[]
}

export function permissionsFactory<Action extends string, Subject extends string>({
  additionalPermissionsGenerators = [],
}: PermissionsFactoryProps<Action, Subject> = {}) {
  type AbilityType = MongoAbility<[Action, Subject]>

  const ability = baseAbility as unknown as AbilityType

  const AbilityContext = BaseAbilityContext as unknown as Context<AbilityType>

  const useAbilityContext = () => useContext(AbilityContext)

  const useAbility = () => useBaseAbility(AbilityContext)

  const updateAbility = (permissions: Permissions<Action, Subject>, roles: Role[]) =>
    baseUpdateAbility(permissions, roles, additionalPermissionsGenerators)

  function useKeepAbilityUpToDate() {
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
    const [hasUpdatedAbility, setHasUpdatedAbility] = useState(false)

    useEffect(() => {
      if (!(isAuthenticated && user && getAccessTokenSilently)) return

      async function handleUpdatePermissions() {
        const token = await getAccessTokenSilently()
        const decodedToken = jwtDecode<Auth0AccessTokenJwtPayload<Action, Subject>>(token)
        updateAbility(decodedToken.permissions, decodedToken[Auth0TokenRoleKey])
        setHasUpdatedAbility(true)
      }

      handleUpdatePermissions()
    }, [isAuthenticated, user, getAccessTokenSilently])

    return hasUpdatedAbility
  }

  const Can = CanBase as unknown as FunctionComponent<BoundCanProps<AbilityType>>

  const useHasRequiredPermissions = (props: UseHasRequiredPermissionsProps<Action, Subject>) =>
    useHasRequiredPermissionsBase(props)

  const withRequiredPermissions = <Props extends {} = {}>(
    Component: ComponentType<Props>,
    props: WithRequiredPermissionsProps<Action, Subject>
  ) => withRequiredPermissionsBase(Component, props)

  return {
    ability,
    AbilityContext,
    useAbility,
    useAbilityContext,
    updateAbility,
    useKeepAbilityUpToDate,
    Can,
    useHasRequiredPermissions,
    withRequiredPermissions,
  }
}
