import { IS_DEV } from '@eigtech/ui-shared-constants'
import log from '@eigtech/ui-shared-logging'
import React from 'react'
import errorDog from './error_dog.gif'
import messedUp from './key_and_peele_messed_up.gif'

export function GenericErrorBoundaryComponent() {
  return (
    <div
      style={{
        padding: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100vw',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          borderRadius: '1rem',
          padding: '1rem 2rem',
          maxWidth: '25rem',
          fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        }}
      >
        <h1>Well this is embarrassing...</h1>
        <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
          Something went wrong. Please try again or contact support for help.
        </p>
        <img
          alt=""
          aria-hidden="true"
          src={IS_DEV ? messedUp : errorDog}
          style={{ maxWidth: '100%' }}
        />
      </div>
    </div>
  )
}

export class GenericErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    log.error('Generic Error Boundary', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <GenericErrorBoundaryComponent />
    }

    return this.props.children
  }
}
