import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimSummaryFieldSchema, ClaimSummarySchema } from '../../queryModels/claimQueryModel.js'
import { PageResponsePropertiesSchema, makePageRequestSchema } from '../pagination.js'

export const GetClaimSummariesInternalSchemas = {
  query: makePageRequestSchema(ClaimSummaryFieldSchema),
  response: z
    .object({
      claims: ClaimSummarySchema.array(),
    })
    .merge(PageResponsePropertiesSchema),
}

export const GetClaimSummariesSchemas = {
  ...GetClaimSummariesInternalSchemas,
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestToken: true }).required({
    requestToken: true,
  }),
  response: z
    .object({
      claims: ClaimSummarySchema.partial().array(),
    })
    .merge(PageResponsePropertiesSchema),
}

export const GetClaimSummariesInternalRequestSchema = GetClaimSummariesInternalSchemas.query
export const GetClaimSummariesRequestSchema = GetClaimSummariesSchemas.query.merge(
  GetClaimSummariesSchemas.headers
)
export const GetClaimSummariesInternalResponseSchema = GetClaimSummariesInternalSchemas.response
export const GetClaimSummariesResponseSchema = GetClaimSummariesSchemas.response

export type GetClaimSummariesRequest = z.infer<typeof GetClaimSummariesRequestSchema>
export type GetClaimSummariesInternalRequest = z.infer<
  typeof GetClaimSummariesInternalRequestSchema
>
export type GetClaimSummariesResponse = z.infer<typeof GetClaimSummariesResponseSchema>
export type GetClaimSummariesInternalResponse = z.infer<
  typeof GetClaimSummariesInternalResponseSchema
>

export interface GetClaimSummariesHandler
  extends Handler<GetClaimSummariesRequest, GetClaimSummariesResponse> {}
export interface GetClaimSummariesHandlerInternal
  extends Handler<GetClaimSummariesInternalRequest, GetClaimSummariesInternalResponse> {}

export const GetClaimSummariesApiSchema: ApiSchema = GetClaimSummariesSchemas
export const GetClaimSummariesInternalApiSchema: ApiSchema = GetClaimSummariesInternalSchemas
