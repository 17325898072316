import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'summarizer'
export const summarizerServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const SummarizerServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: summarizerServiceEventType(),
  })
)

export type SummarizerServiceEventBase = z.infer<typeof SummarizerServiceEventBaseSchema>
