import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const LinkAccountsRequestBodySchema = z.object({
  primaryUserId: z.string(),
  secondaryUserId: z.string(),
})

export const LinkAccountsRequestSchema = LinkAccountsRequestBodySchema
export const LinkAccountsResponseSchema = z.void()

export type LinkAccountsRequest = z.infer<typeof LinkAccountsRequestSchema>
export type LinkAccountsRequestBody = z.infer<typeof LinkAccountsRequestBodySchema>
export type LinkAccountsResponse = z.infer<typeof LinkAccountsResponseSchema>

export interface LinkAccountsHandler extends Handler<LinkAccountsRequest, LinkAccountsResponse> {}

export const LinkAccountsApiSchema: ApiSchema = {
  body: LinkAccountsRequestBodySchema,
}
