import { isValid } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { baseFormatStringAsDate } from './baseFormatStringAsDate'
import { ConvertDateOnlyStringToDateOptions } from './convertDateOnlyStringToDate'

export function formatStringAsDate<ValidDate extends boolean>(
  value: string | number | Date | undefined | null,
  dateFormat: string = 'MM/dd/yyyy',
  options?: ConvertDateOnlyStringToDateOptions<ValidDate>
) {
  return baseFormatStringAsDate(value, dateFormat, { ...options })
}

export function formatDateInTimeZone(
  date: string | number | Date | undefined | null,
  dateFormat: string = 'MM/dd/yyyy',
  timeZone: string
) {
  if (!date) return '(Empty)'
  if (date instanceof Date && !isValid(date)) return '(Invalid Date)'

  return formatInTimeZone(date, timeZone, dateFormat)
}
