import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookSchema, GlobalBookCornLaxSchema } from '../../../base/books.js'
import { ContactSchema } from '../../../base/contacts.js'

export const GetGlobalBookRequestPathParametersSchema = z.object({
  bookId: GlobalBookCornLaxSchema,
})

export const GetGlobalBookRequestSchema = GetGlobalBookRequestPathParametersSchema

export const GetGlobalBookResponseSchema = BookSchema.extend({
  contacts: ContactSchema.array(),
})

export type GetGlobalBookRequestPathParameters = z.infer<
  typeof GetGlobalBookRequestPathParametersSchema
>

export type GetGlobalBookRequest = z.infer<typeof GetGlobalBookRequestSchema>

export type GetGlobalBookResponse = z.infer<typeof GetGlobalBookResponseSchema>

export interface GetGlobalBookHandler
  extends Handler<GetGlobalBookRequest, GetGlobalBookResponse> {}

export const GetGlobalBookApiSchema: ApiSchema = {
  params: GetGlobalBookRequestPathParametersSchema,
  response: GetGlobalBookResponseSchema,
}
