import { AssignmentLaxSchema } from '@eigtech/assignments-types'
import { z } from 'zod'

export const JobAssignedEventDetailsSchema = AssignmentLaxSchema.omit({
  requestActor: true,
})
export type JobAssignedEventDetails = z.infer<typeof JobAssignedEventDetailsSchema>

export const JobUnassignedEventDetailsSchema = z.object({
  assignableId: z.string(),
  assigneeId: z.string(),
})
export type JobUnassignedEventDetails = z.infer<typeof JobUnassignedEventDetailsSchema>
