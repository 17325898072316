import { getUrlFilename } from '@eigtech/function-utils'
import { getResourceId } from '@eigtech/shared-corn'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import {
  ClaimLossOfUseReceiptAddedDetails,
  ClaimLossOfUseReceiptRemovedDetails,
} from '@eigtech/summarizer-types'
import { Blockquote, Button, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { useGetDocumentUrl } from '@eigtech/ui-shared-documents'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { startCase } from 'lodash-es'

type Details = ClaimLossOfUseReceiptAddedDetails | ClaimLossOfUseReceiptRemovedDetails

export function LossOfUseReceiptDetails(props: EventDetailsComponentProps<Details>) {
  const { type, receipt } = props.details.lossOfUse
  const { documentCorn } = receipt

  const { data: url } = useGetDocumentUrl({
    entityId: ensureClaimCorn(props.entityId),
    documentId: getResourceId(documentCorn),
  })

  const { filename } = getUrlFilename(url)
  return (
    <VStack alignItems="flex-start">
      {filename && (
        <HStack>
          <Tag>{startCase(type)}</Tag>
          <Text as="span">receipt {'receiptDateRemoved' in receipt ? 'removed' : 'approved'}</Text>
          <Button as="a" href={url} size="sm" target={documentCorn}>
            View Receipt
          </Button>
        </HStack>
      )}

      {'note' in receipt && !!receipt.note && (
        <Blockquote whiteSpace="preserve">{receipt.note}</Blockquote>
      )}
    </VStack>
  )
}
