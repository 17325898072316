import { Contact } from '@eigtech/contacts-types'
import { getContactName as baseGetContactName } from '@eigtech/contacts-util'
import { formatStringAsDate } from '@eigtech/ui-shared-dates'

export const getContactName = (contact: Partial<Contact> = {}, fallback = 'Unknown') =>
  baseGetContactName(contact) ?? fallback

export const getContactNameWithRemovedStatus = (
  contact: Partial<Contact> = {},
  fallback = 'Unknown'
) =>
  `${getContactName(contact, fallback)}${
    contact?.metadata?.removed
      ? ` (removed on ${formatStringAsDate(contact.metadata.removedOn)})`
      : ''
  }`
