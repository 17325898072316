import { z } from 'zod'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetProfilePhotoUploadUrlRequestHeadersSchema = ApiLambdalerAuthHeaderSchema

export const GetProfilePhotoUploadUrlRequestSchema = GetProfilePhotoUploadUrlRequestHeadersSchema

export const GetProfilePhotoUploadUrlResponseSchema = z.object({
  fields: z.record(z.string()),
  url: z.string(),
})

export const GetProfilePhotoUploadUrlApiSchema = {
  headers: GetProfilePhotoUploadUrlRequestHeadersSchema,
  response: GetProfilePhotoUploadUrlResponseSchema,
} satisfies ApiSchema

export type GetProfilePhotoUploadUrlRequest = z.infer<typeof GetProfilePhotoUploadUrlRequestSchema>
export type GetProfilePhotoUploadUrlResponse = z.infer<
  typeof GetProfilePhotoUploadUrlResponseSchema
>
export type GetProfilePhotoUploadUrlHandler = Handler<
  GetProfilePhotoUploadUrlRequest,
  GetProfilePhotoUploadUrlResponse
>
