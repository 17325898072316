// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/alert.ts

import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, cssVar, StyleFunctionProps } from '@chakra-ui/styled-system'
import { transparentize } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

// const $fg = cssVar('alert-fg')
const $bg = cssVar('alert-bg')

// const baseStyle = definePartsStyle({})

function getBg(props: StyleFunctionProps) {
  const { theme, colorScheme: c } = props
  const darkBg = transparentize(`${c}.200`, 0.16)(theme)
  return {
    light: `colors.${c}.50`,
    dark: darkBg,
  }
}

const variantSubtle = definePartsStyle({
  container: {
    borderRadius: 'md',
  },
})

const variantLeftAccent = definePartsStyle((props) => {
  const bg = getBg(props)
  return {
    container: {
      [$bg.variable]: bg.light,
      borderLeftRadius: 'sm',
      borderRightRadius: 'md',
    },
  }
})

// const variantTopAccent = definePartsStyle({})

// const variantSolid = definePartsStyle({})

const variants = {
  subtle: variantSubtle,
  'left-accent': variantLeftAccent,
  // 'top-accent': variantTopAccent,
  // solid: variantSolid,
}

export const alertTheme = defineMultiStyleConfig({
  // baseStyle,
  variants,
  defaultProps: {
    colorScheme: 'info',
  },
})
