import z from 'zod'
import { TimelineEntityTypeSchema } from '../timeline'
import { StepFunctionTaskContextSchema } from './context'
import { Handler } from 'aws-lambda'

export const SetupTimelineSyncInputSchema = z.object({
  entityType: TimelineEntityTypeSchema,
})
export type SetupTimelineSyncInput = z.infer<typeof SetupTimelineSyncInputSchema>

export const SetupTimelineSyncRequestSchema = z.object({
  context: StepFunctionTaskContextSchema,
  input: SetupTimelineSyncInputSchema,
})
export type SetupTimelineSyncRequest = z.infer<typeof SetupTimelineSyncRequestSchema>

export const SetupTimelineSyncResponseSchema = z.object({
  entityType: TimelineEntityTypeSchema,
  executionId: z.string(),
  pageSize: z.number(),
  totalPages: z.number(),
})
export type SetupTimelineSyncResponse = z.infer<typeof SetupTimelineSyncResponseSchema>

export interface SetupTimelineSyncHandler
  extends Handler<SetupTimelineSyncRequest, SetupTimelineSyncResponse> {}
