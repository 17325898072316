import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  AccordionProps,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  ComponentWithAs,
  forwardRef,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react'
import React from 'react'

export type CardAccordionProps = {
  accordionPanelProps?: AccordionPanelProps
  cardProps?: CardProps
  children: string | React.ReactNode
  defaultToClosed?: boolean
  heading: string | React.ReactNode
  footer?: string | React.ReactNode
  lazyMount?: boolean
} & AccordionProps

export const CardAccordion: ComponentWithAs<'div', CardAccordionProps> = forwardRef(
  (
    {
      accordionPanelProps = {},
      cardProps = {},
      children,
      defaultToClosed,
      heading,
      footer,
      lazyMount = false,
      ...props
    },
    ref
  ) => {
    return (
      <Accordion ref={ref} allowToggle defaultIndex={defaultToClosed ? undefined : 0} {...props}>
        <AccordionItem border="0">
          {({ isExpanded }) => (
            <Card {...cardProps}>
              <AccordionButton p="0" roundedBottom={isExpanded ? 'none' : 'lg'} roundedTop="lg">
                <CardHeader borderBottomWidth={isExpanded ? '1px' : '0px'} w="full">
                  <HStack spacing="4">
                    <Box flex="1" textAlign="left" w="full">
                      {typeof heading === 'string' ? (
                        <Heading as="h2" size="md">
                          {heading}
                        </Heading>
                      ) : (
                        heading
                      )}
                    </Box>
                    <AccordionIcon />
                  </HStack>
                </CardHeader>
              </AccordionButton>
              <AccordionPanel p="0" {...accordionPanelProps}>
                <CardBody>
                  {((lazyMount && isExpanded) || !lazyMount) &&
                    (typeof children === 'string' ? <Text>{children}</Text> : children)}
                </CardBody>

                {!!footer && <CardFooter>{footer}</CardFooter>}
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
      </Accordion>
    )
  }
)
