import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema, WorkAuthorizationIdSchema } from '../../base/index.js'

export const RecordWorkAuthorizationOverrideApprovedRequestBodySchema = z.object({
  workAuthorization: z.object({
    effectiveDate: z.string().datetime(),
    approvedBy: ContactCornSchema.or(z.string()),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const RecordWorkAuthorizationOverrideApprovedRequestHeadersSchema =
  ApiLambdalerAuthHeaderSchema.pick({ requestActor: true })
export const RecordWorkAuthorizationOverrideApprovedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordWorkAuthorizationOverrideApprovedRequestSchema =
  RecordWorkAuthorizationOverrideApprovedRequestBodySchema.merge(
    RecordWorkAuthorizationOverrideApprovedRequestHeadersSchema
  ).merge(RecordWorkAuthorizationOverrideApprovedRequestPathSchema)
export const RecordWorkAuthorizationOverrideApprovedResponseSchema = z.void()
export const RecordWorkAuthorizationOverrideApprovedApiSchema: ApiSchema = {
  body: RecordWorkAuthorizationOverrideApprovedRequestBodySchema,
  headers: RecordWorkAuthorizationOverrideApprovedRequestHeadersSchema,
  params: RecordWorkAuthorizationOverrideApprovedRequestPathSchema,
}
export interface RecordWorkAuthorizationOverrideApprovedRequestBody
  extends z.infer<typeof RecordWorkAuthorizationOverrideApprovedRequestBodySchema> {}
export interface RecordWorkAuthorizationOverrideApprovedRequestPath
  extends z.infer<typeof RecordWorkAuthorizationOverrideApprovedRequestPathSchema> {}
export interface RecordWorkAuthorizationOverrideApprovedRequest
  extends z.infer<typeof RecordWorkAuthorizationOverrideApprovedRequestSchema> {}
export type RecordWorkAuthorizationOverrideApprovedResponse = z.infer<
  typeof RecordWorkAuthorizationOverrideApprovedResponseSchema
>
export interface RecordWorkAuthorizationOverrideApprovedHandler
  extends Handler<
    RecordWorkAuthorizationOverrideApprovedRequest,
    RecordWorkAuthorizationOverrideApprovedResponse
  > {}
