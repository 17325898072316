import { getErrorMessage } from '@eigtech/function-utils'
import { getAccessToken, useAuthStore } from '@eigtech/ui-shared-auth'
import { secondsToMilliseconds } from '@eigtech/ui-shared-dates'
import log from '@eigtech/ui-shared-logging'
import { Sentry } from '@eigtech/ui-shared-sentry'
import { exhaustive } from 'exhaustive'
import ky, { HTTPError, Options as KyOptions, ResponsePromise } from 'ky'

export type KyInstance = typeof ky

export type ResponseType = 'none' | 'json' | 'arrayBuffer' | 'blob' | 'formData' | 'text'

type DefaultResponseType<Response> = Response extends void ? 'none' : 'json'

export type Options<Type extends ResponseType = 'json'> = {
  responseType?: Type
} & KyOptions

export type ApiCallReturn<
  Response = unknown,
  Type extends ResponseType = DefaultResponseType<Response>,
> = Promise<
  Type extends 'json'
    ? Response
    : Type extends 'arrayBuffer'
      ? ArrayBuffer
      : Type extends 'blob'
        ? Blob
        : Type extends 'formData'
          ? FormData
          : Type extends 'text'
            ? string
            : ResponsePromise
>

const api = ky.create({})

async function getResponse<
  Response = unknown,
  Type extends ResponseType = DefaultResponseType<Response>,
>(response: ResponsePromise, responseType?: Type): ApiCallReturn<Response, Type> {
  try {
    const apiResponse = await exhaustive((responseType ?? 'json') as ResponseType, {
      arrayBuffer: () => response.arrayBuffer(),
      blob: () => response.blob(),
      formData: () => response.formData(),
      json: () => response.json<Response>(),
      text: () => response.text(),
      none: () => response,
      _: () => response.json<Response>(),
    })

    return apiResponse as ApiCallReturn<Response, Type>
  } catch (error) {
    if (error instanceof HTTPError) {
      let response
      try {
        response = await error.response.json()
      } catch {
        try {
          response = await error.response.text()
        } catch {}
      }

      log.error('API request error', {
        message: error.message,
        request: {
          url: error.request.url,
          method: error.request.method,
        },
        response: {
          status: error.response.status,
          body: response,
        },
      })

      Sentry.addBreadcrumb({
        type: 'error',
        category: 'error',
        level: 'error',
        message: error.message,
        data: { response },
      })
    } else {
      log.error('API request error', { error: getErrorMessage(error) })
    }

    throw error
  }
}

export async function genericRequest(
  path: string,
  options: KyOptions = {},
  instance: KyInstance = api
) {
  try {
    return await instance(path, options)
  } catch (error) {
    const skipLogToRemote =
      error instanceof HTTPError && (error.response.status === 404 || error.response.status === 403)

    if (error instanceof HTTPError) {
      log.error(
        'API request error',
        {
          message: error.message,
          request: {
            url: error.request.url,
            method: error.request.method,
          },
          response: {
            status: error.response.status,
            body: await error.response.json(),
          },
        },
        { logToRemote: !skipLogToRemote }
      )
    } else {
      log.error(
        'API request error',
        { error: getErrorMessage(error) },
        { logToRemote: !skipLogToRemote }
      )
    }

    throw error
  }
}

export async function genericGet<
  Response = unknown,
  Type extends ResponseType = DefaultResponseType<Response>,
>(path: string, options?: Options<Type>, instance: KyInstance = api) {
  const response = instance.get(path, options)
  return getResponse<Response, Type>(response, options?.responseType)
}

export async function genericPost<
  Response = unknown,
  Body = any,
  Type extends ResponseType = DefaultResponseType<Response>,
>(path: string, json?: Body, options?: Options<Type>, instance: KyInstance = api) {
  const response = instance.post(path, {
    ...(options ?? {}),
    json: json,
  })
  return getResponse<Response, Type>(response, options?.responseType)
}

export async function genericPut<
  Response = unknown,
  Body = any,
  Type extends ResponseType = DefaultResponseType<Response>,
>(path: string, json?: Body, options?: Options<Type>, instance: KyInstance = api) {
  const response = instance.put(path, {
    ...(options ?? {}),
    json: json,
  })
  return getResponse<Response, Type>(response, options?.responseType)
}

export async function genericRemove<
  Response = unknown,
  Body = any,
  Type extends ResponseType = DefaultResponseType<Response>,
>(path: string, json?: Body, options?: Options<Type>, instance: KyInstance = api) {
  const response = instance.delete(path, {
    ...(options ?? {}),
    json: json,
  })
  return getResponse<Response, Type>(response, options?.responseType)
}

export async function genericPatch<
  Response = unknown,
  Body = any,
  Type extends ResponseType = DefaultResponseType<Response>,
>(path: string, json?: Body, options?: Options<Type>, instance: KyInstance = api) {
  const response = instance.patch(path, {
    ...(options ?? {}),
    json: json,
  })
  return getResponse<Response, Type>(response, options?.responseType)
}

type ApiInstanceFactoryProps = KyOptions & {
  setAccessToken?: boolean
}

/**
 * This helps with the new router/data loading patterns. It is not always
 * guaranteed that the user's access token will be set before we start
 * trying to load api requests. If it has not been set, we can wait for
 * it to be loaded. This typically takes less than a couple second.
 */
function waitForAccessToken() {
  return new Promise<string>(async (resolve, reject) => {
    const accessToken = await getAccessToken()
    if (accessToken) {
      return resolve(accessToken)
    }

    // if access token has not been set within 5 seconds, throw error
    const timeout = setTimeout(
      () => reject(new Error('timed out waiting for access token')),
      secondsToMilliseconds(5)
    )

    // if access token not already set, wait for it to be set in auth store
    const unsubscribe = useAuthStore.subscribe(
      (state) => state.accessToken,
      (accessToken) => {
        // once access token has been set, clear timeout so we don't reject and
        // clean up auth store subscription
        if (accessToken) {
          clearTimeout(timeout)
          unsubscribe()
          resolve(accessToken)
        }
      }
    )
  })
}

export function apiInstanceFactory({ setAccessToken = true, ...options }: ApiInstanceFactoryProps) {
  const instance = ky.create({
    retry: 0,
    timeout: 30000,
    ...options,
    hooks: {
      ...options.hooks,
      beforeRequest: [
        ...(setAccessToken
          ? [
              async (request: Request) => {
                const accessToken = await waitForAccessToken()

                // For future reference. We structure the header like this because of specification
                // https://www.rfc-editor.org/rfc/rfc6750
                request.headers.set('Authorization', `Bearer ${accessToken}`)
                for (const [key, value] of Object.entries(options.headers ?? {})) {
                  request.headers.set(key, value)
                }
              },
            ]
          : []),
        ...(options.hooks?.beforeRequest ?? []),
      ],
    },
  })

  // My original goal was to use a `responseType` argument to change the
  // return type, but this is a feature TypeScript does not currently support.
  // There are a number of issues related to using a generics argument to
  // change a function's return type:
  // https://github.com/microsoft/TypeScript/issues/33912
  // https://github.com/microsoft/TypeScript/issues/33014
  return {
    instance,

    request: (path: string, options: KyOptions = {}) => genericRequest(path, options, instance),

    get: <Response = unknown, Type extends ResponseType = DefaultResponseType<Response>>(
      path: string,
      options?: Options<Type>
    ) => genericGet<Response, Type>(path, options, instance),

    getJson: <Response = unknown>(path: string, options: KyOptions = {}) =>
      genericGet<Response, 'json'>(path, { ...options, responseType: 'json' }, instance),

    getArrayBuffer: (path: string, options: KyOptions = {}) =>
      genericGet(path, { ...options, responseType: 'arrayBuffer' }, instance),

    getBlob: (path: string, options: KyOptions = {}) =>
      genericGet(path, { ...options, responseType: 'blob' }, instance),

    getFormData: (path: string, options: KyOptions = {}) =>
      genericGet(path, { ...options, responseType: 'formData' }, instance),

    getText: (path: string, options: KyOptions = {}) =>
      genericGet(path, { ...options, responseType: 'text' }, instance),

    post: <
      Response = unknown,
      Body = any,
      Type extends ResponseType = DefaultResponseType<Response>,
    >(
      path: string,
      json?: Body,
      options?: Options<Type>
    ) => genericPost<Response, Body, Type>(path, json, options, instance),

    put: <
      Response = unknown,
      Body = any,
      Type extends ResponseType = DefaultResponseType<Response>,
    >(
      path: string,
      json?: Body,
      options?: Options<Type>
    ) => genericPut<Response, Body, Type>(path, json, options, instance),

    remove: <
      Response = unknown,
      Body = any,
      Type extends ResponseType = DefaultResponseType<Response>,
    >(
      path: string,
      json?: Body,
      options?: Options<Type>
    ) => genericRemove<Response, Body, Type>(path, json, options, instance),

    patch: <
      Response = unknown,
      Body = any,
      Type extends ResponseType = DefaultResponseType<Response>,
    >(
      path: string,
      json?: Body,
      options?: Options<Type>
    ) => genericPatch<Response, Body, Type>(path, json, options, instance),
  }
}

export type ApiInstanceFactoryReturn = ReturnType<typeof apiInstanceFactory>

export type ApiGetRequest = ApiInstanceFactoryReturn['get']
export type ApiPostRequest = ApiInstanceFactoryReturn['post']
export type ApiPutRequest = ApiInstanceFactoryReturn['put']
export type ApiPatchRequest = ApiInstanceFactoryReturn['patch']
export type ApiRemoveRequest = ApiInstanceFactoryReturn['remove']
