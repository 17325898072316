import {
  Alert,
  AlertDescription,
  AlertDescriptionProps,
  AlertIcon,
  AlertIconProps,
  AlertProps,
  AlertStatus,
  AlertTitle,
  AlertTitleProps,
  Box,
  CloseButton,
  forwardRef,
  Progress,
  ProgressProps,
  useDisclosure,
} from '@chakra-ui/react'
import { millisecondsToSeconds } from '@eigtech/ui-shared-dates'
import { motion } from 'framer-motion'
import {
  isValidElement,
  forwardRef as reactForwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

export type ComposedAlertProps = AlertProps & {
  iconProps?: AlertIconProps
  isClosable?: boolean
  titleProps?: AlertTitleProps
  descriptionProps?: AlertDescriptionProps
  hasIcon?: boolean
  autoClose?: boolean | number
  hideProgress?: boolean
  scrollToOnMount?: boolean
  alert:
    | React.ReactNode
    | {
        title?: React.ReactNode
        description?: React.ReactNode
      }
  onClose?: () => any
}

export const ComposedAlert = forwardRef<ComposedAlertProps, 'div'>(
  (
    {
      alert,
      autoClose,
      descriptionProps = {},
      hasIcon = true,
      hideProgress,
      iconProps = {},
      isClosable,
      onClose: onCloseProp,
      titleProps = {},
      scrollToOnMount,
      ...props
    },
    ref
  ) => {
    const alertIsObject = alert !== null && alert !== undefined && typeof alert === 'object'

    const alertTitle = alertIsObject && 'title' in alert ? alert.title : alert
    const alertDescription = alertIsObject && 'description' in alert ? alert.description : ''

    const { isOpen, onClose: defaultOnClose } = useDisclosure({ defaultIsOpen: true })

    const onClose = useCallback(() => {
      defaultOnClose()
      onCloseProp?.()
    }, [defaultOnClose, onCloseProp])

    useEffect(() => {
      if (!autoClose) return
      const timeout = setTimeout(onClose, autoClose === true ? DEFAULT_TIMEOUT : autoClose)

      return () => {
        if (!!timeout) {
          clearTimeout(timeout)
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const alertRef = useRef<HTMLDivElement>()
    useImperativeHandle(ref, () => alertRef.current)

    useEffect(() => {
      // abort if `scrollToMount` is explicitly `false` or if alert status is not `warning` or `error`
      if (
        scrollToOnMount === false ||
        !(['warning', 'error'] satisfies AlertStatus[] as string[]).includes(props.status ?? 'info')
      ) {
        return
      }

      alertRef.current?.scrollIntoView({
        behavior: 'smooth',
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!isOpen) return null

    return (
      <Alert ref={alertRef} {...props}>
        {!!hasIcon && <AlertIcon {...iconProps} />}

        <Box>
          {!!alertTitle && (isValidElement(alertTitle) || typeof alertTitle === 'string') && (
            <AlertTitle {...titleProps}>{alertTitle}</AlertTitle>
          )}
          {!!alertDescription && (
            <AlertDescription {...descriptionProps}>{alertDescription}</AlertDescription>
          )}
        </Box>

        {(isClosable || !!autoClose || !!onCloseProp) && (
          <CloseButton
            alignSelf="flex-start"
            position="absolute"
            right={0.5}
            size="sm"
            top={0.5}
            onClick={onClose}
          />
        )}

        {!!autoClose && !hideProgress && (
          <MotionProgress
            animate={{ transform: 'scaleX(0)' }}
            as={motion.div}
            bottom="0"
            initial={{ transform: 'scaleX(1)' }}
            left="0"
            position="absolute"
            right="0"
            size="xs"
            transformOrigin="left"
            transition={{
              duration: millisecondsToSeconds(autoClose === true ? DEFAULT_TIMEOUT : autoClose),
            }}
            value={100}
          />
        )}
      </Alert>
    )
  }
)

const MotionProgress = motion.create(
  reactForwardRef<HTMLDivElement, ProgressProps>((props, ref) => <Progress ref={ref} {...props} />)
)

const DEFAULT_TIMEOUT = 10000
