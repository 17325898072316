import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'

export const RecordJobStartedRequestBodySchema = z.object({
  startedNotes: z.string().optional(),
  startedDate: z.string().datetime(),
})
export const RecordJobStartedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordJobStartedRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordJobStartedRequestSchema = RecordJobStartedRequestBodySchema.merge(
  RecordJobStartedRequestHeadersSchema
).merge(RecordJobStartedRequestPathSchema)
export const RecordJobStartedResponseSchema = z.void()
export const RecordJobStartedApiSchema: ApiSchema = {
  body: RecordJobStartedRequestBodySchema,
  headers: RecordJobStartedRequestHeadersSchema,
  params: RecordJobStartedRequestPathSchema,
}
export type RecordJobStartedRequestBody = z.infer<typeof RecordJobStartedRequestBodySchema>
export interface RecordJobStartedRequest extends z.infer<typeof RecordJobStartedRequestSchema> {}
export type RecordJobStartedResponse = z.infer<typeof RecordJobStartedResponseSchema>
export interface RecordJobStartedHandler
  extends Handler<RecordJobStartedRequest, RecordJobStartedResponse> {}
