import { ComposedAlert } from '@eigtech/ui-shared-dave'
import { MeetingStatus, useMeetingStatus } from 'amazon-chime-sdk-component-library-react'

export function AfterMeeting() {
  const meetingStatusEnum = useMeetingStatus()
  const isMeetingClosed = meetingStatusEnum === MeetingStatus.Ended

  if (!isMeetingClosed) return null

  return (
    <ComposedAlert
      alert={{
        title: 'This meeting has been closed',
        description:
          'This meeting is closed. If you believe this to be an error, please contact EIG customer support.',
      }}
      status="info"
    />
  )
}
