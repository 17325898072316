import { DocumentV2 } from '@eigtech/documents-types'
import { ComposedAlert, forwardRef, Skeleton, Stack, StackProps } from '@eigtech/ui-shared-dave'
import { range } from 'lodash-es'
import { DocumentCard, DocumentCardProps } from './DocumentCard'
import { useOrganizeDocuments } from '../hooks/useOrganizeDocuments'

export type DocumentsListProps = {
  documents: DocumentV2[] | undefined
  isPending?: boolean
  isError?: boolean
} & Pick<DocumentCardProps, 'entityId' | 'DocumentActions'> &
  StackProps

export const DocumentsList = forwardRef<DocumentsListProps, 'div'>(
  ({ entityId, documents, isError, isPending, DocumentActions, ...props }, ref) => {
    const organizedDocuments = useOrganizeDocuments(documents)

    return (
      <Stack ref={ref} spacing="4" {...props}>
        {isError ? (
          <ComposedAlert alert="Something went wrong retrieving these documents." status="error" />
        ) : isPending ? (
          range(0, 3).map((i) => <Skeleton key={i} h="40" w="full" />)
        ) : !organizedDocuments.length ? (
          <ComposedAlert alert="No documents found." />
        ) : (
          organizedDocuments.map((document) => (
            <DocumentCard
              key={document.id}
              DocumentActions={DocumentActions}
              document={document}
              entityId={entityId}
            />
          ))
        )}
      </Stack>
    )
  }
)
