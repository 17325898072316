import { DocumentV2, mediaTypeRegex } from '@eigtech/documents-types'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import { augmentDocument } from '../utils/augmentDocument'

export type UseOrganizeDocumentsProps = {
  documents: DocumentV2[] | undefined
  filterFn?: (doc: DocumentV2) => boolean
}

const fallbackFilterFn = () => true

export function useBaseOrganizeDocuments({ documents, filterFn }: UseOrganizeDocumentsProps) {
  return useMemo(() => {
    const filteredDocuments = (documents ?? [])
      .filter(filterFn ?? fallbackFilterFn)
      .map(augmentDocument)
    const filenameDocMap = groupBy(filteredDocuments, 'filename')

    const groupedByFilename = Object.values(filenameDocMap).map((group) => {
      const description = group.find((d) => !!d.description)?.description ?? ''
      const items = group.map((doc) => ({ ...doc, description: doc.description || description }))

      return {
        ...items[0],
        subRows: items.slice(1),
      }
    })

    const groupedByNotes = groupBy(groupedByFilename, (group) => group.notes || group.filename)

    const grouped = Object.values(groupedByNotes)

    const rows = grouped.map((group) => {
      const flat = group.map((g) => [{ ...g, subRows: [] }, ...g.subRows]).flat()

      if (flat.length === 1) return flat[0]

      const sorted = [...flat].sort(sortDocumentsByDate)

      return {
        ...sorted[0],
        subRows: sorted.slice(1),
      }
    })

    // sort by date then push dec/loss to top
    rows
      .sort(sortDocumentsByDate)
      .sort(sortDocumentsByType('loss'))
      .sort(sortDocumentsByType('dec'))

    return rows
  }, [documents, filterFn])
}

export function useOrganizeDocuments(documents: UseOrganizeDocumentsProps['documents']) {
  return useBaseOrganizeDocuments({ documents, filterFn: documentFilterFn })
}

export function useOrganizeMedia(documents: UseOrganizeDocumentsProps['documents']) {
  return useBaseOrganizeDocuments({ documents, filterFn: mediaFilterFn })
}

const mediaFilterFn = (doc: DocumentV2) => !!doc.filename?.match(mediaTypeRegex)
const documentFilterFn = (doc: DocumentV2) => !mediaFilterFn(doc)

function sortDocumentsByDate(a: DocumentV2, b: DocumentV2) {
  return (
    new Date(b.modifiedDate ?? b.createdDate ?? new Date().toISOString()).getTime() -
    new Date(a.modifiedDate ?? a.createdDate ?? new Date().toISOString()).getTime()
  )
}

function sortDocumentsByType(type: string) {
  return function sortDocumentsByType(a: DocumentV2, b: DocumentV2) {
    const aIsProbablyType = a.filename.toLowerCase().includes(type)
    const bIsProbablyType = b.filename.toLowerCase().includes(type)

    return Number(bIsProbablyType) - Number(aIsProbablyType)
  }
}
