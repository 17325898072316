import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0UserSchema } from '../../logStreamEvents/base/base.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetUserFromIdRequestPathSchema = z.object({
  userId: z.string(),
})
export const GetUserFromIdRequestSchema = GetUserFromIdRequestPathSchema

export const GetUserFromIdResponseSchema = z.object({
  user: Auth0UserSchema,
})

export const GetUserFromIdApiSchema = {
  params: GetUserFromIdRequestPathSchema,
  response: GetUserFromIdResponseSchema,
} satisfies ApiSchema

export type GetUserFromIdRequest = z.infer<typeof GetUserFromIdRequestSchema>
export type GetUserFromIdResponse = z.infer<typeof GetUserFromIdResponseSchema>
export type GetUserFromIdHandler = Handler<GetUserFromIdRequest, GetUserFromIdResponse>
