import {
  EndMeetingRequestPath,
  EndMeetingResponse,
  GetMeetingResponse,
} from '@eigtech/meetings-types'
import { contextualPost, useMutation, useQueryClient } from '@eigtech/ui-shared-api'
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react'
import { MeetingState, useMeetingStore } from '../context'
import { meetingsBasePath, meetingsQueryKeys } from './constants'
import { useInvalidateGetMeeting } from './getMeeting'
import { useInvalidateListMeetings } from './listMeetings'

export function useLeaveMeeting() {
  const meetingManager = useMeetingManager()
  const { onLeaveMeeting, resetMeetingInformation } = useMeetingStore(stateSelector)

  return useMutation({
    mutationFn: async () => {
      onLeaveMeeting?.()
      await meetingManager.leave()
      resetMeetingInformation()
    },
  })
}
const stateSelector = ({ onLeaveMeeting, resetMeetingInformation }: MeetingState) => ({
  onLeaveMeeting,
  resetMeetingInformation,
})

export function useEndMeeting() {
  const invalidateListMeetings = useInvalidateListMeetings()
  const invalidateMeetingDetails = useInvalidateGetMeeting()

  const queryClient = useQueryClient()

  const meetingManager = useMeetingManager()
  const { resetMeetingInformation } = useMeetingStore(stateSelector)

  return useMutation({
    mutationFn: async (params: EndMeetingRequestPath & { meetingId: string }) => {
      const data = await contextualPost<EndMeetingResponse>(
        `${meetingsBasePath}/meeting/end/${params.entityId}/${params.startTime}`,
        {},
        {
          responseType: 'json',
          retry: 3,
        }
      )

      return data
    },
    async onMutate({ entityId, meetingId }) {
      const queryKey = meetingsQueryKeys.detail({
        entityId,
        meetingId,
      })

      await queryClient.cancelQueries({ queryKey })

      const previousMeeting = queryClient.getQueryData<GetMeetingResponse>(queryKey)

      queryClient.setQueryData<Partial<GetMeetingResponse>>(queryKey, (old) => ({
        ...old,
        endTime: new Date().toISOString(),
        status: 'closed',
        endMeetingReason: 'Ended by user',
      }))

      return { previousMeeting }
    },
    onError(__, { entityId, meetingId }, context) {
      const queryKey = meetingsQueryKeys.detail({
        entityId,
        meetingId,
      })

      queryClient.setQueryData(queryKey, context?.previousMeeting)
    },
    async onSettled(__, ___, { entityId, meetingId }) {
      await meetingManager.leave()
      resetMeetingInformation()
      invalidateListMeetings({ entityId })
      invalidateMeetingDetails({ entityId, meetingId })
    },
  })
}
