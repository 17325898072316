import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { BaseLossDetailsSchema, ClaimNumberSchema, LocationSchema } from '../model'

export const UpdateLossDetailsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    lossLocation: LocationSchema.optional(),
    lossDetails: BaseLossDetailsSchema,
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
}

export const UpdateLossDetailsRequestSchema = UpdateLossDetailsSchemas.params
  .merge(UpdateLossDetailsSchemas.body)
  .merge(UpdateLossDetailsSchemas.headers)

export const UpdateLossDetailsResponseSchema = z.void()

export const UpdateLossDetailsApiSchema: ApiSchema = UpdateLossDetailsSchemas

export type UpdateLossDetailsPath = z.infer<typeof UpdateLossDetailsSchemas.params>
export type UpdateLossDetailsBody = z.infer<typeof UpdateLossDetailsSchemas.body>
export type UpdateLossDetailsHeaders = z.infer<typeof UpdateLossDetailsSchemas.headers>
export type UpdateLossDetailsResponse = z.infer<typeof UpdateLossDetailsResponseSchema>
export type UpdateLossDetailsRequest = z.infer<typeof UpdateLossDetailsRequestSchema>

export interface UpdateLossDetailsHandler
  extends Handler<UpdateLossDetailsRequest, UpdateLossDetailsResponse> {}
