import {
  Box,
  BoxProps,
  CloseButton,
  ExpandingIconButton,
  forwardRef,
  Modal,
  ModalBody,
  ModalContent,
  Portal,
  Progress,
  Stack,
  Table,
  TableProps,
} from '@eigtech/ui-shared-dave'
import { ForwardedRef, useEffect, useRef, useState } from 'react'
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md'
import { DataGridBody } from './DataGridBody'
import { useDataGridContext } from './DataGridContext'
import { DataGridFoot } from './DataGridFoot'
import { DataGridHead } from './DataGridHead'
import { DataGridPagination } from './DataGridPagination'
import { DataGridTools } from './Settings'

type DataGridTableProps = TableProps & {
  containerRef?: ForwardedRef<any>
  containerProps?: BoxProps
}

export const DataGridTable = forwardRef<DataGridTableProps, 'table'>(
  ({ containerProps = {}, containerRef, ...props }, ref) => {
    const { tableId, isFetching, isPending, table } = useDataGridContext()

    const headRef = useRef<HTMLTableSectionElement>(null)

    const [headHeight, setHeadHeight] = useState(0)
    useEffect(() => {
      if (!headRef.current) return
      setHeadHeight(headRef.current.offsetHeight)
    }, [headRef, setHeadHeight])

    const [isExpanded, setIsExpanded] = useState(false)

    const portalRef = useRef(null)

    const Component = (
      <Stack
        ref={containerRef}
        {...containerProps}
        maxW="full"
        position="relative"
        {...(isExpanded
          ? {
              p: 4,
              w: '100vw',
              h: '100vh',
              sx: {
                '.data-grid-table-container': {
                  maxH: 'calc(100vh - 10px)',
                },
              },
            }
          : {})}
      >
        {isExpanded && (
          <CloseButton position="absolute" right="2" top="2" onClick={() => setIsExpanded(false)} />
        )}

        <DataGridTools
          afterSettingsButtons={
            <ExpandingIconButton
              aria-label={`${isExpanded ? 'Close' : 'Expand'} table.`}
              icon={isExpanded ? <MdFullscreenExit size="1.5em" /> : <MdFullscreen size="1.5em" />}
              iconPosition="right"
              variant="outline"
              onClick={() => setIsExpanded((state) => !state)}
            >
              {isExpanded ? 'Close' : 'Expand'} table
            </ExpandingIconButton>
          }
        />

        <Box
          className="data-grid-table-container"
          flexGrow={1}
          maxH="calc(100vh - 100px)"
          overflow="auto"
          overflowX="auto"
          position="relative"
          sx={{
            '::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '10px',
              height: '10px',
            },
            '::-webkit-scrollbar-thumb': {
              borderRadius: '5px',
              backgroundColor: 'rgba(0, 0, 0, .25)',
              WebkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
            },
          }}
        >
          {isFetching && !isPending && !!headHeight && (
            <Progress
              isIndeterminate
              position="absolute"
              size="xs"
              top={`${headHeight}px`}
              transform="translateY(-50%)"
              width="100%"
              zIndex={2}
            />
          )}

          <Table
            ref={ref}
            id={tableId}
            minW="full"
            position="relative"
            w={table.getTotalSize()}
            zIndex={0}
            {...props}
          >
            <DataGridHead ref={headRef} />
            <DataGridBody />
            {!!table.getFooterGroups().length && <DataGridFoot />}
          </Table>
        </Box>

        {(table.getPageCount() > 1 || table.getRowModel().rows.length > 10) && (
          <Box>
            <DataGridPagination />
          </Box>
        )}
      </Stack>
    )

    return isExpanded ? (
      <Modal isOpen motionPreset="none" size="full" onClose={() => setIsExpanded(false)}>
        <ModalContent h="100vh" m="0" p="0" w="100vw">
          <ModalBody ref={portalRef} h="100vh" p="0" w="100vw">
            <Portal containerRef={portalRef}>{Component}</Portal>
          </ModalBody>
        </ModalContent>
      </Modal>
    ) : (
      Component
    )
  }
)
