import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'
import { useMemo } from 'react'
import { useGetAssignedClaims } from '../../../../api'
import { ClaimsSearch } from '../../../../routes/claims'
import { isNewClaim, isTodayClaim } from '../utils'

export function useFilteredClaims(assignments: ClaimsSearch['assignments']) {
  const { data } = useGetAssignedClaims()

  const claims = useMemo(() => {
    let claims = data ?? []

    switch (assignments) {
      case 'new':
        claims = claims.filter((claim) => isNewClaim(claim))
        break
      case 'today':
        claims = claims.filter((claim) => isTodayClaim(claim))
        break
    }

    return claims.filter((claim): claim is DelegateAdminClaimSummary => !!claim)
  }, [assignments, data])

  return claims
}
