import {
  Box,
  DebouncedInput,
  FormControl,
  forwardRef,
  IconButton,
  IconButtonProps,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
} from '@eigtech/ui-shared-dave'
import { MdClose as ClearIcon, MdSearch as SearchIcon } from 'react-icons/md'
import { useDataGridContext } from '../DataGridContext'

export const DataGridGlobalSearch = forwardRef<Partial<IconButtonProps>, 'button'>((props, ref) => {
  const { table } = useDataGridContext()

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <IconButton
          ref={ref}
          aria-label="Open table search."
          icon={
            <>
              <Tooltip label="Open table search">
                <Box bottom="0" left="0" position="absolute" right="0" top="0"></Box>
              </Tooltip>
              <SearchIcon />
            </>
          }
          isRound={false}
          {...props}
        />
      </PopoverTrigger>

      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold">Global Search</PopoverHeader>
        <PopoverBody>
          <FormControl>
            <InputGroup>
              <DebouncedInput
                value={table.getState().globalFilter ?? ''}
                onChange={(event) => {
                  table.setGlobalFilter(event.target.value.trim())
                }}
              />
              <InputRightElement>
                <IconButton
                  aria-label="Clear filter."
                  icon={<ClearIcon />}
                  isRound={false}
                  size="sm"
                  variant="ghost"
                  onClick={() => table.setGlobalFilter('')}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
})
