import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema, WorkAuthorizationIdSchema } from '../../base/index.js'

export const RecordWorkAuthorizationCompletedRequestBodySchema = z.object({
  workAuthorization: z.object({
    approvedBy: z.string(),
    effectiveDate: z.string().datetime(),
    signer: ContactCornSchema.or(z.string()),
    workAuthorizationId: WorkAuthorizationIdSchema,
  }),
})
export const RecordWorkAuthorizationCompletedRequestHeadersSchema =
  ApiLambdalerAuthHeaderSchema.pick({ requestActor: true })
export const RecordWorkAuthorizationCompletedRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordWorkAuthorizationCompletedRequestSchema =
  RecordWorkAuthorizationCompletedRequestBodySchema.merge(
    RecordWorkAuthorizationCompletedRequestHeadersSchema
  ).merge(RecordWorkAuthorizationCompletedRequestPathSchema)
export const RecordWorkAuthorizationCompletedResponseSchema = z.void()
export const RecordWorkAuthorizationCompletedApiSchema: ApiSchema = {
  body: RecordWorkAuthorizationCompletedRequestBodySchema,
  headers: RecordWorkAuthorizationCompletedRequestHeadersSchema,
  params: RecordWorkAuthorizationCompletedRequestPathSchema,
}
export interface RecordWorkAuthorizationCompletedRequestBody
  extends z.infer<typeof RecordWorkAuthorizationCompletedRequestBodySchema> {}
export interface RecordWorkAuthorizationCompletedRequestPath
  extends z.infer<typeof RecordWorkAuthorizationCompletedRequestPathSchema> {}
export interface RecordWorkAuthorizationCompletedRequest
  extends z.infer<typeof RecordWorkAuthorizationCompletedRequestSchema> {}
export type RecordWorkAuthorizationCompletedResponse = z.infer<
  typeof RecordWorkAuthorizationCompletedResponseSchema
>
export interface RecordWorkAuthorizationCompletedHandler
  extends Handler<
    RecordWorkAuthorizationCompletedRequest,
    RecordWorkAuthorizationCompletedResponse
  > {}
