import { AllClaimsInspections } from '@eigtech/ui-estimator/components/Domains/Claims/Views/AllClaimsInspections'
import { createFileRoute } from '@eigtech/ui-shared-router'
import { z } from 'zod'

const SearchSchema = z.object({
  selectedMonth: z.string().optional(),
})

export const Route = createFileRoute('/schedules/inspections/claims')({
  component: AllClaimsInspections,
  validateSearch: SearchSchema.parse,
})
