import z from 'zod'

// based on https://hl7-definition.caristix.com/v2/HL7v2.5.1/Tables/0190
export const JobLocationAddressTypeSchema = z.enum([
  'Firm or Business',
  'Bad Address',
  'Current or Temporary',
  'Home',
  'Legal Address',
  'Mailing',
  'Office',
  'Permanent',
  'Registry Home',
])

export type JobLocationAddressType = z.infer<typeof JobLocationAddressTypeSchema>

export const JobLocationGeoCoordinatesSchema = z.object({
  accuracy: z.enum([
    'ADDRESS',
    'STREET',
    'ZIP',
    'CITY',
    'STATE',
    'COUNTRY',
    'CITY BLOCK',
    'INTERSECTION',
    'ZIP7',
    'ZIP9',
  ]),
  latitude: z.number(),
  longitude: z.number(),
})

export const JobLocationAddressSchema = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().optional(),
  addressLine3: z.string().optional(),
  city: z.optional(z.string()),
  stateOrProvince: z.optional(z.string()),
  zipOrPostalCode: z.optional(z.string()),
  country: z.optional(z.string()),
  addressType: JobLocationAddressTypeSchema.optional(),
  countyOrParishCode: z.optional(z.string()),
})

export const JobLocationSchema = z.object({
  address: JobLocationAddressSchema,
  geoCoordinates: JobLocationGeoCoordinatesSchema.optional(),
})

export interface JobLocation extends z.infer<typeof JobLocationSchema> {}
export interface JobLocationAddress extends z.infer<typeof JobLocationAddressSchema> {}
export interface JobLocationGeoCoordinates
  extends z.infer<typeof JobLocationGeoCoordinatesSchema> {}

export type JobLocationGeoAccuracy = z.infer<
  (typeof JobLocationGeoCoordinatesSchema.shape)['accuracy']
>
