import {
  JobCorn,
  serviceName as restorationServiceName,
  jobResourceType,
} from '@eigtech/restoration-types'
import { getCornKernels, isCorn } from '@eigtech/shared-corn'

export const ensureJobCorn = (jobId: string | JobCorn): JobCorn =>
  isCorn(jobId) ? (jobId as JobCorn) : makeJobCorn(jobId)

export const makeJobCorn = (resourceId: string): JobCorn =>
  `corn:${restorationServiceName}:${jobResourceType}:${resourceId}`

export const isJobCorn = (entityId: string): entityId is JobCorn => {
  if (!isCorn(entityId)) return false

  const { serviceName, resourceType } = getCornKernels(entityId)
  return serviceName === restorationServiceName && resourceType === jobResourceType
}
