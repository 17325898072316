import { useAuth0 } from '@eigtech/ui-shared-auth'
import { useEffect } from 'react'
import { useHeap } from '../utils'

export function useHeapIdentityTracking() {
  const { user } = useAuth0()
  const heap = useHeap()

  // use user's id to track them w/ heap analytics
  useEffect(() => {
    if (!user?.sub || !heap) return

    heap?.identify(user.sub)

    if (!!user.email) {
      heap?.addUserProperties({
        email: user.email,
      })
    }

    if (!!user.name) {
      heap?.addUserProperties({
        name: user.name,
      })
    }
  }, [user, heap])
}
