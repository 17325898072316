import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js'

export const RecordBillSentToCollectionsRequestBodySchema = z.object({
  billCollection: z.object({
    externalId: JobExternalIdSchema.optional(),
    sentDate: z.string().datetime(),
    collectorName: z.string().optional(),
  }),
})
export const RecordBillSentToCollectionsRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordBillSentToCollectionsRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordBillSentToCollectionsRequestSchema =
  RecordBillSentToCollectionsRequestBodySchema.merge(
    RecordBillSentToCollectionsRequestHeadersSchema
  ).merge(RecordBillSentToCollectionsRequestPathSchema)
export const RecordBillSentToCollectionsResponseSchema = z.void()
export const RecordBillSentToCollectionsApiSchema: ApiSchema = {
  body: RecordBillSentToCollectionsRequestBodySchema,
  headers: RecordBillSentToCollectionsRequestHeadersSchema,
  params: RecordBillSentToCollectionsRequestPathSchema,
}

export interface RecordBillSentToCollectionsRequestBody
  extends z.infer<typeof RecordBillSentToCollectionsRequestBodySchema> {}
export interface RecordBillSentToCollectionsRequestPath
  extends z.infer<typeof RecordBillSentToCollectionsRequestPathSchema> {}
export interface RecordBillSentToCollectionsRequest
  extends z.infer<typeof RecordBillSentToCollectionsRequestSchema> {}
export type RecordBillSentToCollectionsResponse = z.infer<
  typeof RecordBillSentToCollectionsResponseSchema
>
export interface RecordBillSentToCollectionsHandler
  extends Handler<RecordBillSentToCollectionsRequest, RecordBillSentToCollectionsResponse> {}
