import { DocumentV2, photoTypeRegex, videoTypeRegex } from '@eigtech/documents-types'
import {
  Button,
  Center,
  chakra,
  ComposedAlert,
  ComposedDrawerWrapperProps,
  Image,
  Tooltip,
  useToast,
  withDrawerErrorBoundary,
  WithDrawerErrorBoundaryProps,
} from '@eigtech/ui-shared-dave'
import { createForm, FormDrawer } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { useGetDocumentUrl } from '../api/getDocumentUrl'
import { UpdateDocumentRequest, useUpdateDocument } from '../api/updateDocument'

export type UpdateDocumentDrawerProps = ComposedDrawerWrapperProps & {
  document: DocumentV2
}

export const UpdateDocumentDrawer = withDrawerErrorBoundary(
  function UpdateDocumentDrawer({ document, ...props }: UpdateDocumentDrawerProps) {
    const { data: url } = useGetDocumentUrl({
      entityId: document.entityId,
      documentId: document.id,
    })

    const form = useForm({
      defaultValues: {
        notes: document?.notes ?? '',
        description: document?.description ?? '',
      },
    })

    const toast = useToast()

    const { mutateAsync: updateDocument, isError } = useUpdateDocument()

    async function handleSubmit(data: UpdateDocumentForm) {
      const payload: UpdateDocumentRequest = {
        entityId: document.entityId,
        documentId: document.id,
        ...data,
      }

      await updateDocument(payload)

      toast({
        status: 'success',
        title: 'Successfully updated document information!',
      })

      props.onClose()
    }

    return (
      <FormDrawer
        {...props}
        {...baseProps}
        form={form}
        title="Update Document Information"
        onSubmit={handleSubmit}
      >
        {!!url && (
          <Tooltip label={document.filename}>
            <Center>
              {document.filename.match(videoTypeRegex) ? (
                <chakra.video src={url} w="full" />
              ) : document.filename?.match(photoTypeRegex) ? (
                <chakra.a href={url} target={document.filename}>
                  <Image maxH="30vh" src={url} />
                </chakra.a>
              ) : (
                <Button as="a" href={url} target={document.filename} variant="outline">
                  Open Document Preview
                </Button>
              )}
            </Center>
          </Tooltip>
        )}

        <InputField label="Title" name="notes" />
        <TextareaField label="Description" name="description" />

        {isError && (
          <ComposedAlert
            alert={{
              title: 'Something went wrong',
              description:
                "We could not update this file's information. Please try again. If the issue persists, please contact EIG Customer Service.",
            }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps: WithDrawerErrorBoundaryProps = {
  title: 'Update Document Information',
}

export const UpdateDocumentSchema = z.object({
  notes: z.string().min(1).max(50, 'String cannot contain more than 50 characters'),
  description: z.string().max(500, 'String cannot contain more than 500 characters'),
})

type UpdateDocumentForm = z.infer<typeof UpdateDocumentSchema>

const { useForm, InputField, TextareaField } = createForm(
  UpdateDocumentSchema,
  'updateDocumentForm'
)
