import { ContactRoleProducer as BaseContactRole } from '@eigtech/contacts-types'
import { Stack } from '@eigtech/ui-shared-dave'
import { useWatch } from '@eigtech/ui-shared-forms'
import { ReactNode } from 'react'
import { getContactRoleLabel } from '../../../utils'
import {
  ContactForm,
  ContactFormArrayField,
  ContactFormContactRole,
  ContactFormContactRoleEnumSchema,
  ContactFormSelectField,
  useContactFormArrayFieldRowContext,
  useContactFormContext,
} from './ContactFormSchema'
import { ContactInfoRowContainer } from './ContactInfoRowContainer'

export function RoleFieldArray(props: { requiredRole: BaseContactRole | undefined }) {
  return (
    <ContactFormArrayField
      RowContainer={(rowProps) => <RowContainer {...rowProps} {...props} />}
      defaultValue={{ role: '' as ContactForm['roles'][number]['role'] }}
      label="Roles"
      min={1}
      name="roles"
      renderRow={() => <RoleField {...props} />}
    />
  )
}

function RowContainer({
  requiredRole,
  ...props
}: { children: ReactNode; index: number } & RoleFieldProps) {
  const { isDisabled } = useRoleField({ requiredRole })

  return <ContactInfoRowContainer {...props} isDisabled={isDisabled} />
}

type RoleFieldProps = { requiredRole: BaseContactRole | undefined }
function RoleField({ requiredRole }: RoleFieldProps) {
  const { index } = useContactFormArrayFieldRowContext()

  const { role, isRequiredRole, isDefaultRole, isDisabled } = useRoleField({ requiredRole })

  return (
    <Stack spacing="2" w="full">
      <ContactFormSelectField
        isDisabled={isDisabled}
        label="Role"
        name={`roles.${index}.role`}
        placeholder={false}
      >
        {isRequiredRole && requiredRole ? (
          <option value={requiredRole}>{getContactRoleLabel(requiredRole)}</option>
        ) : isDefaultRole ? (
          <option value={role}>{getContactRoleLabel(role)}</option>
        ) : (
          <>
            <option disabled value="">
              Select Role
            </option>
            {ContactFormContactRoleEnumSchema.options.map((role) => (
              <option key={role} value={role}>
                {getContactRoleLabel(role)}
              </option>
            ))}
          </>
        )}
      </ContactFormSelectField>
    </Stack>
  )
}

function useRoleField({ requiredRole }: RoleFieldProps) {
  const { control } = useContactFormContext()
  const { index } = useContactFormArrayFieldRowContext()

  // const isFirst = index === 0

  const role = useWatch({ control, name: `roles.${index}.role` })
  const isRequiredRole = !!requiredRole && role === requiredRole
  const isDefaultRole =
    !!role && !ContactFormContactRoleEnumSchema.options.includes(role as ContactFormContactRole)

  const isDisabled = isRequiredRole || isDefaultRole

  return { role, isRequiredRole, isDefaultRole, isDisabled }
}
