import { CohortEnum } from '@eigtech/auth0-types'
import { Contact, ContactSchema } from '@eigtech/contacts-types'
import { getContactEmailAddress, getContactPhoneNumber } from '@eigtech/contacts-util'
import { MeetingInvite } from '@eigtech/meetings-types'
import { useCohortContext } from '@eigtech/ui-shared-api'
import { getContactName } from '@eigtech/ui-shared-contacts'
import {
  Button,
  ButtonProps,
  ComposedAlert,
  ComposedDrawerWrapperProps,
  HStack,
  SimpleGrid,
  Stack,
  WithDrawerErrorBoundaryProps,
  chakra,
  useDisclosure,
  useFormControlContext,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import {
  ArrayFieldRemoveButton,
  FormDrawer,
  FormFieldControl,
  createForm,
} from '@eigtech/ui-shared-forms'
import { pick } from 'lodash-es'
import { ReactNode } from 'react'
import { z } from 'zod'
import { useInviteToMeeting } from '../../../api'
import { MeetingState, useMeetingStore } from '../../../context'

type BaseInviteDrawerProps = Pick<MeetingInvite, 'from' | 'meetingDetails'> & {
  contacts: Contact[]
}

export type InviteDrawerProps = ComposedDrawerWrapperProps & BaseInviteDrawerProps

export const InviteDrawer = withDrawerErrorBoundary(
  function InviteDrawer({ contacts, from, meetingDetails, ...drawerProps }: InviteDrawerProps) {
    const contactsWithPhoneOrEmail = contacts
      .map((contact) => ({
        ...contact,
        email: getContactEmailAddress(contact),
        phone: getContactPhoneNumber(contact),
      }))
      .filter((contact): contact is ContactWithPhoneOrEmail => !!(contact.email || contact.phone))

    const form = useForm({
      defaultValues: {
        contacts: [],
        guests: [{ name: '', email: '' }],
        custom: '',
      },
    })

    const toast = useToast()
    const { mutateAsync: inviteToMeeting, isError } = useInviteToMeeting()

    async function onSubmit(data: InviteForm) {
      const to: MeetingInvite['to'] = [
        ...data.guests,
        ...data.contacts.map((contact) => ({
          name: getContactName(contact),
          email: contact.email,
          phone: contact.phone,
          auth0Id: contact.externalSystemIds?.auth0,
        })),
      ].filter(({ email, name, phone }) => !!((email || phone) && name))

      await inviteToMeeting({
        entityId: meetingDetails.entityId,
        meetingId: meetingDetails.meetingId,
        from,
        to,
      })

      toast({
        status: 'success',
        title: 'Meeting invites have been sent!',
      })

      drawerProps.onClose()
    }

    return (
      <FormDrawer {...drawerProps} {...baseProps} form={form} onSubmit={onSubmit}>
        {isError && (
          <ComposedAlert
            alert={{
              title: 'Something went wrong',
              description:
                'Meeting invites could not be send. Please try again. If the issue persists, contact EIG customer service.',
            }}
            status="error"
          />
        )}

        {!!contactsWithPhoneOrEmail.length ? (
          <ReactSelectField
            getOptionLabel={(option) => getContactName(option)}
            getOptionValue={(option) => option.contactId}
            isMulti
            label="Contacts To Invite"
            name="contacts"
            options={contactsWithPhoneOrEmail}
          />
        ) : (
          <ComposedAlert alert="No contacts available to select for invitation." status="warning" />
        )}

        <ArrayField
          RowContainer={Row}
          label="Guests To Invite"
          name="guests"
          renderRow={(field) => (
            <Stack
              borderLeft="2px"
              borderLeftColor="gray.200"
              borderLeftRadius="md"
              px="2"
              py="2"
              w="full"
            >
              <InputField label="Name" name={`${field.name}.name`} />
              <SimpleGrid columns={2} spacing="2">
                <InputField label="Email" name={`${field.name}.email`} type="email" />
                <MaskedInputField label="Phone" mask="000-000-0000" name={`${field.name}.phone`} />
              </SimpleGrid>
            </Stack>
          )}
        />

        {/* Displays the custom error messages; the input will be hidden but the error message will be displayed */}
        <FormFieldControl name="custom" />
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps: WithDrawerErrorBoundaryProps = { title: 'Invite Form' }

function Row({ children, index }: { children: ReactNode; index: number }) {
  const { id } = useFormControlContext()
  const rowId = `${id}-row-${index}`

  return (
    <HStack alignItems="flex-start" id={rowId}>
      {children}
      <chakra.div pt="10">
        <ArrayFieldRemoveButton />
      </chakra.div>
    </HStack>
  )
}

export function InviteButtonControl(props: Omit<ButtonProps, 'onClick'>) {
  const { cohort } = useCohortContext()
  const { invitableContacts, meetingDetails, participant } = useMeetingStore(stateSelector)

  const canInvite = cohort !== 'insured'

  if (!(canInvite && meetingDetails && participant)) return null

  return (
    <InviteButtonControlInner
      {...props}
      contacts={invitableContacts ?? []}
      from={{
        name: participant.name,
        auth0Id: participant.auth0Id,
        role: cohortLabel[cohort],
      }}
      meetingDetails={meetingDetails}
    />
  )
}

function InviteButtonControlInner({
  contacts,
  from,
  meetingDetails,
  ...props
}: BaseInviteDrawerProps & Omit<ButtonProps, 'onClick'>) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button {...props} onClick={onOpen}>
        Send Invites
      </Button>

      {isOpen && (
        <InviteDrawer
          contacts={contacts}
          from={from}
          isOpen={isOpen}
          meetingDetails={meetingDetails}
          onClose={onClose}
        />
      )}
    </>
  )
}

const stateSelector = (state: MeetingState) =>
  pick(state, ['cohort', 'invitableContacts', 'meetingDetails', 'participant'])

const cohortLabel: Record<CohortEnum, string> = {
  csr: 'Coordinator',
  estimator: 'Estimator',
  insured: 'Client', // this should never be used - it's only here to appease typescript
  super: 'Admin',
  technician: 'Technician',
}

const InviteFormSchema = z
  .object({
    contacts: z.array(
      ContactSchema.and(z.object({ email: z.string().optional(), phone: z.string().optional() }))
    ),
    guests: z.array(
      z
        .object({
          email: z.string().optional(),
          name: z.string(),
          phone: z.string().optional(),
        })
        // if name is set, email must also be set
        .refine(({ name, email, phone }) => !name || !!email || !!phone, {
          message: 'Email or phone is required',
          path: ['email', 'phone'],
        })
        // if email is set, name must also be set
        .refine(({ name, email }) => !email || !!name, {
          message: 'Name is required',
          path: ['name'],
        })
    ),
    custom: z.string(),
  })
  .refine(
    ({ contacts, guests }) => {
      const filteredGuests = guests.filter(({ name, email, phone }) => !!(name && (email || phone)))
      return !!(contacts.length || filteredGuests.length)
    },
    { message: 'At least one contact or guest should be selected to invite', path: ['custom'] }
  )

type ContactWithPhoneOrEmail = Contact & { email: string; phone: string }

type InviteForm = z.infer<typeof InviteFormSchema>

const { useForm, ReactSelectField, InputField, MaskedInputField, ArrayField } = createForm(
  InviteFormSchema,
  'inviteForm'
)
