import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, InboxMessageIdSchema } from '../../base'

export const ArchiveInboxMessageRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const ArchiveInboxMessageRequestPathSchema = z.object({
  inboxId: Auth0IdSchema,
  messageId: InboxMessageIdSchema,
})

export const ArchiveInboxMessageRequestSchema = ArchiveInboxMessageRequestPathSchema.merge(
  ArchiveInboxMessageRequestHeaderSchema
)

export type ArchiveInboxMessageRequestPath = z.infer<typeof ArchiveInboxMessageRequestPathSchema>
export type ArchiveInboxMessageRequest = z.infer<typeof ArchiveInboxMessageRequestSchema>

export const ArchiveInboxMessageResponseSchema = z.void()
export type ArchiveInboxMessageResponse = z.infer<typeof ArchiveInboxMessageResponseSchema>

export interface ArchiveInboxMessageHandler
  extends Handler<ArchiveInboxMessageRequest, ArchiveInboxMessageResponse> {}
