import { UpdateMeetingRequest, UpdateMeetingResponse } from '@eigtech/meetings-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { meetingsBasePath } from './constants'
import { useInvalidateMeetingsDetails } from './getMeeting'
import { useInvalidateListMeetings } from './listMeetings'

const updateMeeting = ({ entityId, meetingId, startTime, description }: UpdateMeetingRequest) =>
  contextualPost<UpdateMeetingResponse>(
    `${meetingsBasePath}/meeting/update-meeting/${entityId}/${startTime}/${meetingId}`,
    { description }
  )

// start meeting
export function useUpdateMeeting() {
  const invalidateMeetingDetails = useInvalidateMeetingsDetails()
  const invalidateListMeetings = useInvalidateListMeetings()

  return useMutation({
    mutationFn: updateMeeting,
    onSuccess(__, { entityId, meetingId }) {
      invalidateMeetingDetails({ entityId, meetingId })
      invalidateListMeetings({ entityId })
    },
  })
}
