import { mapboxAccessToken } from '@eigtech/ui-estimator/lib/constants'
import { apiInstanceFactory } from '@eigtech/ui-shared-api'

export const apiInstance = apiInstanceFactory({
  setAccessToken: true,
  prefixUrl: import.meta.env.VITE_API_URL,
})

const { instance: cinnamonApi, getJson: get, request, patch, post, put, remove } = apiInstance

export { cinnamonApi, get, patch, post, put, remove, request }

// mapbox:

export const mapboxBaseUrl = 'https://api.mapbox.com/geocoding/v5'

export const mapboxInstance = apiInstanceFactory({
  setAccessToken: false,
  prefixUrl: mapboxBaseUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        const url = new URL(request.url)
        url.searchParams.set('access_token', mapboxAccessToken)

        const updatedRequest = new Request(url.href, request)

        return updatedRequest
      },
    ],
  },
})
