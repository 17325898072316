import { Button, ButtonProps } from '@chakra-ui/react'
import { ComposedModal, ComposedModalProps } from './ComposedModal'

export type ConfirmModalProps = Omit<
  ComposedModalProps,
  'closeLabel' | 'footerCloseButtonProps'
> & {
  cancelLabel?: ComposedModalProps['closeLabel']
  cancelProps?: Omit<ComposedModalProps['closeButtonProps'], 'children'>
  confirmLabel?: string
  confirmProps?: Omit<ButtonProps, 'children'>
  isPending?: boolean
  onCancel?: () => any
  onConfirm: () => any | Promise<any>
}

export function ConfirmModal({
  cancelLabel,
  cancelProps,
  closeButtonProps,
  confirmProps,
  confirmLabel,
  footerActionsRight,
  isPending,
  onCancel,
  onConfirm,
  onClose,
  ...modalProps
}: ConfirmModalProps) {
  return (
    <ComposedModal
      closeButtonProps={{ isDisabled: isPending, ...closeButtonProps }}
      closeLabel={cancelLabel}
      footerActionsRight={
        <>
          {footerActionsRight}
          <Button
            id={modalProps.id ? `${modalProps.id}_confirm` : undefined}
            isLoading={isPending}
            {...confirmProps}
            onClick={async () => {
              await onConfirm()
              onClose()
            }}
          >
            {confirmLabel ?? 'Confirm'}
          </Button>
        </>
      }
      footerCloseButtonProps={{ isDisabled: isPending, ...cancelProps }}
      onClose={() => {
        if (isPending) return

        onCancel?.()
        onClose()
      }}
      {...modalProps}
    />
  )
}
