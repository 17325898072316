import { Handler } from 'aws-lambda'
import z from 'zod'

import {
  PortalConsumerSchema,
  PortalEntityTypeSchema,
  PortalZoneSchema,
} from '../../base/portal.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const ListEntityPortalsRequestPathSchema = z.object({
  entityId: z.string(),
  entityType: PortalEntityTypeSchema,
})

export const ListEntityPortalsRequestQueryStringSchema = z.object({
  zone: PortalZoneSchema.optional(),
})

export const ListEntityPortalsRequestSchema = ListEntityPortalsRequestPathSchema.merge(
  ListEntityPortalsRequestQueryStringSchema
)

export const ListEntityPortalSchema = PortalConsumerSchema
export const ListEntityPortalsResponseSchema = z.object({
  portals: z.array(ListEntityPortalSchema),
})

export type ListEntityPortalsRequestPath = z.infer<typeof ListEntityPortalsRequestPathSchema>
export type ListEntityPortalsRequest = z.infer<typeof ListEntityPortalsRequestSchema>
export type ListEntityPortalsResponse = z.infer<typeof ListEntityPortalsResponseSchema>
export type ListEntityPortal = z.infer<typeof ListEntityPortalSchema>

export interface ListEntityPortalsHandler
  extends Handler<ListEntityPortalsRequest, ListEntityPortalsResponse> {}

export const ListEntityPortalsApiSchema: ApiSchema = {
  params: ListEntityPortalsRequestPathSchema,
  query: ListEntityPortalsRequestQueryStringSchema,
  response: ListEntityPortalsResponseSchema,
}
