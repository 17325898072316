import {
  Box,
  Button,
  FormControl,
  FormLabel,
  forwardRef,
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { Column, RowData } from '@tanstack/react-table'
import { ChangeEventHandler, useState } from 'react'
import { MdAdd as AddIcon, MdEdit as EditIcon, MdFilterAlt as FilterIcon } from 'react-icons/md'
import { useDataGridContext } from '../../DataGridContext'
import { FilterFnName, WithColumnProps } from '../../types'
import { getColumnTitle, getFilterActualName } from '../utils'
import { DataGridArrayFilter } from './DataGridArrayFilter'
import { DataGridDateFilter, DataGridDateTimeFilter } from './DataGridDateFilter'
import { DataGridNumberRangeFilter } from './DataGridNumberRangeFilter'
import { DataGridTextFilter } from './DataGridTextFilter'

export const DataGridFiltersForm = forwardRef<MenuButtonProps, 'button'>((props, ref) => {
  const { table } = useDataGridContext()

  const filterableColumns = table.getAllLeafColumns().filter((column) => column.getCanFilter())

  const [currentColumnId, setCurrentColumnId] = useState<string | null>(null)

  const currentColumn = filterableColumns.find((column) => column.id === currentColumnId)

  return (
    <>
      <Menu isLazy>
        <MenuButton
          ref={ref}
          aria-label="Add table filter."
          as={IconButton}
          icon={
            <>
              <Tooltip label="Add table filter">
                <Box bottom="0" left="0" position="absolute" right="0" top="0"></Box>
              </Tooltip>
              <FilterIcon />
            </>
          }
          {...props}
        />
        <MenuList shadow="md">
          {filterableColumns.map((column) => (
            <MenuItem
              key={column.id}
              icon={column.getIsFiltered() ? <EditIcon /> : <AddIcon />}
              onClick={() => setCurrentColumnId(column.id)}
            >
              {getColumnTitle(column)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {!!currentColumn && (
        <FilterModal column={currentColumn} onClose={() => setCurrentColumnId(null)} />
      )}
    </>
  )
})

function FilterModal({
  column,
  onClose,
}: {
  column: Column<unknown, unknown>
  onClose: () => void
}) {
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{column.getIsFiltered() ? 'Edit' : 'Add'} Filter</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FilterForm column={column} />
        </ModalBody>

        <ModalFooter>
          <Button mr={2} variant="outline" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function FilterForm<TData extends RowData, TValue>({ column }: WithColumnProps<TData, TValue>) {
  const instance = useDataGridContext<TData>()

  const { availableFilterFns = [], onFilterFnChange, FilterComponent } = column.columnDef.meta ?? {}

  const { FilterComponents = {} } = instance.table.options.meta ?? {}
  const filterName = column.getFilterFn()?.name ?? ''
  const GlobalFilterComponent =
    FilterComponents[getFilterActualName(filterName)] ?? FilterComponents[filterName]

  let Filter: JSX.Element

  if (!!FilterComponent) {
    Filter = <FilterComponent {...{ column, instance }} />
  } else if (!!GlobalFilterComponent) {
    Filter = <GlobalFilterComponent {...{ column, instance }} />
  } else {
    Filter = getFilterFnComponent(filterName, column)
  }

  const handleFilterFnChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (!onFilterFnChange) return

    const index = parseInt(event.target.value)

    if (!(isFinite(index) && availableFilterFns[index])) {
      log.warn('invalid function option', {
        value: event.target.value,
        parsed: index,
        availableFilterFns,
      })
      return
    }

    const fn = availableFilterFns[index]

    const normalizedFn = typeof fn === 'string' || typeof fn === 'function' ? fn : fn.value

    onFilterFnChange(normalizedFn)
  }

  return (
    <Stack>
      {availableFilterFns.length > 1 && (
        <FormControl>
          <FormLabel fontSize="sm">Available Filters</FormLabel>
          <Select
            value={availableFilterFns.findIndex((fn) => {
              const columnFilter = column.getFilterFn()?.name
              const availableFilter = typeof fn !== 'string' && 'value' in fn ? fn.value : fn
              return columnFilter === getFilterActualName(availableFilter)
            })}
            onChange={handleFilterFnChange}
          >
            {availableFilterFns.map((fn, index) => {
              const fnName =
                typeof fn === 'string' || typeof fn === 'function' ? fn.toString() : fn.label

              return (
                <option key={fnName} value={index}>
                  {fnName}
                </option>
              )
            })}
          </Select>
        </FormControl>
      )}
      <Box>{Filter}</Box>
    </Stack>
  )
}

function getFilterFnComponent<TData, TValue>(filterName: string, column: Column<TData, TValue>) {
  const notImplemented = <Text>Filter not implemented for: {filterName}</Text>

  const components: Record<FilterFnName, JSX.Element> = {
    arrIncludes: <DataGridArrayFilter column={column} type="one" />,
    arrIncludesAll: <DataGridArrayFilter column={column} type="all" />,
    arrIncludesSome: <DataGridArrayFilter column={column} type="some" />,
    equals: <DataGridTextFilter column={column} />,
    equalsString: <DataGridTextFilter column={column} />,
    inNumberRange: <DataGridNumberRangeFilter column={column} />,
    includesString: <DataGridTextFilter column={column} />,
    includesStringSensitive: <DataGridTextFilter column={column} />,
    weakEquals: <DataGridTextFilter column={column} />,
    dateEquals: <DataGridDateFilter column={column} />,
    dateGreaterThan: <DataGridDateFilter column={column} />,
    dateGreaterEqualThan: <DataGridDateFilter column={column} />,
    dateLesserThan: <DataGridDateFilter column={column} />,
    dateLesserEqualThan: <DataGridDateFilter column={column} />,
    dateTimeEquals: <DataGridDateTimeFilter column={column} />,
    dateTimeGreaterThan: <DataGridDateTimeFilter column={column} />,
    dateTimeGreaterEqualThan: <DataGridDateTimeFilter column={column} />,
    dateTimeLesserThan: <DataGridDateTimeFilter column={column} />,
    dateTimeLesserEqualThan: <DataGridDateTimeFilter column={column} />,
  }

  const filterActualName = getFilterActualName(filterName)

  return components[filterActualName] ?? notImplemented
}
