import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema } from '../../../base/books.js'
import { ContactCornSchema } from '../../../base/contacts.js'

export const RemoveContactFromBookRequestPathParametersSchema = z.object({
  bookId: BookCornSchema,
})
export const RemoveContactFromBookRequestBodySchema = z.object({
  contactId: ContactCornSchema,
})
export const RemoveContactFromBookRequestSchema =
  RemoveContactFromBookRequestPathParametersSchema.merge(RemoveContactFromBookRequestBodySchema)

export type RemoveContactFromBookRequestPathParameters = z.infer<
  typeof RemoveContactFromBookRequestPathParametersSchema
>
export type RemoveContactFromBookRequestBody = z.infer<
  typeof RemoveContactFromBookRequestBodySchema
>
export type RemoveContactFromBookRequest = z.infer<typeof RemoveContactFromBookRequestSchema>

export type RemoveContactFromBookResponse = void

export interface RemoveContactFromBookHandler
  extends Handler<RemoveContactFromBookRequest, RemoveContactFromBookResponse> {}

export const RemoveContactFromBookApiSchema: ApiSchema = {
  body: RemoveContactFromBookRequestBodySchema,
  params: RemoveContactFromBookRequestPathParametersSchema,
}
