import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobIdSchema } from '../../base/ids.js'

export const ReopenJobRequestBodySchema = z.object({
  notes: z.string().optional(),
  reopenedDate: z.string().datetime(),
})
export const ReopenJobRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const ReopenJobRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const ReopenJobRequestSchema = ReopenJobRequestBodySchema.merge(
  ReopenJobRequestHeadersSchema
).merge(ReopenJobRequestPathSchema)
export const ReopenJobResponseSchema = z.void()
export const ReopenJobApiSchema: ApiSchema = {
  body: ReopenJobRequestBodySchema,
  headers: ReopenJobRequestHeadersSchema,
  params: ReopenJobRequestPathSchema,
}

export type ReopenJobRequestBody = z.infer<typeof ReopenJobRequestBodySchema>
export interface ReopenJobRequest extends z.infer<typeof ReopenJobRequestSchema> {}
export type ReopenJobResponse = z.infer<typeof ReopenJobResponseSchema>
export interface ReopenJobHandler extends Handler<ReopenJobRequest, ReopenJobResponse> {}
