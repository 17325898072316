import { UserPreferences } from '@eigtech/ui-estimator/types/UserPreferences'
import { PutUserPreferencesBodyRequest } from '@eigtech/user-preferences-types'
import { put } from '../instance'
import { preferencesBasePath, preferencesUi, userQueryKeys } from './constants'
import { getUserPreferences } from './getPreferences'
import { usePutPreferences } from './helpers'

export const putUserPreferences = async (payload: UserPreferences) =>
  put<UserPreferences, PutUserPreferencesBodyRequest<UserPreferences>>(`${preferencesBasePath}/`, {
    ui: preferencesUi,
    preferences: payload,
  })

export function mergeUserPreferences(
  oldPreferences: UserPreferences,
  newPreferences: Partial<UserPreferences>
): UserPreferences {
  return {
    ...oldPreferences,
    ...newPreferences,
  }
}

export const usePutUserPreferences = () =>
  usePutPreferences({
    queryKey: userQueryKeys.preferences(),
    queryFunction: getUserPreferences,
    mergeFunction: mergeUserPreferences,
    mutationFunction: putUserPreferences,
  })
