import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model'

export const UpdateLossDescriptionSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    lossDescription: z.string(),
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
  response: z.object({
    claimNumber: ClaimNumberSchema,
    lossDetails: z.object({
      lossDescription: z.string(),
    }),
  }),
}

export const UpdateLossDescriptionRequestSchema = UpdateLossDescriptionSchemas.params
  .merge(UpdateLossDescriptionSchemas.body)
  .merge(UpdateLossDescriptionSchemas.headers)

export type UpdateLossDescriptionBodyRequest = z.infer<typeof UpdateLossDescriptionSchemas.body>
export type UpdateLossDescriptionHeaderRequest = z.infer<
  typeof UpdateLossDescriptionSchemas.headers
>
export type UpdateLossDescriptionRequest = z.infer<typeof UpdateLossDescriptionRequestSchema>
export type UpdateLossDescriptionResponse = z.infer<typeof UpdateLossDescriptionSchemas.response>

export interface UpdateLossDescriptionHandler
  extends Handler<UpdateLossDescriptionRequest, UpdateLossDescriptionResponse> {}

export const UpdateLossDescriptionApiSchema: ApiSchema = UpdateLossDescriptionSchemas
