import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ScreenshotSchema } from '../base/screenshot.js'

export const ListMeetingScreenshotsRequestPathSchema = z.object({
  entityId: CornSchema,
  startTime: z.string().datetime(),
})

export const ListMeetingScreenshotsRequestHeaderSchema = ApiLambdalerAuthHeaderSchema

export const ListMeetingScreenshotsRequestSchema = ListMeetingScreenshotsRequestPathSchema.merge(
  ListMeetingScreenshotsRequestHeaderSchema
)

export const ListMeetingScreenshotsResponseSchema = z.array(ScreenshotSchema)

export type ListMeetingScreenshotsRequestPath = z.infer<
  typeof ListMeetingScreenshotsRequestPathSchema
>
export type ListMeetingScreenshotsRequest = z.infer<typeof ListMeetingScreenshotsRequestSchema>
export type ListMeetingScreenshotsResponse = z.infer<typeof ListMeetingScreenshotsResponseSchema>

export interface ListMeetingScreenshotsHandler
  extends Handler<ListMeetingScreenshotsRequest, ListMeetingScreenshotsResponse> {}
