import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ClaimStatusLaxSchema } from '../../aggregates/claimStatus.js'

export const UpsertClaimStatusRequestBodySchema = ClaimStatusLaxSchema.omit({ id: true })
export const UpsertClaimStatusRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const SetStatusRequestSchema = UpsertClaimStatusRequestBodySchema.merge(
  UpsertClaimStatusRequestHeaderSchema
)
export const UpsertClaimStatusResponseSchema = z.void()

export type UpsertClaimStatusRequestBody = z.infer<typeof UpsertClaimStatusRequestBodySchema>

export type UpsertClaimStatusRequest = z.infer<typeof SetStatusRequestSchema>
export type UpsertClaimStatusResponse = z.infer<typeof UpsertClaimStatusResponseSchema>

export interface UpsertClaimStatusHandler
  extends Handler<UpsertClaimStatusRequest, UpsertClaimStatusResponse> {}
