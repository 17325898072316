import { InspectionDetailsSchema } from '@eigtech/claims-v2-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const GetInspectionsApiSchema = {
  response: z.object({
    inspections: InspectionDetailsSchema.array(),
  }),
  query: z.object({ filter: z.string().optional() }),
} satisfies ApiSchema

export const GetInspectionsInternalApiSchema = {
  response: GetInspectionsApiSchema.response,
  query: GetInspectionsApiSchema.query,
}

export const GetInspectionsRequestSchema = GetInspectionsApiSchema.query

export const GetInspectionsResponseSchema = GetInspectionsApiSchema.response

export type GetInspectionsRequest = z.infer<typeof GetInspectionsRequestSchema>
export type GetInspectionsInternalRequest = void
export type GetInspectionsResponse = z.infer<typeof GetInspectionsResponseSchema>
export interface GetInspectionsHandler
  extends Handler<GetInspectionsRequest, GetInspectionsResponse> {}
export interface GetInspectionsInternalHandler
  extends Handler<GetInspectionsInternalRequest, GetInspectionsResponse> {}
