import { Contact } from '@eigtech/contacts-types'
import { Attendee, Meeting, MeetingDetails, Participant } from '@eigtech/meetings-types'
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react'
import { ReactNode, createContext, useContext, useRef } from 'react'
import type { SetNonNullable } from 'type-fest'
import { StoreApi, createStore, useStore } from 'zustand'
import { ConfirmLeave } from '../components/ConfirmLeave'
import { WakeLockHandler } from './WakeLockHandler'

export type MeetingState = {
  canScreenshot: boolean
  invitableContacts: Contact[]
  selectedSpeaker: string | null
  setSelectedSpeaker: (chimeId: string | null) => void

  onLeaveMeeting?: () => void

  resetMeetingInformation: () => void
  setMeetingInformation: (info: {
    chimeMeeting: Meeting
    canScreenshot?: boolean
    chimeAttendee: Attendee
    invitableContacts?: Contact[]
    meetingDetails: MeetingDetails
    participant: Participant
  }) => void
} & (
  | {
      chimeAttendee: null
      chimeMeeting: null
      meetingDetails: null
      participant: null
    }
  | {
      chimeAttendee: Attendee
      chimeMeeting: Meeting
      meetingDetails: MeetingDetails
      participant: Participant
    }
)

export type InitialMeetingState = {
  canScreenshot?: boolean
}

export function createMeetingStore(initialState: InitialMeetingState) {
  return createStore<MeetingState>()((set) => ({
    canScreenshot: false,
    ...initialState,
    chimeAttendee: null,
    chimeMeeting: null,
    invitableContacts: [],
    meetingDetails: null,
    participant: null,
    selectedSpeaker: null,
    setSelectedSpeaker: (chimeId) => set(() => ({ selectedSpeaker: chimeId })),
    setMeetingInformation: (info) => set(() => ({ ...info })),
    resetMeetingInformation: () =>
      set(() => ({
        chimeAttendee: null,
        chimeMeeting: null,
        invitableContacts: [],
        meetingDetails: null,
        participant: null,
      })),
  }))
}

export type MeetingStore = ReturnType<typeof createMeetingStore>

const MeetingStateContext = createContext<MeetingStore | undefined>(undefined)

export type MeetingStateProviderProps = InitialMeetingState

export function MeetingStateProvider({
  children,
  ...initialState
}: MeetingStateProviderProps & { children: ReactNode }) {
  const store = useRef(createMeetingStore(initialState))

  return (
    // @ts-ignore
    <MeetingProvider>
      <MeetingStateContext.Provider value={store.current}>
        <ConfirmLeave />
        <WakeLockHandler />
        {children}
      </MeetingStateContext.Provider>
    </MeetingProvider>
  )
}

export const MeetingStateConsumer = MeetingStateContext.Consumer

export function useMeetingStateContext() {
  const context = useContext(MeetingStateContext)

  if (context === undefined) {
    throw new Error('useMeetingStateContext must be used within a MeetingStateProvider')
  }

  return context
}

export function useMeetingStore<T>(
  selector: (state: MeetingState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useMeetingStateContext()

  return useStore(store, selector, equalityFn)
}

/**
 * This function should only be used if you know `setMeetingInformation`
 * has been called. This is safe to use in components that are a child of
 * the ChimeMeeting component.
 */
export function useMeetingStoreDangerously<T>(
  selector: (state: NonNullMeetingState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useMeetingStateContext()

  return useStore(store as StoreApi<NonNullMeetingState>, selector, equalityFn)
}
export type NonNullMeetingState = SetNonNullable<MeetingState, keyof MeetingState>
