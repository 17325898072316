import z from 'zod'
import { Handler } from 'aws-lambda'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { DocumentCornSchema } from '@eigtech/documents-types'

export const ImportDocumentRequestBodySchema = z.object({
  claimNumber: z.string().optional(),
  description: z.string(),
  documentId: DocumentCornSchema,
  fileName: z.string(),
  mimeType: z.string(),
  s3Location: z.object({ bucket: z.string(), key: z.string() }),
  sendToXM8: z.boolean().optional(),
  transactionId: z.string().optional(),
})

export const ImportDocumentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
}).required({ requestActor: true })

export const ImportDocumentRequestSchema = ImportDocumentRequestBodySchema.merge(
  ImportDocumentRequestHeadersSchema
)

export type ImportDocumentRequest = z.infer<typeof ImportDocumentRequestSchema>
export type ImportDocumentResponse = void

export type ImportDocumentHandler = Handler<ImportDocumentRequest, ImportDocumentResponse>

export const ImportDocumentApiSchema: ApiSchema = {
  body: ImportDocumentRequestBodySchema,
  headers: ImportDocumentRequestHeadersSchema,
}
