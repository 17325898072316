import {
  Locale,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  getYear,
  startOfMonth,
  subMonths,
} from '@eigtech/ui-shared-dates'
import { Button, HStack, Text } from '@eigtech/ui-shared-dave'
import { ReactNode, useMemo } from 'react'
import { MonthlyCalendarContext, useMonthlyCalendar } from './MonthlyCalendarContext'

export type MonthlyCalendarProps<DayData> = {
  locale?: Locale
  children: ReactNode
  selectedMonth: Date
  onSelectedMonthChange: (date: Date) => any
  events: (DayData & { date: Date })[]
}

export function MonthlyCalendar<DayData>({
  locale,
  selectedMonth,
  onSelectedMonthChange,
  children,
  events: propEvents,
}: MonthlyCalendarProps<DayData>) {
  const monthStart = startOfMonth(selectedMonth)
  const days = eachDayOfInterval({
    start: monthStart,
    end: endOfMonth(monthStart),
  })

  const events = useMemo(
    () => [...propEvents].sort((a, b) => a.date.getTime() - b.date.getTime()),
    [propEvents]
  )

  return (
    <MonthlyCalendarContext.Provider
      value={{
        days,
        locale,
        onSelectedMonthChange,
        selectedMonth: monthStart,
        events,
      }}
    >
      {children}
    </MonthlyCalendarContext.Provider>
  )
}

export function MonthlyNav() {
  const { locale, selectedMonth, onSelectedMonthChange } = useMonthlyCalendar()

  return (
    <HStack justifyContent="flex-end" mb="2" spacing="6">
      <Button
        size="sm"
        variant="outline"
        onClick={() => onSelectedMonthChange(subMonths(selectedMonth, 1))}
      >
        Previous
      </Button>

      <Text fontWeight="bold" textAlign="center" title="Current Month">
        {format(
          selectedMonth,
          getYear(selectedMonth) === getYear(new Date()) ? 'LLLL' : 'LLLL yyyy',
          { locale }
        )}
      </Text>

      <Button
        size="sm"
        variant="outline"
        onClick={() => onSelectedMonthChange(addMonths(selectedMonth, 1))}
      >
        Next
      </Button>
    </HStack>
  )
}
