import { z } from 'zod'
import { LossDetailsSchema } from '../../aggregates/lossDetails.js'
import { Handler } from 'aws-lambda'

// Create Loss Details
export const CreateLossDetailsRequestBodySchema = LossDetailsSchema.omit({ id: true })

export const CreateLossDetailsRequestSchema = CreateLossDetailsRequestBodySchema

export const CreateLossDetailsResponseSchema = z.object({
  id: LossDetailsSchema.shape.id,
})

export type CreateLossDetailsRequestBody = z.infer<typeof CreateLossDetailsRequestBodySchema>
export type CreateLossDetailsRequest = z.infer<typeof CreateLossDetailsRequestSchema>
export type CreateLossDetailsResponse = z.infer<typeof CreateLossDetailsResponseSchema>

export interface CreateLossDetailsHandler
  extends Handler<CreateLossDetailsRequest, CreateLossDetailsResponse> {}

// Update Loss Details
export const UpdateLossDetailsRequestBodySchema = LossDetailsSchema.omit({
  id: true,
  claimNumber: true,
}).partial()

export const UpdateLossDetailsRequestPathSchema = z.object({
  claimNumber: z.string(),
})

export const UpdateLossDetailsRequestSchema = UpdateLossDetailsRequestBodySchema.merge(
  UpdateLossDetailsRequestPathSchema
)
export const UpdateLossDetailsResponseSchema = z.void()

export type UpdateLossDetailsRequest = z.infer<typeof UpdateLossDetailsRequestSchema>
export type UpdateLossDetailsRequestBody = z.infer<typeof UpdateLossDetailsRequestBodySchema>
export type UpdateLossDetailsRequestPath = z.infer<typeof UpdateLossDetailsRequestPathSchema>
export type UpdateLossDetailsResponse = z.infer<typeof UpdateLossDetailsResponseSchema>

export interface UpdateLossDetailsHandler
  extends Handler<UpdateLossDetailsRequest, UpdateLossDetailsResponse> {}

// Delete Loss Details
export const DeleteLossDetailsRequestBodySchema = z.void()

export const DeleteLossDetailsRequestPathSchema = z.object({
  lossDetailsId: LossDetailsSchema.shape.id,
})

export const DeleteLossDetailsRequestSchema = DeleteLossDetailsRequestPathSchema

export const DeleteLossDetailsResponseSchema = z.void()

export type DeleteLossDetailsRequestBody = z.infer<typeof DeleteLossDetailsRequestBodySchema>
export type DeleteLossDetailsRequestPath = z.infer<typeof DeleteLossDetailsRequestPathSchema>
export type DeleteLossDetailsRequest = z.infer<typeof DeleteLossDetailsRequestSchema>
export type DeleteLossDetailsResponse = z.infer<typeof DeleteLossDetailsResponseSchema>

export interface DeleteLossDetailsHandler
  extends Handler<DeleteLossDetailsRequest, DeleteLossDetailsResponse> {}
