import { UpdateMeetingRequestBody } from '@eigtech/meetings-types'
import { isJobCorn, isJobId } from '@eigtech/restoration-types'
import { getJobLabelFromId } from '@eigtech/restoration-util'
import { Corn, getCornKernels } from '@eigtech/shared-corn'
import { STANDARD_DATE_TIME_FORMAT, formatStringAsDate } from '@eigtech/ui-shared-dates'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  WithDrawerErrorBoundaryProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { useUpdateMeeting } from '../../api/updateMeeting'

export type UpdateMeetingFormDrawerProps = ComposedDrawerWrapperProps & {
  entityId: Corn
  startTime: string
  meetingId: string
  description?: string
}

export const UpdateMeetingFormDrawer = withDrawerErrorBoundary(
  function UpdateMeetingFormDrawer(props: UpdateMeetingFormDrawerProps) {
    const toast = useToast()

    const { entityId, startTime, meetingId, description } = props

    const form = useForm({ defaultValues: { description: description ?? '' } })

    const { mutateAsync: updateMeeting, isError } = useUpdateMeeting()

    async function onSubmit({ description }: UpdateMeetingRequestBody) {
      await updateMeeting({ entityId, startTime, meetingId, description })

      toast({
        status: 'success',
        title: 'Successfully updated meeting!',
      })
      props.onClose()
    }

    function formatEntityId(entityCorn: Corn) {
      const { resourceId: entityResourceId } = getCornKernels(entityCorn)
      if (isJobCorn(entityCorn) && isJobId(entityResourceId)) {
        return getJobLabelFromId(entityResourceId)
      }

      return entityResourceId
    }

    return (
      <FormDrawer
        {...props}
        form={form}
        title={`Edit meeting for ${formatEntityId(entityId)} started at ${formatStringAsDate(props.startTime, STANDARD_DATE_TIME_FORMAT)}`}
        onSubmit={onSubmit}
      >
        <TextareaField label="Description" name="description" />

        {isError && (
          <ComposedAlert
            alert={{
              title: 'Submit Error',
              description: 'Something went wrong try to update the meeting.',
            }}
            status="error"
          />
        )}
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps: WithDrawerErrorBoundaryProps = { title: 'Edit Meeting' }

const UpdateMeetingSchema = z.object({
  description: z.string().min(1),
})

export type UpdateMeetingFormSchema = z.infer<typeof UpdateMeetingSchema>

const { useForm, TextareaField } = createForm(UpdateMeetingSchema, 'updateMeetingForm')
