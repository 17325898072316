import { createFileRoute } from '@eigtech/ui-shared-router'
import { useGetAssignedClaims } from '../../api'
import { Page, Stack } from '@eigtech/ui-shared-dave'
import { DesktopClaimsDashboard, MobileClaimsDashboard } from '../../components/Domains/Claims'
import { z } from 'zod'

const ClaimsSearchSchema = z.object({
  assignments: z.enum(['new', 'today', 'all']).optional(),
})

export type ClaimsSearch = z.infer<typeof ClaimsSearchSchema>

export const Route = createFileRoute('/claims/')({
  component: ClaimsDashboard,
  validateSearch: ClaimsSearchSchema.parse,
})

function ClaimsDashboard() {
  const { isPending, isError } = useGetAssignedClaims()

  return (
    <Page heading="Your Assigned Claims" isError={isError} isFullWidth isPending={isPending}>
      <Stack display={{ base: 'block', lg: 'none' }} spacing="4">
        <MobileClaimsDashboard />
      </Stack>

      <Stack display={{ base: 'none', lg: 'block' }} spacing="4">
        <DesktopClaimsDashboard />
      </Stack>
    </Page>
  )
}
