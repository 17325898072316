// import { blur } from './blur'
// import { borders } from './borders'
// import { breakpoints } from './breakpoints'
import { colors } from './colors'
// import { radii } from './radius'
import { shadows } from './shadows'
import { sizes } from './sizes'
// import { space } from './space'
import { textStyles } from './text-styles'
// import { transition } from './transition'
// import { typography } from './typography'
// import { zIndices } from './z-index'

export const foundations = {
  // ...typography,
  // blur,
  // borders,
  // breakpoints,
  colors,
  // radii,
  shadows,
  sizes,
  // space,
  textStyles,
  // transition,
  // zIndices,
}
