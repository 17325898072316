import { DocumentSourceSchema } from '@eigtech/documents-types'
import { z } from 'zod'

export const InternalDocumentSourceSchema = DocumentSourceSchema.extract([
  'CSR',
  'TPLAP',
  'Estimator',
  'Meetings',
  'Technician',
])

export type InternalDocumentSource = z.infer<typeof InternalDocumentSourceSchema>

export const ExternalDocumentSourceSchema = DocumentSourceSchema.extract(['Verisk'])

export type ExternalDocumentSource = z.infer<typeof ExternalDocumentSourceSchema>
