import { useRosterState } from 'amazon-chime-sdk-component-library-react'
import { useMemo } from 'react'
import { MEDIA_PIPELINE_PREFIX } from '../constants'

export function useFilteredRoster() {
  const { roster } = useRosterState()

  return useMemo(() => {
    const array = Object.entries(roster)
    const filtered = array.filter(
      ([__, attendee]) => !attendee.externalUserId?.startsWith(MEDIA_PIPELINE_PREFIX)
    )

    return Object.fromEntries(filtered)
  }, [roster])
}
