import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const GetScreenshotLabelsRequestPathSchema = z.object({
  entityId: CornSchema,
  meetingId: z.string(),
  startTime: z.string().datetime(),
})

export const GetScreenshotLabelsRequestSchema = GetScreenshotLabelsRequestPathSchema

export const GetScreenshotLabelsResponseSchema = z.object({
  labels: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
    })
  ),
})

export type GetScreenshotLabelsRequest = z.infer<typeof GetScreenshotLabelsRequestSchema>
export type GetScreenshotLabelsRequestPath = z.infer<typeof GetScreenshotLabelsRequestPathSchema>
export type GetScreenshotLabelsResponse = z.infer<typeof GetScreenshotLabelsResponseSchema>

export interface GetScreenshotLabelsHandler
  extends Handler<GetScreenshotLabelsRequest, GetScreenshotLabelsResponse> {}
