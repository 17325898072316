import { PhoneNumberOptionalSchema } from '@eigtech/function-utils'
import z from 'zod'

export const ParticipantSchema = z.object({
  name: z.string(),
  email: z.string().optional(),
  phone: PhoneNumberOptionalSchema,
  auth0Id: z.string().optional(),
  attendeeId: z.string().optional(),
})

export interface MeetingParticipant extends z.infer<typeof ParticipantSchema> {}
export interface MeetingParticipantWithEmail extends MeetingParticipant {
  email: string
}
export interface MeetingParticipantWithPhone extends MeetingParticipant {
  phone: string
}
