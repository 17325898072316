import {
  MenuListProps,
  MenuProps,
  MenuButton,
  Button,
  ButtonProps,
  Menu,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { MdExpandMore } from 'react-icons/md'

export type ComposedMenuProps = {
  children: ReactNode
  menuProps?: MenuProps
  menuListProps?: MenuListProps
  trigger: ReactNode
  buttonProps?: ButtonProps
}

export function ComposedMenu({ children, menuProps, menuListProps, ...props }: ComposedMenuProps) {
  return (
    <Menu computePositionOnMount {...menuProps}>
      {typeof props.trigger === 'string' ? (
        <MenuButton as={Button} rightIcon={<MdExpandMore />} {...props.buttonProps}>
          {props.trigger}
        </MenuButton>
      ) : (
        props.trigger
      )}
      <Portal>
        <MenuList {...menuListProps}>{children}</MenuList>
      </Portal>
    </Menu>
  )
}
