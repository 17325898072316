import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { PortalUserSchema } from '../../base/user'

export const GetPortalUserRequestPathSchema = z.object({
  email: z.string().transform((val) => decodeURI(val)),
})

export const GetPortalUserRequestSchema = GetPortalUserRequestPathSchema
export const GetPortalUserResponseSchema = z.object({ users: PortalUserSchema.array() })

export type GetPortalUserRequest = z.infer<typeof GetPortalUserRequestSchema>
export type GetPortalUserRequestPath = z.infer<typeof GetPortalUserRequestPathSchema>
export type GetPortalUserResponse = z.infer<typeof GetPortalUserResponseSchema>

export interface GetPortalUserHandler
  extends Handler<GetPortalUserRequest, GetPortalUserResponse> {}

export const GetPortalUserApiSchema: ApiSchema = {
  params: GetPortalUserRequestPathSchema,
  response: GetPortalUserResponseSchema,
}
