import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, MessageGroupIdSchema } from '../../base/index.js'

export const ListMessageGroupInboxesRequestPathSchema = z.object({
  groupId: MessageGroupIdSchema,
})
export const ListMessageGroupInboxesRequestSchema = ListMessageGroupInboxesRequestPathSchema

export type ListMessageGroupInboxesRequestBody = z.infer<
  typeof ListMessageGroupInboxesRequestPathSchema
>
export type ListMessageGroupInboxesRequest = z.infer<typeof ListMessageGroupInboxesRequestSchema>

export const ListMessageGroupInboxesResponseSchema = Auth0IdSchema.array()
export type ListMessageGroupInboxesResponse = z.infer<typeof ListMessageGroupInboxesResponseSchema>

export interface ListMessageGroupInboxesHandler
  extends Handler<ListMessageGroupInboxesRequest, ListMessageGroupInboxesResponse> {}
