import { Handler } from 'aws-lambda'
import { NoteSchema } from '../../base/note.js'
import { NoteCommandSchema } from '../note.js'
import z from 'zod'

// Internal add Note
export const InternalAddNoteBodyRequestSchema = NoteCommandSchema.omit({
  createdOn: true,
  entityId: true,
}).merge(
  z.object({
    createdOn: NoteSchema.shape.createdOn.optional(),
  })
)

export const InternalAddNotePathRequestSchema = z.object({
  /**
   * For use with claim notebooks, use claim number here
   */
  entityId: z.string(),
})

export const InternalAddNoteRequestSchema = InternalAddNoteBodyRequestSchema.merge(
  InternalAddNotePathRequestSchema
)

export const InternalAddNoteResponseSchema = z.object({
  noteId: NoteSchema.shape.id,
})

export type InternalAddNoteBodyRequest = z.infer<typeof InternalAddNoteBodyRequestSchema>
export type InternalAddNotePathRequest = z.infer<typeof InternalAddNotePathRequestSchema>
export type InternalAddNoteRequest = z.infer<typeof InternalAddNoteRequestSchema>
export type InternalAddNoteResponse = z.infer<typeof InternalAddNoteResponseSchema>
export type InternalAddNoteHandler = Handler<InternalAddNoteRequest, InternalAddNoteResponse>
