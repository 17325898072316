import { Button, Center, Stack, Text } from '@chakra-ui/react'
import log from '@eigtech/ui-shared-logging'
import React from 'react'
import { ComposedCard } from '../Card'

export type PageErrorBoundaryProps = { children: React.ReactNode }

export type PageErrorBoundaryState = { hasError: boolean }

export class PageErrorBoundary extends React.Component<
  PageErrorBoundaryProps,
  PageErrorBoundaryState
> {
  constructor(props: PageErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    log.error('Page Error Boundary', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ComposedCard heading="Whoops!">
          <Stack spacing="4">
            <Text>
              Something unexpected happened and caused an error. Please try again or contact the EIG
              Tech team for help.
            </Text>

            <Center>
              <Button as="a" href="/">
                Go Home
              </Button>
            </Center>
          </Stack>
        </ComposedCard>
      )
    }

    return this.props.children
  }
}
