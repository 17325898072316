import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { CallbackRequestSchema } from '../../../index.js'

export const GetCallbackRequestsByUserRequestPathSchema = z.object({ auth0Id: z.string() })

export type GetCallbackRequestsByUserRequestPath = z.infer<
  typeof GetCallbackRequestsByUserRequestPathSchema
>

export const GetCallbackRequestsByUserRequestSchema = GetCallbackRequestsByUserRequestPathSchema

export type GetCallbackRequestsByUserRequest = z.infer<
  typeof GetCallbackRequestsByUserRequestSchema
>

export const GetCallbackRequestsByUserResponseSchema = CallbackRequestSchema.array()

export type GetCallbackRequestsByUserResponse = z.infer<
  typeof GetCallbackRequestsByUserResponseSchema
>

export interface GetCallbackRequestsByUserHandler
  extends Handler<GetCallbackRequestsByUserRequest, GetCallbackRequestsByUserResponse> {}
