import { cornType } from '@eigtech/shared-corn'
import { makeTypeIdSchema, mimicTypeIdSchema } from '@eigtech/typeid'
import z from 'zod'

export const JobCornSchema = cornType('restoration', 'job')
export type JobCorn = z.infer<typeof JobCornSchema>
export const isJobCorn = (corn: string): corn is JobCorn => JobCornSchema.safeParse(corn).success

export const JobIdSchema = mimicTypeIdSchema<'job'>('job')
export type JobId = z.infer<typeof JobIdSchema>
export const isJobId = (id: string): id is JobId => JobIdSchema.safeParse(id).success

export const JobAppointmentIdSchema = makeTypeIdSchema<'jobappointment'>('jobappointment')
export type JobAppointmentId = z.infer<typeof JobAppointmentIdSchema>
export const isJobAppointmentId = (id: string): id is JobAppointmentId =>
  JobAppointmentIdSchema.safeParse(id).success

export const SubcontractorDocumentIdSchema =
  makeTypeIdSchema<'subcontractordocument'>('subcontractordocument')
export type SubcontractorDocumentId = z.infer<typeof SubcontractorDocumentIdSchema>
export const isSubcontractorDocumentId = (id: string): id is SubcontractorDocumentId =>
  SubcontractorDocumentIdSchema.safeParse(id).success

export const workAuthorizationIdPrefix = 'workauthorization'
export const WorkAuthorizationIdSchema =
  mimicTypeIdSchema<typeof workAuthorizationIdPrefix>(workAuthorizationIdPrefix)
export type WorkAuthorizationId = z.infer<typeof WorkAuthorizationIdSchema>
export const isWorkAuthorizationId = (id: string): id is WorkAuthorizationId =>
  WorkAuthorizationIdSchema.safeParse(id).success
