import { RowData } from '@tanstack/react-table'
import { DataGridTextFilter } from './DataGridTextFilter'
import { WithColumnProps } from '../../types'

export function DataGridDateFilter<TData extends RowData, TValue>(
  props: WithColumnProps<TData, TValue>
) {
  return <DataGridTextFilter {...props} type="date" />
}

export function DataGridDateTimeFilter<TData extends RowData, TValue>(
  props: WithColumnProps<TData, TValue>
) {
  return <DataGridTextFilter {...props} type="datetime-local" />
}
