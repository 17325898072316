import { z } from 'zod'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { CornSchema } from '@eigtech/shared-corn'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetMeetingUploadApiSchema = {
  params: z.object({
    entityId: CornSchema,
  }),
  headers: ApiLambdalerAuthHeaderSchema,
  response: z.object({
    fields: z.record(z.string()),
    url: z.string(),
  }),
} satisfies ApiSchema

export const GetMeetingUploadUrlRequestSchema = GetMeetingUploadApiSchema.params.merge(
  GetMeetingUploadApiSchema.headers
)

export type GetMeetingUploadUrlRequest = z.infer<typeof GetMeetingUploadUrlRequestSchema>
export type GetMeetingUploadUrlResponse = z.infer<typeof GetMeetingUploadApiSchema.response>
export type GetMeetingUploadUrlHandler = Handler<
  GetMeetingUploadUrlRequest,
  GetMeetingUploadUrlResponse
>
