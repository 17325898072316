import { ProducerUserTypesEnumSchema } from '@eigtech/auth0-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  PortalConsumerInSchema,
  PortalEntityTypeSchema,
  PortalZoneSchema,
  StagedPortalConsumerInSchema,
} from '../../base/portal.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const ActivatePortalRequestBodySchema = z.object({
  auth0Id: z.string().optional(),
  email: z.string().optional(),
  entityId: z.string(),
  entityPermissions: z.array(z.string()),
  entityType: PortalEntityTypeSchema,
  externalUserId: z.string(),
  name: z.string().optional(),
  requestActor: z.string(),
  userType: ProducerUserTypesEnumSchema,
  zone: PortalZoneSchema,
})

export const ActivatePortalRequestSchema = ActivatePortalRequestBodySchema

export const ActivatePortalResponseSchema = z.object({
  portal: PortalConsumerInSchema.or(StagedPortalConsumerInSchema).or(z.null()),
})

export type ActivatePortalRequestBody = z.infer<typeof ActivatePortalRequestBodySchema>
export type ActivatePortalRequest = z.infer<typeof ActivatePortalRequestSchema>
export type ActivatePortalResponse = z.infer<typeof ActivatePortalResponseSchema>

export interface ActivatePortalHandler
  extends Handler<ActivatePortalRequest, ActivatePortalResponse> {}

export const ActivatePortalApiSchema: ApiSchema = {
  body: ActivatePortalRequestBodySchema,
  response: ActivatePortalResponseSchema,
}
