import {
  Button,
  ButtonProps,
  CloseButtonProps,
  HStack,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import { Component, ComponentType, ReactNode } from 'react'
import { WrapChildrenWithText } from '../Text/WrapChildrenWithText'
import { SetOptional } from 'type-fest'
import { ComposedAlert } from '../Chakra/Alert'
import log from '@eigtech/ui-shared-logging'
import { IS_DEV } from '@eigtech/ui-shared-constants'

export type ComposedModalProps = ModalProps & {
  bodyProps?: ModalBodyProps
  closeButtonProps?: CloseButtonProps
  closeLabel?: string
  contentProps?: ModalContentProps
  footer?: ReactNode
  footerActionsLeft?: ReactNode
  footerActionsRight?: ReactNode
  footerCloseButtonProps?: ButtonProps
  footerProps?: ModalFooterProps
  headerProps?: ModalHeaderProps
  overlayProps?: ModalOverlayProps
  stackProps?: StackProps
  testId?: string
  title: ReactNode
}

export type ComposedModalWrapperProps = Omit<ComposedModalProps, 'title' | 'children'>

export function ComposedModal({
  bodyProps,
  children,
  closeButtonProps,
  closeLabel = 'Close',
  contentProps,
  footer,
  footerActionsLeft,
  footerActionsRight,
  footerCloseButtonProps,
  footerProps,
  headerProps,
  overlayProps,
  stackProps,
  title,
  ...props
}: ComposedModalProps) {
  return (
    <Modal closeOnOverlayClick={false} {...props}>
      <ModalOverlay {...overlayProps} />
      <ModalContent {...contentProps}>
        <ModalCloseButton {...closeButtonProps} />
        <ModalHeader {...headerProps}>{title}</ModalHeader>

        <ModalBody {...bodyProps}>
          <Stack spacing="6" {...stackProps}>
            <WrapChildrenWithText>{children}</WrapChildrenWithText>
          </Stack>
        </ModalBody>

        {footer ?? (
          <ModalFooter as={HStack} {...footerProps}>
            {footerActionsLeft}

            <Button
              id={props.id ? `${props.id}_close` : undefined}
              variant="outline"
              onClick={props.onClose}
              {...footerCloseButtonProps}
            >
              {closeLabel}
            </Button>

            {footerActionsRight}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export type WithModalErrorBoundaryProps = SetOptional<
  ComposedModalProps,
  'isOpen' | 'onClose' | 'children'
>

export function withModalErrorBoundary<Props extends ComposedModalWrapperProps>(
  Component: ComponentType<Props>,
  options: WithModalErrorBoundaryProps | ((props: Props) => WithModalErrorBoundaryProps)
) {
  return function WithModalErrorBoundary(props: Props) {
    return (
      <ComposedModalBoundary
        {...props}
        {...(typeof options === 'function' ? options(props) : options)}
      >
        <Component {...props} />
      </ComposedModalBoundary>
    )
  }
}

export type ComposedModalBoundaryProps<Props extends ComposedModalProps> = Props

export type ComposedModalBoundaryState = { hasError: boolean }

export class ComposedModalBoundary<Props extends ComposedModalProps> extends Component<
  ComposedModalBoundaryProps<Props>,
  ComposedModalBoundaryState
> {
  constructor(props: ComposedModalBoundaryProps<Props>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    log.error('Page Error Boundary', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ComposedModal {...this.props}>
          <ComposedAlert
            alert={{
              title: 'Whoops! Something went wrong.',
              description: IS_DEV ? (
                <Stack>
                  <Text>Something went wrong in the Modal.</Text>
                  <Text>See console for more details.</Text>
                </Stack>
              ) : (
                <Text>Please try again or contact EIG Tech for help.</Text>
              ),
            }}
            status="error"
          />
        </ComposedModal>
      )
    }

    return this.props.children
  }
}
