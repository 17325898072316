import z from 'zod'
import { BaseClaimSchema, ClaimNumber, RequestActor, RequestActorSchema } from '../model/base'
import { CustomerCommunicationTypeEnum } from '../model/customerCommunication'
import { OutcomeTypeEnum } from '../model/outcome'
import { ClaimsV2ServiceEventBaseSchema, claimsV2ServiceEventType } from './service'

export const ClaimEventTypeSchema = z.enum([
  'catCodeUpdated',
  'claimCreated',
  'claimFactorsAdded',
  'claimFactorsRemoved',
  'claimFactorsUpdated',
  'dateOfLossUpdated',
  'dateReceivedUpdated',
  'externalSystemIdUpserted',
  'externalSystemIdRemoved',
  'initialClaimActionsAdded',
  'initialClaimActionsRemoved',
  'initialClaimActionsUpdated',
  'inspectionCanceled',
  'inspectionCompleted',
  'inspectionScheduled',
  'lossDescriptionUpdated',
  'lossLocationUpdated',
  'lossOfUseAdded',
  'lossOfUseApproved',
  'lossOfUseCanceled',
  'lossOfUseDenied',
  'lossOfUsePending',
  'lossOfUseReceiptAdded',
  'lossOfUseReceiptRemoved',
  'lossSeverityUpdated',
  'lossTypeUpdated',
  'markedForPublication',
  'reInspectionRequested',
  'reviewCompleted',
  'reviewRequested',
  OutcomeTypeEnum.closed,
  OutcomeTypeEnum.reopened,
  CustomerCommunicationTypeEnum.customerContactedEmail,
  CustomerCommunicationTypeEnum.customerContactedLvm,
  CustomerCommunicationTypeEnum.customerNoContact,
  CustomerCommunicationTypeEnum.initialCustomerContactAttempted,
  CustomerCommunicationTypeEnum.initialCustomerContactFailed,
  CustomerCommunicationTypeEnum.initialCustomerContactSuccess,
  CustomerCommunicationTypeEnum.invalidContactInformation,
])
export const ClaimEventType = ClaimEventTypeSchema.Enum

export const claimEventType = <V extends ClaimEventTypeEnum | undefined>(eventName?: V) =>
  claimsV2ServiceEventType('claim', eventName)

export const ClaimEventSchema = ClaimsV2ServiceEventBaseSchema.merge(
  z.object({
    type: claimEventType(),
    claim: BaseClaimSchema.and(z.unknown()),
    metadata: ClaimsV2ServiceEventBaseSchema.shape.metadata.and(
      z.object({
        requestActor: RequestActorSchema,
      })
    ),
  })
)

export type ClaimEvent = z.infer<typeof ClaimEventSchema>
export type ClaimEventTypeEnum = z.infer<typeof ClaimEventTypeSchema>

export type ClaimNumberHaver = {
  claimNumber: ClaimNumber
}

export type RequestActorHaver = {
  requestActor: RequestActor
}
