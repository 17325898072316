import { DocumentV2, videoTypeRegex } from '@eigtech/documents-types'
import {
  Box,
  ButtonGroup,
  Center,
  CircularProgress,
  ComposedAlert,
  HStack,
  Icon,
  IconButton,
  Image,
  ImageProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  chakra,
} from '@eigtech/ui-shared-dave'
import { MotionConfig, motion } from 'framer-motion'
import { forwardRef as reactForwardRef, useEffect, useReducer } from 'react'
import { MdChevronLeft, MdChevronRight, MdInfo, MdOpenInNew } from 'react-icons/md'
import { useGetDocumentUrl } from '../../api/getDocumentUrl'
import { useOrganizeMedia } from '../../hooks/useOrganizeDocuments'
import { DocumentV2WithTags } from '../../types/DocumentV2WithTags'
import { DocumentPropertyList } from '../DocumentCard'

export type MediaCarouselProps = { documents: DocumentV2WithTags[] | undefined }

export function MediaCarousel({ documents }: MediaCarouselProps) {
  const groupedMedia = useOrganizeMedia(documents)

  const [carouselState, dispatchCarouselAction] = useReducer(carouselReducer, {
    currentIndex: 0,
    numberOfSlides: groupedMedia.length,
  })

  if (!documents) {
    return (
      <Center>
        <CircularProgress isIndeterminate size={100} />
      </Center>
    )
  }

  if (!groupedMedia.length) {
    return (
      <Center>
        <ComposedAlert alert="No media found" status="warning" />
      </Center>
    )
  }

  return (
    <MotionConfig transition={{ duration: 0.5, ease: [0.32, 0.72, 0, 1] }}>
      <HStack maxH="full" minH="0" overflow="hidden" spacing="4">
        <IconButton
          aria-label="Previous Slide"
          colorScheme="alphaBlack"
          icon={<MdChevronLeft />}
          size="lg"
          variant="outline"
          onClick={() => dispatchCarouselAction({ type: 'previous' })}
        />
        <Box flexGrow={1} overflow="hidden" position="relative">
          <MotionList animate={{ x: `-${carouselState.currentIndex * 100}%` }}>
            {groupedMedia.map((media, index) => (
              <MotionListItem
                key={media.id}
                animate={{ opacity: carouselState.currentIndex === index ? 1 : 0 }}
              >
                <MediaPreview {...media} />
              </MotionListItem>
            ))}
          </MotionList>
        </Box>
        <IconButton
          aria-label="Next Slide"
          colorScheme="alphaBlack"
          icon={<MdChevronRight />}
          size="lg"
          variant="outline"
          onClick={() => dispatchCarouselAction({ type: 'next' })}
        />
      </HStack>

      <Center bottom="4" position="absolute" w="full">
        {groupedMedia.map((media, index) => (
          <Box key={media.id} display={index === carouselState.currentIndex ? 'block' : 'none'}>
            <MediaInfo {...media} />
          </Box>
        ))}
      </Center>
    </MotionConfig>
  )
}

function MediaInfo(document: DocumentV2) {
  const { id, entityId, filename } = document
  const { data: url } = useGetDocumentUrl({ entityId, documentId: id })

  const buttonProps = {
    size: 'sm',
    variant: 'ghost',
  }

  return (
    <HStack>
      <chakra.span fontWeight="bold">{filename ?? '(No filename)'}</chakra.span>

      <ButtonGroup spacing="0.5">
        <Popover computePositionOnMount isLazy placement="top">
          <PopoverTrigger>
            <IconButton {...buttonProps} aria-label="View more info" icon={<Icon as={MdInfo} />} />
          </PopoverTrigger>
          <PopoverContent w="container.text">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="bold">Document Information</PopoverHeader>
            <PopoverBody maxH="calc(100vh - 40rem)" overflow="auto">
              <DocumentPropertyList document={document} entityId={entityId} />
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <IconButton
          {...buttonProps}
          aria-label="Download"
          as="a"
          href={url}
          icon={<Icon as={MdOpenInNew} />}
          target={document.id}
        />
      </ButtonGroup>
    </HStack>
  )
}

function MediaPreview(document: DocumentV2) {
  const { id, entityId, filename } = document
  const { data: url, refetch } = useGetDocumentUrl({ entityId, documentId: id })

  useEffect(() => {
    refetch()
  }, [refetch])

  const props = {
    maxH: 'calc(100vh - 18rem)',
    objectFit: 'contain',
    objectPosition: 'center',
    src: url,
  } satisfies ImageProps

  return filename.match(videoTypeRegex) ? (
    <chakra.video {...props} controls w="full" />
  ) : (
    <Image {...props} mx="auto" />
  )
}

type CarouselState = {
  numberOfSlides: number
  currentIndex: number
}
type CarouselAction = {
  type: 'next' | 'previous'
}

function carouselReducer(state: CarouselState, action: CarouselAction): CarouselState {
  switch (action.type) {
    case 'next':
      const nextIndex = state.currentIndex + 1
      return {
        ...state,
        currentIndex: nextIndex + 1 > state.numberOfSlides ? 0 : nextIndex,
      }

    case 'previous':
      const prevIndex = state.currentIndex - 1
      return {
        ...state,
        currentIndex: prevIndex < 0 ? state.numberOfSlides - 1 : prevIndex,
      }
  }
}

const MotionList = motion.create(
  reactForwardRef<HTMLUListElement, ListProps>((props, ref) => {
    return <List ref={ref} {...props} alignItems="center" display="flex" flexDir="row" />
  })
)

const MotionListItem = motion.create(
  reactForwardRef<HTMLLIElement, ListItemProps>((props, ref) => {
    return <ListItem ref={ref} flexGrow={1} flexShrink={0} w="full" {...props} />
  })
)
