import log from '@eigtech/ui-shared-logging'
import { useEffect, useRef, useState } from 'react'

const DEFAULT_SCRIPT_ID = 'heap-analytics-script'

/**
 * Provides the Heap script to inject.
 */
const getHeapScript = (id: string | number) => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}");
`

const getBaseHeap = () => (window as any).heap as Heap | undefined

/**
 * Initializes Heap tracking.
 */
export function initializeHeap(id: string | number) {
  const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID)

  if (!hasScript) {
    const script = document.createElement('script')

    script.innerHTML = getHeapScript(id)
    script.id = DEFAULT_SCRIPT_ID
    script.async = true

    document.body.appendChild(script)
  }

  const fallbackHeap: Heap = {
    track: () => {},
    identify: () => {},
    resetIdentity: () => {},
    addUserProperties: () => {},
    addEventProperties: () => {},
    removeEventProperty: () => {},
    clearEventProperties: () => {},
    appid: '',
    userId: '',
    identity: null,
    config: {},
    loaded: false,
  }

  const heap = new Proxy(fallbackHeap, {
    get(target, prop, receiver) {
      const heap = getBaseHeap()

      if (heap?.loaded) {
        return Reflect.get(heap, prop)
      }

      return Reflect.get(target, prop, receiver)
    },
  })

  // There is an odd race condition where heap, when it is initially loading, will be an array
  // This hook can be used to ensure that when using the heap instance, it is full loaded
  // before doing so
  function useHeap() {
    const tries = useRef(1)
    const [heapInstance, setHeapInstance] = useState(heap?.loaded ? heap : undefined)

    useEffect(() => {
      async function loadHeap() {
        if (!heap?.loaded) {
          if (tries.current > 5) {
            log.warn('Tried to load Heap Analytics 5 times and failed')
            return
          }

          tries.current = tries.current + 1
          await wait(200 * tries.current)

          loadHeap()
          return
        }

        setHeapInstance(heap)
      }

      loadHeap()
    }, [])

    return heapInstance
  }

  return { heap, useHeap }
}

export interface Heap {
  track: (event: string, properties?: Object) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (properties: Object) => void
  addEventProperties: (properties: Object) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
  config: any
  loaded: boolean
}

function wait(time = 1000) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
