import { chakra, HStack, useFormControlContext } from '@eigtech/ui-shared-dave'
import { ReactNode } from 'react'
import { FieldValues } from 'react-hook-form'
import { ArrayFieldRemoveButton } from './ArrayFieldButtons'
import { ArrayFieldRowProvider, ArrayFieldRowProviderProps } from './ArrayFieldRowContext'

export type ArrayFieldRowProps<TFieldValues extends FieldValues = FieldValues> =
  ArrayFieldRowProviderProps<TFieldValues> & {
    RowContainer?: (props: { children: ReactNode; index: number }) => JSX.Element
  }

export function ArrayFieldRow<TFieldValues extends FieldValues = FieldValues>({
  children,
  field,
  index,
  RowContainer = Row,
}: ArrayFieldRowProps<TFieldValues>) {
  return (
    <ArrayFieldRowProvider field={field} index={index}>
      <RowContainer index={index}>{children}</RowContainer>
    </ArrayFieldRowProvider>
  )
}

function Row({ children, index }: { children: ReactNode; index: number }) {
  const { id } = useFormControlContext()
  const rowId = `${id}-row-${index}`

  return (
    <HStack alignItems="flex-start" id={rowId}>
      {children}
      <chakra.div pt="8">
        <ArrayFieldRemoveButton />
      </chakra.div>
    </HStack>
  )
}

export type ArrayFieldRowComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof ArrayFieldRow<TFieldValues>
