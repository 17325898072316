import { ContactRole } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderWithRequestActorSchema } from '@eigtech/lambdaler-api-gateway-types'
import { PortalUserSchema } from '@eigtech/portals-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const CreateDelegateUserApiSchema = {
  body: z.object({
    email: z.string().email(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    options: z
      .object({
        useExistingContact: z.boolean().optional(),
      })
      .optional(),
    contactRole: z.enum([ContactRole.fieldAdjuster, ContactRole.inspectionTechnician]),
  }),
  headers: ApiLambdalerAuthHeaderWithRequestActorSchema,
  response: z.object({
    portalUser: PortalUserSchema.nullable(),
  }),
} satisfies ApiSchema
export const CreateDelegateUserRequestSchema = CreateDelegateUserApiSchema.body.merge(
  CreateDelegateUserApiSchema.headers
)
export type CreateDelegateUserRequest = z.infer<typeof CreateDelegateUserRequestSchema>

export const CreateDelegateUserResponseSchema = CreateDelegateUserApiSchema.response
export type CreateDelegateUserResponse = z.infer<typeof CreateDelegateUserResponseSchema>

export interface CreateDelegateUserHandler
  extends Handler<CreateDelegateUserRequest, CreateDelegateUserResponse> {}
