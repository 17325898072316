import { Skeleton, SkeletonProps } from '@chakra-ui/react'

export function SkeletonWrapper({
  children,
  height = '24px',
  width = '100%',
  ...props
}: SkeletonProps = {}) {
  if (props.isLoaded) {
    return <>{children}</>
  }

  return (
    <Skeleton data-test-id="skeleton-wrapper" height={height} width={width} {...props}>
      {children}
    </Skeleton>
  )
}
