import { Cell, Header } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useWindowSize } from './useWindowSize'

export type UseGetCellWidthsProps = {
  getCellId: (id: string) => string
  pinned: (Cell<any, any> | Header<any, any>)[]
}

export function useGetCellWidths({ getCellId, pinned }: UseGetCellWidthsProps) {
  // get updated widths on window resize
  const windowSize = useWindowSize()

  return useMemo(() => {
    const mapped = pinned.map((cell, index) => [
      cell.id,
      pinned
        .slice(0, index)
        .reduce(
          (total, cell) => total + (document.getElementById(getCellId(cell.id))?.clientWidth || 0),
          0
        ),
    ])
    const widths = Object.fromEntries(mapped)

    return widths

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinned, getCellId, windowSize])
}
