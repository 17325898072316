import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  ButtonProps,
  chakra,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@eigtech/ui-shared-dave'
import { getUserName } from '../utils'
import { UserAvatar } from './UserAvatar'

export type BeforeLogoutReturn = void | {
  shouldLogout: boolean
}

export type UserMenuProps = {
  isCollapsed: boolean
  beforeLogout?: (() => BeforeLogoutReturn) | (() => Promise<BeforeLogoutReturn>)
  menuItems?: JSX.Element[]
} & ButtonProps

export const UserMenu = forwardRef<UserMenuProps, 'button'>(
  ({ beforeLogout, isCollapsed, menuItems = [], ...props }, ref) => {
    const { user, logout } = useAuth0()
    async function handleLogout() {
      const response = await beforeLogout?.()

      if (response?.shouldLogout === false) {
        return
      }

      logout({
        returnTo: window.location.origin,
      })
    }

    if (!user) return null

    return (
      <Menu>
        <MenuButton ref={ref} as={Button} id="navigation_userMenu" {...props}>
          <chakra.span alignItems="center" display="flex" maxW="full">
            <UserAvatar disableTooltip mr="2" size="xs" user={user} />
            <chakra.span
              data-test-id="navigation_userName"
              flex={1}
              overflow="hidden"
              textAlign="left"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {!isCollapsed && getUserName(user)}
            </chakra.span>
          </chakra.span>
        </MenuButton>

        <MenuList color="chakra-body-text" data-test-id="navigation_userMenu_list">
          <>
            {menuItems}
            <MenuItem id="navigation_userMenu_logout" onClick={handleLogout}>
              Logout
            </MenuItem>
          </>
        </MenuList>
      </Menu>
    )
  }
)
