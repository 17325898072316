import { Handler } from 'aws-lambda'
import z from 'zod'
import { EstimatorClaimSummarySchema } from '../../queryModel/estimatorClaimQueryModel.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetEstimatorAssignedClaimsPathRequestSchema = z.object({
  email: z.string().email(),
})

export const GetEstimatorAssignedClaimsRequestSchema = GetEstimatorAssignedClaimsPathRequestSchema
export const GetEstimatorAssignedClaimsResponseSchema = EstimatorClaimSummarySchema.array()

export type GetEstimatorAssignedClaimsRequest = z.infer<
  typeof GetEstimatorAssignedClaimsRequestSchema
>
export type GetEstimatorAssignedClaimsResponse = z.infer<
  typeof GetEstimatorAssignedClaimsResponseSchema
>

export interface GetEstimatorAssignedClaimsHandler
  extends Handler<GetEstimatorAssignedClaimsRequest, GetEstimatorAssignedClaimsResponse> {}

export const GetEstimatorAssignedClaimsApiSchema = {
  params: GetEstimatorAssignedClaimsPathRequestSchema,
  response: GetEstimatorAssignedClaimsResponseSchema,
} satisfies ApiSchema
