import { Stack } from '@eigtech/ui-shared-dave'
import { MeetingStatus, useMeetingStatus } from 'amazon-chime-sdk-component-library-react'
import { AudioVideoControls } from './AudioVideoControls'
import { EndMeetingControls } from './EndMeetingControls'
import { InviteButtonControl } from './InviteDrawer'

export function InMeeting() {
  const meetingStatusEnum = useMeetingStatus()
  const isInMeeting = meetingStatusEnum === MeetingStatus.Succeeded

  if (!isInMeeting) return null

  return (
    <>
      <AudioVideoControls />
      <Stack direction={{ base: 'row', lg: 'column' }}>
        <InviteButtonControl />
        <EndMeetingControls />
      </Stack>
    </>
  )
}
