import { Note } from '@eigtech/notes-types'
import { Corn } from '@eigtech/shared-corn'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  HStack,
  Stack,
  Tooltip,
  WithDrawerErrorBoundaryProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { CheckboxGroupControl, FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { VediImportStateEnum } from '@eigtech/verisk-edi-interface-types'
import { ReactNode } from 'react'
import { MdHelp } from 'react-icons/md'
import { z } from 'zod'
import { useMarkNoteForPublication } from '../../api/publishNote'

export type ExportNoteDrawerProps = ComposedDrawerWrapperProps & {
  note: Note
  additionalPublicationTargets?: { value: string; label: string | ReactNode }[]
  excludeXaPublicationTarget?: boolean
  entityCorn: Corn
  latestImportStatus?: VediImportStateEnum
  onExport?: () => void
}

export const ExportNoteDrawer = withDrawerErrorBoundary(
  function ExportNoteDrawer({
    additionalPublicationTargets = [],
    entityCorn,
    excludeXaPublicationTarget = false,
    note,
    latestImportStatus,
    onExport,
    ...drawerProps
  }: ExportNoteDrawerProps) {
    const form = useForm()

    const toast = useToast()
    const { mutateAsync: exportNote } = useMarkNoteForPublication(entityCorn)

    async function handleExport({ publicationTargets }: ExportForm) {
      await exportNote({
        noteId: note.id,
        publicationTargets,
      })

      toast({
        status: 'success',
        title: 'Successfully marked note for export',
        description:
          'It will take a few minutes for note to be fully exported and available in the selected target locations.',
      })

      onExport?.()
      drawerProps.onClose()
    }

    const availablePublicationTargets = [
      ...(excludeXaPublicationTarget ? [] : [{ value: xaPublicationTarget, label: 'XA' }]),
      ...additionalPublicationTargets,
    ]

    return (
      <FormDrawer {...drawerProps} {...baseProps} form={form} onSubmit={handleExport}>
        <Stack spacing="8">
          <Stack>
            <ComposedAlert
              alert={{
                title: 'Publication Targets',
                description: 'Where would you like the selected note to be exported to?',
              }}
            />
            <CheckboxGroupField label="Publication Targets" name="publicationTargets">
              <Stack>
                {availablePublicationTargets?.map(({ label, value }) => {
                  const hasAlreadyPublishedToTarget =
                    note.publicationTargets?.includes(value) &&
                    value === xaPublicationTarget &&
                    latestImportStatus !== 'failed'

                  return (
                    <HStack key={value}>
                      {hasAlreadyPublishedToTarget && (
                        <Tooltip
                          label="This note has already been exported to this target."
                          shouldWrapChildren
                        >
                          <MdHelp />
                        </Tooltip>
                      )}

                      <CheckboxGroupControl
                        isDisabled={hasAlreadyPublishedToTarget}
                        name="publicationTargets"
                        value={value}
                      >
                        {label}
                      </CheckboxGroupControl>
                    </HStack>
                  )
                })}
              </Stack>
            </CheckboxGroupField>
          </Stack>
        </Stack>
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps = {
  title: 'Export Note',
} satisfies WithDrawerErrorBoundaryProps

const ExportFormSchema = z.object({
  publicationTargets: z.string().array(),
})

type ExportForm = z.infer<typeof ExportFormSchema>

const { useForm, CheckboxGroupField } = createForm(ExportFormSchema, 'exportForm')

const xaPublicationTarget = 'XactAnalysis'
