import { Action, Subject } from '@eigtech/ui-estimator/lib/permissions'
import { forwardRef } from '@eigtech/ui-shared-dave'
import {
  MobileNavigation as BaseMobileNavigation,
  NavButton,
  navButtonStyleProps,
  Navigation as BaseNavigation,
  NavigationProps,
} from '@eigtech/ui-shared-navigation'
import { memo, useMemo } from 'react'
import { MdBugReport, MdInfo } from 'react-icons/md'
import { UserMenu } from '../UserMenu'
import { NavId, useNavs } from './navs'
import { useNavCollapsedSetting } from './useNavCollapsedSetting'
import { ROUTER_PATHS } from '../../../lib/constants'

function useNavProps() {
  const navCollapsedSettings = useNavCollapsedSetting()

  const navs = useNavs()

  return useMemo<NavigationProps<Action, Subject, NavId>>(
    () => ({
      ...navCollapsedSettings,
      heading: 'Estimator Portal',
      headingProps: { fontSize: 'lg' },
      navs,
      bottomNavItems: [
        <NavButton
          key="documentation"
          item={{
            icon: MdInfo,
            label: 'Documentation',
            navId: '',
            url: { to: ROUTER_PATHS.documentation.route },
          }}
        />,
        <NavButton
          key="mondayForm"
          item={{
            icon: MdBugReport,
            label: 'Submit Bug',
            href: 'https://forms.monday.com/forms/313f04f46bed33062683e8847c99e223?r=use1',
            target: 'mondayForm',
          }}
        />,
        ({ isCollapsed }) => (
          <UserMenu key="userMenu" {...navButtonStyleProps} isCollapsed={isCollapsed} />
        ),
      ],
    }),
    [navCollapsedSettings, navs]
  )
}

export const Navigation = memo(
  forwardRef<Partial<NavigationProps<Action, Subject, NavId>>, 'div'>((props, ref) => {
    const defaults = useNavProps()

    return <BaseNavigation ref={ref} id="main-navigation" {...defaults} {...props} />
  })
)

export function MobileNavigation() {
  const defaults = useNavProps()
  return <BaseMobileNavigation {...defaults} />
}
