import {
  ClaimDefaultChronicleTypeEnum,
  ClaimDefaultChronicleTypeSchema,
  claimDefaultChronicleLabelMap,
} from '@eigtech/summarizer-types'

export const getClaimTimelineEventLabel = (event: ClaimDefaultChronicleTypeEnum) =>
  claimDefaultChronicleLabelMap[event]

/**
 * - fieldAdjusterContactedCustomer is kept elsewhere only for compatibility
 * - jobContracted is not an XA status, apparently was a "bad label"
 */
const historicalPointTypes: string[] = ['fieldAdjusterContactedCustomer', 'jobContracted']

export const claimTimelineEventLabels = Object.fromEntries(
  ClaimDefaultChronicleTypeSchema.options
    .filter((pointType) => !historicalPointTypes.includes(pointType))
    .map((eventKey) => [eventKey, getClaimTimelineEventLabel(eventKey)])
) as Record<ClaimDefaultChronicleTypeEnum, string>

export const claimTimelineEventLabelsArray = [
  ...new Set(Object.values(claimTimelineEventLabels)),
].sort()
