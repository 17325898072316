import { ComposedAlert } from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import React from 'react'

export type DataGridErrorBoundaryProps = { children: React.ReactNode }

export type DataGridErrorBoundaryState = { hasError: boolean }

export class DataGridErrorBoundary extends React.Component<
  DataGridErrorBoundaryProps,
  DataGridErrorBoundaryState
> {
  constructor(props: DataGridErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    log.error('Data Grid error', { error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <ComposedAlert
          alert={{
            title: 'Table Error',
            description: 'Something went wrong displaying this table.',
          }}
          status="error"
        />
      )
    }

    return this.props.children
  }
}
