import { Flex, StackProps, forwardRef, useBreakpointValue } from '@chakra-ui/react'
import { createContext, useContext } from 'react'
import { PropertyProps } from './Property'

type PropertyListVariant = 'horizontal' | 'vertical' | 'vertical-stacked'

export type PropertyListProps = StackProps &
  Omit<PropertyListContext, 'hasPropertyListParent' | 'spacing' | 'variant'> & {
    propertySpacing?: PropertyListContext['spacing']
    variant?:
      | PropertyListVariant
      | Partial<Record<string, PropertyListVariant>>
      | Array<PropertyListVariant | null>
  }

export const PropertyList = forwardRef<PropertyListProps, 'dl'>(function PropertyList(
  { labelWidth, propertySpacing, spacing = '4', variant: propsVariant = 'vertical', ...props },
  ref
) {
  const variant = useBreakpointValue(
    typeof propsVariant === 'string' ? [propsVariant] : propsVariant
  )

  return (
    <PropertyListContext.Provider
      value={{
        ...defaultPropertyListContext(),
        hasPropertyListParent: true,
        labelWidth,
        spacing: propertySpacing,
        variant: variant === 'vertical' ? 'horizontal' : 'vertical',
      }}
    >
      <Flex
        ref={ref}
        as="dl"
        columnGap={variant === 'vertical' || variant === 'vertical-stacked' ? '2' : spacing}
        direction={variant === 'vertical' || variant === 'vertical-stacked' ? 'column' : 'row'}
        rowGap={variant === 'vertical' || variant === 'vertical-stacked' ? spacing : '2'}
        wrap={variant === 'horizontal' ? 'wrap' : undefined}
        {...props}
      ></Flex>
    </PropertyListContext.Provider>
  )
})

export type PropertyListContext = Pick<PropertyProps, 'labelWidth' | 'spacing' | 'variant'> & {
  hasPropertyListParent: boolean
}

const defaultPropertyListContext = () =>
  ({ hasPropertyListParent: false }) satisfies PropertyListContext

const PropertyListContext = createContext<PropertyListContext>(defaultPropertyListContext())

export function usePropertyListContext() {
  return useContext(PropertyListContext)
}
