import { z } from 'zod'

export type EmptyRequest = Record<string, never>

export const DocumentCornParamSchema = z.object({
  documentCorn: z.string(),
})
export type DocumentCornParam = z.infer<typeof DocumentCornParamSchema>

export const S3KeySchema = z.object({
  key: z.string(),
})
export type S3Key = z.infer<typeof S3KeySchema>

export const S3BucketNameSchema = z.object({
  bucketName: z.string(),
})
export type S3BucketName = z.infer<typeof S3BucketNameSchema>

export const S3BucketWithPrefixSchema = S3BucketNameSchema.merge(z.object({ prefix: z.string() }))
export type S3BucketWithPrefix = z.infer<typeof S3BucketWithPrefixSchema>

export type S3ObjectWithKey = S3BucketName & S3Key

export const DocumentCountSchema = z.object({ success: z.number(), failures: z.string().array() })
export type DocumentCount = z.infer<typeof DocumentCountSchema>
