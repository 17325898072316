import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimLossTypeSchema, ClaimNumberSchema } from '../model'

export const UpdateLossTypeSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    lossType: ClaimLossTypeSchema,
  }),
  headers: ApiLambdalerAuthHeaderSchema.pick({ requestActor: true }).required({
    requestActor: true,
  }),
  response: z.object({
    claimNumber: ClaimNumberSchema,
    lossDetails: z.object({
      lossType: ClaimLossTypeSchema,
    }),
  }),
}

export const UpdateLossTypeRequestSchema = UpdateLossTypeSchemas.params
  .merge(UpdateLossTypeSchemas.body)
  .merge(UpdateLossTypeSchemas.headers)

export type UpdateLossTypeBodyRequest = z.infer<typeof UpdateLossTypeSchemas.body>
export type UpdateLossTypeHeaderRequest = z.infer<typeof UpdateLossTypeSchemas.headers>
export type UpdateLossTypeRequest = z.infer<typeof UpdateLossTypeRequestSchema>
export type UpdateLossTypeResponse = z.infer<typeof UpdateLossTypeSchemas.response>

export interface UpdateLossTypeHandler
  extends Handler<UpdateLossTypeRequest, UpdateLossTypeResponse> {}

export const UpdateLossTypeApiSchema: ApiSchema = UpdateLossTypeSchemas
