import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Template, TemplateTypesSchema } from '../aggregates/template.js'

export const ListTemplatesApiSchema = {
  query: z.object({
    type: TemplateTypesSchema.optional(),
  }),
} satisfies ApiSchema

export const ListTemplatesRequestSchema = ListTemplatesApiSchema.query

export type ListTemplatesQueryParameters = z.infer<typeof ListTemplatesApiSchema.query>
export type ListTemplatesRequest = z.infer<typeof ListTemplatesRequestSchema>
export type ListTemplatesResponse = Template[]
export type ListTemplatesHandler = Handler<ListTemplatesRequest, ListTemplatesResponse>
