import { z } from 'zod'
import { CallbackRequestedRolesSchema, CallbackStatusEnum } from '../../index.js'

import { CornSchema } from '@eigtech/shared-corn'
import { callbackEventType, CommunicationEventSchema } from './service.js'

export const CallbackResponseEventSchema = CommunicationEventSchema.merge(
  z.object({
    type: callbackEventType('response'),
    callback: z.object({
      entityId: CornSchema,
      role: CallbackRequestedRolesSchema,
      notes: z.string().optional(),
      status: CallbackStatusEnum,
    }),
    schemaVersion: z.literal(1),
  })
)

export type CallbackResponseEvent = z.infer<typeof CallbackResponseEventSchema>
