// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts

import { theme as baseTheme } from '@chakra-ui/react'
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const baseStyle = defineStyle({
  borderRadius: 'lg',
})

const variantGhost = defineStyle({
  _hover: {
    textDecoration: 'none',
  },
})

const variantOutline = defineStyle({
  _hover: {
    textDecoration: 'none',
  },
})

const variantSolid = defineStyle({
  _hover: {
    textDecoration: 'none',
  },
})

const variantLink = defineStyle({
  _hover: {
    textDecoration: 'underline',
  },
})

// const variantUnstyled = defineStyle({})

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  // unstyled: variantUnstyled,
  primary: defineStyle((props) => ({
    ...(baseTheme.components.Button.variants?.solid(props) ?? {}),
    _hover: {
      textDecoration: 'none',
    },
  })),
}

const sizes = {
  lg: defineStyle({
    px: 8,
  }),
  md: defineStyle({
    px: 6,
  }),
  sm: defineStyle({
    px: 4,
  }),
  xs: defineStyle({
    px: 3,
  }),
}

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    colorScheme: 'brandPrimary',
    size: 'md',
  },
})
