import { Corn } from '@eigtech/shared-corn'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'

export const notebooksBasePath = 'notes'
export const veriskEdiBasePath = 'verisk-edi'

const { all } = makeBasicQueryKeys('notes')

export const notebooksQueryKeys = {
  all,
  notebooks: () => [...all(), 'notebooks'],
  notebook: (notebookId: string) => [...notebooksQueryKeys.notebooks(), notebookId],
  entityNotebooks: () => [...all(), 'entityNotebooks'],
  entityNotebook: (entityCorn: Corn) => [...notebooksQueryKeys.entityNotebooks(), entityCorn],
  noteImportStatus: (entityCorn: Corn) => [
    ...notebooksQueryKeys.entityNotebook(entityCorn),
    'status',
  ],
}
