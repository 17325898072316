import { JobEventType } from '@eigtech/restoration-types'
import {
  DefaultTimelineTemplateSchema,
  makeTimelineInstructionTemplateSchema,
} from '@eigtech/templates-types'
import z from 'zod'
import { timelineServiceCorn } from '../base/service.js'
import { StatusReduction, StatusReductionSchema } from '../base/statusReduction.js'
import { TimelinePointSchema, TimelineSchema } from '../base/timeline.js'

// Timeline Type
export const JobTimelineTypeSchema = z.literal('job')

// Timeline Corn
export const JobTimelineCornSchema = timelineServiceCorn(JobTimelineTypeSchema.value)

// Timeline Points
export const JobTimelinePointTypeSchema = z.enum([
  // Restoration
  JobEventType.appointmentCompleted,
  JobEventType.appointmentScheduled,
  JobEventType.billSentToCollections,
  JobEventType.cancelled,
  JobEventType.carrierInvoiced,
  JobEventType.carrierMadePayment,
  JobEventType.completed,
  JobEventType.customerContactAttempted,
  JobEventType.depositOverrideApproved,
  JobEventType.depositPaid,
  JobEventType.locationUpdated,
  JobEventType.received,
  JobEventType.started,
  JobEventType.subcontractorPaid,
  JobEventType.subdocumentIssued,
  JobEventType.subdocumentReceived,
  JobEventType.withdrawn,
  JobEventType.workAuthorizationCompleted,
  JobEventType.workAuthorizationDeclined,
  JobEventType.workAuthorizationExpired,
  JobEventType.workAuthorizationOverrideApproved,
  JobEventType.workAuthorizationReassigned,
  JobEventType.workAuthorizationRecalled,
  JobEventType.workAuthorizationSent,
  // TBD:
  // - Deposit Sent
  // - Deposit Override
  // - Pending Assignment
  // - Subcontractor Assigned
  // - Job Approved

  // Claim Assignable
  'jobAssignedToClaim',
  'jobUnassignedFromClaim',

  // Contact Assignee - Assigned
  'approverAssignedToJob',
  'coordinatorAssignedToJob',
  'dispatcherAssignedToJob',
  'fieldTechnicianAssignedToJob',
  'primaryContactAssignedToJob',
  'projectManagerAssignedToJob',
  'subcontractorAssignedToJob',

  // Contact Assignee - Unassigned
  'approverUnassignedFromJob',
  'coordinatorUnassignedFromJob',
  'dispatcherUnassignedFromJob',
  'fieldTechnicianUnassignedFromJob',
  'primaryContactUnassignedFromJob',
  'projectManagerUnassignedFromJob',
  'subcontractorUnassignedFromJob',
])

export const JobTimelinePointSchema = TimelinePointSchema.merge(
  z.object({
    type: JobTimelinePointTypeSchema,
  })
)

// Timeline
export const JobTimelineSchema = TimelineSchema.merge(
  z.object({
    id: JobTimelineCornSchema,
    type: JobTimelineTypeSchema,
    points: JobTimelinePointSchema.array(),
  })
)

// Timeline Template
export type JobTimelineEnum = typeof JobTimelinePointTypeSchema.enum
export type JobTimelineEvent = z.infer<typeof JobTimelinePointTypeSchema>
export type JobTimelinePoints = typeof JobTimelinePointTypeSchema.options
export type JobTimelineEvents = typeof JobTimelinePointTypeSchema.options

export const JobTimelineInstructionsTemplateSchema = makeTimelineInstructionTemplateSchema<
  JobTimelineEvents,
  JobTimelinePoints
>(JobTimelinePointTypeSchema, JobTimelinePointTypeSchema)

export const JobTimelineTemplateSchema = DefaultTimelineTemplateSchema.merge(
  z.object({
    instructionTemplate: JobTimelineInstructionsTemplateSchema,
    timelineType: JobTimelineTypeSchema,
  })
)

// Status Reduction
export const JobReductionSchema = StatusReductionSchema.merge(
  z.object({
    type: JobTimelineTypeSchema,
    timeline: JobTimelineSchema,
  })
)

export const isJobReduction = (reduction: StatusReduction): reduction is JobReduction =>
  JobReductionSchema.safeParse(reduction).success

// Types
export type JobReduction = z.infer<typeof JobReductionSchema>
export type JobTimeline = z.infer<typeof JobTimelineSchema>
export type JobTimelineCorn = z.infer<typeof JobTimelineCornSchema>
export type JobTimelineInstructionsTemplate = z.infer<typeof JobTimelineInstructionsTemplateSchema>
export type JobTimelinePoint = z.infer<typeof JobTimelinePointSchema>
export type JobTimelinePointType = z.infer<typeof JobTimelinePointTypeSchema>
export type JobTimelineTemplate = z.infer<typeof JobTimelineTemplateSchema>
export type JobTimelineType = z.infer<typeof JobTimelineTypeSchema>
