import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js'

export const RecordCarrierMadePaymentRequestBodySchema = z.object({
  carrierPayment: z.object({
    externalId: JobExternalIdSchema.optional(),
    paidBy: ContactCornSchema.or(z.string()),
    paidDate: z.string().datetime(),
    paidInFull: z.boolean(),
  }),
})
export const RecordCarrierMadePaymentRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordCarrierMadePaymentRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordCarrierMadePaymentRequestSchema =
  RecordCarrierMadePaymentRequestBodySchema.merge(
    RecordCarrierMadePaymentRequestHeadersSchema
  ).merge(RecordCarrierMadePaymentRequestPathSchema)
export const RecordCarrierMadePaymentResponseSchema = z.void()
export const RecordCarrierMadePaymentApiSchema: ApiSchema = {
  body: RecordCarrierMadePaymentRequestBodySchema,
  headers: RecordCarrierMadePaymentRequestHeadersSchema,
  params: RecordCarrierMadePaymentRequestPathSchema,
}

export interface RecordCarrierMadePaymentRequestBody
  extends z.infer<typeof RecordCarrierMadePaymentRequestBodySchema> {}
export interface RecordCarrierMadePaymentRequestPath
  extends z.infer<typeof RecordCarrierMadePaymentRequestPathSchema> {}
export interface RecordCarrierMadePaymentRequest
  extends z.infer<typeof RecordCarrierMadePaymentRequestSchema> {}
export type RecordCarrierMadePaymentResponse = z.infer<
  typeof RecordCarrierMadePaymentResponseSchema
>
export interface RecordCarrierMadePaymentHandler
  extends Handler<RecordCarrierMadePaymentRequest, RecordCarrierMadePaymentResponse> {}
