import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AssignmentCanonSchema } from '../entities/assignment'

export const GetAssignmentsForAssignableApiSchema = {
  params: z.object({
    assignableId: z.string(),
  }),
  response: AssignmentCanonSchema.array(),
} satisfies ApiSchema

export const GetAssignmentsForAssignablePathRequestSchema =
  GetAssignmentsForAssignableApiSchema.params

export const GetAssignmentsForAssignableRequestSchema = GetAssignmentsForAssignablePathRequestSchema

export const GetAssignmentsForAssignableResponseSchema =
  GetAssignmentsForAssignableApiSchema.response

export type GetAssignmentsForAssignablePathRequest = z.infer<
  typeof GetAssignmentsForAssignablePathRequestSchema
>
export type GetAssignmentsForAssignableRequest = z.infer<
  typeof GetAssignmentsForAssignableRequestSchema
>
export type GetAssignmentsForAssignableResponse = z.infer<
  typeof GetAssignmentsForAssignableResponseSchema
>
export type GetAssignmentsForAssignableHandler = Handler<
  GetAssignmentsForAssignableRequest,
  GetAssignmentsForAssignableResponse
>
