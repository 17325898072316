import type {
  ListFullTagsResponse,
  ListTagKeysResponse,
  ListTagsByFilterRequest,
  ListTagsByKeyRequest,
  ListTagsByResourceRequest,
} from '@eigtech/resource-tagging-types'
import { contextualGet, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { tagsBasePath, tagsQueryKeys } from './constants'

const getTagKeysList = () => contextualGet<ListTagKeysResponse>(`${tagsBasePath}/`)

const getTagsByKey = (params: ListTagsByKeyRequest) =>
  contextualGet<ListFullTagsResponse>(`${tagsBasePath}/`, {
    searchParams: params,
  })

const getTagsByResource = (params: ListTagsByResourceRequest) =>
  contextualGet<ListFullTagsResponse>(`${tagsBasePath}/`, {
    searchParams: params,
  })

const getTagsByFilter = (params: ListTagsByFilterRequest) =>
  contextualGet<ListFullTagsResponse>(`${tagsBasePath}/`, {
    searchParams: params,
  })

//

export function useGetTagKeysList() {
  return useQuery({ queryKey: tagsQueryKeys.list(), queryFn: () => getTagKeysList() })
}

export function useGetTagsByKey(params: ListTagsByKeyRequest) {
  return useQuery({
    queryKey: tagsQueryKeys.filteredList(params),
    queryFn: () => getTagsByKey(params),
  })
}

export function getTagsByResourceQueryOptions(params: ListTagsByResourceRequest) {
  return {
    queryKey: tagsQueryKeys.filteredList(params),
    queryFn: () => getTagsByResource(params),
  }
}

export function useGetTagsByResource(params: ListTagsByResourceRequest) {
  return useQuery(getTagsByResourceQueryOptions(params))
}

export function getGetTagsByFilterQueryOptions(params: ListTagsByFilterRequest) {
  return {
    queryKey: tagsQueryKeys.filteredList(params),
    queryFn: () => getTagsByFilter(params),
  }
}
export function useGetTagsByFilter(params: ListTagsByFilterRequest) {
  return useQuery(getGetTagsByFilterQueryOptions(params))
}

//

export function useInvalidateTags() {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey: tagsQueryKeys.all() })
  }
}

export function useInvalidateTagsList() {
  const queryClient = useQueryClient()

  return (params?: ListTagsByKeyRequest | ListTagsByResourceRequest | ListTagsByFilterRequest) => {
    const queryKey = !!params ? tagsQueryKeys.filteredList(params) : tagsQueryKeys.list()
    queryClient.invalidateQueries({ queryKey })
  }
}
