import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'
import { GetInspectionsRequest } from '../schedules'

export const claimsBasePath = 'claims'
export const claimsV2BasePath = 'claims-v2'
export const summarizerBasePath = 'summarizer'

const claimsBaseQueryKeys = makeBasicQueryKeys('claims')

export const claimsQueryKeys = {
  ...claimsBaseQueryKeys,
  timeline: (claimNumber: string) => [...claimsQueryKeys.detail(claimNumber), 'timeline'],
  schedule: (claimNumber: string) => [...claimsQueryKeys.detail(claimNumber), 'schedule'],
  filteredSchedule: (claimNumber: string, request: GetInspectionsRequest) => [
    ...claimsQueryKeys.schedule(claimNumber),
    request,
  ],
}
