import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
} from '@eigtech/ui-shared-dave'
import { RowData } from '@tanstack/react-table'
import { isBoolean } from 'lodash-es'
import { MdClose as ClearIcon } from 'react-icons/md'
import { WithColumnProps } from '../../types'
import { getColumnTitle } from '../utils'

export function DataGridBooleanFilter<TData extends RowData, TValue>({
  column,
}: WithColumnProps<TData, TValue>) {
  const filterValue = column.getFilterValue()

  return (
    <FormControl>
      <FormLabel fontSize="sm">
        <chakra.span fontStyle="italic">{getColumnTitle(column)}</chakra.span> is:
      </FormLabel>
      <HStack justifyContent="space-between">
        <RadioGroup
          value={isBoolean(filterValue) ? (!!filterValue ? 'true' : 'false') : ''}
          onChange={(value) => column.setFilterValue(value === 'true')}
        >
          <HStack>
            <Radio value="true">True</Radio>
            <Radio value="false">False</Radio>
          </HStack>
        </RadioGroup>
        <Button
          leftIcon={<ClearIcon />}
          size="sm"
          variant="ghost"
          onClick={() => column.setFilterValue(undefined)}
        >
          Clear filter
        </Button>
      </HStack>
    </FormControl>
  )
}
