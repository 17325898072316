import z from 'zod'
import { BaseJobSchema } from './baseJob.js'
import { JobService } from './baseJobService.js'

export const EmergencyServicesJobTypeSchema = z.literal('emergencyServices')
export const JOB_TYPE_EMERGENCY_SERVICES = EmergencyServicesJobTypeSchema.value

export const EmergencyServicesJobTypeAbbreviationSchema = z.literal('EMS')
export const JOB_TYPE_EMERGENCY_SERVICES_ABBREVIATION =
  EmergencyServicesJobTypeAbbreviationSchema.value

export const EmergencyServicesServiceSchema = z.enum([
  JobService.tarping,
  JobService.waterMitigation,
  JobService.boardUp,
])

export const EmergencyServicesJobSchema = BaseJobSchema.merge(
  z.object({
    jobType: EmergencyServicesJobTypeSchema,
    services: z.array(EmergencyServicesServiceSchema).transform((val) => Array.from(new Set(val))),
  })
)

export const isEmergencyServiceServices = (
  s: EmergencyServicesServiceEnum
): s is EmergencyServicesServiceEnum => EmergencyServicesServiceSchema.safeParse(s).success

export interface EmergencyServicesJob extends z.infer<typeof EmergencyServicesJobSchema> {}
export type EmergencyServicesServiceEnum = z.infer<typeof EmergencyServicesServiceSchema>
