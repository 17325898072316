import { EventBase } from '@eigtech/event-stream-types'
import { z } from 'zod'
import { MeetingConcatenationSchema } from '../base/meetingConcatenation.js'
import { MeetingCaptureSchema, MeetingDetailsSchema } from '../index.js'
import { MeetingsServiceEventBaseSchema, serviceEventType } from './service.js'

export const mediaPipelineEntityName = 'mediaPipeline'

const mediaPipelineEventType = <V extends string | undefined>(eventName?: V) =>
  serviceEventType(mediaPipelineEntityName, eventName)

export const MediaPipelineEventSchema = MeetingsServiceEventBaseSchema.merge(
  z.object({
    type: mediaPipelineEventType(),
    pipeline: z.unknown(),
  })
)

export const MediaCaptureStartedEventSchema = MediaPipelineEventSchema.merge(
  z.object({
    type: mediaPipelineEventType('captureStarted'),
    pipeline: MeetingCaptureSchema,
    meetingDetails: MeetingDetailsSchema,
  })
)

export const MediaCaptureStoppedEventSchema = MediaPipelineEventSchema.merge(
  z.object({
    type: mediaPipelineEventType('captureStopped'),
    pipeline: MeetingCaptureSchema,
    meetingDetails: MeetingDetailsSchema,
  })
)

export const MediaConcatenationStartedEventSchema = MediaPipelineEventSchema.merge(
  z.object({
    type: mediaPipelineEventType('concatenationStarted'),
    pipeline: MeetingConcatenationSchema,
    meetingDetails: MeetingDetailsSchema,
  })
)

export const MediaConcatenationStoppedEventSchema = MediaPipelineEventSchema.merge(
  z.object({
    type: mediaPipelineEventType('concatenationStopped'),
    pipeline: MeetingConcatenationSchema,
    meetingDetails: MeetingDetailsSchema,
  })
)

export const isMediaCaptureStartedEvent = (event: EventBase): event is MediaCaptureStartedEvent =>
  MediaCaptureStartedEventSchema.safeParse(event).success
export const isMediaCaptureStoppedEvent = (event: EventBase): event is MediaCaptureStoppedEvent =>
  MediaCaptureStoppedEventSchema.safeParse(event).success
export const isMediaConcatenationStartedEvent = (
  event: EventBase
): event is MediaConcatenationStartedEvent =>
  MediaConcatenationStartedEventSchema.safeParse(event).success
export const isMediaConcatenationStoppedEvent = (
  event: EventBase
): event is MediaConcatenationStoppedEvent =>
  MediaConcatenationStoppedEventSchema.safeParse(event).success

export type MediaCaptureStartedEvent = z.infer<typeof MediaCaptureStartedEventSchema>
export type MediaCaptureStoppedEvent = z.infer<typeof MediaCaptureStoppedEventSchema>
export type MediaConcatenationStartedEvent = z.infer<typeof MediaConcatenationStartedEventSchema>
export type MediaConcatenationStoppedEvent = z.infer<typeof MediaConcatenationStoppedEventSchema>
