// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/container.ts

import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'

const baseStyle = defineStyle({
  maxW: 'container.xl',
  my: '1rem',
  px: '1.5rem',
})

export const containerTheme = defineStyleConfig({
  baseStyle,
})
