import { ClaimStatusEventLaxSchema } from '@eigtech/claims-types'
import { ClaimEventSchema, EstimateEventSchema } from '@eigtech/claims-v2-types'
import z from 'zod'
import { TimelineAssignmentsEventSchema } from './assignments'

export const ClaimTimelineEventSchema = z.union([
  ClaimEventSchema,
  EstimateEventSchema,
  TimelineAssignmentsEventSchema,
  ClaimStatusEventLaxSchema,
])
export type ClaimTimelineEvent = z.infer<typeof ClaimTimelineEventSchema>
