import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema, MessageGroupIdSchema } from '../../base/index.js'

export const RemoveMessageGroupInboxRequestPathSchema = z.object({
  groupId: MessageGroupIdSchema,
  inboxId: Auth0IdSchema,
})
export const RemoveMessageGroupInboxRequestSchema = RemoveMessageGroupInboxRequestPathSchema

export type RemoveMessageGroupInboxRequest = z.infer<typeof RemoveMessageGroupInboxRequestSchema>

export const RemoveMessageGroupInboxResponseSchema = z.void()
export type RemoveMessageGroupInboxResponse = z.infer<typeof RemoveMessageGroupInboxResponseSchema>

export interface RemoveMessageGroupInboxHandler
  extends Handler<RemoveMessageGroupInboxRequest, RemoveMessageGroupInboxResponse> {}
