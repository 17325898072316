import { CohortEnumSchema } from '@eigtech/auth0-types'
import { Note } from '@eigtech/notes-types'
import { Corn } from '@eigtech/shared-corn'
import { HTTPError } from '@eigtech/ui-shared-api'
import { getAvailableVisibilityCohorts, getCohortLabel } from '@eigtech/ui-shared-cohorts'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  Stack,
  Text,
  WithDrawerErrorBoundaryProps,
  pluralize,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { useAddNoteCohort } from '../../api/addNoteCohort'
import { useInvalidateEntityNotebook } from '../../api/getNotebooks'

export type NoteVisibilityDrawerProps = ComposedDrawerWrapperProps & {
  note: Note
  entityCorn: Corn
  onSubmit?: () => void
}

export const NoteVisibilityDrawer = withDrawerErrorBoundary(
  function NoteVisibilityDrawer({
    note,
    entityCorn,
    onSubmit: propsOnSubmit,
    ...modalProps
  }: NoteVisibilityDrawerProps) {
    const availableCohorts = getAvailableVisibilityCohorts([entityCorn], 'note')

    const form = useForm({
      defaultValues: { cohorts: [CohortEnumSchema.Values.csr] },
    })

    const toast = useToast()
    const invalidateNotebook = useInvalidateEntityNotebook()

    const {
      mutateAsync: addNoteCohort,
      isError,
      error,
    } = useAddNoteCohort({ shouldInvalidate: false })
    const errorResponseCode = !!error && error instanceof HTTPError && error.response.status

    async function onSubmit(data: NoteVisibilityForm) {
      const cohorts = data.cohorts.filter(
        (cohort) =>
          cohort !== CohortEnumSchema.Values.csr && cohort !== CohortEnumSchema.Values.super
      )

      await Promise.all(
        cohorts.map(
          async (cohort) =>
            await addNoteCohort(
              { cohort, noteId: note.id },
              {
                onSuccess(__) {
                  invalidateNotebook(note.entityId as Corn)
                },
              }
            )
        )
      )
      toast({
        status: 'success',
        title: `${pluralize('Cohort', cohorts.length)} added!`,
        description: `Successfully added ${pluralize('cohort', cohorts.length)} (${cohorts
          .map((cohort) => getCohortLabel(cohort))
          .join(', ')}) to selected .`,
      })

      propsOnSubmit?.()
      modalProps.onClose()
    }

    return (
      <FormDrawer {...modalProps} {...baseProps} form={form} onSubmit={onSubmit}>
        <Stack spacing="6">
          <ComposedAlert
            alert={{
              title: 'Group Visibility',
              description: (
                <Stack>
                  <Text>
                    You may select which additional groups of users you would like these Notes to be
                    visible to. Every Note will always be visible to users in the coordinator group.
                  </Text>
                  <Text fontWeight="medium">
                    This is only additive; it will not remove any cohorts&apos; ability to see these
                    Notes.
                  </Text>
                </Stack>
              ),
            }}
          />

          <CheckboxGroupField
            label="Group Visibility"
            name="cohorts"
            options={availableCohorts.map((value) => ({
              value,
              label: getCohortLabel(value),
              checkboxProps: {
                isDisabled: value === CohortEnumSchema.Values.csr,
              },
            }))}
          />

          {isError && (
            <ComposedAlert
              alert={{
                title: 'Could not add cohorts to the note',
                description: !!errorResponseCode
                  ? `Response status: ${errorResponseCode}`
                  : 'Unknown error',
              }}
              status="error"
            />
          )}
        </Stack>
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps = {
  title: 'Make Note Visible to Additional Groups',
} satisfies WithDrawerErrorBoundaryProps

const NoteVisibilityFormSchema = z.object({
  cohorts: CohortEnumSchema.array().min(2, 'You must select at least one group'),
})

type NoteVisibilityForm = z.infer<typeof NoteVisibilityFormSchema>

const { useForm, CheckboxGroupField } = createForm(NoteVisibilityFormSchema, 'noteVisibilityForm')
