import { TextareaProps } from '@eigtech/ui-shared-dave'
import { FieldValues } from 'react-hook-form'
import { TextareaControl, TextareaControlProps } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type TextareaFieldProps<TFieldValues extends FieldValues = FieldValues> = WithFieldNameProp<
  TextareaControlProps,
  TFieldValues
> &
  Pick<TextareaProps, 'rows'>

export function TextareaField<TFieldValues extends FieldValues = FieldValues>({
  rows,
  textareaProps,
  ...props
}: TextareaFieldProps<TFieldValues>) {
  return (
    <TextareaControl
      textareaProps={{
        isDisabled: props.isDisabled,
        isReadOnly: props.isReadOnly,
        rows,
        ...textareaProps,
      }}
      {...props}
    />
  )
}

export type TextareaFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof TextareaField<TFieldValues>
