import { HStack, Switch, WrapChildrenWithText } from '@eigtech/ui-shared-dave'
import { FC, ReactNode } from 'react'
import { FieldValues, get, useController } from 'react-hook-form'
import { FormControl, SwitchControlProps } from 'react-hook-form-chakra'
import { WithFieldNameProp } from '../types/FieldProps'

export type SwitchFieldProps<TFieldValues extends FieldValues = FieldValues> = WithFieldNameProp<
  SwitchControlProps,
  TFieldValues
> &
  SwitchControlExtraProps

export function SwitchField<TFieldValues extends FieldValues = FieldValues>(
  props: SwitchFieldProps<TFieldValues>
) {
  return <SwitchControl {...props} />
}

export type SwitchFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof SwitchField<TFieldValues>

//

type SwitchControlExtraProps = {
  leftLabel?: ReactNode
  rightLabel?: ReactNode
}

export const SwitchControl: FC<SwitchControlProps> = (
  props: SwitchControlProps & SwitchControlExtraProps
) => {
  const { name, control, label, switchProps, leftLabel, rightLabel, ...rest } = props
  const {
    field,
    fieldState: { isTouched },
    formState: { isSubmitting, errors },
  } = useController({
    name,
    control,
    defaultValue: props.switchProps?.defaultChecked || false,
  })
  const error = get(errors, name, '')

  return (
    <FormControl control={control} label={label} name={name} {...rest}>
      <HStack>
        <WrapChildrenWithText textStyle="label">{leftLabel}</WrapChildrenWithText>
        <Switch
          {...field}
          id={name}
          isChecked={field.value}
          isDisabled={isSubmitting ?? rest.isDisabled ?? switchProps?.isDisabled}
          isInvalid={!!error && isTouched}
          isReadOnly={rest.isReadOnly ?? switchProps?.isReadOnly}
          {...switchProps}
        />
        <WrapChildrenWithText textStyle="label">{rightLabel}</WrapChildrenWithText>
      </HStack>
    </FormControl>
  )
}
