import z from 'zod'
import { TimelineEntityTypeSchema } from '../timeline'

export const StepFunctionErrorSchema = z.object({
  errorMessage: z.string(),
  errorType: z.string().optional(),
  trace: z.string().array().optional(),
})
export type StepFunctionError = z.infer<typeof StepFunctionErrorSchema>
export const isStepFunctionError = (error: unknown): error is StepFunctionError =>
  StepFunctionErrorSchema.safeParse(error).success

export const TimelineSyncErrorSchema = z.object({
  errorMessage: z.string(),
  entityType: TimelineEntityTypeSchema,
  executionId: z.string(),
})
export type TimelineSyncError = z.infer<typeof TimelineSyncErrorSchema>
export const isTimelineSyncError = (error: unknown): error is TimelineSyncError =>
  TimelineSyncErrorSchema.safeParse(error).success
