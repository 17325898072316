import { useUserContext } from '@eigtech/ui-shared-auth'
import { ACTION_MENU_ID, DataGrid, createColumnHelper } from '@eigtech/ui-shared-data-grid'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { ComposedCard } from '@eigtech/ui-shared-dave'
import { LinkButton, useRoutesContext } from '@eigtech/ui-shared-router'
import { useMemo } from 'react'
import { useGetClaim } from '../api'
import { DelegateAdminClaim } from '../types/DelegateAdminClaim'
import { EntityAdminClaim } from '../types/EntityAdminClaim'
import { renderEstimateAmount } from './renderEstimateAmount'

export type ClaimEstimatesProps = { claimNumber: string }

export function ClaimEstimates({ claimNumber }: ClaimEstimatesProps) {
  const { data: claim } = useGetClaim<DelegateAdminClaim | EntityAdminClaim>(claimNumber)

  const estimates = useMemo(() => Object.values(claim?.estimates ?? {}), [claim])

  return (
    <ComposedCard heading="Estimates">
      <DataGrid columns={columns} data={estimates} />
    </ComposedCard>
  )
}

type EstimatesRecord = Required<EntityAdminClaim>['estimates']
type Estimate = EstimatesRecord[keyof EstimatesRecord]

const columnHelper = createColumnHelper<Estimate>()

const columns = [
  columnHelper.display({
    id: ACTION_MENU_ID,
    cell: ({ row }) => <Actions estimate={row.original.estimate} />,
  }),
  // columnHelper.accessor('estimate.estimateId', {
  //   id: 'estimateId',
  //   header: 'ID',
  // }),
  // columnHelper.accessor('estimate.estimateType', {
  //   id: 'estimateType',
  //   header: 'Type',
  // }),
  // columnHelper.accessor('estimate.externalSource', {
  //   id: 'externalSource',
  //   header: 'External Source',
  // }),
  // columnHelper.accessor('estimate.externalSourceId', {
  //   id: 'externalSourceId',
  //   header: 'External Source ID',
  // }),
  // columnHelper.accessor('estimate.notes', {
  //   id: 'notes',
  //   header: 'Notes',
  // }),
  columnHelper.accessor('estimate.submissionDate', {
    id: 'submissionDate',
    header: 'Submission Date',
    cell: ({ getValue }) => <DateValue property="submissionDate">{getValue()}</DateValue>,
  }),
  columnHelper.accessor('estimate.submittedBy', {
    id: 'submittedBy',
    header: 'Submitted By',
    cell: ({ getValue }) => <UserValue property="submittedBy" user={getValue()} />,
  }),
  columnHelper.accessor('estimate.summary.grossEstimate', {
    id: 'grossEstimate',
    header: 'Estimate Amount',
    cell: ({ getValue }) => {
      const value = getValue()

      return value ? renderEstimateAmount(value) : null
    },
  }),
]

function Actions({ estimate }: { estimate: Estimate['estimate'] }) {
  const { claimDetailEstimateDetail } = useRoutesContext()

  if (!claimDetailEstimateDetail) {
    return null
  }

  return (
    <LinkButton
      size="sm"
      to={claimDetailEstimateDetail({
        claimNumber: estimate.claimNumber,
        estimateId: estimate.estimateId,
      })}
      variant="outline"
    >
      View Estimate
    </LinkButton>
  )
}

function DateValue(props: {
  children: string
  format?: string
  secondaryFormat?: string
  property: string
}) {
  const { PreferredDateTimeComponent } = useDatesContext()

  return <PreferredDateTimeComponent {...props} />
}

function UserValue(props: { user: string; property: string }) {
  const { PreferredUserComponent } = useUserContext()

  return <PreferredUserComponent {...props} />
}
