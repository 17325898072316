import {
  RemoveTagFromResourceBodyRequest,
  RemoveTagFromResourceRequest,
  RemoveTagFromResourceResponse,
} from '@eigtech/resource-tagging-types'
import { ApiRemoveRequest, useApiInstanceContext, useMutation } from '@eigtech/ui-shared-api'
import { tagsBasePath } from './constants'
import { useInvalidateTags } from './tags'

const removeTagFromResource =
  (remove: ApiRemoveRequest) =>
  ({ resourceCorn, ...payload }: RemoveTagFromResourceRequest) =>
    remove<RemoveTagFromResourceResponse, RemoveTagFromResourceBodyRequest>(
      `${tagsBasePath}/${resourceCorn}`,
      payload
    )

export function useRemoveTagFromResource() {
  const invalidateTags = useInvalidateTags()
  const { remove } = useApiInstanceContext()

  return useMutation({
    mutationFn: removeTagFromResource(remove),
    onSuccess() {
      invalidateTags()
    },
  })
}
