import {
  GetClaimLossReportsRequest,
  GetClaimLossReportsResponse,
  LossReportEntityId,
} from '@eigtech/riddler-types'
import { queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { get } from '../instance'
import { lossReportQueryKeys, riddlerBasePath } from './constants'

const latestIsPendingByClaim: Record<LossReportEntityId, boolean> = {}

const getLatestLossReport = async ({ entityId }: GetClaimLossReportsRequest) => {
  const { reports } = await get<GetClaimLossReportsResponse>(
    `${riddlerBasePath}/lossreports/${entityId}`
  )

  // Report IDs are timestamps, so they can be sorted this way
  const latestReport = reports.sort((a, b) =>
    new Date(a.reportId).getTime() < new Date(b.reportId).getTime() ? 1 : -1
  )[0]

  if (latestReport !== undefined) {
    latestIsPendingByClaim[entityId] =
      latestReport.status === 'pending' || latestReport.status === 'requested'
  }

  return { latestReport }
}

export function getLatestLossReportQueryOptions(entityId: LossReportEntityId) {
  const isPending = latestIsPendingByClaim[entityId]
  return queryOptions({
    queryKey: lossReportQueryKeys.latest(entityId),
    queryFn: () => getLatestLossReport({ entityId }),
    refetchInterval: () => (isPending ? 15_000 : false),
  })
}

export const useGetLatestLossReport = (entityId: LossReportEntityId) =>
  useQuery(getLatestLossReportQueryOptions(entityId))

export function useInvalidateLatestLossReport() {
  const queryClient = useQueryClient()

  return (claimNumber: LossReportEntityId) => {
    queryClient.invalidateQueries({ queryKey: lossReportQueryKeys.latest(claimNumber) })
  }
}
