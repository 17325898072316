import { Box, HStack } from '@eigtech/ui-shared-dave'
import { MobileNavigation } from './Navigation'

export function TopToolbar() {
  return (
    <HStack
      bg="white"
      display={{ base: 'block', md: 'none' }}
      justifyContent="space-between"
      px="4"
      py="2"
      shadow="md"
      w="full"
    >
      <Box>
        <MobileNavigation />
      </Box>
    </HStack>
  )
}
