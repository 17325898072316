import { ClaimEstimateReceivedDetails } from '@eigtech/summarizer-types'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { Link, useRoutesContext } from '@eigtech/ui-shared-router'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { startCase } from 'lodash-es'

export function EstimateReceivedDetails(
  props: EventDetailsComponentProps<ClaimEstimateReceivedDetails>
) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const { claimDetailEstimateDetail } = useRoutesContext()
  const {
    entityId,
    details: { estimateId, notes, estimateType, submissionDate, submittedBy },
  } = props

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Tag colorScheme="brandPrimary">
          {!!estimateType && startCase(estimateType)} Estimate Received
        </Tag>
        {!!submittedBy && (
          <Text as="span">
            by <Tag>{startCase(submittedBy)}</Tag>
          </Text>
        )}
        {!!submissionDate && (
          <Text as="span">
            on{' '}
            <PreferredDateTimeComponent property="submissionDate">
              {submissionDate}
            </PreferredDateTimeComponent>
          </Text>
        )}
      </HStack>

      {!!claimDetailEstimateDetail && (
        <Link to={claimDetailEstimateDetail({ claimNumber: entityId, estimateId })}>
          View Estimate
        </Link>
      )}

      {!!notes && <Blockquote whiteSpace="preserve">{notes}</Blockquote>}
    </VStack>
  )
}
