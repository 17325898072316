import log from '@eigtech/ui-shared-logging'
import { useState, useRef, useCallback } from 'react'

export type WakeLockOptions = {
  onError?: (error: Error) => void
  onRequest?: () => void
  onRelease?: EventListener
}

type WakeLockType = WakeLockSentinel['type']

export const useWakeLock = ({ onError, onRequest, onRelease }: WakeLockOptions = {}) => {
  const [released, setReleased] = useState<boolean | undefined>()
  const wakeLock = useRef<WakeLockSentinel | null>(null)

  const request = useCallback(
    async (type: WakeLockType = 'screen') => {
      const isWakeLockAlreadyDefined = wakeLock.current !== null

      if (!isSupported) {
        return log.warn(
          "Calling the `request` function has no effect, Wake Lock Screen API isn't supported"
        )
      }
      if (isWakeLockAlreadyDefined) {
        return log.warn('Calling `request` multiple times without `release` has no effect')
      }

      try {
        wakeLock.current = await navigator.wakeLock.request(type)

        wakeLock.current.onrelease = (e: Event) => {
          // Default to `true` - `released` API is experimental: https://caniuse.com/mdn-api_wakelocksentinel_released
          setReleased(wakeLock.current?.released ?? true)
          onRelease?.(e)
          wakeLock.current = null
        }

        onRequest?.()

        setReleased(wakeLock.current?.released ?? false)
      } catch (error: any) {
        log.error('error requesting wake lock', { error })
        onError?.(error)
      }
    },
    [onRequest, onError, onRelease]
  )

  const release = useCallback(async () => {
    const isWakeLockUndefined = wakeLock.current == null
    if (!isSupported) {
      return log.warn(
        "Calling the `release` function has no effect, Wake Lock Screen API isn't supported"
      )
    }

    if (isWakeLockUndefined) {
      return log.warn('Calling `release` before `request` has no effect.')
    }

    await wakeLock.current?.release()
  }, [])

  return {
    isSupported,
    request,
    released,
    release,
    type: wakeLock.current?.type || undefined,
  }
}

// https://caniuse.com/mdn-api_wakelock
const isSupported = typeof window !== 'undefined' && 'wakeLock' in navigator
