import { NewTableRow, Schema, TableRow, defineTable } from 'squid'

export type NewFieldTechnicianRecord = NewTableRow<typeof fieldTechnicianTable>
export type FieldTechnicianRecord = TableRow<typeof fieldTechnicianTable>

const fieldTechnicianTable = defineTable('field_technician', {
  id: Schema.String,
  name: Schema.String,
  email: Schema.nullable(Schema.String),
  phone: Schema.nullable(Schema.String),
  raw_address: Schema.nullable(Schema.String),
  street_1: Schema.nullable(Schema.String),
  street_2: Schema.nullable(Schema.String),
  city: Schema.nullable(Schema.String),
  state: Schema.nullable(Schema.String),
  zipcode: Schema.nullable(Schema.String),
})
