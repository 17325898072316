import { CohortEnum } from '@eigtech/auth0-types'
import { PortalZone } from '../base'

/**
 * DO NOT USE. This helper function is only here to map historical events.
 */
export const getCohortByPortalZone = (zone: PortalZone) => {
  const cohortToZoneMap: Record<PortalZone, CohortEnum> = {
    csr: 'csr',
    estimator: 'estimator',
    'external-estimator': 'estimator',
    tplap: 'insured',
  }
  return cohortToZoneMap[zone]
}
