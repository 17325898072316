import { AddDocumentCohortRequest, AddDocumentCohortResponse } from '@eigtech/documents-types'
import { contextualPut, useMutation } from '@eigtech/ui-shared-api'
import { documentsBasePath } from './constants'
import { useInvalidateEntityDocuments } from './getEntityDocuments'

const addDocumentCohort = ({ cohort, documentCorn, entityId }: AddDocumentCohortRequest) =>
  contextualPut<AddDocumentCohortResponse>(
    `${documentsBasePath}/${entityId}/${documentCorn}/cohort/${cohort}`,
    null,
    { responseType: 'none' }
  )

export function useAddDocumentCohort({
  shouldInvalidate = true,
}: { shouldInvalidate?: boolean } = {}) {
  const invalidateQueries = useInvalidateEntityDocuments()

  return useMutation({
    mutationFn: addDocumentCohort,
    onSuccess(__, { entityId }) {
      if (shouldInvalidate) {
        invalidateQueries(entityId)
      }
    },
  })
}
