import z from 'zod'
import { PortalConsumerInSchema, StagedPortalConsumerInSchema } from '../base/portal.js'
import { PortalsServiceEventBaseSchema, portalsServiceEventType } from './service.js'

const portalEventType = <V extends string | undefined>(eventName?: V) =>
  portalsServiceEventType('portal', eventName)

export const PortalEventSchema = PortalsServiceEventBaseSchema.merge(
  z.object({
    type: portalEventType(),
    portal: z.unknown(),
  })
)

export const PortalActivatedEventSchema = PortalEventSchema.merge(
  z.object({
    type: portalEventType('activated'),
    portal: PortalConsumerInSchema,
  })
)

export const PortalDeactivatedEventSchema = PortalEventSchema.merge(
  z.object({
    type: portalEventType('deactivated'),
    portal: PortalConsumerInSchema.or(StagedPortalConsumerInSchema),
  })
)

export const PortalExpirationUpdatedEventSchema = PortalEventSchema.merge(
  z.object({
    type: portalEventType('expirationUpdated'),
    portal: PortalConsumerInSchema.or(StagedPortalConsumerInSchema),
  })
)

export const PortalExpiredEventSchema = PortalEventSchema.merge(
  z.object({
    type: portalEventType('expired'),
    portal: PortalConsumerInSchema.or(StagedPortalConsumerInSchema),
  })
)

export const PortalStagedEventSchema = PortalEventSchema.merge(
  z.object({
    type: portalEventType('staged'),
    portal: StagedPortalConsumerInSchema,
  })
)

export type PortalEvent = z.infer<typeof PortalEventSchema>
export type PortalActivatedEvent = z.infer<typeof PortalActivatedEventSchema>
export type PortalDeactivatedEvent = z.infer<typeof PortalDeactivatedEventSchema>
export type PortalExpirationUpdatedEvent = z.infer<typeof PortalExpirationUpdatedEventSchema>
export type PortalExpiredEvent = z.infer<typeof PortalExpiredEventSchema>
export type PortalStagedEvent = z.infer<typeof PortalStagedEventSchema>
