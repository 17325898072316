import {
  FieldValues,
  FormFieldControl,
  FormFieldControlProps,
  WithFieldNameProp,
  useController,
} from '@eigtech/ui-shared-forms'
import { ForwardedRef, ReactNode, forwardRef } from 'react'
import { MentionsInput, MentionsInputProps } from './MentionsInput'
import { LexicalEditor } from 'lexical'

type OmittedProps = keyof FormFieldControlProps

type TopLevelMentionsProps<TFieldValues extends FieldValues = FieldValues> = WithFieldNameProp<
  Omit<MentionsInputProps, OmittedProps>,
  TFieldValues
>

export type MentionsInputFieldProps<TFieldValues extends FieldValues = FieldValues> =
  TopLevelMentionsProps<TFieldValues> & {
    label?: ReactNode
    fieldProps?: Omit<FormFieldControlProps, 'label' | 'name'>
    mentionsProps?: Partial<Omit<MentionsInputProps, keyof TopLevelMentionsProps<TFieldValues>>>
  }

function MentionsInputFieldComponent<TFieldValues extends FieldValues = FieldValues>(
  { name, label, fieldProps, mentionsProps, ...props }: MentionsInputFieldProps<TFieldValues>,
  ref: ForwardedRef<LexicalEditor>
) {
  const {
    field: { value: __value, onBlur: __onBlur, ...field },
    formState: { isSubmitting },
  } = useController({
    name,
    control: fieldProps?.control,
  })

  return (
    <FormFieldControl {...fieldProps} label={label} name={name}>
      <MentionsInput
        {...field}
        editorRef={ref}
        isDisabled={isSubmitting ?? fieldProps?.isDisabled ?? mentionsProps?.isDisabled}
        isReadOnly={fieldProps?.isReadOnly ?? mentionsProps?.isReadOnly}
        {...mentionsProps}
        {...(props as MentionsInputProps)}
      />
    </FormFieldControl>
  )
}

export const MentionsInputField = forwardRef(MentionsInputFieldComponent) as <
  TFieldValues extends FieldValues = FieldValues,
>(
  props: MentionsInputFieldProps<TFieldValues> & { ref?: ForwardedRef<LexicalEditor> }
) => JSX.Element

export type MentionsInputFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  typeof MentionsInputField<TFieldValues>

// MentionsInputFieldProps<TFieldValues>

// interface ForwardRefWithGenerics extends React.FC<WithForwardRefProps<Option>> {
//   <T extends Option>(props: WithForwardRefProps<T>): ReturnType<
//     React.FC<WithForwardRefProps<T>>
//   >;
// }
