import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { BaseJobSchema } from '../../base/baseJob.js'
import { JobSchema, JobTypeSchema } from '../../base/job.js'
import { JobServicesSchema } from '../../base/jobService.js'

export const ReceiveJobRequestBodySchema = BaseJobSchema.omit({
  jobId: true,
  jobNumber: true,
}).merge(
  z.object({
    jobType: JobTypeSchema,
    services: JobServicesSchema,
  })
)
export const ReceiveJobRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const ReceiveJobRequestSchema = ReceiveJobRequestBodySchema.merge(
  ReceiveJobRequestHeadersSchema
)
export const ReceiveJobResponseSchema = JobSchema
export const ReceiveJobApiSchema: ApiSchema = {
  body: ReceiveJobRequestBodySchema,
  headers: ReceiveJobRequestHeadersSchema,
}

export type ReceiveJobRequestBody = z.infer<typeof ReceiveJobRequestBodySchema>

export interface ReceiveJobRequest extends z.infer<typeof ReceiveJobRequestSchema> {}
export type ReceiveJobResponse = z.infer<typeof ReceiveJobResponseSchema>
export interface ReceiveJobHandler extends Handler<ReceiveJobRequest, ReceiveJobResponse> {}
