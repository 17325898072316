import { ProducerUserTypesEnumSchema } from '@eigtech/auth0-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { PortalEntityTypeSchema } from '../../base/portal'
import { PortalZoneSchema } from '../../base/portal.js'
import { PortalUserSchema } from '../../base/user.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const CreatePortalUserRequestBodySchema = z.object({
  auth0Id: z.string().optional(),
  email: z.string().email(),
  entityPermissions: z.string().array(),
  entityType: PortalEntityTypeSchema,
  externalUserId: z.string(),
  name: z.string(),
  requestActor: z.string(),
  userType: ProducerUserTypesEnumSchema,
  zone: PortalZoneSchema,
})

export const CreatePortalUserRequestSchema = CreatePortalUserRequestBodySchema

export const CreatePortalUserResponseSchema = z.object({ user: PortalUserSchema.or(z.null()) })

export type CreatePortalUserRequestBody = z.infer<typeof CreatePortalUserRequestBodySchema>
export type CreatePortalUserRequest = z.infer<typeof CreatePortalUserRequestSchema>
export type CreatePortalUserResponse = z.infer<typeof CreatePortalUserResponseSchema>

export interface CreatePortalUserHandler
  extends Handler<CreatePortalUserRequest, CreatePortalUserResponse> {}

export const CreatePortalUserApiSchema: ApiSchema = {
  body: CreatePortalUserRequestBodySchema,
  response: CreatePortalUserResponseSchema,
}
