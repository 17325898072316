import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { JobExternalIdSchema, JobIdSchema } from '../../base/index.js'

export const RecordDepositPaidRequestBodySchema = z.object({
  paidBy: ContactCornSchema.or(z.string()),
  paidDate: z.string().datetime(),
  paidInFull: z.boolean(),
  externalId: JobExternalIdSchema.optional(),
})
export const RecordDepositPaidRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordDepositPaidRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordDepositPaidRequestSchema = RecordDepositPaidRequestBodySchema.merge(
  RecordDepositPaidRequestHeadersSchema
).merge(RecordDepositPaidRequestPathSchema)
export const RecordDepositPaidResponseSchema = z.void()
export const RecordDepositPaidApiSchema: ApiSchema = {
  body: RecordDepositPaidRequestBodySchema,
  headers: RecordDepositPaidRequestHeadersSchema,
  params: RecordDepositPaidRequestPathSchema,
}

export interface RecordDepositPaidRequestBody
  extends z.infer<typeof RecordDepositPaidRequestBodySchema> {}
export interface RecordDepositPaidRequestPath
  extends z.infer<typeof RecordDepositPaidRequestPathSchema> {}
export interface RecordDepositPaidRequest extends z.infer<typeof RecordDepositPaidRequestSchema> {}
export type RecordDepositPaidResponse = z.infer<typeof RecordDepositPaidResponseSchema>
export interface RecordDepositPaidHandler
  extends Handler<RecordDepositPaidRequest, RecordDepositPaidResponse> {}
