import { Auth0Id, MessageStatusSchema } from '@eigtech/messaging-types'
import {
  ComposedDrawerWrapperProps,
  WithDrawerErrorBoundaryProps,
  useToast,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm, zodEnumWithUserFriendlyError } from '@eigtech/ui-shared-forms'
import { startCase } from 'lodash-es'
import { z } from 'zod'
import { useArchiveAllMessage, useUpdateAllMessageStatuses } from '../api'

type InboxAllMessagesFormDrawerProps = ComposedDrawerWrapperProps & {
  inboxId: Auth0Id
}

export const InboxAllMessagesFormDrawer = withDrawerErrorBoundary(
  function InboxAllMessagesFormDrawer(props: InboxAllMessagesFormDrawerProps) {
    const form = useForm()

    const toast = useToast()

    const { mutateAsync: archiveAllMessages } = useArchiveAllMessage()
    const { mutateAsync: updateAllMessageStatuses } = useUpdateAllMessageStatuses()

    async function handleSubmit({ messageStatus }: UpdateAllMessagesForm) {
      const { inboxId } = props
      if (messageStatus === 'ARCHIVED') {
        await archiveAllMessages({ inboxId })
      } else {
        await updateAllMessageStatuses({ inboxId, messageStatus })
      }

      toast({
        status: 'success',
        title: 'It may take a few minutes for messages to be updated.',
      })
      props.onClose()
    }

    return (
      <FormDrawer {...props} {...baseProps} form={form} onSubmit={handleSubmit}>
        <SelectField
          label="Message Status"
          name="messageStatus"
          options={[
            { value: MessageStatusSchema.Values.READ, label: 'Read' },
            { value: MessageStatusSchema.Values.UNREAD, label: 'Unread' },
            { value: MessageStatusSchema.Values.ARCHIVED, label: 'Archive' },
          ]}
        />
      </FormDrawer>
    )
  },
  () => baseProps
)

const baseProps: WithDrawerErrorBoundaryProps = {
  title: 'Update All Messages',
}

export const UpdateAllMessagesSchema = z.object({
  messageStatus: zodEnumWithUserFriendlyError(MessageStatusSchema.options, {
    optionFormatter: (option) => startCase(option.toLocaleLowerCase()),
  }),
})

type UpdateAllMessagesForm = z.infer<typeof UpdateAllMessagesSchema>

const { useForm, SelectField } = createForm(UpdateAllMessagesSchema, 'updateAllMessagesForm')
