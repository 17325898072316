import { Corn } from '@eigtech/shared-corn'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'
import { pick } from 'lodash-es'

export const assignmentsBasePath = 'assignments'

const { all } = makeBasicQueryKeys('assignments')
const assignable = pick(makeBasicQueryKeys([...all(), 'assignable']), ['details'])
const assignee = pick(makeBasicQueryKeys([...all(), 'assignee']), ['details'])

export const assignmentsQueryKeys = {
  all,
  assignable: {
    ...assignable,
    detail: (corn: Corn) => [...assignmentsQueryKeys.assignable.details(), corn],
  },
  assignee: {
    ...assignee,
    detail: (corn: Corn) => [...assignmentsQueryKeys.assignee.details(), corn],
  },
}
