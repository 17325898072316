import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { MessageGroupIdSchema } from '../../base/index.js'

export const RemoveMessageGroupRequestPathSchema = z.object({
  groupId: MessageGroupIdSchema,
})
export const RemoveMessageGroupRequestSchema = RemoveMessageGroupRequestPathSchema

export type RemoveMessageGroupRequestBody = z.infer<typeof RemoveMessageGroupRequestSchema>
export type RemoveMessageGroupRequest = z.infer<typeof RemoveMessageGroupRequestSchema>

export const RemoveMessageGroupResponseSchema = z.void()
export type RemoveMessageGroupResponse = z.infer<typeof RemoveMessageGroupResponseSchema>

export interface RemoveMessageGroupHandler
  extends Handler<RemoveMessageGroupRequest, RemoveMessageGroupResponse> {}
