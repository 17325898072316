import { Corn } from '@eigtech/shared-corn'
import {
  AspectRatio,
  chakra,
  ComposedCard,
  ComposedCardProps,
  forwardRef,
  Image,
  SkeletonWrapper,
  Stack,
} from '@eigtech/ui-shared-dave'
import { FC, useEffect } from 'react'
import { useGetDocumentUrl } from '../api/getDocumentUrl'
import { AugmentedDocument } from '../types/AugmentedDocument'
import { DocumentPropertyList } from './DocumentCard'

export type MediaCardProps = {
  entityId: Corn
  media: AugmentedDocument
  MediaActions?: FC<{ document: AugmentedDocument }>
} & ComposedCardProps

export const MediaCard = forwardRef<MediaCardProps, 'div'>(
  ({ entityId, media, MediaActions, ...props }, ref) => {
    const { data: url, isPending, refetch } = useGetDocumentUrl({ entityId, documentId: media.id })

    useEffect(() => {
      refetch()
    }, [refetch])

    return (
      <ComposedCard
        ref={ref}
        cardBodyProps={{ p: 0 }}
        cardFooterProps={{ flexGrow: 1, flexDir: 'column', alignItems: 'stretch' }}
        display="flex"
        flexDir="column"
        {...props}
        footer={
          <Stack>
            <DocumentPropertyList document={media} entityId={entityId} />

            {!!MediaActions && <MediaActions document={media} />}
          </Stack>
        }
      >
        <chakra.a href={url} target={media.id}>
          <AspectRatio objectFit="contain" ratio={4 / 3}>
            <SkeletonWrapper h="20" isLoaded={!isPending} w="full">
              <Image src={url} sx={{ objectFit: 'contain !important' }} w="full" />
            </SkeletonWrapper>
          </AspectRatio>
        </chakra.a>
      </ComposedCard>
    )
  }
)
