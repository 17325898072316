import { useUpdateContact } from '@eigtech/ui-shared-contacts'
import {
  Center,
  CircularProgress,
  ComposedAlert,
  ComposedDrawerWrapperProps,
  useToast,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { z } from 'zod'
import { useGetEstimatorUserContact } from '../../../api'

export type EstimatorLicenseDrawerProps = ComposedDrawerWrapperProps

export function EstimatorLicenseDrawer(props: EstimatorLicenseDrawerProps) {
  const {
    data: contact,
    isPending: isPendingContact,
    isError: isContactError,
  } = useGetEstimatorUserContact()

  const form = useForm({
    defaultValues: {
      licenseNumber: contact?.metadata.licenseNumber ?? undefined,
    },
  })

  const { mutateAsync: updateContact, isError: isUpdateError } = useUpdateContact()

  const toast = useToast()

  async function onSubmit({ licenseNumber }: EstimatorLicenseForm) {
    if (!contact) return

    await updateContact({
      ...contact,
      metadata: {
        ...contact.metadata,
        licenseNumber,
      },
    })

    toast({
      status: 'success',
      title: 'Successfully updated your license number!',
    })

    props.onClose()
  }

  return (
    <FormDrawer {...props} form={form} title="Update Your License" onSubmit={onSubmit}>
      {isPendingContact ? (
        <Center>
          <CircularProgress isIndeterminate size={100} />
        </Center>
      ) : isContactError ? (
        <ComposedAlert
          alert={{
            title: 'Could not load your contact information',
            description: 'Please try again. If the issue persists, contact EIG Tech Support.',
          }}
          status="error"
        />
      ) : (
        <InputField label="License Number" name="licenseNumber" />
      )}

      {isUpdateError && (
        <ComposedAlert
          alert={{
            title: 'Could not update your license number',
            description: 'Please try again. If the issue persists, contact EIG Tech Support.',
          }}
          status="error"
        />
      )}
    </FormDrawer>
  )
}

const EstimatorLicenseFormSchema = z.object({
  licenseNumber: z.string().min(1, 'License Number field is required'),
})

type EstimatorLicenseForm = z.infer<typeof EstimatorLicenseFormSchema>

const { useForm, InputField } = createForm(EstimatorLicenseFormSchema, 'estimatorLicenseForm')
