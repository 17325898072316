import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { BookCornSchema, BookMetadataSchema } from '../../../base/books.js'

export const CreateBookRequestBodySchema = BookMetadataSchema.extend({
  bookId: BookCornSchema.optional(),
})

export const CreateBookRequestSchema = CreateBookRequestBodySchema

export const CreateBookResponseSchema = z.object({
  bookId: BookCornSchema,
})

export type CreateBookRequestBody = z.infer<typeof CreateBookRequestBodySchema>

export type CreateBookRequest = z.infer<typeof CreateBookRequestSchema>

export type CreateBookResponse = z.infer<typeof CreateBookResponseSchema>

export interface CreateBookHandler extends Handler<CreateBookRequest, CreateBookResponse> {}

export const CreateBookApiSchema: ApiSchema = {
  body: CreateBookRequestSchema,
  response: CreateBookResponseSchema,
}
