import { CohortEnumSchema } from '@eigtech/auth0-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { DocumentSchemaV2, DocumentV2 } from '../aggregates/index.js'

export const AddDocumentFromS3BodySchema = DocumentSchemaV2.pick({
  type: true,
  parentFilename: true,
  entityId: true,
  filename: true,
  notes: true,
  source: true,
}).merge(
  z.object({
    description: z.string().optional(),
    bucketName: z.string(),
    key: z.string(),
    userCohorts: CohortEnumSchema.array().optional(),
  })
)

export const AddDocumentFromS3RequestSchema = AddDocumentFromS3BodySchema
export type AddDocumentFromS3Request = z.infer<typeof AddDocumentFromS3RequestSchema>
export type AddDocumentFromS3Response = DocumentV2
export type AddDocumentFromS3Handler = Handler<AddDocumentFromS3Request, AddDocumentFromS3Response>
