import z from 'zod'

export const EstimatorQueryModelSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  'GSI-PK-1': z.string().optional(),
  'GSI-SK-1': z.string().optional(),
})

export type EstimatorQueryModel = z.infer<typeof EstimatorQueryModelSchema>
