import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'

export const claimsSortFunctions = {
  carrier: (a, b) => (b.carrier ?? '').localeCompare(a.carrier ?? ''),
  catCode: (a, b) => (b.catCode ?? '').localeCompare(a.catCode ?? ''),
  claimNumber: (a, b) => b.claimNumber.localeCompare(a.claimNumber),
  claimStatus: (a, b) => (b.claimStatus ?? '').localeCompare(a.claimStatus ?? ''),
  // dateAssigned: (a, b) => new Date(b.dateAssigned).getTime() - new Date(a.dateAssigned).getTime(),
  dateReceived: (a, b) =>
    new Date(b.dateReceived ?? new Date()).getTime() -
    new Date(a.dateReceived ?? new Date()).getTime(),
  inspectionScheduled: (a, b) =>
    (!!b.inspectionScheduled ? new Date(b.inspectionScheduled).getTime() : new Date().getTime()) -
    (!!a.inspectionScheduled ? new Date(a.inspectionScheduled).getTime() : new Date().getTime()),
  inAssignQueue: (a, b) =>
    new Date(b.inAssignQueue ?? new Date()).getTime() -
    new Date(a.inAssignQueue ?? new Date()).getTime(),
  lossDate: (a, b) =>
    new Date(b.lossDate ?? new Date()).getTime() - new Date(a.lossDate ?? new Date()).getTime(),
  // lossType: (a, b) => b.lossType.localeCompare(a.lossType),
  lossLocation: (a, b) => {
    const lossLocationA = `${a.lossLocation.state}${a.lossLocation.city}`
    const lossLocationB = `${b.lossLocation.state}${b.lossLocation.city}`
    return lossLocationB.localeCompare(lossLocationA)
  },
  primaryContact: (a, b) =>
    (b.primaryContact?.name ?? '').localeCompare(a.primaryContact?.name ?? ''),
} as const satisfies Partial<
  Record<
    keyof DelegateAdminClaimSummary,
    (a: DelegateAdminClaimSummary, b: DelegateAdminClaimSummary) => number
  >
>
