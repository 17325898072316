import { initializeHeap } from '@eigtech/ui-shared-analytics'
import { IS_DEV } from '@eigtech/ui-shared-constants'

const prodHeapAppId = '914779864'
const devHeapAppId = '2631734432'

const isDev = IS_DEV

const stageName = import.meta.env.VITE_STAGE_NAME ?? 'prod'
const isDevStage = !stageName.startsWith('prod')

const heapAppId = isDev || isDevStage ? devHeapAppId : prodHeapAppId

const { heap, useHeap } = initializeHeap(heapAppId)

export { heap, useHeap }
