import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/base'
import { EstimateReviewSchema } from '../model/estimate'
import { BaseHeaderSchema } from './base'

const ReviewEstimateSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: EstimateReviewSchema.omit({ claimNumber: true }).partial({
    estimateId: true,
  }),
  headers: BaseHeaderSchema,
  response: EstimateReviewSchema,
}

export const ReviewEstimateRequestSchema = ReviewEstimateSchemas.params
  .merge(ReviewEstimateSchemas.body)
  .merge(ReviewEstimateSchemas.headers)

export type ReviewEstimateHeaderRequest = z.infer<typeof ReviewEstimateSchemas.headers>
export type ReviewEstimateBodyRequest = z.infer<typeof ReviewEstimateSchemas.body>
export type ReviewEstimateRequest = z.infer<typeof ReviewEstimateRequestSchema>
export type ReviewEstimateResponse = z.infer<typeof ReviewEstimateSchemas.response>

export interface ReviewEstimateHandler
  extends Handler<ReviewEstimateRequest, ReviewEstimateResponse> {}

export const ReviewEstimateApiSchema: ApiSchema = ReviewEstimateSchemas
