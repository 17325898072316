import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const serviceName = 'documents'
export const documentServiceEventType = <
  U extends string | undefined,
  V extends string | undefined,
>(
  entity?: U,
  eventName?: V
) => eventType(serviceName, entity, eventName)

export const DocumentServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: documentServiceEventType(),
  })
)

export type DocumentServiceEventBase = z.infer<typeof DocumentServiceEventBaseSchema>
