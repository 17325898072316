import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { PublicationTargetsSchema } from '../aggregates'
import { DocumentMarkedForPublicationEventSchemaV2 } from '../events/documents'

/** Mark  for publication */
export const MarkForPublicationBodyRequestSchema = z.object({
  publicationTargets: PublicationTargetsSchema.array(),
  publicationAttributes:
    DocumentMarkedForPublicationEventSchemaV2.shape.document.shape.publicationAttributes,
})

export const MarkForPublicationPathRequestSchema = z.object({
  entityId: CornSchema,
  documentCorn: z.string(),
})

export const MarkForPublicationHeadersRequestSchema = ApiLambdalerAuthHeaderSchema

export const MarkForPublicationRequestSchema = MarkForPublicationBodyRequestSchema.merge(
  MarkForPublicationPathRequestSchema
).merge(MarkForPublicationHeadersRequestSchema)

export type MarkForPublicationBodyRequest = z.infer<typeof MarkForPublicationBodyRequestSchema>
export type MarkForPublicationPathRequest = z.infer<typeof MarkForPublicationPathRequestSchema>
export type MarkForPublicationRequest = z.infer<typeof MarkForPublicationRequestSchema>
export type MarkForPublicationResponse = void
export type MarkForPublicationHandler = Handler<
  MarkForPublicationRequest,
  MarkForPublicationResponse
>
