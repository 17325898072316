import { z } from 'zod'
import { eventType, EventBaseSchema } from '@eigtech/event-stream-types'

export const assignmentsServiceName = 'assignments'
export const serviceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(assignmentsServiceName, entity, eventName)

export const AssignmentsServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: serviceEventType(),
  })
)

export const AssignmentsServiceRequestActorEnumSchema = z.enum(['CONTACT_MERGED'])
export const AssignmentsServiceRequestActor = AssignmentsServiceRequestActorEnumSchema.Values

export type AssignmentsServiceEventBase = z.infer<typeof AssignmentsServiceEventBaseSchema>
export type AssignmentsServiceRequestActorEnum = z.infer<
  typeof AssignmentsServiceRequestActorEnumSchema
>
