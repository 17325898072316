import { BookCorn, ContactCorn } from '@eigtech/contacts-types'
import { makeBasicQueryKeys } from '@eigtech/ui-shared-api'

export const contactsBasePath = 'contacts'
export const contactBasePath = `${contactsBasePath}/contact`

export const contactsQueryKeys = {
  ...makeBasicQueryKeys<ContactCorn>('contacts'),
  books: (contactId: ContactCorn) => [...contactsQueryKeys.detail(contactId), 'books'],
}

export const booksBasePath = `${contactsBasePath}/books`
export const bookBasePath = `${contactsBasePath}/book`
export const globalBookBasePath = `${contactsBasePath}/global-book`

export const booksQueryKeys = {
  ...makeBasicQueryKeys<BookCorn>('books'),
}
