import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AssignmentCanonSchema } from '../entities/assignment'

export const GetAssignmentsForAssigneeApiSchema = {
  params: z.object({
    assigneeId: z.string(),
  }),
  response: AssignmentCanonSchema.array(),
} satisfies ApiSchema

export const GetAssignmentsForAssigneePathRequestSchema = GetAssignmentsForAssigneeApiSchema.params

export const GetAssignmentsForAssigneeRequestSchema = GetAssignmentsForAssigneePathRequestSchema

export const GetAssignmentsForAssigneeResponseSchema = GetAssignmentsForAssigneeApiSchema.response

export type GetAssignmentsForAssigneePathRequest = z.infer<
  typeof GetAssignmentsForAssigneePathRequestSchema
>
export type GetAssignmentsForAssigneeRequest = z.infer<
  typeof GetAssignmentsForAssigneeRequestSchema
>
export type GetAssignmentsForAssigneeResponse = z.infer<
  typeof GetAssignmentsForAssigneeResponseSchema
>
export type GetAssignmentsForAssigneeHandler = Handler<
  GetAssignmentsForAssigneeRequest,
  GetAssignmentsForAssigneeResponse
>
