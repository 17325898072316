import { CohortEnumSchema } from '@eigtech/auth0-types'
import { DocumentV2 as DocumentType } from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { Corn } from '@eigtech/shared-corn'
import { HTTPError } from '@eigtech/ui-shared-api'
import { getAvailableVisibilityCohorts, getCohortLabel } from '@eigtech/ui-shared-cohorts'
import {
  ComposedAlert,
  ComposedDrawerWrapperProps,
  Stack,
  Text,
  WithDrawerErrorBoundaryProps,
  asyncForEach,
  pluralize,
  useToast,
  wait,
  withDrawerErrorBoundary,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm } from '@eigtech/ui-shared-forms'
import { chunk, startCase } from 'lodash-es'
import { z } from 'zod'
import { useAddDocumentCohort } from '../../api/addDocumentCohort'
import { useInvalidateEntityDocuments } from '../../api/getEntityDocuments'

export type DocumentVisibilityDrawerBaseProps = {
  documents: DocumentType[] | (() => DocumentType[])
  documentType: 'document' | 'media'
  entityCorn: Corn
  onSubmit?: () => void
}

export type DocumentVisibilityDrawerProps = ComposedDrawerWrapperProps &
  DocumentVisibilityDrawerBaseProps

export const DocumentVisibilityDrawer = withDrawerErrorBoundary(
  function DocumentVisibilityDrawer(props: DocumentVisibilityDrawerProps) {
    const { documents, documentType, entityCorn, onSubmit: propsOnSubmit, ...modalProps } = props

    const form = useForm({
      defaultValues: { cohorts: [CohortEnumSchema.Values.csr] },
    })

    const availableCohorts = getAvailableVisibilityCohorts([entityCorn], 'document')
    const getDocuments = () => (Array.isArray(documents) ? documents : documents())

    const toast = useToast()

    const invalidateQueries = useInvalidateEntityDocuments()

    const {
      mutateAsync: addDocumentCohort,
      isError,
      error,
    } = useAddDocumentCohort({ shouldInvalidate: false })
    const errorResponseCode = !!error && error instanceof HTTPError && error.response.status

    async function onSubmit(data: DocumentVisibilityForm) {
      const documents = getDocuments()

      const cohorts = data.cohorts.filter(
        (cohort) =>
          cohort !== CohortEnumSchema.Values.csr && cohort !== CohortEnumSchema.Values.super
      )

      const documentsWithCohorts = documents.flatMap((document) =>
        cohorts.map((cohort) => ({ documentCorn: ensureDocumentCorn(document.id), cohort }))
      )
      const batches = chunk(documentsWithCohorts, 10)

      await asyncForEach(batches, async (batch) => {
        const promises = batch.map(async ({ cohort, documentCorn }) =>
          addDocumentCohort(
            {
              entityId: entityCorn,
              cohort,
              documentCorn,
            },
            {
              onSuccess(__, { entityId }) {
                invalidateQueries(entityId)
              },
            }
          )
        )

        await Promise.all(promises)

        await wait(300)
      })

      toast({
        status: 'success',
        title: `${pluralize('Cohort', cohorts.length)} added!`,
        description: `Successfully added ${pluralize('cohort', cohorts.length)} (${cohorts
          .map((cohort) => getCohortLabel(cohort))
          .join(', ')}) to selected ${pluralize(documentType, documents.length)}.`,
      })

      propsOnSubmit?.()
      modalProps.onClose()
    }

    return (
      <FormDrawer {...modalProps} {...baseProps(props)} form={form} onSubmit={onSubmit}>
        <Stack spacing="6">
          <ComposedAlert
            alert={{
              title: 'Group Visibility',
              description: (
                <Stack>
                  <Text>
                    You may select which additional groups of users you would like these{' '}
                    {documentType}s to be visible to. Every {documentType} will always be visible to
                    users in the coordinator group.
                  </Text>
                  <Text fontWeight="medium">
                    This is only additive; it will not remove any cohorts&apos; ability to see these{' '}
                    {documentType}s.
                  </Text>
                </Stack>
              ),
            }}
          />

          <CheckboxGroupField
            label="Group Visibility"
            name="cohorts"
            options={availableCohorts.map((value) => ({
              label: getCohortLabel(value),
              value,
              checkboxProps: {
                isDisabled: value === CohortEnumSchema.Values.csr,
              },
            }))}
          />

          {isError && (
            <ComposedAlert
              alert={{
                title: 'Could not add cohorts to documents',
                description: !!errorResponseCode
                  ? `Response status: ${errorResponseCode}`
                  : 'Unknown error',
              }}
              status="error"
            />
          )}
        </Stack>
      </FormDrawer>
    )
  },
  (props) => baseProps(props)
)

const baseProps = ({ documentType }: DocumentVisibilityDrawerProps) =>
  ({
    title: `Make ${startCase(documentType)}s Visible to Additional Groups`,
  }) satisfies WithDrawerErrorBoundaryProps

const DocumentVisibilityFormSchema = z.object({
  cohorts: CohortEnumSchema.array().min(2, 'You must select at least one group'),
})

type DocumentVisibilityForm = z.infer<typeof DocumentVisibilityFormSchema>

const { useForm, CheckboxGroupField } = createForm(
  DocumentVisibilityFormSchema,
  'documentVisibilityForm'
)
