import {
  CallbackRequest,
  CallbackResponseRequest,
  CallbackStatusEnum,
} from '@eigtech/communications-types'
import { CurrentClaimCorn } from '@eigtech/shared-corn-helper'
import { useCanPublishClaimInfoToXA } from '@eigtech/ui-shared-claims'
import {
  ComposedDrawerWrapperProps,
  Divider,
  Stack,
  useDisclosure,
  useToast,
} from '@eigtech/ui-shared-dave'
import { FormDrawer, createForm, zodEnumWithUserFriendlyError } from '@eigtech/ui-shared-forms'
import { CreateEntityNoteDrawer } from '@eigtech/ui-shared-notes'
import { startCase } from 'lodash-es'
import { z } from 'zod'
import { useRespondToCallback } from '../api/respondToCallback'
import { statusesArray } from '../constants/statuses'

export type CallbackStatusDrawerProps = ComposedDrawerWrapperProps &
  Pick<CallbackRequest, 'role'> & {
    entityCorn: CallbackRequest['entityId']
    entity?: Record<string, unknown>
  }

export function CallbackStatusDrawer({
  entityCorn: propsEntityCorn,
  role,
  entity,
  ...props
}: CallbackStatusDrawerProps) {
  const toast = useToast()

  const entityCorn =
    'claimNumber' in props ? (props.claimNumber as CurrentClaimCorn) : propsEntityCorn

  const includePublishField = useCanPublishClaimInfoToXA({ claimCorn: entityCorn })

  const noteFormDrawer = useDisclosure()

  const form = useForm({
    defaultValues: {
      includeNote: false,
      status: '' as CallbackRequest['status'],
    },
  })

  const { mutateAsync: respondToCallback } = useRespondToCallback()

  async function handleSubmit(data: CallbackStatusForm) {
    const payload: CallbackResponseRequest = {
      entityId: entityCorn,
      role,
      status: data.status,
    }

    await respondToCallback(payload)

    toast({
      status: 'success',
      title: 'Successfully updated the status for this callback!',
    })

    if (data.includeNote) {
      noteFormDrawer.onOpen()
    } else {
      props.onClose()
    }
  }

  return (
    <>
      <FormDrawer {...props} form={form} title="Update Callback Status" onSubmit={handleSubmit}>
        <Stack h="full" spacing="4">
          <SelectField
            label="Select Status"
            name="status"
            options={statusesArray}
            placeholder="Select a status"
          />

          <Divider />

          <CheckboxField
            helperText="If selected, you'll be prompted to create a note after this form has been submitted."
            label="Include Note?"
            name="includeNote"
          />
        </Stack>
      </FormDrawer>

      {noteFormDrawer.isOpen && (
        <CreateEntityNoteDrawer
          {...noteFormDrawer}
          entityCorn={entityCorn}
          includePublishField={includePublishField}
          includeTemplateField
          templateParams={entity}
          onClose={() => {
            noteFormDrawer.onClose()
            props.onClose()
          }}
        />
      )}
    </>
  )
}

const CallbackStatusSchema = z.object({
  includeNote: z.boolean(),
  status: zodEnumWithUserFriendlyError(CallbackStatusEnum.options, {
    optionFormatter: (option) => startCase(option.toLocaleLowerCase()),
  }),
})

type CallbackStatusForm = z.infer<typeof CallbackStatusSchema>

const { useForm, SelectField, CheckboxField } = createForm(
  CallbackStatusSchema,
  'callbackStatusForm'
)
