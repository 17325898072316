import { GetAssignmentsForAssignableResponse } from '@eigtech/assignments-types'
import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { Book } from '@eigtech/contacts-types'
import { Corn } from '@eigtech/shared-corn'
import { last } from 'lodash-es'
import { useMemo } from 'react'
import { useGetAssignmentsForEntity } from './getAssignmentsForEntity'

export type UseGetContactAssignmentForClaimProps = { contactId: string } & (
  | { claimNumber: string }
  | { book: Book | undefined }
)

export function useGetContactAssignmentForClaim(props: UseGetContactAssignmentForClaimProps) {
  const claimNumber =
    'claimNumber' in props
      ? props.claimNumber
      : (props.book?.metadata.type === 'claim' && last(props.book.bookId.split(':'))) || ''

  const claimCorn = ensureClaimCorn(claimNumber)

  return useGetContactAssignmentForEntity({ contactId: props.contactId, entityCorn: claimCorn })
}

export type UseGetContactAssignmentForEntityProps = { contactId: string; entityCorn: Corn }

export function useGetContactAssignmentForEntity({
  contactId,
  entityCorn,
}: UseGetContactAssignmentForEntityProps) {
  const query = useGetAssignmentsForEntity(entityCorn)

  return useMemo(
    () => ({
      ...query,
      data: getContactAssignmentForEntity({ assignments: query.data, contactId }),
    }),
    [query, contactId]
  )
}

export type GetContactAssignmentForEntityProps = {
  assignments: GetAssignmentsForAssignableResponse | undefined
  contactId: string
}

export function getContactAssignmentForEntity({
  assignments,
  contactId,
}: GetContactAssignmentForEntityProps) {
  return assignments?.filter((assignment) => assignment.assignee.assigneeId === contactId)
}
