import { JobId } from '@eigtech/restoration-types'
import { ReactNode, createContext, useContext } from 'react'

export type RoutesContext = { home: string } & Partial<{
  claims: string

  claimDetail: (claimNumber: string) => string
  claimDetailCallbacks: (claimNumber: string) => string
  claimDetailContacts: (claimNumber: string) => string
  claimDetailDocuments: (claimNumber: string) => string
  claimDetailEstimates: (claimNumber: string) => string
  claimDetailEstimateDetail: (props: { claimNumber: string; estimateId: string }) => string
  claimDetailInfo: (claimNumber: string) => string
  claimDetailMeetings: (claimNumber: string) => string
  claimDetailNewMeeting: (claimNumber: string) => string
  claimDetailMeeting: (props: {
    claimNumber: string
    meetingStartTime: string
    meetingId: string
  }) => string
  claimDetailNotes: (claimNumber: string) => string
  claimDetailNewNote: (claimNumber: string) => string
  claimDetailMedia: (claimNumber: string) => string
  claimDetailPortals: (claimNumber: string) => string
  claimDetailUploadDocument: (claimNumber: string) => string

  jobs: string

  jobDetail: (jobId: JobId) => string
  jobDetailCallbacks: (jobId: JobId) => string
  jobDetailContacts: (jobId: JobId) => string
  jobDetailDocuments: (jobId: JobId) => string
  jobDetailInfo: (jobId: JobId) => string
  jobDetailMeeting: (props: { jobId: JobId; meetingStartTime: string; meetingId: string }) => string
  jobDetailMeetings: (jobId: JobId) => string
  jobDetailNewMeeting: (jobId: JobId) => string
  jobDetailNotes: (jobId: JobId) => string
  jobDetailNewNote: (jobId: JobId) => string
  jobDetailMedia: (jobId: JobId) => string
  jobDetailPortals: (jobId: JobId) => string
  jobDetailTimelineNewEvent: (jobId: JobId) => string
  jobDetailUploadDocument: (jobId: JobId) => string

  inbox: string

  activeCallbacks: string

  documentation: string

  templates: string
}>

const RoutesContext = createContext<RoutesContext>({ home: '/' })

export function RoutesProvider({
  children,
  routes,
}: {
  children: ReactNode
  routes: RoutesContext
}) {
  return <RoutesContext.Provider value={routes}>{children}</RoutesContext.Provider>
}

export function useRoutesContext() {
  const routes = useContext(RoutesContext)

  if (!routes) {
    throw new Error('RoutesProvider required to use useRoutesContext')
  }

  return routes
}
