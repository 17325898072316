import { chakra, Stack, StyleProps } from '@eigtech/ui-shared-dave'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import { FormEventHandler, ForwardedRef, forwardRef, ReactNode } from 'react'

export type FormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = {
  children: ReactNode
  form: UseFormReturn<TFieldValues, TContext, TTransformedValues>
  id: string
  onSubmit: FormEventHandler<HTMLFormElement>
} & Omit<StyleProps, 'id'>

export const Form = forwardRef(function Form({ children, form, ...props }, ref) {
  return (
    <FormProvider {...form}>
      <chakra.form ref={ref} {...props}>
        <Stack h="full" spacing="4">
          {children}
        </Stack>
      </chakra.form>
    </FormProvider>
  )
}) as <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: FormProps<TFieldValues, TContext, TTransformedValues> & {
    ref?: ForwardedRef<HTMLFormElement>
  }
) => JSX.Element

export type FormComponent<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = typeof Form<TFieldValues, TContext, TTransformedValues>
