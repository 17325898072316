import { InspectionDetails } from '@eigtech/claims-v2-types'
import { useGetAllClaimsInspections } from '@eigtech/ui-estimator/api'
import { ROUTER_PATHS } from '@eigtech/ui-estimator/lib/constants'
import { InspectionCalendar } from '@eigtech/ui-shared-claims'
import { endOfMonth, startOfMonth } from '@eigtech/ui-shared-dates'
import { CircularProgress, ComposedCard, HStack, Page } from '@eigtech/ui-shared-dave'
import { getRouteApi, useNavigate } from '@eigtech/ui-shared-router'
import { useMemo } from 'react'

const Route = getRouteApi(ROUTER_PATHS.inspectionsSchedule.routeId)

export function AllClaimsInspections() {
  const { selectedMonth } = Route.useSearch()
  const startOfMonthDate = !!selectedMonth ? new Date(selectedMonth) : startOfMonth(new Date())
  const endOfMonthDate = endOfMonth(startOfMonthDate)

  const navigate = useNavigate()

  function setCurrentMonth(month: Date) {
    navigate({
      search: (prev: Record<string, any>) => ({
        ...prev,
        selectedMonth: month.toISOString(),
      }),
      replace: true,
    })
  }

  const { data, isPending: isPendingInspections } = useGetAllClaimsInspections({
    filter: [
      {
        field: 'scheduledDate',
        operator: '>=',
        value: startOfMonthDate.toISOString(),
      },
      {
        field: 'scheduledDate',
        operator: '<=',
        value: endOfMonthDate.toISOString(),
      },
    ],
  })

  const { data: requestedInspections, isPending: isPendingRequested } = useGetAllClaimsInspections({
    filter: [
      {
        field: 'status',
        operator: 'ANYOF',
        value: ['requested'],
      },
    ],
  })

  const isPending = isPendingInspections || isPendingRequested

  const inspections = useMemo(
    () =>
      Object.values({
        ...data?.inspections,
        ...requestedInspections?.inspections,
      })
        .filter(
          (inspection): inspection is InspectionDetails & { entityId: string } =>
            !!(inspection as InspectionDetails & { entityId?: string }).entityId
        )
        .map((inspection) => ({
          ...inspection,
          claimNumber: inspection.entityId,
        })),
    [data?.inspections, requestedInspections?.inspections]
  )

  return (
    <Page>
      <ComposedCard
        actions={<HStack>{isPending && <CircularProgress isIndeterminate size={6} />}</HStack>}
        heading="Claim Inspections"
      >
        <InspectionCalendar
          inspections={inspections}
          selectedMonth={startOfMonthDate}
          onSelectedMonthChange={setCurrentMonth}
        />
      </ComposedCard>
    </Page>
  )
}
