import { z } from 'zod'

export const eventType = <
  T extends string | undefined,
  U extends string | undefined,
  V extends string | undefined,
>(
  domain?: T,
  entity?: U,
  eventName?: V
) =>
  z
    .custom<`${NonNullable<T>}:${NonNullable<U>}:${NonNullable<V>}`>((val) => {
      const cpat = '\\w+[-\\w]*\\w+'
      const test = (pat: string, val: string) => new RegExp(`^${pat}$`, 'g').test(val)
      if ([domain, entity, eventName].some((c) => c !== undefined && !test(cpat, c))) return false
      return test(`^${domain ?? cpat}:${entity ?? cpat}:${eventName ?? cpat}$`, val as string)
    })
    .describe(`${domain ?? '*'}:${entity ?? '*'}:${eventName ?? '*'}`)

/**
 * ## EventBase
 *
 * All events entering the global event stream should extend this type
 *
 * | Property | Description |
 * |:---------|:------------|
 * | id | A globally unique Id for the event |
 * | type | Format: `[ServiceName]:[Entity]:[Event]` For example: `csr:claim:primaryContactIndentified` |
 * | partitionKey | The specific aggregate ID that the event applied to |
 * | version | The sequentially increasing integer for the event applied to the aggregate |
 * | timestamp | epoch value of when the event took place |
 * | schemaVersion | version of the specific event schema |
 * | metadata | A generic place for event metadata |
 */
export const EventBaseSchema = z.object({
  id: z.string(),
  type: eventType(),
  partitionKey: z.string(),
  version: z.number().or(z.literal('not-set')),
  timestamp: z.number(),
  schemaVersion: z.number(),
  metadata: z.record(z.string(), z.union([z.string(), z.number(), z.boolean()])),
})

export const SystemEventTypeSchema = eventType('system')

export const SystemEventSchema = EventBaseSchema.merge(
  z.object({
    type: SystemEventTypeSchema,
  })
)

export const isSystemEvent = (event: EventBase) => SystemEventSchema.safeParse(event).success

export type EventBase = z.infer<typeof EventBaseSchema>
export type SystemEvent = z.infer<typeof SystemEventSchema>

export const EventTriggerSchema = z.object({
  triggerType: z.enum(['command', 'event']),
  triggerName: z.string(),
})
export type EventTrigger = z.infer<typeof EventTriggerSchema>

// Replay
export const ReplayEventSchema = SystemEventSchema.merge(
  z.object({
    type: eventType('system', 'replay'),
  })
)

export const ReplayStartedEventSchema = ReplayEventSchema.merge(
  z.object({
    type: eventType('system', 'replay', 'replayStarted'),
    replay: z.object({
      token: z.string(),
      callbackPayload: z.string(),
      errorPayload: z.string().optional(),
    }),
  })
)

export const ReplayBatchCompletedEventSchema = ReplayEventSchema.merge(
  z.object({
    type: eventType('system', 'replay', 'replayBatchCompleted'),
    replay: z.object({
      lambdaFunctionArn: z.string(),
      executionId: z.string(),
    }),
  })
)

export const ReplayCompletedEventSchema = ReplayEventSchema.merge(
  z.object({
    type: eventType('system', 'replay', 'replayCompleted'),
    replay: z.object({
      token: z.string(),
      callbackPayload: z.string(),
      errorPayload: z.string().optional(),
    }),
  })
)
export type ReplayEvent = z.infer<typeof ReplayEventSchema>
export type ReplayStartedEvent = z.infer<typeof ReplayStartedEventSchema>
export type ReplayBatchCompletedEvent = z.infer<typeof ReplayBatchCompletedEventSchema>
export type ReplayCompletedEvent = z.infer<typeof ReplayCompletedEventSchema>

export type ReplayStartedHandler = (event: ReplayStartedEvent) => Promise<void>
export type ReplayBatchCompletedHandler = (event: ReplayBatchCompletedEvent) => Promise<void>
export type ReplayCompletedHandler = (event: ReplayCompletedEvent) => Promise<void>
