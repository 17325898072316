import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { BaseJobSchema } from '../../base/baseJob.js'
import { JobIdSchema } from '../../base/ids.js'

export const UpdateJobDescriptionRequestBodySchema = z.object({
  jobDescription: BaseJobSchema.shape.jobDescription,
})
export const UpdateJobDescriptionRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const UpdateJobDescriptionRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const UpdateJobDescriptionRequestSchema = UpdateJobDescriptionRequestBodySchema.merge(
  UpdateJobDescriptionRequestHeadersSchema
).merge(UpdateJobDescriptionRequestPathSchema)
export const UpdateJobDescriptionResponseSchema = z.object({
  jobId: JobIdSchema,
  jobDescription: BaseJobSchema.shape.jobDescription,
})
export const UpdateJobDescriptionApiSchema: ApiSchema = {
  body: UpdateJobDescriptionRequestBodySchema,
  headers: UpdateJobDescriptionRequestHeadersSchema,
  params: UpdateJobDescriptionRequestPathSchema,
  response: UpdateJobDescriptionResponseSchema,
}

export type UpdateJobDescriptionRequestBody = z.infer<typeof UpdateJobDescriptionRequestBodySchema>
export interface UpdateJobDescriptionRequest
  extends z.infer<typeof UpdateJobDescriptionRequestSchema> {}
export type UpdateJobDescriptionResponse = z.infer<typeof UpdateJobDescriptionResponseSchema>
export interface UpdateJobDescriptionHandler
  extends Handler<UpdateJobDescriptionRequest, UpdateJobDescriptionResponse> {}
