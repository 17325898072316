import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema, ContactSchema } from '../../../base/contacts.js'

export const CreateContactRequestBodySchema = ContactSchema.omit({ contactId: true })

export const CreateContactRequestSchema = CreateContactRequestBodySchema

export const CreateContactResponseSchema = z.object({
  contactId: ContactCornSchema,
})

export type CreateContactRequestBody = z.infer<typeof CreateContactRequestBodySchema>
export type CreateContactRequest = z.infer<typeof CreateContactRequestSchema>

export type CreateContactResponse = z.infer<typeof CreateContactResponseSchema>

export interface CreateContactHandler
  extends Handler<CreateContactRequest, CreateContactResponse> {}

export const CreateContactApiSchema: ApiSchema = {
  body: CreateContactRequestBodySchema,
  response: CreateContactResponseSchema,
}
