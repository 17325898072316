export function makeBasicQueryKeys<T = string>(baseKey: string | string[]) {
  const keys = {
    all: () => (typeof baseKey === 'string' ? [baseKey] : baseKey),
    list: () => [...keys.all(), 'list'],
    details: () => [...keys.all(), 'detail'],
    detail: (id: T) => [...keys.details(), id],
  }

  return keys
}
