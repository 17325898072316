import { ROUTER_PATHS } from '@eigtech/ui-estimator/lib/constants'
import { ClaimEstimates } from '@eigtech/ui-shared-claims'
import { getRouteApi } from '@eigtech/ui-shared-router'

const Route = getRouteApi(ROUTER_PATHS.claimDetailEstimates.routeId)

export function ClaimDetailEstimates() {
  const { claimNumber } = Route.useParams()

  return <ClaimEstimates claimNumber={claimNumber} />
}
