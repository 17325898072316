import { CornKernels } from '../types/Kernels.js'
import { isCorn } from './isCorn.js'

export type GetCornKernelsOptions<ShouldValidate extends boolean> = {
  shouldValidate?: ShouldValidate
}

export type GetCornKernelsReturn<ShouldValidate extends boolean> = (ShouldValidate extends false
  ? Partial<CornKernels>
  : CornKernels) & { corn: 'corn' }

/**
 * @example
 * // returns { serviceName: 'claims-v2', resourceType: 'claim', resourceId: '223SSU6SKL' }
 * getCornKernels('corn:claims:claim:223SSU6SKL')
 * @param corn corn to deconstruct
 * @returns Corn kernels
 */
export function getCornKernels<ShouldValidate extends boolean = true>(
  corn: string,
  { shouldValidate }: GetCornKernelsOptions<ShouldValidate> = {}
): GetCornKernelsReturn<ShouldValidate> {
  if (shouldValidate !== false) {
    const isValid = isCorn(corn)

    if (!isValid) {
      throw new Error('Invalid Corn')
    }
  }

  const [_, serviceName, resourceType] = corn.split(':')
  const resourceId =
    _ &&
    serviceName &&
    resourceType &&
    corn.substring(`corn:${serviceName}:${resourceType}:`.length)

  return {
    corn: 'corn',
    serviceName,
    resourceType,
    resourceId,
  }
}
