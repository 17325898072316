import { DocumentV2 } from '@eigtech/documents-types'
import { Corn } from '@eigtech/shared-corn'
import {
  ComposedCard,
  ComposedCardProps,
  FancyLink,
  forwardRef,
  PropertyList,
  PropertyListProps,
  ResponsiveProperty,
} from '@eigtech/ui-shared-dave'
import { FC, useEffect } from 'react'
import { useGetDocumentUrl } from '../api/getDocumentUrl'
import { AugmentedDocument } from '../types/AugmentedDocument'

export type DocumentCardProps = {
  entityId: Corn
  document: AugmentedDocument
  DocumentActions?: FC<{ document: AugmentedDocument }>
} & ComposedCardProps

export const DocumentCard = forwardRef<DocumentCardProps, 'div'>(
  ({ entityId, document, DocumentActions, ...props }, ref) => {
    return (
      <ComposedCard
        ref={ref}
        cardBodyProps={{ p: 0 }}
        cardFooterProps={{ flexGrow: 1, flexDir: 'column', alignItems: 'stretch' }}
        display="flex"
        flexDir="column"
        {...props}
        footer={<>{!!DocumentActions && <DocumentActions document={document} />}</>}
      >
        <DocumentPropertyList document={document} entityId={entityId} />
      </ComposedCard>
    )
  }
)

export type DocumentPropertyListProps = {
  entityId: Corn
  document: DocumentV2
} & PropertyListProps

export function DocumentPropertyList({ entityId, document, ...props }: DocumentPropertyListProps) {
  const { data: url, refetch } = useGetDocumentUrl({ entityId, documentId: document.id })

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <PropertyList flexGrow={1} flexShrink={0} mb="2" {...props}>
      <ResponsiveProperty
        label="Filename"
        value={
          !!url ? (
            <FancyLink href={url} isExternal target={document.id}>
              {document.filename || '(None)'}
            </FancyLink>
          ) : (
            document.filename || '(None)'
          )
        }
        wordBreak="break-word"
      />
      <ResponsiveProperty label="Title" value={document.notes || '(None)'} />
      <ResponsiveProperty label="Description" value={document.description || '(None)'} />
    </PropertyList>
  )
}
