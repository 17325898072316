import { AddCohortToNoteRequest } from '@eigtech/notes-types'
import { Corn } from '@eigtech/shared-corn'
import { contextualPatch, useMutation } from '@eigtech/ui-shared-api'
import { notebooksBasePath } from './constants'
import { useInvalidateEntityNotebook } from './getNotebooks'

const addNoteCohort = ({ cohort, noteId }: AddCohortToNoteRequest) =>
  contextualPatch<AddCohortToNoteRequest>(`${notebooksBasePath}/add/${noteId}`, {
    cohort,
  })

export function useAddNoteCohort({ shouldInvalidate = true }: { shouldInvalidate?: boolean } = {}) {
  const invalidateEntityNotebook = useInvalidateEntityNotebook()

  return useMutation({
    mutationFn: addNoteCohort,
    onSuccess(__, { noteId }) {
      if (shouldInvalidate) {
        invalidateEntityNotebook(noteId as Corn)
      }
    },
  })
}
