import { formatArrayAsStringList } from '@eigtech/ui-shared-dave'
import { startCase } from 'lodash-es'
import { z, ZodErrorMap } from 'zod'

export type ZodEnumWithUserFriendlyErrorOptions<T extends [string, ...string[]]> = {
  optionFormatter?: (option: T[number]) => string
  enumErrorPrefix?: string
} & RawCreateParams

export function zodEnumWithUserFriendlyError<T extends [string, ...string[]]>(
  values: T,
  {
    optionFormatter = startCase,
    enumErrorPrefix = 'Invalid value. Expected: ',
    errorMap,
    ...options
  }: ZodEnumWithUserFriendlyErrorOptions<T> = {}
): z.ZodEnum<T> {
  const schema = z.enum(values, {
    ...options,
    errorMap(issue, context) {
      if (issue.code === 'invalid_enum_value') {
        const formatted = schema.options.map(optionFormatter)
        const string: string = formatArrayAsStringList(formatted, {
          type: 'disjunction',
        })

        return { message: `${enumErrorPrefix}${string}.` }
      }

      return errorMap?.(issue, context) ?? { message: context.defaultError }
    },
  })

  return schema
}

type RawCreateParams = {
  errorMap?: ZodErrorMap
  invalid_type_error?: string
  required_error?: string
  description?: string
}
