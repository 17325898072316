import { ContactCornSchema } from '@eigtech/contacts-types'
import { CurrencySchema } from '@eigtech/currency'
import { SoftDateSchema } from '@eigtech/function-utils'
import { z } from 'zod'
import { InitialClaimActionsSchema } from './initialActions'

export const CarrierSchema = z.object({
  contactId: ContactCornSchema,
  name: z.string().optional(),
})

export const ClaimLossTypeSchema = z.string()

export const ClaimSeveritySchema = z.enum(['High', 'Medium', 'Low'])

export const ClaimSeverityEnum = ClaimSeveritySchema.Enum

export const DateOfLossSchema = SoftDateSchema
export const DateReceivedSchema = SoftDateSchema

export const BaseLossDetailsSchema = z
  .object({
    catCode: z.string(),
    dateOfLoss: DateOfLossSchema,
    lossDescription: z.string(),
    lossType: ClaimLossTypeSchema,
    severity: ClaimSeveritySchema,
  })
  .partial()

//#region We should consider adding a shared "address" package
export const AddressTypeSchema = z.enum([
  'Firm or Business',
  'Bad Address',
  'Current or Temporary',
  'Home',
  'Legal Address',
  'Mailing',
  'Office',
  'Permanent',
  'Registry Home',
])

export const GeoCoordinateAccuracySchema = z.enum([
  'ADDRESS',
  'STREET',
  'ZIP',
  'CITY',
  'STATE',
  'COUNTRY',
  'CITY BLOCK',
  'INTERSECTION',
  'ZIP7',
  'ZIP9',
])
export const LocationGeoCoordinatesSchema = z.object({
  accuracy: GeoCoordinateAccuracySchema,
  latitude: z.number(),
  longitude: z.number(),
})
export const LocationAddressSchema = z.object({
  city: z.string().min(1),
  stateOrProvince: z.string().min(2),
  country: z.string().min(2), // ie. US
  addressLine1: z.string().min(1),
  addressLine2: z.string().optional(),
  addressLine3: z.string().optional(),
  zipOrPostalCode: z.string().min(2), //https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
  addressType: AddressTypeSchema.optional(),
  countyOrParishCode: z.string().optional(),
})
export const LocationSchema = z.object({
  address: LocationAddressSchema,
  geoCoordinates: LocationGeoCoordinatesSchema.optional(),
})
//#endregion

export const ExternalSystemIdSchema = z.enum(['verisk', 'symbility', 'symbilityAssignmentId'])
export const ExternalSystemRecordSchema = z.record(ExternalSystemIdSchema, z.string())
export const ExternalSystemId = ExternalSystemIdSchema.enum
export type ExternalSystemIdEnum = z.infer<typeof ExternalSystemIdSchema>
export type ExternalSystemRecord = z.infer<typeof ExternalSystemRecordSchema>

export const ClaimFactorsEnumSchema = z.enum([
  'sinkhole',
  'bodily injury',
  'option to repair invoked',
])
export const ClaimFactorsSchema = ClaimFactorsEnumSchema.or(z.string())

export const ClaimDataSourceSchema = z.enum(['EIG', 'Verisk', 'Other', 'Symbility'])
export const ClaimDataSourceEnum = ClaimDataSourceSchema.Enum

export const NonEIGClaimNumberSchema = z
  .string()
  .refine((arg) => /.*/.test(arg), { message: 'claimNumber is an EIG Claim' })

export const EIGClaimNumberSchema = z
  .string()
  .refine((arg) => /.*/.test(arg), { message: 'claimNumber is not an EIG Claim' })
export const ClaimNumberSchema = NonEIGClaimNumberSchema.or(EIGClaimNumberSchema)

export const PolicyNumberSchema = z.string()

export const CarrierNameSchema = z.string()

export const ClaimPrecursorSchema = z.object({
  carrier: CarrierSchema,
  carrierClaimNumber: NonEIGClaimNumberSchema.optional(),
  claimFactors: ClaimFactorsSchema.array().optional(),
  dataSource: ClaimDataSourceSchema,
  dateReceived: DateReceivedSchema,
  externalSystemIds: ExternalSystemRecordSchema.optional(),
  initialClaimActions: InitialClaimActionsSchema.array().optional(),
  lossDetails: BaseLossDetailsSchema,
  lossLocation: LocationSchema.optional(),
  policyNumber: PolicyNumberSchema,
})

export const BaseClaimSchema = ClaimPrecursorSchema.merge(
  z.object({
    claimNumber: ClaimNumberSchema,
  })
)

export const MinimalClaimSchema = BaseClaimSchema.pick({
  claimNumber: true,
  dataSource: true,
  dateReceived: true,
}).merge(
  z.object({
    receivedInAssignQueueEvent: z.boolean().optional(),
  })
)

export const ClaimSetPropertySchema = BaseClaimSchema.pick({
  claimFactors: true,
  initialClaimActions: true,
}).keyof()

export const ClaimSetProperties = ClaimSetPropertySchema.options

export const RequestActorSchema = z.string()

// export const isClaimLossType = (carrier: CarrierName, lossType: string | undefined) =>
//   getLossTypesForCarrier(carrier).includes(lossType as ClaimLossType)
export const ClaimAddressTypes = AddressTypeSchema.options

export const isClaimAddressType = (
  addressType: string | undefined
): addressType is ClaimAddressType => ClaimAddressTypes.includes(addressType as ClaimAddressType)

export const EstimateTypeSchema = z.enum(['standard', 'supplement', 'preliminary', 'correction'])
export const EstimateTypeEnum = EstimateTypeSchema.Enum
export const EstimateIdSchema = z.string()

export const BaseEstimateSchema = z.object({
  estimateId: EstimateIdSchema,
  claimNumber: ClaimNumberSchema,
  submittedBy: z.string(),
  estimateType: EstimateTypeSchema,
  submissionDate: z.string().datetime(),
  notes: z.string(),
  /* These parameters come directly from XA. It looks like they can be resolved from Symbility as well though */
  summary: z.object({
    homeOwnerItems: CurrencySchema.optional(),
    contractorItems: CurrencySchema.optional(),
    estimateLineItemTotal: CurrencySchema.optional(),
    minimumChargeAdjustments: CurrencySchema.optional(),
    overhead: CurrencySchema.optional(),
    profit: CurrencySchema.optional(),
    salesTax: CurrencySchema.optional(),
    grossEstimate: CurrencySchema.optional(),
    recoverableDepreciation: CurrencySchema.optional(),
    nonRecoverableDepreciation: CurrencySchema.optional(),
    netEstimate: CurrencySchema.optional(),
    priceListLineItemTotal: CurrencySchema.optional(),
    deductible: CurrencySchema.optional(),
  }),
  externalSource: z.string().optional(),
  externalSourceId: z.string(),
})

export type BaseClaim = z.infer<typeof BaseClaimSchema>
export type BaseEstimate = z.infer<typeof BaseEstimateSchema>
export type BaseLocation = z.infer<typeof LocationSchema>
export type BaseLossDetails = z.infer<typeof BaseLossDetailsSchema>
export type CarrierName = z.infer<typeof CarrierNameSchema>
export type ClaimAddressType = z.infer<typeof AddressTypeSchema>
export type ClaimDataSource = z.infer<typeof ClaimDataSourceSchema>
export type ClaimFactor = z.infer<typeof ClaimFactorsSchema>
export type ClaimFactorEnum = z.infer<typeof ClaimFactorsEnumSchema>
export type ClaimLossType = z.infer<typeof ClaimLossTypeSchema>
export type ClaimNumber = z.infer<typeof ClaimNumberSchema>
export type ClaimSetProperty = z.infer<typeof ClaimSetPropertySchema>
export type DateOfLoss = z.infer<typeof DateOfLossSchema>
export type DateReceived = z.infer<typeof DateReceivedSchema>
export type EstimateId = z.infer<typeof EstimateIdSchema>
export type EstimateType = z.infer<typeof EstimateTypeSchema>
export type GeoCoordinateAccuracy = z.infer<typeof GeoCoordinateAccuracySchema>
export type Location = z.infer<typeof LocationSchema>
export type LocationAddress = z.infer<typeof LocationAddressSchema>
export type MinimalClaim = z.infer<typeof MinimalClaimSchema>
export type RequestActor = z.infer<typeof RequestActorSchema>
