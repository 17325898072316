import { UpdateDocumentRequestBody, UpdateDocumentResponse } from '@eigtech/documents-types'
import { ensureDocumentCorn } from '@eigtech/documents-util'
import { Corn } from '@eigtech/shared-corn'
import { contextualPatch, useMutation } from '@eigtech/ui-shared-api'
import { pick } from 'lodash-es'
import { documentsBasePath } from './constants'
import { useInvalidateEntityDocuments } from './getEntityDocuments'

export type UpdateDocumentRequest = UpdateDocumentRequestBody & {
  entityId: Corn
  documentId: string
}

function updateDocument(req: UpdateDocumentRequest) {
  const payload: UpdateDocumentRequestBody = pick(req, ['notes', 'description'])
  const entityId = req.entityId
  const documentCorn = ensureDocumentCorn(req.documentId)

  return contextualPatch<UpdateDocumentResponse>(
    `${documentsBasePath}/${entityId}/${documentCorn}`,
    payload
  )
}

export function useUpdateDocument() {
  const invalidateDocuments = useInvalidateEntityDocuments()

  return useMutation({
    mutationFn: updateDocument,
    onSuccess(__, req) {
      invalidateDocuments(req.entityId)
    },
  })
}
