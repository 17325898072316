import { DelegateAdminClaimSummary } from '@eigtech/ui-shared-claims'
import { isSameDay } from '@eigtech/ui-shared-dates'

export const inAssignQueueDate = (claim: DelegateAdminClaimSummary) => claim.dateReceived

export const isNewClaim = (claim: DelegateAdminClaimSummary | undefined) =>
  !(claim?.inspectionScheduled && claim?.inspectionCompleted)

export const isTodayClaim = (claim: DelegateAdminClaimSummary | undefined) => {
  const scheduled = claim?.inspectionScheduled
  if (!scheduled) return false

  return isSameDay(Date.now(), new Date(scheduled))
}
