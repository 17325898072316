import { parsePhoneNumber } from 'awesome-phonenumber'
import { startCase } from 'lodash-es'
import z, { ZodIssueCode, ZodSchema } from 'zod'
import { isDefined } from './filter'
import { isISOish } from './time'

export const OptionalStringSchema = z
  .optional(z.string())
  .transform((str) => (!str ? undefined : str))

export const QueryModelSchema = z.object({
  PK: z.string(),
  SK: z.string(),
  'GSI-PK-1': z.string().optional(),
  'GSI-SK-1': z.string().optional(),
})
export type QueryModel = z.infer<typeof QueryModelSchema>

export const fromQueryModel = <Q extends QueryModel>({
  PK: __PK,
  SK: __SK,
  'GSI-PK-1': __gsiPk1,
  'GSI-SK-1': __gsiSk1,
  ...record
}: Q): Omit<Q, 'PK' | 'SK' | 'GSI-PK-1' | 'GSI-SK-1'> => record

/**
 * Removes "inferred type cannot be named without a reference" errors
 */
export type SchemaOf<T> = ZodSchema<T>

export const isParseSuccess = <Input, Output>(
  parseResults: z.SafeParseReturnType<Input, Output>
): parseResults is z.SafeParseSuccess<Output> => parseResults.success

export const PhoneNumberSchema = z.string().refine(
  (phone) => {
    const nationalNumber = parsePhoneNumber(phone, {
      regionCode: 'US',
    })
    const plainNumber = parsePhoneNumber(phone)
    return nationalNumber.valid || plainNumber.valid
  },
  { message: 'unable to parse phone number' }
)

export const PhoneNumberOptionalSchema = z
  .string()
  .optional()
  .refine((phone) => !phone || PhoneNumberSchema.safeParse(phone).success, {
    message: 'unable to parse phone number',
  })

export const SoftDateSchema = z.string().refine(isISOish, { message: 'Not nearly ISO enough' })

export const friendlyErrorMap: z.ZodErrorMap = (issue, ctx) => {
  const defaultMessage = issue.message ?? ctx.defaultError
  // check if a path is attribute or index based
  // ignoring index based paths, unless we want to convert them to ordinal numbers,
  const issuePath = issue.path
    .map((i) => (typeof i === 'string' ? startCase(i) : undefined))
    .filter(isDefined)
    .join(' ')

  switch (issue.code) {
    case ZodIssueCode.too_big:
      return {
        message: `${issuePath} must be greater than${issue.inclusive ? ' or equal' : ''} to ${issue.maximum}`,
      }
    case ZodIssueCode.too_small:
      return {
        message: `${issuePath} must be less than${issue.inclusive ? ' or equal' : ''} to ${issue.minimum}`,
      }
    case ZodIssueCode.invalid_type: {
      // common validation messages
      // "Invalid input: expected string, received number"
      // "Required"
      const isRequired =
        issue.message?.toLowerCase().startsWith('required') || issue.received === 'undefined'
      const isInvalid = issue.received !== issue.expected

      let message: string | undefined
      if (isRequired) message = `${issuePath} is required`
      else if (isInvalid) message = `${issuePath} is invalid`
      else message = defaultMessage

      return {
        message,
      }
    }
    default: {
      return {
        message: defaultMessage,
      }
    }
  }
}
