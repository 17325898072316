import {
  GetInboxResponse,
  ListMessagesResponse,
  UpdateAllInboxMessageStatusesRequest,
  UpdateInboxMessageStatusRequest,
  UpdateInboxMessageStatusRequestBody,
  UpdateInboxMessageStatusResponse,
} from '@eigtech/messaging-types'
import {
  ApiPostRequest,
  useApiInstanceContext,
  useMutation,
  useQueryClient,
} from '@eigtech/ui-shared-api'
import { useToast } from '@eigtech/ui-shared-dave'
import { inboxQueryKeys, messageBasePath } from './constants'
import { useOptimisticallyUpdateMessage } from './hooks'

const updateMessageStatus =
  (post: ApiPostRequest) =>
  ({ inboxId, messageId, ...body }: UpdateInboxMessageStatusRequest) =>
    post<UpdateInboxMessageStatusResponse, UpdateInboxMessageStatusRequestBody>(
      `${messageBasePath}/inbox/${inboxId}/${encodeURIComponent(messageId)}`,
      body,
      { responseType: 'none' }
    )

const updateAllMessageStatuses =
  (post: ApiPostRequest) =>
  ({ inboxId, ...body }: UpdateAllInboxMessageStatusesRequest) =>
    post<UpdateInboxMessageStatusResponse, UpdateInboxMessageStatusRequestBody>(
      `${messageBasePath}/inbox/${inboxId}/all/status`,
      body,
      { responseType: 'none' }
    )

export function useUpdateMessageStatus() {
  const { post } = useApiInstanceContext()
  const queryClient = useQueryClient()
  const toast = useToast()

  const { onError, onMutate } = useOptimisticallyUpdateMessage()

  return useMutation({
    mutationFn: updateMessageStatus(post),
    async onMutate({ inboxId, messageId, messageStatus }) {
      const inboxQueryKey = inboxQueryKeys.detail(inboxId)

      await queryClient.cancelQueries({ queryKey: inboxQueryKey })

      const previousInbox = queryClient.getQueryData<GetInboxResponse>(inboxQueryKey)

      if (!!previousInbox) {
        queryClient.setQueryData<GetInboxResponse>(inboxQueryKey, () => ({
          ...previousInbox,
          unread:
            previousInbox.unread +
            (messageStatus === 'UNREAD' ? 1 : previousInbox.unread === 0 ? 0 : -1),
          read: previousInbox.read + (messageStatus === 'READ' ? 1 : -1),
        }))
      }

      const context = await onMutate(
        { inboxId, messageId },
        { dateRead: messageStatus === 'READ' ? new Date().toISOString() : undefined }
      )

      return { previousInbox, ...(context ?? {}) }
    },
    onError(error, request, context) {
      toast({
        status: 'error',
        title: 'Failed to update message status',
      })

      queryClient.setQueryData(inboxQueryKeys.detail(request.inboxId), context?.previousInbox)

      onError(error, request, context)
    },
  })
}

export function useUpdateAllMessageStatuses() {
  const { post } = useApiInstanceContext()
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: updateAllMessageStatuses(post),
    async onMutate({ inboxId, messageStatus }) {
      const inboxQueryKey = inboxQueryKeys.detail(inboxId)
      const listMessagesQueryKey = inboxQueryKeys.messages(inboxId)
      const oldMessageStatus = messageStatus === 'READ' ? 'UNREAD' : 'READ'

      await queryClient.cancelQueries({ queryKey: inboxQueryKey })

      const previousInbox = queryClient.getQueryData<GetInboxResponse>(inboxQueryKey)

      // update inbox stats
      if (!!previousInbox) {
        queryClient.setQueryData<GetInboxResponse>(inboxQueryKey, () => ({
          ...previousInbox,
          ...(messageStatus === 'READ' && {
            unread: 0,
            read: previousInbox.read + previousInbox.unread,
          }),
          ...(messageStatus === 'UNREAD' && {
            read: 0,
            unread: previousInbox.read + previousInbox.unread,
          }),
        }))
      }

      // update all message statuses
      const previousMessagesQueries = queryClient.setQueriesData<ListMessagesResponse>(
        { queryKey: listMessagesQueryKey },
        (messages) => {
          const newMessages = [...(messages?.items ?? [])]
          newMessages.map((message, idx) => {
            if (message.messageStatus === oldMessageStatus) {
              const now = new Date().toISOString()
              newMessages.splice(idx, 1, {
                ...message,
                messageStatus,
                dateRead: messageStatus === 'READ' ? now : undefined,
              })
            }
          })

          return { ...messages, items: newMessages }
        }
      )

      return { previousInbox, previousMessagesQueries }
    },
    onError(__, request, context) {
      toast({
        status: 'error',
        title: 'Failed to update all messages statuses',
      })

      queryClient.setQueryData(inboxQueryKeys.detail(request.inboxId), context?.previousInbox)
      context?.previousMessagesQueries.forEach(([queryKey, messages]) => {
        queryClient.setQueryData(queryKey, messages)
      })
    },
  })
}
