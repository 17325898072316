import { forwardRef, Input, InputProps } from '@chakra-ui/react'
import type { FactoryOpts, InputMask } from 'imask'
import { useEffect, useRef } from 'react'
import { IMaskInput } from 'react-imask'

export type MaskedInputProps = Omit<InputProps, 'onChange'> & {
  mask: string
  onChange?: (value: InputProps['value']) => void
}

export const MaskedInput = forwardRef<MaskedInputProps, 'input'>(
  ({ onChange, ...props }, inputRef) => {
    const ref = useRef<{ maskRef: InputMask<FactoryOpts> }>(null)

    useEffect(() => {
      onChange?.(ref.current?.maskRef._value ?? props.value ?? '')

      // we only want this to run on mount
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Input
        ref={ref}
        as={IMaskInput}
        inputRef={inputRef}
        {...props}
        onAccept={(value: string) => {
          onChange?.(value)
        }}
        onChange={() => {}}
      />
    )
  }
)

MaskedInput.id = 'Input'
