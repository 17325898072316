import { GetInboxRequest, GetInboxResponse } from '@eigtech/messaging-types'
import { ApiGetRequest, useApiInstanceContext, useQuery } from '@eigtech/ui-shared-api'
import { useAuth0 } from '@eigtech/ui-shared-auth'
import { minutesToMilliseconds } from '@eigtech/ui-shared-dates'
import { inboxQueryKeys, messageBasePath } from './constants'

const getInbox = (get: ApiGetRequest, { inboxId }: GetInboxRequest) =>
  get<GetInboxResponse>(`${messageBasePath}/inbox/${inboxId}`, { responseType: 'json' })

export function useGetInbox(request: GetInboxRequest) {
  const { get } = useApiInstanceContext()

  return useQuery({
    queryKey: inboxQueryKeys.detail(request.inboxId),
    queryFn: () => getInbox(get, request),
    enabled: !!request.inboxId,
    refetchInterval: minutesToMilliseconds(1),
    refetchIntervalInBackground: true,
  })
}

export function useGetUserInbox() {
  const { user } = useAuth0()

  return useGetInbox({ inboxId: (user?.sub ?? '') as GetInboxRequest['inboxId'] })
}
