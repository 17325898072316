import { z } from 'zod'
import {
  EstimateReviewOutcomeSchema,
  EstimateReviewSchema,
  EstimateReviewerRoleSchema,
} from '../model/estimate'
import { BaseEstimateSchema, RequestActorSchema } from '../model/base'
import { ClaimsV2ServiceEventBaseSchema, claimsV2ServiceEventType } from './service'

export const EstimateEventTypeSchema = z.enum(['received', 'reviewed'])
export const EstimateEventType = EstimateEventTypeSchema.Enum
export type EstimateEventTypeEnum = z.infer<typeof EstimateEventTypeSchema>

export const estimateEventType = <V extends string | undefined>(eventName?: V) =>
  claimsV2ServiceEventType('estimate', eventName)

export const EstimateEventSchema = ClaimsV2ServiceEventBaseSchema.merge(
  z.object({
    type: estimateEventType(),
    estimate: z.unknown(),
    metadata: ClaimsV2ServiceEventBaseSchema.shape.metadata.and(
      z.object({
        requestActor: RequestActorSchema,
      })
    ),
  })
)

export type EstimateEvent = z.infer<typeof EstimateEventSchema>

export const EstimateReceivedEventSchema = EstimateEventSchema.merge(
  z.object({
    type: estimateEventType(EstimateEventType.received),
    estimate: BaseEstimateSchema,
  })
)
export const isEstimateReceivedEvent = (event: unknown): event is EstimateReceivedEvent =>
  EstimateReceivedEventSchema.safeParse(event).success

export const EstimateReviewedEventSchema = EstimateEventSchema.merge(
  z.object({
    type: estimateEventType(EstimateEventType.reviewed),
    estimate: z.object({
      review: EstimateReviewSchema,
      estimate: BaseEstimateSchema,
    }),
    metadata: EstimateEventSchema.shape.metadata.and(
      z.object({
        outcome: EstimateReviewOutcomeSchema,
        publishToXa: z.boolean(),
        reviewerRole: EstimateReviewerRoleSchema,
      })
    ),
  })
)
export const isEstimateReviewedEvent = (event: unknown): event is EstimateReviewedEvent =>
  EstimateReviewedEventSchema.safeParse(event).success

export type EstimateReceivedEvent = z.infer<typeof EstimateReceivedEventSchema>
export type EstimateReviewedEvent = z.infer<typeof EstimateReviewedEventSchema>
