import { Handler } from 'aws-lambda'
import z from 'zod'
import { StepFunctionTaskContextSchema } from './context'
import { IncrementPageResponseSchema } from './increment'
import { SetupTimelineSyncResponseSchema } from './setup'

export const ErrorInputSchema = z.object({
  Cause: z.string(),
  Error: z.string(),
})

export const CompleteTimelineSyncInputSchema = z
  .object({
    setupResults: SetupTimelineSyncResponseSchema,
    incrementPageResults: IncrementPageResponseSchema,
  })
  .or(ErrorInputSchema)
export type CompleteTimelineSyncInput = z.infer<typeof CompleteTimelineSyncInputSchema>

export const CompleteTimelineSyncRequestSchema = z.object({
  context: StepFunctionTaskContextSchema,
  input: CompleteTimelineSyncInputSchema,
})
export type CompleteTimelineSyncRequest = z.infer<typeof CompleteTimelineSyncRequestSchema>
export interface CompleteTimelineSyncHandler extends Handler<CompleteTimelineSyncRequest, void> {}
