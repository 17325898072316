import { ClaimReviewRequestedDetails } from '@eigtech/summarizer-types'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Text, VStack } from '@eigtech/ui-shared-dave'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'

export function ReviewRequestedDetails(
  props: EventDetailsComponentProps<ClaimReviewRequestedDetails>
) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const { dateFlagged, flaggerNotes, reviewers } = props.details.review

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Text as="span">Review for</Text>
        {reviewers && reviewers.length > 0 && (
          <Text>{`for ${reviewers.map((r) => r.name).join(',')}`}</Text>
        )}
        <Text as="span">requested on</Text>
        <PreferredDateTimeComponent property="requestedDate">
          {dateFlagged}
        </PreferredDateTimeComponent>
      </HStack>

      {!!flaggerNotes && <Blockquote whiteSpace="preserve">{flaggerNotes}</Blockquote>}
    </VStack>
  )
}
