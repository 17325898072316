import {
  DailyServicesServiceEnum,
  EmergencyServicesServiceEnum,
  EmergencyServicesServiceSchema,
} from '@eigtech/restoration-types'
import { startCase } from 'lodash-es'

const partialJobServiceLabels: Partial<
  Record<EmergencyServicesServiceEnum | DailyServicesServiceEnum | 'notImplemented', string>
> = {}

export const getJobServiceLabel = (
  type: EmergencyServicesServiceEnum | DailyServicesServiceEnum | 'notImplemented'
) => partialJobServiceLabels[type] ?? startCase(type)

export const jobServiceLabels = Object.fromEntries(
  EmergencyServicesServiceSchema.options.map((key) => [key, getJobServiceLabel(key)])
) as Record<EmergencyServicesServiceEnum | DailyServicesServiceEnum, string>
