import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema, ClaimFactorsSchema } from '../model'
import { BaseHeaderSchema } from './base'

const RemoveClaimFactorsSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    claimFactors: ClaimFactorsSchema.array(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    claimFactors: ClaimFactorsSchema.array(),
  }),
}

export const RemoveClaimFactorsRequestSchema = RemoveClaimFactorsSchemas.params
  .merge(RemoveClaimFactorsSchemas.body)
  .merge(RemoveClaimFactorsSchemas.headers)

export type RemoveClaimFactorsBodyRequest = z.infer<typeof RemoveClaimFactorsSchemas.body>
export type RemoveClaimFactorsHeaderRequest = z.infer<typeof RemoveClaimFactorsSchemas.headers>
export type RemoveClaimFactorsRequest = z.infer<typeof RemoveClaimFactorsRequestSchema>
export type RemoveClaimFactorsResponse = z.infer<typeof RemoveClaimFactorsSchemas.response>

export interface RemoveClaimFactorsHandler
  extends Handler<RemoveClaimFactorsRequest, RemoveClaimFactorsResponse> {}

export const RemoveClaimFactorsApiSchema: ApiSchema = RemoveClaimFactorsSchemas
