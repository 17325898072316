import { EstimateReviewOutcome } from '@eigtech/claims-v2-types'
import { ClaimEstimateReviewedDetails } from '@eigtech/summarizer-types'
import { ContactName } from '@eigtech/ui-shared-contacts'
import { useDatesContext } from '@eigtech/ui-shared-dates'
import { Blockquote, HStack, Tag, Text, VStack } from '@eigtech/ui-shared-dave'
import { Link, useRoutesContext } from '@eigtech/ui-shared-router'
import { EventDetailsComponentProps } from '@eigtech/ui-shared-timeline'
import { exhaustive } from 'exhaustive'
import { startCase } from 'lodash-es'

export function EstimateReviewedDetails(
  props: EventDetailsComponentProps<ClaimEstimateReviewedDetails>
) {
  const { PreferredDateTimeComponent } = useDatesContext()
  const { claimDetailEstimateDetail } = useRoutesContext()
  const {
    entityId,
    details: {
      review: {
        additionalInformation,
        reviewedOn,
        outcome,
        reviewerContact,
        reviewerRole,
        estimateId,
      },
    },
  } = props

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <EstimateTag outcome={outcome}></EstimateTag>
        {!!(reviewerRole || reviewerContact) && <Text as="span"> by </Text>}
        {!!reviewerRole && <Tag>{reviewerRole === 'qa' ? 'QA' : startCase(reviewerRole)}</Tag>}
        {!!reviewerContact && <ContactName contactId={reviewerContact} />}
        {!!reviewedOn && (
          <Text as="span">
            on{' '}
            <PreferredDateTimeComponent property="reviewedOn">
              {reviewedOn}
            </PreferredDateTimeComponent>
          </Text>
        )}
      </HStack>
      {!!claimDetailEstimateDetail && (
        <Link to={claimDetailEstimateDetail({ claimNumber: entityId, estimateId })}>
          View Estimate
        </Link>
      )}

      {!!additionalInformation && (
        <Blockquote whiteSpace="preserve">{additionalInformation}</Blockquote>
      )}
    </VStack>
  )
}

function EstimateTag({ outcome }: { outcome: EstimateReviewOutcome }) {
  return exhaustive(outcome, {
    approved: () => <Tag colorScheme="green">Estimate Approved</Tag>,
    rejected: () => <Tag colorScheme="red">Estimate Rejected</Tag>,
    withExceptions: () => <Tag colorScheme="yellow">Estimate Reviewed With Exceptions</Tag>,
  })
}
