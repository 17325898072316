import {
  SendMeetingInviteRequest,
  SendMeetingInviteRequestBody,
  SendMeetingInviteResponse,
} from '@eigtech/meetings-types'
import { contextualPost, useMutation } from '@eigtech/ui-shared-api'
import { useToast } from '@eigtech/ui-shared-dave'
import { meetingsBasePath } from './constants'

const inviteToMeeting = ({
  entityId,
  meetingId,
  requestActor: __,
  ...payload
}: SendMeetingInviteRequest) =>
  contextualPost<SendMeetingInviteResponse, SendMeetingInviteRequestBody>(
    `${meetingsBasePath}/meeting/send-invite/${entityId}/${meetingId}`,
    payload,
    { responseType: 'json' }
  )

export function useInviteToMeeting() {
  const toast = useToast()

  return useMutation({
    mutationFn: inviteToMeeting,
    onSuccess(data) {
      const failures = data.filter(({ success }) => !success)
      if (failures.length) {
        failures.forEach((f) => {
          toast({
            status: 'error',
            title: `Failed to send ${f.channel} to ${f.name}`,
          })
        })
      }
    },
  })
}
