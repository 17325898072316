import { z } from 'zod'
import { BaseClaimSchema, ClaimNumberSchema } from '../model/base'
import { CompletedReviewSchema, PendingReviewSchema } from '../model/reviews'
import { ClaimEventSchema, ClaimEventType, claimEventType } from './claim'

export const ClaimReviewCompletedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.reviewCompleted),
    claim: BaseClaimSchema.merge(
      z.object({
        review: CompletedReviewSchema,
      })
    ),
  })
)

export const ClaimReviewCompletedEventInputSchema = z
  .object({
    claimNumber: ClaimNumberSchema,
  })
  .merge(CompletedReviewSchema.pick({ dateReviewed: true, reviewedBy: true, reviewerNotes: true }))

export const ClaimReviewRequestedEventSchema = ClaimEventSchema.merge(
  z.object({
    type: claimEventType(ClaimEventType.reviewRequested),
    claim: BaseClaimSchema.merge(
      z.object({
        review: PendingReviewSchema,
      })
    ),
  })
)

export const ClaimReviewRequestedEventInputSchema = z
  .object({
    claimNumber: ClaimNumberSchema,
  })
  .merge(PendingReviewSchema)

export type ClaimReviewCompletedEvent = z.infer<typeof ClaimReviewCompletedEventSchema>
export type ClaimReviewCompletedEventInput = z.infer<typeof ClaimReviewCompletedEventInputSchema>
export type ClaimReviewRequestedEvent = z.infer<typeof ClaimReviewRequestedEventSchema>
export type ClaimReviewRequestedEventInput = z.infer<typeof ClaimReviewRequestedEventInputSchema>
