import type { WithRequiredPermissionsProps } from '@eigtech/ui-shared-auth'
import { ButtonProps } from '@eigtech/ui-shared-dave'
import { RegisteredRouter, RoutePaths, ToSubOptions } from '@eigtech/ui-shared-router'
import React, { MouseEventHandler } from 'react'
import type { Merge } from 'type-fest'

export type NavId<Id extends string = string> = Id

export type NavItemBase<Action extends string = string, Subject extends string = string> = {
  label: string
  icon: React.FC
  badge?: number | string
  onClick?: MouseEventHandler<HTMLButtonElement | 'a'>
} & Partial<WithRequiredPermissionsProps<Action, Subject>>

export type NavItemExternalLink<
  Action extends string = string,
  Subject extends string = string,
> = NavItemBase<Action, Subject> & { href: string; target?: string }

export type NavItemLink<
  Action extends string = string,
  Subject extends string = string,
> = NavItemBase<Action, Subject> & {
  url: ToSubOptions<any, any, any>
}

export type NavItemNavLink<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
> = NavItemBase<Action, Subject> & { navId: NavId<Id>; isActiveNav?: (navId: NavId<Id>) => boolean }

export type NavItemAction<
  Action extends string = string,
  Subject extends string = string,
> = NavItemBase<Action, Subject> & { onClick: ButtonProps['onClick'] }

export type NavItem<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
> =
  | NavItemExternalLink<Action, Subject>
  | NavItemLink<Action, Subject>
  | NavItemNavLink<Action, Subject, Id>
  | NavItemAction<Action, Subject>

export type Nav<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
> = NavItem<Action, Subject, Id>[]

export type Navs<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
> = { [key in NavId<Id>]: Nav<Action, Subject, Id> }

export function createNavItemHelper<
  Action extends string = string,
  Subject extends string = string,
  Id extends string = string,
>() {
  const action = (props: NavItemAction<Action, Subject>) => props
  const externalLink = (props: NavItemExternalLink<Action, Subject>) => props
  const navLink = (props: NavItemNavLink<Action, Subject, Id>) => props

  function link<
    TFrom extends RoutePaths<RegisteredRouter['routeTree']> | string = '/',
    TTo extends string = '',
  >(
    props: Merge<
      NavItemLink<Action, Subject>,
      {
        url: ToSubOptions<RegisteredRouter['routeTree'], TFrom, TTo>
      }
    >
  ) {
    return props
  }

  return {
    action,
    externalLink,
    navLink,
    link,
  }
}
