import { useAudioVideo, useContentShareState } from 'amazon-chime-sdk-component-library-react'
import { useEffect, useImperativeHandle, useRef } from 'react'
import { VideoTile, VideoTileProps } from './VideoTile'
import { forwardRef } from '@eigtech/ui-shared-dave'

export type ContentShareProps = {} & VideoTileProps

export const ContentShare = forwardRef<ContentShareProps, 'video'>(
  function ContentShare(props, ref) {
    const audioVideo = useAudioVideo()
    const { tileId } = useContentShareState()
    const videoEl = useRef<HTMLVideoElement | null>(null)

    useImperativeHandle(ref, () => videoEl.current)

    useEffect(() => {
      if (!audioVideo || !videoEl.current || !tileId) {
        return
      }

      audioVideo.bindVideoElement(tileId, videoEl.current)

      return () => {
        const tile = audioVideo.getVideoTile(tileId)
        if (tile) {
          audioVideo.unbindVideoElement(tileId)
        }
      }
    }, [audioVideo, tileId])

    return tileId ? (
      <VideoTile ref={videoEl} objectFit="contain" tileId={tileId} {...props} />
    ) : null
  }
)
