import { ContactCornSchema } from '@eigtech/contacts-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  ClaimNumberSchema,
  InspectorRoleSchema,
  PublicationTargetsSchema,
  ScheduledInspectionSchema,
} from '../model'
import { BaseHeaderSchema } from './base'

export const ScheduleClaimInspectionApiSchema = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    inspectionId: z.string().optional(),
    inspectorRole: InspectorRoleSchema,
    notes: z.string().optional(),
    publicationTargets: PublicationTargetsSchema.array().optional(),
    scheduledBy: ContactCornSchema.or(z.string()),
    scheduledDate: z.string().datetime(),
  }),
  headers: BaseHeaderSchema,
  response: z
    .object({
      claimNumber: ClaimNumberSchema,
    })
    .merge(ScheduledInspectionSchema),
}

export const ScheduleClaimInspectionRequestSchema = ScheduleClaimInspectionApiSchema.params
  .merge(ScheduleClaimInspectionApiSchema.body)
  .merge(ScheduleClaimInspectionApiSchema.headers)

export type ScheduleClaimInspectionBodyRequest = z.infer<
  typeof ScheduleClaimInspectionApiSchema.body
>
export type ScheduleClaimInspectionHeaderRequest = z.infer<
  typeof ScheduleClaimInspectionApiSchema.headers
>
export type ScheduleClaimInspectionRequest = z.infer<typeof ScheduleClaimInspectionRequestSchema>
export type ScheduleClaimInspectionResponse = z.infer<
  typeof ScheduleClaimInspectionApiSchema.response
>

export interface ScheduleClaimInspectionHandler
  extends Handler<ScheduleClaimInspectionRequest, ScheduleClaimInspectionResponse> {}
