import { Corn, isCorn } from '@eigtech/shared-corn'
import { queryOptions, useQueries, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { assignmentsQueryKeys } from './constants'
import { getAssignmentsForAssignable } from './getAssignmentsForAssignable'

export const getAssignmentsForEntityQueryOptions = (entityId: Corn) =>
  queryOptions({
    queryKey: assignmentsQueryKeys.assignable.detail(entityId),
    queryFn: () => getAssignmentsForAssignable({ assignableId: entityId }),
    enabled: !!entityId && isCorn(entityId),
  })

export const useGetAssignmentsForEntity = (entityId: Corn) =>
  useQuery(getAssignmentsForEntityQueryOptions(entityId))

export const useGetAssignmentsForEntities = (entityIds: Corn[]) =>
  useQueries({
    queries: entityIds.map((id) => getAssignmentsForEntityQueryOptions(id)),
  })

export function useInvalidateAssignmentsForEntity() {
  const queryClient = useQueryClient()

  return function invalidateAssignmentsForEntity(entityId: Corn) {
    queryClient.invalidateQueries({
      queryKey: getAssignmentsForEntityQueryOptions(entityId).queryKey,
    })
  }
}
