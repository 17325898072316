import { Chronicle, GetTimelineRequest } from '@eigtech/summarizer-types'
import { OmitAuthHeaders } from '@eigtech/ui-shared-api'
import { useUserContext } from '@eigtech/ui-shared-auth'
import { STANDARD_DATE_TIME_FORMAT, format, useDatesContext } from '@eigtech/ui-shared-dates'
import {
  Badge,
  Box,
  Center,
  ComposedAlert,
  HStack,
  Icon,
  Skeleton,
  Stack,
  StackProps,
  Text,
  chakra,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import { VediImportStateEnum, VediImportStateSchema } from '@eigtech/verisk-edi-interface-types'
import { groupBy, range } from 'lodash-es'
import { ReactNode, useMemo } from 'react'
import { IconType } from 'react-icons'
import { useGetTimeline } from '../api/getTimeline'
import { timelinePointsMainDateComparator } from '../utils/comparator'
import { getCreatedByLabel } from '../utils/getCreatedByLabel'
import { getTimelinePointMainDate, getTimelinePointRecordedDate } from '../utils/getTimelineDates'
import { normalizeTimelineDate } from '../utils/normalizeTimelineDate'

export type DetailedTimelineProps = StackProps &
  Omit<OmitAuthHeaders<GetTimelineRequest>, 'filter'> &
  Pick<PointProps, 'getPointIcon' | 'eventComponents'>

export const DetailedTimeline = forwardRef<DetailedTimelineProps, 'div'>(function DetailedTimeline(
  { entityId, entityType, eventComponents, getPointIcon, timelineType, ...props },
  ref
) {
  const { data, isPending, isError } = useGetTimeline({ entityId, entityType, timelineType })

  const timeline = useMemo(() => {
    const { unknownDate, ...groupedTimeline } = groupBy(
      data?.timeline.chronicles ?? [],
      (point) => {
        const dateValue = getTimelinePointMainDate(point)

        if (!dateValue) {
          return 'unknownDate'
        }

        return format(new Date(dateValue), 'MMM dd, yyyy')
      }
    )

    const sorted = Object.entries(groupedTimeline).sort(([a], [b]) => {
      return new Date(b).getTime() - new Date(a).getTime()
    })

    if (!!unknownDate?.length) {
      sorted.push(['Unknown Date', unknownDate])
    }

    const normalized = sorted.map(
      ([date, points]) => [date, points.sort(timelinePointsMainDateComparator)] as const
    )

    return normalized
  }, [data?.timeline.chronicles])

  return (
    <Stack ref={ref} {...props}>
      {isPending ? (
        range(0, 3).map((i) => <Skeleton key={i} h="10" />)
      ) : isError ? (
        <ComposedAlert alert="Could not retrieve timeline information." status="error" />
      ) : (
        timeline.map(([dateLabel, points], index) => (
          <Box key={dateLabel} position="relative">
            <Stack position="relative" spacing="4" zIndex={1}>
              <HStack>
                <chakra.div w={firstColumnWidth} />
                <Center h="6" position="relative" w={secondColumnWidth}>
                  <chakra.div
                    backgroundColor="brandPrimary.200"
                    bottom="-2"
                    position="absolute"
                    top={index === 0 ? '0' : '-10'}
                    width="2px"
                    zIndex={1}
                  />

                  <Badge maxW="32" position="absolute" textAlign="center" zIndex={2}>
                    {dateLabel}
                  </Badge>
                </Center>
              </HStack>

              <Stack spacing="8">
                {points.map((point, pointIndex) => {
                  const recordedDate = getTimelinePointRecordedDate(point)
                  const mainDate = getTimelinePointMainDate(point)
                  const key = `${point.type}${recordedDate}${mainDate}${
                    point.createdBy
                  }${JSON.stringify(point.details ?? {})}`

                  return (
                    <Point
                      key={key}
                      entityId={entityId}
                      eventComponents={eventComponents}
                      getPointIcon={getPointIcon}
                      isFirstPoint={index === 0 && pointIndex === 0}
                      isLastPoint={
                        index + 1 === timeline.length && pointIndex + 1 === points.length
                      }
                      point={point}
                    />
                  )
                })}
              </Stack>
            </Stack>
          </Box>
        ))
      )}
    </Stack>
  )
})

type PointProps = Pick<EventComponentProps, 'entityId' | 'eventComponents'> & {
  getPointIcon: (event: string) => IconType
  point: Chronicle
  isFirstPoint: boolean
  isLastPoint: boolean
}

function Point({
  getPointIcon,
  entityId,
  eventComponents,
  point,
  isFirstPoint,
  isLastPoint,
}: PointProps) {
  const { label, type: timelineEvent, createdBy, details } = point

  const mainDate = getTimelinePointMainDate(point)
  const recordedDate = getTimelinePointRecordedDate(point)
  const createdByLabel = !!createdBy && getCreatedByLabel(createdBy)

  const { PreferredUserComponent: UserComponent } = useUserContext()
  const { PreferredDateTimeComponent: DateComponent } = useDatesContext()

  const xaStatus =
    !!details && 'xaStatus' in details && VediImportStateSchema.safeParse(details.xaStatus).success
      ? (details.xaStatus as VediImportStateEnum)
      : undefined

  return (
    <HStack alignItems="flex-start" lineHeight={1.2}>
      <Text display="flex" flexShrink={0} justifyContent="flex-end" pt="7px" w={firstColumnWidth}>
        <DateComponent
          format="hh:mm aa"
          property="mainDate"
          secondaryFormat={STANDARD_DATE_TIME_FORMAT}
        >
          {!!mainDate ? normalizeTimelineDate(timelineEvent, mainDate) : mainDate}
        </DateComponent>
      </Text>

      <Center
        alignItems="flex-start"
        alignSelf="stretch"
        borderRadius="full"
        flexShrink={0}
        position="relative"
        w={secondColumnWidth}
      >
        <chakra.div
          backgroundColor="brandPrimary.200"
          bottom={isLastPoint ? '100%' : '0'}
          position="absolute"
          top="-8"
          width="2px"
          zIndex={1}
        />
        <Center
          backgroundColor={isFirstPoint ? 'brandPrimary.500' : 'white'}
          borderColor="brandPrimary.500"
          borderRadius="full"
          borderWidth="1px"
          h={secondColumnWidth}
          position="relative"
          w={secondColumnWidth}
          zIndex={2}
        >
          <Icon
            as={getPointIcon(timelineEvent)}
            color={isFirstPoint ? 'white' : 'brandPrimary.500'}
          />
        </Center>
      </Center>

      <Stack pt="7px" spacing="0.5">
        <HStack spacing="4">
          <Text>{label}</Text>

          {!!xaStatus && <Badge colorScheme="blue">XA - {xaStatus}</Badge>}
        </HStack>

        {!!details && (
          <EventComponent
            details={details}
            entityId={entityId}
            event={timelineEvent}
            eventComponents={eventComponents}
          />
        )}

        {!!(recordedDate || createdByLabel) && (
          <Text fontSize="sm">
            {!!createdByLabel && (
              <>
                Created by{' '}
                <chakra.span fontWeight="semibold">
                  <UserComponent property="createdBy" user={createdByLabel} />
                </chakra.span>
              </>
            )}

            {!!recordedDate && (
              <>
                {!!createdByLabel ? ' on ' : 'Recorded on '}
                <chakra.span fontWeight="semibold">
                  <DateComponent property="recordedDate">{recordedDate}</DateComponent>
                </chakra.span>
              </>
            )}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}

type EventComponentProps = {
  entityId: string
  event: string
  eventComponents: Record<
    string,
    (props: { details: Record<string, unknown>; entityId: string }) => ReactNode
  >
  details: Record<string, unknown>
}

function EventComponent({ entityId, event, eventComponents, details }: EventComponentProps) {
  const Component = eventComponents[event]

  if (!Component) {
    return null
  }

  return <Component details={details} entityId={entityId} />
}

const firstColumnWidth = '75px'
const secondColumnWidth = '8'
