import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'

export const useToast: typeof useChakraToast = () => {
  const chakraToast = useChakraToast()

  const createToastFn = useCallback(() => {
    const toast = (options: UseToastOptions = {}) => {
      const mergedOptions: UseToastOptions = {
        isClosable: true,
        position: 'top-right',
        variant: 'subtle',
        ...options,
      }
      return chakraToast(mergedOptions)
    }

    toast.update = chakraToast.update

    toast.promise = chakraToast.promise

    toast.closeAll = chakraToast.closeAll
    toast.close = chakraToast.close
    toast.isActive = chakraToast.isActive

    return toast
  }, [chakraToast])

  return useMemo(() => createToastFn(), [createToastFn])
}
