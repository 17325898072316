import { ContactCornSchema } from '@eigtech/contacts-types'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  CustomerContactMethodSchema,
  CustomerContactOutcomeSchema,
} from '../../base/customerContact.js'
import { JobIdSchema } from '../../base/ids.js'

export const RecordCustomerContactAttemptRequestBodySchema = z.object({
  contactedBy: ContactCornSchema,
  contactAttemptedDate: z.string().datetime(),
  customerContacted: ContactCornSchema.or(z.string()),
  contactOutcome: CustomerContactOutcomeSchema,
  contactMethod: CustomerContactMethodSchema,
})
export const RecordCustomerContactAttemptRequestHeadersSchema = ApiLambdalerAuthHeaderSchema.pick({
  requestActor: true,
})
export const RecordCustomerContactAttemptRequestPathSchema = z.object({
  jobId: JobIdSchema,
})
export const RecordCustomerContactAttemptRequestSchema =
  RecordCustomerContactAttemptRequestBodySchema.merge(
    RecordCustomerContactAttemptRequestHeadersSchema
  ).merge(RecordCustomerContactAttemptRequestPathSchema)
export const RecordCustomerContactAttemptResponseSchema = z.void()
export const RecordCustomerContactAttemptApiSchema: ApiSchema = {
  body: RecordCustomerContactAttemptRequestBodySchema,
  headers: RecordCustomerContactAttemptRequestHeadersSchema,
  params: RecordCustomerContactAttemptRequestPathSchema,
}

export interface RecordCustomerContactAttemptRequestBody
  extends z.infer<typeof RecordCustomerContactAttemptRequestBodySchema> {}
export interface RecordCustomerContactAttemptRequestPath
  extends z.infer<typeof RecordCustomerContactAttemptRequestPathSchema> {}
export interface RecordCustomerContactAttemptRequest
  extends z.infer<typeof RecordCustomerContactAttemptRequestSchema> {}
export type RecordCustomerContactAttemptResponse = z.infer<
  typeof RecordCustomerContactAttemptResponseSchema
>
export interface RecordCustomerContactAttemptHandler
  extends Handler<RecordCustomerContactAttemptRequest, RecordCustomerContactAttemptResponse> {}
