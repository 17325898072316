import { CircularProgress, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

type GlobalLoaderProps = {
  phrases?: string[]
}

export function GlobalLoader({ phrases }: GlobalLoaderProps) {
  return (
    <Stack
      alignItems="center"
      bg="gray.50"
      bottom="0"
      h="100vh"
      justifyContent="center"
      left="0"
      position="fixed"
      right="0"
      top="0"
      w="100vw"
      zIndex={9999}
    >
      <CircularProgress isIndeterminate size={100} />
      {!!phrases?.length && <GlobalLoaderPhrases phrases={phrases} />}
    </Stack>
  )
}

export function GlobalLoaderPhrases({ phrases }: Required<Pick<GlobalLoaderProps, 'phrases'>>) {
  const [phrase, setPhrase] = useState<number>(0)

  useEffect(() => {
    if (!phrases?.length) return

    function setNextPhrase() {
      if (!phrases?.length) return

      setPhrase((currentPhrase) => {
        const index = currentPhrase + 1
        return !!phrases[index] ? index : 0
      })
    }

    setNextPhrase()
    const interval = setInterval(() => {
      setNextPhrase()
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [phrases])

  return <Text>{phrases[phrase]}...</Text>
}
