import { Tag as TagType } from '@eigtech/resource-tagging-types'
import { Corn } from '@eigtech/shared-corn'
import { ContextualCan } from '@eigtech/ui-shared-auth'
import { mostReadable, stringToColor } from '@eigtech/ui-shared-color'
import {
  Skeleton,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
  WrapProps,
  forwardRef,
  useToast,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { useEffect } from 'react'
import { useRemoveTagFromResource } from '../api/removeTag'
import { useGetTagsByResource } from '../api/tags'

export type TagsListProps = { resourceCorn: Corn; onTagClick?: (tag: TagType) => any } & WrapProps

export const TagsList = forwardRef<TagsListProps, 'ul'>(
  ({ resourceCorn, onTagClick, ...props }, ref) => {
    const { data: tags = [], isPending, isError } = useGetTagsByResource({ resourceCorn })

    const toast = useToast()
    useEffect(() => {
      if (isError) {
        toast({
          status: 'error',
          title: 'Could not retrieve tags for resource',
        })
      }
    }, [isError, toast])

    return (
      <Wrap ref={ref} spacing="2" {...props}>
        {isPending ? (
          <>
            <WrapItem>
              <Skeleton h="6" w="20" />
            </WrapItem>
            <WrapItem>
              <Skeleton h="6" w="20" />
            </WrapItem>
            <WrapItem>
              <Skeleton h="6" w="20" />
            </WrapItem>
          </>
        ) : (
          tags.map((tag) => (
            <WrapItem key={`${tag.key}:${tag.value}`}>
              <TagItem tag={tag} onTagClick={onTagClick} />
            </WrapItem>
          ))
        )}
      </Wrap>
    )
  }
)

function TagItem({ tag, onTagClick }: { tag: TagType; onTagClick?: (tag: TagType) => any }) {
  const toast = useToast()

  const { mutateAsync: removeTag, isPending } = useRemoveTagFromResource()

  async function handleRemoveTag() {
    const id = toast({
      status: 'info',
      title: 'Removing tag...',
      duration: null,
    })

    try {
      await removeTag(tag)
    } catch (e) {
      log.error('error removing tag', { error: e })
      toast({
        status: 'error',
        title: 'Something went wrong trying to remove this tag.',
      })
      throw e
    } finally {
      toast.close(id)
    }

    toast({
      status: 'success',
      title: 'Successfully removed tag!',
    })
  }

  const label = () => {
    let label = tag.key
    if (tag.value !== 'EMPTY_VALUE') {
      label = `${label}:${tag.value}`
    }
    return label
  }

  const color = tag.color || stringToColor(tag.key)
  const fontColor = mostReadable(color, ['#fff', '#000'])?.toHexString()

  return (
    <Tag bg={color} color={fontColor}>
      <TagLabel cursor={!!onTagClick ? 'pointer' : undefined} onClick={() => onTagClick?.(tag)}>
        {label()}
      </TagLabel>
      <ContextualCan I="delete" a="tag">
        <TagCloseButton isDisabled={isPending} onClick={handleRemoveTag} />
      </ContextualCan>
    </Tag>
  )
}
