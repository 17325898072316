import { CornSchema } from '@eigtech/shared-corn'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const UpdateScreenshotNotesRequestPathSchema = z.object({
  entityId: CornSchema,
  startTime: z.string().datetime(),
  dateAdded: z.string().datetime(),
})

export const UpdateScreenshotNotesRequestBodySchema = z.object({
  notes: z.string(),
  label: z.string(),
})

export const UpdateScreenshotNotesRequestSchema = UpdateScreenshotNotesRequestBodySchema.merge(
  UpdateScreenshotNotesRequestPathSchema
)

export const UpdateScreenshotNotesResponseSchema = z.void()

export type UpdateScreenshotNotesRequest = z.infer<typeof UpdateScreenshotNotesRequestSchema>
export type UpdateScreenshotNotesRequestPath = z.infer<
  typeof UpdateScreenshotNotesRequestPathSchema
>
export type UpdateScreenshotNotesRequestBody = z.infer<
  typeof UpdateScreenshotNotesRequestBodySchema
>
export type UpdateScreenshotNotesResponse = z.infer<typeof UpdateScreenshotNotesResponseSchema>

export interface UpdateScreenshotNotesHandler
  extends Handler<UpdateScreenshotNotesRequest, UpdateScreenshotNotesResponse> {}
