import { defineTable, Schema, NewTableRow, TableRow } from 'squid'

export type NewCarrierRecord = NewTableRow<typeof carrierTable>
export type CarrierRecord = TableRow<typeof carrierTable>

const carrierTable = defineTable('carrier', {
  id: Schema.String,
  name: Schema.String,
  office: Schema.nullable(Schema.String),
})
