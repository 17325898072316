import { IncomingHttpHeaders } from 'http'
import { z } from 'zod'
import { ParsedExportBaseSchema } from './parsedBase.js'
import { ParsedContactSchema } from './parsedContact.js'
import { XAContact } from './xaBase.js'

/** This type is temporary and should be updated when we understand more about what this event will look like*/
export type RawStatusExport = {
  payload: string
  headers: IncomingHttpHeaders
}

/** This type is temporary and should be updated when we understand more about what this event will look like*/
export const ParsedStatusExportSchema = ParsedExportBaseSchema.merge(
  z.object({
    type: z.string(),
    claimNumber: z.string(),
    contacts: ParsedContactSchema.array(),
    origTransactionId: z.string().optional(),
    recipientsXNAddress: z.string(),
    recipientsXM8UserId: z.string(),
    senderReviewerEmail: z.string().optional(),
    senderReviewerName: z.string().optional(),
  })
)
export type ParsedStatusExport = z.infer<typeof ParsedStatusExportSchema>

export type XAStatusDoc = {
  XACTDOC: {
    XACTNET_INFO: {
      CONTROL_POINTS: {
        CONTROL_POINT: {
          stamp: string
          type: string
        }
      }
      transactionId: string
      recipientsXNAddress: string
      recipientsXM8UserId: string
      senderReviewerEmail: string | undefined
      senderReviewerName: string | undefined
      origTransactionId: string | undefined
    }
    CONTACTS: {
      CONTACT: XAContact | Array<XAContact>
    }
    ADM: {
      TYPESOFLOSS: {
        TYPEOFLOSS: {
          claimNumber: string
        }
      }
    }
  }
}
