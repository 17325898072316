import { BoxProps, Center, CircularProgress, forwardRef } from '@eigtech/ui-shared-dave'
import { lazy, Suspense } from 'react'

const Game = lazy(() => import('@eigtech/ui-shared-dino'))

export const DinoGame = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Suspense
      fallback={
        <Center>
          <CircularProgress size={50} />
        </Center>
      }
    >
      <Game ref={ref} {...props} />
    </Suspense>
  )
})
