import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactSchema } from '../../../base/contacts.js'

export const DeleteContactDescriptionRequestPathSchema = z.object({
  contactId: ContactSchema.shape.contactId,
})

export const DeleteContactDescriptionRequestSchema = DeleteContactDescriptionRequestPathSchema

export const DeleteContactDescriptionResponseSchema = z.void()

export type DeleteContactDescriptionRequestPath = z.infer<
  typeof DeleteContactDescriptionRequestPathSchema
>
export type DeleteContactDescriptionRequest = z.infer<typeof DeleteContactDescriptionRequestSchema>

export type DeleteContactDescriptionResponse = z.infer<
  typeof DeleteContactDescriptionResponseSchema
>

export interface DeleteContactDescriptionHandler
  extends Handler<DeleteContactDescriptionRequest, DeleteContactDescriptionResponse> {}

export const DeleteContactDescriptionApiSchema: ApiSchema = {
  params: DeleteContactDescriptionRequestPathSchema,
}
