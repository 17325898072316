import { ensureClaimCorn } from '@eigtech/shared-corn-helper'
import { useGetClaim } from '@eigtech/ui-estimator/api'
import { useCanPublishClaimInfoToXA } from '@eigtech/ui-shared-claims'
import { CreateEntityNoteDrawer, CreateEntityNoteDrawerProps } from '@eigtech/ui-shared-notes'

export type ClaimNoteFormDrawerProps = Omit<CreateEntityNoteDrawerProps, 'entityCorn'> & {
  claimNumber: string
}

export function ClaimNoteFormDrawer({
  claimNumber,
  templateParams,
  ...props
}: ClaimNoteFormDrawerProps) {
  const { data: claim } = useGetClaim(claimNumber)
  const includePublishField = useCanPublishClaimInfoToXA({ claim })

  return (
    <CreateEntityNoteDrawer
      {...props}
      entityCorn={ensureClaimCorn(claimNumber)}
      includePublishField={includePublishField}
      includeTemplateField={false}
      templateParams={{ ...templateParams, ...claim }}
    />
  )
}
