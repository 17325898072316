import { validateDateInRangeOfCurrentDate } from '@eigtech/function-utils'
import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import {
  PortalConsumerInSchema,
  PortalEntityTypeSchema,
  PortalZoneSchema,
  StagedPortalConsumerInSchema,
} from '../../base/portal.js'

export const SetPortalExpirationRequestBodySchema = z.object({
  externalUserId: z.string(),
  entityId: z.string(),
  entityType: PortalEntityTypeSchema,
  expireDate: z
    .string()
    .datetime()
    .refine(
      (dateStr) =>
        validateDateInRangeOfCurrentDate(dateStr, {
          minDateMsQuery: '-10y',
          maxDateMsQuery: '10y',
        }),
      'date is out of range'
    ),
  requestActor: z.string(),
  zone: PortalZoneSchema,
})

export const SetPortalExpirationRequestSchema = SetPortalExpirationRequestBodySchema

export const SetPortalExpirationResponseSchema = z.object({
  portal: PortalConsumerInSchema.or(StagedPortalConsumerInSchema).or(z.null()),
})

export type SetPortalExpirationRequestBody = z.infer<typeof SetPortalExpirationRequestBodySchema>
export type SetPortalExpirationRequest = z.infer<typeof SetPortalExpirationRequestSchema>
export type SetPortalExpirationResponse = z.infer<typeof SetPortalExpirationResponseSchema>

export interface SetPortalExpirationHandler
  extends Handler<SetPortalExpirationRequest, SetPortalExpirationResponse> {}

export const SetPortalExpirationApiSchema: ApiSchema = {
  body: SetPortalExpirationRequestBodySchema,
  response: SetPortalExpirationResponseSchema,
}
