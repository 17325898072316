import {
  Button,
  chakra,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
} from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'
import { RowData } from '@tanstack/react-table'
import { ReactNode, useEffect } from 'react'
import { MdClose as ClearIcon } from 'react-icons/md'
import { WithColumnProps } from '../../types'
import { getColumnTitle } from '../utils'

export function DataGridArrayFilter<TData extends RowData, TValue>({
  column,
  type = 'one',
  label,
}: WithColumnProps<TData, TValue> & { type?: 'one' | 'some' | 'all'; label?: ReactNode }) {
  const verb =
    type === 'one' ? 'includes' : type === 'some' ? 'includes some of' : 'includes all of'

  useEffect(() => {
    if (!column.columnDef.meta?.arrayFilterOptions) {
      log.warn(
        "Attempting to use DataGridArrayFilter component without providing `arrayFilterOptions` in the column's `meta` option"
      )
    }
  }, [column])

  return (
    <FormControl>
      <Stack>
        <HStack justifyContent="space-between">
          <FormLabel fontSize="sm">
            {!label || typeof label === 'string' ? (
              <>
                <chakra.span fontStyle="italic">{getColumnTitle(column)}</chakra.span>{' '}
                {label ?? verb}:
              </>
            ) : (
              label
            )}
          </FormLabel>

          <Button
            rightIcon={<ClearIcon />}
            size="sm"
            variant="ghost"
            onClick={() => column.setFilterValue(undefined)}
          >
            Clear
          </Button>
        </HStack>

        {type === 'one' ? <RadioFilter column={column} /> : <CheckboxFilter column={column} />}
      </Stack>
    </FormControl>
  )
}

function RadioFilter<TData extends RowData, TValue>({ column }: WithColumnProps<TData, TValue>) {
  const options = column.columnDef.meta?.arrayFilterOptions ?? []

  return (
    <RadioGroup
      value={column.getFilterValue() as string}
      onChange={(value) => {
        column.setFilterValue(value)
      }}
    >
      <SimpleGrid columns={2} gap={2}>
        {options.map((option) => {
          const { label, value } =
            typeof option === 'object' ? option : { label: option, value: option }
          return (
            <GridItem key={value}>
              <Radio value={value.toString()}>{label}</Radio>
            </GridItem>
          )
        })}
      </SimpleGrid>
    </RadioGroup>
  )
}

function CheckboxFilter<TData extends RowData, TValue>({ column }: WithColumnProps<TData, TValue>) {
  const options = column.columnDef.meta?.arrayFilterOptions ?? []

  return (
    <CheckboxGroup
      value={(column.getFilterValue() as string[] | undefined) ?? []}
      onChange={(value) => {
        column.setFilterValue(value ?? [])
      }}
    >
      <SimpleGrid columns={2} gap={2}>
        {options.map((option) => {
          const { label, value } =
            typeof option === 'object' ? option : { label: option, value: option }
          return (
            <GridItem key={value}>
              <Checkbox value={value}>{label}</Checkbox>
            </GridItem>
          )
        })}
      </SimpleGrid>
    </CheckboxGroup>
  )
}
