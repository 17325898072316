import z, { ZodSchema } from 'zod'
import { DailyServiceEnum, DailyServiceSchema } from './dailyJob'
import {
  EmergencyServicesServiceEnum,
  EmergencyServicesServiceSchema,
} from './emergencyServicesJob'
import { JobTypeEnum } from './job'
import { OtrServiceEnum, OtrServiceSchema } from './otrJob'

export const JobServicesSchema = z.union([
  DailyServiceSchema.array(),
  EmergencyServicesServiceSchema.array(),
  OtrServiceSchema.array(),
])

export type JobServices = z.infer<typeof JobServicesSchema>

export const jobServiceSchemaMap = {
  daily: DailyServiceSchema,
  emergencyServices: EmergencyServicesServiceSchema,
  otr: OtrServiceSchema,
} satisfies Record<
  JobTypeEnum,
  ZodSchema<DailyServiceEnum> | ZodSchema<EmergencyServicesServiceEnum> | ZodSchema<OtrServiceEnum>
>

export const jobServiceArraySchemaMap = {
  daily: jobServiceSchemaMap.daily.array(),
  emergencyServices: jobServiceSchemaMap.emergencyServices.array(),
  otr: jobServiceSchemaMap.otr.array(),
} satisfies Record<
  JobTypeEnum,
  | ZodSchema<DailyServiceEnum[]>
  | ZodSchema<EmergencyServicesServiceEnum[]>
  | ZodSchema<OtrServiceEnum[]>
>
