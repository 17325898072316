import { Operator } from '@eigtech/summarizer-types'
import {
  createForm,
  useArrayFieldAddButtonProps,
  useArrayFieldContext,
} from '@eigtech/ui-shared-forms'
import {
  Button,
  ButtonGroup,
  ComposedAlert,
  HStack,
  Icon,
  IconProps,
  Stack,
  forwardRef,
} from '@eigtech/ui-shared-dave'
import { MdAdd } from 'react-icons/md'
import { ServerSideFilterRow, ServerSideFilterRowProps } from './FilterRow'
import {
  ServerSideFilterField,
  ServerSideFilterForm,
  ServerSideFilterFormSchema,
  isContactSchema,
} from './formSchema'

type ServerFilter<Field extends string = string> = {
  value: string
  operator?: Operator | 'WITHIN'
  field: Field
}

export type ServerSideFilteringProps<Field extends string = string> = {
  defaultFilters?: ServerSideFilterField[]
  onChange: (filter: ServerFilter<Field>[]) => void
} & Pick<ServerSideFilterRowProps<Field>, 'fieldOptions' | 'fieldOperators' | 'fieldValueFields'>

export function ServerSideFiltering<Field extends string = string>({
  defaultFilters,
  onChange,
  ...filterRowProps
}: ServerSideFilteringProps<Field>) {
  const form = useForm({ defaultValues: { filter: defaultFilters } })

  function onSubmit({ filter }: ServerSideFilterForm) {
    const filters: ServerFilter<Field>[] = filter.map(({ value, field, ...filter }) => {
      const base = { ...filter, field: field as Field }

      if (isContactSchema(value)) {
        return { ...base, value: value.contactId }
      }

      return { ...base, value }
    })

    return onChange(filters)
  }

  return (
    <form.Form onSubmit={onSubmit}>
      <Stack>
        <ArrayField
          RowsContainer={({ children }) => (
            <Stack spacing="4">
              <Stack spacing="4">{children}</Stack>

              <HStack justifyContent="flex-end">
                <ButtonGroup isAttached>
                  <ClearButton />
                  <AddButton />
                </ButtonGroup>

                <form.SubmitButton>Save Filters</form.SubmitButton>
              </HStack>
            </Stack>
          )}
          defaultValue={{ field: '', operator: '=', value: '' }}
          emptyMessage={
            <ComposedAlert
              alert={{
                description: 'Click the "Add Filter" button to start filtering.',
              }}
              mb="4"
              py="2"
            />
          }
          name="filter"
          renderRow={(field, index) => (
            <ServerSideFilterRow {...filterRowProps} field={field} index={index} />
          )}
        />
      </Stack>
    </form.Form>
  )
}

const { useForm, ArrayField } = createForm(ServerSideFilterFormSchema, 'serverSideFilterForm')

function ClearButton() {
  const { remove } = useArrayFieldContext()
  return (
    <Button colorScheme="red" variant="outline" onClick={() => remove()}>
      Clear Filters
    </Button>
  )
}

function AddButton() {
  return (
    <Button {...useArrayFieldAddButtonProps()} rightIcon={<AddIcon />} variant="outline">
      Add Filter
    </Button>
  )
}

const AddIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <Icon ref={ref} {...props} as={MdAdd} />
))
