import {
  ComponentWithAs,
  forwardRef,
  TableFooterProps,
  Tfoot,
  Th,
  Tr,
} from '@eigtech/ui-shared-dave'
import { flexRender } from '@tanstack/react-table'
import { useDataGridContext } from './DataGridContext'

export const DataGridFoot: ComponentWithAs<'tfoot', TableFooterProps> = forwardRef((props, ref) => {
  const { tableId, table } = useDataGridContext()

  const tableFootId = `${tableId}_Foot`
  const tableFootRowId = (rowId: string) => `${tableFootId}_Row_${rowId}`
  const tableFootCellId = (rowId: string, cellId: string) => `${tableFootRowId(rowId)}_${cellId}`

  return (
    <Tfoot ref={ref} id={tableFootId} {...props}>
      {table.getFooterGroups().map((footerGroup) => (
        <Tr key={footerGroup.id} id={tableFootRowId(footerGroup.id)}>
          {footerGroup.headers.map((header) => (
            <Th key={header.id} id={tableFootCellId(footerGroup.id, header.id)}>
              {header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.footer, header.getContext())}
            </Th>
          ))}
        </Tr>
      ))}
    </Tfoot>
  )
})
