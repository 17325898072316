import { Contact } from '@eigtech/contacts-types'
import { isContactInactive, isContactRemoved } from '@eigtech/contacts-util'
import { getContactName, useGetCarrierBook } from '@eigtech/ui-shared-contacts'
import { Badge, HStack, Stack, Text } from '@eigtech/ui-shared-dave'
import {
  FieldValues,
  FormFieldControl,
  FormFieldControlProps,
  WithFieldNameProp,
  useController,
} from '@eigtech/ui-shared-forms'
import {
  chakraComponents,
  GroupBase,
  Props,
  Select,
  SelectComponentsConfig,
  SelectInstance,
} from 'chakra-react-select'
import { ReactElement, ReactNode, RefAttributes, forwardRef, useMemo } from 'react'

export const CarrierSelect = forwardRef(function CarrierSelect(props, ref) {
  const { data, isPending } = useGetCarrierBook()

  const contacts = useMemo(() => {
    const statusMap = {
      active: 0,
      inactive: 1,
      removed: 2,
    }

    return (data?.contacts ?? []).toSorted((a, b) => {
      const aStatus = isContactInactive(a)
        ? statusMap.inactive
        : isContactRemoved(a)
          ? statusMap.removed
          : statusMap.active
      const bStatus = isContactInactive(b)
        ? statusMap.inactive
        : isContactRemoved(b)
          ? statusMap.removed
          : statusMap.active

      return aStatus - bStatus
    })
  }, [data?.contacts])

  return (
    <Select
      ref={ref}
      {...props}
      components={selectComponents}
      getOptionLabel={(contact) => getContactName(contact)}
      getOptionValue={(contact) => contact.contactId}
      isLoading={isPending}
      isOptionDisabled={(contact) => isContactInactive(contact) || isContactRemoved(contact)}
      menuPortalTarget={document.body}
      options={contacts}
    />
  )
}) as (
  props: Props<Contact, false, GroupBase<Contact>> &
    RefAttributes<SelectInstance<Contact, false, GroupBase<Contact>>>
) => ReactElement

const selectComponents: SelectComponentsConfig<Contact, false, GroupBase<Contact>> = {
  Option: ({ children: __, ...props }) => (
    <chakraComponents.Option {...props}>
      <Stack spacing="0.5">
        <Text>{props.label}</Text>
        {(isContactInactive(props.data) || isContactRemoved(props.data)) && (
          <HStack>
            {isContactInactive(props.data) && <Badge colorScheme="yellow">Inactive</Badge>}
            {isContactRemoved(props.data) && <Badge colorScheme="red">Removed</Badge>}
          </HStack>
        )}
      </Stack>
    </chakraComponents.Option>
  ),
}

export type CarrierSelectFieldProps<TFieldValues extends FieldValues = FieldValues> =
  WithFieldNameProp<
    Omit<Props<Contact, false, GroupBase<Contact>>, keyof FormFieldControlProps>,
    TFieldValues
  > & {
    label: ReactNode
    fieldProps?: Omit<FormFieldControlProps, 'label' | 'name'>
  }

export function CarrierSelectField<TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  fieldProps,
  ...selectProps
}: CarrierSelectFieldProps<TFieldValues>) {
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control: fieldProps?.control,
  })

  return (
    <FormFieldControl {...fieldProps} label={label} name={name}>
      <CarrierSelect {...field} isDisabled={isSubmitting} {...selectProps} />
    </FormFieldControl>
  )
}
