import { chakra, DebouncedInput, DebouncedInputProps } from '@eigtech/ui-shared-dave'
import { RowData } from '@tanstack/react-table'
import { ReactNode } from 'react'
import { getColumnTitle, getFilterActualName } from '../utils'
import { DataGridFilterContainer } from './DataGridFilterContainer'
import { WithColumnProps } from '../../types'

export function DataGridTextFilter<TData extends RowData, TValue>({
  column,
  type = 'text',
  label,
}: WithColumnProps<TData, TValue> & Pick<DebouncedInputProps, 'type'> & { label?: ReactNode }) {
  const verb = () => {
    if (!!column.columnDef.meta?.availableFilterFns?.length) {
      return ''
    }

    const filterName = column.getFilterFn()?.name

    if (!filterName) return '???'

    const filterActualName = getFilterActualName(filterName)
    const lowerCaseFilterName = filterActualName.toLowerCase()

    if (lowerCaseFilterName.includes('equals')) return 'equals'
    if (lowerCaseFilterName.includes('include')) return 'includes'

    return ` ${filterActualName}`
  }

  return (
    <DataGridFilterContainer
      column={column}
      label={
        !label || typeof label === 'string' ? (
          <>
            <chakra.span fontStyle="italic">{getColumnTitle(column)}</chakra.span> {label ?? verb()}
            :
          </>
        ) : (
          label
        )
      }
    >
      <DebouncedInput
        pr="10"
        type={type}
        value={(column.getFilterValue() as string) ?? ''}
        onChange={(event) => {
          column.setFilterValue(event.target.value)
        }}
      />
    </DataGridFilterContainer>
  )
}
