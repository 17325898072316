import { z } from 'zod'

export const TranscriptItemSchema = z.object({
  type: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  speakerAttendeeId: z.string(),
  speakerExternalUserId: z.string(),
  content: z.string(),
  vocabularyFilterMatch: z.boolean(),
})

export const TranscriptResultSchema = z.object({
  resultId: z.string(),
  isPartial: z.boolean(),
  startTimeMs: z.number(), // epoch time
  endTimeMs: z.number(), // epoch time
  alternatives: z
    .object({
      transcript: z.string(),
      items: TranscriptItemSchema.array(),
    })
    .array(),
})

export const TranscriptSchema = z.object({
  transcript: z.object({
    results: TranscriptResultSchema.array(),
  }),
})

export type TranscriptItem = z.infer<typeof TranscriptItemSchema>
export type TranscriptResult = z.infer<typeof TranscriptResultSchema>
export type Transcript = z.infer<typeof TranscriptSchema>

export const MeetingTranscriptEventSchema = z.object({
  speakerName: z.string(),
  seconds: z.number(),
  text: z.string(),
})

export const MeetingTranscriptSchema = MeetingTranscriptEventSchema.array()

export type MeetingTranscriptEvent = z.infer<typeof MeetingTranscriptEventSchema>
export type MeetingTranscript = z.infer<typeof MeetingTranscriptSchema>
