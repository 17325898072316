import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const DeleteUserRequestPathSchema = z.object({
  userId: z.string(),
})

export const DeleteUserRequestSchema = DeleteUserRequestPathSchema
export const DeleteUserResponseSchema = z.void()
export const DeleteUserApiSchema = { params: DeleteUserRequestPathSchema } satisfies ApiSchema

export type DeleteUserRequest = z.infer<typeof DeleteUserRequestSchema>
export type DeleteUserRequestPath = z.infer<typeof DeleteUserRequestPathSchema>
export type DeleteUserResponse = z.infer<typeof DeleteUserResponseSchema>

export interface DeleteUserHandler extends Handler<DeleteUserRequest, DeleteUserResponse> {}
