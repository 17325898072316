import { CohortEnum, CohortEnumSchema } from '@eigtech/auth0-types'
import { isClaimCorn } from '@eigtech/shared-corn-helper'
import { isJobCorn } from '@eigtech/restoration-types'

export type CohortEntityType = 'claim' | 'job'

export type CohortMapping<EntityType extends string = string> = {
  check: (s: string) => boolean
  cohorts: CohortEnum[] | ((entityType: EntityType) => CohortEnum[])
}

type VisibilityEntityType = 'document' | 'note'

export const exportTargetCohortMap = {
  job: {
    check: isJobCorn,
    cohorts: [
      CohortEnumSchema.Enum.csr,
      CohortEnumSchema.Enum.insured,
      CohortEnumSchema.Enum.technician,
    ],
  },
  claim: {
    check: isClaimCorn,
    cohorts: [
      CohortEnumSchema.Enum.csr,
      CohortEnumSchema.Enum.estimator,
      CohortEnumSchema.Enum.insured,
      CohortEnumSchema.Enum.technician,
    ],
  },
} satisfies Record<CohortEntityType, CohortMapping>

export const visibilityTargetCohortMap = {
  job: {
    check: isJobCorn,
    cohorts: (entityType) => [
      CohortEnumSchema.Enum.csr,
      CohortEnumSchema.Enum.technician,
      ...(entityType === 'document' ? [CohortEnumSchema.Enum.insured] : []),
    ],
  },
  claim: {
    check: isClaimCorn,
    cohorts: (entityType) => [
      CohortEnumSchema.Enum.csr,
      CohortEnumSchema.Enum.estimator,
      ...(entityType === 'document' ? [CohortEnumSchema.Enum.insured] : []),
    ],
  },
} satisfies Record<CohortEntityType, CohortMapping<VisibilityEntityType>>

export const getAvailableExportCohorts = (targets: string[] | undefined) => {
  if (!targets?.length) return []

  const availableCohorts = Object.values(exportTargetCohortMap)
    .filter(({ check }) => targets.some(check))
    .flatMap(({ cohorts }) => cohorts)
  return [...new Set(availableCohorts)]
}

export const getAvailableVisibilityCohorts = (
  targets: string[] | undefined,
  entityType: VisibilityEntityType
) => {
  if (!targets?.length) return []

  const availableCohorts = Object.values(visibilityTargetCohortMap)
    .filter(({ check }) => targets.some(check))
    .flatMap(({ cohorts }) => (typeof cohorts === 'function' ? cohorts(entityType) : cohorts))
  return [...new Set(availableCohorts)]
}
