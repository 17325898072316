import { z } from 'zod'
import { NoteSchema } from '../base/note.js'

export const InternalNoteCommandSchema = NoteSchema.omit({
  id: true,
})

export const NoteCommandSchema = NoteSchema.omit({
  id: true,
})

export type InternalCommandNote = z.infer<typeof InternalNoteCommandSchema>
export type CommandNote = z.infer<typeof NoteCommandSchema>
