import { User } from '@auth0/auth0-react'
import { CohortEnum } from '@eigtech/auth0-types'
import { roleToCohort } from '@eigtech/shared-auth0'
import { getUserRoles } from '@eigtech/ui-shared-auth'
import { arrayOfAll } from '@eigtech/ui-shared-dave'
import log from '@eigtech/ui-shared-logging'

export function getUserCohorts(user: User | undefined): CohortEnum[] {
  const roles = getUserRoles(user)
  return roles.map((role) => roleToCohort[role]).filter((cohort): cohort is CohortEnum => !!cohort)
}

export function getUserCohort(user: User | undefined) {
  const cohorts = getUserCohorts(user)

  for (let index = 0; index < orderedCohorts.length; index++) {
    const cohort = orderedCohorts[index]

    if (cohorts.includes(cohort)) {
      return cohort
    }
  }
}

/**
 * The goal of this is to make sure we have an exhaustive list
 * of cohorts to check against for the getUserCohort function.
 * I did this instead of using something like a Record because
 * I want to ensure a specific order in which we check cohorts
 * and object keys order is not guaranteed.
 */
const arrayOfAllCohorts = arrayOfAll<CohortEnum>()
const orderedCohorts = arrayOfAllCohorts(['super', 'csr', 'estimator', 'technician', 'insured'])

const uniqueOrderedCohorts = [...new Set(orderedCohorts)]

if (orderedCohorts.length !== uniqueOrderedCohorts.length) {
  log.error('Mismatch between orderedCohorts and uniqueOrderedCohorts', {
    orderedCohorts,
    uniqueOrderedCohorts,
  })
}
