import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { TemplateSchema } from '../aggregates/template.js'

export const GetTemplateApiSchema = {
  params: z.object({
    id: z.string(),
  }),
  response: TemplateSchema,
} satisfies ApiSchema

export const GetTemplateRequestSchema = GetTemplateApiSchema.params

export type GetTemplateRequestPathParameters = z.infer<typeof GetTemplateApiSchema.params>
export type GetTemplateRequest = z.infer<typeof GetTemplateRequestSchema>
export type GetTemplateResponse = z.infer<typeof GetTemplateApiSchema.response>
export type GetTemplateHandler = Handler<GetTemplateRequest, GetTemplateResponse>
