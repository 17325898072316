import { CallbackRequest } from '@eigtech/communications-types'
import { useGetCallbackRequestsByClaims } from '@eigtech/ui-shared-callbacks'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import { useGetAssignedClaims } from '../claims'

export function useGetEstimatorCallbacks() {
  const { data } = useGetAssignedClaims()

  const queries = useGetCallbackRequestsByClaims((data ?? []).map(({ claimNumber }) => claimNumber))

  return useMemo(() => {
    const callbacks = queries
      .map(({ data }) => data)
      .flat()
      .filter(
        (callback: CallbackRequest | undefined): callback is CallbackRequest =>
          callback?.role === 'fieldAdjuster' && callback.status !== 'COMPLETED'
      )
      .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())

    const { REQUESTED, PENDING, ATTEMPTED } = groupBy(callbacks, (callback) => callback.status)

    const isPending = queries.some(({ isPending }) => isPending)
    const isFetching = queries.some(({ isFetching }) => isFetching)
    const isError = queries.some(({ isError }) => isError)

    return { callbacks, REQUESTED, PENDING, ATTEMPTED, status: { isPending, isFetching, isError } }
  }, [queries])
}
