import {
  CommunicationTemplate,
  CommunicationTemplateType,
  GetTemplateRequest,
  GetTemplateResponse,
  NoteTemplate,
  NoteTemplateType,
  Template,
  TemplateTypes,
} from '@eigtech/templates-types'
import { contextualGet, queryOptions, useQuery, useQueryClient } from '@eigtech/ui-shared-api'
import { templatesBasePath, templatesQueryKeys } from './constants'

type ListTemplatesQueryParameters<Type extends TemplateTypes> = {
  type?: Type
}

type ListTemplatesResponse<Type extends TemplateTypes> = Type extends NoteTemplateType
  ? NoteTemplate[]
  : Type extends CommunicationTemplateType
  ? CommunicationTemplate[]
  : Template[]

async function getTemplates<Type extends TemplateTypes>(
  params?: ListTemplatesQueryParameters<Type>
) {
  const data = await contextualGet<ListTemplatesResponse<Type>>(`${templatesBasePath}/`, {
    searchParams: params,
  })

  return data.sort(
    (a, b) =>
      new Date(b.lastUpdated ?? a.createdOn).getTime() -
      new Date(a.lastUpdated ?? a.createdOn).getTime()
  )
}

export const getTemplatesQueryOptions = <Type extends TemplateTypes>(
  params?: ListTemplatesQueryParameters<Type>
) =>
  queryOptions({
    queryKey: !!params ? templatesQueryKeys.filteredList(params) : templatesQueryKeys.list(),
    queryFn: () => getTemplates(params),
  })

export const useGetTemplates = <Type extends TemplateTypes>(
  params?: ListTemplatesQueryParameters<Type>
) => useQuery(getTemplatesQueryOptions(params))

export function useInvalidateTemplates() {
  const queryClient = useQueryClient()

  return function invalidateTemplates<Type extends TemplateTypes>(
    params?: ListTemplatesQueryParameters<Type>
  ) {
    queryClient.invalidateQueries({
      queryKey: getTemplatesQueryOptions(params).queryKey,
    })
  }
}

//

const getTemplate = ({ id }: GetTemplateRequest) =>
  contextualGet<GetTemplateResponse>(`${templatesBasePath}/${id}`)

export const getTemplateQueryOptions = (request: GetTemplateRequest) =>
  queryOptions({
    queryKey: templatesQueryKeys.detail(request.id),
    queryFn: () => getTemplate(request),
  })

export const useGetTemplate = (request: GetTemplateRequest) =>
  useQuery(getTemplateQueryOptions(request))

export function useInvalidateTemplate() {
  const queryClient = useQueryClient()

  return function invalidateTemplate(request: GetTemplateRequest) {
    queryClient.invalidateQueries({ queryKey: getTemplateQueryOptions(request).queryKey })
  }
}
