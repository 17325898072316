import { corrupt } from 'exhaustive'

export type ConvertDateOnlyStringToDateOptions<ValidDate extends Boolean> = {
  requireValidDate?: ValidDate
  incomingDateFormat?: 'month-day-year' | 'day-month-year' | 'year-month-day'
}

export type ConvertDateOnlyStringToDateReturn<T, ValidDate extends Boolean> = ValidDate extends true
  ? Date
  : T | Date

export function convertDateOnlyStringToDate<T, ValidDate extends boolean>(
  value: T,
  options: ConvertDateOnlyStringToDateOptions<ValidDate> = {}
): ConvertDateOnlyStringToDateReturn<T, ValidDate> {
  let returnDate: Date | undefined = undefined

  if (typeof value === 'string') {
    const dateParts: [string, string, string] = ['', '', '']
    const year = 0
    const month = 1
    const day = 2

    function fromYearMonthDay() {
      if (typeof value === 'string' && yearMonthDayRegex.test(value)) {
        const date = value.split(separatorRegex)
        dateParts[year] = date[0]
        dateParts[month] = date[1]
        dateParts[day] = date[2]
      }
    }

    function fromMonthDayYear() {
      if (typeof value === 'string' && monthDayYearRegex.test(value)) {
        const date = value.split(separatorRegex)
        dateParts[month] = date[0]
        dateParts[day] = date[1]
        dateParts[year] = date[2]
      }
    }

    function fromDayMonthYear() {
      if (typeof value === 'string' && dayMonthYearRegex.test(value)) {
        const date = value.split(separatorRegex)
        dateParts[day] = date[0]
        dateParts[month] = date[1]
        dateParts[year] = date[2]
      }
    }

    if (options.incomingDateFormat) {
      switch (options.incomingDateFormat) {
        case 'day-month-year':
          fromDayMonthYear()
          break
        case 'month-day-year':
          fromMonthDayYear()
          break
        case 'year-month-day':
          fromYearMonthDay()
          break

        default:
          corrupt(options.incomingDateFormat)
      }
    } else {
      fromMonthDayYear()
      fromDayMonthYear()
      fromYearMonthDay()
    }

    const parsedDate = dateParts.map((part) => parseInt(part))

    if (parsedDate.every(Number.isInteger)) {
      returnDate = new Date(parsedDate[year], parsedDate[month] - 1, parsedDate[day])
    }
  }

  if (!returnDate) {
    if (options.requireValidDate) {
      throw new Error(
        `invalid value passed to convertDateOnlyStringToDate: ${JSON.stringify(value)}`
      )
    }

    return value as ConvertDateOnlyStringToDateReturn<T, ValidDate>
  }

  return returnDate
}

const separatorRegex = /\/|\-|\./
const yearRegex = /\d{4}|\d{2}/
const monthRegex = /0?[1-9]|1[012]/
const dayRegex = /0?[1-9]|[12][0-9]|3[01]/

const composeRegex = (regexs: RegExp[]) =>
  new RegExp(`^${regexs.map((regex) => `(${regex.source})`).join(`(${separatorRegex.source})`)}$`)

// yyyy/mm/dd
const yearMonthDayRegex = composeRegex([yearRegex, monthRegex, dayRegex])

// mm/dd/yyyy
const monthDayYearRegex = composeRegex([monthRegex, dayRegex, yearRegex])

// dd/mm/yyyy
const dayMonthYearRegex = composeRegex([dayRegex, monthRegex, yearRegex])
