import {
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@eigtech/ui-shared-dave'
import { Column, RowData } from '@tanstack/react-table'
import { forwardRef, ReactElement, ReactNode, RefAttributes } from 'react'
import { MdClose as ClearIcon } from 'react-icons/md'

export type DataGridFilterContainerProps<TData extends RowData, TValue> = {
  children: ReactNode
  label: ReactNode
  column: Column<TData, TValue>
}

export const DataGridFilterContainer = forwardRef(function DataGridFilterContainer(
  { children, label, column },
  ref
) {
  return (
    <FormControl ref={ref}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <InputGroup>
        {children}
        <InputRightElement>
          <IconButton
            aria-label="Clear filter."
            icon={<ClearIcon />}
            size="sm"
            variant="ghost"
            onClick={() => column.setFilterValue(undefined)}
          />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  )
}) as <TData extends RowData, TValue>(
  props: DataGridFilterContainerProps<TData, TValue> & RefAttributes<HTMLDivElement>
) => ReactElement
