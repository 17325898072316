import { Center, CircularProgress, GenericErrorBoundaryComponent } from '@eigtech/ui-shared-dave'
import { RouterProvider, createRouter } from '@eigtech/ui-shared-router'
import { Sentry, initSentry } from '@eigtech/ui-shared-sentry'
import 'mapbox-gl/dist/mapbox-gl.css'
import { createRoot } from 'react-dom/client'
import { version } from '../package.json'
import './lib/utils/heap'
import { routeTree } from './routeTree.gen'
import { queryClient } from './store/queryClient'

// Set up a Router instance
const router = createRouter({
  routeTree,
  // defaultPreload: 'intent',
  defaultPendingComponent: () => (
    <Center>
      <CircularProgress isIndeterminate size={100} />
    </Center>
  ),
  context: {
    queryClient,
  },
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
})

initSentry({
  release: `ui-estimator@${version}`,
  router,
})

// Register things for typesafety
declare module '@eigtech/ui-shared-router' {
  interface Register {
    router: typeof router
  }
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <Sentry.ErrorBoundary fallback={GenericErrorBoundaryComponent}>
    <RouterProvider router={router} />
  </Sentry.ErrorBoundary>
)
