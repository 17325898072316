import { Box, Stack, StackProps } from '@chakra-ui/react'
import { ReactNode, useId } from 'react'
import { PageBody } from './PageBody'
import { PageContainer, PageContainerBaseProps, PageContainerProps } from './PageContainer'
import { PageError } from './PageAlert'
import { PageErrorBoundary } from './PageErrorBoundary'
import { PageHeader, PageHeaderBaseProps } from './PageHeader'
import { PageLoader } from './PageLoader'
import { PageToolbarBaseProps } from './PageToolbar'
import { exhaustive } from 'exhaustive'

export type PageProps = {
  aboveHeader?: ReactNode
  belowHeader?: ReactNode
  children?: ReactNode
  containerProps?: PageContainerProps
  errorComponent?: ReactNode
  errorMessage?: ReactNode
  errorTitle?: ReactNode
  headerProps?: StackProps
  heading?: ReactNode
  isError?: boolean
  isPending?: boolean
  loaderComponent?: ReactNode
  status?: 'error' | 'pending' | 'success'
  id?: string
} & PageHeaderBaseProps &
  PageContainerBaseProps &
  Partial<PageToolbarBaseProps>

export function Page({
  aboveHeader,
  belowHeader,
  children,
  containerProps = {},
  ErrorBoundary = PageErrorBoundary,
  errorComponent,
  errorMessage,
  errorTitle,
  headerProps = {},
  heading,
  isError,
  isFullWidth,
  isPending,
  loaderComponent = <PageLoader />,
  status: statusProp,
  toolbar,
  toolbarItems,
  id: propsId,
}: PageProps) {
  const status: Required<PageProps>['status'] =
    statusProp || (isPending ? 'pending' : isError ? 'error' : 'success')

  const baseId = useId()
  const fallbackId = `page_${baseId}`
  const id = propsId || fallbackId

  return (
    <PageContainer
      ErrorBoundary={ErrorBoundary}
      id={`${id}_container`}
      isFullWidth={isFullWidth}
      {...containerProps}
    >
      <Stack spacing="4">
        {!!aboveHeader && <Box>{aboveHeader}</Box>}

        {!!heading && (
          <PageHeader
            {...headerProps}
            id={`${id}_header`}
            toolbar={toolbar}
            toolbarItems={toolbarItems}
          >
            {heading}
          </PageHeader>
        )}

        {!!(belowHeader || children) && (
          <PageBody ErrorBoundary={ErrorBoundary} id={`${id}_body`}>
            {!!belowHeader && <Box>{belowHeader}</Box>}

            {exhaustive(status, {
              error: () =>
                errorComponent ?? (
                  <PageError
                    alert={{
                      title: errorTitle ?? 'Error',
                      description: errorMessage ?? 'Something went wrong.',
                    }}
                  />
                ),
              pending: () => loaderComponent,
              success: () => children,
              _: () => children,
            })}
          </PageBody>
        )}
      </Stack>
    </PageContainer>
  )
}
