import { z } from 'zod'
import { Auth0IdSchema } from './message'

export const MessagingClaimAssignmentSchema = z.object({
  fieldAdjusterId: Auth0IdSchema.optional(),
  coordinatorId: Auth0IdSchema.optional(),
  inspectionTechnicianId: Auth0IdSchema.optional(),
})

export const DeprecatedClaimAssigneeTypeEnumSchema = z.enum(['fileReviewer'])
export const ProducerClaimAssigneeTypeEnumSchema = z.enum([
  'coordinator',
  'fieldAdjuster',
  'inspectionTechnician',
  'reviewer',
  'projectManager',
])

export const ClaimAssigneeTypeEnumLaxSchema = z.enum([
  ...DeprecatedClaimAssigneeTypeEnumSchema.options,
  ...ProducerClaimAssigneeTypeEnumSchema.options,
])

export const ClaimAssigneeTypeEnumCanonSchema = z
  .enum([...ClaimAssigneeTypeEnumLaxSchema.options])
  .transform((assigneeType) => {
    switch (assigneeType) {
      case 'fileReviewer':
        return ProducerClaimAssigneeTypeEnumSchema.enum.reviewer
      default:
        return assigneeType
    }
  })

export const JobAssigneeTypeEnumSchema = z.enum([
  'approver',
  'coordinator',
  'dispatcher',
  'fieldTechnician',
  'projectManager',
  'subcontractor',
])

export type MessagingClaimAssignment = z.infer<typeof MessagingClaimAssignmentSchema>
export type ClaimAssigneeTypeEnumCanon = z.infer<typeof ClaimAssigneeTypeEnumCanonSchema>
export type ClaimAssigneeTypeEnumLax = z.infer<typeof ClaimAssigneeTypeEnumLaxSchema>
export type JobAssigneeTypeEnum = z.infer<typeof JobAssigneeTypeEnumSchema>
