import { ContactCornSchema } from '@eigtech/contacts-types'
import { EventBase, EventBaseSchema, eventType } from '@eigtech/event-stream-types'
import z from 'zod'

const estimatorServiceName = 'estimator'
const estimatorServiceEventType = <U extends string | undefined, V extends string | undefined>(
  entity?: U,
  eventName?: V
) => eventType(estimatorServiceName, entity, eventName)

const estimatorPortalEventType = <V extends string | undefined>(eventName?: V) =>
  estimatorServiceEventType('portal', eventName)

const EstimatorServiceEventBaseSchema = EventBaseSchema.merge(
  z.object({
    type: estimatorServiceEventType(),
  })
)

export const DeprecatedEstimatorPortalOriginSchema = z.enum(['API', 'PROJECTION'])
export const DEPRECATED_ESTIMATOR_PORTAL_ORIGIN = DeprecatedEstimatorPortalOriginSchema.Values

export const DeprecatedEstimatorPortalStatusSchema = z.enum(['ACTIVE', 'EXPIRED', 'INACTIVE'])
export const DEPRECATED_ESTIMATOR_PORTAL_STATUS = DeprecatedEstimatorPortalStatusSchema.Values

export type DeprecatedEstimatorPortalStatus = z.infer<typeof DeprecatedEstimatorPortalStatusSchema>

export const DeprecatedEstimatorPortalSchema = z.object({
  auth0Id: z.string().optional(),
  contactId: ContactCornSchema,
  claimNumber: z.string(),
  dateCreated: z.string().datetime(),
  dateExpired: z.string().datetime(),
  dateModified: z.string().datetime(),
  expiresAt: z.number(),
  createdBy: z.string(),
  modifiedBy: z.string(),
  status: DeprecatedEstimatorPortalStatusSchema,
  origin: DeprecatedEstimatorPortalOriginSchema,
})

export type DeprecatedEstimatorPortal = z.infer<typeof DeprecatedEstimatorPortalSchema>

export const DeprecatedEstimatorPortalEventSchema = EstimatorServiceEventBaseSchema.merge(
  z.object({
    type: estimatorPortalEventType(),
    portal: DeprecatedEstimatorPortalSchema,
  })
)

export const DeprecatedEstimatorPortalActivatedEventSchema =
  DeprecatedEstimatorPortalEventSchema.merge(
    z.object({
      type: estimatorPortalEventType('activated'),
    })
  )

export const DeprecatedEstimatorPortalDeactivatedEventSchema =
  DeprecatedEstimatorPortalEventSchema.merge(
    z.object({
      type: estimatorPortalEventType('deactivated'),
    })
  )

export const DeprecatedEstimatorPortalExpiredEventSchema =
  DeprecatedEstimatorPortalEventSchema.merge(
    z.object({
      type: estimatorPortalEventType('expired'),
    })
  )

export const isDeprecatedEstimatorPortalEvent = (event: EventBase) =>
  DeprecatedEstimatorPortalEventSchema.safeParse(event).success

export type DeprecatedEstimatorPortalEvent = z.infer<typeof DeprecatedEstimatorPortalEventSchema>
export type DeprecatedEstimatorPortalActivatedEvent = z.infer<
  typeof DeprecatedEstimatorPortalActivatedEventSchema
>
export type DeprecatedEstimatorPortalDeactivatedEvent = z.infer<
  typeof DeprecatedEstimatorPortalDeactivatedEventSchema
>
export type DeprecatedEstimatorPortalExpiredEvent = z.infer<
  typeof DeprecatedEstimatorPortalExpiredEventSchema
>
