import { ProducerClaimStatusEventNameEnumSchema } from '@eigtech/claims-types'
import z from 'zod'
import { ChronicleSchema } from './timeline'

const v1Status = ProducerClaimStatusEventNameEnumSchema.Enum

// excluding assigned/removed events and leveraging assignments instead
export const ClaimV1EventTypeSchema = z.enum([
  v1Status.canceled,
  v1Status.carrierApprovedEstimate,
  v1Status.carrierApprovedEstimateWithExceptions,
  v1Status.carrierRejectedEstimate,
  v1Status.clientApproved,
  v1Status.clientRejected,
  v1Status.customerContactedEmail,
  v1Status.customerContactedLvm,
  v1Status.customerNoContact,
  v1Status.dateReceived,
  v1Status.estimateRevisionRequested,
  v1Status.estimateSentToCarrier,
  v1Status.fieldAdjusterContactedCustomer,
  v1Status.fieldAdjusterCorrectionUploaded,
  v1Status.fieldAdjusterEstimateUploaded,
  v1Status.fieldAdjusterInspectionCompleted,
  v1Status.fieldAdjusterInspectionScheduled,
  v1Status.inAssignQueue,
  v1Status.initialCustomerContactAttempted,
  v1Status.initialCustomerContactFailed,
  v1Status.initialCustomerContactSuccess,
  v1Status.invalidContactInformation,
  v1Status.jobCompleted,
  v1Status.jobContracted,
  v1Status.jobNotSold,
  v1Status.jobSold,
  v1Status.jobStarted,
  v1Status.lossDate,
  v1Status.qaApproved,
  v1Status.qaApprovedPreliminaryReport,
  v1Status.qaApprovedSupplementReport,
  v1Status.qaRejected,
  v1Status.reInspectionRequested,
  v1Status.surveySentToCustomer,
])

export const ClaimV1ChronicleSchema = ChronicleSchema.merge(
  z.object({
    type: ClaimV1EventTypeSchema,
  })
)
