import { UiLogRequestBody } from '@eigtech/ui-logger-types'
import log from 'loglevel'
import { EigLogger, EigLoggingMethod } from './types'

const IS_DEV = import.meta.env.DEV
const STAGE_NAME = import.meta.env.VITE_STAGE_NAME ?? 'prod'
const isProdStage = STAGE_NAME.startsWith('prod')
const isTestStage = STAGE_NAME.startsWith('test')
const defaultShouldLog = (isProdStage || isTestStage) && !IS_DEV

const levels: log.LogLevelNames[] = ['debug', 'error', 'info', 'trace', 'warn']
const localStorageForceLevel = localStorage.getItem('FORCE_REMOTE_LOG_LEVEL')?.toLowerCase() as
  | log.LogLevelNames
  | undefined
const FORCE_REMOTE_LOG_LEVEL = levels.includes(localStorageForceLevel as log.LogLevelNames)
  ? localStorageForceLevel
  : undefined

const FORCE_LOG = localStorage.getItem('FORCE_REMOTE_LOG')?.toLowerCase() === 'true'

// for debugging remote logging
const remoteLog = log.getLogger('remoteLoggerLog')

const remoteLogLevel = localStorage.getItem('REMOTE_DEBUG_LOGGER_LEVEL')?.toLowerCase() as
  | log.LogLevelNames
  | undefined
remoteLog.setDefaultLevel(
  remoteLogLevel || (import.meta.env.PROD && !FORCE_LOG ? 'silent' : 'info')
)

export type RemoteLoggerOptions<UserContext extends object = object> = {
  apiBaseUrl: string
  apiLogServicePath?: string
  getAccessToken?: () => Promise<string>
  level?: log.LogLevelNames
  loggingInterval?: 5000
  shouldLog?: boolean
  traceLevels?: log.LogLevelNames[]
  ui: UiLogRequestBody['ui']
  userContext?: UserContext
}

export class RemoteLogger<UserContext extends object = object> {
  private ui: UiLogRequestBody['ui']
  private shouldLog = false

  apiBaseUrl: string
  apiLogServicePath: string = 'ui-log'
  getAccessToken: RemoteLoggerOptions['getAccessToken'] | undefined

  remoteLevel: log.LogLevelNames = 'error'
  traceLevels: log.LogLevelNames[] = ['trace', 'warn', 'error']

  private originalFactory: EigLogger['methodFactory']

  private queue: UiLogRequestBody['messages'] = []
  private logFailureCount = 0
  private queueInterval: NodeJS.Timeout | null = null
  loggingInterval = 5000

  userContext?: UserContext

  private originalLogLevel: log.LogLevelDesc

  constructor(logger: EigLogger, options: RemoteLoggerOptions<UserContext>) {
    this.ui = options.ui
    this.apiBaseUrl = options.apiBaseUrl
    this.originalFactory = logger.methodFactory
    this.originalLogLevel = logger.getLevel()
    this.shouldLog = FORCE_LOG || (options.shouldLog ?? defaultShouldLog)
    this.userContext = options.userContext
    this.remoteLevel = FORCE_REMOTE_LOG_LEVEL ?? options.level ?? 'error'

    if (!!options.apiLogServicePath) this.apiLogServicePath = options.apiLogServicePath
    if (!!options.getAccessToken) this.getAccessToken = options.getAccessToken
    if (!!options.traceLevels) this.traceLevels = options.traceLevels
    if (!!options.loggingInterval) this.loggingInterval = options.loggingInterval

    logger.methodFactory = this.remoteMethodFactory.bind(this)

    // Set to lowest log level and let remote logger determine if
    // it should log to the original logger and remote logging api.
    logger.setLevel(1, false)

    if (this.shouldLog) {
      this.startRemoteLogging()
    }
  }

  startRemoteLogging() {
    remoteLog.debug('starting remote logging')
    this.queueInterval = setInterval(this.workQueue.bind(this), this.loggingInterval)
    window.addEventListener('beforeunload', this.workQueue.bind(this))
  }

  stopRemoteLogging() {
    remoteLog.debug('stopping remote logging')
    if (this.queueInterval) {
      clearInterval(this.queueInterval)
      this.queueInterval = null
    }
    window.removeEventListener('beforeunload', this.workQueue.bind(this))
  }

  get apiUrl() {
    return `${this.apiBaseUrl}/${this.apiLogServicePath}/${this.ui}`
  }

  get hasSetAccessToken() {
    return (async () => {
      if (!this.getAccessToken) return true

      return !!(await this.getAccessToken())
    })()
  }

  private get originalLogLevelEnum() {
    return typeof this.originalLogLevel === 'number'
      ? this.originalLogLevel
      : log.levels[this.originalLogLevel.toUpperCase() as keyof typeof log.levels]
  }

  private determineShouldLog(methodName: log.LogLevelNames, level: log.LogLevelDesc) {
    const methodLevel = (
      methodName === 'debug' ? 'log' : methodName
    ).toUpperCase() as keyof typeof log.levels

    const methodEnum = log.levels[methodLevel]
    const levelEnum =
      typeof level === 'number' ? level : log.levels[level.toUpperCase() as keyof typeof log.levels]

    return (
      !!(Number.isInteger(methodEnum) && Number.isInteger(levelEnum)) && methodEnum >= levelEnum
    )
  }

  private shouldLogToRemote(methodName: log.LogLevelNames) {
    const shouldLogToRemote = this.determineShouldLog(methodName, this.remoteLevel)

    remoteLog.debug('should log to remote?', {
      methodName,
      originalLogLevel: this.originalLogLevel,
      shouldLogToRemote,
    })

    return shouldLogToRemote
  }

  private shouldLogToOriginal(methodName: log.LogLevelNames) {
    const shouldLogToOriginal = this.determineShouldLog(methodName, this.originalLogLevel)

    remoteLog.debug('should log to original?', {
      methodName,
      originalLogLevel: this.originalLogLevel,
      shouldLogToOriginal,
    })

    return shouldLogToOriginal
  }

  private remoteMethodFactory(
    methodName: log.LogLevelNames,
    logLevel: log.LogLevelNumbers,
    loggerName: string | symbol
  ): EigLoggingMethod {
    remoteLog.debug('remoteMethodFactory', {
      methodName,
      logLevel: this.originalLogLevelEnum,
      loggerName,
    })
    const rawMethod = this.originalFactory(methodName, this.originalLogLevelEnum, loggerName)

    const shouldLogToRemote = this.shouldLogToRemote(methodName)
    const shouldLogToOriginal = this.shouldLogToOriginal(methodName)

    const addToLogQueue = this.addToLogQueue.bind(this)

    return function (message, context, options) {
      const { logToRemote: logToRemoteOverride = true } = options ?? {}
      remoteLog.debug('lets log!')

      if (logToRemoteOverride && shouldLogToRemote) {
        remoteLog.debug('adding to remote log queue')
        addToLogQueue(methodName, message, context)
      }

      if (shouldLogToOriginal) {
        remoteLog.debug('logging to original')
        rawMethod.apply(undefined, [message, context])
      }
    }
  }

  private addToLogQueue(level: log.LogLevelNames, message: string, context: any) {
    const payload: UiLogRequestBody['messages'][number] = {
      level,
      message,
      context: { context, userContext: this.userContext },
    }

    const isTraceLevel = this.traceLevels.includes(level)
    if (isTraceLevel) {
      payload.trace = getStackTrace()
    }

    this.queue.push(payload)
  }

  private async workQueue() {
    const hasSetAccessToken = await this.hasSetAccessToken
    if (!hasSetAccessToken || !this.queue.length) return

    const messages = [...this.queue]
    this.queue = []

    try {
      await this.makeRequest(messages)
      this.logFailureCount = 0
    } catch (e) {
      remoteLog.error('Could not log to cloudwatch', e)
      this.logFailureCount = this.logFailureCount + 1

      if (this.logFailureCount > 3) {
        this.stopRemoteLogging()
      }
    }
  }

  private async makeRequest(messages: UiLogRequestBody['messages']) {
    const headers: RequestInit['headers'] = {
      'Content-Type': 'application/json',
    }

    const accessToken = await this.getAccessToken?.()
    if (!!accessToken) {
      headers.Authorization = `Bearer ${accessToken}`
    }

    const payload = {
      ui: this.ui,
      messages,
    }

    remoteLog.info('sending log to API', payload)

    await fetch(this.apiUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    })
  }
}

function getStackTrace() {
  try {
    throw new Error()
  } catch (e) {
    return e instanceof Error ? JSON.stringify(e.stack) : undefined
  }
}
