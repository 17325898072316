import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { RoleSchema } from '../../logStreamEvents/base/base.js'
import { ApiSchema } from '@eigtech/gatekeeper-types'

export const GetRolesForUserRequestPathSchema = z.object({
  userId: z.string(),
})
export const GetRolesForUserRequestSchema = GetRolesForUserRequestPathSchema
export const GetRolesForUserResponseSchema = z.object({
  roles: RoleSchema.array(),
})
export const GetRolesForUserApiSchema = {
  params: GetRolesForUserRequestPathSchema,
  response: GetRolesForUserResponseSchema,
} satisfies ApiSchema

export type GetRolesForUserRequest = z.infer<typeof GetRolesForUserRequestSchema>
export type GetRolesForUserResponse = z.infer<typeof GetRolesForUserResponseSchema>
export type GetRolesForUserHandler = Handler<GetRolesForUserRequest, GetRolesForUserResponse>
