import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { Auth0IdSchema } from '../../base'

export const ArchiveAllInboxMessageRequestHeaderSchema = ApiLambdalerAuthHeaderSchema
export const ArchiveAllInboxMessageRequestPathSchema = z.object({
  inboxId: Auth0IdSchema,
})

export const ArchiveAllInboxMessageRequestSchema = ArchiveAllInboxMessageRequestPathSchema.merge(
  ArchiveAllInboxMessageRequestHeaderSchema
)

export type ArchiveAllInboxMessageRequestPath = z.infer<
  typeof ArchiveAllInboxMessageRequestPathSchema
>
export type ArchiveAllInboxMessageRequest = z.infer<typeof ArchiveAllInboxMessageRequestSchema>

export const ArchiveAllInboxMessageResponseSchema = z.void()
export type ArchiveAllInboxMessageResponse = z.infer<typeof ArchiveAllInboxMessageResponseSchema>

export interface ArchiveAllInboxMessageHandler
  extends Handler<ArchiveAllInboxMessageRequest, ArchiveAllInboxMessageResponse> {}
