import { MdContentCopy as CopyIcon } from 'react-icons/md'
import {
  ComposedAlert,
  Textarea,
  Skeleton,
  Button,
  useCopyToClipboard,
  Stack,
  IconButton,
  Text,
} from '@eigtech/ui-shared-dave'
import { ROUTER_PATHS } from '../../../../lib/constants'
import { ComposedCard } from '@eigtech/ui-shared-dave'
import { getRouteApi } from '@eigtech/ui-shared-router'
import { useRequestLossReport } from '../../../../api/lossReport/requestLossReport'
import { useGetLatestLossReport } from '../../../../api/lossReport/getLatestLossReport'
import { useEffect, useState } from 'react'

const Route = getRouteApi(ROUTER_PATHS.claimDetailLossReport.routeId)

export function ClaimDetailLossReport() {
  const { claimNumber } = Route.useParams()
  const {
    data: lossReport,
    isError: isGetReportsError,
    isPending: isGettingReports,
  } = useGetLatestLossReport(claimNumber)
  const {
    mutateAsync: requestLossReport,
    isError: isRequestReportError,
    isPending: isRequestingLossReport,
  } = useRequestLossReport()
  const { copyToClipboard } = useCopyToClipboard()

  const isPending =
    isGettingReports ||
    lossReport?.latestReport?.status === 'pending' ||
    lossReport?.latestReport?.status === 'requested'

  const [reportText, setReportText] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!isGetReportsError && !isGettingReports)
      setReportText(lossReport?.latestReport?.reportText ?? '')
  }, [isGetReportsError, isGettingReports, lossReport, setReportText])

  async function handleGenerateLossReport() {
    await requestLossReport({ entityId: claimNumber })
    setReportText(undefined)
  }

  return (
    <ComposedCard heading="Loss Report">
      <ComposedAlert
        alert="The loss reports on this page are automatically generated by a computer system based on available claim information. 
        They are provided for your convenience, and should not be taken as a source of truth. Please review each report carefully before using it."
        status="warning"
      />
      {isPending && (
        <Stack mt={5}>
          <Text>Retrieving Loss Report...</Text>
          <Skeleton h="96" mb={3} mt={3} w="full" />
        </Stack>
      )}
      {!isPending && lossReport?.latestReport?.reportText && (
        <Stack>
          <IconButton
            alignSelf="flex-end"
            aria-label="Copy to clipboard"
            colorScheme="gray"
            icon={<CopyIcon />}
            position="absolute"
            right="2"
            size="sm"
            top="2"
            onClick={() => copyToClipboard(reportText ?? '')}
          />
          <Textarea
            mb={3}
            mt={5}
            rows={12}
            value={reportText ?? ''}
            onChange={(e) => setReportText(e.target.value)}
          />
        </Stack>
      )}
      {isRequestReportError && (
        <ComposedAlert
          alert="Error requesting new loss report. Please try again later."
          mt={5}
          status="error"
        />
      )}
      {isGetReportsError && (
        <ComposedAlert
          alert="Error obtaining loss report. Please try again later."
          mt={5}
          status="error"
        />
      )}
      {lossReport?.latestReport?.status === 'error' && (
        <ComposedAlert
          alert="Error creating loss report. Please try again later."
          mt={5}
          status="error"
        />
      )}
      {!isPending && (
        <Button isLoading={isRequestingLossReport} mb={3} mt={5} onClick={handleGenerateLossReport}>
          Request New Loss Report
        </Button>
      )}
    </ComposedCard>
  )
}
