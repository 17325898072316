import { ClaimAssigneeRelationshipCanonSchema } from '@eigtech/assignments-types'
import { LossDetailsSchema, LossLocationSchema } from '@eigtech/claims-types'
import { ContactSchema } from '@eigtech/contacts-types'
import { JobIdSchema, JobSchema } from '@eigtech/restoration-types'
import {
  DefaultClaimStatusTimelineCornSchema,
  DefaultClaimStatusTimelinePointCanonSchema,
} from '@eigtech/timeline-types'
import z from 'zod'

export const EstimatorClaimLossDetailsSchema = LossDetailsSchema.omit({
  claimNumber: true,
  id: true,
}).merge(z.object({ lossDate: z.string().optional(), lossType: z.string() }))

export const EstimatorClaimDetailsSchema = z.object({
  claimNumber: z.string(),
  coordinator: ContactSchema.optional(),
  carrier: ContactSchema,
  dateAssigned: z.string().optional(),
  fieldAdjuster: ContactSchema,
  primaryContact: ContactSchema,
  policyHolder: ContactSchema.optional(),
  lossDetails: EstimatorClaimLossDetailsSchema,
  lossLocation: LossLocationSchema.omit({ claimNumber: true, id: true }),
  policyNumber: z.string(),
  hasLegalRep: z.boolean().optional(),
  fieldAdjusterScheduledDate: z.string().optional(),
  fieldAdjusterCompletedDate: z.string().optional(),
  carrierApprovedEstimate: z.string().optional(),
  carrierApprovedEstimateWithExceptions: z.string().optional(),
  qaApprovedDate: z.string().optional(),
  customerContactedDate: z.string().optional(),
  claimStatus: z.string(),
  jobs: z.record(JobIdSchema, JobSchema).optional(),
  timeline: z.object({
    id: DefaultClaimStatusTimelineCornSchema,
    points: z.array(
      z.object({
        timelineEvent: z.string(),
        date: DefaultClaimStatusTimelinePointCanonSchema.shape.date,
        details: DefaultClaimStatusTimelinePointCanonSchema.shape.details,
        createdBy: DefaultClaimStatusTimelinePointCanonSchema.shape.createdBy,
        label: DefaultClaimStatusTimelinePointCanonSchema.shape.label,
      })
    ),
  }),
  veriskId: z.string().optional(),
})

export const EstimatorCsrTimelineSummarySchema = z.object({
  dateReceived: z.string().optional(),
  inAssignQueue: z.string().optional(),
  latestTimelineEvent: z.string().optional(),
})

export const EstimatorClaimRecordSchema = EstimatorClaimDetailsSchema.partial().merge(
  z.object({
    csrTimelineSummary: EstimatorCsrTimelineSummarySchema,
  })
)

export type EstimatorClaimDetails = z.infer<typeof EstimatorClaimDetailsSchema>
export type EstimatorClaimRecord = z.infer<typeof EstimatorClaimRecordSchema>
export type EstimatorClaimLossDetails = z.infer<typeof EstimatorClaimLossDetailsSchema>
export type EstimatorCsrTimelineSummary = z.infer<typeof EstimatorCsrTimelineSummarySchema>

export const EstimatorAssigneeRelationshipEnumSchema = ClaimAssigneeRelationshipCanonSchema

export type EstimatorAssigneeRelationship = z.infer<typeof EstimatorAssigneeRelationshipEnumSchema>
