import { ApiSchema } from '@eigtech/gatekeeper-types'
import { APIGatewayEvent, APIGatewayProxyResult, Handler } from 'aws-lambda'
import z, { ZodSchema } from 'zod'

/**
 * ApiLambdaler specific
 */
export const ApiLambdalerAuthHeaderSchema = z.object({
  requestActor: z.string().optional(),
  requestToken: z
    .record(z.string(), z.union([z.string(), z.number(), z.array(z.string())]))
    .optional(),
})

export type ApiLambdalerAuthHeader = z.infer<typeof ApiLambdalerAuthHeaderSchema>

/**
 * ApiLambdaler headers with required request actor
 */
export const ApiLambdalerAuthHeaderWithRequestActorSchema = ApiLambdalerAuthHeaderSchema.merge(
  z.object({
    requestActor: z.string(),
  })
)
export type ApiLambdalerAuthHeaderWithRequestActor = z.infer<
  typeof ApiLambdalerAuthHeaderWithRequestActorSchema
>

/**
 * isKeyOfT is a Type Guard for telling typescript key is a keyof T
 */
export const isKeyOfT =
  <T extends Record<string, unknown>>(schema: T) =>
  (key: string): key is Extract<keyof T, string> => {
    return schema[key as keyof T] !== undefined
  }

// API gateway
export type APIQueryStringSchemaTypes = 'string' | 'number' | 'boolean' | 'json'
export type APIQueryStringSchema<T extends Record<string, unknown>> = {
  [P in keyof T]: APIQueryStringSchemaTypes
}

export type APIHeaderSchema<T extends Record<string, unknown>> = { [P in keyof T]: string }

export type APIPathStringSchemaTypes = 'string' | 'number'
export type APIPathStringSchema<T extends Record<string, unknown>> = {
  [P in keyof T]: APIPathStringSchemaTypes
}

export type APIClaimSchema<T extends Record<string, unknown>> = { [P in keyof T]: string }

export type APIIdentitySchemaTypes = 'string' | 'number' | 'boolean'
export type APIIdentitySchema<T extends Record<string, unknown>> = {
  [P in keyof T]: {
    attribute: string
    type: APIIdentitySchemaTypes
  }
}

export type APIGatewaySyncMapper<T> = (gwe: APIGatewayEvent, request: Partial<T>) => Partial<T>
export type APIGatewayAsyncMapper<T> = (
  gwe: APIGatewayEvent,
  request: Partial<T>
) => Promise<Partial<T>>
export type APIGatewayParameterMapper<T> = APIGatewaySyncMapper<T> | APIGatewayAsyncMapper<T>

export type APIGatewayRequestHandler = <T, U>(
  handler: Handler<T, U>,
  bodyTemplate: ZodSchema<Partial<T>> | null,
  options?: {
    pathParameterTemplate?: ZodSchema<Partial<T>>
    querystringTemplate?: ZodSchema<Partial<T>>
    headersTemplate?: ZodSchema<Partial<T>>
    suppressLogging?: boolean
    allowInternalRequestActor?: boolean
    allowedInternalIssuers?: string[]
    decodeUser?: boolean
    onError?: (err: unknown) => Promise<void>
  },
  ...mappers: APIGatewayParameterMapper<T>[]
) => Handler<APIGatewayEvent, APIGatewayProxyResult>

export type APIGatewayRequestHandlerV2 = <T, U>(
  handler: Handler<T, U>,
  apiSchema: ApiSchema,
  options?: {
    suppressLogging?: boolean
    allowInternalRequestActor?: boolean
    allowedInternalIssuers?: string[]
    decodeUser?: boolean
    onError?: (err: unknown) => Promise<void>
  },
  ...mappers: APIGatewayParameterMapper<T>[]
) => Handler<APIGatewayEvent, APIGatewayProxyResult>
