import { z } from 'zod'
import { CallbackRequestedRolesSchema, CallbackUserSchema } from '../base/index.js'
import { CornSchema } from '@eigtech/shared-corn'
import { callbackEventType, CommunicationEventSchema } from './service.js'

export const CallbackRequestedEventSchema = CommunicationEventSchema.merge(
  z.object({
    type: callbackEventType('created'),
    callback: z.object({
      entityId: CornSchema,
      role: CallbackRequestedRolesSchema,
      notes: z.string().optional(),
      user: CallbackUserSchema,
    }),
    schemaVersion: z.literal(1),
  })
)

export type CallbackRequestedEvent = z.infer<typeof CallbackRequestedEventSchema>
