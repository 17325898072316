// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/drawer.ts

import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys)

// const $bg = cssVar('drawer-bg')
// const $bs = cssVar('drawer-box-shadow')

// const baseStyleOverlay = defineStyle({})

// const baseStyleDialogContainer = defineStyle({})

// const baseStyleDialog = defineStyle({})

const baseStyleHeader = defineStyle({
  borderBottomWidth: '1px',
  mb: '2',
})

// const baseStyleCloseButton = defineStyle({})

// const baseStyleBody = defineStyle({})

// const baseStyleFooter = defineStyle({})

const baseStyle = definePartsStyle({
  // overlay: baseStyleOverlay,
  // dialogContainer: baseStyleDialogContainer,
  // dialog: baseStyleDialog,
  header: baseStyleHeader,
  // closeButton: baseStyleCloseButton,
  // body: baseStyleBody,
  // footer: baseStyleFooter,
})

const sizes = {
  almostFull: {
    dialog: { maxW: 'calc(100vw - 3rem)' },
  },
  nav: {
    dialog: { maxW: 64 },
  },
  lg: {
    dialog: { maxW: '2xl' },
  },
  '2xl': {
    dialog: { maxW: '5xl' },
  },
  '3xl': {
    dialog: { maxW: '6xl' },
  },
  '4xl': {
    dialog: { maxW: '7xl' },
  },
  '5xl': {
    dialog: { maxW: '8xl' },
  },
}

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
  },
})
