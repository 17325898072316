import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardBodyProps,
  CardFooter,
  CardFooterProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  ComponentWithAs,
  forwardRef,
  Heading,
  HeadingProps,
  HStack,
  Text,
} from '@chakra-ui/react'
import React, { useId } from 'react'

export type ComposedCardProps = {
  cardBodyProps?: CardBodyProps
  cardFooterProps?: CardFooterProps
  cardHeaderProps?: CardHeaderProps
  actions?: React.ReactNode
  children?: React.ReactNode
  footer?: React.ReactNode
  heading?: React.ReactNode
  headingProps?: HeadingProps
  id?: string
} & CardProps

const cardIDs = {
  content: '_content',
  footer: '_footer',
  title: '_title',
}

export const ComposedCard: ComponentWithAs<'div', BoxProps & ComposedCardProps> = forwardRef(
  (
    {
      cardBodyProps = {},
      cardFooterProps = {},
      cardHeaderProps = {},
      actions,
      children,
      footer,
      heading,
      headingProps,
      id: propsId = '',
      ...props
    },
    ref
  ) => {
    const baseId = useId()
    const fallbackId = `card_${baseId}`
    const id = propsId || fallbackId

    return (
      <Card ref={ref} id={id} {...props}>
        {!!(heading || actions) && (
          <CardHeader id={`${id}${cardIDs.title}`} {...cardHeaderProps}>
            <HStack justifyContent="space-between">
              {typeof heading === 'string' ? (
                <Heading as="h2" flex="1" size="md" textAlign="left" {...headingProps}>
                  {heading}
                </Heading>
              ) : (
                <Box flexGrow={1}>{heading}</Box>
              )}

              {actions}
            </HStack>
          </CardHeader>
        )}

        {!!children && (
          <CardBody id={`${id}${cardIDs.content}`} {...cardBodyProps}>
            {typeof children === 'string' ? <Text>{children}</Text> : children}
          </CardBody>
        )}

        {!!footer && (
          <CardFooter id={`${id}${cardIDs.footer}`} {...cardFooterProps}>
            {typeof footer === 'string' ? <Text>{footer}</Text> : footer}
          </CardFooter>
        )}
      </Card>
    )
  }
)
