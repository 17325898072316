import { ApiLambdalerAuthHeaderSchema } from '@eigtech/lambdaler-api-gateway-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { ContactCornSchema } from '../../../base/contacts.js'

export const ActivateContactRequestPathSchema = z.object({ contactId: ContactCornSchema })
export const ActivateContactRequestHeadersSchema = ApiLambdalerAuthHeaderSchema
export const ActivateContactRequestSchema = ActivateContactRequestPathSchema.merge(
  ActivateContactRequestHeadersSchema
)

export type ActivateContactPath = z.infer<typeof ActivateContactRequestPathSchema>
export type ActivateContactRequest = z.infer<typeof ActivateContactRequestSchema>

export type ActivateContactResponse = void

export interface ActivateContactHandler
  extends Handler<ActivateContactRequest, ActivateContactResponse> {}

export const ActivateContactApiSchema = {
  headers: ActivateContactRequestHeadersSchema,
  params: ActivateContactRequestPathSchema,
}
