import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { AssigneeRelationshipCanonSchema } from '../entities/assignment'

export const UnassignApiSchema = {
  params: z.object({
    assignableId: z.string(),
    assigneeId: z.string(),
    assigneeRelationship: AssigneeRelationshipCanonSchema,
  }),
  headers: z.object({
    requestActor: z.string().optional(),
  }),
} satisfies ApiSchema

export const UnassignPathRequestSchema = UnassignApiSchema.params
export const UnassignHeadersRequestSchema = UnassignApiSchema.headers

export const UnassignRequestSchema = UnassignPathRequestSchema.merge(UnassignHeadersRequestSchema)

export type UnassignPathRequest = z.infer<typeof UnassignPathRequestSchema>
export type UnassignRequest = z.infer<typeof UnassignRequestSchema>
export type UnassignResponse = void
export type UnassignHandler = Handler<UnassignRequest, UnassignResponse>
