import z from 'zod'
import { Handler } from 'aws-lambda'
import { AttendeeSchema, MeetingSchema } from '../../base/chime.js'

export const RequestMeetingQueryStringSchema = z.object({
  m: z.string().optional(),
  u: z.string().optional(),
})

export const RequestMeetingRequestSchema = RequestMeetingQueryStringSchema

export const RequestMeetingResponseSchema = z.object({
  meeting: MeetingSchema,
  attendee: AttendeeSchema,
})

export type RequestMeetingRequest = z.infer<typeof RequestMeetingRequestSchema>
export type RequestMeetingResponse = z.infer<typeof RequestMeetingResponseSchema>
export type RequestMeetingHandler = Handler<RequestMeetingRequest, RequestMeetingResponse>
