import { heap } from '@eigtech/ui-estimator/lib/utils'
import {
  UserMenu as BaseUserMenu,
  BeforeLogoutReturn,
  UserProfileImageDrawer,
} from '@eigtech/ui-shared-auth'
import {
  ButtonProps,
  ConfirmModal,
  FancyLink,
  MenuItem,
  Text,
  forwardRef,
  useCopyToClipboard,
  useDisclosure,
} from '@eigtech/ui-shared-dave'
import { Sentry } from '@eigtech/ui-shared-sentry'
import { useRef } from 'react'
import { MdContentCopy, MdEditSquare, MdUploadFile } from 'react-icons/md'
import { version as cinnamonVersion } from '../../../../app/package.json'
import { version as uiVersion } from '../../../package.json'
import { EstimatorLicenseDrawer } from '../Core'

type UserMenuProps = { isCollapsed: boolean } & ButtonProps

export const UserMenu = forwardRef<UserMenuProps, 'button'>((props, ref) => {
  const { copyToClipboard } = useCopyToClipboard()

  const userProfileImageDrawer = useDisclosure()
  const {
    isOpen: isOpenEstimatorLicenseDrawer,
    onOpen: onOpenEstimatorLicenseDrawer,
    onClose: onCloseEstimatorLicenseDrawer,
  } = useDisclosure()

  const resolveRef = useRef<((value: BeforeLogoutReturn) => void) | null>(null)

  const confirmModal = useDisclosure()

  async function startConfirmLogout() {
    return new Promise<BeforeLogoutReturn>((resolve) => {
      resolveRef.current = resolve
      confirmModal.onOpen()
    })
  }

  function finishConfirmLogout(shouldLogout: boolean) {
    if (shouldLogout !== false) {
      heap.resetIdentity()

      Sentry.setUser(null)
    }

    resolveRef.current?.({ shouldLogout })
    resolveRef.current = null
  }

  return (
    <>
      <BaseUserMenu
        ref={ref}
        {...props}
        beforeLogout={startConfirmLogout}
        menuItems={[
          <MenuItem
            key="estimatorLicense"
            icon={<MdEditSquare />}
            onClick={onOpenEstimatorLicenseDrawer}
          >
            Update License Number
          </MenuItem>,
          <MenuItem
            key="userProfileImage"
            icon={<MdUploadFile />}
            onClick={userProfileImageDrawer.onOpen}
          >
            Update Profile Image
          </MenuItem>,
          <MenuItem
            key="uiVersion"
            icon={<MdContentCopy />}
            onClick={() => copyToClipboard(uiVersion)}
          >
            UI v{uiVersion}
          </MenuItem>,
          <MenuItem
            key="cinnamonVersion"
            icon={<MdContentCopy />}
            onClick={() => copyToClipboard(cinnamonVersion)}
          >
            Cinnamon v{cinnamonVersion}
          </MenuItem>,
        ]}
      />

      {confirmModal.isOpen && (
        <ConfirmModal
          {...confirmModal}
          confirmLabel="I've Logged Out of Microsoft AD"
          confirmProps={{ colorScheme: 'red' }}
          title="Before Logging Out"
          onCancel={() => finishConfirmLogout(false)}
          onConfirm={() => finishConfirmLogout(true)}
        >
          <Text>
            In order to fully logout, you&apos;ll need to also log out of your{' '}
            <FancyLink href="https://www.office.com/" isExternal target="microsoftAD">
              Microsoft Active Directory
            </FancyLink>{' '}
            account. If you don&apos;t do that before logging out here, you&apos;ll automatically be
            logged back into the Estimator Portal.
          </Text>
        </ConfirmModal>
      )}

      {userProfileImageDrawer.isOpen && <UserProfileImageDrawer {...userProfileImageDrawer} />}

      {isOpenEstimatorLicenseDrawer && (
        <EstimatorLicenseDrawer isOpen onClose={onCloseEstimatorLicenseDrawer} />
      )}
    </>
  )
})
