import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'
import { ClaimNumberSchema } from '../model/base'
import { CustomerCommunicationSchema } from '../model/customerCommunication'
import { PublicationTargetsSchema } from '../model/publishable'
import { BaseHeaderSchema } from './base'

const AddCustomerCommunicationSchemas = {
  params: z.object({
    claimNumber: ClaimNumberSchema,
  }),
  body: z.object({
    customerCommunication: CustomerCommunicationSchema,
    publicationTargets: PublicationTargetsSchema.array().optional(),
  }),
  headers: BaseHeaderSchema,
  response: z.object({
    claimNumber: ClaimNumberSchema,
    customerCommunication: CustomerCommunicationSchema,
  }),
}

export const AddCustomerCommunicationRequestSchema = AddCustomerCommunicationSchemas.params
  .merge(AddCustomerCommunicationSchemas.body)
  .merge(AddCustomerCommunicationSchemas.headers)

export type AddCustomerCommunicationBodyRequest = z.infer<
  typeof AddCustomerCommunicationSchemas.body
>
export type AddCustomerCommunicationHeaderRequest = z.infer<
  typeof AddCustomerCommunicationSchemas.headers
>
export type AddCustomerCommunicationRequest = z.infer<typeof AddCustomerCommunicationRequestSchema>
export type AddCustomerCommunicationResponse = z.infer<
  typeof AddCustomerCommunicationSchemas.response
>

export interface AddCustomerCommunicationHandler
  extends Handler<AddCustomerCommunicationRequest, AddCustomerCommunicationResponse> {}

export const AddCustomerCommunicationApiSchema: ApiSchema = AddCustomerCommunicationSchemas
