import { z } from 'zod'

// outbox message statuses can be updated to any status, while the other message types cannot.
export const MessageStatusSchema = z.enum(['UNREAD', 'READ', 'ARCHIVED'])
export const OutboxMessageStatusSchema = MessageStatusSchema
export const InboxMessageStatusSchema = z.enum([
  OutboxMessageStatusSchema.Values.READ,
  OutboxMessageStatusSchema.Values.UNREAD,
])

export const cohortPrefix = 'cohort'
export const serviceOutboxIdPrefix = 'service'
export const auth0IdPrefix = 'auth0'
export const auth0AzureAdPrefix = 'waad'
export const auth0GoogleAppsPrefix = 'google-apps'
export const auth0PasswordlessEmailPrefix = 'email'
export const messageGroupIdPrefix = 'group'
export const outboxIdPrefix = 'outbox'
export const inboxIdPrefix = 'inbox'

export const InboxContentSchema = z.object({
  subject: z.string(),
  body: z.string(),
  contentSchemaVersion: z.literal(1),
})

const auth0PrefixList = `${auth0IdPrefix}|${auth0AzureAdPrefix}|${auth0GoogleAppsPrefix}|${auth0PasswordlessEmailPrefix}`
const auth0Regex = `\\b(${auth0PrefixList})[|]`
const serviceRegex = `\\b${serviceOutboxIdPrefix}[|]`
const messageGroupRegex = `\\b${messageGroupIdPrefix}[|]`
const cohortRegex = `\\b${cohortPrefix}[|]`
const outboxMessageIdAuth0Regex = `\\b${outboxIdPrefix}#(${auth0PrefixList})[|]`
const outboxMessageIdServiceRegex = `\\b${outboxIdPrefix}#${serviceOutboxIdPrefix}[|]`
const inboxMessageIdAuth0Regex = `\\b${inboxIdPrefix}#(${auth0PrefixList})[|]`

export const Auth0IdSchema = z.custom<
  | `${typeof auth0IdPrefix}|${string}`
  | `${typeof auth0AzureAdPrefix}|${string}`
  | `${typeof auth0GoogleAppsPrefix}|${string}`
  | `${typeof auth0PasswordlessEmailPrefix}|${string}`
>((val) => new RegExp(`${auth0Regex}`, 'g').test(val as string))

export const MessageGroupIdSchema = z.custom<`${typeof messageGroupIdPrefix}|${string}`>((val) =>
  new RegExp(`${messageGroupRegex}`, 'g').test(val as string)
)

export const CohortIdSchema = z.custom<`${typeof cohortPrefix}|${string}`>((val) =>
  new RegExp(`${cohortRegex}`, 'g').test(val as string)
)

export const OutboxIdSchema = z.custom<
  | `${typeof auth0IdPrefix}|${string}`
  | `${typeof auth0AzureAdPrefix}|${string}`
  | `${typeof auth0GoogleAppsPrefix}|${string}`
  | `${typeof auth0PasswordlessEmailPrefix}|${string}`
  | `${typeof serviceOutboxIdPrefix}|${string}`
>((val) => new RegExp(`${serviceRegex}|${auth0Regex}`, 'g').test(val as string))

export const OutboxMessageIdSchema = z.custom<`${typeof outboxIdPrefix}#${OutboxId}#${string}`>(
  (val) =>
    new RegExp(`${outboxMessageIdAuth0Regex}|${outboxMessageIdServiceRegex}`, 'g').test(
      val as string
    )
)

export const InboxMessageIdSchema = z.custom<`${typeof inboxIdPrefix}#${Auth0Id}#${string}`>(
  (val) => new RegExp(`${inboxMessageIdAuth0Regex}`, 'g').test(val as string)
)

export const MessageContextDeprecatedSchema = z.object({
  claimNumber: z.string().optional(),
  jobId: z.string().optional(),
})

export const MessageContextCanonSchema = z.object({
  entityId: z.string(),
  entityType: z.enum(['Claim', 'Job']),
})

export const MessageContextLaxSchema = MessageContextCanonSchema.or(
  MessageContextDeprecatedSchema
).transform((val) => {
  if ('entityId' in val) {
    return val
  } else if ('claimNumber' in val && typeof val.claimNumber === 'string') {
    return {
      entityId: val.claimNumber,
      entityType: 'Claim' as 'Claim' | 'Job',
    }
  } else if ('jobId' in val && typeof val.jobId === 'string') {
    return {
      entityId: val.jobId,
      entityType: 'Job' as 'Claim' | 'Job',
    }
  }
  return z.NEVER
})

export const MessageSchema = z.object({
  messageId: z.unknown(),
  inboxId: z.unknown(),
  type: z.string(),
  outboxId: OutboxIdSchema,
  messageStatus: InboxMessageStatusSchema,
  content: InboxContentSchema,
  dateSent: z.string().datetime(),
  dateRead: z.string().datetime().optional(),
  context: MessageContextLaxSchema.optional(),
})

export const Auth0InboxMessageSchema = MessageSchema.merge(
  z.object({ inboxId: Auth0IdSchema, messageId: InboxMessageIdSchema, type: z.literal('auth0') })
)

export const MessageGroupInboxMessageSchema = MessageSchema.merge(
  z.object({ inboxId: MessageGroupIdSchema, type: z.literal('group') })
).omit({ messageId: true })

export const ArchivedInboxMessageSchema = Auth0InboxMessageSchema.merge(
  z.object({
    type: z.literal('archive'),
    archivedDate: z.string().datetime(),
  })
)

export const InboxMessageSchema = Auth0InboxMessageSchema

export const MessageGroupSchema = z.object({
  groupId: MessageGroupIdSchema,
  name: z.string(),
  description: z.string(),
})

export const MessageGroupMapSchema = z.object({
  messageGroups: z.record(z.string(), MessageGroupSchema),
})

export const MessageGroupInboxSchema = z.object({
  inboxId: Auth0IdSchema,
  groupId: MessageGroupIdSchema,
})

export const OutboxMessageSchema = MessageSchema.omit({
  dateRead: true,
  messageStatus: true,
  type: true,
}).merge(
  z.object({
    inboxId: Auth0IdSchema.or(MessageGroupIdSchema).or(CohortIdSchema),
    messageId: OutboxMessageIdSchema,
    messageStatus: OutboxMessageStatusSchema,
  })
)

export const OutboxSchema = z.object({
  outboxId: OutboxIdSchema,
  sent: z.number(),
})

export const InboxSchema = z.object({
  inboxId: Auth0IdSchema,
  read: z.number(),
  unread: z.number(),
  archived: z.number(),
})

export type InboxContent = z.infer<typeof InboxContentSchema>
export type Inbox = z.infer<typeof InboxSchema>
export type Outbox = z.infer<typeof OutboxSchema>
export type Auth0InboxMessage = z.infer<typeof Auth0InboxMessageSchema>
export type MessageGroupInboxMessage = z.infer<typeof MessageGroupInboxMessageSchema>
export type InboxMessage = z.infer<typeof InboxMessageSchema>

export type OutboxMessage = z.infer<typeof OutboxMessageSchema>
export type ArchivedInboxMessage = z.infer<typeof ArchivedInboxMessageSchema>
export type InboxMessageStatus = z.infer<typeof InboxMessageStatusSchema>
export type MessageStatus = z.infer<typeof MessageStatusSchema>
export type OutboxMessageStatuses = z.infer<typeof OutboxMessageStatusSchema>
export type MessageGroup = z.infer<typeof MessageGroupSchema>
export type MessageGroupMap = z.infer<typeof MessageGroupMapSchema>
export type MessageContext = z.infer<typeof MessageContextLaxSchema>
export type MessageContextDeprecated = z.infer<typeof MessageContextDeprecatedSchema>

export type OutboxId = z.infer<typeof OutboxIdSchema>
export type Auth0Id = z.infer<typeof Auth0IdSchema>
export type MessageGroupId = z.infer<typeof MessageGroupIdSchema>
export type CohortId = z.infer<typeof CohortIdSchema>
export type OutboxMessageId = z.infer<typeof OutboxMessageIdSchema>
export type InboxMessageId = z.infer<typeof InboxMessageIdSchema>

export const isOutboxId = (inboxId: unknown): inboxId is OutboxId =>
  OutboxIdSchema.safeParse(inboxId).success
export const isServiceMessage = (inboxId: unknown): inboxId is OutboxId =>
  OutboxIdSchema.safeParse(inboxId).success
export const isAuth0Id = (inboxId: unknown): inboxId is Auth0Id =>
  Auth0IdSchema.safeParse(inboxId).success
export const isMessageGroupId = (inboxId: unknown): inboxId is MessageGroupId =>
  MessageGroupIdSchema.safeParse(inboxId).success
export const isOutboxMessageId = (messageId: unknown): messageId is OutboxMessageId =>
  OutboxMessageIdSchema.safeParse(messageId).success
export const isCohortId = (inboxId: unknown): inboxId is CohortId =>
  CohortIdSchema.safeParse(inboxId).success
export const isInboxMessageId = (messageId: unknown): messageId is InboxMessageId =>
  InboxMessageIdSchema.safeParse(messageId).success
