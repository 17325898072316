import type {
  AddNoteBodyRequest,
  AddNoteResponse,
  AddNoteRequest as BaseAddNoteRequest,
  GetNotebookForCohortResponse,
  Note,
} from '@eigtech/notes-types'
import { Corn, getCornKernels } from '@eigtech/shared-corn'
import { useApiInstanceContext, useMutation, useQueryClient } from '@eigtech/ui-shared-api'
import { randomString } from '@eigtech/ui-shared-dave'
import { notebooksBasePath, notebooksQueryKeys } from './constants'
import { useInvalidateEntityNotebook } from './getNotebooks'

export type AddNoteRequest = Omit<BaseAddNoteRequest, 'entityId'> & { entityCorn: Corn }

export function useCreateEntityNote() {
  const { post } = useApiInstanceContext()
  const queryClient = useQueryClient()
  const invalidateEntityNotebook = useInvalidateEntityNotebook()

  return useMutation({
    mutationFn: async ({ entityCorn, ...request }: AddNoteRequest) => {
      const id = getCornKernels(entityCorn).resourceId
      await post<AddNoteResponse, AddNoteBodyRequest>(`${notebooksBasePath}/${id}`, request)
      return { entityCorn, entityId: id, ...request }
    },
    async onMutate(data) {
      await queryClient.cancelQueries({
        queryKey: notebooksQueryKeys.entityNotebook(data.entityCorn),
      })

      const previousNotebook = queryClient.getQueryData(
        notebooksQueryKeys.entityNotebook(data.entityCorn)
      )

      queryClient.setQueryData<GetNotebookForCohortResponse>(
        notebooksQueryKeys.entityNotebook(data.entityCorn),
        (old) => {
          if (old === undefined) return old
          const tempNote: Note = {
            ...data,
            entityId: getCornKernels(data.entityCorn).resourceId,
            createdBy: 'User (pending)',
            createdOn: new Date().toISOString(),
            id: `corn:notes:note:${randomString()}`,
          }
          return {
            ...old,
            notes: [tempNote, ...(old.notes ?? [])],
          }
        }
      )

      return {
        previousNotebook,
      }
    },
    onError: (__, data, context) => {
      if (context === undefined) return
      queryClient.setQueryData(
        notebooksQueryKeys.entityNotebook(data.entityCorn),
        context.previousNotebook
      )
    },
    onSettled(data) {
      if (data === undefined) return
      //Kind gross
      setTimeout(() => invalidateEntityNotebook(data.entityCorn), 20000)
    },
  })
}
