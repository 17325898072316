import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import z from 'zod'

export const UnlinkAccountsRequestBodySchema = z.object({
  userId: z.string(),
  linkedUserIds: z.string().array(),
})

export const UnlinkAccountsRequestSchema = UnlinkAccountsRequestBodySchema
export const UnlinkAccountsResponseSchema = z.void()

export type UnlinkAccountsRequest = z.infer<typeof UnlinkAccountsRequestSchema>
export type UnlinkAccountsRequestBody = z.infer<typeof UnlinkAccountsRequestBodySchema>
export type UnlinkAccountsResponse = z.infer<typeof UnlinkAccountsResponseSchema>

export interface UnlinkAccountsHandler
  extends Handler<UnlinkAccountsRequest, UnlinkAccountsResponse> {}

export const UnlinkAccountsApiSchema: ApiSchema = {
  body: UnlinkAccountsRequestBodySchema,
}
