import { Handler } from 'aws-lambda'
import { z } from 'zod'
import { CallbackQueryableStatusSchema, CallbackRequestSchema } from '../../../index.js'

export const GetCallbackRequestsByStatusRequestPathSchema = z.object({
  status: CallbackQueryableStatusSchema,
})

export type GetCallbackRequestsByStatusRequestPath = z.infer<
  typeof GetCallbackRequestsByStatusRequestPathSchema
>

export const GetCallbackRequestsByStatusRequestSchema = GetCallbackRequestsByStatusRequestPathSchema

export type GetCallbackRequestsByStatusRequest = z.infer<
  typeof GetCallbackRequestsByStatusRequestSchema
>

export const GetCallbackRequestsByStatusResponseSchema = CallbackRequestSchema.array()

export type GetCallbackRequestsByStatusResponse = z.infer<
  typeof GetCallbackRequestsByStatusResponseSchema
>

export interface GetCallbackRequestsByStatusHandler
  extends Handler<GetCallbackRequestsByStatusRequest, GetCallbackRequestsByStatusResponse> {}
