import { z } from 'zod'

export const BaseEventMetadataSchema = z.object({
  timestamp: z.string(),
  transactionId: z.string(),
  eventType: z.enum(['assignment', 'note', 'status', 'customDocument', 'estimate']),
})
export type BaseEventMetadata = z.infer<typeof BaseEventMetadataSchema>

export const ParsedExportBaseSchema = BaseEventMetadataSchema.merge(
  z.object({
    eventId: z.string(),
  })
)
export type ParsedExportBase = z.infer<typeof ParsedExportBaseSchema>
