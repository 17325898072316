import { z } from 'zod'
import { claimsServiceCorn } from '../base/service.js'

export const NotebookIdSchema = z.string()
export const EstimateIdSchema = z.string()
export const EMSJobIdSchema = z.string()
export const BuildingInformationIdSchema = z.string()
export const ExternalSystemId = z.enum(['verisk', 'symbility', 'symbilityAssignmentId'])
export const ExternalSystemIdsSchema = z.record(ExternalSystemId, z.string())
export const ClaimCornSchema = claimsServiceCorn('claim')
export const isClaimCorn = (entityId: string): entityId is ClaimCorn =>
  ClaimCornSchema.safeParse(entityId).success

export const ClaimSchema = z.object({
  claimNumber: z.string(),
  policyNumber: z.string(),
  lossDetailsId: z.string(),
  lossLocationId: z.string(),
  externalSystemIds: ExternalSystemIdsSchema.optional(),
})

export type Claim = z.infer<typeof ClaimSchema>
export type ClaimCorn = z.infer<typeof ClaimCornSchema>
