import { ApiSchema } from '@eigtech/gatekeeper-types'
import { Handler } from 'aws-lambda'
import { z } from 'zod'

export const GetUserNameFromIdRequestPathSchema = z.object({
  userId: z.string(),
})
export const GetUserNameFromIdRequestSchema = GetUserNameFromIdRequestPathSchema

export const GetUserNameFromIdResponseSchema = z.object({
  name: z.string(),
})

export const GetUserNameFromIdApiSchema = {
  params: GetUserNameFromIdRequestPathSchema,
  response: GetUserNameFromIdResponseSchema,
} satisfies ApiSchema

export type GetUserNameFromIdRequest = z.infer<typeof GetUserNameFromIdRequestSchema>
export type GetUserNameFromIdResponse = z.infer<typeof GetUserNameFromIdResponseSchema>
export type GetUserNameFromIdHandler = Handler<GetUserNameFromIdRequest, GetUserNameFromIdResponse>
