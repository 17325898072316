import { ContactCornSchema } from '@eigtech/contacts-types'
import z from 'zod'
import { JobExternalIdSchema } from './externalIds'

export const SubcontractorPaymentSchema = z.object({
  externalId: JobExternalIdSchema.optional(), // e.g. transaction ID
  paidDate: z.string().datetime().optional(),
  paidInFull: z.boolean(),
})
export const JobDepositPaymentSchema = z.object({
  paidBy: ContactCornSchema.or(z.string()),
  paidDate: z.string().datetime(),
  paidInFull: z.boolean(),
  externalId: JobExternalIdSchema.optional(),
})
export const JobDepositOverrideApprovalSchema = z.object({
  approvedBy: ContactCornSchema.or(z.string()),
  approvedDate: z.string().datetime(),
  externalId: JobExternalIdSchema.optional(),
})
export const JobBillCollectionSchema = z.object({
  externalId: JobExternalIdSchema.optional(),
  sentDate: z.string().datetime(),
  collectorName: z.string().optional(),
})
export const CarrierPaymentSchema = z.object({
  externalId: JobExternalIdSchema.optional(),
  paidBy: ContactCornSchema.or(z.string()),
  paidDate: z.string().datetime(),
  paidInFull: z.boolean(),
})
export const CarrierInvoiceSchema = z.object({
  externalId: JobExternalIdSchema.optional(),
  carrier: ContactCornSchema.or(z.string()),
  invoicedDate: z.string().datetime(),
  notes: z.string().optional(),
})

export type SubcontractorPayment = z.infer<typeof SubcontractorPaymentSchema>
export type JobDepositPayment = z.infer<typeof JobDepositPaymentSchema>
export type JobDepositOverrideApproval = z.infer<typeof JobDepositOverrideApprovalSchema>
export type JobBillCollection = z.infer<typeof JobBillCollectionSchema>
export type CarrierPayment = z.infer<typeof CarrierPaymentSchema>
export type CarrierInvoice = z.infer<typeof CarrierInvoiceSchema>
